import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import _get from "lodash/get";
import t from "../../../shared/translations";
import { getAboutUs, logKibana } from "../../../shared/actions";
import { sendFeedback, hideModal } from "../../../shared/actions/v2_actions";
import { v2DefaultConfig } from "../../../shared/theme/config";
import {
    toggleFooter,
    countTextPercentage,
    handleMobileInputFocus,
    handleMobileInputBlur,
} from "../../../main";
import { mobile } from "../../../shared/components/helpers/styled_queries";
import { Row, Col, ElementTitle } from "../../../shared/components/V2/Layout";
import CircleProgressBar from "../../../shared/components/V2/CircleProgressBar";
import NewSelectField from "../../../shared/components/V2/NewSelectField";
import { BorderStarIcon, HappyIcon, SadIcon } from "../../../shared/components/V2/V2Icons";
import { V2Button } from "../../../shared/components/V2/V2Buttons";
import { FeedbackSubheaderContainer } from "./common";

const AnimationSpan = styled.span`
    @keyframes appearAll {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    opacity: 0;
    width: 100%;
    transition: all 1.5s ease;
    animation: appearAll;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    ${mobile`
        ${Col} {
            padding-left: 0px;
            padding-right: 0px;
        }
    `}
`;

const FormContainer = styled.div`
    width: 100%;
`;

const H1 = styled.h1`
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${v2DefaultConfig.colors.darkText};
`;

const Subheader = styled.p`
    @keyframes smoothAppearStars {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes smoothAppearIcon {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    opacity: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #7f8fa4;

    &.stars-label {
        animation: smoothAppearStars;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    &.icon-label {
        animation: smoothAppearIcon;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    span {
        font-weight: bold;
        color: #353d51;
    }

    svg {
        height: 69px;
    }
`;

const TextareaContainer = styled.div`
    position: relative;
    width: 100%;

    textarea {
        max-height: 250px;
    }
`;

const Textarea = styled.textarea`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    min-height: 105px;
    border-radius: 6px;
    border: solid 1px #bcc5d3;
    padding: 10px 64px 10px 16px;
    outline: 0 none;

    &:focus {
        border: 1px solid #36b56a;
        outline: 0 none;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #b4bdca;
    }
`;

const CircleContainer = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    right: 24px;
    bottom: 24px;
`;

const StarsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 8px 0;
`;

const StarLabel = styled.div`
    color: #8c9aae;
    font-size: 12px;
    font-weight: 600;
    line-height: 3;
    text-align: center;
    width: 100%;
`;

const Star = styled.div`
    @keyframes appearStar {
        0% {
            display: none;
            visibility: hidden;
            opacity: 0;
            width: 34px;
            height: 32px;
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
        }

        100% {
            opacity: 1;
            display: flex;
            visibility: visible;
            width: 34px;
            height: 32px;
        }
    }

    @keyframes labelAppear {
        0% {
            opacity: 0;
            visibility: hidden;
        }

        100% {
            opacity: 1;
            visibility: visible;
        }
    }

    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    min-width: 56px;
    max-width: 56px;
    transition: all 1s ease;

    ${StarLabel} {
        opacity: 0;
        width: fit-content;
        text-align: center;

        &.first {
            animation: labelAppear;
            animation-delay: 0.1s;
            animation-duration: 0.7s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }

        &.second {
            animation: labelAppear;
            animation-delay: 0.5s;
            animation-duration: 0.7s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
    }

    .one {
        animation: appearStar;
        animation-delay: 0.1s;
        animation-duration: 0.7s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .two {
        animation: appearStar;
        animation-delay: 0.2s;
        animation-duration: 0.7s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .three {
        animation: appearStar;
        animation-delay: 0.3s;
        animation-duration: 0.7s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .four {
        animation: appearStar;
        animation-delay: 0.4s;
        animation-duration: 0.7s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .five {
        animation: appearStar;
        animation-delay: 0.5s;
        animation-duration: 0.7s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .one, .two, .three, .four, .five {
        opacity: 0;
    }

    svg {
        margin-bottom: auto !important;
    }

    &:hover {
        cursor: pointer;

        .one, .two, .three, .four, .five {
            min-width: 51px;
            min-height: 48px;
            position: relative;
            bottom: -8px;
            margin-top: -16px;
        }
    }
`;

const StyledV2Button = styled(V2Button)`
    margin: auto;
    margin-top: 16px;
`;

const SubtitleLabel = styled.label`
    margin-bottom: 0px;
    text-align: center;
    color: #353d51;
    font-size: 14px;
    line-height: 1.71;

    span {
        display: block;
        font-weight: bold;
        line-height: 1.6;
        font-size: 20px;
    }
`;

let starsDisplayInterval;

class ImprovePage extends React.Component {
    state = {
        comment: "",
        maxCount: 1500,
        rating: 0,
        ratingHover: 0,
        answer: "",
        stars: [1, 2, 3, 4, 5],
        showIcons: false,
    };

    componentDidMount() {
        const { aboutUs, getAboutUs } = this.props;

        if (aboutUs) {
        } else {
            getAboutUs();
        }

        starsDisplayInterval = setInterval(() => {
            const { stars } = this.state;
            if (stars.length < 5) {
                this.setState({
                    stars: [...stars, stars.length + 1],
                });
            } else if (starsDisplayInterval) {
                clearInterval(starsDisplayInterval);
            }
        }, 100);
    }

    componentWillUnmount() {
        toggleFooter().show();
        if (starsDisplayInterval) {
            clearInterval(starsDisplayInterval);
        }
    }

    handleChange = (e) => {
        const { target } = e;

        this.setState({ [target.name]: target.value });
    };

    handleRating = (rating) => {
        this.setState({ rating, showIcons: true }, () => {
            setTimeout(() => {
                this.setState({
                    showIcons: false,
                });
            }, 5000)
        });
    };

    handleRatingHover = (ratingHover) => {
        this.setState({ ratingHover });
    };

    submitFeedback = () => {
        const { answer, rating, comment } = this.state;
        const { sendFeedback, postedClaim, logKibana } = this.props;
        const claimId = _get(postedClaim, "id", _get(postedClaim, "draft.id", ""));

        logKibana(`send_feedback, claimId: ${claimId}`, "/thanks", "warning");

        sendFeedback({
            claim: claimId,
            answer,
            rating: parseInt(rating, 10),
            comment,
        }).then(() => {
            this.props.hideModal();
        }).catch(() => {
            this.props.hideModal();
        });
    };

    getOptions = () => {
        const { aboutUs } = this.props;

        if (!aboutUs) return [];

        return aboutUs.map((option) => ({
            value: option,
            label: t`${option}`,
        }));
    };

    renderStars = () => {
        const { rating, ratingHover, stars } = this.state;
        const classes = ["one", "two", "three", "four", "five"];
        const rateClasses = ["first", null, null, null, "second"];
        return stars.map((rate) => {
            const size = 32;

            return (
                <Star
                    key={rate}
                    onClick={() => this.handleRating(rate)}
                    onTouchEnd={() => this.handleRating(rate)}
                    onMouseEnter={() => this.handleRatingHover(rate)}
                    onMouseLeave={() => this.handleRatingHover(0)}
                >
                    {
                        <BorderStarIcon
                            className={`${classes[rate - 1]}`}
                            size={size}
                            fillColor={(rate <= rating || rate <= ratingHover) ? "#ffce6c" : "#FFF"}
                            strokeColor={(rate <= rating || rate <= ratingHover) ? "#ffce6c" : "#B3BDC9"}
                            gradient={(rate <= rating || rate <= ratingHover) ? "1" : "2"}
                        />
                    }
                    {
                        [1, 5].includes(rate) && <StarLabel className={rateClasses[rate - 1]}>
                            {
                                rate === 1 && t`v2_feedback.rating.star_label.bad`
                            }
                            {
                                rate === 5 && t`v2_feedback.rating.star_label.good`
                            }
                        </StarLabel>
                    }
                </Star>
            );
        });
    };

    render() {
        const { comment, maxCount, answer, rating, showIcons } = this.state;

        return (
            <AnimationSpan>
                <FormContainer>
                    <H1 className="text-center">{t`v2_feedback.rating.page_title`}</H1>
                    <FeedbackSubheaderContainer>
                        {
                            !showIcons ?
                                <Subheader
                                    className="stars-label"
                                    dangerouslySetInnerHTML={{
                                        __html: t`v2_feedback.rating.page_description`
                                    }}
                                />
                                :
                                <Subheader className="icon-label">
                                    {
                                        [1, 2, 3].includes(rating) ?
                                            <React.Fragment>
                                                <SadIcon />
                                                <SubtitleLabel
                                                    dangerouslySetInnerHTML={{
                                                        __html: t`v2_feedback.rating.subtitle.bad`
                                                    }}
                                                />
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <HappyIcon />
                                                <SubtitleLabel
                                                    dangerouslySetInnerHTML={{
                                                        __html: t`v2_feedback.rating.subtitle.good`
                                                    }}
                                                />
                                            </React.Fragment>

                                    }
                                </Subheader>
                        }
                    </FeedbackSubheaderContainer>
                    {
                        !showIcons &&
                        <Row sm={12} md={12} lg={12}>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <StarsContainer>{this.renderStars()}</StarsContainer>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextareaContainer>
                                <ElementTitle>
                                    {!rating ? t`v2_feedback.rating.comment.label` : (
                                        [1, 2, 3].includes(rating) ?
                                            t`v2_feedback.rating.comment.label.bad`
                                            :
                                            t`v2_feedback.rating.comment.label.good`
                                    )}
                                </ElementTitle>
                                <Textarea
                                    value={comment}
                                    name="comment"
                                    onChange={this.handleChange}
                                    maxLength={maxCount}
                                    placeholder={t`v2_feedback.rating.comment.placeholder`}
                                    onFocus={(e) => {
                                        e.preventDefault();
                                        handleMobileInputFocus();
                                    }}
                                    onBlur={(e) => {
                                        e.preventDefault();
                                        handleMobileInputBlur();
                                    }}
                                />
                                <CircleContainer>
                                    <CircleProgressBar
                                        percentage={countTextPercentage(
                                            comment.length,
                                            maxCount
                                        )}
                                        strokeColor={"#36b56a"}
                                        backgroundColor={"#dfe3e9"}
                                        strokeWidth={15}
                                    />
                                </CircleContainer>
                            </TextareaContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <NewSelectField
                                label={t`common.title.about_us`}
                                errorText=""
                                className="select-mb-1"
                                selectProps={{
                                    placeholder: t`common.placeholder.select`,
                                    name: "answer",
                                    value: answer,
                                    clearable: false,
                                    searchable: false,
                                    options: this.getOptions(),
                                    onChange: (e) => {
                                        this.setState({ answer: e.value });
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </FormContainer>
                <Row sm={12} md={8} lg={8}>
                    <Col sm={12} md={12} lg={12}>
                        <StyledV2Button onClick={this.submitFeedback}>
                            {t`payment_page.button.submit`}
                        </StyledV2Button>
                    </Col>
                </Row>
            </AnimationSpan>
        );
    }
}

function mapStateToProps(state) {
    return {
        aboutUs: state.about_us,
        trans: state.translations,
        postedClaim: state.v2_posted_claim,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAboutUs, sendFeedback, hideModal, logKibana }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImprovePage);
