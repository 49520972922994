import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import t from '../../shared/translations'
import { Col } from '../../shared/components/V2/Layout'
import { V2Button } from '../../shared/components/V2/V2Buttons'
import { tablet, mobile } from '../../shared/components/helpers/styled_queries'
import { BlockCircle, Checkbox } from '../../shared/components/svgIcons'

const InputCol = styled(Col)`
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    label {
        font-family: 'Noto sans';
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        color: #7f8fa4;
        letter-spacing: normal;
        margin-bottom: 8px;

        span {
            &.required {
                color: #fe6050;
            }
        }
    }
`

const SendCodeBtn = styled(V2Button)`
    width: fit-content;
    // border-color: #32b86a;
    color: #FFFFFF;

    background: #149954;
    border-radius: 16px;

    &:hover {
        // color: #32b86a;
    }

    svg {
        margin-left: 8px;
        vertical-align: middle;
    }

    ${tablet`
        width: 40%;
    `}

    ${mobile`
        width: 100%;
    `}

    ${({ disabled }) => disabled && 'background-color: #f3f5f7;'}
    ${({ disabled }) => disabled && 'border-color: unset;'}
    color: ${({ disabled }) => disabled && '#c7cdd4'};
    
`

const VerifiedContainer = styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    border-radius: 16px;
    background-color: #ecf7f1;
    color: #149954;
`

const VerifiedText = styled.div`
    margin-left: 6px;
    color: #36b56a;
    font-weight: 600;
    font-size: 16px;
`

const ResendCode = styled.span`
    text-decoration: underline;
    margin-left: 6px;

    &:hover {
        cursor: pointer;
        color: #36b56a;
    }
`

const SubmitVerificationTxt = styled.span`
    color: #36b56a;
    font-weight: 600;
    font-size: 14px;

    &:hover {
        cursor: pointer;
    }
`

const Input = styled.input`
    display: relative;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-family: 'Noto sans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: normal;
    color: #353d51;
    padding: 12px 16px;

    border: 1px solid #cbd3df;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 32px;

    &:hover,
    &:active,
    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
        box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
    }

    &:disabled,
    &.disabled {
        background: #f2f5f9;
        color: #7f8fa4;
        border: 1px solid #f2f5f9;
        font-weight: 600;
        opacity: 1;
        -webkit-text-fill-color: #7f8fa4;

        &:hover {
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &.warn {
        border: 1px solid #ff713b;
    }

    &.danger {
        border: 1px solid #fe6050;
    }

    &::placeholder {
        color: #aaa;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
`

class PhoneVerificationField extends React.Component {
    render() {
        const {
            isPhoneValid,
            isCodeSent,
            onSendCode,
            verificationCode,
            isCodeValid,
            onResendClick,
            onVerificationCodeChange,
            onVerificationCodeSubmit,
            isVerificationSubmitClicked,
            isResendButtonActive,
        } = this.props

        if (
            isVerificationSubmitClicked &&
            isCodeValid &&
            isCodeSent &&
            !!verificationCode
        ) {
            return (
                <VerifiedContainer>
                    <Content>
                        <Checkbox />
                        <VerifiedText>
                            {t`phone.verification.verified`}
                        </VerifiedText>
                    </Content>
                </VerifiedContainer>
            )
        }

        if (!isCodeSent) {
            return (
                <InputCol xs={12} sm={8} lg={6}>
                    <label>
                        {t`phone.verification.sms.code`}
                        <span className="required"> *</span>
                    </label>
                    <SendCodeBtn
                        disabled={!isPhoneValid}
                        type="borderBtn"
                        onClick={() => onSendCode()}
                    >
                        {t`phone.verification.send.code`}
                    </SendCodeBtn>
                </InputCol>
            )
        }

        if (isPhoneValid && isCodeSent) {
            return (
                <InputCol xs={12} md={12} lg={12}>
                    <label>
                        {t`phone.verification.sms.code`}
                        <span className="required"> *</span>
                        {isResendButtonActive && (
                            <ResendCode onClick={() => onResendClick()}>
                                {t`phone.verification.resend.code`}
                            </ResendCode>
                        )}
                    </label>
                    <form>
                        <Input
                            id="one-time-code"
                            inputmode="numeric"
                            autocomplete="one-time-code"
                            required
                            onChange={e =>
                                onVerificationCodeChange(e.target.value)
                            }
                            value={verificationCode}
                        />
                        {!!BlockCircle && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '56%',
                                    right: '24px',
                                }}
                            >
                                {isCodeValid ? (
                                    <SubmitVerificationTxt
                                        type="submit"
                                        onClick={() =>
                                            onVerificationCodeSubmit(
                                                verificationCode
                                            )
                                        }
                                    >
                                        {t`phone.verification.submit`}
                                    </SubmitVerificationTxt>
                                ) : (
                                    <BlockCircle height={18} width={18} />
                                )}
                            </div>
                        )}
                    </form>
                </InputCol>
            )
        }

        return null
    }
}

PhoneVerificationField.propTypes = {
    onSendCode: PropTypes.func.isRequired,
    onResendClick: PropTypes.func.isRequired,
    onVerificationCodeChange: PropTypes.func.isRequired,
    onVerificationCodeSubmit: PropTypes.func.isRequired,
    verificationCode: PropTypes.string,
    isPhoneValid: PropTypes.bool,
    isCodeSent: PropTypes.bool,
    isCodeValid: PropTypes.bool,
    isVerificationSubmitClicked: PropTypes.bool.isRequired,
    isResendButtonActive: PropTypes.bool.isRequired,
}

PhoneVerificationField.defaultProps = {
    isPhoneValid: false,
    isCodeSent: false,
    verificationCode: '',
    isCodeValid: true,
}

export default PhoneVerificationField
