import React from "react";
import _get from "lodash/get";
import t from "../../../../shared/translations";
import ErrorBox from "../../components/ErrorsBox";
import {
    Row,
    Col,
} from "../../../../shared/components/V2/Layout";
import {
    StyledLink,
    ErrorText,
    StyledInfoBox,
    IbanRowContainer,
} from "./paymentStyles";

export const IbanInfoBox = ({ isIban, isMobile }) => (
    !isIban ? null : (
        <IbanRowContainer isMobile={!!isMobile}>
            <Col>
                <StyledInfoBox>
                    <span dangerouslySetInnerHTML={{ __html: t`payment_page.iban_info_box` }} />
                </StyledInfoBox>
            </Col>
        </IbanRowContainer>
    )
);

export const InvalidCountryRedirect = ({ isInvalidCountry, user }) => {
    const userCountryTitle = _get(user, "country.title", "");
    const countriesErrText = t`payment_page.countries.dont.match`.replace(
        "%COUNTRY%",
        `<span class="bold">${userCountryTitle}</span>`
    );

    return (
        !isInvalidCountry ? null : (
            <Row>
                <Col lg={12}>
                    <ErrorBox noTitle={true}>
                        <ErrorText>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `${t`common.errors.list_title`} <br /> ${countriesErrText}`,
                                }}
                            />
                            <br />
                            <br />
                            <Row id="error-list-box">
                                <StyledLink to={`/profile?p=payment`}>{t`menu.edit_profile`}</StyledLink>
                            </Row>
                        </ErrorText>
                    </ErrorBox>
                </Col>
            </Row>
        )
    )
};