import React from "react";

export const CompletedTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#32B86A" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M15.194 18.417l5.681-6.126a1 1 0 1 1 1.467 1.36L15.99 20.5a.997.997 0 0 1-1.402.063l-4.049-3.649a1 1 0 1 1 1.339-1.485l3.316 2.988z"
            />
        </g>
    </svg>
);

export const ValidatingTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#FFB620" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M20.922 8.875c.195 0 .361.068.498.205a.678.678 0 0 1 .205.498v.469a.678.678 0 0 1-.205.498.678.678 0 0 1-.498.205c0 1.309-.332 2.485-.996 3.53-.664 1.045-1.514 1.743-2.55 2.095 1.036.352 1.886 1.05 2.55 2.095.664 1.045.996 2.221.996 3.53.195 0 .361.068.498.205a.678.678 0 0 1 .205.498v.469a.678.678 0 0 1-.205.498.678.678 0 0 1-.498.205h-9.844a.678.678 0 0 1-.498-.205.678.678 0 0 1-.205-.498v-.469c0-.195.068-.361.205-.498a.678.678 0 0 1 .498-.205c0-1.309.332-2.485.996-3.53.664-1.045 1.514-1.743 2.549-2.095-1.035-.352-1.885-1.05-2.549-2.095-.664-1.045-.996-2.221-.996-3.53a.678.678 0 0 1-.498-.205.678.678 0 0 1-.205-.498v-.469c0-.195.068-.361.205-.498a.678.678 0 0 1 .498-.205h9.844zm-2.197 11.25c-.254-.703-.625-1.27-1.114-1.7-.488-.429-1.025-.644-1.611-.644-.586 0-1.123.215-1.611.645-.489.43-.86.996-1.114 1.699h5.45zm0-7.5c.214-.586.322-1.21.322-1.875h-6.094c0 .664.108 1.29.322 1.875h5.45z"
            />
        </g>
    </svg>
);

export const LegalTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#5aadaa" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M14.125 18.719c0 .43-.166.82-.498 1.172-.332.351-.786.634-1.362.85a5.368 5.368 0 0 1-1.89.322 5.368 5.368 0 0 1-1.89-.323c-.576-.215-1.03-.498-1.362-.85-.332-.35-.498-.742-.498-1.171v-.03c0-.156.049-.346.146-.57.098-.225.44-.924 1.026-2.096l1.318-2.636c.176-.332.43-.557.762-.674.332-.117.664-.117.996 0 .332.117.586.342.762.674l1.347 2.724c.567 1.114.899 1.778.996 1.993.098.214.147.41.147.585v.03zm-3.75-4.688l-2.11 4.219h4.22l-2.11-4.219zm15 4.688c0 .43-.166.82-.498 1.172-.332.351-.786.634-1.362.85a5.368 5.368 0 0 1-1.89.322 5.368 5.368 0 0 1-1.89-.323c-.576-.215-1.03-.498-1.362-.85-.332-.35-.498-.742-.498-1.171v-.03c0-.156.049-.346.146-.57.098-.225.44-.924 1.026-2.096l1.318-2.636c.176-.332.43-.557.762-.674.332-.117.664-.117.996 0 .332.117.586.342.762.674l1.347 2.724c.567 1.114.899 1.778.996 1.993.098.214.147.41.147.585v.03zm-5.86-.469h4.22l-2.11-4.219-2.11 4.219zM22.095 22c.136 0 .249.044.337.132.088.088.131.2.131.337v.937a.456.456 0 0 1-.131.337.456.456 0 0 1-.337.132H9.906a.456.456 0 0 1-.337-.132.456.456 0 0 1-.132-.337v-.937c0-.137.044-.25.132-.337A.456.456 0 0 1 9.906 22h5.156v-8.643a2.346 2.346 0 0 1-1.348-1.67H9.907a.456.456 0 0 1-.337-.131.456.456 0 0 1-.132-.337v-.938c0-.136.044-.249.132-.337a.456.456 0 0 1 .337-.131h4.219c.234-.293.513-.523.835-.689A2.238 2.238 0 0 1 16 8.875c.371 0 .718.083 1.04.25.322.165.6.395.835.688h4.219c.136 0 .249.043.337.131.088.088.131.2.131.337v.938a.456.456 0 0 1-.131.337.456.456 0 0 1-.337.132h-3.809a2.346 2.346 0 0 1-1.348 1.67V22h5.157z"
            />
        </g>
    </svg>
);

export const PostTrialTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#5aadaa" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M9.437 22.469c0-.137.044-.25.132-.337A.456.456 0 0 1 9.906 22h5.156v-8.643a2.113 2.113 0 0 1-.776-.542 2.18 2.18 0 0 1-.483-.835l-3.867-1.289a.576.576 0 0 1-.293-.249c-.059-.107-.059-.23 0-.366l.293-.879a.444.444 0 0 1 .234-.263.444.444 0 0 1 .351-.03l3.457 1.143c.196-.352.474-.635.835-.85A2.28 2.28 0 0 1 16 8.875c.645 0 1.196.23 1.655.689.46.458.689 1.01.689 1.655l-.03.293 3.75 1.26c.137.039.235.117.293.234a.374.374 0 0 1 0 .351l-.293.909a.444.444 0 0 1-.234.263.444.444 0 0 1-.352.03l-4.16-1.407a2.362 2.362 0 0 1-.38.205v10.05a.456.456 0 0 1-.132.336.456.456 0 0 1-.337.132H9.906a.456.456 0 0 1-.337-.132.456.456 0 0 1-.132-.337v-.937zM6.625 17.78v-.029c0-.176.049-.371.146-.586.098-.215.43-.879.997-1.992l1.347-2.725c.176-.332.43-.556.762-.674.332-.117.664-.117.996 0 .332.118.586.342.762.674l1.318 2.637c.586 1.172.928 1.87 1.025 2.095.098.224.147.415.147.571v.03c0 .429-.166.82-.498 1.171-.332.352-.786.635-1.362.85a5.368 5.368 0 0 1-1.89.322 5.368 5.368 0 0 1-1.89-.322c-.576-.215-1.03-.498-1.362-.85-.332-.351-.498-.742-.498-1.172zm1.64-.468h4.22l-2.11-4.22-2.11 4.22zm9.61 4.218v-.029c0-.176.049-.371.146-.586.098-.215.43-.879.997-1.992l1.347-2.725c.176-.332.43-.556.762-.674.332-.117.664-.117.996 0 .332.118.586.342.762.674l1.318 2.637c.586 1.172.928 1.87 1.025 2.095.098.224.147.415.147.571v.03c0 .429-.166.82-.498 1.171-.332.352-.786.635-1.362.85a5.368 5.368 0 0 1-1.89.322 5.368 5.368 0 0 1-1.89-.322c-.576-.215-1.03-.498-1.362-.85-.332-.351-.498-.742-.498-1.172zm1.64-.468h4.22l-2.11-4.22-2.11 4.22z"
            />
        </g>
    </svg>
);

export const WaitingForReviewTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#FFB620" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M16 9.11c1.309 0 2.52.327 3.633.98a7.342 7.342 0 0 1 2.651 2.652 7.043 7.043 0 0 1 .982 3.633c0 1.309-.328 2.52-.982 3.633a7.342 7.342 0 0 1-2.651 2.651 7.043 7.043 0 0 1-3.633.982 7.043 7.043 0 0 1-3.633-.982 7.342 7.342 0 0 1-2.651-2.651 7.043 7.043 0 0 1-.982-3.633c0-1.309.328-2.52.982-3.633a7.342 7.342 0 0 1 2.651-2.651A7.043 7.043 0 0 1 16 9.109zm1.67 10.253a.317.317 0 0 0 .264.059.375.375 0 0 0 .234-.147l.82-1.113a.364.364 0 0 0 .073-.264.32.32 0 0 0-.131-.234l-1.875-1.348v-4.043a.339.339 0 0 0-.103-.249.339.339 0 0 0-.249-.102h-1.406a.339.339 0 0 0-.25.102.339.339 0 0 0-.102.25v4.921a.36.36 0 0 0 .147.293l2.578 1.875z"
            />
        </g>
    </svg>
);

export const ApprovedTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#32b56a" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M21.625 8.875c.508 0 .947.186 1.318.557.371.37.557.81.557 1.318v8.438c0 .507-.186.947-.557 1.318-.37.371-.81.557-1.318.557h-4.219l-3.662 2.753a.333.333 0 0 1-.366.015.33.33 0 0 1-.19-.308v-2.46h-2.813c-.508 0-.947-.186-1.318-.557a1.802 1.802 0 0 1-.557-1.318V10.75c0-.508.186-.947.557-1.318.37-.371.81-.557 1.318-.557h11.25zM19.34 13.68a.298.298 0 0 0 .088-.22c0-.088-.03-.17-.088-.249l-.762-.762a.298.298 0 0 0-.22-.088.298.298 0 0 0-.22.088l-2.841 2.842-1.23-1.26a.408.408 0 0 0-.25-.088.298.298 0 0 0-.22.088l-.761.762a.298.298 0 0 0-.088.22c0 .088.03.16.088.22l2.197 2.255a.408.408 0 0 0 .25.088c.087 0 .16-.03.219-.088l3.838-3.808z"
            />
        </g>
    </svg>
);

export const CaaAdrTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#5aadaa" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M18.607 13.563h-5.185c.215-1.329.557-2.403 1.025-3.223.469-.82.987-1.23 1.553-1.23s1.084.41 1.553 1.23c.468.82.82 1.894 1.054 3.223zm-5.42 2.812c0-.625.03-1.25.088-1.875h5.45a20.077 20.077 0 0 1 0 3.75h-5.45a20.077 20.077 0 0 1-.088-1.875zm9.522-2.812h-3.164c-.293-1.797-.781-3.184-1.465-4.16a7.176 7.176 0 0 1 2.769 1.567 7.416 7.416 0 0 1 1.86 2.593zm-8.79-4.16c-.702.976-1.19 2.363-1.464 4.16H9.291a7.416 7.416 0 0 1 1.86-2.593 7.176 7.176 0 0 1 2.769-1.568zm9.083 5.097c.176.625.264 1.25.264 1.875s-.088 1.25-.264 1.875h-3.34c.059-.645.088-1.27.088-1.875s-.03-1.23-.088-1.875h3.34zM12.25 16.375c0 .586.03 1.21.088 1.875h-3.34a7.114 7.114 0 0 1-.264-1.875c0-.605.088-1.23.264-1.875h3.34c-.059.645-.088 1.27-.088 1.875zm1.172 2.813h5.156c-.215 1.328-.557 2.402-1.025 3.222-.469.82-.987 1.23-1.553 1.23s-1.084-.41-1.553-1.23c-.468-.82-.81-1.894-1.025-3.222zm4.658 4.16c.703-.977 1.191-2.364 1.465-4.16h3.164a7.416 7.416 0 0 1-1.86 2.592 7.176 7.176 0 0 1-2.769 1.568zm-8.789-4.16h3.164c.293 1.796.781 3.183 1.465 4.16a7.176 7.176 0 0 1-2.769-1.568 7.416 7.416 0 0 1-1.86-2.592z"
            />
        </g>
    </svg>
);

export const TransferTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#32b56a" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M8.5 13.328c0-.195.068-.361.205-.498a.678.678 0 0 1 .498-.205H19.75v-1.406c0-.196.068-.362.205-.498a.678.678 0 0 1 .498-.205c.195 0 .361.068.498.205l2.344 2.343a.678.678 0 0 1 .205.499.678.678 0 0 1-.205.498l-2.344 2.343a.678.678 0 0 1-.498.205.678.678 0 0 1-.498-.205.678.678 0 0 1-.205-.498V14.5H9.203a.678.678 0 0 1-.498-.205.678.678 0 0 1-.205-.498v-.469zm14.297 4.922c.195 0 .361.068.498.205a.678.678 0 0 1 .205.498v.469a.678.678 0 0 1-.205.498.678.678 0 0 1-.498.205H12.25v1.406a.678.678 0 0 1-.205.498.678.678 0 0 1-.498.205.678.678 0 0 1-.498-.205l-2.344-2.343a.678.678 0 0 1-.205-.498c0-.196.068-.362.205-.499l2.344-2.343a.678.678 0 0 1 .498-.205c.195 0 .361.068.498.205a.678.678 0 0 1 .205.498v1.406h10.547z"
            />
        </g>
    </svg>
);

export const RejectedTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#F16464" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M17.963 16.375l2.93 2.93c.175.175.263.39.263.644a.978.978 0 0 1-.263.674l-.645.645a.978.978 0 0 1-.674.263.877.877 0 0 1-.644-.263L16 18.338l-2.93 2.93a.877.877 0 0 1-.644.263.978.978 0 0 1-.674-.263l-.645-.645a.978.978 0 0 1-.263-.674c0-.254.088-.469.263-.644l2.93-2.93-2.93-2.93a.877.877 0 0 1-.263-.644c0-.254.088-.479.263-.674l.645-.645a.978.978 0 0 1 .674-.263c.254 0 .469.088.644.263l2.93 2.93 2.93-2.93a.877.877 0 0 1 .644-.263c.254 0 .479.088.674.263l.645.645c.175.195.263.42.263.674a.877.877 0 0 1-.263.644l-2.93 2.93z"
            />
        </g>
    </svg>
);

export const DeptorTimelineStatus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g fill="none" fillRule="evenodd">
            <circle cx="16" cy="16" r="16" fill="#F16464" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M21.625 8.875c.527 0 .972.18 1.333.542.361.361.542.806.542 1.333v8.438c0 .527-.18.971-.542 1.333a1.812 1.812 0 0 1-1.333.542h-4.219l-3.662 2.753a.333.333 0 0 1-.366.015.33.33 0 0 1-.19-.308v-2.46h-2.813c-.527 0-.972-.181-1.333-.542a1.812 1.812 0 0 1-.542-1.333V10.75c0-.527.18-.972.542-1.333a1.812 1.812 0 0 1 1.333-.542h11.25zm-4.619 4.032c.323 0 .66.074 1.011.224l.523-1.314a3.701 3.701 0 0 0-1.534-.334c-.738 0-1.344.194-1.817.582-.473.388-.784.956-.934 1.703h-.65v.9h.567l-.009.19v.097l.009.092h-.567v.9h.686c.137.69.439 1.218.905 1.587.466.37 1.07.554 1.81.554.554 0 .98-.08 1.28-.242v-1.42a2.792 2.792 0 0 1-1.17.242c-.665 0-1.046-.24-1.142-.72h1.16v-.901h-1.261v.004l-.01-.193v-.062l.01-.127h1.55v-.9h-1.485c.036-.262.14-.47.315-.627.174-.157.425-.235.753-.235z"
            />
        </g>
    </svg>
);
