import { browserHistory } from 'react-router'
import axios from 'axios'
import { saveAs } from 'file-saver'
import _get from 'lodash/get'
import {
    loadScreenOff,
    loadScreenOn,
    getConfigParamValue,
    dataURItoBlob,
    loginPageHide,
    setStorage,
    getStorage,
    removeStorage,
    getApiClient,
    getStatus,
    deleteAllCookies,
    getLanguagesArray,
    getAdblockGaStatus,
    appendAnalyticsCookie,
    triggerAffiliatePixel,
    deleteAffiliateCookies,
    getCookie,
    isValidEmail,
    mapLocale,
    isFeatureEnabled,
    isProduction,
} from '../../main'
import store from '../services/store'
import { globalInjectBackground } from '../../App'
import {
    getUserClaims,
    getUserData,
    setClaimErrors,
    setSubmitedClaimData,
} from './index'
import { actionTitles, oopsUrl, featureToggles } from '../constants'
import envConfig from '../../env.json'
import { logRaw, logLevel } from '../services/logger'
import {
    CLAIMABLE_AMOUNT,
    AUTH_ERROR,
    AUTH_USER_EXTERNAL,
    REMOVE_ERROR,
    AUTH_USER,
    UNAUTH_USER,
    USER,
    COUNTRIES,
    AIRPORTS,
    AIRLINES,
    STOPS,
    SUCCESS_MESSAGE,
    RESET_MESSAGE,
    CLAIM,
    TRANSLATIONS,
    DEFAULT_TRANSLATIONS,
    LANGUAGE,
    TOTAL_KM,
    PAYOUT_PLANS,
    ABOUT_US,
    PASSENGER_NAME,
    DUPLICATE_CLAIM,
    DOMAIN_INFO,
    WEB_LANGUAGES,
    USER_AGREEMENT,
    DEFAULT_WEB_LANGUAGE,
    CONFIG,
    THEME_CONFIG,
    CP_ACTIONS,
    SET_LOADING_TEXT,
    SMTP_VALIDATION,
    REASON_COEFFICIENTS,
    BRANDED_GDPR,
    CURRENCIES,
    SET_ID_ASSETS,
    SUPPORTED_POSTCODES,
    SMTP_PASSENGER_VALIDATION,
    V2_ACTIONS,
    USER_RELATED_CLAIMS,
    USER_CLAIMS,
    SET_LANDING_PARAMETERS,
} from './actionTypes'

/* global Raven */

/// USER FUNCTIONS ///

export function setLoadingText(type) {
    return {
        type: SET_LOADING_TEXT,
        payload: type,
    }
}

export function clearAuthErrors() {
    return function(dispatch) {
        dispatch({
            type: REMOVE_ERROR,
        })
    }
}

export function authUserExternal() {
    return function(dispatch) {
        dispatch({
            type: AUTH_USER_EXTERNAL,
        })
    }
}

export function setNewPass(pass, userId, token) {
    const url = '/api/password-change'
    const apiClient = getApiClient()
    let fd = new FormData()

    fd.append('userId', userId)
    fd.append('token', token)
    fd.append('password', pass)

    return function(dispatch) {
        apiClient
            .post(url, fd)
            .then(response => {
                loadScreenOff('login-page')
                browserHistory.replace('/new-password-success')
            })
            .catch(error => {
                loadScreenOff()
                const errorMessage = _get(
                    error,
                    'response.data.payload.error',
                    null
                )
                dispatch({
                    type: AUTH_ERROR,
                    payload: errorMessage,
                })
            })
    }
}

export function getTotalKm(data) {
    const url = '/api/airports-distance'
    const apiClient = getApiClient()

    return function(dispatch) {
        apiClient
            .post(url, data)
            .then(response => {
                dispatch({
                    type: TOTAL_KM,
                    payload: response.data,
                })
            })
            .catch(error => {
                Raven.captureException(
                    'GET AIRPORTS DISTANCE ERROR: ' + JSON.stringify(error)
                )
                console.log(error)
            })
    }
}

export function getAgreementCombinedInfo(passengerType, agreementType, id) {
    return function(dispatch) {
        if (passengerType === 'owner') {
            return dispatch(getOwnerInfo(id))
                .then(res => {
                    const { payload } = res
                    let birthdate = ''
                    if (payload.birthdate) {
                        birthdate = payload.birthdate.split('T')[0]
                    }
                    return dispatch(
                        getUserAgreement(
                            payload.id,
                            `${payload.name} ${payload.surname}`,
                            agreementType,
                            birthdate,
                            payload.address
                        )
                    )
                        .then(res => res)
                        .catch(err => console.log(err))
                })
                .catch(err => {
                    console.log(err)
                    browserHistory.push('/')
                })
        } else {
            return dispatch(getPassengerName(id))
                .then(res => {
                    let birthdate = ''
                    if (res.birthdate) {
                        birthdate = res.birthdate.split('T')[0]
                    }
                    return dispatch(
                        getUserAgreement(
                            res.claim.id,
                            `${res.name} ${res.surname}`,
                            agreementType,
                            birthdate,
                            res.passenger_address,
                            res.legal_guardian_full_name,
                            res.legal_guardian
                        )
                    )
                        .then(res => res)
                        .catch(err => console.log(err))
                })
                .catch(err => {
                    console.log(err)
                    browserHistory.push('/')
                })
        }
    }
}

export function getOwnerInfo(claimId) {
    const url = `/api/claim/has-signature/${claimId}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url)
            .then(response => {
                response.data.payload.claimId = claimId.split('-')[0]
                dispatch({
                    type: PASSENGER_NAME,
                    payload: response.data.payload,
                })
                return response.data
            })
            .catch(error => {
                console.log(error)
                loginPageHide()
            })
    }
}

export function getPassengerName(passengerId) {
    const url = `/api/passenger/${passengerId}`
    let apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url)
            .then(response => {
                dispatch({
                    type: PASSENGER_NAME,
                    payload: response.data,
                })
                return response.data
            })
            .catch(error => {
                console.log(error)
                loginPageHide()
            })
    }
}

export function getUserAgreements(claimId, fullName, birthDate, address) {
    const url = `/api/claim/get-agreements/${claimId}`
    const apiClient = getApiClient()
    let fd = new FormData()

    fd.append('fullName', fullName)

    if (birthDate) {
        fd.append('birthDate', birthDate)
    }

    if (address) {
        fd.append('address', address)
    }

    return function(dispatch) {
        return apiClient
            .post(url, fd)
            .then(response => {
                dispatch({
                    type: USER_AGREEMENT,
                    payload: response.data,
                })
                return response.data
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                loadScreenOff()
            })
    }
}

export function getUserAgreement(
    claimId,
    fullName,
    type,
    birthDate,
    address,
    legalGuardianFullName,
    legalGuardian
) {
    const url = `/api/claim/get-agreement/${claimId}/`
    const apiClient = getApiClient()
    let fd = new FormData()

    fd.append('fullName', fullName)
    fd.append('type', type)

    if (birthDate) {
        fd.append('birthDate', birthDate)
    }

    if (address) {
        fd.append('address', address)
    }

    if (legalGuardianFullName) {
        fd.append('legalGuardianFullName', legalGuardianFullName)
    }

    if (legalGuardian) {
        fd.append('legalGuardian', legalGuardian)
    }

    return function(dispatch) {
        return apiClient
            .post(url, fd)
            .then(response => {
                dispatch({
                    type: USER_AGREEMENT,
                    payload: response.data,
                })
                return response.data
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function downloadUserAgreement(
    claimId,
    fullName,
    type,
    birthDate,
    address
) {
    const url = `${
        envConfig[process.env.REACT_APP_ENV || 'development'].API_V2
    }/api/claim/get-agreement/${claimId}/download`
    let fd = new FormData()
    const claimCode = (claimId || '').substring(0, 8)
    const originalName = `signed_manually_${fullName}_assignment_form_${claimCode}.pdf`

    store.dispatch(
        logKibana(
            `download_agreement_click, claimId: ${claimId}`,
            '/claim-signature',
            'warning'
        )
    )

    fd.append('fullName', fullName)
    fd.append('type', type)

    if (birthDate) {
        fd.append('birthDate', birthDate)
    }

    if (address) {
        fd.append('address', address)
    }

    return function() {
        axios({
            method: 'post',
            url,
            data: fd,
            responseType: 'blob',
        }).then(response => {
            saveAs(response.data, originalName)
        })
    }
}

export function resetAgreement() {
    return function(dispatch) {
        dispatch({
            type: USER_AGREEMENT,
            payload: null,
        })
        dispatch({
            type: CLAIMABLE_AMOUNT,
            payload: null,
        })
    }
}

export function getAboutUs() {
    const url = `/api/about-us/answers%7D`
    const apiClient = getApiClient()

    return function(dispatch) {
        apiClient
            .get(url)
            .then(response => {
                dispatch({
                    type: ABOUT_US,
                    payload: response.data,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function postAboutUs(claimId, answerId) {
    const url = `/api/about-us/answer%7D`
    const apiClient = getApiClient()
    let fd = new FormData()
    fd.append('about_us[answer]', answerId)
    fd.append('about_us[claim]', claimId)

    return function(dispatch) {
        apiClient
            .post(url, fd)
            .then(response => {
                dispatch({
                    type: TOTAL_KM,
                    payload: response.data,
                })
            })
            .catch(error => {
                Raven.captureException(
                    'POST ABOUT US ERROR: ' + JSON.stringify(error)
                )
            })
    }
}

export function logout() {
    return function(dispatch) {
        dispatch({ type: UNAUTH_USER })
        dispatch({
            type: USER,
            payload: null,
        })
        dispatch({
            type: V2_ACTIONS.RESET_V2_CLAIM_USER,
        })
        dispatch({
            type: USER_CLAIMS,
            payload: null,
        })
        dispatch({
            type: USER_RELATED_CLAIMS,
            payload: null,
        })
        dispatch({
            type: V2_ACTIONS.DRAFTS_ALL,
            payload: [],
        })
        removeStorage('login')
        removeStorage('token')
        deleteAllCookies()
    }
}

/////////////////////

/// DATA FUNCTIONS ///

export function getLanguages() {
    const url = `/api/languages`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient.get(url).then(({ data }) => {
            dispatch({
                type: WEB_LANGUAGES,
                payload: data,
            })
            return data
        })
    }
}

export function getCurrencies(filter = '') {
    const url = `/api/currencies/0/0?currency_filter[keyword]=${filter}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient.get(url).then(response => {
            const payload = _get(response, 'data.payload', [])
            const primaryCurrencies = [...payload].filter(
                c => c.isPrimaryCurrency === 'true'
            )
            const secondaryCurrencies = [...payload].filter(
                c => c.isPrimaryCurrency !== 'true'
            )
            if (!filter) {
                primaryCurrencies.push({
                    grayedOutLabel: true,
                    currency: 'EUR',
                    id: 'EUR',
                })
            }
            const fullPayload = primaryCurrencies.concat(secondaryCurrencies)
            const filteredData = fullPayload.filter(c => !!c.currency)
            dispatch({
                type: CURRENCIES,
                payload: filteredData,
            })
            return filteredData
        })
    }
}

export function getPayoutPlans(amount) {
    const url = `/api/claim/payouts`
    const apiClient = getApiClient()
    let fd = new FormData()

    fd.append('amount', amount)

    return function(dispatch) {
        apiClient
            .post(url, fd)
            .then(response => {
                dispatch({
                    type: PAYOUT_PLANS,
                    payload: response.data.payload,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function setPayoutPlan(amount, payout_id, claim_id) {
    const url = `/api/claim/payout`
    const apiClient = getApiClient()
    let fd = new FormData()

    fd.append('claim_payout[amount]', amount)
    fd.append('claim_payout[claim]', claim_id)
    fd.append('claim_payout[payout]', payout_id)

    return function(dispatch) {
        apiClient
            .post(url, fd)
            .then(response => {
                browserHistory.push('/thank-you')
                loadScreenOff()
            })
            .catch(error => {
                Raven.setUserContext({
                    claimId: claim_id,
                    payout: payout_id,
                    amount,
                })
                Raven.captureException(
                    'FAILED TO SUBMIT PAYOUT PLAN: ' + JSON.stringify(error)
                )
                browserHistory.push('/thank-you')
                loadScreenOff()
            })
    }
}

export function getClaimReasons(id, stepNr, resetLast) {
    const url = `/api/claim/details/reason/${id}`

    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url)
            .then(response => {
                if (resetLast) {
                    dispatch({
                        type: 'REASON_4',
                        payload: null,
                    })
                }
                dispatch({
                    type: `REASON_${stepNr}`,
                    payload: response.data.payload,
                })

                return response.data.payload
            })
            .catch(error => {
                const statusCode = getStatus(error)
                if (statusCode && statusCode !== 404) {
                    Raven.setUserContext({
                        reasonId: id,
                        stepNumber: stepNr,
                    })
                    Raven.captureException(
                        'FAILED TO GET CLAIM REASON: ' + JSON.stringify(error)
                    )
                }
            })
    }
}

export const setClaimReasons = (id, payload) => dispatch => {
    dispatch({
        type: `REASON_${id}`,
        payload,
    })
}

export function resetReasons() {
    return function(dispatch) {
        dispatch({
            type: `REASON_2`,
            payload: undefined,
        })
        dispatch({
            type: `REASON_3`,
            payload: undefined,
        })
        dispatch({
            type: `REASON_4`,
            payload: undefined,
        })
    }
}

export function getCountries(lang, id = '') {
    const url = `/api/countries/1/300`
    const apiClient = getApiClient()

    if (!lang) lang = getStorage('lang') || 'en'

    return function(dispatch) {
        apiClient
            .get(url, { headers: { 'Accept-language': lang } })
            .then(response => {
                dispatch({
                    type: COUNTRIES,
                    payload: response.data,
                })
            })
            .catch(error => {
                Raven.captureException(
                    `GET COUNTRIES ERROR ${id}_${isProduction()}: ` +
                        JSON.stringify(error)
                )
                console.log(error)
            })
    }
}

export const getMarketingSubscriptionInfo = token => {
    const apiClient = getApiClient()
    const url = `/api/marketing/subscription-info/` + token

    return apiClient
        .get(url, null, actionTitles.GET_CONFIG)
        .then(resp => {
            return (resp || {}).data
        })
        .catch(e => {
            return false
        })
}

export const unsubscribeMarketingEmails = token => {
    const apiClient = getApiClient()
    const url = `/api/marketing/unsubscribe/` + token

    return apiClient
        .post(url, null, {
            baseURL: envConfig[process.env.REACT_APP_ENV || 'development'].API,
        })
        .then(resp => {
            return (resp || {}).data
        })
        .catch(e => {
            return false
        })
}

export function getConfig() {
    const url = `/api/configuration/1/200`
    let apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url, null, actionTitles.GET_CONFIG)
            .then(response => {
                const { payload } = response.data
                const cpActions = getConfigParamValue(
                    'cp_actions',
                    false,
                    payload
                )
                const supportedPostcodes = getConfigParamValue(
                    'supported_postcodes',
                    false,
                    payload
                )

                try {
                    let reasonCofs
                    reasonCofs = JSON.parse(
                        getConfigParamValue(
                            'reason_coefficients',
                            false,
                            payload
                        )
                    )
                    dispatch({
                        type: REASON_COEFFICIENTS,
                        payload: reasonCofs || {},
                    })
                } catch (e) {}

                dispatch({
                    type: CP_ACTIONS,
                    payload: (cpActions || '').split(','),
                })
                dispatch({
                    type: SUPPORTED_POSTCODES,
                    payload: (supportedPostcodes || '').split(','),
                })
                dispatch({
                    type: CONFIG,
                    payload,
                })

                return payload
            })
            .catch(error => {
                if (getStatus(error)) {
                    Raven.captureException(
                        'GET CONFIG ERROR: ' + JSON.stringify(error)
                    )
                    console.log(error)
                }
            })
    }
}

export function getAirports(query, name, id, options = { range: 0 }) {
    let url = `/api/airports/0/${options.range}?airport_filter`

    if (id) {
        url += `[id]=${id}`
    } else {
        url += `[keyword]=${query}`
    }

    const lang = getStorage('lang') || 'en'
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url, { headers: { 'Accept-language': lang } })
            .then(response => {
                if (id) {
                    dispatch({
                        type: SET_ID_ASSETS,
                        id: id,
                        payload:
                            response.data.payload && response.data.payload[0],
                    })
                } else {
                    dispatch({
                        type: AIRPORTS,
                        payload: response.data,
                    })
                    dispatch({
                        type: `${name}_LOADING`,
                        payload: false,
                    })
                }
                return response.data
            })
            .catch(error => {
                dispatch({
                    type: `${name}_LOADING`,
                    payload: false,
                })
            })
    }
}

export function getAirlines(query, isIata, id) {
    const reg = /([%])/g
    const filteredQuery = (query || '').replace(reg, '')

    let url = '/api/airlines/0/0?airline_api_filter'
    if (id) {
        url += `[id]=${id}`
    } else {
        url += `[${!!isIata ? 'iata' : 'keyword'}]=${filteredQuery}`
    }

    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url)
            .then(response => {
                if (id) {
                    dispatch({
                        type: SET_ID_ASSETS,
                        id: id,
                        payload:
                            response.data.payload && response.data.payload[0],
                    })
                } else {
                    dispatch({
                        type: AIRLINES,
                        payload: response.data,
                    })
                }

                return response.data.payload
            })
            .catch(error => {
                if (getStatus(error)) {
                    Raven.setUserContext({
                        query: query,
                        isIata: !!isIata,
                    })
                    Raven.captureException(
                        'FAILED TO GET AIRLINES: ' + JSON.stringify(error)
                    )
                    console.log(error)
                }
            })
    }
}

export function getTranslations(domain, language, type = TRANSLATIONS) {
    const url = `/api/translations/${domain}/${language}`
    const apiClient = getApiClient()
    const v2Translations = store.getState().v2_translations

    if (
        isFeatureEnabled(featureToggles.deprecatedKeysFeature) &&
        (language || '').toLowerCase() === 'en' &&
        Object.keys(v2Translations).length === 0
    ) {
        apiClient.get(`/api/translations/app/en`).then(response => {
            store.dispatch({
                type: V2_ACTIONS.V2_NEW_TRANSLATIONS,
                payload: response.data,
            })
        })
    }

    return function(dispatch) {
        let successHandler = response => {
            dispatch({
                type: type,
                payload: response.data,
            })
        }

        apiClient
            .get(url)
            .then(successHandler)
            .catch(error => {
                const statusCode = getStatus(error)
                if (statusCode && statusCode >= 500) {
                    Raven.captureException(
                        `GET TRANSLATIONS ${statusCode} ERROR. MINIMAL REDIRECT: ${JSON.stringify(
                            error
                        )}`
                    )
                    const isEnabledOopsRedirect = getConfigParamValue(
                        'oopsRedirect'
                    )
                    if (oopsUrl && isEnabledOopsRedirect !== '0') {
                        setTimeout(() => {
                            window.location.href = oopsUrl
                        }, 1000)
                    }
                    return
                } else {
                    if (statusCode) {
                        Raven.captureException(
                            'GET TRANSLATIONS FAILED' + JSON.stringify(error)
                        )
                    }
                }
            })
    }
}

export function getEnTrans(key) {
    const defaultTrans = store.getState().default_translations
    const trans = store.getState().translations
    const lang = getStorage('lang')

    if (!key) return

    if (lang === 'en' && !!trans[key]) {
        return trans[key]
    } else if (defaultTrans && defaultTrans[key]) {
        return defaultTrans[key]
    }

    return key
}

export function postClaimSuccessTransaction(
    data,
    dispatch,
    isV2,
    V2airlines = [],
    V2airports = [],
    V2clmAmt
) {
    const parsedAirlines = _get(store.getState().airlines, 'payload', [])
    const airlines = [...parsedAirlines, ...V2airlines]
    const airports = [
        ...(store.getState().airports.payload || []),
        ...V2airports,
    ]
    const clmAmt = store.getState().claimable_amt || V2clmAmt
    const reasonCoeffs = store.getState().reason_coefficients
    const origin = airports.filter(a => a.id === data.origin)[0]
    const destination = airports.filter(a => a.id === data.destination)[0]
    const carrier = airlines && airlines.filter(a => a.id === data.carrier)[0]
    let adjustedPrice = clmAmt * reasonCoeffs[data.reasonbyairlines]
    // eslint-disable-next-line
    dataLayer.push(
        {
            event: 'ecomm_event',
            transactionId: `${data.claimId}`, // Transaction ID - this is normally generated by your system.
            transactionAffiliation: 'skycop.com', // Affiliation or store name
            transactionTotal: `${clmAmt}`, // Grand Total
            transactionProducts: [
                {
                    sku: 'CLAIM', // SKU/code.
                    name: 'Claim', // Product name.
                    category: 'Claims', // Category or variation.
                    price: `${clmAmt / data.passengerLength}`, // Unit price.
                    quantity: `${data.passengerLength}`,
                },
            ],
        },
        {
            event: 'completedsign',
            emailSubmitted: `${data.userEmail}`,
            passengersegments: `${data.passengerLength}`,
            origin: origin && origin.title,
            destination: destination && destination.title,
            carrier: carrier && carrier.title,
            flightproblem: getEnTrans(data.flightproblem),
            delayedfor: getEnTrans(data.delayedfor),
            reasonbyairlines: getEnTrans(data.reasonbyairlines),
            adjustedPrice:
                typeof adjustedPrice === 'number'
                    ? adjustedPrice.toFixed(2)
                    : adjustedPrice,
        }
    )

    dispatch(
        logKibana(
            `${isV2 ? 'V2_' : ''}V2_transaction_did_create`,
            'POST /claim',
            'warning'
        )
    )
}

export function postUserClaim(
    data,
    userLanguage,
    files,
    aboutUs,
    passengerLength,
    isAuthorised,
    affiliatePixel
) {
    const url = `/api/claim`
    const apiClient = getApiClient()
    const getRavenContext = () => ({
        userEmail: data.get('claim[user][email]'),
        userPhone: data.get('claim[user][phone]'),
        airportArrival: data.get('claim[airportArrival]'),
        airportDeparture: data.get('claim[airportDeparture]'),
        price: data.get('claim[price]'),
        passengerAmount: passengerLength,
        filesLength: (files || []).length,
    })

    const appendData = (name, value) => data.append(name, value)
    appendAnalyticsCookie('GASession', 'sessionId', appendData)
    appendAnalyticsCookie('MediumCookie', 'medium', appendData)
    appendAnalyticsCookie('SourceCookie', 'source', appendData)
    appendAnalyticsCookie('KeywordCookie', 'keyword', appendData)
    appendAnalyticsCookie('CampaignCookie', 'campaign', appendData)

    const mediumCookie = getCookie('partner_medium')
    if (mediumCookie) {
        data.append('claim[metadata][medium]', mediumCookie)
    }

    return function(dispatch) {
        dispatch(
            logKibana('transaction_should_create', 'POST /claim', 'warning')
        )
        const successHandler = response => {
            const countries = _get(store.getState(), 'countries.payload', [])
            const userCountryID = data.get('claim[user][country]')
            const userCity = data.get('claim[user][city]')
            const userAddress = data.get('claim[user][address]')
            const userCountry = countries.filter(c => c.id === userCountryID)[0]
            const fullAddress = `${userAddress}, ${userCity}, ${
                userCountry ? userCountry.title : ''
            }`
            const clickId = affiliatePixel || getCookie('partner_tid1')
            const updatedClaim = {
                claimId: response.data.id,
                userName: response.data.user.name,
                userSurname: response.data.user.surname,
                userLanguage: userLanguage,
                userBirthdate: data.get('claim[user][birthdate]'),
                userAddress: fullAddress,
                passengerLength,
                flightproblem: data.get('claim[details][0][value]'),
                delayedfor: data.get('claim[details][1][value]'),
                reasonbyairlines:
                    data.get('claim[details][3][value]') ||
                    data.get('claim[details][2][value]'),
                carrier: data.get('claim[flights][0][airline]'),
                origin: data.get('claim[airportDeparture]'),
                destination: data.get('claim[airportArrival]'),
            }

            dispatch(setSubmitedClaimData({ claimId: updatedClaim.claimId }))
            dispatch({ type: CLAIM, payload: updatedClaim })
            dispatch(postAboutUs(response.data.id, aboutUs))

            if (isAuthorised) {
                dispatch(getUserClaims())
            }

            triggerAffiliatePixel(clickId, updatedClaim.claimId)
            deleteAffiliateCookies()
            postClaimSuccessTransaction(updatedClaim, dispatch)
            dispatch(
                logKibana(
                    'user_adblock_and_ga',
                    'POST /claim',
                    'warning',
                    getAdblockGaStatus()
                )
            )
            browserHistory.push('/completed-sign')
            loadScreenOff()
        }

        const errorsHandler = (error, stringError, initialText) => {
            const errorMessage = _get(error, 'response.data.error', null)
            const errorsMessage = _get(error, 'response.data.errors', null)
            const claimErrors = errorsMessage || errorMessage

            if (claimErrors) {
                const duplicateClaim = _get(
                    claimErrors,
                    'problemClaimFlight[0]',
                    null
                )
                const userErrors = _get(claimErrors, 'user', null)
                const flightsErrors = _get(claimErrors, 'flights', null)

                if (duplicateClaim) {
                    dispatch({
                        type: DUPLICATE_CLAIM,
                        payload: _get(
                            claimErrors,
                            'problemClaimFlight[1]',
                            null
                        ),
                    })
                    dispatch(
                        logKibana(
                            'transaction_create_duplicate_failed',
                            'POST /claim',
                            'warning',
                            stringError
                        )
                    )
                    browserHistory.push('/duplicate')
                } else if (userErrors || flightsErrors) {
                    dispatch(setClaimErrors(claimErrors))
                } else {
                    Raven.setUserContext(getRavenContext())
                    Raven.captureException(
                        `${initialText} FAILED DUPLICATE CHECK. /error Redirect: ${stringError}`
                    )
                    dispatch(
                        logKibana(
                            'transaction_create_failed',
                            'POST /claim',
                            'error',
                            stringError
                        )
                    )
                    browserHistory.push('/error?q=claim')
                }
            } else {
                Raven.setUserContext(getRavenContext())
                Raven.captureException(
                    `${initialText} /error Redirect. UNKNOWN ERROR MESSAGE: ${stringError}`
                )
                dispatch(
                    logKibana(
                        'transaction_create_failed',
                        'POST /claim',
                        'error',
                        stringError
                    )
                )
                browserHistory.push('/error?q=claim')
            }
        }

        apiClient
            .post(url, data)
            .then(successHandler)
            .catch(error => {
                loadScreenOff()
                let statusCode = getStatus(error)
                let stringError = JSON.stringify(error)
                let initialText = `POST CLAIM ERROR: ${statusCode}.`

                if (statusCode >= 500) {
                    const backupUrl =
                        envConfig[process.env.REACT_APP_ENV || 'development']
                            .API + url

                    Raven.setUserContext(getRavenContext())
                    Raven.captureException(
                        `${initialText} after 500 REPOSTING to Dashboard: ${stringError}`
                    )
                    apiClient
                        .post(backupUrl, data)
                        .then(successHandler)
                        .catch(err => {
                            statusCode = getStatus(err)
                            stringError = JSON.stringify(err)
                            initialText = `POST CLAIM ERROR: ${statusCode}.`

                            if (statusCode >= 500) {
                                Raven.setUserContext(getRavenContext())
                                Raven.captureException(
                                    `${initialText} Minimal Claim Form Redirect: ${stringError}`
                                )
                                dispatch(
                                    logKibana(
                                        'transaction_create_failed_minimal',
                                        'POST /claim',
                                        'error',
                                        stringError
                                    )
                                )

                                const isEnabledOopsRedirect = getConfigParamValue(
                                    'oopsRedirect'
                                )

                                if (oopsUrl && isEnabledOopsRedirect !== '0') {
                                    setTimeout(() => {
                                        window.location.href = oopsUrl
                                    }, 1000)
                                    return
                                }
                            }
                            errorsHandler(err, stringError, initialText)
                        })
                    return
                }
                errorsHandler(error, stringError, initialText)
            })
    }
}

export function uploadPassengerSignature(data, isLegal) {
    let url
    const apiClient = getApiClient()

    if (isLegal) {
        url = `/api/agreement-legal-passenger-signature`
    } else {
        url = `/api/agreement-passenger-signature`
    }

    return function(dispatch) {
        return apiClient
            .post(url, data)
            .then(() => {
                if (!isLegal) {
                    loadScreenOff('login-page')
                    browserHistory.push('/passenger-signature-completed')
                }
            })
            .catch(error => {
                const { response } = error
                if (
                    response &&
                    response.status === 400 &&
                    response.data === 'agreement.already_signed'
                ) {
                    return response
                } else {
                    try {
                        const dataArray = Object.fromEntries(data.entries())
                        const logData = [
                            { error },
                            { response },
                            { data: dataArray },
                        ]

                        logRaw(logLevel.warning, 'upload_passenger_signature', {
                            sUrl: `POST ${url}`,
                            sData: JSON.stringify(logData),
                        })
                    } catch (e) {}

                    Raven.setUserContext({
                        passengerId: data.get('claim_signature[passenger]'),
                    })
                    Raven.captureException(
                        'UPLOAD PASSENGER SIGNATURE ERROR: ' +
                            JSON.stringify(error)
                    )
                    console.log(error)
                    loadScreenOff()
                    loginPageHide()
                    browserHistory.push('/error?q=other')
                }
            })
    }
}

export const getUserDocument = (docId, originalName) => {
    const url = `${
        envConfig[process.env.REACT_APP_ENV || 'development'].API
    }/api/claim/document/${docId}`
    const authString = `Bearer ${getStorage('token')}`

    return function() {
        axios({
            method: 'get',
            url,
            headers: { Authorization: authString },
            responseType: 'blob',
        }).then(response => {
            saveAs(response.data, originalName)
        })
    }
}

// Deprecated
export function uploadUserDocument(files, claimId, noRedirect) {
    const url = `/api/account/add-claim-document/${claimId}`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        if (files) {
            return new Promise((resolve, reject) => {
                files.forEach((file, index) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = function() {
                        let blob = dataURItoBlob(reader.result)
                        let fd = new FormData()
                        fd.append('claim_document[file]', blob, file.name)
                        apiClient
                            .post(url, fd, {
                                headers: { Authorization: authString },
                            })
                            .then(response => {
                                if (index === files.length - 1) {
                                    if (!noRedirect) {
                                        browserHistory.push('/completed-sign')
                                    }
                                    resolve(response)
                                }
                            })
                            .catch(error => {
                                Raven.setUserContext({
                                    claimId: claimId,
                                    totalFiles: files.length,
                                    isFromClaimPage: !noRedirect,
                                })
                                Raven.captureException(
                                    'UPLOAD USER DOCUMENT ERROR: ' +
                                        JSON.stringify(error)
                                )
                                console.log(error)
                                reject(error)
                            })
                    }
                })
            })
        } else {
            if (!noRedirect) {
                browserHistory.push('/completed-sign')
            }
            return new Promise(resolve => resolve())
        }
    }
}

export function logCookiePreference(settings, value) {
    const url = `/api/log/cookies-preferences`
    const apiClient = getApiClient()
    let fd = new FormData()

    fd.append('settings', settings)
    fd.append('value', value)

    return function(dispatch) {
        apiClient
            .post(url, fd)
            .then(response => {
                return true
            })
            .catch(error => {
                Raven.captureException(
                    'LOG COOKIE PREFERENCES ERROR: ' + JSON.stringify(error)
                )
                return false
            })
    }
}

export function logKibana(
    message,
    endpoint,
    level = 'critical',
    exception = '',
    details = ''
) {
    const apiClient = getApiClient()
    const url = `/api/log`
    let fd = new FormData()
    fd.append('message', message)
    fd.append('level', level)
    fd.append('sEndpoint', endpoint)
    fd.append('sException', exception)
    fd.append('sDetails', details)

    return function() {
        apiClient
            .post(url, fd)
            .then(() => {
                return true
            })
            .catch(error => {
                Raven.captureException(
                    'LOG KIBANA ERROR: ' + JSON.stringify(error)
                )
                return false
            })
    }
}

/////////////////////

export function getInfoByDomain(domain) {
    const url = `/api/partner/${domain}`
    const apiClient = getApiClient()

    return function(dispatch) {
        apiClient
            .get(url)
            .then(response => {
                dispatch({
                    type: DOMAIN_INFO,
                    payload: response.data,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

/// HELPER FUNCTIONS ///

export function setLanguage(language, skipCheck) {
    const inputLanguage = language ? language.toLowerCase() : 'en'
    const supportedLangs = store.getState().web_languages
    const languageExists = (langs, currLang) =>
        getLanguagesArray(langs).filter(l => l.key === currLang).length === 1
    const checkValidLang = (langs, currLang) =>
        skipCheck || languageExists(langs, currLang) ? currLang : 'en'

    return function(dispatch) {
        const languageSettingHandler = lang => {
            const domain = 'web'
            dispatch(getTranslations(domain, lang))
            dispatch(getCountries(lang, 'LANGUAGE'))
            dispatch({
                type: LANGUAGE,
                payload: lang,
            })
            setStorage('lang', lang)
            if (window && typeof window.zE === 'function') {
                window.zE(
                    'webWidget',
                    'setLocale',
                    mapLocale(lang).toUpperCase()
                )
            }

            if (
                lang !== DEFAULT_WEB_LANGUAGE &&
                !store.getState().default_translations
            ) {
                dispatch(
                    getTranslations(
                        domain,
                        DEFAULT_WEB_LANGUAGE,
                        DEFAULT_TRANSLATIONS
                    )
                )
            }
        }

        if (inputLanguage !== 'en') {
            if (!supportedLangs) {
                loadScreenOn()
                dispatch(getLanguages())
                    .then(resp => {
                        languageSettingHandler(
                            checkValidLang(resp, inputLanguage)
                        )
                        loadScreenOff()
                    })
                    .catch(() => loadScreenOff())
            } else {
                languageSettingHandler(
                    checkValidLang(supportedLangs, inputLanguage)
                )
            }
        } else {
            languageSettingHandler(inputLanguage)
        }
    }
}

export function resetAirlines(payload) {
    return function(dispatch) {
        dispatch({
            type: AIRLINES,
            payload: {
                payload,
            },
        })
    }
}

export function resetCountries() {
    return function(dispatch) {
        dispatch({
            type: AIRLINES,
            payload: null,
        })
    }
}

export function setLandingParameters(payload) {
    return function(dispatch) {
        dispatch({
            type: SET_LANDING_PARAMETERS,
            payload,
        })
    }
}

export function updateStops(amount) {
    return function(dispatch) {
        dispatch({
            type: STOPS,
            payload: amount,
        })
    }
}

export function showSuccessMessage(message) {
    return function(dispatch) {
        dispatch({
            type: SUCCESS_MESSAGE,
            payload: message,
        })
    }
}

export function resetMessage() {
    return function(dispatch) {
        dispatch({
            type: RESET_MESSAGE,
        })
    }
}

export function updateClaim(claim) {
    return function(dispatch) {
        dispatch({
            type: CLAIM,
            payload: claim,
        })
    }
}

export function clearErrors() {
    return function(dispatch) {
        dispatch({ type: REMOVE_ERROR })
    }
}

export function generateUrlToken(response, redirect = false, message = '') {
    const apiClient = getApiClient()

    return function(dispatch) {
        apiClient
            .get('/oauth/v2/token', {
                params: {
                    client_id: response.data.public_key,
                    client_secret: response.data.secret_key,
                    grant_type: 'http://skycop/grants/api_key',
                    api_key: response.data.api_key,
                },
            })
            .then(response => {
                loadScreenOff()
                dispatch({ type: AUTH_USER })
                setStorage('login', true)
                setStorage('token', response.data.access_token)
                if (!redirect) dispatch(getUserData('true'))
                else dispatch(getUserData('false', redirect))
            })
            .catch(error => {
                Raven.captureException(
                    'GENERATE URL TOKEN ERROR: ' + JSON.stringify(error)
                )
                dispatch({ type: UNAUTH_USER })
                dispatch({
                    type: AUTH_ERROR,
                    payload: message,
                })
                removeStorage('login')
                removeStorage('token')
                loadScreenOff('login-page')
            })
    }
}

export function getFieldErrors(error) {
    let errors = []
    for (var key in error) {
        if (error.hasOwnProperty(key)) {
            if (typeof error[key] !== 'string') {
                for (var nKey in error[key]) {
                    errors = [...errors, error[key][nKey]]
                }
            } else {
                errors = [...errors, error[key]]
            }
        }
    }

    return errors
}

export function setThemeConfigOptions(cfg) {
    const defaultConfig = store.getState().propsTheme
    const mergedTheme = {
        colors: {
            ...defaultConfig.colors,
            ...cfg.colors,
        },
        logo: {
            ...defaultConfig.colors,
            ...cfg.logo,
        },
    }

    return function(dispatch) {
        dispatch({
            type: THEME_CONFIG,
            payload: mergedTheme,
        })
        globalInjectBackground(mergedTheme)
    }
}

var smtpDebounceTimeout
export function validateEmailSmtp(email, name) {
    if (smtpDebounceTimeout) {
        clearTimeout(smtpDebounceTimeout)
    }

    if (!email || !isValidEmail(email)) {
        return
    }

    const apiClient = getApiClient()
    const url = `/api/email/validate`
    const passSMTPS = store.getState().passenger_smtp

    let fd = new FormData()
    fd.append('email', email)

    smtpDebounceTimeout = setTimeout(() => {
        apiClient
            .post(url, fd, { timeout: 4000 })
            .then(resp => {
                if (!name) {
                    store.dispatch({
                        type: SMTP_VALIDATION,
                        payload: _get(resp, 'data.isValid', true),
                    })
                } else {
                    store.dispatch({
                        type: SMTP_PASSENGER_VALIDATION,
                        payload: {
                            ...passSMTPS,
                            [name]: !_get(resp, 'data.isValid', true),
                        },
                    })
                }
            })
            .catch(error => {
                if (_get(error, 'code', '') !== 'ECONNABORTED') {
                    Raven.captureException(
                        'Validate Email ERROR: ' + JSON.stringify(error)
                    )
                }
                if (!name) {
                    store.dispatch({
                        type: SMTP_VALIDATION,
                        payload: true,
                    })
                } else {
                    store.dispatch({
                        type: SMTP_PASSENGER_VALIDATION,
                        payload: {
                            ...passSMTPS,
                            [name]: false,
                        },
                    })
                }
            })
    }, 3000)
}

export function toggleBrandedGdpr(state) {
    store.dispatch({
        type: BRANDED_GDPR,
        payload: !!state,
    })
}

export function clearSmtpValidation() {
    return function(dispatch) {
        dispatch({
            type: SMTP_VALIDATION,
            payload: true,
        })
    }
}

export function getPassengerAgreements(
    claimId,
    passengerId,
    fullName,
    birthDate,
    address
) {
    const url = `/api/claim/get-agreements/${claimId}/${passengerId}`
    const apiClient = getApiClient()
    let fd = new FormData()
    fd.append('fullName', fullName)
    if (birthDate) {
        fd.append('birthDate', birthDate)
    }
    if (address) {
        fd.append('address', address)
    }

    return function(dispatch) {
        return apiClient
            .post(url, fd)
            .then(response => {
                dispatch({
                    type: USER_AGREEMENT,
                    payload: response.data,
                })
                return response.data
            })
            .catch(error => {
                console.log(error)
            })
    }
}
