import { LANGUAGE } from '../actions/actionTypes';


export default function (state = null, action) {
    switch (action.type) {
        case LANGUAGE:
            return action.payload;
        default:
    }
    return state;
};