import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'
import App from './App'
import { paths, V2_CORE } from './shared/constants'

//import ChangePaymentOption from './views/deprecated/ChangePaymentOption';
import SorryPage from './views/other/SorryPage'
import ErrorPage from './views/other/ErrorPage'
import NotFoundPage from './views/other/NotFoundPage'
import DuplicatePage from './views/other/DuplicatePage'

import RequireAuth from './shared/components/require_auth'
import ResetPassword from './views/user/screens/ResetPassword'
import ResetPasswordSuccess from './views/user/screens/ResetPasswordSuccess'
import NewPassword from './views/user/screens/NewPassword'
import NewPasswordSuccess from './views/user/screens/NewPasswordSuccess'
//import ScannerIndex from './views/deprecated/InboxScanner/ScannerIndex';
//import ScannerResults from './views/deprecated/InboxScanner/ScannerResult';

import PassengerAgreement from './views/passenger/PassengerAgreement'
import PassengerAgreementSigned from './views/passenger/PassengerAgreementSigned'
import PassengerLegal from './views/passenger/PassengerLegal'
import OwnerAgreement from './views/owner/OwnerAgreement'
import OwnerAgreementSigned from './views/owner/OwnerAgreementSigned'
import AgreementAlreadySigned from './shared/components/Agreement/AgreementAlreadySigned'
import OwnerLegal from './views/owner/OwnerLegal'
import MissingDetails from './views/missingDetails/MissingDetails'
import EmailSubscriptions from './views/user/screens/EmailSubscriptions'
import LeadList from './views/other/ClaimAgreementLead/LeadList'
import LeadAgreement from './views/other/ClaimAgreementLead/LeadAgreement'
import LeadDocuments from './views/other/ClaimAgreementLead/LeadDocuments'
import ChatbotAgreement from './views/other/ClaimAgreementLead/ChatbotAgreement'

import SkycopCare from './views/other/SkycopCare'
import FamilyMembersEdit from './views/other/SkycopCare/FamilyMembersEdit'
import PlannedTrips from './views/other/PlannedTrips'
import SkycopPay from './views/other/SkycopPay'

import BrandedPreview from './views/branded/BrandedPreview'

import IndexV2 from './views/V2'
import SkycopCareSuccess from './views/other/SkycopCare/SkycopCareSuccess'
import PaymentSuccess from './views/other/PaymentSuccess'

import MarketingUnsubscribe from './views/V2/steps/MarketingUnsubscribe'
import AddReviewPage from './views/V2/components/AddReviewPage'
import PayoutOfferSendout from './views/V2/components/PayoutOfferSendout/PayoutOfferSendout'

export default (
    <Route path="/" component={App}>
        <IndexRoute component={IndexV2} />
        {/*<Route path="/(:pathParam)" component={Index} />*/}
        {/* <Route path='/change-payment-option' component={RequireAuth(ChangePaymentOption)} /> */}
        <Route path="/skycop-care" component={SkycopCare} />
        <Route path="/skycop-care-success" component={SkycopCareSuccess} />
        <Route
            path="/subscriptions/family-edit/:id"
            component={FamilyMembersEdit}
        />
        <Route path="/payment-success" component={PaymentSuccess} />
        <Route
            path={V2_CORE.PAYMENT.path}
            component={RequireAuth(V2_CORE.PAYMENT.component)}
        />
        <Route path={paths.SORRY} component={SorryPage} />
        <Route path={paths.ERROR} component={ErrorPage} />
        <Route path={paths.DUPLICATE} component={DuplicatePage} />
        {/* <Route path='/scanner' component={ScannerIndex} />
        <Route path='/scanner-results' component={ScannerResults} /> */}
        <Route path={'/lead/list'} component={LeadList} />
        <Route path={'/lead/agreement'} component={LeadAgreement} />
        <Route path={'/lead/documents'} component={LeadDocuments} />
        <Route
            path={'/offer/claim'}
            component={RequireAuth(PayoutOfferSendout)}
        />
        <Route path={'/claim/chatbot'} component={ChatbotAgreement} />
        <Route
            path={paths.PASSENGER_SIGNATURE}
            component={PassengerAgreement}
        />
        <Route
            path={paths.PASSENGER_SIGNATURE_COMPLETED}
            component={PassengerAgreementSigned}
        />
        <Route
            path={paths.PASSENGER_LEGAL_SIGNATURE}
            component={PassengerLegal}
        />
        <Route
            path={paths.MARKETING_UNSUBSCRIBE}
            component={MarketingUnsubscribe}
        />
        <Route path={paths.SIGNATURE} component={OwnerAgreement} />
        <Route
            path={paths.SIGNATURE_COMPLETED}
            component={OwnerAgreementSigned}
        />
        <Route path={paths.LEGAL_SIGNATURE} component={OwnerLegal} />
        {/* <Route path='/legal-signature-completed' component={OwnerLegalSigned} /> */}
        <Route
            path={V2_CORE.CLAIM_INNER.path}
            component={RequireAuth(V2_CORE.CLAIM_INNER.component)}
        />
        <Route
            path={V2_CORE.MY_CLAIMS.path}
            component={RequireAuth(V2_CORE.MY_CLAIMS.component)}
        />
        <Route
            path={V2_CORE.SC_SUBSCRIPTIONS.path}
            component={RequireAuth(V2_CORE.SC_SUBSCRIPTIONS.component)}
        />
        <Route
            path={V2_CORE.SC_SUBSCRIPTIONS_HISTORY.path}
            component={RequireAuth(V2_CORE.SC_SUBSCRIPTIONS_HISTORY.component)}
        />
        <Route
            path={V2_CORE.SC_CANCEL_SUBSCRIPTIONS.path}
            component={RequireAuth(V2_CORE.SC_CANCEL_SUBSCRIPTIONS.component)}
        />
        <Route
            path={V2_CORE.MY_PROFILE.path}
            component={RequireAuth(V2_CORE.MY_PROFILE.component)}
        />
        <Route path={paths.CHANGE_PASSWORD} component={ResetPassword} />
        <Route
            path={paths.CHANGE_PASSWORD_SUCCESS}
            component={ResetPasswordSuccess}
        />
        <Route path={paths.EMAIL_PREFERENCES} component={EmailSubscriptions} />
        <Route path={paths.BRANDED} component={RequireAuth(BrandedPreview)} />
        <Route path={V2_CORE.V2_prePath} component={IndexV2}>
            <Route
                path={V2_CORE.LOGIN.path}
                component={V2_CORE.LOGIN.component}
            />
            <Route path={paths.NEW_PASSWORD} component={NewPassword} />
            <Route
                path={paths.NEW_PASSWORD_SUCCESS}
                component={NewPasswordSuccess}
            />
            <Route
                path={paths.AGREEMENT_SIGNED}
                component={AgreementAlreadySigned}
            />
            <Route
                path={V2_CORE.STEP_2.path}
                component={V2_CORE.STEP_2.component}
            />
            <Route
                path={V2_CORE.STEP_3.path}
                component={V2_CORE.STEP_3.component}
            />
            <Route
                path={V2_CORE.STEP_4_Email.path}
                component={V2_CORE.STEP_4_Email.component}
            />
            <Route
                path={V2_CORE.STEP_4.path}
                component={V2_CORE.STEP_4.component}
            />
            <Route
                path={V2_CORE.STEP_5.path}
                component={V2_CORE.STEP_5.component}
            />
            <Route
                path={V2_CORE.STEP_6.path}
                component={V2_CORE.STEP_6.component}
            />
            <Route
                path={V2_CORE.STEP_7.path}
                component={V2_CORE.STEP_7.component}
            />
            <Route
                path={V2_CORE.STEP_8.path}
                component={V2_CORE.STEP_8.component}
            />
            <Route
                path={V2_CORE.DUPLICATE_PAGE.path}
                component={V2_CORE.DUPLICATE_PAGE.component}
            />
            <Route
                path={V2_CORE.REGISTER.path}
                component={V2_CORE.REGISTER.component}
            />
            <Route
                path={V2_CORE.DRAFT_EXPIRED.path}
                component={V2_CORE.DRAFT_EXPIRED.component}
            />
            <Route
                path={V2_CORE.NOT_SUPPORTED_AIRLINES.path}
                component={V2_CORE.NOT_SUPPORTED_AIRLINES.component}
            />
            <Route
                path={V2_CORE.REMARKETING_UNSUBSCRIBE.path}
                component={V2_CORE.REMARKETING_UNSUBSCRIBE.component}
            />
            <Route path={paths.ADD_REVIEW} component={AddReviewPage} />
        </Route>
        <Route path={V2_CORE.SMART_DELAY.path}>
            <IndexRoute component={V2_CORE.SMART_DELAY.component} />
            <Route
                path={V2_CORE.SMART_DELAY.path}
                component={V2_CORE.SMART_DELAY.component}
            />
            <Route
                path={V2_CORE.STEP_1_SMART_DELAY.path}
                component={V2_CORE.STEP_1_SMART_DELAY.component}
            />
            <Route
                path={V2_CORE.STEP_2_SMART_DELAY.path}
                component={V2_CORE.STEP_2_SMART_DELAY.component}
            />
            <Route
                path={V2_CORE.STEP_3_SMART_DELAY.path}
                component={V2_CORE.STEP_3_SMART_DELAY.component}
            />
            <Route
                path={V2_CORE.STEP_4_SMART_DELAY.path}
                component={V2_CORE.STEP_4_SMART_DELAY.component}
            />
            <Redirect from="*" to={V2_CORE.SMART_DELAY.path} />
        </Route>
        <Route path={'/skycop-pay'} component={SkycopPay} />
        <Route path={'/planned-trips'} component={RequireAuth(PlannedTrips)} />
        <Route path={paths.MISSING_DETAILS} component={MissingDetails} />
        <Route path="*" component={NotFoundPage} />
    </Route>
)

export const skycopPayRoutes = (
    <Route
        path="/"
        component={({ ...props }) => <App {...props} isSkycopPay />}
    >
        <IndexRoute component={SkycopPay} />

        <Route path="/skycop-pay" component={SkycopPay} />

        <Route
            path="*"
            component={({ ...props }) => (
                <NotFoundPage redirect={'/skycop-pay'} {...props} />
            )}
        />
    </Route>
)

export const secureRoutes = (
    <Route
        path="/"
        component={({ ...props }) => <App {...props} isSkycopCare />}
    >
        <IndexRoute component={SkycopCare} />
        <Route path={'/planned-trips'} component={RequireAuth(PlannedTrips)} />
        <Route path="/new-claim" component={IndexV2} />
        <Route path="/skycop-care" component={SkycopCare} />
        <Route path="/skycop-care-success" component={SkycopCareSuccess} />
        <Route
            path="/subscriptions/family-edit/:id"
            component={FamilyMembersEdit}
        />
        <Route path="/payment-success" component={PaymentSuccess} />
        <Route
            path={V2_CORE.PAYMENT.path}
            component={RequireAuth(V2_CORE.PAYMENT.component)}
        />
        <Route path={paths.SORRY} component={SorryPage} />
        <Route path={paths.ERROR} component={ErrorPage} />
        <Route path={paths.DUPLICATE} component={DuplicatePage} />
        <Route path={'/lead/list'} component={LeadList} />
        <Route path={'/lead/agreement'} component={LeadAgreement} />
        <Route path={'/lead/documents'} component={LeadDocuments} />
        <Route
            path={paths.PASSENGER_SIGNATURE}
            component={PassengerAgreement}
        />
        <Route
            path={paths.PASSENGER_SIGNATURE_COMPLETED}
            component={PassengerAgreementSigned}
        />
        <Route
            path={paths.PASSENGER_LEGAL_SIGNATURE}
            component={PassengerLegal}
        />
        <Route path={paths.SIGNATURE} component={OwnerAgreement} />
        <Route
            path={paths.SIGNATURE_COMPLETED}
            component={OwnerAgreementSigned}
        />
        <Route path={paths.LEGAL_SIGNATURE} component={OwnerLegal} />
        <Route
            path={V2_CORE.CLAIM_INNER.path}
            component={RequireAuth(V2_CORE.CLAIM_INNER.component)}
        />
        <Route
            path={V2_CORE.MY_CLAIMS.path}
            component={RequireAuth(V2_CORE.MY_CLAIMS.component)}
        />
        <Route
            path={V2_CORE.SC_SUBSCRIPTIONS.path}
            component={RequireAuth(V2_CORE.SC_SUBSCRIPTIONS.component)}
        />
        <Route
            path={V2_CORE.SC_CANCEL_SUBSCRIPTIONS.path}
            component={RequireAuth(V2_CORE.SC_CANCEL_SUBSCRIPTIONS.component)}
        />
        <Route
            path={V2_CORE.MY_PROFILE.path}
            component={RequireAuth(V2_CORE.MY_PROFILE.component)}
        />
        <Route path={paths.CHANGE_PASSWORD} component={ResetPassword} />
        <Route
            path={paths.CHANGE_PASSWORD_SUCCESS}
            component={ResetPasswordSuccess}
        />
        <Route path={paths.EMAIL_PREFERENCES} component={EmailSubscriptions} />
        <Route path={paths.BRANDED} component={RequireAuth(BrandedPreview)} />
        <Route path={V2_CORE.V2_prePath} component={IndexV2}>
            <Route
                path={V2_CORE.LOGIN.path}
                component={V2_CORE.LOGIN.component}
            />
            <Route path={paths.NEW_PASSWORD} component={NewPassword} />
            <Route
                path={paths.NEW_PASSWORD_SUCCESS}
                component={NewPasswordSuccess}
            />
            <Route
                path={paths.AGREEMENT_SIGNED}
                component={AgreementAlreadySigned}
            />
            <Route
                path={V2_CORE.STEP_2.path}
                component={V2_CORE.STEP_2.component}
            />
            <Route
                path={V2_CORE.STEP_3.path}
                component={V2_CORE.STEP_3.component}
            />
            <Route
                path={V2_CORE.STEP_4_Email.path}
                component={V2_CORE.STEP_4_Email.component}
            />
            <Route
                path={V2_CORE.STEP_4.path}
                component={V2_CORE.STEP_4.component}
            />
            <Route
                path={V2_CORE.STEP_5.path}
                component={V2_CORE.STEP_5.component}
            />
            <Route
                path={V2_CORE.STEP_6.path}
                component={V2_CORE.STEP_6.component}
            />
            <Route
                path={V2_CORE.STEP_7.path}
                component={V2_CORE.STEP_7.component}
            />
            <Route
                path={V2_CORE.STEP_8.path}
                component={V2_CORE.STEP_8.component}
            />
            <Route
                path={V2_CORE.DUPLICATE_PAGE.path}
                component={V2_CORE.DUPLICATE_PAGE.component}
            />
            <Route
                path={V2_CORE.REGISTER.path}
                component={V2_CORE.REGISTER.component}
            />
            <Route
                path={V2_CORE.DRAFT_EXPIRED.path}
                component={V2_CORE.DRAFT_EXPIRED.component}
            />
            <Route
                path={V2_CORE.NOT_SUPPORTED_AIRLINES.path}
                component={V2_CORE.NOT_SUPPORTED_AIRLINES.component}
            />
            <Route
                path={V2_CORE.REMARKETING_UNSUBSCRIBE.path}
                component={V2_CORE.REMARKETING_UNSUBSCRIBE.component}
            />
        </Route>
        <Route
            path="*"
            component={({ ...props }) => (
                <NotFoundPage redirect={'/skycop-care'} {...props} />
            )}
        />
    </Route>
)
