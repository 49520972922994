import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import _get from "lodash/get";
import t from "../../../shared/translations";
import { getCurrencies } from "../../../shared/actions/";
import NewSelectField from "../../../shared/components/V2/NewSelectField";
import { SelectFieldOptions } from "../../../shared/components/Form";
import { renderCurrencies } from "../../../render";
import { ClearIcon } from "../../../shared/components/svgIcons";

const SelectField = styled(NewSelectField)`
    .Select-option {
        &.is-disabled {
            display: block;
            background-color: #fcfcfc;
        }
    }
`;

const CurrencyInput = ({
    autoload = true,
    className = "",
    ignoreAccents = false,
    clearable = true,
    clear = () => null,
    cache = false,
    callback = () => null,
    isAsync = false,
    hasIcon = false,
    required = false,
    currencies,
    name,
    label,
    placeholder,
    value,
    defaultValue,
    onChange,
    onInputChange,
    disabled,
    errorText,
    id,
}) => (
        <SelectField
            label={label || t`v2_common.label.country`}
            errorText={errorText}
            hasIcon={hasIcon}
            required={required}
            disabled={disabled}
            data-hj-whitelist=""
            name={name}
            id={id}
            selectProps={{
                "data-hj-whitelist": "",
                inputProps: {
                    "data-hj-whitelist": "",
                },
                disabled,
                id: id,
                name,
                value,
                defaultValue,
                autoload,
                className,
                ignoreAccents,
                clearable,
                cache,
                searchable: true,
                options: renderCurrencies(currencies, name),
                optionRenderer: SelectFieldOptions,
                isAsync: !!isAsync,
                onBlurResetsInput: false,
                loadingPlaceholder: t`v2_common.no_results`,
                searchPromptText: t`v2_common.no_results`,
                placeholder: placeholder || t`v2_common.placeholder.country`,

                onFocus: (e) => e.preventDefault(),
                onBlur: (e) => e.preventDefault(),
                onClick: (e) => e.preventDefault(),
                onChange: (e) => {
                    onChange(e, callback);
                    const elem = _get(document, "activeElement", null);

                    if (elem && typeof elem.blur === "function") {
                        elem.blur();
                    }
                },
                clearRenderer: () => {
                    if (!value) {
                        return null;
                    }
                    return (
                        <span
                            className="clear-item"
                            onClick={() => clear()}
                            onTouchEnd={() => clear()}
                        >
                            <ClearIcon />
                        </span>
                    );
                },
                onInputChange,
            }}
        />
    );

function mapStateToProps(state) {
    return {
        currencies: state.currencies,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrencies }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrencyInput);
