import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { hideModal } from "../../../shared/actions/v2_actions";
import Modal from "../../../shared/components/V2/Modal";
import { CloseButton } from "../../../shared/components/V2/V2Buttons";
import { modalTypes } from "../../../shared/constants";
import { tablet, mobile } from "../../../shared/components/helpers/styled_queries";
import { GdprShieldIcon } from "../../../shared/components/svgIcons";
import t from "../../../shared/translations";
import store from "../../../shared/services/store";
import { logKibana } from "../../../shared/actions";

const FloatingGDPR = styled.div`
    transition: all 0.25s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 43px;
    height: 46px;
    position: fixed;
    cursor: pointer;
    z-index: 250;
    left: 20px;
    bottom: 14px;

    ${tablet`
        left: 20px;
        bottom: 14px;
    `}

    ${mobile`
        left: 20px;
        bottom: 14px;
    `}
`;

export const FloatGdprIcon = ({ showModal, path }) => {
    const actionClick = () => {
        store.dispatch(logKibana("GDPR_notice_modal_clicked", path, "warning"));
        showModal(modalTypes.gdprModal);
    }

    return (
        <FloatingGDPR onClick={actionClick} id="shield-icon">
            <GdprShieldIcon />
        </FloatingGDPR>
    );
}

const GdprBox = styled.div`
    width: 100%;
    height: 100%;
    padding: 50px 32px;
    display: flex;
    font-family: "Open Sans", sans-serif;
    color: #343d53;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    ${tablet`
        padding: 75px 20px;
    `}

    ${mobile`
        padding: 75px 20px;
    `}

    svg {
        margin-bottom: 24px;
        display: flex;
    }

    .bold {
        font-weight: bold;
    }

    span {
        color: #353d51;
        line-height: 1.71;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    b {
        font-size: 28px;
        line-height: 1.21;
        display: block;
        margin-bottom: 20px;
        color: #353d51;
        font-weight: bold;
        text-align: center;
    }
`;


class GdprModal extends React.Component {
    render() {
        const { hideModal } = this.props;

        return (
            <Modal
                modalType={modalTypes.gdprModal}
                width="476px"
                height="auto"
            >
                <CloseButton onClick={hideModal} />
                <GdprBox sm={8} md={12} lg={12}>
                    <GdprShieldIcon ratio={1.6} />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t`gdpr_complience_validation`
                        }}
                    />
                </GdprBox>
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal,
        },
        dispatch
    );
}

export default connect(
    null,
    mapDispatchToProps
)(GdprModal);
