import _get from 'lodash/get'
import t from './shared/translations'

const TOP_LABEL = 'highlighted_title.top_label'
const BOTTOM_LABEL = 'highlighted_title.bottom_label'

export function createAirportObj(airport = {}) {
    return {
        value: airport.id,
        label: `${airport.title}, ${airport.city} (${airport.iata})`,
        optionsTopLabel: _get(
            airport,
            TOP_LABEL,
            `${airport.title} (${airport.iata})`
        ),
        optionsBottomLabel: _get(
            airport,
            BOTTOM_LABEL,
            `${airport.city}, ${airport.country_title}`
        ),
        cityIata: `${airport.city} (${airport.iata})`,
        // eslint-disable-next-line no-restricted-globals
        name: name,
        iata: airport.iata,
        city: airport.city,
        country: airport.country_title,
        title: airport.title,
        id: airport.id,
    }
}

export function renderAirports(airports, name, usedAirports) {
    if (airports) {
        let airportsList = []
        let usedAirportsList = []

        airports.map((airport, index) => {
            if (usedAirports.filter(obj => obj.id === airport.id).length > 0) {
                return airportsList.push({
                    value: airport.id,
                    label: `${airport.title}, ${airport.city} (${airport.iata})`,
                    optionsTopLabel: _get(
                        airport,
                        TOP_LABEL,
                        `${airport.title} (${airport.iata})`
                    ),
                    optionsBottomLabel: _get(
                        airport,
                        BOTTOM_LABEL,
                        `${airport.city}, ${airport.country_title}`
                    ),
                    cityIata: `${airport.city} (${airport.iata})`,
                    name: name,
                    iata: airport.iata,
                    city: airport.city,
                    country: airport.country_title,
                    title: airport.title,
                    id: airport.id,
                    disabled: true,
                })
            } else {
                return airportsList.push({
                    value: airport.id,
                    label: `${airport.title}, ${airport.city} (${airport.iata})`,
                    optionsTopLabel: _get(
                        airport,
                        TOP_LABEL,
                        `${airport.title} (${airport.iata})`
                    ),
                    optionsBottomLabel: _get(
                        airport,
                        BOTTOM_LABEL,
                        `${airport.city}, ${airport.country_title}`
                    ),
                    cityIata: `${airport.city} (${airport.iata})`,
                    name: name,
                    iata: airport.iata,
                    city: airport.city,
                    country: airport.country_title,
                    title: airport.title,
                    id: airport.id,
                })
            }
        })
        usedAirports.map((airport, index) => {
            return usedAirportsList.push({
                value: airport.id,
                label: `${airport.title}, ${airport.city} (${airport.iata})`,
                optionsTopLabel: _get(
                    airport,
                    TOP_LABEL,
                    `${airport.title} (${airport.iata})`
                ),
                optionsBottomLabel: _get(
                    airport,
                    BOTTOM_LABEL,
                    `${airport.city}, ${airport.country_title}`
                ),
                name: name,
                iata: airport.iata,
                city: airport.city,
                country: airport.country_title,
                title: airport.title,
                id: airport.id,
                disabled: true,
            })
        })

        return arrayUnique(airportsList.concat(usedAirportsList))
    }
}

export function arrayUnique(array) {
    var a = array.concat()
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (
                (a[i] && a[i].value) === (a[j] && a[j].value) &&
                (a[i] && a[i].name) === (a[j] && a[j].name)
            )
                a.splice(j--, 1)
        }
    }
    return a
}

export function airlinesArrayUnique(array) {
    var a = array.concat()
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if ((a[i] && a[i].id) === (a[j] && a[j].id)) a.splice(j--, 1)
        }
    }
    return a
}

export function renderAirlines(airlines, name, usedAirlines) {
    if (airlines) {
        let airlinesList = []
        let usedAirlinesList = []

        airlines.map((airline, index) => {
            if (airline.id) {
                return airlinesList.push({
                    value: airline.id,
                    label: `${airline.title} (${airline.iata})${
                        !!airline.isBankrupt
                            ? ` (${t`v2_common.ceased_operations`})`
                            : ''
                    }`,
                    name: name,
                    id: airline.id,
                    iata: airline.iata,
                    title: airline.title,
                    disabled: !!airline.isBankrupt,
                    airlineDocuments: airline.airlineDocuments || [],
                })
            } else return null
        })
        usedAirlines.map((airline, index) => {
            if (airline.id) {
                return usedAirlinesList.push({
                    value: airline.id,
                    label: `${airline.title} (${airline.iata})${
                        !!airline.isBankrupt
                            ? ` (${t`v2_common.ceased_operations`})`
                            : ''
                    }`,
                    name: name,
                    id: airline.id,
                    iata: airline.iata,
                    title: airline.title,
                    disabled: !!airline.isBankrupt,
                    airlineDocuments: airline.airlineDocuments || [],
                })
            } else return null
        })

        return arrayUnique(airlinesList.concat(usedAirlinesList))
    }
}

export function renderCountries(countries, name) {
    if (countries) {
        let countriesList = []
        countries.payload.map((country, index) => {
            return countriesList.push({
                value: country.id,
                label: country.title,
                name: name,
                code: country.code,
            })
        })
        return countriesList.sort(function(a, b) {
            if (a && b && a.label && b.label) {
                let textA = a.label.toUpperCase()
                let textB = b.label.toUpperCase()
                return textA < textB ? -1 : textA > textB ? 1 : 0
            } else return countriesList
        })
    }
}

export function renderCurrencies(currencies, name) {
    if (currencies) {
        let currenciesList = []
        currencies.map((c, index) => {
            return currenciesList.push({
                value: c.id,
                label: `${c.currencyTitle} (${c.currency})`,
                optionsTopLabel: c.currency,
                optionsBottomLabel: c.currencyTitle,
                isPrimaryCurrency: c.isPrimaryCurrency === 'true',
                grayedOutLabel: c.grayedOutLabel,
                disabled: !!c.grayedOutLabel || c.isDisabled,
                name: name,
            })
        })
        return currenciesList
    }
}
