import React from 'react'
import styled from 'styled-components'

const BoxHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 18px 18px 10px 18px;
    width: 100%;

    h3 {
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #353d51;
    }
`

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    height: 100%;

    p {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        color: #7f8fa4;
        margin-bottom: 8px;
    }

    .mb-24 {
        margin-bottom: 24px;
    }

    .bold {
        font-weight: 600;
        color: #353d51;
    }
`

const BoxLine = styled.div`
    position: relative;
    height: 16px;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border: solid 1px #cbd3df;
        border-radius: 50%;
        z-index: 10;
        background: #fff;
    }

    &:before {
        border-bottom-color: transparent;
        border-left-color: transparent;
        left: -8px;
        transform: rotate(45deg);
    }

    .dashed-line {
        position: absolute;
        z-index: 9;
        top: 8px;
        width: 100%;
        margin: 0;
    }
`

const IconContainer = styled.div`
    margin-right: 12px;
`

const MainContainer = styled.div`
    border-radius: 8px;
    border: solid 1px #cbd3df;

    & .header {
        display: flex;
    }

    &.dragDrop {
        &:hover,
        &.hover {
            border-color: #32b86a;

            ${BoxLine} {
                &:before {
                    border-color: #32b86a;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    left: -8px;
                    transform: rotate(45deg);
                }

                .dashed-line {
                    border-color: #32b86a;
                }
            }
        }
    }

    &.danger {
        border-color: #f16464;

        ${BoxLine} {
            &:before {
                border-color: #f16464;
                border-bottom-color: transparent;
                border-left-color: transparent;
                left: -8px;
                transform: rotate(45deg);
            }
        }
    }
`

const ClaimInnerCard = ({
    title,
    icon,
    className,
    children,
    headerElement,
    id,
    ...props
}) => (
    <MainContainer className={className} id={id} {...props}>
        <div className="header">
            <BoxHeader>
                {icon && <IconContainer>{icon()}</IconContainer>}
                <h3>{title}</h3>
            </BoxHeader>
            {headerElement && headerElement}
        </div>
        <BoxLine>
            <hr className="dashed-line" />
        </BoxLine>
        <BoxContent>{children}</BoxContent>
    </MainContainer>
)

export default ClaimInnerCard
