import { css } from 'styled-components'

const V2_BREAKPOINTS = {
    XSM: 320,
    SM: 480,
    SM1: 481,
    MD: 720,
    LG: 960,
    XL: 1200,
    XXL: 1440,
}

const between = (min, max) => (...args) => css`
    @media (min-width: ${min}px) and (max-width: ${max}px) {
        ${css(...args)}
    }
`

const min = size => (...args) => css`
    @media (min-width: ${size}px) {
        ${css(...args)}
    }
`

const max = size => (...args) => css`
    @media (max-width: ${size}px) {
        ${css(...args)}
    }
`

/**
 * min-width: 960px
 */
export const desktop = min(V2_BREAKPOINTS.LG)
/**
 * min-width: 1200px - max-width: 1440px
 */
export const desktopLG = between(V2_BREAKPOINTS.XL, V2_BREAKPOINTS.XXL)
/**
 * min-width: 960px - max-width: 1200px
 */
export const desktopSM = between(V2_BREAKPOINTS.LG, V2_BREAKPOINTS.XL)
/**
 * min-width: 480px - max-width: 960px
 */
export const tablet = between(V2_BREAKPOINTS.SM, V2_BREAKPOINTS.LG)
/**
 * min-width: 720px - max-width: 960px
 */
export const tabletLG = between(V2_BREAKPOINTS.MD, V2_BREAKPOINTS.LG)
/**
 * min-width: 480px - max-width: 720px
 */
export const tabletSM = between(V2_BREAKPOINTS.SM, V2_BREAKPOINTS.MD)
/**
 * max-width: 480px
 */
export const mobile = max(V2_BREAKPOINTS.SM)
/**
 * max-width: 320px
 */
export const mobileSM = max(V2_BREAKPOINTS.XSM)
/**
 * min-width: 320px - max-width: 480px
 */
export const mobileLG = between(V2_BREAKPOINTS.SM, V2_BREAKPOINTS.MD)

export const desktopOrTablet = between(V2_BREAKPOINTS.SM, V2_BREAKPOINTS.XXL)

export const minTablet = min(V2_BREAKPOINTS.SM1)

export default {
    desktop,
    desktopLG,
    desktopSM,
    tablet,
    tabletLG,
    tabletSM,
    mobile,
    mobileLG,
    mobileSM,
    desktopOrTablet,
}
