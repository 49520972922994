import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { SUCCESS_MESSAGE } from '../actions/actionTypes';
import { resetMessage, clearClaimErrors } from '../../shared/actions';
import t from '../../shared/translations';
import { DangerSign, CloseIcon } from '../../shared/components/svgIcons';

const Message = styled.div`
    ${({ visible }) => visible && 'padding: 10px;'}
    text-align: center;
    color: white;
    ${({ level, theme }) => level === SUCCESS_MESSAGE && `
        background-color: ${theme.colors.primaryColor};
    `}
`;

const DangerMessage = styled.div`
    ${({ visible }) => visible && 'padding: 10px;'}
    text-align: center;
    color: white;
    color: #d9534f;
    background-color: #f9e4e3;

    p {
        margin: 0;
    }
`;

const CloseButton = styled.div`
    display: flex;
    right: 0;
    position: absolute;
    cursor: pointer;
    img {
        width: 15px;
        height: 15px;
        float: right;
        object-fit: contain;
    }
`;

const DangerCloseButton = styled(CloseButton)`
    top: 0;

    svg {
        height: 15px;
        width: 15px;
        fill: #d9534f;
    }
`;

const ErrorList = styled.ul`
    text-align: left;
    list-style: none;
    margin: 0;
    padding: 0;
`;

const ErrroMessageHeader = styled.div`
    display: flex;
    margin-bottom: 10px;

    svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
`;

const ErrorHeaderText = styled.p`
    text-align: left;
    font-size: 16px;
`;

const CloseNotification = CloseButton.extend`
    left: 0px;
    top: 10px;
`;

const StyledRow = styled.div`
    align-items: center;
`;

const Notification = styled.div`
    position: fixed;
    transition: all 1s;
    ${({ visible }) => visible ? 'right: 0;' : 'right: -100%;'}
    bottom: 100px;
    box-sizing: border-box;
    padding: 20px 30px;
    min-width: 250px;
    min-height: 100px;
    z-index: 9999999999999;
    text-align: center;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    background: #36B56A;
    color: white;
    font-size: 13px;
`;

const FlashMessageContainer = styled.div`
    border-radius: 4px;
    transition-delay: 2s;
    transition: opacity 2.0s ease-in-out;
    opacity: ${({ visible }) => !visible ? 0 : 1};
    margin: 0;
    ${({ visible }) => visible && 'margin-bottom: 15px;'}

    .bold {
        font-weight: bold;
    }
`;

const FlashMessage = ({ flashMessage, resetMessage }) => {
    const { message, level } = flashMessage;

    return (
        <FlashMessageContainer className="row" visible={!!message}>
            <div className="col-md-24" style={{ padding: 0 }}>
                <Message level={level} visible={!!message}>
                    <div className="container">
                        <StyledRow className="row">
                            <div className="col-22 col-sm-22 col-md-23">
                                {t`${message}`}
                            </div>
                            <CloseButton className="col-2 col-sm-2 col-md-1" onClick={resetMessage}>
                                <img src="images/close.svg" alt="close" />
                            </CloseButton>
                        </StyledRow>
                    </div>
                </Message>
            </div>
        </FlashMessageContainer>
    )
}

const NotificationMessage = ({ flashMessage, resetMessage }) => {
    const { message } = flashMessage;

    return (
        <Notification visible={!!message}>
            <span>{t`${message}`}</span>
            <CloseNotification className="col-2 col-sm-2 col-md-1" onClick={resetMessage}>
                <img src="images/close.svg" alt="close" />
            </CloseNotification>
        </Notification>
    )
}

export const ErrorFlashMessage = ({ message, clear, hasClear = true, ...restProps }) => {
    return (
        <FlashMessageContainer className="row" visible={!!message}>
            <div className="col-md-24" style={{ padding: 0 }}>
                <DangerMessage visible={!!message}>
                    <div className="container">
                        <StyledRow className="row">
                            <div className="col-22 col-sm-22 col-md-23">
                                <ErrroMessageHeader>
                                    <DangerSign />
                                    <ErrorHeaderText>{t`common.errors.list_title`}</ErrorHeaderText>
                                </ErrroMessageHeader>
                                <ErrorList>
                                    {restProps.children}
                                </ErrorList>
                            </div>
                            {
                                hasClear &&
                                <DangerCloseButton className="col-2 col-sm-2 col-md-1" onClick={clear}>
                                    <CloseIcon fill="red" />
                                </DangerCloseButton>
                            }
                        </StyledRow>
                    </div>
                </DangerMessage>
            </div>
        </FlashMessageContainer>
    )
}

export const ErrorItem = ({ errorTitle, errors }) => {
    const mappedErrors = errors.map((error, i) => {
        return (<li key={i} style={{ marginLeft: "35px" }}>{error}</li>)
    });

    return (<ul>{errorTitle}: {mappedErrors}</ul>);
}

function mapStateToProps(state) {
    return {
        flashMessage: state.flash_message,
        trans: state.translations,
        lang: state.language,
        claimErrors: state.claim_errors,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        resetMessage,
        clearClaimErrors
    }, dispatch);
}

export const NotificationFlash = connect(mapStateToProps, mapDispatchToProps)(NotificationMessage);
export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);
