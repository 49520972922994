import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { setLanguage } from '../../actions/index'
import { getLanguagesArray } from '../../../main'

const Container = styled.div`
    display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'flex')};
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};
    width: 100%;

    ${({ isIcao }) => (isIcao ? 'margin-left: auto;' : '')}

    .lang-wrap {
        align-items: center;
        position: relative;
        display: flex;
        ${({ isIcao }) => (isIcao ? 'margin-right: 24px;' : '')}
        border: 1px solid #333333;
        padding: 14px;

        p {
            color: #333333;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
`

const StyledP = styled.p`
    font-family: 'Noto Sans';
    background: transparent;
    z-index: 10;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
    outline: none;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    option {
        color: #353d51;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
`

const IconContainer = styled.div`
    margin-right: 20px;
    width: 17px;
    height: 17px;
    margin-bottom: 8px;
`

const LangSelectContainer = styled.div`
    position: absolute;
    width: 330px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(38, 38, 69, 0.2);
    z-index: 2100000;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;

    ${({ top }) => top && `top: ${top};`}
    ${({ bottom }) => bottom && `bottom: ${bottom};`}
    ${({ left }) => left && `left: ${left};`}
    ${({ right }) => right && `right: ${right};`}
    

    &:before {
        content: "";
        background: #fff;
        border-radius: 4px;
        position: absolute;
        right: 80px;
        bottom: -9px;
        width: 20px;
        height: 20px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);

        ${({ arrowPosition }) =>
            arrowPosition && arrowPosition.top && `top: ${arrowPosition.top};`}
        ${({ arrowPosition }) =>
            arrowPosition &&
            arrowPosition.bottom &&
            `bottom: ${arrowPosition.bottom};`}
        ${({ arrowPosition }) =>
            arrowPosition &&
            arrowPosition.left &&
            `left: ${arrowPosition.left};`}
        ${({ arrowPosition }) =>
            arrowPosition &&
            arrowPosition.right &&
            `right: ${arrowPosition.right};`}
    }
`

const Item = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0 40%;
    padding: 6px;
    padding-left: 32px;
    max-width: 165px;

    p {
        opacity: 0.9;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: ${({ isActive }) => (isActive ? '600' : 'normal')};
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #525f7f;
        padding-left: 12px;
        margin: 0;

        &:hover {
            color: #32b86a;
        }
    }

    img {
        border-radius: 12px;
    }

    &:hover {
        cursor: pointer;
    }
`

const InputWrapper = styled.div`
    border-radius: 16px;
    padding: 14px 32px 14px 42px;
    border: solid 1px #ffffff;
    width: 100%;
    justify-content: center;
`

class LangSelect extends React.Component {
    state = {
        isOpen: false,
        lang: {
            key: '',
            title: '',
        },
    }

    componentDidMount() {
        const { lang } = this.props
        this.updateLangState(lang)

        document.addEventListener('mousedown', this.handleOutsideClick, false)
    }

    componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            this.handleOutsideClick,
            false
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang !== this.props.lang) {
            this.updateLangState(this.props.lang)
        }
    }

    updateLangState = langKey => {
        const { webLanguages } = this.props
        const initialLang = (getLanguagesArray(webLanguages) || []).filter(
            webLang => webLang.key === langKey
        )[0]

        if (initialLang) {
            this.setState({ lang: initialLang })
        }
    }

    handleOutsideClick = ({ target }) => {
        if (this.node && this.node.contains(target)) {
            return
        }

        this.setState({ isOpen: false })
    }

    toggleLangSelect = () => {
        const { disabled } = this.props

        if (!disabled) {
            this.setState({ isOpen: !this.state.isOpen })
        }
    }

    handleClick = lang => {
        const { setLanguage } = this.props

        this.setState({ lang }, () => {
            setLanguage(lang.key || 'en')
        })
    }

    render() {
        const {
            webLanguages,
            justifyContent,
            disabled,
            position,
            arrowPosition,
            mobileOnly,
            isIcao = false,
        } = this.props
        const { isOpen, lang } = this.state

        if (webLanguages) {
            return (
                <Container
                    className={disabled ? 'disabled' : ''}
                    justifyContent={justifyContent}
                    onClick={this.toggleLangSelect}
                    innerRef={node => (this.node = node)}
                    mobileOnly={mobileOnly}
                    isIcao={!!isIcao}
                >
                    <InputWrapper className="lang-wrap">
                        <IconContainer>
                            <img
                                src="/images/blank.gif"
                                className={`flag locale-${
                                    lang.key === 'ca' ? 'cat' : lang.key
                                } empty-flag`}
                                alt={lang.key}
                            />
                        </IconContainer>
                        <StyledP id="mob-lang">
                            {isIcao ? lang.key.toUpperCase() : lang.title}
                        </StyledP>
                        {isOpen && (
                            <LangSelectContainer
                                top={position && position.top}
                                bottom={position && position.bottom}
                                left={position && position.left}
                                right={position && position.right}
                                arrowPosition={arrowPosition}
                            >
                                {(getLanguagesArray(webLanguages) || []).map(
                                    (webLang, i) => {
                                        return (
                                            <Item
                                                key={i}
                                                onClick={() =>
                                                    this.handleClick(webLang)
                                                }
                                                isActive={
                                                    lang.key === webLang.key
                                                }
                                            >
                                                <img
                                                    src="/images/blank.gif"
                                                    className={`flag locale-${
                                                        webLang.key === 'ca'
                                                            ? 'cat'
                                                            : webLang.key
                                                    } empty-flag`}
                                                    alt={webLang.key}
                                                />
                                                <p>{webLang.title}</p>
                                            </Item>
                                        )
                                    }
                                )}
                            </LangSelectContainer>
                        )}
                    </InputWrapper>
                </Container>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        lang: state.language,
        trans: state.translations,
        webLanguages: state.web_languages,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLanguage }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LangSelect)
