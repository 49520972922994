import { CP_ACTIONS } from '../actions/actionTypes';
import { cpActions } from '../constants';

const INITIAL_STATE = cpActions;

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CP_ACTIONS:
            return [ ...state, ...action.payload ].filter((val, index, self) => self.indexOf(val) === index);
        default:
    }
    return state;
}