import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = {
    modalType: false,
    props: {},
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SHOW_MODAL:
            return {
                modalType: action.modalType,
                props: {
                    ...INITIAL_STATE.props,
                    ...action.props,
                }
            };
        case V2_ACTIONS.HIDE_MODAL:
            return INITIAL_STATE;
        default:
    }
    return state;
}
