import { V2_ACTIONS } from '../../actions/actionTypes';
import { V2_STEPS } from "../../constants";

const INITIAL_STATE = {
    [V2_STEPS.STEP_1] : false,
    [V2_STEPS.STEP_2] : false,
    [V2_STEPS.STEP_3] : false,
    [V2_STEPS.STEP_4] : false,
    [V2_STEPS.STEP_5] : false,
    [V2_STEPS.STEP_6] : false,
}

export default function (state = INITIAL_STATE, action) {
    const { type, step } = action;

    switch (type) {
        case V2_ACTIONS.CLAIM_DRAFT_PRELOAD_STEP:
            const newState = {...state};

            newState[step] = true;
            return newState;
        default:
    }
    return state;
}