import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import { scrollComponentToView } from '../../../../main'
import { ErrorMessage } from './ErrorMessage'

import { get } from 'lodash'
import {
    Row,
    PageViewContainer,
    Col,
} from '../../../../shared/components/V2/Layout'
import t from '../../../../shared/translations'
import { StyledSmartDelayContainer, TitleContainer } from './SmartDelayPage'
import { PlaneIcon1 } from '../../../../shared/components/svgIcons'
import TermsAndConditions from '../../../V2/components/TermsAndConditions'
import SmartDelayHandler from './SmartDelayHandler'
import {
    mobile,
    tablet,
    desktop,
} from '../../../../shared/components/helpers/styled_queries'
import { EditIcon } from '../../../../views/V2/steps/ClaimInner/icons.js'
import { getAirportById } from '../../../../shared/actions/v2_actions'
import { paths } from '../../../../shared/constants'
import SmartDelayStepper from './SmartDelayStepper'

const PageHeader = styled.p`
    color: #353d51;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
`

const ConfirmationBox = styled.div`
    position: relative;
    background-color: #ffffff;
    height: 100%;
    display: flex;
    margin-left: 12px;
    ${mobile`
    font-size: 26px;
    margin-left: 18px;
    `}

    ${tablet`
    font-size: 26px;
    margin-left: unset;
    `}

    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
`
const HeaderBox = styled.div`
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: solid 1px #cbd3df;
    border-bottom: none;

    display: flex;
    align-items: center;
    padding: 18px 24px 8px;
    width: 100%;
`

const BoxLine = styled.div`
    position: relative;
    height: 16px;
    width: 100%;
    padding: 0 0 0 8px;
    overflow: hidden;
    background: linear-gradient( to bottom,#cbd3df 0%, #cbd3df 50%, white 50%, white 100% )

    border-right: solid 1px #cbd3df;
    border-left: solid 1px #cbd3df;

    &:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border: solid 1px #cbd3df;
        border-radius: 50%;
        z-index: 10;
        background: #fff;
    }

    &:before {
        border-bottom-color: transparent;
        border-left-color: transparent;
        left: -8px;
        transform: rotate(45deg);

    }

    .dashed-line {
        position: absolute;
        z-index: 9;
        top: 8px;
        width: 100%;
        margin: 0;
    }
    border-right: solid 1px #cbd3df;
`

const BoxContent = styled.div`
    border: solid 1px #cbd3df;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    height: 100%;

    & .airline {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & :first-child {
            margin-right: 8px;
        }

        p {
            color: #7f8fa4;
            text-align: center;
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            margin-bottom: 4px;
        }
    }

    & .reason {
        margin-bottom: 16px;
        color: #7f8fa4;
        text-align: center;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }

    .bold {
        font-weight: 600;
        color: #353d51;
    }
`

const ArrivalAirport = styled.div`
    font-family: Open Sans;
    font-weight: 400;
    font-size: 24px;
    color: #353d51;
    margin-left: 10px;
`

const DepartureAirport = styled.div`
    font-family: Open Sans;
    font-weight: 400;
    font-size: 24px;
    color: #353d51;
    margin-right: 10px;
`

const RowContent = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    ${mobile`
    flex-wrap: wrap;
    `}
    overflow: hidden;
    white-space: nowrap;
`

const StyledRow = styled(Row)`
    flex-direction: row;
    position: relative;
`

const TermsContainer = styled.div`
    margin: 20px 0px 20px 0px;

    margin-left: 12px;

    ${mobile`
    margin-left: 18px;
`}

    ${tablet`
    margin-left: unset;
`}
`

const IconWrapper = styled.div`
    cursor: pointer;
    margin-top: 25px;
    margin-left: 10px;
`

const ListHeader = styled.div`
    font-family: Open Sans;
    font-size: 14px;
    font-eight: 400;
    color: #525f7f;
`

const ListItem = styled.div`
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    color: #353d51;
`

const ListItemEmail = styled(ListItem)`
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledCol = styled(Col)`
    ${desktop`
        width: 33.3%;
    `}

    ${tablet`
    width: 33.3%;
`}
`

class Confirmation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            termsCheck: false,
            shouldValidateTerms: false,
        }
    }

    componentDidMount() {
        scrollComponentToView('pageTopNavbar', false, true)
    }

    onChangeTerms = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
        this.setState({ shouldValidateTerms: false })
    }

    getCountryPhoneCode = code => {
        const { countries } = this.props
        const phoneCode = (countries ? countries.payload : []).filter(
            country => country.id === code
        )[0]

        return phoneCode ? phoneCode.phone_code : ''
    }

    render() {
        const {
            trans,
            flightDetails,
            passengerDetails,
            flightDetailsErrors,
        } = this.props
        const { termsCheck, shouldValidateTerms } = this.state

        if (!trans) {
            return null
        }

        const flightDetailsTable = flightDetails => (
            <StyledRow>
                <RowContent>
                    <StyledCol sm={12}>
                        <ListHeader>{t`smart_delay.confirmation_page.flight_itinerary`}</ListHeader>
                        {Object.keys(flightDetails).map(key => (
                            <ListItem key={key}>
                                {`
                                ${get(
                                    getAirportById(
                                        flightDetails[key].arrivalAirport
                                    ),
                                    'iata',
                                    ''
                                )}-${get(
                                    getAirportById(
                                        flightDetails[key].departureAirport
                                    ),
                                    'iata',
                                    ''
                                )}`}
                            </ListItem>
                        ))}
                    </StyledCol>
                    <StyledCol sm={12}>
                        <ListHeader>{t`smart_delay.confirmation_page.flight_date`}</ListHeader>
                        {Object.keys(flightDetails).map(key => (
                            <ListItem key={key}>
                                {`${flightDetails[key].flightDate}`}
                            </ListItem>
                        ))}
                    </StyledCol>
                    <StyledCol sm={12}>
                        <ListHeader>{t`smart_delay.confirmation_page.flight_number`}</ListHeader>
                        {Object.keys(flightDetails).map(key => (
                            <ListItem key={key}>
                                {`${flightDetails[key].flightNumber}`}
                            </ListItem>
                        ))}
                    </StyledCol>
                </RowContent>
                <IconWrapper
                    onClick={() =>
                        browserHistory.push(
                            paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS
                        )
                    }
                >
                    <EditIcon />
                </IconWrapper>
            </StyledRow>
        )
        const passengerDetailsTable = passengerDetails => (
            <StyledRow>
                <RowContent>
                    <StyledCol sm={12}>
                        <ListHeader>{t`smart_delay.confirmation_page.passenger`}</ListHeader>
                        <>
                            {Object.keys(passengerDetails).map(key => (
                                <ListItem key={key}>
                                    {`${passengerDetails[key].salutation
                                        .charAt(0)
                                        .toUpperCase() +
                                        passengerDetails[key].salutation.slice(
                                            1
                                        )} ${passengerDetails[key].name} ${
                                        passengerDetails[key].surname
                                    }`}
                                </ListItem>
                            ))}
                        </>
                    </StyledCol>
                    <StyledCol sm={12}>
                        <ListHeader>{t`smart_delay.confirmation_page.email`}</ListHeader>
                        {Object.keys(passengerDetails).map(key => (
                            <ListItemEmail
                                key={key}
                                title={passengerDetails[key].email}
                            >
                                {`${passengerDetails[key].email || ''}`}
                            </ListItemEmail>
                        ))}
                    </StyledCol>
                    <StyledCol sm={12}>
                        <ListHeader>{t`smart_delay.confirmation_page.phone_number`}</ListHeader>
                        {Object.keys(passengerDetails).map(key => (
                            <ListItem key={key}>
                                {`${this.getCountryPhoneCode(
                                    passengerDetails[key].phoneCode
                                )} ${passengerDetails[key].phone || ''}`}
                            </ListItem>
                        ))}
                    </StyledCol>
                </RowContent>
                <IconWrapper
                    onClick={() =>
                        browserHistory.push(
                            paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS
                        )
                    }
                >
                    <EditIcon />
                </IconWrapper>
            </StyledRow>
        )

        const departureAirport = get(
            getAirportById(flightDetails[1].departureAirport),
            'cityIata',
            ''
        )
        const arrivalAirport = get(
            getAirportById(
                flightDetails[Object.keys(flightDetails).length].arrivalAirport
            ),
            'cityIata',
            ''
        )

        return (
            <PageViewContainer>
                <StyledSmartDelayContainer>
                    <TitleContainer>
                        <Helmet>
                            <title>{`Skycop - ${t`smart_delay.title`} - ${t`smart_delay.confirmation_page_header`}`}</title>
                        </Helmet>
                        <SmartDelayStepper />
                        <PageHeader>{t`smart_delay.confirmation_page_header`}</PageHeader>
                    </TitleContainer>
                    {!!Object.keys(flightDetailsErrors).length && (
                        <ErrorMessage
                            errorText={t`${flightDetailsErrors[1]}`}
                        />
                    )}
                    <ConfirmationBox>
                        <HeaderBox>
                            <DepartureAirport>
                                {departureAirport}
                            </DepartureAirport>
                            <PlaneIcon1
                                fill="#959595"
                                width="16px"
                                height="16px"
                            />
                            <ArrivalAirport>{arrivalAirport}</ArrivalAirport>
                        </HeaderBox>
                        <BoxLine>
                            <hr className="dashed-line" />
                        </BoxLine>
                        <BoxContent>
                            {flightDetailsTable(flightDetails)}
                            {passengerDetailsTable(passengerDetails)}
                        </BoxContent>
                    </ConfirmationBox>
                    <TermsContainer>
                        <TermsAndConditions
                            text={t`smart_delay.confirmation_page.terms_link`}
                            onChange={this.onChangeTerms}
                            checked={termsCheck}
                            alert={!termsCheck && shouldValidateTerms}
                        />
                    </TermsContainer>
                    <div
                        onClick={() =>
                            this.setState({ shouldValidateTerms: true })
                        }
                    >
                        <SmartDelayHandler isDisabledNext={!termsCheck} />
                    </div>
                </StyledSmartDelayContainer>
            </PageViewContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        flightDetails: state.smartDelay.flightDetails,
        passengerDetails: state.smartDelay.passengerDetails,
        countries: state.countries,
        flightDetailsErrors: state.smartDelay.flightDetailsErrors,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(Confirmation)
