import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorText from "./ErrorText";
import { StyledInput } from '../styledComponents';

class PasswordField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
        }
    }

    _toggleShow = () => {
        const { showPassword } = this.state;
        this.setState({ showPassword: !showPassword });
    }

    render() {
        const { showPassword } = this.state;
        const { errorText, trans, label, classes, ...props } = this.props;

        return (
            <div className={`form-group h-pos-rel ${errorText && 'has-danger'} ${classes}`}>
                {label && <label>{label}</label>}
                <button
                    tabIndex="-1"
                    onMouseOver={this._toggleShow}
                    onMouseOut={this._toggleShow}
                    onMouseUp={(e) => { e.preventDefault(); this._toggleShow(); }}
                    onTouchEnd={(e) => { e.preventDefault(); this._toggleShow(); }}
                    onClick={(e) => e.preventDefault()}
                    className="btn btn-outline-secondary btn-sm btn--show js-toggle-pass h-pos-a h-r"
                >
                    <span>{trans["login.label.show_password"] || "Show"}</span>
                </button>
                <StyledInput
                    className="form-control h-pr100"
                    type={showPassword ? "text" : "password"}
                    {...props}
                />
                <ErrorText text={errorText} />
            </div>
        );
    }
}

PasswordField.propTypes = {
    trans:     PropTypes.shape({}),
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    classes:   PropTypes.string,
    label:     PropTypes.string,
}

PasswordField.defaultProps = {
    classes:   "",
    trans:     {},
    errorText: "",
    label:     "",
};

export default connect(
    state => ({
        trans: state.translations,
    }),
    () => ({}),
)(PasswordField);