import { V2_ACTIONS } from "../../actions/actionTypes";
import { isValidEmail, isValidLatinChars, isValidSpecialChars } from "../../../main";

const INITIAL_STATE = {
    validations: {
        user: {
            name: {
                type: "string",
                required: true,
                validLatin: true,
                minLength: 2,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.name",
                    validLatin_error: "common.label.is_required.latin_only",
                    min_error: `min.length.error.text`,
                },
            },
            surname: {
                type: "string",
                required: true,
                validLatin: true,
                minLength: 2,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.surname",
                    validLatin_error: "common.label.is_required.latin_only",
                    min_error: `min.length.error.text`,
                },
            },
            email: {
                type: "string",
                required: true,
                emailValid: true,
                minLength: 2,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.email",
                    emailValid_error: "common.label.email_invalid",
                    min_error: `min.length.error.text`,
                },
            },
            phone: {
                type: "string",
                required: true,
                minLength: 5,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.phone",
                    min_error: `min.length.error.text`,
                },
            },
            country: {
                type: "string",
                required: true,
                skipTypeCheck: true,
                errorMessages: {
                    required_error: "common.label.is_required.country",
                },
            },
        }
    },
    errors: {},
};

const validateUserInputs = (validations, payload, checkRequired = true) => {
    let errors = {};

    return Object.keys(validations).reduce((errors, validator) => {

        if (typeof payload[validator] !== validations[validator].type) {
            errors[validator] = validations[validator].errorMessages.type_error;
        }

        if (validations[validator].minLength && payload[validator].length < validations[validator].minLength) {
            errors[validator] = validations[validator].errorMessages.min_error;
        }

        if (checkRequired && !payload[validator]) {
            errors[validator] =
                validations[validator].errorMessages.required_error;
        }

        if (validations[validator].emailValid && !isValidEmail(payload[validator])) {
            errors[validator] = validations[validator].errorMessages.emailValid_error;
        }

        if (validations[validator].validLatin && !isValidLatinChars(payload[validator])) {
            errors[validator] = validations[validator].errorMessages.validLatin_error;
        }

        if (validations[validator].validSymbols && !isValidSpecialChars(payload[validator])) {
            errors[validator] = validations[validator].errorMessages.validSymbols_error;
        }

        return errors;
    }, errors);
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_SKYCOPCARE_ERRORS:
            const user = validateUserInputs(
                state.validations.user,
                action.payload,
                action.checkRequired,
            );

            return {
                ...state,
                errors: {
                    ...user
                },
            };
        default:
    }

    return state;
}
