import React from 'react'
import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../helpers/styled_queries'
import { LangSelect } from '../../../components/helpers'
import V2MobileLangSelect from '../../../components/V2/V2MobileLangSelect'

import { connect } from 'react-redux'

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${desktop`
      .icon {
        padding: 12px 16px;
        margin-right: 96px;
      }
    `}

    ${tablet`
        justify-content: space-between;
    `}

    ${mobile`
        justify-content: space-between;
    `}

    .lang {
        display: flex;
        
    }
`

const SkycopPayHeader = ({ trans, text }) => {
    if (!trans) return null

    return (
        <Container>
            <div className="icon">
                <img src="/images/skycop_pay_logo.png" alt="logo"/>
            </div>
            <div className="lang">
                <LangSelect
                    position={{
                        bottom: '-170px',
                        left: '-200px',
                    }}
                    arrowPosition={{
                        bottom: '172px;',
                        left: '269px',
                    }}
                />
                <V2MobileLangSelect />
            </div>
        </Container>
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(SkycopPayHeader)
