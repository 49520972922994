import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Sidebar from "../../../shared/components/V2/Sidebar";
import { showModal } from "../../../shared/actions/v2_actions";
import FastPayoutSideBox from "./FastPayoutSideBox";
import FlightDistanceBox from "../../../shared/components/V2/FlightDistanceBox";

export const AbsoluteCorner = styled.div`
    transform: rotate(45deg);
    background: ${({ bgColor }) => bgColor || '#f5bb28'};
    position: absolute;
    height: 80px;
    width: 80px;
    right: -40px;
    top: -40px;
`;

export const StopWatch = styled.div`
    background: url('/images/fast.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 2;
    top: 6px;
    right: 5px;
`;

class InfoSidebar extends React.Component {
    render() {
        const { top, trans, distance, amount, totalAmount, noBackground } = this.props;

        if (!trans || !distance || (!totalAmount && !amount)) return null;

        return (
            <Sidebar top={top} showLogos={false} noBackground={!!noBackground}>
                <FlightDistanceBox 
                    noBackground={noBackground}
                />
                <FastPayoutSideBox />
            </Sidebar>
        );
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        fastPayouts: state.v2_hasFastPayout,
        amount: state.v2_amount,
        totalAmount: state.v2_total_amount,
        distance: state.v2_distance,
        trans: state.translations,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showModal }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InfoSidebar);
