import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    top: ${({ top }) => (top ? top : 0)}px;
    position: sticky;
    width: 312px;
    min-height: 400px;

    text-align: center;
    overflow: hidden;
    padding-top: ${({ pt }) => pt}px;
`

const InnerContainer = styled.div`
    border-radius: 8px;
    background: ${({ noBackground }) =>
        noBackground ? 'transparent' : '#fff'};
    box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);

    ${({ customStyle }) => customStyle}
`

const LogosContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;

    img {
        width: 235px;
        height: 48px;
    }
`

const Sidebar = ({
    top,
    pt = 0,
    children,
    showLogos,
    noBackground,
    customStyle = '',
}) => (
    <Container top={top} pt={pt}>
        <InnerContainer noBackground={!!noBackground} customStyle={customStyle}>
            {children}
        </InnerContainer>
        {showLogos && (
            <LogosContainer>
                <img
                    src="../images/v2/v2_logos.png"
                    alt="trust pilot and norton logo"
                />
            </LogosContainer>
        )}
    </Container>
)

export default Sidebar
