import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import NavLogin from './NavLogin'
import NavLogo from './NavLogo'
import TopBarLangSelect from '../../helpers/TopBarLangSelectOld'

const LoginContainer = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    @media (min-width: 990px) {
        max-width: 119rem;
    }
`

const FlexDiv = styled.div`
    display: flex;
    flex: 1;
`

const LoginToRight = styled.div`
    display: flex;
    justify-content: flex-end;
`

const LoginNavigation = ({
    logoLink,
    backUrl,
    showModal,
    hideLogin,
    isSkycopCare,
    signatureConfigs,
    skycopLogoType,
}) => (
    <LoginContainer id="pageTopNavbar">
        <FlexDiv>
            <NavLogo
                signatureConfigs={signatureConfigs}
                logoLink={logoLink}
                skycopLogoType={skycopLogoType}
            />
        </FlexDiv>
        {/* <LangSelectWrapper>
            <TopBarLangSelect
                isIcao
                position={{
                    top: '62px',
                    right: '-45px',
                }}
                arrowPosition={{
                    right: '50px',
                    top: '-9px',
                    left: 'unset',
                }}
            />
        </LangSelectWrapper> */}
        <TopBarLangSelect
            isIcao
            position={{
                top: '62px',
                right: '-45px',
            }}
            arrowPosition={{
                right: '50px',
                top: '-9px',
                left: 'unset',
            }}
        />
        {!hideLogin && (
            <LoginToRight>
                <NavLogin backUrl={backUrl} showModal={showModal} />
            </LoginToRight>
        )}
    </LoginContainer>
)

LoginNavigation.propTypes = {
    logoLink: PropTypes.string.isRequired,
    backUrl: PropTypes.string.isRequired,
}

export default LoginNavigation
