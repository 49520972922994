import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import Modal from '../../../shared/components/V2/Modal'
import {
    hideModal,
    showModal,
    resetV2Claim,
    setModalPromoCode,
} from '../../../shared/actions/v2_actions'
import { mobile } from '../../../shared/components/helpers/styled_queries'
import { CloseButton, V2Button } from '../../../shared/components/V2/V2Buttons'
import t from '../../../shared/translations'
import { FlightIcon } from '../../../shared/components/svgIcons'
import { modalTypes } from '../../../shared/constants'
import {
    getApiClient,
    applyTranslationElement,
    isFeatureEnabled,
} from '../../../main'
import { GiftIcon } from '../../../shared/components/V2/V2Icons'
import { paths, featureToggles } from '../../../shared/constants'

const Container = styled.div`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${mobile`
        padding: 16px;
    `}
`

const IconContainer = styled.div``

const Header = styled.h3`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #353d51;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 40px;
`

const PromoCodeHeader = styled.h3`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #353d51;
    padding-left: 8px;
    margin-top: 25px;
    margin-bottom: 10px;
`

const PromoCodeParentContainer = styled.div`
    width: 100%;
`

const PromoCodeContainer = styled.div`
    background: #ddefd6;
    border-radius: 8px;
    padding: 8px;
    color: #6b738d;
    margin-top: 20px;
    margin-bottom: -20px;

    svg {
        margin-right: 16px;
        min-width: 24px;
    }

    b {
        color: #e74848;
        font-weight: bold;
    }
`

const PromoCodeContent = styled.div`
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
`

const PromoCodeSaveButton = styled.div`
    margin: auto;
    width: max-content;
    margin-top: 12px;
`

const PromoCodeContentText = styled.div`
    margin-top: 4px;
`

const PromoCodeLeftContentIcon = styled.div`
    align-self: center;
`

const PromoCodeButtonLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
class PromoGiftModal extends React.Component {
    state = {
        promoCodeInternal: '',
        isLoading: true,
    }

    componentDidMount() {
        const {
            setModalPromoCode,
            v2_step,
            hideModal,
            postedClaim,
        } = this.props
        const apiClient = getApiClient()
        const url = '/api/promo-code/generate'
        const step = v2_step && v2_step.current.substring(1)
        const claimDraft = _get(postedClaim, 'id', null)

        if (
            !isFeatureEnabled(featureToggles.claimCompletePromoCodes) ||
            !claimDraft
        ) {
            this.setState({ isLoading: false })
            hideModal()
            return
        }

        setModalPromoCode('')
        this.setState({ isLoading: true })
        apiClient
            .post(url, {
                claimDraft,
                event: 'claim_complete',
                step,
            })
            .then(({ data }) => {
                this.setState({ promoCodeInternal: data.promo_code })
            })
            .catch(error => {
                this.setState({ promoCodeInternal: null })
                console.log('error', error)
                hideModal()
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    handlePromoCodeRedirect = () => {
        const { setModalPromoCode, hideModal } = this.props
        const { promoCodeInternal } = this.state
        setModalPromoCode(promoCodeInternal)

        hideModal()
        const subscriptionsRedirect = paths.V2.SC_SUBSCRIPTIONS
        browserHistory.push(subscriptionsRedirect)
    }

    render() {
        const { hideModal } = this.props
        const { promoCodeInternal, isLoading } = this.state

        return !isLoading ? (
            <Modal
                width="476px"
                height="auto"
                modalType={modalTypes.promoGiftModal}
            >
                <CloseButton
                    onClick={() => {
                        hideModal()
                    }}
                />

                <Container className="enabledTouch">
                    <IconContainer>
                        <GiftIcon width={54} height={54} />
                    </IconContainer>
                    <Header>{t`v2_claim.promo_gift_modal.title`}</Header>
                    <PromoCodeParentContainer>
                        <PromoCodeHeader>{t`v2_claim.sorry_modal.promo_code.title`}</PromoCodeHeader>
                        <PromoCodeContainer>
                            <PromoCodeContent>
                                <PromoCodeLeftContentIcon>
                                    <GiftIcon />
                                </PromoCodeLeftContentIcon>
                                <div>
                                    <PromoCodeContentText>{t`v2_claim.sorry_modal.promo_code.unlock`}</PromoCodeContentText>
                                    <PromoCodeContentText>
                                        {applyTranslationElement(
                                            t`v2_claim.sorry_modal.promo_code.use_promo_code`,
                                            '%promoCode%',
                                            <strong>{promoCodeInternal}</strong>
                                        )}
                                    </PromoCodeContentText>
                                    <PromoCodeContentText>{t`v2_claim.sorry_modal.promo_code.treat_claim`}</PromoCodeContentText>
                                    <PromoCodeContentText>{t`v2_claim.sorry_modal.promo_code.happy_travelling`}</PromoCodeContentText>
                                </div>
                            </PromoCodeContent>
                            <PromoCodeSaveButton>
                                <V2Button
                                    onClick={() =>
                                        this.handlePromoCodeRedirect()
                                    }
                                >
                                    <PromoCodeButtonLabel>
                                        <FlightIcon />
                                        {t`v2_claim.sorry_modal.promo_code.save_button`}
                                    </PromoCodeButtonLabel>
                                </V2Button>
                            </PromoCodeSaveButton>
                        </PromoCodeContainer>
                    </PromoCodeParentContainer>
                </Container>
            </Modal>
        ) : null
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        claimDraftId: state.claimDraftProps.id,
        v2_step: state.v2_step,
        postedClaim: state.v2_posted_claim,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { hideModal, showModal, resetV2Claim, setModalPromoCode },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoGiftModal)
