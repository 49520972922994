import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    passengers: [{
        id: 1,
        name: "",
        surname: "",
    }],
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_PASSENGER_DETAILS:
            return {...state, passengers: action.payload};
        case V2_ACTIONS.UPDATE_PASSENGER_DETAILS: {
            const { payload } = action;
            const { passengers } = state;
            let updatedPassengers;

            if (passengers) {
                updatedPassengers = passengers.map((item, index) => {
                    if (payload.id === item.id) {
                        return {...item, ...payload};
                    } 

                    return item;
                });
            }
            
            return { ...state, passengers: updatedPassengers}
        }
        case V2_ACTIONS.REMOVE_PASSENGER_DETAILS:
            return {...state, passengers: [...state.passengers, ...action.payload]};
        case V2_ACTIONS.CLEAR_PASSENGER_DETAILS:
            return {...state, passengers: null};
        default:
    }
    return state;
}