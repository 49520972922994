import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";
import { ExclamationMark } from "../../../../shared/components/V2/V2Icons";
import { mobile, tablet } from "../../../../shared/components/helpers/styled_queries";

const IconContainer = styled.div`
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    margin: 0 8px;

    svg {
        width: 16px;
        height: 16px;
    }

    ${mobile`
        margin: 0px 4px;
    `}
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
`;

const MainContainer = styled.div`
    padding: 8px 8px 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    ${tablet`
        justify-content: center;
    `}

    ${mobile`
        justify-content: center;
    `}

    .line-gap {
        letter-spacing: -0.9pt;
        margin: 0px 6px;

        ${mobile`
            margin: 0px 4px;
            margin-right: 8px;
            letter-spacing: -3pt;
        `}
    }
`;

const FailedFlightInformation = ({ selectedClaim }) => {
    let indents = [];
    let usedCities = [];

    const clm = selectedClaim;
    
    if (clm.flights.length < 2) {
        return null;
    }

    const sortedFlights = clm.flights.sort((a, b) => (a.flight_order > b.flight_order) ? 1 : -1);

    for (let i = 0; i < clm.flights.length; i++) {
        const probArrival = _get(clm, "problem_claim_flight.airport_arrival", {});
        const probDeparture = _get(clm, "problem_claim_flight.airport_departure", {});
        const flightArrival = _get(sortedFlights, `[${i}].airport_arrival`, {});
        const flightDeparture = _get(sortedFlights, `[${i}].airport_departure`, {});

        if (probArrival.city === flightArrival.city && probDeparture.city === flightDeparture.city) {
            usedCities.push(`${probArrival.iata}`);
            usedCities.push(`${probDeparture.iata}`);

            indents.push(
                <Container key={`flightSetNr${i}`} className="has-danger">
                    {`${probDeparture.iata}`}
                    <IconContainer>
                        <ExclamationMark fill="#f16464" color="#fff" />
                    </IconContainer>
                    {`${probArrival.iata}`}
                </Container>
            );
        } else {
            let probArr = `${probArrival.iata}`;
            let probDep = `${probDeparture.iata}`;
            let thisArr = `${flightArrival.iata}`;
            let thisDep = `${flightDeparture.iata}`;

            indents.push(
                <div key={`flightSetNr${i}`}>
                    {`${usedCities.indexOf(thisDep) === -1 && thisDep !== probArr ? thisDep : ""}`}
                    {
                        (usedCities.indexOf(thisDep) === -1 || usedCities.indexOf(thisArr) === -1) &&
                        <span className="line-gap">------</span>
                    }
                    {`${(usedCities.indexOf(thisArr) === -1 && thisArr !== probDep) ? thisArr : ""}`}
                </div>
            );
            usedCities.push(thisDep);
            usedCities.push(thisArr);
        }
    }

    return <MainContainer>{indents}</MainContainer>;
};

FailedFlightInformation.propTypes = {
    selectedClaim: PropTypes.object.isRequired,
};

export default FailedFlightInformation;
