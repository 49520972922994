import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import t from '../../../../shared/translations'
import { Row } from '../../../../shared/components/V2/Layout'
import { logKibana } from '../../../../shared/actions'
import { VirtualCardsContainer, Col } from './styles'
import { getVirtualCards } from '../../../../shared/actions/v2_actions'
import _get from 'lodash/get'

class VirtualCards extends React.Component {
    state = {
        virtualCardLink: '',
        virtualCardQr: '',
        installed: false,
    }
    componentDidMount() {
        const { postedClaim } = this.props
        const virtualCardQr = _get(postedClaim, 'virtual_card_qr_code', false)
        const virtualCardLink = _get(postedClaim, 'virtual_card_link', false)
        const installed = _get(postedClaim, 'installed', false)

        if (virtualCardQr && virtualCardLink) {
            this.setState({ virtualCardQr, virtualCardLink, installed })
        } else {
            getVirtualCards().then(response => {
                this.setState({
                    virtualCardQr: _get(response, 'virtual_card_qr_code'),
                    virtualCardLink: _get(response, 'virtual_card_link'),
                    installed: _get(response, 'installed'),
                })
            })
        }
    }

    render() {
        const { trans } = this.props
        const { virtualCardLink, virtualCardQr, installed } = this.state

        if (installed || !trans || !virtualCardLink) return null

        return (
            <VirtualCardsContainer>
                <Row>
                    <Col className="image">
                        <img
                            width="241.599px"
                            src="../images/virtual_cards.png"
                            alt="virtual cards"
                        />
                    </Col>
                    <Col className="content">
                        <Row className="content-text">
                            <h1>{t`vc.header`}</h1>

                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t`vc.text`,
                                }}
                            ></p>
                        </Row>
                        <Row>
                            <Col className="image-mobile">
                                <img
                                    width="241.599px"
                                    src="../images/virtual_cards.png"
                                    alt="virtual cards"
                                />
                            </Col>
                        </Row>
                        <Row className="wallets">
                            <Col>
                                <Row className="wallet-container">
                                    <div className="logo-img">
                                        <img
                                            width="15px"
                                            height="17.602px"
                                            src="../images/apple-logo.png"
                                            alt="apple logo"
                                        />
                                    </div>
                                    <div className="card-button">
                                        <a
                                            href={virtualCardLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                width="25.13px"
                                                height="25.13px"
                                                src="../images/apple-wallet-img.png"
                                                alt="apple wallet"
                                            />
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: t`vc.apple_text`,
                                                }}
                                            ></p>
                                        </a>
                                    </div>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="wallet-container">
                                    <div className="logo-img">
                                        <img
                                            width="15px"
                                            height="17.602px"
                                            src="../images/android-logo.png"
                                            alt="android logo"
                                        />
                                    </div>
                                    <div className="card-button">
                                        <a
                                            href={virtualCardLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                width="25.13px"
                                                height="25.13px"
                                                src="../images/android-wallet-img.png"
                                                alt="android waller"
                                            />
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: t`vc.android_text`,
                                                }}
                                            ></p>
                                        </a>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="qr-image">
                        {virtualCardQr && (
                            <img src={virtualCardQr} alt="virtual card qr" />
                        )}
                    </Col>
                </Row>
            </VirtualCardsContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        postedClaim: state.v2_posted_claim,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ logKibana }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualCards)
