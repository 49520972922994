import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import _get from 'lodash/get'
import store from '../../../shared/services/store'
import Modal from '../../../shared/components/V2/Modal'
import { V2Button2 } from '../../../shared/components/V2/V2Buttons'
import { ArrowUp } from '../../../shared/components/V2/V2Icons'
import {
    hideModal,
    showModal,
    resetV2Claim,
    setReferralModalShareCode,
    setModalPromoCode,
} from '../../../shared/actions/v2_actions'
import {
    desktop,
    mobile,
    tablet,
    tabletLG,
    tabletSM,
} from '../../../shared/components/helpers/styled_queries'
import { CloseButton2 } from '../../../shared/components/V2/V2Buttons'
import t from '../../../shared/translations'
import { V2_ACTIONS } from '../../../shared/actions/actionTypes'
import { V2_prePath, modalTypes } from '../../../shared/constants'
import { onlyUnique, getApiClient, isFeatureEnabled } from '../../../main'
import { paths, featureToggles } from '../../../shared/constants'

const Container = styled.div`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${mobile`
        padding: 16px;
    `}
`

const Header = styled.h3`
    font-family: 'Noto sans';
    font-size: 32px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #149954;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 20px;

    text-transform: capitalize;
    font-weight: 600;
`

const HeaderDesktop = styled(Header)`
    padding-top: 50px;
`

const HeaderDescription = styled(Header)`
    color: #000000;
    margin-bottom: 20px;
    margin-top: 0px;
`

// const PromoCodeHeader = styled.h3`
//     font-family: 'Open Sans', sans-serif;
//     font-size: 28px;
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: 1.14;
//     letter-spacing: normal;
//     color: #353d51;
//     padding-left: 8px;
//     margin-top: 25px;
//     margin-bottom: 10px;
// `

// const Subheader = styled.div`
//     font-family: 'Open Sans', sans-serif;
//     font-size: 14px;
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     letter-spacing: normal;
//     text-align: center;
//     color: black;
//     display: flex;
// `

const List = styled.ul`
    padding-left: 8px;
    width: 100%;
    list-style-type: none;
    text-align: center;

    li {
        font-family: 'Noto sans';
        color: #000000;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

const BtnsContainer = styled.div`
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    button {
        min-width: unset;
        min-height: 48px;
        padding: 0;
        font-weight: 600;
    }

    button:nth-child(2) {
        margin-top: 16px;
    }
`

export const ErrorContainer = styled.div`
    background: #fdefef;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    color: #6b738d;
    margin-top: 20px;

    svg {
        margin-right: 16px;
        min-width: 24px;
    }

    b {
        color: #e74848;
        font-weight: bold;
    }
`

// const PromoCodeParentContainer = styled.div`
//     width: 100%;
// `

// const PromoCodeContainer = styled.div`
//     background: #ddefd6;
//     border-radius: 8px;
//     padding: 8px;
//     color: #6b738d;
//     margin-top: 20px;
//     margin-bottom: -20px;

//     svg {
//         margin-right: 16px;
//         min-width: 24px;
//     }

//     b {
//         color: #e74848;
//         font-weight: bold;
//     }
// `

// const PromoCodeContent = styled.div`
//     display: flex;
//     padding-left: 8px;
//     padding-right: 8px;
// `

// const PromoCodeSaveButton = styled.div`
//     margin: auto;
//     width: max-content;
//     margin-top: 12px;
// `

// const PromoCodeContentText = styled.div`
//     margin-top: 4px;
// `

// const PromoCodeLeftContentIcon = styled.div`
//     align-self: center;
// `

// const PromoCodeButtonLabel = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `

// const SubheaderContainer = styled.div`
//     width: 100%;
//     display: flex;
//     padding-left: 8px;
// `

const ImageWrapper = styled.div`
    position: relative;
    height: 400px;
    background-image: url(/images/sorryModalHands.png);

    background-size: cover;
    background-repeat: no-repeat;
`

const DesktopTabletContent = styled.div`
    display: flex;
    justify-direction: column;
    width: 100%;
    height: 100%;
    ${mobile`
    display: none;
`}
    ${tabletSM`
    display: none;
`}

${tablet`
    display: flex;
`}
`
const MobileContent = styled.div`
    ${desktop`
    display: none;
    `}
    ${tablet`
        display: none;
    `}
    ${tabletLG`
        display: none;
    `}
`

const V2ButtonStyled = styled(V2Button2)`
    border-radius: 16px;
    font-family: 'Noto sans';
    height: 58px;
`

const ImageTitle = styled.div`
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    padding: 42px 58px 32px 58px;
    text-transform: capitalize;
`

const ImageTitleDesktop = styled(ImageTitle)`
    padding: 90px 32px 32px 32px;
`

const ImageDescription = styled.div`
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 29.6px;
    text-align: center;
    color: #fafafa;
    padding: 0px 42px 52px 42px;
    span {
        font-weight: 600 !important;
    }
`

const ReferralBtnTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ReferralLinkText = styled.div`
    margin-right: 6px;
    text-wrap: nowrap;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const StyledBtn = styled(V2Button2)`
    width: 70%;
    height: 52px;

    div {
        height: 100%;
        align-items: center;
        display: flex;
        margin-right: 8px;
        :hover {
            transform: translateX(0px);
            svg {
                ${desktop`
                transform: translate(10px, 0px);
                transition: transform 0.4s ease;
                `}
            }
        }
    }

    svg {
        margin-left: 6px;
    }
`

const DesktopImageWrapper = styled.div`
    width: 320px;
    background-image: url(/images/sorryModalHands.png);

    background-size: cover;
    background-repeat: no-repeat;
`

const DesktopFormContent = styled.div`
    width: 530px;

    ${tablet`
        width: 640px;
    `}
`

const ImageDescriptionDesktop = styled(ImageDescription)`
    padding: 0px 30px 120px 30px;
`

class SorryModal extends React.Component {
    state = {
        promoCodeInternal: '',
        isLoading: true,
    }

    componentDidMount() {
        const { claimDraftId, setModalPromoCode, v2_step } = this.props
        const apiClient = getApiClient()
        const url = '/api/promo-code/generate'
        const step = v2_step && v2_step.current.substring(1)

        if (
            !isFeatureEnabled(featureToggles.reject_promo_codes) ||
            !claimDraftId
        ) {
            this.setState({ isLoading: false })
            return
        }

        setModalPromoCode('')
        this.setState({ isLoading: true })
        apiClient
            .post(url, {
                claimDraft: claimDraftId,
                event: 'reject',
                step,
            })
            .then(({ data }) => {
                this.setState({ promoCodeInternal: data.promo_code })
            })
            .catch(error => {
                this.setState({ promoCodeInternal: null })
                console.log('error', error)
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    renderErrors = () => {
        const { errors } = this.props

        if (Array.isArray(errors)) {
            return (
                <List>
                    {errors.map((error, index) => {
                        return <li key={index}>{error}</li>
                    })}
                </List>
            )
        }

        if (!!Object.keys(errors).length) {
            const keys = Object.keys(errors)
            const results = (keys || []).map(key =>
                _get(errors, `[${key}][0]`, errors[key])
            )

            return (
                <List>
                    {results.filter(onlyUnique).map((error, index) => {
                        return <li key={index}>{error}</li>
                    })}
                </List>
            )
        }

        if (typeof errors === 'function') {
            return errors()
        }
    }

    tryNewFlight = () => {
        const { resetV2Claim, hideModal, newFlight } = this.props

        if (typeof newFlight === 'function') {
            newFlight()
        }
        resetV2Claim()
        hideModal()
        browserHistory.push(V2_prePath)
        store.dispatch({
            type: V2_ACTIONS.STEP_RESET,
            payload: 0,
        })
    }

    editFlight = () => {
        this.props.hideModal()
    }

    handlePromoCodeRedirect = () => {
        const { setModalPromoCode, hideModal, errors } = this.props
        const { promoCodeInternal } = this.state
        setModalPromoCode(promoCodeInternal)

        hideModal()
        // eslint-disable-next-line
        dataLayer.push({
            event: 'skycop_care_activate',
            activationReason: errors,
        })
        const subscriptionsRedirect = paths.V2.SC_SUBSCRIPTIONS
        browserHistory.push(subscriptionsRedirect)
    }

    handleReferralClick = () => {
        const { lang, setReferralModalShareCode, hideModal } = this.props
        if (window.SkycopReferral) {
            hideModal()
            window.SkycopReferral.ReferralModal.call(
                lang || 'en',
                shareData => {
                    if (shareData.code) {
                        setReferralModalShareCode(shareData.code)
                    }
                }
            )
        }
    }

    render() {
        const { hideModal, errors } = this.props
        const { isLoading } = this.state
        return !isLoading ? (
            <Modal
                width="850px"
                height="644px"
                modalType={modalTypes.sorryModal}
                radius="24px"
            >
                <CloseButton2
                    id="btn-sorry-modal-close"
                    onClick={() => {
                        hideModal()
                        // eslint-disable-next-line
                        dataLayer.push({
                            event: 'skycop_care_close',
                            activationReason: errors,
                        })
                    }}
                />
                <DesktopTabletContent>
                    <DesktopFormContent>
                        <Container className="enabledTouch">
                            <HeaderDesktop>{t`v2_claim.sorry_modal.title`}</HeaderDesktop>
                            <HeaderDescription>{t`v2_claim.sorry_modal.subheader.description`}</HeaderDescription>
                            {errors && this.renderErrors()}
                            <BtnsContainer>
                                <V2ButtonStyled
                                    type="borderBtn"
                                    id="btn-edit-flight"
                                    onClick={this.editFlight}
                                >{t`v2_claim.sorry_modal.button.edit`}</V2ButtonStyled>
                                <V2ButtonStyled
                                    id="btn-try-new-flight"
                                    onClick={this.tryNewFlight}
                                >{t`v2_claim.sorry_modal.button.try_new`}</V2ButtonStyled>
                            </BtnsContainer>
                        </Container>
                    </DesktopFormContent>
                    <DesktopImageWrapper>
                        <ImageTitleDesktop>{t`v2_claim_sorry_modal_image_title`}</ImageTitleDesktop>
                        <ImageDescriptionDesktop
                            dangerouslySetInnerHTML={{
                                __html: t`v2_claim_sorry_modal_image_description`,
                            }}
                        />
                        <ButtonWrapper>
                            <StyledBtn
                                id="btn-referral"
                                onClick={() => this.handleReferralClick()}
                            >
                                <ReferralBtnTitle>
                                    <ReferralLinkText>
                                        {t`referal.text.share_and_earn`}
                                    </ReferralLinkText>
                                    <ArrowUp fill="#FFFFFF" />
                                </ReferralBtnTitle>
                            </StyledBtn>
                        </ButtonWrapper>
                    </DesktopImageWrapper>
                </DesktopTabletContent>

                <MobileContent>
                    <ImageWrapper>
                        <ImageTitle>{t`v2_claim_sorry_modal_image_title`}</ImageTitle>
                        <ImageDescription
                            dangerouslySetInnerHTML={{
                                __html: t`v2_claim_sorry_modal_image_description`,
                            }}
                        ></ImageDescription>
                        <ButtonWrapper>
                            <StyledBtn
                                id="btn-referral"
                                onClick={() => this.handleReferralClick()}
                            >
                                <ReferralBtnTitle>
                                    <ReferralLinkText>
                                        {t`referal.text.share_and_earn`}
                                    </ReferralLinkText>
                                    <ArrowUp fill="#FFFFFF" />
                                </ReferralBtnTitle>
                            </StyledBtn>
                        </ButtonWrapper>
                    </ImageWrapper>
                    <Container className="enabledTouch">
                        <Header>{t`v2_claim.sorry_modal.title`}</Header>
                        <HeaderDescription>{t`v2_claim.sorry_modal.subheader.description`}</HeaderDescription>
                        {errors && this.renderErrors()}
                        <BtnsContainer>
                            <V2ButtonStyled
                                type="borderBtn"
                                id="btn-edit-flight"
                                onClick={this.editFlight}
                            >{t`v2_claim.sorry_modal.button.edit`}</V2ButtonStyled>
                            <V2ButtonStyled
                                id="btn-try-new-flight"
                                onClick={this.tryNewFlight}
                            >{t`v2_claim.sorry_modal.button.try_new`}</V2ButtonStyled>
                        </BtnsContainer>
                    </Container>
                </MobileContent>

                {/* <ImageWrapper>
                    <img
                        width="100%"
                        src="/images/sorryModalHands.png"
                        alt="sorry hands"
                    />
                </ImageWrapper> */}
                {/* <Container className="enabledTouch">
                    <SorryIcon color="#b0caec" height="70" />
                    <Header>{t`v2_claim.sorry_modal.title`}</Header>
                    <SubheaderContainer>
                        <Subheader id="subheader-sorry">{t`v2_claim.sorry_modal.subheader`}</Subheader>
                    </SubheaderContainer>
                    {errors && this.renderErrors()}
                    {promoCodeInternal ? (
                        <PromoCodeParentContainer>
                            <PromoCodeHeader>{t`v2_claim.sorry_modal.promo_code.title`}</PromoCodeHeader>
                            <PromoCodeContainer>
                                <PromoCodeContent>
                                    <PromoCodeLeftContentIcon>
                                        <GiftIcon />
                                    </PromoCodeLeftContentIcon>
                                    <div>
                                        <PromoCodeContentText>{t`v2_claim.sorry_modal.promo_code.unlock`}</PromoCodeContentText>
                                        <PromoCodeContentText>
                                            {applyTranslationElement(
                                                t`v2_claim.sorry_modal.promo_code.use_promo_code`,
                                                '%promoCode%',
                                                <strong>
                                                    {promoCodeInternal}
                                                </strong>
                                            )}
                                        </PromoCodeContentText>
                                        <PromoCodeContentText>{t`v2_claim.sorry_modal.promo_code.treat_claim`}</PromoCodeContentText>
                                        <PromoCodeContentText>{t`v2_claim.sorry_modal.promo_code.happy_travelling`}</PromoCodeContentText>
                                    </div>
                                </PromoCodeContent>
                                <PromoCodeSaveButton>
                                    <V2Button
                                        onClick={() =>
                                            this.handlePromoCodeRedirect()
                                        }
                                    >
                                        <PromoCodeButtonLabel>
                                            <FlightIcon />
                                            {t`v2_claim.sorry_modal.promo_code.save_button`}
                                        </PromoCodeButtonLabel>
                                    </V2Button>
                                </PromoCodeSaveButton>
                            </PromoCodeContainer>
                        </PromoCodeParentContainer>
                    ) : (
                        <BtnsContainer>
                            <V2Button
                                id="btn-try-new-flight"
                                onClick={this.tryNewFlight}
                            >{t`v2_claim.sorry_modal.button.try_new`}</V2Button>
                            <V2Button
                                id="btn-edit-flight"
                                onClick={this.editFlight}
                                type="borderBtn"
                            >{t`v2_claim.sorry_modal.button.edit`}</V2Button>
                        </BtnsContainer>
                    )}
                </Container> */}
            </Modal>
        ) : null
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        error: state.error.errorMessage,
        claimDraftId: state.claimDraftProps.id,
        v2_step: state.v2_step,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal,
            showModal,
            resetV2Claim,
            setModalPromoCode,
            setReferralModalShareCode,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SorryModal)
