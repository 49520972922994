import {
    AUTH_ERROR,
    REMOVE_ERROR,
    SOCIAL_LOGIN_ERROR,
} from '../actions/actionTypes'

const INITIAL_STATE = { errorMessage: null }

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_ERROR:
            return { ...state, errorMessage: action.payload }
        case REMOVE_ERROR:
            return { state, errorMessage: null, errorResponse: null }
        case SOCIAL_LOGIN_ERROR:
            return { ...state, errorResponse: { ...action.payload } }
        default:
    }
    return state
}
