import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { browserHistory } from 'react-router';
import { paths } from '../constants';
import { showModal } from '../actions/v2_actions';

export default function (ComposedComponent) {
    class Authentication extends Component {

        componentDidMount() {
            this._handleUserRedirect(this.props);
        }

        componentDidUpdate(nextProps) {
            this._handleUserRedirect(nextProps);
        }

        _handleUserRedirect = (props) => {
            const { location } = this.props;
            const { isAuthenticated } = props;

            const backUrl = `${location.pathname}${location.search}`;
            if (props && !isAuthenticated) {
                browserHistory.push(`${paths.V2.LOGIN}?p=${backUrl}`);
            }
        }

        render() {
            const { isAuthenticated } = this.props;

            if (!isAuthenticated) {
                return null;
            }

            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return {
            isAuthenticated: state.auth.authenticated
        };
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            showModal
        }, dispatch)
    }

    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}