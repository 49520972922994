import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import Helmet from "react-helmet";
import styled from "styled-components";
import { getUserAgreement, resetAgreement } from "../../shared/actions/index";
import { loginPageShow, loginPageHide, appendDayOrdinal, loadScreenOff } from "../../main";
import t from "../../shared/translations";
import { paths } from "../../shared/constants";
import { AgreementContainer, HeaderContainer } from "../../shared/components/V2/V2Agreement";
import { V2Button } from "../../shared/components/V2/V2Buttons";
import { PageContent, Row, Col } from "../../shared/components/V2/Layout";

const MainContainer = styled.div`
    max-width: 992px;
    margin: 0 auto 32px;
`;

const DateText = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    color: #353d51;
    letter-spacing: normal;
    margin: 0;
    text-align: center;
`;

class OwnerAgreementSigned extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signature: "",
            signatureDate: "",
            termsCheck: false,
        };
    }

    UNSAFE_componentWillMount() {
        loginPageShow();
    }

    UNSAFE_componentWillUpdate() {
        loginPageShow();
    }

    componentDidMount() {
        loginPageShow();
        loadScreenOff();
        if (!this.props.passenger_name) {
            browserHistory.push("/");
            loginPageHide();
        }
    }

    componentWillUnmount() {
        this.props.resetAgreement();
    }

    getMonthTrans = (monthNumber) => {
        const months = [
            t`month.January`,
            t`month.February`,
            t`month.March`,
            t`month.April`,
            t`month.May`,
            t`month.June`,
            t`month.July`,
            t`month.August`,
            t`month.September`,
            t`month.October`,
            t`month.November`,
            t`month.December`,
        ];

        return months[monthNumber];
    };

    renderAgreement = () => {
        const { passenger_name, userAgreement } = this.props;
        if (passenger_name) {
            let data = passenger_name;
            let fullName = `${data.name} ${data.surname}`;
            let claimId = data.id;

            if (userAgreement) {
                return (
                    <AgreementContainer
                        dangerouslySetInnerHTML={{
                            __html: userAgreement,
                        }}
                    />
                );
            } else if (claimId) {
                this.props
                    .getUserAgreement(claimId, fullName, "sig")
                    .then(() => this.forceUpdate());
            }
            return <div />;
        }
    };

    signDate = () => {
        if (
            this.props.signature &&
            this.props.signature.data.signatureDate &&
            this.props.signature.data.signatureDate !== ""
        ) {
            var sDate = this.props.signature.data.signatureDate;
            var timeZoneConvert = sDate.getTimezoneOffset() / -60;
            var timeZone = "";
            if (timeZoneConvert > 0) timeZone = `+${timeZoneConvert}`;
            else timeZone = `-${timeZoneConvert}`;
            var signDate = `${this.getMonthTrans(sDate.getMonth())} 
                 ${appendDayOrdinal(("0" + sDate.getDate()).slice(-2))}, 
                 ${sDate.getFullYear()}, 
                 ${("0" + sDate.getHours()).slice(-2)}:${(
                "0" + sDate.getMinutes()
            ).slice(-2)}:${("0" + sDate.getSeconds()).slice(-2)} 
                 GMT ${timeZone}`;
            return (
                <DateText>
                    {t`sign_form.date_signed_at`} {signDate}
                </DateText>
            );
        }
    };

    render() {
        const { trans, userAgreement } = this.props;

        if (trans && userAgreement) {
            return (
                <MainContainer>
                    <PageContent>
                        <Helmet>
                            <title>{`Skycop - ${t`sign_form.title_part1`} ${t`sign_form.title_part2_bold`}`}</title>
                        </Helmet>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <HeaderContainer>
                                    <h1>
                                        {t`sign_form.title_part1`}{" "}
                                        <span>{t`sign_form.title_part2_bold`}</span>
                                    </h1>
                                </HeaderContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                {this.renderAgreement()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>{this.signDate()}</Col>
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <Col md={4} lg={4}>
                                <V2Button
                                    onClick={() =>
                                        browserHistory.push(paths.V2.LOGIN)
                                    }
                                >
                                    {t`signature_form.button.close`}
                                </V2Button>
                            </Col>
                        </Row>
                    </PageContent>
                </MainContainer>
            );
        } else return null;
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        claim: state.claim,
        signature: state.signature,
        trans: state.translations,
        clmAmt: state.claimable_amt,
        payouts: state.payouts,
        passenger_name: state.passenger_name,
        userAgreement: state.userAgreement,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getUserAgreement, resetAgreement }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OwnerAgreementSigned);
