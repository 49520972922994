import styled from 'styled-components'
import {
    tablet,
    mobile,
    tabletSM,
    desktop,
} from '../../../../shared/components/helpers/styled_queries'
import { PageContent } from '../../../../shared/components/V2/Layout'
import { Col as Col1 } from '../../../../shared/components/V2/Layout'

export const Col = styled(Col1)`
    width: auto;
    padding: 14px;
`

export const VirtualCardsContainer = styled(PageContent)`
    margin-bottom: 16px;
    padding: 16px;
    flex-direction: row;
    flex: unset;
    margin: 0;
    border: unset;
    box-shadow: unset;
    align-self: end;

     ${desktop`
    margin-right: 24px;
        `}

    ${mobile`
        flex-direction: column;
    `}

    ${tablet`
        flex-direction: column;
    `}

    .image {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        ${mobile`
            display: none;
        `}

        ${tabletSM`
            display: none;
        `}
    }

    .qr-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 130px;
            height: 130px;
        }
    }

    .content {
        .image-mobile {
            display: none;

            ${mobile`
                {
                    display: flex;
                    justify-content: center;
                
                }
            `}
            ${tabletSM`
                {
                    display: flex;
                    justify-content: center;
                
                }
            `}
        }

        .content-text {
            display: flex;
            flex-direction: column;

            h1 {
                color: #353d51;
                font-family: Open Sans;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 22px;
            }

            p {
                color: #353d51;
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: normal;
                line-height: 180%;
                margin-bottom: 40px;

                span {
                    color: #32b86a;
                    font-weight: 700;
                }
            }
        }
    }

    .wallets {
        display: flex;
        flex-direction: row;

        ${mobile`
            > div {
                justify-content: center;
                display: flex;
            }      
        `}

        ${tabletSM`
            > div {
                justify-content: center;
                display: flex;
            }      
        `}
    }

    .wallet-container {
        flex-wrap: nowrap;
        flex-direction: row;

        ${mobile`
            {
                justify-content: center;
               
            }
        `}

        ${tabletSM`
            {
                justify-content: center;
               
            }
        `}

        .logo-img {
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .card-button {
            border: 1px solid #f2f5f9;
            border-radius: 8px;

            a {
                padding: 6px 8px;
                display: flex;
                text-decoration: none;

                p {
                    margin: 0;
                    padding-left: 6px;
                    color: #000;
                    font-family: Open Sans;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 11px;

                    b {
                        font-size: 11px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
`
