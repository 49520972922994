import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import t from '../../translations'

export const Text = styled.div`
    cursor: pointer;
    user-select: none;
    padding-left: 3px;

    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    margin: 0;
    padding-top: 16px;
    font-style: normal;
    font-stretch: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #fe6050;

    span {
        text-decoration: underline;
    }
`

const SuggestedText = ({ text, callback }) => {
    if (!text) {
        return null
    } else {
        return (
            <Text className="form-control-feedback" onClick={callback}>
                {t`common.info.did_you_mean_email`}&nbsp;<span>{text}</span>?
            </Text>
        )
    }
}

SuggestedText.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    callback: PropTypes.func,
}

SuggestedText.defaultProps = {
    text: '',
    callback: () => null,
}

export default SuggestedText
