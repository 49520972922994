import { V2_ACTIONS } from '../../actions/actionTypes'

const INITIAL_STATE = {
    shareCode: '',
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_REFERRAL_SHARE_CODE:
            return {
                ...state,
                shareCode: action.payload,
            }
        default:
    }
    return state
}
