import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { modalTypes } from '../../../../shared/constants'
import Modal from '../../../../shared/components/V2/Modal'
import { CloseIcon } from '../../../../shared/components/V2/V2Icons'
import t from '../../../../shared/translations'
import { hideModal } from '../../../../shared/actions/v2_actions'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import { browserHistory } from 'react-router'
import { getApiClient, getStorage } from '../../../../main'
import { paths } from '../../../..//shared/constants.js'
import {
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'

const CloseIconWrapper = styled.div`
    justify-content: end;
    cursor: pointer;
    display: flex;
    justify-content: end;
`

const ModalContentWrapper = styled.div`
    margin: 20px;
`

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 30px;
    color: #333333;
    margin-bottom: 30px;
`

const ContentText = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    color: #353d51;
`

const ButtonWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
`

const StyledButton = styled(V2Button)`
    min-width: 105px;
    width: 105px;
    margin-left: auto;
    height: fit-content;
    background: #f16964;

    img {
        margin-right: 8px;
        vertical-align: middle;
    }

    ${tablet`
    margin: 0;
    margin-top: 12px;
`}

    ${mobile`
    margin: 0;
    margin-top: 12px;
`}
`

const BackButton = styled(StyledButton)`
    background: #fff;
    margin-right: 20px;
`

class CancelModal extends React.Component {
    handleModalYesClick = () => {
        const { registeredFlightIds, hideModal } = this.props

        const authString = `Bearer ${getStorage('token')}`
        const url = 'api/account/smart-delay-cancel-flights'

        const apiClient = getApiClient()

        apiClient
            .post(
                url,
                { ...registeredFlightIds },
                { headers: { Authorization: authString } }
            )
            .then(() => {
                browserHistory.push(paths.V2_SMART_DELAY.STEP_SMART_DELAY)
            })
            .catch(error => console.error(error))
            .finally(() => hideModal())
    }

    render() {
        const { trans, hideModal } = this.props

        if (!trans) {
            return null
        }

        return (
            <Modal
                width="476px"
                height="auto"
                modalType={modalTypes.smartDelayCancelRegistration}
                radius="20px"
            >
                <ModalContentWrapper>
                    <CloseIconWrapper onClick={() => hideModal()}>
                        <CloseIcon
                            width="28px"
                            height="28px"
                            stroke="#7f8ea4"
                        />
                    </CloseIconWrapper>
                    <div>
                        <HeaderWrapper>{t`smart_delay.registration_completed_modal_header`}</HeaderWrapper>
                        <ContentText>{t`smart_delay.registration_completed_modal_text1`}</ContentText>
                        <ContentText>{t`smart_delay.registration_completed_modal_text2`}</ContentText>
                        <ButtonWrapper>
                            <div>
                                <BackButton
                                    id="btn-back"
                                    type="borderBtn"
                                    onClick={() => hideModal()}
                                >
                                    {t`smart_delay.registration_completed_button_back`}
                                </BackButton>
                            </div>
                            <div>
                                <StyledButton
                                    id="btn-yes"
                                    onClick={() => this.handleModalYesClick()}
                                >
                                    {t`smart_delay.registration_completed_button_yes`}
                                </StyledButton>
                            </div>
                        </ButtonWrapper>
                    </div>
                </ModalContentWrapper>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        registeredFlightIds: state.smartDelay.registeredFlightIds,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ hideModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelModal)
