import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { v2DefaultConfig } from '../../theme/config'
import { tablet, mobile } from '../helpers/styled_queries'

export const H1 = ({ children }) => <StyledH1>{children}</StyledH1>

export const FlexSpan = styled.span`
    align-items: start;
    display: ${({ hideTitle }) => (hideTitle ? 'none' : 'flex')};
    padding: 0px 12px 40px;
    position: relative;
    ${({ bottomPadding }) =>
        bottomPadding && `padding-bottom: ${bottomPadding}`};

    ${({ disableTopBorder }) => (disableTopBorder ? '' : 'padding-top: 20px;')}

    animation: appear-step 0.3s linear;
    @keyframes appear-step {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    ${mobile`
        padding: 0px 16px 0px;
        justify-content: center;
        ${({ mobileBottomPadding }) =>
            mobileBottomPadding && `padding-bottom: ${mobileBottomPadding}`};
        ${({ hasHeaderInfo }) => hasHeaderInfo && `flex-direction: column;`};
    `}

    ${tablet`
        padding: 16px 16px 12px;
        justify-content: center;
        ${({ mobileBottomPadding }) =>
            mobileBottomPadding && `padding-bottom: ${mobileBottomPadding}`};
        ${({ hasHeaderInfo }) => hasHeaderInfo && `flex-direction: column;`};
    `}

    ${({ hasHeaderInfo }) =>
        hasHeaderInfo &&
        `
        ${H1} {
            display: inline-flex;
        }
    `}

`

const StyledH1 = styled.span`
    font-size: 28px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    color: ${v2DefaultConfig.colors.darkText};
    display: block;

    ${mobile`
        text-align: center;
        font-size: 20px;
    `}

    ${tablet`
        text-align: center;
        font-size: 26px;
    `}
`

const StyledH3 = styled(StyledH1)`
    font-size: 18px;
`

export const H3 = ({ children }) => <StyledH3>{children}</StyledH3>

const StyledH4 = styled(StyledH1)`
    font-size: 14px;
`

export const H4 = ({ children }) => <StyledH4>{children}</StyledH4>

const StyledSubHeader = styled.div`
    background: #dfe3e9;
    border-radius: 4px;
    display: block;
    margin-bottom: 5px;
    font-family: sans-serif;
    font-size: 14px;
    padding: 4px 16px;
    color: #353d51;
    width: fit-content;
`

export const SubHeader = ({ children }) => (
    <StyledSubHeader>{children}</StyledSubHeader>
)

export const PageTitleMobile = ({
    title,
    subTitle,
    subHeader,
    metaDesc,
    children,
    bottomPadding,
    disableTopBorder,
    titleText,
    hasHeaderInfo,
    hideTitle,
}) => (
    <FlexSpan
        hideTitle={hideTitle}
        hasHeaderInfo={hasHeaderInfo}
        disableTopBorder={disableTopBorder}
        bottomPadding={bottomPadding}
        id="page-view-title"
    >
        {title && !hideTitle && (
            <H1>
                <Helmet>
                    <title>{`Skycop - ${titleText || title || ''}`}</title>
                    {metaDesc && <meta name="description" content={metaDesc} />}
                </Helmet>
                {typeof title === 'function' ? title() : title}
            </H1>
        )}
        {subTitle && <H4>{subTitle}</H4>}
        {subHeader && <SubHeader>{subHeader}</SubHeader>}
        {children}
    </FlexSpan>
)
