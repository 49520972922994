import React from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import t from '../../../shared/translations'
import styled from 'styled-components'
import _get from 'lodash/get'
import { showModal } from '../../../shared/actions/v2_actions'
import LoginModal from '../components/LoginModal'
import { PageWrapper } from './common'
import {
    loginPageShow,
    loginPageHide,
    isProduction,
    loadScreenOff,
} from '../../../main'
import {
    tablet,
    mobile,
} from '../../../shared/components/helpers/styled_queries'
import { paths } from '../../../shared/constants'

///////////// CASES
//
// 0 - Register (All fields)
//
// 1 - Email & Pass
//
// 2 - Facebook
//
// 3 - Facebook & Pass
//
// 4 - Google
//
// 5 - Google & Pass
//
// 6 - Facebook & Google
//
// 7 - Google, Facebook & Pass
//
/////////////

const Container = styled.div``

const StyledPageWrapper = styled(PageWrapper)`
    margin: 24px auto;
    height: fit-content;
    padding: 24px;

    ${tablet`
        padding: 16px;
    `}

    ${mobile`
        padding: 8px;
    `}
`

class Login extends React.Component {
    state = {
        caseParams: {},
    }

    componentDidMount() {
        const { isAuthenticated, location } = this.props
        loginPageShow(true)

        if (isAuthenticated) {
            const redirect = _get(location, 'query.p', paths.V2.MY_CLAIMS)
            browserHistory.replace(redirect)
        } else {
            this.props.setParentState({
                isCentered: true,
                hideNav: true,
            })
            const params = _get(location, 'query.params', '')
            if (params) {
                this.parseCaseParams(params)
            }
        }
    }

    componentWillMount() {
        loginPageShow(true)
    }

    componentDidUpdate(prevProps, prevState) {
        loginPageShow(true)
        loadScreenOff()
    }

    componentWillUnmount() {
        loginPageHide()
        this.props.setParentState({
            isCentered: false,
            hideNav: false,
        })
    }

    parseCaseParams = eData => {
        try {
            const dataString = window.atob(eData)
            const dataObj = JSON.parse(dataString)
            const loginCase = _get(dataObj, 'loginCase', 0).toString()
            this.setState({
                caseParams: {
                    ...dataObj,
                    loginCase: loginCase === '0' ? null : loginCase,
                },
            })
        } catch (e) {
            if (!isProduction()) {
                console.log(e)
            }
        }
    }

    getCaseTranslation = () => {
        const { caseParams } = this.state
        switch (caseParams.loginCase) {
            case '0':
                return 'register'
            case '1':
                return 'email_login'
            case '2':
            case '4':
            case '6':
                return 'social_login'
            case '3':
            case '5':
            case '7':
                return 'social_and_password'
            default:
                return ''
        }
    }

    render() {
        const { caseParams } = this.state
        const { location, trans } = this.props
        const register = _get(location, 'query.tab', '') === 'register'
        const redirect = _get(location, 'query.p', paths.V2.MY_CLAIMS)

        if (!trans) {
            return null
        }

        return (
            <Container>
                <StyledPageWrapper
                    register={caseParams.loginCase === '0' || register}
                >
                    <LoginModal
                        isNotModal={true}
                        onClose={() => browserHistory.replace('/')}
                        callback={() => browserHistory.replace(redirect)}
                        loginCase={caseParams.loginCase || '0'}
                        prefillEmail={caseParams.email || ''}
                        register={caseParams.loginCase === '0' || register}
                        customDescription={
                            caseParams.loginCase &&
                            t`v2_claim.login.account_exists.${this.getCaseTranslation(
                                caseParams.loginCase
                            )}`
                        }
                        padding="0px"
                    />
                </StyledPageWrapper>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        isAuthenticated: state.auth.authenticated,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
