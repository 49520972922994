import styled from "styled-components";
import { Link } from "react-router";
import { desktop, tablet, mobile } from "../../../../shared/components/helpers/styled_queries";
import {
    Row,
    Col,
    PageContent,
} from "../../../../shared/components/V2/Layout";
import InfoBox from "../../components/InfoBox";

export const IbanRowContainer = styled(Row)`
        ${desktop`
            ${({ isMobile }) => isMobile && "display: none;"}
        `}

        ${tablet`
            ${({ isMobile }) => !isMobile && "display: none;"}
        `}
        ${mobile`
            ${({ isMobile }) => !isMobile && "display: none;"}
        `}
`;

export const StyledInfoBox = styled(InfoBox)`
    svg {
        min-width: 24px;
    }

    margin-bottom: 0px;
`;

export const StyledLink = styled(Link)`
    width: max-content;
    border-radius: 24px;
    border: solid 1px #ffffff;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
    padding: 12px 16px;
    text-decoration: none;

    &:hover {
        opacity: 0.6;
        color: #ffffff;
    }

    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

export const PaymentContainer = styled(PageContent)`
    height: fit-content;

    .btn-row {
        ${Col} {
            display: flex;
            justify-content: center;
            margin-top: 16px;

            button {
                ${desktop`
                    width: unset;
                `}
            }
        }
    }
`;

export const CheckBoxInner = styled.div`
    margin-left: 8px;

    p {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #353d51;
    }
`;

export const ErrorText = styled.div`
    padding: 0 0 0 16px;
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #ffffff;

    span {
        font-weight: bold;
    }
`;
