import { V2_ACTIONS } from '../../actions/actionTypes';
import { airlinesArrayUnique } from '../../../render';

export default function (state = [], action) {
    switch (action.type) {
        case V2_ACTIONS.USED_AIRLINES:
            return airlinesArrayUnique([
                ...state,
                ...action.payload,
            ]);
        default:
    }
    return state;
}