import React from 'react'
import styled from 'styled-components'
import { ListItem } from './ListItem'

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;

    > div {
        margin-right: 28px;
    }
`

export const List = ({ list }) => {
    return (
        <Container>
            {list.map((item, idx) => (
                <ListItem key={idx} text={item.key} />
            ))}
        </Container>
    )
}
