import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import {
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'
import styled from 'styled-components'

export const LightButton = styled(V2Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;
    height: 40px;
    border-radius: 100px;
    color: #1d194d;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: fit-content;
    border-radius: 26px;
    border: 1px solid #1d194d;
    background: #f6fafa;
`

export const DarkButton = styled(V2Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;
    height: 40px;
    background: #493fc2;
    border-radius: 100px;
    color: #fff;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: fit-content;
`

export const Button = styled(V2Button)`
    width: fit-content;
    border-color: #32b86a;
    color: #32b86a;

    &:hover {
        color: #32b86a;
    }

    svg {
        margin-left: 8px;
        vertical-align: middle;
    }

    ${tablet`
        width: 40%;
    `}

    ${mobile`
        width: 100%;
    `}
`

export const ScButton = styled(V2Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 40px;
    border-radius: 100px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: fit-content;

    svg {
        margin-left: 8px;
        vertical-align: middle;
    }

    ${tablet`
        width: 40%;
        min-width: 200px;
    `}

    ${mobile`
        width: 100%;
        min-width: 40px;
    `}
`
