import React from "react";
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from "react-helmet";
import styled from "styled-components";
import LetterPreferences, { Item } from './LetterPreferences';
import {
    setLanguage,
    setEmailPreferences,
    getEmailPreferences,
    showSuccessMessage,
    resetMessage,
} from '../../../shared/actions/index';
import { loadScreenOn, loginPageHide, loadScreenOff, callAsyncActionWithConfig } from '../../../main';
import { CheckBox } from "../../../shared/components/Form";
import { Button } from '../../../shared/components/Buttons';
import t from '../../../shared/translations';
import FlashMessage from "../../../shared/components/FlashMessage";

const UserInfo = styled.div`
    color: #666;
    margin-bottom: 30px;
`;

const SubmitButton = styled(Button)`
    width: auto;
    margin: 2.5rem auto 0;
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
    }
`;

const UserEmail = styled.div`
    padding-top: 5px;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-size: 1.8rem;
    line-height: 1.44;
`;

const GreenLink = styled.a`
    color: ${({ theme }) => theme.colors.primaryColor};
`;

const UserLetterPreferences = styled.div`
    ${Item} {
        padding: 30px 0;
        border-top: 1px solid #e3e7e7;
        &:last-of-type {
            border-bottom: 1px solid #e3e7e7;
        }
    }
`;

const Header = styled.h1`
    padding-top: 1rem;
    margin-bottom: 2rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 4rem;
    }
`;

const Title = styled.h3`
    margin-bottom: 1rem;
`;

const CenteredItem = styled.div`
    padding: 30px 0 0;
    text-align: center;
    display: flex;
    justify-content: center;
    ${({ isBlurred }) => isBlurred && `
        opacity: 0.8;
    `}
`;

class EmailSubscriptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: "",
            email: "",
            subscribe: false,
            unsubscribe_from_all: false,
        }
    }

    async UNSAFE_componentWillMount() {
        const { location, getEmailPreferences } = this.props;

        if (location.query) {
            var reqObj = location.query;
            if (!reqObj["token"]) {
                browserHistory.push("/");
            }
            
            const response = await callAsyncActionWithConfig(getEmailPreferences, reqObj["token"]);
            
            if (response) {
                this.setState({
                    token: reqObj["token"],
                        ...response
                    });
            } else {
                loginPageHide();
                browserHistory.push("/");
            }
        }
    }

    componentWillUnmount() {
        loginPageHide();
        this.props.resetMessage();
    }

    handleCheckboxChange = (e) => {
        const value = this.state[e.target.name];
        this.setState({ [e.target.name]: !value });
        if (!value) {
            this.setState({ unsubscribe_from_all: false });
        }
    };

    handleUnsubscribeEvent = (e) => {
        const value = this.state[e.target.name];
        this.setState({ [e.target.name]: !value });
        if (!value) {
            this.setState({
                subscribe: false,
            });
        }
    };

    _submitPreferences = () => {
        const { subscribe, token } = this.state;
        var fd = new FormData();
        if (subscribe) {
            fd.append("user_settings_edit[subscribe]", subscribe);
        }
        loadScreenOn();
        this.props.setEmailPreferences(fd, token).then(() => {
            this.props.showSuccessMessage('common.flash_message.success.email_preferences');
            window.scrollTo(0, 0);
            loadScreenOff();
        }).catch(() => loadScreenOff("login-page"));
    };

    render() {
        const { trans } = this.props;
        if (trans) {
            return (
                <div className="container">
                    <Helmet>
                        <title>{`Skycop - ${t`common.subtitle.letter_preferences`}`}</title>
                    </Helmet>
                    <div className="row">
                        <div className="col-xs-24 col-lg-24 h-pb60">
                            <Header>
                                {t`common.subtitle.letter_preferences`}
                            </Header>
                            <UserInfo>
                                <div>
                                    {t`common.subtitle.your_email`}
                                </div>
                                <UserEmail>
                                    {this.state.email}
                                </UserEmail>
                            </UserInfo>
                            <FlashMessage/>
                            <UserLetterPreferences>
                                <Title>{t`subscription.subtitle.stay_informed`}</Title>
                                <LetterPreferences
                                    onChange={this.handleCheckboxChange}
                                    subscribe={this.state.subscribe}
                                    showReadMore={true}
                                    disableReadMore={true}
                                />
                                <CenteredItem isBlurred={!this.state.unsubscribe_from_all}>
                                    <CheckBox
                                        onChange={this.handleUnsubscribeEvent}
                                        label={t`subscription.unsubscribe_from_all`}
                                        name="unsubscribe_from_all"
                                        checked={this.state.unsubscribe_from_all}
                                    />
                                </CenteredItem>
                                <div className="text-center h-pl-0">
                                    <SubmitButton
                                        onClick={this._submitPreferences}>
                                        {t`subscription.button.update_email_preferences`}
                                    </SubmitButton>
                                </div>
                            </UserLetterPreferences>
                        </div>
                        <div className="col-md-18 offset-md-2 col-lg-16 offset-lg-4 h-pb60">
                            <div className="login-footer align-items-center text-center">
                                <div className="container justify-content-between">
                                    {t`common.info.privacy_policy`}
                                    &nbsp;
                                    <GreenLink
                                        target="_blank"
                                        href={`${t`URL.logo`}/privacy-policy/`}>
                                        {t`common.title.privacy_policy`}
                                    </GreenLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else return null;
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setLanguage,
        setEmailPreferences,
        getEmailPreferences,
        showSuccessMessage,
        resetMessage,
    }, dispatch);
};

export default connect(
    state => ({
        errorMessage: state.error.errorMessage,
        trans: state.translations,
        lang: state.language,
    }),
    mapDispatchToProps
)(EmailSubscriptions);