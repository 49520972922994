import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import store from "../../../shared/services/store";
import Modal from "../../../shared/components/V2/Modal";
import {
    hideModal,
    showModal,
    resetV2Claim,
} from "../../../shared/actions/v2_actions";
import { CloseButton } from "../../../shared/components/V2/V2Buttons";
import { V2_ACTIONS } from "../../../shared/actions/actionTypes";
import { V2_prePath, modalTypes } from "../../../shared/constants";
import SocialPage from "../steps/SocialPage";

const Container = styled.div`
    width: 100%;
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const List = styled.ul`
    width: 100%;
    margin-top: 32px;
    padding-left: 20px;

    li {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #353d51;
    }
`;

class SocialShareModal extends React.Component {

    renderErrors = () => {
        const { errors } = this.props;

        if (Array.isArray(errors)) {
            return (
                <List>
                    {errors.map((error, index) => {
                        return <li key={index}>{error}</li>;
                    })}
                </List>
            );
        }

        if (!!Object.keys(errors).length) {
            const keys = Object.keys(errors)

            return (
                <List>
                    {keys.map((key, index) => {
                        return <li key={index}>{errors[key]}</li>;
                    })}
                </List>
            );
        }

        
    };

    tryNewFlight = () => {
        const { resetV2Claim, hideModal, newFlight } = this.props;

        if (typeof newFlight === "function") {
            newFlight();
        }
        resetV2Claim();
        hideModal();
        browserHistory.push(V2_prePath);
        store.dispatch({
            type: V2_ACTIONS.STEP_RESET,
            payload: 0,
        });
    };

    editFlight = () => {
        this.props.hideModal();
    };

    render() {
        const {
            hideModal,
        } = this.props;

        return (
            <Modal width="476px" height="auto" modalType={modalTypes.socialShareModal}>
                <CloseButton onClick={hideModal} />
                <Container className="enabledTouch">
                    <SocialPage/>
                </Container>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        error: state.error.errorMessage,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ hideModal, showModal, resetV2Claim }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SocialShareModal);
