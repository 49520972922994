import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { desktop } from "../../helpers/styled_queries";

const ToggleBtn = styled.button`
    background-color: #fff;
    display: flex;
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    border: 0 none;
    color: #3b445a;
    box-shadow: none;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    position: relative;
    
    :focus {
        outline: none;
    }

    ${desktop`
        display: none;
    `}
`;

const MenuIcon = styled.div`
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    position: relative; 
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: inherit;
        height: inherit;
        position: absolute;
        left: 0;
        opacity: ${props => (props.active ? 0 : 1)};
        transition: all 0.2s ease-in-out;   
        span {
            border-radius: 4rem;
            width: 16px;
            margin: 2px;
            height: 2px;
            background-color: #3b445a;
            display: block;
        }
    }   
    div:nth-child(2) {
        top: -4px;
        opacity: ${props => (props.active ? 1 : 0)};    
        span:nth-child(3) {
            margin-top: -4px;
            transform: rotate(-45deg);
        }   
        span:nth-child(2) {
            transform: rotate(45deg);
        }   
        span:nth-child(1) {
            opacity: 0;
        }
    }
`;

const ToggleButton = ({ showMenu, active }) => (
    <ToggleBtn onClick={showMenu}>
        <MenuIcon active={active}>
            <div>
                <span />
                <span />
                <span />
            </div>
            <div>
                <span />
                <span />
                <span />
            </div>
        </MenuIcon>
    </ToggleBtn>
);

ToggleButton.propTypes = {
    showMenu: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
}

export default ToggleButton;