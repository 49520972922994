import { useState, useEffect } from 'react'
import _ from 'lodash'
import { getApiClient } from '../../../main'

export const useLeadData = leadId => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setErrror] = useState(false)

    const apiClient = getApiClient()

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                const [leadResponse, passengerResponse] = await Promise.all([
                    apiClient.get(`api/leads/${leadId}`),
                    apiClient.get(`/api/leads/${leadId}/passengers`),
                ])

                const flightNumber = _.get(
                    leadResponse.data,
                    'flights[0].flight_number',
                    '-'
                )
                const arrivalAirport = _.get(
                    leadResponse.data,
                    'flights[0].arival_airport.iata',
                    '-'
                )
                const departureAirport = _.get(
                    leadResponse.data,
                    'flights[0].departure_airport.iata',
                    '-'
                )

                setData({
                    ...leadResponse.data,
                    passengers: passengerResponse.data,
                    flightNumber,
                    arrivalAirport,
                    departureAirport,
                })
                setLoading(false)
            } catch (e) {
                setErrror(e)
                setLoading(false)
            }
        }

        fetchData()
        return () => setLoading(false)
    }, [])

    return {
        data,
        loading,
        error,
    }
}
