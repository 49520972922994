import { getApiClient, dataURItoBlob } from '../../../../main'

export const getUiid = async () => {
    const url = '/api/get-uuid'
    const apiClient = getApiClient()

    try {
        const response = await apiClient.get(url)

        if (response) {
            return response.data.uuid
        }
    } catch (error) {
        return error
    }
}

export function uploadFormDocument(file, uuid) {
    const url = `/api/add-claim-draft-document`
    const apiClient = getApiClient()

    if (!file || !uuid) {
        return
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
            let blob = dataURItoBlob(reader.result)
            let fd = new FormData()
            fd.append('claim_draft_document[claimDraft]', uuid)
            fd.append('claim_draft_document[file]', blob, file.name)
            return apiClient
                .post(url, fd)
                .then(res => {
                    return resolve(res.data.id)
                })
                .catch(err => {
                    return { err }
                })
        }
    })
}

export const saveClaimForm = async (uuid, data) => {
    const url = '/api/pay-form'
    const apiClient = getApiClient()
    let fd = new FormData()

    if (!uuid) return

    fd.append('claim_draft[id]', uuid)
    fd.append('claim_draft[draft]', JSON.stringify(data))

    try {
        const response = await apiClient.post(url, fd)

        if (response) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
