import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    signature: "",
    payoutPlan: null,
    termsConditions: false,
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_SIGNATURE:
            return {...state, signature: action.payload};
        case V2_ACTIONS.SET_PAYOUT_PLAN:
            return {...state, payoutPlan: action.payload};
        case V2_ACTIONS.CLEAR_SIGNATURE:
            return {...state, signature: null};
        case V2_ACTIONS.CLEAR_PAYOUT_PLAN:
            return {...state, payoutPlan: null};
        case V2_ACTIONS.TOGGLE_TERMS_CONDITIONS:
            return {...state, termsConditions: !state.termsConditions};
        default:
    }
    return state;
}

export const getStepSeven = state => state.v2_step_seven;