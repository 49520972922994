import { API_CLIENT } from '../actions/actionTypes';

export default function (state = null, action) {
    switch (action.type) {
        case API_CLIENT:
            return action.payload;
        default:
    }

    return state;
}