import store from "../services/store";
import { getApiClient, isFeatureEnabled } from "../../main";
import { featureToggles } from "../constants";
import { V2_ACTIONS } from "../actions/actionTypes";

class Translation {

    key = null;

    constructor(key) {
        this.key = key;
    }

    postMissingTranslation = () => {
        const apiClient = getApiClient();
        const url = "/api/translations";
        apiClient.post(url, this.getMissingTranslationData(), null, "postTranslations");
    }

    postNewTranslationDomain = () => {
        const apiClient = getApiClient();
        const url = "/api/translations";
        apiClient.post(url, this.getNewTranslations(), null, "postTranslations").then(() => {
            store.dispatch({
                type: V2_ACTIONS.V2_NEW_TRANSLATIONS,
                payload: {
                    [this.key]: '',
                },
            })
        });
    }

    getNewTranslations = () => {
        let fd = new FormData();
        fd.append('translations[0]', this.key || null);
        fd.append("domain", "app");

        return fd;
    }

    getMissingTranslationData = () => {
        let fd = new FormData();
        fd.append('translations[0]', this.key || null);
        fd.append("domain", "web");

        return fd;
    }

    getTranslation = (trans, defaultTrans) => {
        let translation = this.key;
        const v2Translations = store.getState().v2_translations;

        if (!translation) {
            return '';
        }

        if (trans && trans[this.key]) {
            translation = trans[this.key];
        }

        if (translation === this.key && defaultTrans && defaultTrans[this.key] && !isFeatureEnabled(featureToggles.disableTransFallback)) {
            translation = defaultTrans[this.key];
        }

        if (translation === this.key && trans && !(this.key in trans)) {
            this.postMissingTranslation();
        }

        if (isFeatureEnabled(featureToggles.deprecatedKeysFeature) && v2Translations && this.key && !(this.key in v2Translations)) {
            this.postNewTranslationDomain();
        }

        return translation;
    }

    translate = () => {
        const trans = store.getState().translations;
        const defaultTrans = store.getState().default_translations;

        if (trans || defaultTrans) {
            return this.getTranslation(trans, defaultTrans);
        } else return '';
    }
}

export default Translation;
