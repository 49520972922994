import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { hideModal } from '../../actions/v2_actions'
import {
    desktop,
    mobile,
    tablet,
    tabletLG,
    tabletSM,
} from '../../components/helpers/styled_queries'
import Locky from 'react-locky'
import _get from 'lodash/get'

const Wrapper = styled.div`
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #f3f5f7;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2147483647;
    display: flex;
    justify-content: center;
    align-items: center;

    ${mobile`
        padding-top: 24px;
        padding-bottom: 24px;
    `}

     ${tablet`
        padding-top: 24px;
        padding-bottom: 24px;
    `}

     ${tabletSM`
        padding-top: 24px;
        padding-bottom: 24px;
    `}

     ${tabletLG`
        padding-top: 24px;
        padding-bottom: 24px;
    `}

    ${({ fadeIn }) =>
        fadeIn
            ? `
        animation: fadeIn;
        animation-duration: 0.3s;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    `
            : ''}
`

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: block;
    opacity: 1;
    background-color: #fff;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;

    ${desktop`
        width: ${({ width }) => (width ? width : '60vw')};
        height: ${({ height }) => (height ? height : '80vh')};
        max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
        max-height: ${({ maxHeight }) =>
            maxHeight ? maxHeight : 'calc(100% - 40px)'};
        // border: 1px solid #f3f5f7;
        border-radius: ${({ radius }) => (radius ? radius : '5px')};
        
        ${({ allowOverflow }) =>
            allowOverflow &&
            `
            overflow: unset;
            -webkit-overflow-scrolling: unset;
            overscroll-behavior: unset;
        `}
    `}

    
    ${tabletLG`
          border-radius: 24px;
                  height: ${({ height }) => (height ? height : '80vh')};

    `}

      ${tabletSM`
          border-radius: 24px;
                  height: ${({ height }) => (height ? height : '80vh')};

    `}

    ${tablet`
        width: ${({ keepDesktopStyles }) =>
            keepDesktopStyles ? 'unset' : '100%'};
        height: ${({ keepDesktopStyles }) =>
            keepDesktopStyles ? 'unset' : '100%'}
          border-radius: 24px;

    `}

    ${mobile`
        width: ${({ keepDesktopStyles }) =>
            keepDesktopStyles ? 'unset' : '100%'};
        height: ${({ keepDesktopStyles }) =>
            keepDesktopStyles ? 'unset' : '100%'};
        border-radius: 24px;
    `}
`

const ESC_KEY = 27
export const ENTER_KEY = 13
export const SPACE_KEY = 32

class Modal extends React.Component {
    node = React.createRef()
    wrapper = React.createRef()

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            if (this.wrapper && this.wrapper.current) {
                this.wrapper.current.focus()
            }
        }
    }

    handleOutsideClick = ({ target }) => {
        const { hideModal, handleClose } = this.props

        if (this.node.current.contains(target)) {
            return
        }

        if (handleClose && typeof handleClose === 'function') {
            handleClose()
        }

        hideModal()
    }

    handleKeyDown = e => {
        const { hideModal, onEnterClick, onEscClick } = this.props

        if (e.keyCode === ESC_KEY) {
            if (typeof onEscClick === 'function') {
                onEscClick()
            } else {
                hideModal()
            }
        }

        if (e.keyCode === ENTER_KEY) {
            if (typeof onEnterClick === 'function') {
                onEnterClick()
            }
        }
    }

    render() {
        const {
            modal,
            children,
            width,
            height,
            modalType,
            maxWidth,
            maxHeight,
            allowOverflow,
            fadeIn = false,
            style,
            radius,
            keepDesktopStyles,
        } = this.props

        return _get(modal, 'modalType', '') === modalType ? (
            <Locky noDefault>
                <Wrapper
                    fadeIn={fadeIn}
                    innerRef={this.wrapper}
                    tabIndex="1"
                    onKeyDown={this.handleKeyDown}
                    onMouseDown={this.handleOutsideClick}
                    onTouchEnd={this.handleOutsideClick}
                >
                    <Container
                        radius={radius}
                        keepDesktopStyles={keepDesktopStyles}
                        width={width}
                        height={height}
                        maxWidth={maxWidth}
                        maxHeight={maxHeight}
                        innerRef={this.node}
                        allowOverflow={allowOverflow || false}
                        style={style}
                    >
                        {children}
                    </Container>
                </Wrapper>
            </Locky>
        ) : null
    }
}

function mapStateToProps(state) {
    return {
        modal: state.toggle_modal,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ hideModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
