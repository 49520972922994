import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { BoxContainer, PrimaryText, PrimaryLinkText } from '../../shared/components/styledComponents';
import { setThemeConfigOptions } from '../../shared/actions/index';
import { defaultConfig } from '../../shared/theme/config';
import { setStorage, getStorage, removeStorage } from '../../main';
import { Button, BorderButton } from '../../shared/components/Buttons';
import { RadioButton, InputField } from '../../shared/components/Form';

const BrandedInputs = styled.div`
    margin: 10px 0px;
    position: relative;

    span {
        vertical-align: middle;
        cursor: pointer;

        &:hover {
            color: ${({ theme }) => theme.colors.midHoverColor};
        }
    }

    .no-hover {
        &:hover {
            color: inherit!important;
            cursor: default;
        }
    }
`;

const StyledButton = styled(Button)`
    padding: 1.6rem;
    font-size: 1.5rem;
    margin: 5px 0px;
`;

const GetBackButton = styled(StyledButton)`
    color: #666;
    background: #d0d0d0;
    box-shadow: 0.2rem 0.2rem 0 0 rgba(0,0,0,.04);
    @media screen and (max-width: 767px) {
        display: none;
    }

    &:focus {
        color: #666;
    }

    &:hover {
        color: #fff;
        background: #c0c0c0;
    }
`;

const ComponentsPreview = styled.div`
    border: .1rem solid ${({ theme }) => theme.colors.secondaryBorderColor};
    padding: 10px 0px;

    input, span, div, button, label {
        margin-right: 10px;
        width: auto;
        display: inline-block;
    }
`;

const Container = styled.div`
    textarea {
        resize: both;
        width: 100%;
        max-width: 100%;
        max-height: 300px;
    }
`;

const ColorContainer = styled.div`
    vertical-align: middle;
    margin-right: 10px;
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
`;

const ColorDisplay = styled.div`
    width: 36px;
    height: 14px;
    border-radius: 2px;
    background: ${({ background }) => background};
`;

const ColorPickerWrapper = styled.div`
    position: absolute;
    z-index: 5;
    left: 0;
    top: 24px;
`;

const ColorPickerClose = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

class BrandedPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            colorsArray: Object.keys(defaultConfig.colors).map((key) => ({
                label: [key],
                value: defaultConfig.colors[key]
            })),
            configCopied: false,
        }
    }

    componentDidMount() {
        const { location } = this.props;

        if (location && location.query) {
            const token = location.query['token'];
            if (!token || token !== "NyLSEicWyKGhnhvDwQcx") {
                browserHistory.push('/');
            } else if (!getStorage('brandedPreview')) {
                setStorage('brandedPreview', '1');
            }
        }
    }

    setGlobalConfigValue = (e, obj = 'colors') => {
        const { setThemeConfigOptions } = this.props;
        setThemeConfigOptions({
            [obj]: {
                [e.target.name]: e.target.value,
            }
        });
    }

    resetTheme = () => {
        const { setThemeConfigOptions } = this.props;

        setThemeConfigOptions({
            colors: {
                ...defaultConfig.colors,
            },
            logo: {
                ...defaultConfig.logo,
            }
        });
    }

    setLogoUrl = (e) => {
        const { files, name } = e.target;
        if (files.length > 0) {
            const file = files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const val = {
                    target: {
                        name,
                        value: reader.result,
                    }
                };
                this.setGlobalConfigValue(val, 'logo');
            };
        }
    }

    copyClipboard = (val) => {
        let inp = document.createElement('input');
        document.body.appendChild(inp)
        inp.value = val;
        inp.select();
        document.execCommand('copy', false);
        inp.remove();
        this.setState({ configCopied: true });
        setTimeout(() => {
            this.setState({ configCopied: false });
        }, 5000)
    }

    handleFullConfig = () => {
        const { setThemeConfigOptions } = this.props;
        const inp = document.getElementById("clipboardThemeInput");
        if (inp) {
            const { value } = inp;
            const tryJson = JSON.parse(value);
            if (tryJson) {
                const fullTheme = { colors: {}, logo: {} };
                if (tryJson.colors) {
                    fullTheme.colors = tryJson.colors;
                }
                if (tryJson.logo) {
                    fullTheme.logo = tryJson.logo;
                }
                setThemeConfigOptions({
                    colors: {
                        ...fullTheme.colors,
                    },
                    logo: {
                        ...fullTheme.logo,
                    }
                });
            }
        }
        try {
        } catch (e) {
        }
    }

    leaveBrandedPreview = () => {
        removeStorage('brandedPreview');
        browserHistory.push('/');
        window.location.reload(true);
    }

    handlePickerChange = (color, name, e) => {
        e.preventDefault();
        this.setGlobalConfigValue({
            target: {
                name,
                value: color.hex,
            }
        });
    }

    toggleColorPicker = (name) => {
        const currState = this.state[name];
        if (!currState || currState === false) {
            this.setState({ [name]: true });
        }
    }

    getThemeUrl = () => {
        const { propsTheme } = this.props;
        return `${window.location.origin}/?theme=${window.btoa(JSON.stringify(propsTheme))}`;
    }

    render() {
        const { trans, propsTheme } = this.props;
        if (trans && propsTheme) {
            return (
                <Container className="container">
                    <div className="row">
                        <BoxContainer className="h-ml20 claim-panel col-md-20 h-pb60" style={{ marginRight: 20 }}>
                            <h1 className="h-pt10 h-mb10">Branded Claim Form Setup</h1>
                            <h3>Colors should be in Hex format.</h3>
                            <hr />
                            <div style={{ marginBottom: 20 }}>
                                <BrandedInputs>
                                    <span className="no-hover" style={{ display: 'block', margin: 0 }}>
                                        Website Logo (URL or Upload. Recommended: 140x40): 
                                    </span>
                                    <input
                                        style={{ marginLeft: 0, marginTop: 10 }}
                                        type="file"
                                        accept=".svg, image/png, image/jpeg"
                                        name="url"
                                        onChange={this.setLogoUrl}
                                    />
                                    <span style={{ marginRight: 15, marginLeft: 10}} className="no-hover">OR URL:</span>
                                    <input
                                        type="text"
                                        name="url"
                                        onChange={(e) => this.setGlobalConfigValue(e, 'logo')}
                                    />
                                </BrandedInputs>
                            </div>
                            <div style={{ marginBottom: 40 }}>
                                {
                                    this.state.colorsArray.map((item) => (
                                        <BrandedInputs key={item.label} >
                                            <ColorContainer>
                                                <ColorDisplay
                                                    background={propsTheme.colors[item.label]}
                                                    onClick={() => this.toggleColorPicker(`${item.label}visible`)}
                                                />
                                                {
                                                    this.state[`${item.label}visible`] &&
                                                    <ColorPickerWrapper>
                                                        <ColorPickerClose
                                                            onClick={() => this.setState({
                                                                [`${item.label}visible`]: false,
                                                            })}
                                                        />
                                                        <SketchPicker
                                                            color={propsTheme.colors[item.label]}
                                                            onChangeComplete={(color, e) => this.handlePickerChange(color, item.label, e)}
                                                        />
                                                    </ColorPickerWrapper>
                                                }
                                            </ColorContainer>
                                            <span onClick={() => this.toggleColorPicker(`${item.label}visible`)}>
                                                {item.label}
                                            </span>
                                        </BrandedInputs>))
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <StyledButton onClick={() => this.copyClipboard(JSON.stringify(propsTheme))}>Copy config</StyledButton>
                                </div>
                                <div className="col-md-6">
                                    <GetBackButton onClick={this.resetTheme}>Reset config</GetBackButton>
                                </div>
                                <div className="col-md-6" style={{ position: 'absolute', right: 40 }}>
                                    <StyledButton href={this.getThemeUrl()} target="_blank">Preview in new window</StyledButton>
                                    <div style={{ textAlign: 'right' }}>
                                        <PrimaryLinkText onClick={() => this.copyClipboard(this.getThemeUrl())}>
                                            Copy preview URL
                                        </PrimaryLinkText>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.configCopied && 
                                <PrimaryText>The configuration has been copied to your clipboard.</PrimaryText>
                            }
                            <hr />
                            <h3>Components preview</h3>
                            <ComponentsPreview>
                                <RadioButton label="Unchecked" value={false} disabled onChange={() => null} name='test1' />
                                <RadioButton label="Checked" value={true} checked onChange={() => null} name='test2' />
                                <InputField value="Input field text" placeholder="test" onChange={() => null} name='test3' />
                                <InputField placeholder="Input placeholder" value="" onChange={() => null} name='test4' />
                                <StyledButton>Regular Button</StyledButton>
                                <BorderButton background="#fff">Border Button</BorderButton>
                                <span>Primary text</span>
                                <PrimaryText>Secondary text</PrimaryText>
                            </ComponentsPreview>
                            <hr />
                            <div className="row">
                                <div className="col-md-10">
                                    <h3>Paste full configuration</h3>
                                    <textarea
                                        style={{ display: 'block', marginBottom: 10 }}
                                        type="textarea"
                                        id="clipboardThemeInput"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <h3>Configuration example</h3>
                                    <pre>
                                        {JSON.stringify(defaultConfig, null, '\t')}
                                    </pre>
                                </div>
                            </div>
                            <BorderButton onClick={this.handleFullConfig} background="#fff">Load config</BorderButton>
                            <div className="row">
                                <div className="col-md-10">
                                    <BorderButton
                                        style={{ marginTop: 60 }}
                                        onClick={this.leaveBrandedPreview}
                                        background="#fff"
                                    >
                                        Leave Branded preview
                                    </BorderButton>
                                </div>
                            </div>
                        </BoxContainer>
                    </div>
                </Container>
            )
        } else {
            return null;
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setThemeConfigOptions }, dispatch);
}

export default connect(
    (state) => ({
        trans: state.translations,
        propsTheme: state.propsTheme,
    }),
    mapDispatchToProps,
)(BrandedPreview);