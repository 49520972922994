import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { tablet, mobile } from '../../helpers/styled_queries'

const borderWidth = '1px'

const Container = styled.div`
    position: relative;
    font-family: 'Open Sans', sans-serif;
    height: 56px;

    .select-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        top: 18px;
        left: 18px;
        transform: translateY(calc(-50% + 15px));
        z-index: 1;
        opacity: 0.7;
        color: #043d5d !important;
    }

    .Select {
        position: relative;
        box-sizing: border-box;
        line-height: 3.8rem;
        font-size: 1.4rem;
        height: 56px;
        font-family: 'Open Sans', sans-serif;

        div,
        input,
        span {
            box-sizing: border-box;
            line-height: 4.6rem;
            font-size: 1.4rem;
        }

        &.is-disabled {
            &:hover {
                box-shadow: none !important;
                cursor: not-allowed;
            }

            > .Select-control {
                background: #f2f5f9;
                border: 1px solid #f2f5f9;
                color: #7f8fa4;
                font-weight: 600;
                height: 56px;

                &:hover {
                    box-shadow: none;
                    cursor: not-allowed;
                }
            }

            .Select-value-label {
                color: #7f8fa4 !important;
                font-weight: 600 !important;

                &:hover {
                    box-shadow: none !important;
                    cursor: not-allowed;
                }
            }

            .Select-arrow-zone {
                cursor: default;
                pointer-events: none;
                opacity: 0.35;
            }
        }

        .Select-aria-only {
            display: inline-block;
            height: 1px;
            width: 1px;
            margin: -1px;
            clip: rect(0, 0, 0, 0);
            overflow: hidden;
            float: left;
        }
    }

    .Select-control {
        background-color: #fff;
        border-radius: 0px;
        border: 1px solid #cfd6dd;
        color: #333;
        cursor: default;
        display: table;
        border-spacing: 0;
        border-collapse: separate;
        height: 4.6rem;
        font-size: 1.4rem;
        outline: none;
        font-weight: 600;
        overflow: hidden;
        position: relative;
        width: 100%;

        &:hover,
        &:active,
        &:focus {
            border: 1px solid #043d5d;
            box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
        }

        &:disabled,
        &.disabled {
            background: #f2f5f9;
            border: ${borderWidth} solid #7f8fa4;
            color: #7f8fa4;
            font-weight: 600;
        }

        &.warn {
            border: ${borderWidth} solid #ff713b;
        }

        &.danger {
            border: ${borderWidth} solid #FE6050;
        }

        .Select-input {
            &:focus {
                outline: none;
            }
        }

        &:not(.is-searchable) {
            > .Select-input {
                outline: none;
                border-color: #043d5d;
            }
        }
    }

    .is-searchable {
        &.is-open {
            > .Select-control {
                cursor: text;
                border-color: #043d5d;
            }
        }

        &.is-focused {
            &:not(.is-open) {
                > .Select-control {
                    border-color: #043d5d;

                    cursor: text;
                }
            }
        }
    }

    .is-open {
        > .Select-control {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            background: #fff;
            border-color: #043d5d;

            .Select-arrow {
                top: -2px;
                border-color: transparent transparent #043d5d;
                border-width: 0 5px 5px;
            }
        }

        .Select-arrow {
            &:hover {
                > .Select-arrow {
                    border-top-color: #043d5d;
                }
            }
        }
    }

    .is-focused {
        &:not(.is-open) {
            > .Select-control {
                border-color: #043d5d;
            }
        }

        .Select-input {
            > input {
                cursor: text;
                font-weight: 600;
            }
        }

        body .select-icon {
            opacity: 1;
        }
    }

    .Select-placeholder {
        color: #043d5d;
        height: 56px;
        line-height: 5.8rem !important;
        bottom: 0;
        left: 0;
        padding: 0px 17px;
        ${({ hasIcon }) =>
            hasIcon ? 'padding-left: 47px;' : 'padding-left: 16px;'}
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: normal;
    }

    .Select--single {
        > .Select-control {
            .Select-value {
                bottom: 0;
                color: #aaa;
                left: 0;
                padding: 1.2rem 1.6rem;
                padding-right: 40px;
                ${({ hasIcon }) =>
                    hasIcon ? 'padding-left: 47px;' : 'padding-left: 16px;'}
                line-height: 3.8rem;
                vertical-align: middle;
                position: absolute;
                right: 0;
                top: 0;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 600;
            }
        }
    }

    .has-value {
        &.Select--single {
            > .Select-control {
                .Select-value {
                    .Select-value-label {
                        color: #353d51;
                        font-weight: 600;
                        line-height: 3.8rem;

                        ${mobile`
                            font-size: 13px;
                        `}

                        ${tablet`
                            font-size: 13px;
                        `}
                    }
                    a {
                        &.Select-value-label {
                            cursor: pointer;
                            text-decoration: none;
                            &:hover,
                            &:focus {
                                color: #353d51;
                                outline: none;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        &.is-pseudo-focused {
            &.Select--single {
                > .Select-control {
                    .Select-value {
                        .Select-value-label {
                            color: #353d51;
                            font-weight: normal;
                            &:hover {
                            }
                        }
                    }

                    a {
                        &.Select-value-label {
                            cursor: pointer;
                            text-decoration: none;
                            &:hover,
                            &:focus {
                                color: #353d51;
                                outline: none;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .Select-input {
                opacity: 0;
            }
        }
    }

    .Select-input {
        height: 56px;
        line-height: 10px;
        ${({ hasIcon }) =>
            hasIcon ? 'padding-left: 47px;' : 'padding-left: 16px;'}
        vertical-align: middle;
        > input {
            width: 100%;
            background: none transparent;
            border: 0 none;
            box-shadow: none;
            cursor: default;
            display: inline-block;
            font-family: 'Open Sans', sans-serif;
            font-size: inherit;
            margin: 0;
            outline: none;
            line-height: 10px;
            /* For IE 8 compatibility */
            padding: 6px 0 10px;
            /* For IE 8 compatibility */
            -webkit-appearance: none;
        }
    }

    .Select-loading-zone {
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        padding-right: 7px;
        width: 16px;
    }

    .Select-loading {
        -webkit-animation: Select-animation-spin 400ms infinite linear;
        -o-animation: Select-animation-spin 400ms infinite linear;
        animation: Select-animation-spin 400ms infinite linear;
        width: 16px;
        padding-right: 7px;
        height: 16px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid #ccc;
        border-right-color: #333;
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }

    .Select-clear-zone {
        -webkit-animation: Select-animation-fadeIn 200ms;
        -o-animation: Select-animation-fadeIn 200ms;
        animation: Select-animation-fadeIn 200ms;
        color: #999;
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 17px;
        &:hover {
            color: #d0021b;
        }

        svg {
            vertical-align: middle;
        }
    }

    .Select-clear {
        display: inline-block;
        font-size: 18px;
        line-height: 1;
    }

    .Select--multi {
        .Select-clear-zone {
            width: 17px;
        }
        .Select-multi-value-wrapper {
            display: inline-block;
        }
        .Select-input {
            vertical-align: middle;
            margin-left: 10px;
            padding: 0;
        }
        &.has-value {
            .Select-input {
                margin-left: 5px;
            }
        }

        .Select-value {
            background-color: #ebf5ff;
            /* Fallback color for IE 8 */
            background-color: rgba(0, 126, 255, 0.08);
            border-radius: 0px;
            border: solid 1px #cbd3df;
            /* Fallback color for IE 8 */
            border: 1px solid rgba(0, 126, 255, 0.24);
            color: #007eff;
            display: inline-block;
            font-size: 0.9em;
            line-height: 1;
            margin-left: 5px;
            margin-top: 5px;
            vertical-align: top;
        }

        .Select-value-icon,
        .Select-value-label {
            display: inline-block;
            vertical-align: middle;
        }
        .Select-value-label {
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            cursor: default;
            padding: 2px 5px;
        }
        a {
            &.Select-value-label {
                color: #007eff;
                cursor: pointer;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .Select-value-icon {
            cursor: pointer;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
            border-right: 1px solid #c2e0ff;
            /* Fallback color for IE 8 */
            border-right: 1px solid rgba(0, 126, 255, 0.24);
            padding: 1px 7px 3px;
            &:hover,
            &:focus {
                background-color: #d8eafd;
                /* Fallback color for IE 8 */
                background-color: rgba(0, 113, 230, 0.08);
                color: #0071e6;
            }
            &:active {
                background-color: #c2e0ff;
                /* Fallback color for IE 8 */
                background-color: rgba(0, 126, 255, 0.24);
            }
        }

        &.is-disabled {
            .Select-value,
            .Select-value-label {
                background-color: #fcfcfc;
                border: 1px solid #e3e3e3;
                color: #7f8fa4;
                font-weight: 600;

                &:hover {
                    cursor: not-allowed;
                }
            }
            .Select-value-icon {
                cursor: not-allowed;
                border-right: 1px solid #e3e3e3;
                &:hover,
                &:focus,
                &:active {
                    background-color: #fcfcfc;
                }
            }
        }
    }

    .Select-arrow-zone {
        max-height: 46rem;
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: ${({ hideArrow }) => (hideArrow ? '12px' : '35px')};
        padding-right: 5px;
        padding-left: 10px;
        &:hover {
            > .Select-arrow {
                border-top-color: #043d5d;
            }
        }
    }

    .Select-arrow {
        border-color: #043d5d transparent transparent;
        border-style: solid;
        border-width: 5px 5px 2.5px;
        display: inline-block;
        height: 0;
        width: 0;
        position: relative;
    }

    @-webkit-keyframes Select-animation-fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes Select-animation-fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .Select-menu-outer {
        border: 1px solid #cbd3df;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        background-color: #fff;
        overflow: hidden;
        //box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: -1px;
        margin-bottom: 20px;
        max-height: 212px;
        position: absolute;
        top: 100%;
        width: 100%;
        max-width: 100%;
        z-index: 9999999;
        -webkit-overflow-scrolling: touch;
        border-color: #043d5d;
        border-top: 1px solid #eee;
    }

    .Select-menu {
        max-height: 200px;
        overflow-y: auto;
    }

    .show-disabled {
        .Select-option {
            display: block !important;
        }
    }

    .Select-option {
        box-sizing: border-box;
        background-color: #fff;
        color: #043d5d;
        cursor: pointer;
        display: block;
        padding: 8px 10px;
        border-bottom: 1px solid #eee;

        &:last-child {
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        &.is-disabled {
            display: none;
            color: #7f8fa4;
            cursor: not-allowed;
        }

        &.is-focused {
            background-color: #fafafa;
            /* Fallback color for IE 8 */
            color: #043d5d;
        }
    }

    .Select-noresults {
        box-sizing: border-box;
        color: #999999;
        cursor: default;
        display: block;
        padding: 6px 10px;
    }

    ${({ hasDanger }) =>
        hasDanger &&
        `
        .Select-control { 
            border-color: #f16464;
        }
    `}

    label {
        color: #728096;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.71;
        font-weight: 600;
    }

    .clear-item {
        &:hover {
            opacity: 0.7;
        }
    }
`

const ErrorTextBox = styled.div`
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding-top: 8px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fe6050;
`

const SelectArrow = styled.div`
    ${({ hideArrow }) => hideArrow && 'display: none;'}
    ${({ isOpen }) =>
        isOpen ? 'margin-top: 4px;' : 'margin-bottom: 4px;'}
    height: 8px;
    width: 8px;
    border: solid #353d51;
    border-width: 0px 1px 1px 0px;
    transform: ${({ isOpen }) =>
        isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'};
`

class SelectField extends Component {
    generateSelectInput = props => {
        const { isAsync, isCreatable, Icon, hideArrow, ...restProps } = props

        if (isAsync) {
            return (
                <Select.Async
                    {...restProps}
                    arrowRenderer={({ onMouseDown, isOpen }) => (
                        <SelectArrow hideArrow={hideArrow} isOpen={isOpen} />
                    )}
                    autosize={false}
                />
            )
        }

        if (isCreatable) {
            return (
                <Select.Creatable
                    {...restProps}
                    arrowRenderer={({ onMouseDown, isOpen }) => (
                        <SelectArrow hideArrow={hideArrow} isOpen={isOpen} />
                    )}
                    autosize={false}
                />
            )
        }

        return (
            <Select
                arrowRenderer={({ onMouseDown, isOpen }) => (
                    <SelectArrow hideArrow={hideArrow} isOpen={isOpen} />
                )}
                autosize={false}
                {...restProps}
            />
        )
    }

    render() {
        const {
            label,
            errorText,
            className,
            Icon,
            selectProps,
            hasIcon,
            hideArrow,
            required,
            disabled,
            ...props
        } = this.props

        return (
            <React.Fragment>
                <Container
                    hasDanger={!!errorText || !!selectProps.errorText}
                    className={`form-group ${className} ${
                        disabled ? 'disabled' : ''
                    }`}
                    hasIcon={hasIcon}
                    hideArrow={hideArrow}
                    {...props}
                >
                    <span className="select-icon">
                        {Icon ? <Icon className="select-icon" /> : null}
                    </span>
                    {this.generateSelectInput({ ...selectProps, hideArrow })}
                </Container>
                {errorText && <ErrorTextBox>{errorText}</ErrorTextBox>}
            </React.Fragment>
        )
    }
}

SelectField.propTypes = {
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    label: PropTypes.string,
    selectProps: PropTypes.object.isRequired,
    className: PropTypes.string,
}

SelectField.defaultProps = {
    errorText: '',
    label: '',
    className: '',
}

export default SelectField
