import Translation from "./translation";
import { BASE_FEE_TRANS } from "../constants";

const BREAKPOINT = 978;

export default function t(strings, ...args) {
    const [DESKTOP, MOBILE] = ['_desktop', '_mobile'];
    let keyParts = strings;

    if (keyParts[1] === true) {
        if (window && window.innerWidth < BREAKPOINT) {
            keyParts[1] = MOBILE;
        } else {
            keyParts[1] = DESKTOP;
        }
    }

    if (args.length > 0) {
        keyParts = [];
        strings.forEach((string, index) => {
            keyParts.push(string);
            keyParts.push(args[index]);
        });
    }

    const key = keyParts.join('');
    let translation = new Translation(key);

    return translation.translate().replace("25", BASE_FEE_TRANS).replace("BASE_FEE_TRANS", BASE_FEE_TRANS);
}
