import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModal } from "../../../shared/actions/v2_actions";
import Modal from "../../../shared/components/V2/Modal";
import FlightDistanceBox from "../../../shared/components/V2/FlightDistanceBox";
import { CloseButton } from "../../../shared/components/V2/V2Buttons";
import { modalTypes } from "../../../shared/constants";

class InfoMobileModal extends React.Component {
    render() {
        const { hideModal } = this.props;

        return (
            <Modal modalType={modalTypes.infoMobileModal}>
                <CloseButton onClick={hideModal} width="34" height="34" />
                <FlightDistanceBox />
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal,
        },
        dispatch
    );
}

export default connect(
    null,
    mapDispatchToProps
)(InfoMobileModal);
