import React from 'react';
import { Link } from 'react-router';
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledButton = styled.button`
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 1.6rem 2.3rem;
    border: 0;
    border-radius: .2rem;
    box-shadow: 0.2rem 0.2rem 0 0 rgba(0,0,0,.04);
    ${({ theme }) => theme.colors.actionButton ?
    `background-color: ${theme.colors.actionButton};`
    :
    `background-image: linear-gradient(to bottom, ${theme.colors.tertiaryColor}, ${theme.colors.primaryColor});`
    }
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2rem;
    vertical-align: middle;
    cursor: pointer;
    outline: 0;
    transition: all .2s ease;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: #fff;
        text-decoration: none;
        opacity: .85;
    }
    &:focus {
        color: #fff;
        text-decoration: none;
        outline: none;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.disabled {
        cursor: not-allowed; opacity: .50;
    }
`;

const StyledLink = StyledButton.withComponent(Link).extend`
    &:focus {
        text-decoration: none;
        outline: none;
    }
`;

const Button = ({ children, isHidden, ...props }) => {

    if (!isHidden) {
        return (props.href || props.to) ? (
            <StyledLink
                {...props}>
                {children}
            </StyledLink>
        ) : (
            <StyledButton
                {...props}>
                {children}
            </StyledButton>
            );
    } else {
        return null;
    }
}

Button.propTypes = {
    isHidden: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Button.defaultProps = {
    isHidden: false,
    href: "",
    onClick: null,
    to: null
};

const SquareButton = styled.button`
    padding: 10px 15px;
    border-radius: 3px;
    background: ${({background}) => background };;
    border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    outline: 0 none;
    color: ${({ color }) => color };
    user-select: none;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 10px;

    &:hover {
        cursor: pointer;
        filter: brightness(105%);
    }

    &:focus { 
        outline:0;
    }
`;

export const BorderButton = (props) => (
    <SquareButton {...props}>
        {props.children}
    </SquareButton>
);

export default Button;