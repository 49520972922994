import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { tablet, mobile } from '../../helpers/styled_queries'

export const H1 = ({ children }) => <StyledH1>{children}</StyledH1>

export const FlexSpan = styled.span`
    display: flex;
    padding: 0px 12px 16px;
    position: relative;
    ${({ bottomPadding }) =>
        bottomPadding && `padding-bottom: ${bottomPadding}`};

    ${({ disableTopBorder }) => (disableTopBorder ? '' : 'padding-top: 20px;')}

    animation: appear-step 0.3s linear;
    @keyframes appear-step {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    ${mobile`
        padding: 24px 16px 12px;
        justify-content: center;
        ${({ mobileBottomPadding }) =>
            mobileBottomPadding && `padding-bottom: ${mobileBottomPadding}`};
        ${({ hasHeaderInfo }) => hasHeaderInfo && `flex-direction: column;`};
    `}

    ${tablet`
        padding: 24px 16px 12px;
        justify-content: center;
        ${({ mobileBottomPadding }) =>
            mobileBottomPadding && `padding-bottom: ${mobileBottomPadding}`};
        ${({ hasHeaderInfo }) => hasHeaderInfo && `flex-direction: column;`};
    `}

    ${({ hasHeaderInfo }) =>
        hasHeaderInfo &&
        `
        ${H1} {
            display: inline-flex;
        }
    `}
`

const StyledH1 = styled.span`
    font-size: 46px;
    font-family: 'Open Sans', sans-serif;
    color: #043d5d;
    display: block;

    ${mobile`
        text-align: center;
        font-size: 30px;
    `}

    ${tablet`
        text-align: center;
        font-size: 48px;
    `}
`

export const PageTitle = ({
    title,
    metaDesc,
    bottomPadding,
    disableTopBorder,
    titleText,
    hasHeaderInfo,
}) => {
    return (
        <FlexSpan
            hasHeaderInfo={hasHeaderInfo}
            disableTopBorder={disableTopBorder}
            bottomPadding={bottomPadding}
            id="page-view-title"
        >
            {title && (
                <H1>
                    <Helmet>
                        <title>{`Skycop - ${titleText || title || ''}`}</title>
                        {metaDesc && (
                            <meta name="description" content={metaDesc} />
                        )}
                    </Helmet>
                    {typeof title === 'function' ? title() : title}
                </H1>
            )}
        </FlexSpan>
    )
}
