import { SET_LOADING_TEXT } from '../actions/actionTypes';

const INITIAL_STATE = "0";

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_LOADING_TEXT:
            return payload;
        default:
    }
    return state;
};