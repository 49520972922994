import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import CookieSettings from './CookieSettings'
import t from '../../shared/translations'
import { SettingsIcon } from '../../shared/components/V2/V2Icons'
import { mobile } from '../../shared/components/helpers/styled_queries'

const Text = styled.div`
    a {
        text-decoration: underline;
        margin-left: 5px;

        &:hover {
            text-decoration: none;
        }
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 1.5rem;
        text-align: center;
    }

    ${mobile`
        display: none;
    `}
`

const MobileText = styled.div`
    display: none;

    ${mobile`
        display: block;
        margin-bottom: 8px;
    `}
`

const Actions = styled.div`
    text-align: right;
    padding-left: 15px;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
        text-align: center;
    }

    ${mobile`
        display: none;
    `}
`

const MobileActions = styled.div`
    display: none;

    ${mobile`
        flex-direction: column;
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const MobileRoundedButton = styled.button`
    border: none;
    padding: 8px 32px;
    border-radius: 16px;
    background-color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #36bc6e;
    width: 85%;
    align-self: flex-start;
`

const TopBar = styled.div`
    background: ${({ theme }) => theme.colors.primaryColor};
    width: 100%;
    position: fixed;
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    color: #ffffff;
    align-items: center;
    padding: 10px 20px;
    left: 0px;
    top: 0px;
    min-height: 40px;
    z-index: 9999999;
    clear: both;
    margin: 0;

    ${mobile`
        position: fixed;
        bottom: 0;
        top: unset;
        padding: 16px;
    `}
`

const RoundedButton = styled.button`
    padding: 5px 10px;
    border-radius: 5px;
    background: white;
    outline: 0 none;
    border: 0 none;
    color: #000000;
    user-select: none;
    margin-right: 15px;

    &:hover {
        cursor: pointer;
        filter: brightness(105%);
    }

    &:focus {
        outline: 0;
    }
`

const StyledLinkButton = styled.button`
    border: 0 none;
    outline: 0 none;
    color: #ffffff;
    text-decoration: underline;
    vertical-align: middle;
    background: none;
    padding: 0px 10px;
    user-select: none;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: 0;
    }
`

class Cookies extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showSettings: false,
        }
    }

    _toggleSettings = e => {
        e.preventDefault()
        const { showSettings } = this.state
        this.setState({ showSettings: !showSettings })
    }

    _handleSubmit = (levels, settings, 
        // isAllCookies
        ) => {
        const { handleSubmit, toggleCookiesBox } = this.props

        // if (isAllCookies) {
        //     if (window && window.gtag) {
        //         window.gtag('consent', 'update', {
        //             ad_storage: 'granted',
        //             ad_user_data: 'granted',
        //             ad_personalization: 'granted',
        //             analytics_storage: 'granted',
        //             security_storage: 'granted',
        //         })
        //     }
        // } else {
        //     if (window && window.gtag) {
        //         window.gtag('consent', 'update', {
        //             ad_storage: 'granted',
        //             ad_user_data: 'granted',
        //             ad_personalization: 'denied',
        //             analytics_storage: 'granted',
        //             security_storage: 'granted',
        //         })
        //     }
        // }

        handleSubmit(levels, settings || 'none').then(needsRefresh => {
            this.setState({ showSettings: false })
            toggleCookiesBox()

            if (needsRefresh) {
                // eslint-disable-next-line no-restricted-globals
                location.reload()
            }
        })
    }

    _handleModalClose = () => {
        this.setState({ showSettings: false })
    }

    openHelpChat = () => {
        const $zopim = window.$zopim

        $zopim.livechat.window.show()
    }

    render() {
        const { showSettings } = this.state
        const { showCookies, trans, hideOnMobileFocus } = this.props

        if (_get(this, 'props.hideElements.hideCookieBar', false)) {
            return null
        }

        if (trans) {
            return (
                <TopBar
                    id="cookies-bar"
                    className="row"
                    visible={showCookies && !hideOnMobileFocus}
                >
                    <Text className="col-md-18">
                        {t`cookies.bar.description`}
                        <a
                            href={t`cookies.shared.privacy_policy.url`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{t`cookies.bar.label.review_policy`}</a>
                        <a
                            href={t`terms.url`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            {t`terms.link_text`}
                        </a>
                    </Text>
                    <MobileText>
                        {t`v2_cookies.bar.description.mobile`}
                    </MobileText>
                    <Actions className="col-md-6">
                        <RoundedButton
                            onClick={() =>
                                this._handleSubmit([0], 'none', true)
                            }
                        >{t`cookies.shared.label.accept_cookies`}</RoundedButton>
                        <RoundedButton
                            onClick={() => this._handleSubmit([1], 'none')}
                        >{t`cookies.shared.label.accept_required_cookies`}</RoundedButton>
                        <StyledLinkButton
                            onClick={this._toggleSettings}
                        >{t`cookies.shared.label.cookie_settings`}</StyledLinkButton>
                    </Actions>
                    <MobileActions>
                        <MobileRoundedButton
                            onClick={() =>
                                this._handleSubmit([1], 'none', true)
                            }
                        >
                            {t`cookies.shared.label.accept_cookies`}
                        </MobileRoundedButton>
                        <div
                            style={{
                                marginTop: '12px',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <MobileRoundedButton
                                onClick={() => this._handleSubmit([1], 'none')}
                            >
                                {t`cookies.shared.label.accept_required_cookies`}
                            </MobileRoundedButton>
                            <div
                                onClick={this._toggleSettings}
                                style={{ marginLeft: '12px' }}
                            >
                                <SettingsIcon />
                            </div>
                        </div>
                    </MobileActions>
                    {showSettings && (
                        <CookieSettings
                            handleSubmit={(levels, settings) =>
                                this._handleSubmit(levels, settings)
                            }
                            closeModal={this._handleModalClose}
                        />
                    )}
                </TopBar>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        hideOnMobileFocus: state.hideCookies,
        hideElements: state.hideElements,
    }
}

export default connect(mapStateToProps)(Cookies)
