import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import Helmet from "react-helmet";
import styled from "styled-components";
import { Button } from '../../shared/components/Buttons';
import { loadScreenOff, mapClaimPostData } from '../../main';
import { saveUserClaim, postUserClaim, getPayoutPlans, setClaimableAmount } from '../../shared/actions';
import { BoxContainer } from '../../shared/components/styledComponents';
import t from '../../shared/translations';
import { paths } from '../../shared/constants';

const SaveUserClaimButton = styled(Button)`
    width: auto;
    float: left;
    margin-top: 2.5rem;
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        float: none;
    }
`;

const ResendClaimButton = SaveUserClaimButton.extend`
    margin-right: 2.5rem;
`;

class ErrorPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDescription: true
        }

        this.resendClaim = this.resendClaim.bind(this);
    }

    componentDidMount() {
        setTimeout(function () { loadScreenOff(); }, 1);
    }

    UNSAFE_componentWillMount() {
        if (this.props.location.query) {
            var reqObj = this.props.location.query;
            if (reqObj["q"]) {
                if (reqObj["q"] === "claim") {
                    browserHistory.push(paths.ERROR);
                    this.setState({
                        errorTitle: "error_page.title",
                        btnText: "error_page.button.please_try_again",
                        goTo: "/",
                        claimError: true,
                        showDescription: true
                    })
                } else if (reqObj["q"] === "other") {
                    var gotoUrl = "/";
                    var errorTitle = "error_page.title.other";
                    var showDescription = true;
                    if (reqObj["ref"])
                        gotoUrl = `/${reqObj["ref"]}`;
                    if (reqObj["type"]) {
                        errorTitle = reqObj["type"];
                        showDescription = false;
                    }
                    browserHistory.push(paths.ERROR);
                    this.setState({
                        errorTitle,
                        btnText: "error_page.button.please_try_again.other",
                        goTo: gotoUrl,
                        showDescription,
                        claimError: false
                    })
                }
            } else {
                this.props.saveUserClaim(undefined);
                browserHistory.push('/');
            }
        }
    }

    resendClaim() {
        const { claim, saveUserClaim } = this.props;
        const { goTo } = this.state;

        if (claim && claim.data) {
            const { data } = claim;
            mapClaimPostData(data, data.clmAmount, this.props);
        } else {
            saveUserClaim(undefined);
            browserHistory.push(goTo);
        }
    }

    showErrorDescription = () => {
        if (this.state.showDescription) {
            return (
                <div className="col-md-16 h-c0 h-fs14 sorry-holder h-mb25">
                    <b>{t`error_page.description.error_has_occurred`} </b>
                    {t`error_page.description.please_try_again`}
                    <br />
                </div>
            )
        }
    }

    render() {
        if (this.props.trans && this.state) {
            return (
                <div className="container">
                    <Helmet>
                        <title>{`Skycop - ${t`${this.state.errorTitle}`}`}</title>
                    </Helmet>
                    <div className="row">
                        <BoxContainer secondary className="h-ml20 claim-panel col-xs-24 col-lg-16 h-pb60">
                            <h1 className="h-pt10 h-mb10 h-mb40-md">{t`${this.state.errorTitle}`}</h1>
                            {this.showErrorDescription()}
                            {this.state && this.state.claimError ?
                                <ResendClaimButton onClick={this.resendClaim}>
                                    {t`error_page.description.please_try_again.repeat_claim`}
                                </ResendClaimButton>
                                : null}
                            <SaveUserClaimButton
                                onClick={() => {
                                        this.props.saveUserClaim(undefined);
                                        browserHistory.push(this.state.goTo);
                                    }}>
                                {t`${this.state.btnText}`}
                            </SaveUserClaimButton>
                        </BoxContainer>
                    </div>
                </div>
            )
        } else return null
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        claim: state.claim,
        countries: state.countries,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
        clmAmt: state.claimable_amt
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ saveUserClaim, postUserClaim, setClaimableAmount, getPayoutPlans }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
