import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router, browserHistory } from 'react-router'
import { Provider } from 'react-redux'
import routes, { secureRoutes, skycopPayRoutes } from './routes'
import store from './shared/services/store'
import ApiClientProxy from './shared/services/api/proxy'
import {
    setLanguage,
    getLanguages,
    getConfig,
    getTranslations,
    setLandingParameters,
} from './shared/actions/index'
import { CLAIM_FORM_VERSIONS, V2_prePath } from './shared/constants'
import {
    AUTH_USER,
    UNAUTH_USER,
    API_CLIENT,
    DEFAULT_TRANSLATIONS,
    DEFAULT_WEB_LANGUAGE,
    V2_ACTIONS,
} from './shared/actions/actionTypes'
import Cookies from 'js-cookie'
import {
    getStorage,
    isProduction,
    disableExtensions,
    handleBrandedRedirect,
    getAllUrlParams,
    getCookie,
    setCookie,
} from './main.js'
import WebFont from 'webfontloader'
import 'core-js/fn/array/from'
import 'core-js/fn/array/find-index'
import 'core-js/fn/object/get-own-property-descriptor'
import 'core-js/fn/is-iterable'
import 'core-js/fn/get-iterator'
import 'core-js/fn/get-iterator-method'
import 'core-js/fn/string/includes'
import 'core-js/fn/symbol/unscopables'
import 'core-js/fn/promise'
import 'es5-shim'
import 'es6-shim'
require('dotenv').config()

if (window && !window.jQuery) {
    window.jQuery = $
}

WebFont.load({
    google: {
        families: ['Open Sans:300,400,600,700,800&subset=cyrillic,greek'],
    },
})

const apiClientProxy = new ApiClientProxy()
store.dispatch({
    type: API_CLIENT,
    payload: apiClientProxy,
})

store.dispatch(
    getTranslations('web', DEFAULT_WEB_LANGUAGE, DEFAULT_TRANSLATIONS)
)

if (isProduction()) {
    disableExtensions()
}

function logPageView() {
    // eslint-disable-next-line
    dataLayer.push({
        event: 'virtual_pageview',
        virtual_path: window.location.pathname,
    })
}

const entryParams = window.location.search.substring(1) || ''
const isScPurchasePage =
    !!window.location.origin.includes('secure') ||
    !!window.location.origin.includes('care') ||
    !!window.location.pathname.includes('skycop-care')
const isSkycopPayPage =
    !!window.location.origin.includes('skycop-pay') ||
    !!window.location.pathname.includes('skycop-pay')
const isLeadPage =
    !!window.location.pathname.includes('/lead/list') ||
    !!window.location.pathname.includes('/lead/agreement') ||
    !!window.location.pathname.includes('/lead/documents')

store.dispatch({
    type: V2_ACTIONS.SET_SKYCOPCARE_PAGE,
    payload: isScPurchasePage,
})

const loadingHandler = lang => {
    store
        .dispatch(getLanguages())
        .then(() => store.dispatch(setLanguage(lang, isScPurchasePage)))
        .catch(() => store.dispatch(setLanguage(lang, isScPurchasePage)))
}

const initialParams = getAllUrlParams(window.location.href) || {}
let prefillParams = {}
if (initialParams.prefill) {
    try {
        const prefill = window.atob(initialParams.prefill)
        const prefillObj = JSON.parse(
            '{"' +
                decodeURI(prefill)
                    .replace(/"/g, '\\"')
                    .replace(/&/g, '","')
                    .replace(/=/g, '":"') +
                '"}'
        )
        if (prefillObj) {
            prefillParams = prefillObj
        }
    } catch (err) {
        if (!isProduction()) {
            console.log('Prefill parse error', err)
        }
    }
}

if (initialParams.lang || prefillParams.lang) {
    const language = initialParams.lang || prefillParams.lang
    loadingHandler(language)
} else {
    const prevLanguage = getStorage('lang') || 'en'
    loadingHandler(prevLanguage)
}

if (
    (prefillParams.iata_from || prefillParams.iata_to) &&
    (!initialParams.iata_from || !initialParams.iata_to)
) {
    const { iata_from, iata_to } = prefillParams
    // eslint-disable-next-line no-restricted-globals
    const currentSearch = location.search
    const adjustedSearch = `${currentSearch || '?'}${
        iata_from ? `&iata_from=${iata_from}` : ''
    }${iata_to ? `&iata_to=${iata_to}` : ''}`
    browserHistory.replace({ pathname: V2_prePath, search: adjustedSearch })
}

if (
    initialParams.h_log ||
    initialParams.h_bar ||
    initialParams.h_chat ||
    isSkycopPayPage ||
    isLeadPage
) {
    store.dispatch({
        type: V2_ACTIONS.HIDE_ELEMENTS,
        payload: {
            hideLogin: !!initialParams.h_log || isLeadPage || false,
            hideCookieBar: !!initialParams.h_bar || false,
            hideChat: !!initialParams.h_chat || isSkycopPayPage || false,
        },
    })
}

if (initialParams.idev_id) {
    store.dispatch(setLandingParameters(initialParams))

    if (!getCookie('idev')) {
        setCookie('idev', `${initialParams.idev_id}---`, 45, '.skycop.com')
    }
}

if (initialParams.idev_p && !getCookie('idev_p')) {
    setCookie('idev_p', initialParams.idev_p, 45, '.skycop.com')
}

let entryParamsObj
try {
    entryParamsObj = JSON.parse(
        '{"' +
            decodeURI(entryParams)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
    )
} catch (e) {}

var isLoggedIn = getStorage('login')
if (isLoggedIn) {
    store.dispatch({ type: AUTH_USER })
} else {
    store.dispatch({ type: UNAUTH_USER })
}

function handlePostConfigGet(cfg) {
    if (cfg) {
        if (
            entryParamsObj &&
            entryParamsObj.idev_id &&
            entryParamsObj.idev_tid2
        ) {
            handleBrandedRedirect({ ...entryParamsObj })
        }
    }
}

function renderRoutes() {
    const params = new URLSearchParams(window.location.search)
    const claimFormVersion = params.get('cf')
    if (claimFormVersion === CLAIM_FORM_VERSIONS.V1) {
        setCookie('cf', claimFormVersion)
    }
    if (claimFormVersion === CLAIM_FORM_VERSIONS.V0) {
        Cookies.remove('cf')
    }

    if (isSkycopPayPage) {
        return (
            <Router
                history={browserHistory}
                routes={skycopPayRoutes}
                onUpdate={logPageView}
            />
        )
    }

    if (isScPurchasePage) {
        return (
            <Router
                history={browserHistory}
                routes={secureRoutes}
                onUpdate={logPageView}
            />
        )
    }

    return (
        <Router
            history={browserHistory}
            routes={routes}
            onUpdate={logPageView}
        />
    )
}

store
    .dispatch(getConfig())
    .then(cfg => handlePostConfigGet(cfg))
    .catch(() => handlePostConfigGet())

ReactDOM.render(
    <Provider store={store}>{renderRoutes()}</Provider>,
    document.getElementById('root')
)
