import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = {
    documents: [],
    uploading: false,
};

export default function(state = INITIAL_STATE, action) {
    const { documents } = state;
    const { type, file, id, progress } = action;

    switch (type) {
        case V2_ACTIONS.ADD_USER_DOCUMENTS:
            return {
                ...state,
                documents: [...documents, { file }],
            };
        case V2_ACTIONS.UPDATE_USER_DOCUMENTS:
            let newDocuments = [...documents];
            
            newDocuments = Array.prototype.map.call(state.documents, (item) => {
                if (item.file.name === file.name) {
                    if (id && !item.id) {
                        item.id = id;
                    }

                    if (progress) {
                        item.progress = progress;
                    }
                }

                return item;
            });

            return {
                ...state,
                documents: [...newDocuments],
            };
        case V2_ACTIONS.ADD_USER_DRAFT_DOCUMENTS:
            return {
                ...state,
                documents: [...documents, { file, id, progress: 100 }],
            };
        case V2_ACTIONS.REMOVE_USER_DOCUMENT:
            return {
                ...state,
                documents: [...documents].filter(
                    (document) => document.id !== id
                ),
            };
        case V2_ACTIONS.REMOVE_USER_BY_NAME:
            return {
                ...state,
                documents: [...documents].filter(
                    // eslint-disable-next-line no-restricted-globals
                    (document) => document.name !== name
                ),
            };
        case V2_ACTIONS.DOCUMENTS_UPLOADING:
            return {
                ...state,
                uploading: action.payload,
            };
        case V2_ACTIONS.DOCUMENTS_CLEAR:
            return INITIAL_STATE;
        default:
    }
    return state;
}
