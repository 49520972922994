import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { hideModal } from '../../../shared/actions/v2_actions'
import { CloseButton } from '../../../shared/components/V2/V2Buttons'
import Modal from '../../../shared/components/V2/Modal'
import t from '../../../shared/translations'
import { modalTypes } from '../../../shared/constants'
import { Row, Col } from '../../../shared/components/V2/Layout'
import { Title } from '../../V2/steps/common'
import { Button } from '../../../shared/components/V2/SkycopPay/Button'

const Container = styled.div`
    padding: 32px 60px;

    ${({ noBackground }) =>
        noBackground &&
        `
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    `}
`
const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
`

const StyledTitle = styled(Title)`
    padding: 20px;
    padding-bottom: 12px;
    margin-bottom: 0;
    font-size: 33px;
    font-weight: 400;
`

class SkycopPayModal extends React.Component {
    render() {
        const { hideModal, email } = this.props

        return (
            <Modal
                modalType={modalTypes.skycopPayModal}
                height="fit-content"
                maxWidth="600px"
                maxHeight="fit-content"
                style={{ borderRadius: 0 }}
            >
                <CloseButton onClick={hideModal} width="34" height="34" />
                <Container>
                    <Row
                        style={{
                            justifyContent: 'center',
                            padding: '12px 0px',
                        }}
                    >
                        <ImageContainer>
                            <img
                                width="100%"
                                src="../images/skycop_pay_plane.png"
                                alt="plane"
                            />
                        </ImageContainer>
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                        <StyledTitle>{t`skycop_pay.modal_title`}</StyledTitle>
                    </Row>
                    <Row>
                        <Col
                            style={{
                                textAlign: 'center',
                                fontSize: '15px',
                                fontWeight: 400,
                            }}
                        >{`${t`skycop_pay.modal_description`} ${email} `}</Col>
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                        <Col
                            sm={12}
                            md={12}
                            lg={12}
                            style={{ textAlign: 'center' }}
                        >
                            <Button
                                onClick={() =>
                                    window.open(
                                        'https://www.skycop.com',
                                        '_self'
                                    )
                                }
                                style={{
                                    width: 'fit-content',
                                }}
                                text={t`skycop_pay.modal_button`}
                            ></Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal,
        },
        dispatch
    )
}

export default connect(
    state => ({
        modal: state.toggle_modal,
    }),
    mapDispatchToProps
)(SkycopPayModal)
