import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import Helmet from "react-helmet";
import styled from "styled-components";
import { loadScreenOff } from '../../main';
import { saveUserClaim, clearErrors } from '../../shared/actions';
import { Button } from '../../shared/components/Buttons';
import t from '../../shared/translations';
import { BoxContainer } from '../../shared/components/styledComponents';
import { paths } from '../../shared/constants';

const ClaimButton = styled(Button)`
    width: auto;
    margin-top: 2.5rem;
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
    }
`;

class DuplicatePage extends Component {

    componentDidMount() {
        const { duplicate } = this.props;
        setTimeout(function () {
            loadScreenOff();
        }, 100);

        if (!duplicate || !duplicate.data) {
            browserHistory.push('/');
        }
    }

    componentWillUnmount() {
        this.props.saveUserClaim(undefined);
        this.props.clearErrors();
    }

    _viewClaimButton = () => {
        const { isAuthenticated, duplicate } = this.props;
        return (
            isAuthenticated &&
            <ClaimButton
                onClick={() => {
                    browserHistory.push(`${paths.CLAIM}?id=${duplicate.data}`);
                }}>
                {t`duplicate.authorized.button.view_claim`}
            </ClaimButton>
        )
    }

    render() {
        const { trans, isAuthenticated } = this.props;
        if (trans) {
            return (
                <div className="container">
                    <Helmet>
                        <title>{`Skycop - ${isAuthenticated ?
                            t`duplicate.authorized.title`
                            :
                            t`duplicate.unauthorized.title`}`}</title>
                    </Helmet>
                    <div className="row">
                        <BoxContainer secondary className="h-ml20 claim-panel col-xs-24 col-lg-16 h-pb60">
                            <h1 className="h-pt10 h-mb10 h-mb40-md">
                                {
                                    isAuthenticated ?
                                        t`duplicate.authorized.title`
                                        :
                                        t`duplicate.unauthorized.title`
                                }
                            </h1>
                            <div className="col-md-16 h-c0 h-fs14 sorry-holder h-mb25">
                                <b>
                                    {
                                        isAuthenticated ?
                                            t`duplicate.authorized.description`
                                            :
                                            t`duplicate.unauthorized.description`
                                    }
                                </b>
                                <br/>
                            </div>
                            {this._viewClaimButton()}
                        </BoxContainer>
                    </div>
                </div>
            );
        } else return null;
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        claim: state.claim,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
        duplicate: state.duplicate,
        lang: state.language,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ saveUserClaim, clearErrors }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicatePage);