import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { setLanguage } from '../../actions'
import { V2ArrowDown, GlobeIcon } from './V2Icons'
import { getLanguagesArray } from '../../../main'
import { desktop } from '../helpers/styled_queries'

const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    ${desktop`
        display: none;
    `}

    ${({ fullWidth }) => (fullWidth ? 'margin: 16px 32px;' : '')}
`

const StyledSelect = styled.select`
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: transparent;
    z-index: 10;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${({ signatureConfigs }) =>
        (signatureConfigs && `${signatureConfigs.menuItemColor} !important;`) ||
        '#353d51 !important;'}
    border-radius: 24px;
    padding: 12px 42px 12px 42px;
    border: solid 1px #98a3bf;
    outline: none;
    text-align: center;
    text-align-last: center;
    ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}

    option {
        color: #353d51;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: #f2f5f9;
        border: none;
    }

    option {
        &:disabled {
            display: none;
        }

        &[disabled='disabled'] {
            display: none;
        }

        &.hidden {
            display: none;
            visibility: collapse;
            -webkit-appearance: none;
        }
    }
`

const ArrowContainer = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    z-index: 10;
`

const IconContainer = styled.div`
    position: absolute;
    left: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
    z-index: 10;
`

const V2MobileLangSelect = ({
    webLanguages,
    lang,
    setLanguage,
    callback = () => null,
    fullWidth,
    signatureConfigs,
    ...props
}) => {
    const _handleLanguageChange = e => {
        const { value } = e.target
        setLanguage(value || 'en')
        callback()
    }

    const renderLangOptions = () => {
        return (getLanguagesArray(webLanguages) || []).map(lang => {
            return (
                <option key={lang.key} value={lang.key}>
                    {lang.title}
                </option>
            )
        })
    }

    if (webLanguages) {
        return (
            <Container fullWidth={fullWidth}>
                <StyledSelect
                    signatureConfigs={signatureConfigs}
                    value={lang}
                    onChange={_handleLanguageChange}
                    id="mob-lang"
                    fullWidth={!!fullWidth}
                    {...props}
                >
                    {renderLangOptions()}
                </StyledSelect>
                <IconContainer>
                    <GlobeIcon />
                </IconContainer>
                <ArrowContainer>
                    <V2ArrowDown
                        width={10}
                        height={17}
                        strokeWidth="2"
                        stroke={
                            signatureConfigs && signatureConfigs.menuItemColor
                        }
                    />
                </ArrowContainer>
            </Container>
        )
    } else {
        return null
    }
}

function mapStateToProps(state) {
    return {
        lang: state.language,
        trans: state.translations,
        webLanguages: state.web_languages,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLanguage }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(V2MobileLangSelect)
