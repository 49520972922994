import { V2_ACTIONS } from '../../actions/actionTypes';

export default function (state = null, action) {
    switch (action.type) {
        case V2_ACTIONS.TOP_BAR:
            return {
                ...state,
                ...action.payload,
            }
        default:
    }
    return state;
}