import React from "react";
import styled from "styled-components";
import t from "../../../shared/translations";
import { V2ManIcon } from "../../../shared/components/V2/V2Icons";
import { desktop } from "../../../shared/components/helpers/styled_queries";
import { adultAge } from "../../../shared/constants";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    ${desktop`
        padding-top: 20px;
    `}
`;

const Box = styled.div`
    width: 100%;
    border-radius: 5px;
    background-color: ${({ bgColor }) => bgColor};
    display: flex;
    align-items: center;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: ${({ bgColor }) => bgColor};
        transform: rotate(45deg);
        top: -8px;
        left: 22px;
    }

    ${desktop`
        &:before {
            top: calc(50% - 8px);
            left: -8px;
        }
    `}
`;

const IconContainer = styled.div`
    padding: 20px 27px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 8px;

    p {
        margin: 0;
        font-family: "Noto Sans";
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-bottom: 4px;

        span {
            font-weight: 600;
        }

        &.dark {
            color: #495363;
        }
    }
`;

const AgeBox = ({ age, bgColor = "#f4f6f8" }) => {
    const [adult, child, infant] = ["adult", "child", "infant"];
    let personAge = "";

    if (age >= adultAge) {
        personAge = adult;
    } else if (age > 2) {
        personAge = child;
    } else {
        personAge = infant;
    }

    return (
        <Container>
            <Box bgColor={bgColor}>
                <IconContainer>
                    <V2ManIcon />
                </IconContainer>
                <TextContainer>
                    <p>{t`v2_claim.passengers_years.text`}</p>
                    <p className="dark">
                        <span>{t`v2.common.${personAge}`}</span> { age && age > 0 && `(${age} ${t`v2_claim.passengers.years_old`})`}
                    </p>
                </TextContainer>
            </Box>
        </Container>
    );
};

export default AgeBox;
