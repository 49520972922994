import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import { Row, Col } from '../../../shared/components/V2/Layout'
import InputField from '../../../shared/components/V2/InputField'
import t from '../../../shared/translations'
import { SPACE_KEY } from '../../../shared/components/V2/Modal'
import ToolTip from '../../../shared/components/V2/V2Tooltip'

import { QuestionMarkIcon2 } from '../../../shared/components/V2/SkycopPay/icons'
import { TooltipInner } from './SkycopCareV2'
import { CircleClose } from '../../../shared/components/V2/V2Icons'
import {
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'

const MobileCol = styled(Col)`
    padding: 0;
`

const TitleRow = styled(Row)`
    flex-direction: row;
`

const NumberText = styled.h4`
    color: #000000;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    margin: 16px 0px;
`

const DeleteCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`
const CloseBox = styled.div`
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`

const InfoBox = styled.div`
    position: relative;
    display: flex;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
`

const StyledFieldsRow = styled(Row)`
    margin-bottom: 16px;

    ${tablet`
        margin-bottom: 0px;
        `}

    ${mobile`
        margin-bottom: 0px;
        `}
`

const emailTooltip = () => (
    <InfoBox data-tip data-for={`tooltip-family-members_email`}>
        <QuestionMarkIcon2 />
        <ToolTip
            clickable={true}
            dataTip="disabled"
            color="light"
            effect="solid"
            id={`tooltip-family-members_email`}
            delayHide={100}
            className="customHover"
            questionId="user-email"
            force="right"
        >
            <TooltipInner
                dangerouslySetInnerHTML={{
                    __html: `${t`sc.family_member.email_description`}`,
                }}
            />
        </ToolTip>
    </InfoBox>
)

export const FamilyMembers = ({
    user,
    index,
    sc_errors,
    handleChange,
    handleRemove,
}) => {
    return (
        <Row>
            <MobileCol xs={12} sm={12} md={12} lg={12}>
                <TitleRow>
                    <Col xs={6}>
                        <NumberText>
                            {t`sc.title.family_member_no`}
                            {index}
                        </NumberText>
                    </Col>
                    <DeleteCol xs={6}>
                        <CloseBox onClick={handleRemove}>
                            <CircleClose stroke="#717171" width={16} />
                        </CloseBox>
                    </DeleteCol>
                </TitleRow>
                <StyledFieldsRow>
                    <InputField
                        name="name"
                        id="user-name"
                        label={t`v2_common.label.name`}
                        required
                        placeholder={t`sc.family_member.placeholder.name`}
                        value={user.name}
                        onChange={handleChange}
                        errorText={t`${_get(sc_errors, 'errors.name', '')}`}
                        autoComplete="name-first"
                    />
                    <InputField
                        required
                        label={t`v2_common.label.surname`}
                        name="surname"
                        id="user-surname"
                        placeholder={t`sc.family_member.placeholder.surname`}
                        value={user.surname}
                        onChange={handleChange}
                        errorText={t`${_get(sc_errors, 'errors.surname', '')}`}
                        autoComplete="name-last"
                    />
                </StyledFieldsRow>
                <StyledFieldsRow>
                    <InputField
                        customTooltip={emailTooltip()}
                        label={t`v2_common.label.email`}
                        name="email"
                        type="email"
                        id="user-email"
                        placeholder={t`sc.family_member.placeholder.email`}
                        value={user.email}
                        onChange={handleChange}
                        errorText={t`${_get(sc_errors, 'errors.email', '')}`}
                        autoComplete="email"
                        onKeyDown={e => {
                            if (e.keyCode === SPACE_KEY) {
                                e.preventDefault()
                                return false
                            }
                        }}
                        hasDanger={
                            !!_get(
                                this,
                                'state.componentState.repeatEmailError',
                                ''
                            )
                        }
                    />
                    {/* <Col xs={12} md={12} lg={6}>
                        <DescriptionText>
                            {t`sc.family_member.email_description`}
                        </DescriptionText>
                    </Col> */}
                </StyledFieldsRow>
            </MobileCol>
        </Row>
    )
}
