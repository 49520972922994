import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import t from "../../../shared/translations";
import { SorryIcon } from "../../../shared/components/V2/V2Icons";
import { V2Button } from "../../../shared/components/V2/V2Buttons";
import { Text, Title, PageWrapper, ButtonContainer } from "./common";

class DraftExpiredPage extends Component {
    componentDidMount() {
        this.props.setParentState({
            nextDisabled: false,
            noBack: true,
            hideButton: true,
        });
    }

    render() {
        return (
            <PageWrapper>
                <SorryIcon />
                <Fragment>
                    <Title>{t`v2_claim.draft.link_expired`}</Title>
                    <Text>{t`v2_claim.draft.link_expired_description`}</Text>
                    <ButtonContainer>
                        <V2Button onClick={() => {
                            browserHistory.push("/");
                        }}
                        >{t`menu.create_new_claim`}</V2Button>
                    </ButtonContainer>
                </Fragment>
            </PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DraftExpiredPage);
