import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import t from '../../../shared/translations';
import NewSelectField from '../../../shared/components/V2/NewSelectField';
import { ClearIcon } from '../../../shared/components/svgIcons';
import { getLanguagesArray } from "../../../main";

const LanguageInput = ({
    autoload = true,
    className = '',
    ignoreAccents = false,
    clearable = false,
    clear = () => null,
    cache = false,
    callback = () => null,
    isAsync = false,
    hasIcon = false,
    required = false,
    name,
    value,
    defaultValue,
    onChange,
    onInputChange,
    disabled,
    errorText,
    webLanguages,
    autoComplete = "locale",
}) => (
        <NewSelectField
            label={t`v2_common.label.language`}
            errorText={errorText}
            hasIcon={hasIcon}
            required={required}
            disabled={disabled}
            data-hj-whitelist=""
            selectProps={{
                "data-hj-whitelist": "",
                inputProps: {
                    "data-hj-whitelist": "",
                },
                disabled,
                name,
                value,
                defaultValue,
                autoload,
                className,
                ignoreAccents,
                clearable,
                cache,
                options: (getLanguagesArray(webLanguages) || []).map((lang) => ({
                    value: lang.key,
                    label: lang.title,
                    name: name || "userLanguage"
                })),
                isAsync: !!isAsync,
                onBlurResetsInput: false,
                loadingPlaceholder: t`v2_common.no_results`,
                searchPromptText: t`v2_common.no_results`,
                placeholder: t`v2_common.placeholder.language`,
                onFocus: (e) => e.preventDefault(),
                onBlur: (e) => e.preventDefault(),
                onClick: (e) => e.preventDefault(),
                onChange: (e) => {
                    onChange(e, callback);
                    const elem = _get(document, "activeElement", null);

                    if (elem && typeof elem.blur === 'function') {
                        elem.blur();
                    }
                },
                clearRenderer: () => {
                    if (!value) {
                        return null
                    }
                    return (
                        <span className="clear-item" onClick={() => clear()} onTouchEnd={() => clear()} >
                            <ClearIcon />
                        </span>
                    )
                },
                onInputChange,
                autoComplete,
            }}
        />
    );

function mapStateToProps(state) {
    return {
        webLanguages: state.web_languages,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageInput);