import { 
    CLAIM_ERRORS,
    CLAIM_ERRORS_CLEAR,
    CLAIM_ERRORS_CLEAR_FLIGHTS,
    CLAIM_ERRORS_CLEAR_USER,
} from '../actions/actionTypes';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CLAIM_ERRORS:
            return action.payload;
        case CLAIM_ERRORS_CLEAR_FLIGHTS:
            const { flights, ...withoutFlights } = state;
            return withoutFlights;
        case CLAIM_ERRORS_CLEAR_USER:
            const { user, ...withoutUser } = state;
            return withoutUser;
        case CLAIM_ERRORS_CLEAR:
            return INITIAL_STATE;
        default:
    }
    return state;
};