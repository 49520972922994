import React from 'react'

export const PlaneIcon = () => (
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.5" clipPath="url(#clip0_99_4365)">
            <path
                d="M16.2201 8.2832C16.2201 7.99492 16.076 7.74266 15.7877 7.52645C15.4994 7.31023 15.2111 7.20212 14.9228 7.20212H10.5985L6.49039 0.499419H4.76066L6.92283 7.20212H2.38228L1.3012 5.47239H0.00390625L0.868771 8.2832L0.00390625 11.3102H1.3012L2.38228 9.5805H6.92283L4.76066 16.2832H6.49039L10.5985 9.5805H14.9228C15.2111 9.5805 15.4994 9.47239 15.7877 9.25618C16.076 9.03996 16.2201 8.71564 16.2201 8.2832Z"
                fill="#7F8FA4"
            />
        </g>
        <defs>
            <clipPath id="clip0_99_4365">
                <rect
                    width="16.44"
                    height="16"
                    fill="white"
                    transform="matrix(1 0 0 -1 0 16.5)"
                />
            </clipPath>
        </defs>
    </svg>
)
