import React, { Component } from 'react'
import styled from 'styled-components'
import { tablet, mobile } from '../../shared/components/helpers/styled_queries'
import { PageContent } from '../../shared/components/V2/Layout'
import { connect } from 'react-redux'
import t from '../../shared/translations'
import { V2Button } from '../../shared/components/V2/V2Buttons'
import { paths } from '../../shared/constants'
import { browserHistory } from 'react-router'

const MainContainer = styled.div`
    flex-grow: 1;
    max-width: 992px;
    margin: 40px auto 80px;

    .h-mb-8 {
        margin-bottom: 8px;
    }

    .h-mt-4 {
        margin-top: 4px;
    }

    h3 {
        font-family: 'Open Sans', sans-serif;
    }

    ${tablet`
        margin-bottom: 100px;
    `}

    ${mobile`
        margin-bottom: 100px;
    `}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 80px 16px 80px;

    ${mobile`
    padding: 16px 40px 16px 40px;
`};
`

const Title = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 28px;
    color: #353d51;
    margin-bottom: 80px;
`

const Description = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    color: #353d51;
    text-align: center;
`

const StyledV2Button = styled(V2Button)`
    max-width: 246px;
    margin-top: 80px;

    ${mobile`
max-width: unset;
`};
`

class ThankYou extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { trans } = this.props

        if (!trans) return null

        return (
            <MainContainer>
                <PageContent>
                    <ContentWrapper>
                        <Title>{t`v2_claim.missing_details.thank_you.title`}</Title>
                        <Description>
                            {t`v2_claim.missing_details.thank_you.description`}
                        </Description>
                        <br />
                        <br />
                        <Description>
                            {t`v2_claim.missing_details.thank_you.description2`}
                        </Description>
                        <StyledV2Button
                            onClick={() => {
                                browserHistory.push(paths.CLAIMS)
                            }}
                        >
                            {t`signature_form.button.close`}
                        </StyledV2Button>
                    </ContentWrapper>
                </PageContent>
            </MainContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(ThankYou)
