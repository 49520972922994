import React, { Component } from 'react';
import styled from "styled-components";
import { StyledInput } from '../styledComponents';
var debounceChange = require('throttle-debounce/debounce');

const Container = styled.div`
    .form-control {
        color: ${({theme}) => theme.colors.secondaryColor};
        
        &:focus {
            border-color: ${({theme}) => theme.colors.secondaryColor};
        }
    }
`; 

const CompactLabel = styled.div`
    color: ${({ hasDanger }) => ( hasDanger ? "#d9534f" : "#959595" )};
    font-weight: normal;
    display: block;
    font-size: 13px;
    line-height: 13px;
    margin: 2px 0px;
`;

const FlightNumberInput = styled(StyledInput)`
        &.form-control {
            color: ${({ theme }) => theme.colors.secondaryColor};
        }
`;

var classes;
class FlightNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            flightNumberLetters: "",
            flightNumberDigits: ""
        };

        if (this.props.extraClasses !== "" && this.props.extraClasses) {
            classes = `col-md-5 ${this.props.extraClasses}`;
        }
        else {
            classes = "col-md-5";
        }

        this.references = {}
        this.handleLocalChange = debounceChange(500, this.handleLocalChange.bind(this));
    }

    onlyNumbers(e) {
        const re = /^[0-9]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }

    onlyAlphabets(e) {
        const re = /[a-zA-Z0-9-]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }

    handleLocalChange(e) {
        this.props.handleChangeEvent(e);
    }

    _handlePaste = (e) => {
        const pastedValue = e.clipboardData.getData('Text');
        const digitRegex = /^\d+$/;

        if (!digitRegex.test(pastedValue)) {
            e.preventDefault();
        }
        return false;
    }

    render() {
        const { selectErrors, LettersName } = this.props;
        return (
            <Container className={`${classes} detail-input`}>
                <div className="form-group h-mb0">
                    {!this.props.compactLabel ?
                        <label className="h-c1">{this.props.Text}</label>
                        :
                        <CompactLabel>{this.props.Text}</CompactLabel>
                    }
                    <div className={`input-group data-hj-whitelist ${selectErrors[LettersName] ? 'has-danger' : ''}`}>
                        <div className="input-group-addon no-danger data-hj-whitelist">
                            <FlightNumberInput
                                data-hj-whitelist
                                innerRef={ref => this.references["letters"] = ref}
                                style={{ textTransform: 'uppercase', backgroundColor: "#f6f6f6" }}
                                type="text"
                                maxLength="2"
                                placeholder="VA"
                                disabled
                                className="form-control js-condition-stop js-flightLetters js-checkable data-hj-whitelist"
                                name={LettersName}
                                onChange={this.props.handleChangeEvent}
                                value={this.props.prefillLetters}
                                autoComplete="off" />
                        </div>
                        <StyledInput type="tel"
                            data-hj-whitelist
                            innerRef={ref => this.references["digits"] = ref}
                            autoComplete="off"
                            placeholder="1234"
                            onKeyPress={(e) => (this.onlyNumbers(e))}
                            className="form-control js-flightDigits js-checkable data-hj-whitelist"
                            maxLength="4"
                            name={this.props.DigitsName}
                            onChange={(e) => {e.persist(); this.handleLocalChange(e)}}
                            onBlur={this.props.handleChangeEvent}
                            onPaste={this._handlePaste}
                            onDrop={(e) => e.preventDefault()}
                            defaultValue={this.props.prefillDigits} />
                    </div>
                    {!this.props.compactLabel ?
                        <div className="form-control-feedback has-danger">
                            {selectErrors[LettersName] ? selectErrors[LettersName] : undefined}
                        </div>
                        :
                        <CompactLabel hasDanger>
                            {this.props.selectErrors[LettersName] || null}
                        </CompactLabel>
                    }
                </div>
            </Container>
        );
    }
}

export default FlightNumber;