import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = {
    componentState: {},
};

export default function(state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case V2_ACTIONS.SET_CLAIM_DRAFT_PROPS:
            const newState = { ...state, ...payload };
            delete newState.validations;
            delete newState.errors;
            delete newState.error;

            newState.componentState = {
                ...state.componentState,
                ...payload.componentState,
            };
            return newState;
        case V2_ACTIONS.RESET_CLAIM_DRAFT_PROPS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
