import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import ErrorText from "./ErrorText";
import Select from 'react-select'
import { ElementTitle } from './Layout'
import { tablet, mobile, desktop } from '../helpers/styled_queries'

const OpenIcon = () => (
    <svg
        width="12"
        height="6"
        viewBox="0 0 12 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.657253 0L6 4.78574L11.3427 0L12 0.919893L6.32863 6H5.67137L0 0.919893L0.657253 0Z"
            fill="#333333"
        />
    </svg>
)

const borderWidth = '1px'

const Container = styled.div`
    position: relative;
    font-family: 'Noto Sans';

    .select-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        top: calc(50% + 0px);
        left: 12px;
        transform: translateY(calc(-50% + 20px));
        z-index: 1;
        opacity: 0.7;
    }

    .Select {
        position: relative;
        box-sizing: border-box;
        line-height: 2.2rem;
        font-size: 16px;
        // font-size: 1.4rem;
        font-family: 'Noto Sans';
        height: ${({ isCountrySelect }) =>
            isCountrySelect ? '56px;' : '54px;'}

        div,
        input,
        span {
            box-sizing: border-box;
            line-height:  ${({ isCountrySelect }) =>
                isCountrySelect ? '54.89px;' : '56px;'}
            font-size: 16px;
            // font-size: 1.4rem;
        }

        &.is-disabled {
            &:hover {
                box-shadow: none !important;
                cursor: not-allowed;
            }

            > .Select-control {
                background: #f2f5f9;
                border: 1px solid #f2f5f9;
                color: #7f8fa4;
                font-weight: 400;

                &:hover {
                    box-shadow: none;
                    cursor: not-allowed;
                }
            }

            .Select-value-label {
                color: #7f8fa4 !important;
                font-weight: 400 !important;

                &:hover {
                    box-shadow: none !important;
                    cursor: not-allowed;
                }
            }

            .Select-arrow-zone {
                cursor: default;
                pointer-events: none;
                opacity: 0.35;
            }
        }

        .Select-aria-only {
            display: inline-block;
            height: 1px;
            width: 1px;
            margin: -1px;
            clip: rect(0, 0, 0, 0);
            overflow: hidden;
            float: left;
        }
    }

    .Select-control {
        background-color: #fafafa;
        border-radius: ${({ borderRadius }) => borderRadius || '16px'};
        border: ${borderWidth} solid #717171;
        //box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
        color: #333;
        cursor: default;
        display: table;
        border-spacing: 0;
        border-collapse: separate;
        height: 54px;
        // font-size: 1.4rem;
        font-size: 16px;
        outline: none;
        font-weight: 400;
        overflow: hidden;
        position: relative;
        width: 100%;

        &:hover,
        &:active,
        &:focus {
            border: ${borderWidth} solid
                ${({ theme }) => theme.colors.primaryColor};
            box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
        }

        &:disabled,
        &.disabled {
            background: #f2f5f9;
            border: ${borderWidth} solid #7f8fa4;
            color: #7f8fa4;
            font-weight: 400;
        }

        &.warn {
            border: ${borderWidth} solid #ff713b;
        }

        &.danger {
            border: ${borderWidth} solid #FE6050;
        }

        .Select-input {
            &:focus {
                outline: none;
            }
        }

        &:not(.is-searchable) {
            > .Select-input {
                outline: none;
                border-color: ${({ theme }) => theme.colors.primaryColor};
                //box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
            }
        }
    }

    .is-searchable {
        &.is-open {
            > .Select-control {
                cursor: text;
                border-color: ${({ theme }) => theme.colors.primaryColor};
                //box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
            }
        }

        &.is-focused {
            &:not(.is-open) {
                > .Select-control {
                    border-color: ${({ theme }) => theme.colors.primaryColor};
                    //box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
                    cursor: text;
                }
            }
        }
    }

    .is-open {
        > .Select-control {
            background-color: #fafafa;
            border-color: ${({ theme }) => theme.colors.primaryColor};
            //box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);

            .Select-arrow {
                top: -2px;
                border-color: transparent transparent
                    ${({ theme }) => theme.colors.primaryColor};
                border-width: 0 5px 5px;
            }
        }

        .Select-arrow {
            &:hover {
                > .Select-arrow {
                    border-top-color: ${({ theme }) =>
                        theme.colors.primaryColor};
                }
            }
        }
    }

    .is-focused {
        &:not(.is-open) {
            > .Select-control {
                border-color: ${({ theme }) => theme.colors.primaryColor};
                //box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
            }
        }

        .Select-input {
            > input {
                cursor: text;
                font-weight: 400;
            }
        }

        body .select-icon {
            opacity: 1;
        }
    }

    .Select-placeholder {
        max-height: 54px;
        bottom: 0;
        color: #717171;
        left: 0;
        font-size: 16px;
        padding: 0px 17px;
        ${({ hasIcon }) =>
            hasIcon ? 'padding-left: 47px;' : 'padding-left: 16px;'}
        line-height: 32px;
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
    }

    .Select--single {
        > .Select-control {
            .Select-value {
                bottom: 0;
                color: #aaa;
                left: 0;
                padding: 1.2rem 1.6rem;
                padding-right: 40px;
                ${({ hasIcon }) =>
                    hasIcon ? 'padding-left: 47px;' : 'padding-left: 16px;'}
                line-height: 32px;
                vertical-align: middle;
                position: absolute;
                right: 0;
                top: 0;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 400;
            }
        }
    }

    .has-value {
        &.Select--single {
            > .Select-control {
                .Select-value {
                    .Select-value-label {
                        color: #333333;
                        font-weight: 400;
                        line-height: 2.2rem;

                        ${mobile`
                            font-size: 16px;
                        `}

                        ${tablet`
                            font-size: 16px;
                        `}
                    }
                    a {
                        &.Select-value-label {
                            cursor: pointer;
                            text-decoration: none;
                            &:hover,
                            &:focus {
                                color: #333333;
                                outline: none;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        &.is-pseudo-focused {
            &.Select--single {
                > .Select-control {
                    .Select-value {
                        .Select-value-label {
                            font-weight: 400;
                            color: #333333;
                            &:hover {
                            }
                        }
                    }

                    a {
                        &.Select-value-label {
                            cursor: pointer;
                            text-decoration: none;
                            &:hover,
                            &:focus {
                                color: #333333;
                                outline: none;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .Select-input {
                opacity: 0;
            }
        }
    }

    .Select-input {
        max-height: 54px;
        line-height: 10px;
        ${({ hasIcon }) =>
            hasIcon ? 'padding-left: 47px;' : 'padding-left: 16px;'}
        vertical-align: middle;
        > input {
            width: 100%;
            background: none transparent;
            border: 0 none;
            box-shadow: none;
            cursor: default;
            display: inline-block;
            font-family: 'Noto Sans';
            font-size: inherit;
            margin: 0;
            outline: none;
            line-height: 10px;
            /* For IE 8 compatibility */
            padding: 6px 0 10px;
            /* For IE 8 compatibility */
            -webkit-appearance: none;
        }
    }

    .Select-loading-zone {
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        padding-right: 7px;
        width: 16px;
        line-height: 54px !important;
    }

    .Select-loading {
        -webkit-animation: Select-animation-spin 400ms infinite linear;
        -o-animation: Select-animation-spin 400ms infinite linear;
        animation: Select-animation-spin 400ms infinite linear;
        width: 16px;
        padding-right: 7px;
        height: 16px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid #ccc;
        border-right-color: #333;
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }

    .Select-clear-zone {
        -webkit-animation: Select-animation-fadeIn 200ms;
        -o-animation: Select-animation-fadeIn 200ms;
        animation: Select-animation-fadeIn 200ms;
        color: #333333;
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 17px;
        &:hover {
            color: #333333;
        }

        svg {
            vertical-align: middle;
        }
    }

    .Select-clear {
        display: inline-block;
        font-size: 18px;
        line-height: 1;
    }

    .Select--multi {
        .Select-clear-zone {
            width: 17px;
        }
        .Select-multi-value-wrapper {
            display: inline-block;
        }
        .Select-input {
            vertical-align: middle;
            margin-left: 10px;
            padding: 0;
        }
        &.has-value {
            .Select-input {
                margin-left: 5px;
            }
        }

        .Select-value {
            background-color: #ebf5ff;
            /* Fallback color for IE 8 */
            background-color: rgba(0, 126, 255, 0.08);
            border-radius: 8px;
            border: solid 1px #cbd3df;
            /* Fallback color for IE 8 */
            border: 1px solid rgba(0, 126, 255, 0.24);
            color: #007eff;
            display: inline-block;
            font-size: 0.9em;
            line-height: 1;
            margin-left: 5px;
            margin-top: 5px;
            vertical-align: top;
        }

        .Select-value-icon,
        .Select-value-label {
            display: inline-block;
            vertical-align: middle;
        }
        .Select-value-label {
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
            cursor: default;
            padding: 2px 5px;
        }
        a {
            &.Select-value-label {
                color: #007eff;
                cursor: pointer;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .Select-value-icon {
            cursor: pointer;
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
            border-right: 1px solid #c2e0ff;
            /* Fallback color for IE 8 */
            border-right: 1px solid rgba(0, 126, 255, 0.24);
            padding: 1px 7px 3px;
            &:hover,
            &:focus {
                background-color: #d8eafd;
                /* Fallback color for IE 8 */
                background-color: rgba(0, 113, 230, 0.08);
                color: #0071e6;
            }
            &:active {
                background-color: #c2e0ff;
                /* Fallback color for IE 8 */
                background-color: rgba(0, 126, 255, 0.24);
            }
        }

        &.is-disabled {
            .Select-value,
            .Select-value-label {
                background-color: #fcfcfc;
                border: 1px solid #e3e3e3;
                color: #7f8fa4;
                font-weight: 400;

                &:hover {
                    cursor: not-allowed;
                }
            }
            .Select-value-icon {
                cursor: not-allowed;
                border-right: 1px solid #e3e3e3;
                &:hover,
                &:focus,
                &:active {
                    background-color: #fcfcfc;
                }
            }
        }
    }

    .Select-arrow-zone {
        max-height: 46rem;
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: ${({ hideArrow }) => (hideArrow ? '12px' : '35px')};
        padding-right: 5px;
        padding-left: 10px;
        &:hover {
            > .Select-arrow {
                border-top-color: ${({ theme }) => theme.colors.Color};
            }
        }
    }

    .Select-arrow {
        border-color: ${({ theme }) => theme.colors.primaryColor} transparent
            transparent;
        border-style: solid;
        border-width: 5px 5px 2.5px;
        display: inline-block;
        height: 0;
        width: 0;
        position: relative;
    }

    @-webkit-keyframes Select-animation-fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes Select-animation-fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .Select-menu-outer {
        border: 1px solid #cbd3df;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: #fff;
        overflow: hidden;
        //box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: -1px;
        margin-bottom: 20px;
        max-height: 212px;
        position: absolute;
        top: 100%;
        z-index: 9999999;
        -webkit-overflow-scrolling: touch;
        border-color: ${({ theme }) => theme.colors.primaryColor};
        border-top: 1px solid #eee;
        scrollbar-color: #149954 #FAFAFA;
        --scrollbar-height: 0px;

        width: 100%;
        max-width: 100%;
        padding-right: 6px;
        ${desktop`
            width: 115%;
            max-width: none;
        `}
    }

    .Select-menu {
        max-height: 200px;
        overflow-y: auto;
    }

    .Select-menu-outer {
        padding: ${({ isFocusHighligh }) =>
            !isFocusHighligh && '0px 0px 0px 0px'};
        margin-top: ${({ stickyOptionsList, stickyOptionsListTopPadding }) =>
            stickyOptionsList
                ? stickyOptionsListTopPadding
                    ? `${stickyOptionsListTopPadding};`
                    : '0px;'
                : '10px;'}
        
        border-radius: 16px;
        border: 1px solid #333333;
        padding-right: 6px;
        
        .Select-noresults {
        height: ${({ stickyOptionsList }) =>
            stickyOptionsList ? '42px;' : '32px;'}
        line-height: 30px;
        padding-bottom: ${({ isFocusHighligh }) => !isFocusHighligh && '28px;'};
        }       
    }

    .show-disabled {
        .Select-option {
            display: block !important;
        }
    }

    .Select-option {
        box-sizing: border-box;
        background-color: #fff;
        color: #666666;
        cursor: pointer;
        display: block;
        padding: 0px 12px 0px 8px;
        margin: ${({ isFocusHighligh }) => isFocusHighligh && '0px 12px;'};

        // border-bottom: 1px solid #eee;

        // &:first-child {
        //     padding: 12px 12px 0px 8px
        //     // border-bottom-left-radius: 8px;
        // }

        &:first-child {
        padding: ${({ isFocusHighligh }) =>
            isFocusHighligh ? '0px 12px 0px 8px;' : '0px 12px 0px 8px;'}; 
        margin: ${({ isFocusHighligh }) => isFocusHighligh && '0px 12px;'};
        // margin-top: ${({ isFocusHighligh }) => isFocusHighligh && '50px;'};
        }

        &:last-child {
        padding: ${({ isFocusHighligh }) =>
            isFocusHighligh ? '0px 12px 0px 8px;' : '0px 12px 0px 8px;'}; 
             margin: ${({ isFocusHighligh }) => isFocusHighligh && '0px 12px;'};

        }

        &.is-disabled {
            display: none;
            color: #7f8fa4;
            cursor: not-allowed;
        }

        &.is-focused {
        ${({ isFocusHighligh }) =>
            isFocusHighligh &&
            `
        color: #149954;
        background-color: #ECF7F1;
        border-radius: 16px;
        `}
        }
    }

    .Select-noresults {
        box-sizing: border-box;
        color: #999999;
        cursor: default;
        display: block;
        margin: 0px 12px 12px 16px;
        padding-top: 14px;
    }

    ${({ hasDanger }) =>
        hasDanger &&
        `
        .Select-control { 
            border-color: #f16464;
        }
    `}

    label {
        color: #728096;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.71;
        font-weight: 400;
    }
    
`

const ErrorTextBox = styled.div`
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    margin: 0;
    padding-top: 16px;
    font-style: normal;
    font-stretch: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #fe6050;
`

const OpenIconWrapper = styled.div`
    margin-right: 12px;
${({ hideArrow }) => hideArrow && 'display: none;'}
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};

    svg {
      transform: ${({ isOpen }) =>
          isOpen ? 'translateY(-5px);' : 'translateY(-1px);'};
    }
    `

class NewSelectField extends Component {
    state = {
        counter: 1,
    }
    somehowIncrementCounter = () =>
        this.setState({ counter: this.state.counter + 1 })

    generateSelectInput = props => {
        const { counter } = this.state
        const { isAsync, isCreatable, Icon, hideArrow, ...restProps } = props

        if (isAsync) {
            return (
                <Select.Async
                    {...restProps}
                    arrowRenderer={({ onMouseDown, isOpen }) => (
                        <OpenIconWrapper hideArrow={hideArrow} isOpen={isOpen}>
                            <OpenIcon />
                        </OpenIconWrapper>
                    )}
                    autosize={false}
                    cacheOptions={counter}
                />
            )
        }

        if (isCreatable) {
            return (
                <Select.Creatable
                    {...restProps}
                    arrowRenderer={({ onMouseDown, isOpen }) => (
                        <OpenIconWrapper hideArrow={hideArrow} isOpen={isOpen}>
                            <OpenIcon />
                        </OpenIconWrapper>
                    )}
                    autosize={false}
                />
            )
        }

        return (
            <Select
                arrowRenderer={({ onMouseDown, isOpen }) => (
                    <OpenIconWrapper hideArrow={hideArrow} isOpen={isOpen}>
                        <OpenIcon />
                    </OpenIconWrapper>
                )}
                autosize={false}
                {...restProps}
            />
        )
    }

    render() {
        const {
            label,
            errorText,
            className,
            Icon,
            selectProps,
            hasIcon,
            hideArrow,
            required,
            disabled,
            isCountrySelect,
            ...props
        } = this.props
        return (
            <React.Fragment>
                <Container
                    isCountrySelect={isCountrySelect}
                    hasDanger={!!errorText || !!selectProps.errorText}
                    className={`form-group ${className} ${
                        disabled ? 'disabled' : ''
                    }`}
                    hasIcon={hasIcon}
                    hideArrow={hideArrow}
                    {...props}
                >
                    {label && (
                        <ElementTitle>
                            {label}
                            {required && <span> *</span>}
                        </ElementTitle>
                    )}
                    <span className="select-icon">
                        {Icon ? <Icon className="select-icon" /> : null}
                    </span>
                    {this.generateSelectInput({ ...selectProps, hideArrow })}
                </Container>
                {errorText && <ErrorTextBox>{errorText}</ErrorTextBox>}
            </React.Fragment>
        )
    }
}

NewSelectField.propTypes = {
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    label: PropTypes.string,
    selectProps: PropTypes.object.isRequired,
    className: PropTypes.string,
}

NewSelectField.defaultProps = {
    errorText: '',
    label: '',
    className: '',
}

export default NewSelectField
