import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/min/locales'
import {
    getStorage,
    handleMobileInputBlur,
    handleMobileInputFocus,
    applyTranslationElement,
    mapCalendarLocale,
} from '../../../../main'
import ToolTip from '../V2Tooltip'
import { QuestionMarkTooltip } from '../V2Icons'
import t from '../../../translations'
import store from '../../../services/store'
import { logKibana } from '../../../actions'

const ToolTipContainer = styled.div`
    span {
        display: flex !important;
        align-items: center;
        margin-left: 8px;
        color: #ffffff !important;
    }
`

const CompactLabel = styled.label`
    color: ${({ hasDanger, labelColor }) =>
        hasDanger ? '#d9534f' : labelColor};
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    vertical-align: middle;
    display: block;
    font-size: 13px;
    margin-bottom: 0.2rem;
`

const ErrorTextBox = styled.div`
    margin: 0;
    padding-top: 8px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fe6050;
`

const Container = styled.div`
    input {
        &::placeholder {
            color: #043d5d !important;
        }
    }

    .form-control {
        height: 56px;
        color: #043d5d;
        font-family: 'Open Sans', sans-serif;

        padding-left: 50px;
        border: ${({ hasDanger }) =>
            hasDanger ? 'solid 1px #f16464' : 'solid 1px #cbd3df'};
        box-shadow: none;
        background: url('/images/skycopPayCalendarIcon.svg') left 18px center
            no-repeat;
        background-color: #fff;
        font-weight: 400;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus,
        &:active,
        &:hover {
            border: 1px solid #043d5d;
            box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
        }

        &::placeholder {
            color: #aaa;
        }

        &:disabled,
        &.disabled {
            background: #f2f5f9;
            color: #7f8fa4;
            border: 1px solid #f2f5f9;
            font-weight: 400;
            -webkit-text-fill-color: #7f8fa4;

            &:hover {
                box-shadow: none;
                cursor: not-allowed;
            }
        }
    }

    .rdtOpen .rdtPicker {
        margin-top: 0px;
        right: 0;
        border: 1px solid #ece9e9 !important;
        font-family: 'Open Sans', sans-serif;
    }

    .rdtOpen input {
        border: 1px solid #043d5d !important;
        font-family: 'Open Sans', sans-serif;
    }

    .rdtPicker {
        min-width: 250px;
        width: 100%;

        font-family: 'Open Sans', sans-serif;

        thead {
            tr {
                height: 48px;

                th {
                    &.rdtPrev,
                    &.rdtNext {
                        padding-top: 8px;
                        font-size: 25px;
                        font-weight: 200;
                    }
                }

                &:first-child {
                    th {
                        &:hover {
                            background: #fff;
                        }
                    }
                }

                &:last-child {
                }
            }
        }
        td {
            &.rdtDay {
                font-weight: 400;
            }

            .rdtActive {
                background-color: #043d5d;
                color: #fff !important;
                &:hover {
                    background-color: ${({ theme }) =>
                        theme.colors.secondaryColor};
                    color: #fff;
                }
            }
            &.rdtNew,
            &.rdtOld {
                color: #70849c;
            }
            span {
                &.rdtDisabled {
                    background: 0 0;
                    color: #70849c;
                    &:hover {
                        background: 0 0;
                        color: #70849c;
                    }
                }
            }
            &.rdtDisabled {
                background: 0 0;
                color: #70849c;
                &:hover {
                    background: 0 0;
                    color: #70849c;
                }
            }
        }
        th {
            .rdtDisabled {
                background: 0 0;
                color: #70849c;
                &:hover {
                    background: 0 0;
                    color: #70849c;
                }
            }
        }
    }

    .rdtCounter {
        .rdtBtn {
            &:hover {
                background: ${({ theme }) => theme.colors.lightHoverColor};
            }
        }
    }
    td {
        &.rdtMonth,
        &.rdtYear,
        &.rdtDay {
            &.rdtToday {
                color: #043d5d;

                &:before {
                    content: none;
                }
            }
        }

        &.rdtDay {
            &:hover {
                background: #f4f6f8;
            }
        }

        &.rdtMonth,
        &.rdtYear {
            &:hover {
                background: #f4f6f8;
            }
        }
    }

    .inner-form-control-datepicker {
        color: #333;
        background-color: transparent;
    }
`
const BubbleContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const Bubble = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width || '32px'};
    height: ${({ height }) => height || '32px'};
    border-radius: 100%;

    &.rdtDay {
        &.rdtOld,
        &.rdtNew {
            color: #7f8fa4;
            font-weight: 600;
        }
    }

    &.rdtDisabled {
        color: #c3cad3 !important;
        font-weight: normal;
        cursor: not-allowed;
    }

    &.rdtDay.rdtToday:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #043d5d;
        bottom: 0px;
        left: calc(50% - 2px);
    }

    &:hover {
        background-color: #f2f5f9;
    }
`

const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    color: #7f8fa4;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 400;
    line-height: 1.71;

    span {
        display: inline-block;
        color: #fe6050;
    }
`

const SuggestDate = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #7f8fa4;
    margin-top: 4px;

    .s-date {
        color: #4e569b;
        letter-spacing: 0.4px;
    }

    .s-link {
        text-decoration: underline;
        color: #32b86a;
        cursor: pointer;

        &:hover {
            opacity: 0.95;
        }
    }
`

class Datepicker extends Component {
    state = {
        isFocus: false,
        date: '',
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick, false)
        document.addEventListener('touchend', this.handleOutsideClick, false)
        this.handleArrowAppend(this.props.id)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false)
        document.removeEventListener('touchend', this.handleOutsideClick, false)
    }

    componentDidUpdate(prevProps, prevState) {
        this.handleCalendarBtns(this.props.id)
        this.handleArrowAppend(this.props.id)
    }

    handleOutsideClick = e => {
        const { isOpen } = this.state
        const { target } = e
        const targetClassList = target.classList

        if (!this.dateTimeWrapperRef) {
            return false
        }
        if (target && target.type === 'radio') {
            return false
        }

        if (
            isOpen &&
            !this.dateTimeWrapperRef.contains(target) &&
            !targetClassList.contains('rdtSwitch') &&
            !targetClassList.contains('rdtYear') &&
            !targetClassList.contains('rdtMonth') &&
            !targetClassList.contains('rdtDay')
        ) {
            this.setState({ isOpen: false })
        }
    }

    toggleDatePicker = e => {
        const picker = this.dateTimeRef

        if (picker.state.open) {
            picker.closeCalendar()
            handleMobileInputBlur()
        } else {
            picker.openCalendar()
            handleMobileInputFocus(e.target, true, 400, 0.1)
        }
    }

    onChange = (date, cb) => {
        const { name } = this.props
        date.locale('en')
        this.setState(moment(date), () => {
            this.dateTimeRef.closeCalendar()
        })

        if (cb) {
            cb({ name, value: moment(date).format('YYYY-MM-DD') })
        }
    }

    handleCalendarBtns = id => {
        const backBtns = document.querySelectorAll(`.${id} .rdtPicker .rdtPrev`)
        const nextBtns = document.querySelectorAll(`.${id} .rdtPicker .rdtNext`)
        const th = document.querySelectorAll(`.${id} .rdtPicker .rdtSwitch`)

        const items = [...th].map((item, i) => {
            return {
                th: item,
                backBtn: backBtns[i],
                nextBtn: nextBtns[i],
            }
        })
        items.forEach(item => this.addCalendarEvents(item))
    }

    addCalendarEvents = ({ th, backBtn, nextBtn }) => {
        const { startDate, endDate } = this.props
        const splittedByDash = th.innerHTML.split('-')
        const isSplittedByDash = splittedByDash.length === 2
        const isSplitBySpace = th.innerHTML.split(' ').length === 2
        const spaceSplitYears = parseInt(
            th.innerHTML.substr(th.innerHTML.length - 4),
            10
        )
        const nowYears = startDate || moment().year()
        const minYears = nowYears - endDate

        if (isSplitBySpace) {
            // Checking months tab view
            this.handleButtonDisable(spaceSplitYears < minYears, backBtn)
            this.handleButtonDisable(spaceSplitYears > nowYears, nextBtn)
        } else if (isSplittedByDash) {
            // Cheking period of a year view
            this.handleButtonDisable(
                parseInt(splittedByDash[0], 10) < minYears,
                backBtn
            )
            this.handleButtonDisable(
                parseInt(splittedByDash[1], 10) >= nowYears,
                nextBtn
            )
        } else {
            // Checking year tab view
            this.handleButtonDisable(
                parseInt(th.innerHTML, 10) < minYears,
                backBtn
            )
            this.handleButtonDisable(
                parseInt(th.innerHTML, 10) > nowYears,
                nextBtn
            )
        }
    }

    handleButtonDisable = (shouldDisable, button) => {
        if (shouldDisable) {
            button.addEventListener('click', this.disableBtn)
            button.addEventListener('touchend', this.disableBtn)
        } else {
            button.removeEventListener('click', this.disableBtn)
            button.removeEventListener('touchend', this.disableBtn)
        }
    }

    disableBtn = e => {
        e.preventDefault()
        e.stopPropagation()
    }

    renderLabel() {
        const {
            compactLabel,
            label,
            tooltip,
            labelColor,
            required,
        } = this.props

        if (!compactLabel) {
            return (
                <StyledLabel style={{ color: labelColor }} className="h-c1">
                    {label} {required ? <span>&nbsp;*</span> : ''}
                    {tooltip && (
                        <ToolTipContainer>
                            <span
                                data-tip={tooltip.dataTip}
                                data-for={tooltip.dataFor}
                                id={tooltip.questionId || 'question-mark'}
                            >
                                <QuestionMarkTooltip />
                            </span>
                            <ToolTip {...tooltip}>
                                <span>
                                    {tooltip.children ? (
                                        <tooltip.children />
                                    ) : (
                                        tooltip.text
                                    )}
                                </span>
                            </ToolTip>
                        </ToolTipContainer>
                    )}
                </StyledLabel>
            )
        } else
            return <CompactLabel labelColor={labelColor}>{label}</CompactLabel>
    }

    renderErrorLabel() {
        const { compactLabel, errorText } = this.props

        if (!errorText) {
            return null
        }

        if (!compactLabel) {
            return (
                <ErrorTextBox>{errorText ? errorText : undefined}</ErrorTextBox>
            )
        } else
            return (
                <CompactLabel hasDanger={true}>
                    {errorText || null}
                </CompactLabel>
            )
    }

    renderDay = (props, currentDate, selectedDate) => {
        return (
            <td key={props.key}>
                <BubbleContainer>
                    <Bubble {...props}>{currentDate.date()}</Bubble>
                </BubbleContainer>
            </td>
        )
    }

    renderMonth = (props, month, year, selectedDate) => {
        return (
            <td key={props.key}>
                <BubbleContainer>
                    <Bubble width="48px" height="48px" {...props}>
                        {moment()
                            .month(month)
                            .format('MMM')}
                    </Bubble>
                </BubbleContainer>
            </td>
        )
    }

    renderYear = (props, year, selectedDate) => {
        return (
            <td key={props.key}>
                <BubbleContainer>
                    <Bubble width="48px" height="48px" {...props}>
                        {year}
                    </Bubble>
                </BubbleContainer>
            </td>
        )
    }

    arrowAppendTimeout

    handleArrowAppend = id => {
        if (this.arrowAppendTimeout) {
            clearTimeout(this.arrowAppendTimeout)
        }
        this.arrowAppendTimeout = setTimeout(() => {
            const viewModeParent = document.querySelectorAll(
                `.${id} .rdtPicker .rdtSwitch`
            )[0]
            if (viewModeParent && viewModeParent.childElementCount === 0) {
                let arrow = document.createElement('img')
                arrow.style = 'margin-left: 6px; top: 25px; position: absolute;'
                arrow.src = '/icons/v2_bottom_arrow.svg'
                arrow.width = '12'
                arrow.height = '6'
                arrow.alt = 'down'

                viewModeParent.appendChild(arrow)
            }
        }, 10)
    }

    renderSuggestedDate = () => {
        const { suggestDate, handleChange } = this.props

        return (
            <SuggestDate>
                {applyTranslationElement(
                    t`flight_date.suggest`,
                    '<date>',
                    <span className="s-date">{suggestDate}</span>,
                    true
                )}
                &nbsp;
                <span
                    className="s-link"
                    onClick={() => {
                        this.onChange(suggestDate, handleChange)
                        store.dispatch(
                            logKibana(
                                'DATE_SUGGEST_CLICK',
                                '/flight-details',
                                'warning'
                            )
                        )
                    }}
                >
                    {t`common.click_here`}
                </span>
            </SuggestDate>
        )
    }

    render() {
        const {
            name,
            placeholder,
            value,
            readOnly,
            locale,
            initialViewMode,
            timeFormat,
            initialViewDate,
            handleChange,
            classNames,
            errorText,
            id,
            suggestDate,
            label,
            ...props
        } = this.props
        const { date } = this.state
        const loc = locale || getStorage('lang')

        return (
            <Container
                value={value}
                className={`detail-input needsclick ${name} ${id} ${classNames}`}
                name={name}
                hasDanger={!!errorText}
                disabled={readOnly}
                innerRef={ref => {
                    this.dateTimeWrapperRef = ref
                }}
            >
                <Datetime
                    ref={ref => {
                        this.dateTimeRef = ref
                    }}
                    utc={true}
                    onNavigate={() => this.handleArrowAppend(id)}
                    renderDay={this.renderDay}
                    renderMonth={this.renderMonth}
                    renderYear={this.renderYear}
                    value={(value && moment(value)) || date}
                    initialViewMode={initialViewMode || 'days'}
                    initialViewDate={initialViewDate}
                    timeFormat={timeFormat || false}
                    locale={moment.locale(mapCalendarLocale(loc))}
                    className={`notranslate inner-form-control-datepicker needsclick
                        ${props.errorText ? 'has-danger' : ''} ${
                        readOnly ? 'disabled' : ''
                    }`}
                    onNavigateBack={() => this.forceUpdate()}
                    onNavigateForward={() => this.forceUpdate()}
                    inputProps={{
                        name,
                        id,
                        disabled: readOnly,
                        placeholder: label,
                        readOnly: true,
                        value:
                            (value && moment(value).format('YYYY-MM-DD')) ||
                            date,
                        className:
                            'notranslate form-control form-control--datepicker js-datepicker js-checkable needsclick data-hj-whitelist',
                        onTouchEnd: e =>
                            readOnly ? () => null : this.toggleDatePicker(e),
                    }}
                    onChange={date => this.onChange(date, handleChange)}
                    {...props}
                />
                {this.renderErrorLabel()}
                {suggestDate && this.renderSuggestedDate()}
            </Container>
        )
    }
}

Datepicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    viewMode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    viewDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    timeFormat: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    locale: PropTypes.string,
    isValidDate: PropTypes.func,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    errorText: PropTypes.string,
    label: PropTypes.string,
    dateFormat: PropTypes.string,
}

export default Datepicker
