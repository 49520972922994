import React from 'react'
import styled from 'styled-components'
import { PlaneIcon1 } from '../svgIcons'
import { desktop, tablet, mobile } from '../helpers/styled_queries'
import { Col, ElementTitle } from './Layout'

const RadioContainer = styled.div`
    display: flex;
    flex: ${({ flexOptions }) => (flexOptions ? flexOptions : '1')};
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : 'flex-start'};
    background: #fafafa;

    align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
    // border: 1px solid #cbd3df;

    border-radius: 16px;
    margin-right: 8px;
    padding: ${({ padding }) => (padding ? padding : ' 13px 18px;')};
    border: 1px solid #717171;
    height: ${({ height }) => (height ? height : '56px')};
    margin-top: 8px;

    :last-child {
        margin-right: ${({ marginR }) => !marginR && '0px;'}
    }

    margin-right: ${({ isLast, isVertical, marginR }) =>
        marginR ? marginR : isVertical && !isLast ? '0px' : '8px'};
    // margin-left: ${({ isLast }) => isLast && '8px'};
`

const RadioReasonsContainer = styled(RadioContainer)`
    display: flex;
    flex: 1;
    min-height: 80px;
    border-radius: 16px;
    background: #fafafa;
    border-color: ${({ checked }) => (checked ? '#149954' : '#717171')};

    ${desktop`
        display: flex;
        flex-direction: row;
        // flex: 0 0 126px;
        min-height: 136px;
    `};
`

const ConnectingFlightContainer = styled(RadioContainer)`
    line-height: 14px;
`

const InnerContainer = styled.div`
    display: flex;
    padding: 0px;
    align-items: center;
    width: 100%;
    height: 100%;
`

const InnerFlightContainer = styled(InnerContainer)`
    flex-direction: row;
    padding: 0;
    display: flex;
    width: 100%;
    text-align: center;
    padding-left: 12px;

    svg {
        width: 16px;
        height: 16px;
        margin: 0 16px;
    }
`

const RadioLabel = styled.label`
    margin: 0;
    transition: all 0.3s linear;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #000000;
    height: 100%;
    padding: ${({ padding }) => padding || '12px 8px;'} 



    ${mobile`
        ${({ smallMobile }) => smallMobile && 'padding: 12px 8px;'}
    `}

    &:hover, &:focus, &:active {
        transition: all 0.3s linear;
        span:last-child:before {
            border: 1px solid ${({ theme }) => theme.colors.primaryColor};
        }
    }

    span {
        ${({ center }) =>
            center
                ? `
            text-align: center;
            margin-left: 22px;
        `
                : `
            padding-left: 12px;
        `}
    }

    span:last-child:before {
        transition: all 0.3s linear;
        position: absolute;
        content: '';
        top: ${({ circleTopLeft }) =>
            !circleTopLeft ? `calc(50% - 10px)` : '16px'};
        left: ${({ smallMobile }) => (smallMobile ? 0 : 0)}px;
        width: 20px;
        height: 20px;
        border: 1px solid #717171;
        background-color: #fff;
        border-radius: 50%;
    }

    span:last-child:after {
        transition: all 0.3s linear;
        background-color: #fff;
    }

    [type='radio']:checked + span:last-child:before {
        transition: all 0.3s linear;
        position: absolute;
        content: '';
        left: ${({ smallMobile }) => (smallMobile ? 0 : 0)}px;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    }

    [type='radio']:checked + span:last-child {
        text-shadow: 0 0 0.4px #000000;
        cursor: pointer;
    }

    [type='radio'] {
        opacity: 0;
        position: absolute;
    }

    [type='radio']:checked + span:last-child:after {
        transition: all 0.3s linear;
        position: absolute;
        content: '';
        top: ${({ circleTopLeft }) =>
            !circleTopLeft ? `calc(50% - 4px)` : '20px'};
        left: ${({ smallMobile }) => (smallMobile ? 6 : 6)}px;
        width: 8px;
        height: 8px;

        background-color: ${({ theme }) => theme.colors.primaryColor};
        border-radius: 50%;
    }
`

const RadioReasonsLabel = styled.label`
    padding: 0;
    margin: 0;
    transition: all 0.3s linear;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #000000;
    height: 100%;

    &:hover {
        transition: all 0.3s linear;
    }

    span {
        width: 100%;
    }

    [type='radio']:checked + span:last-child {
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
        border-radius: 6px;
    }

    [type='radio']:checked + span {
        text-shadow: 0 0 0.4px #000000;
    }

    [type='radio'] {
        opacity: 0;
        position: absolute;
    }
`

const RadioReasonsLabel2 = styled.label`
    padding: 0;
    margin: 0;
    transition: all 0.3s linear;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #000000;
    height: 100%;

    &:hover {
        transition: all 0.3s linear;
    }

    span {
        width: 100%;
    }

    [type='radio']:checked + span {
        text-shadow: 0 0 0.4px #000000;
    }

    [type='radio'] {
        opacity: 0;
        position: absolute;
    }
`

const RadioInputLabel = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${({ marginLabelLeft }) =>
        marginLabelLeft ? `${marginLabelLeft}px;` : '8px;'};
`

const RadioInput = styled.input``

const SkycopCareStyledRadio = styled.input`
    appearance: none;
    margin: 0;
    width: 24px;
    height: 24px;
    border: 1px solid ${({ checked }) => (checked ? '#149954' : '#e0e0e0')};
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
    margin-right: 16px;

    &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin: 7px;
    }
    &:checked::after {
        background-color: #149954;
    }
    &:hover::after {
        background-color: #149954;
    }

    &:hover {
        border: 1px solid #149954;
    }
`

const SkycopCareStyledLabel = styled.label`
  font-weight: 600;
  font-size: 1rem;
  color: green
  cursor: default;
  ${({ disabled }) =>
      disabled &&
      `
    color: blue;
    cursor: not-allowed;
    &::after {
      margin-left: 8px;
      width: 12px;
      height: 15px;
      display: inline-block;
      content: " ";
      -webkit-mask: url("/lock.svg") no-repeat 50% 50%;
      -webkit-mask-size: cover;
      background-color: red;
    }
  `}
`

const SkycopCareWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
`

const SkycopCareTopWrapper = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 32px 24px 8px 24px;
    border-radius: 24px;
    border: 2px solid ${({ checked }) => (checked ? '#149954' : ' #e0e0e0')};

    background: ${({ checked }) => (checked ? '#ECF7F1' : ' #ffffff')};

    box-shadow: 0px 12px 24px 0px #07331c0a;
`

const SkycopCareFooterWrapper = styled.div`
    padding-top: 16px;
    margin-top: 16px;

    background-image: linear-gradient(
        to right,
        ${({ checked }) => (checked ? '#149954' : '#E0E0E0')} 40%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
`

export const RadioButton = ({
    children,
    flexOptions,
    center,
    smallMobile,
    circleTopLeft,
    isLast,
    isVertical,
    height,
    padding,
    marginLabelLeft,
    ...props
}) => {
    return (
        <RadioContainer
            className="radio-outter"
            justifyContent="center"
            flexOptions={flexOptions}
            isLast={isLast}
            isVertical={isVertical}
            height={height}
            padding={padding}
        >
            <RadioLabel
                padding={padding}
                center={!!center}
                smallMobile={!!smallMobile}
                circleTopLeft={!!circleTopLeft}
            >
                <RadioInput type="radio" {...props} />
                <RadioInputLabel marginLabelLeft={marginLabelLeft}>
                    {children}
                </RadioInputLabel>
            </RadioLabel>
        </RadioContainer>
    )
}

export const RadioReasonsButton = ({ children, flexOptions, ...props }) => (
    <RadioReasonsContainer
        flexOptions={flexOptions}
        height="unset"
        padding="6px"
    >
        <RadioReasonsLabel>
            <RadioInput type="radio" {...props} />
            <span>
                <InnerContainer>{children || ''}</InnerContainer>
            </span>
        </RadioReasonsLabel>
    </RadioReasonsContainer>
)

export const RadioReasonsButton2 = ({ children, flexOptions, ...props }) => (
    <RadioReasonsContainer
        flexOptions={flexOptions}
        height="unset"
        padding="6px"
        checked={props.checked}
    >
        <RadioReasonsLabel2>
            <RadioInput type="radio" {...props} />
            <span>
                <InnerContainer>{children || ''}</InnerContainer>
            </span>
        </RadioReasonsLabel2>
    </RadioReasonsContainer>
)

const VerticalCol = styled(Col)`
    width: 100%;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
    padding-right: 0px;
    ${mobile`
    padding-right: 16px;
        
        `}
    .radio-content {
        & > :first-child {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        & > :last-child {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }

    .block-width {
        width: fit-content;
        min-width: 50%;
        display: block;

        ${tablet`
            min-width: 100%;
        `}

        ${mobile`
            min-width: 100%;
        `}
    }
`

const RadioGroupWrapper = styled.span`
    flex-wrap: wrap;
`

export const VerticalRadio = ({
    label,
    required,
    options,
    onChange,
    value,
    marginLabelLeft,
    maxWidth,
    id,
}) => (
    <VerticalCol
        maxWidth={maxWidth}
        xs={12}
        sm={maxWidth ? 12 : 8}
        md={maxWidth ? 12 : 6}
        lg={maxWidth ? 12 : 6}
    >
        <ElementTitle noWrap id={id || ''}>
            {label}
            {required ? <span>&nbsp;*</span> : null}
        </ElementTitle>
        <RadioGroupWrapper className="radio-content">
            {options.map((option, index) => (
                <RadioButton
                    isLast={options.length === index + 1}
                    isVertical={true}
                    key={index}
                    name={option.name}
                    value={option.value}
                    onChange={onChange || option.onChange}
                    checked={option.checked}
                    defaultChecked={option.defaultChecked}
                    id={option.id || ''}
                    marginLabelLeft={marginLabelLeft}
                >
                    {option.label}
                </RadioButton>
            ))}
        </RadioGroupWrapper>
    </VerticalCol>
)

const SidewaysCol = styled(Col)`
    .radio-content {
        display: flex;

        & > :first-child {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            border-left: 1px solid #717171;
        }

        & > :last-child {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }
`

export const SideWaysRadio = ({
    label,
    required,
    options,
    onChange,
    smallMobile,
}) => (
    <SidewaysCol xs={12} sm={8} md={6} lg={6}>
        {label && (
            <ElementTitle noMarginBottom={true} marginBottom="8px">
                {label}
                {required ? <span>&nbsp;*</span> : null}
            </ElementTitle>
        )}
        <RadioGroupWrapper className="radio-content">
            {options.map((option, i) => (
                <RadioButton
                    isLast={options.length === i + 1}
                    smallMobile={!!smallMobile}
                    key={option.label}
                    center={!!option.textCenter}
                    name={option.name}
                    value={option.value}
                    checked={option.checked}
                    onChange={onChange || option.onChange}
                    defaultChecked={option.defaultChecked}
                    id={option.id || ''}
                >
                    {option.label}
                </RadioButton>
            ))}
        </RadioGroupWrapper>
    </SidewaysCol>
)

export const FlightRadio = ({ options, label, required, onChange }) => (
    <VerticalCol xs={12} sm={8} md={true} lg={true}>
        <ElementTitle>
            {label}
            {required ? <span>&nbsp;*</span> : null}
        </ElementTitle>
        <span className="radio-content block-width">
            {options.map((option, i) => (
                <ConnectingFlightContainer key={i} marginR="12px">
                    <RadioLabel>
                        <RadioInput
                            type="radio"
                            name={option.name}
                            value={option.value}
                            onChange={onChange || option.onChange}
                            checked={option.checked}
                        />
                        <span>
                            <InnerFlightContainer>
                                {option.departure || ''}
                                <PlaneIcon1 fill="#149954" />
                                {option.arrival || ''}
                            </InnerFlightContainer>
                        </span>
                    </RadioLabel>
                </ConnectingFlightContainer>
            ))}
        </span>
    </VerticalCol>
)

export const RadioConnectingFlight = ({
    children,
    departure,
    arrival,
    flexOptions,
    ...props
}) => (
    <ConnectingFlightContainer flexOptions={flexOptions}>
        <RadioLabel>
            <RadioInput type="radio" {...props} />
            <span>
                <InnerFlightContainer>
                    {departure || ''}
                    <PlaneIcon1 />
                    {arrival || ''}
                </InnerFlightContainer>
            </span>
        </RadioLabel>
    </ConnectingFlightContainer>
)

const ImageColV2 = styled(Col)`
    &.item-col {
        padding-top: ${({ noPaddingT }) => noPaddingT && '0px;'};
        padding-bottom: ${({ noPaddingB }) => noPaddingB && '0px;'};
    }
    .radio-content2 {
        user-select: none;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        flex-wrap: wrap;

        & > div {
            margin-bottom: 10px;
        }

        ${desktop`
            flex-direction: row;

            & > div {
                margin-bottom: 8px;
            }

            & > div:not(:last-child) {
                margin-right: 8px;
            }
        `}
        ${mobile`
            flex-direction: ${({ mobileDirection }) =>
                mobileDirection ? mobileDirection : 'row'};
        `}
    }
`

const ReasonIcon2 = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    padding: 4px 6px 16px 4px;
    flex-direction: column;

    p {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #000000;

        color: ${({ checked }) => checked && '#262626'};

        width: 100%;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        padding-top: 20px;
        margin: 0;
    }

    img {
        height: 48px;
        width: 48px;
        fill: red;
    }

    ${desktop`
        flex-direction: column;
        padding: 4px 6px 16px 4px;

        p {
            text-align: center;
            margin-top: 12px;
            padding-left: 0px;
        }
    `}
`

export const ImageRadio = ({
    options,
    label,
    required,
    onChange,
    id,
    noPaddingB,
    noPaddingT,
    mobileDirection,
}) => {
    return (
        <ImageColV2
            xs={12}
            sm={12}
            md={12}
            lg={12}
            noPaddingB={noPaddingB}
            noPaddingT={noPaddingT}
            mobileDirection={mobileDirection}
        >
            {label && (
                <ElementTitle marginBottom="8px" id={id || ''}>
                    {label}
                    {required ? <span> *</span> : null}
                </ElementTitle>
            )}
            <span className="radio-content2">
                {options.map((option, index) => (
                    <RadioReasonsButton2
                        isLast={options.length === index + 1}
                        key={index}
                        name={option.name}
                        checked={option.checked}
                        value={option.value}
                        onChange={onChange || option.onChange}
                        id={option.id || ''}
                    >
                        <ReasonIcon2 checked={option.checked} mask={option.src}>
                            {option.icon}
                            <p>{option.label}</p>
                        </ReasonIcon2>
                    </RadioReasonsButton2>
                ))}
            </span>
        </ImageColV2>
    )
}

export const SkycopCarePlanRadio = ({
    checked,
    onChange,
    name,
    content,
    footer,
}) => (
    <SkycopCareTopWrapper checked={checked} onClick={onChange}>
        <SkycopCareWrapper>
            <SkycopCareStyledRadio
                checked={checked}
                type="radio"
                id={`radio-button-${name}`}
            />
            <SkycopCareStyledLabel htmlFor={`radio-button-${name}`}>
                {content && <div>{content}</div>}
            </SkycopCareStyledLabel>
        </SkycopCareWrapper>
        {footer && (
            <SkycopCareFooterWrapper checked={checked}>
                {footer}
            </SkycopCareFooterWrapper>
        )}
    </SkycopCareTopWrapper>
)
