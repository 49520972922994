import styled from "styled-components";
import { ContentBox } from "../../../../shared/components/V2/Layout";
import { desktop, mobile, tablet } from "../../../../shared/components/helpers/styled_queries";

export const PageWrapper = styled(ContentBox)`
    margin: 0 auto;
    margin-top: 32px;
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${desktop`
        width: ${({ width }) => width || "568px"};
        height: ${({ height }) => height || "448px"};
    `}
`;

export const Text = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #7f8fa4;

    ${tablet`
        max-width: 80%;
        margin: 40px auto;
    `}

    ${mobile`
        margin: 32px auto;
    `}
`;

export const Title = styled.h1`
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;
`;

export const ButtonContainer = styled.div`
    padding: 0 166px;
`;

export const FeedbackContainer = styled.div`
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${desktop`
        width: 568px;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
        background-color: #ffffff;
    `}

    .pd {
        padding: 40px 20px 0;

        ${mobile`
            padding: 40px 8px 0px;
        `}

        .item-col {
            max-width: 100%;
        }
    }

    div {
        .text-center {
            text-align: center;
        }
    }
`;

export const FeedbackSubheaderContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;

    ${desktop`
        padding: 0 40px;
    `}
`;

export const FeedbackButtonContainer = styled.div`
    width: 100%;
    padding: 32px;

    ${desktop`
        padding: 32px 174px;
        border-top: 1px solid #e4e7eb;
    `}
`;
