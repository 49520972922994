import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { browserHistory } from 'react-router'
import _get from 'lodash/get'
import t from '../../../shared/translations'
import { showModal, setNavStep } from '../../../shared/actions/v2_actions'
import StepView from '../components/StepView'
import { Row, StepContent } from '../../../shared/components/V2/Layout'
import {
    mobile,
    desktop,
} from '../../../shared/components/helpers/styled_queries'
import {
    RabbitLargeIcon,
    CardIcon,
    LetterIcon,
    InfoIcon2,
} from '../../../shared/components/V2/V2Icons'
import {
    scrollComponentToView,
    isProduction,
    applyTranslationAction,
    // showRandomModal,
    isFeatureEnabled,
} from '../../../main'
import { mapSteps } from '../../../shared/reducers/V2/reducer_steps'
import {
    V2_prePath,
    paths,
    modalTypes,
    featureToggles,
} from '../../../shared/constants'
import CongratsSurveyModal from '../../../shared/components/AboutUsSurvey/AboutUsSurveyModal'
import SidebarProgress from '../components/SidebarProgress'

const StyledStepContent = styled(StepContent)`
    padding: 12px 16px;
`

const Text = styled.p`
    font-family: 'Noto sans';
    font-size: 16px;
    font-weight: 400;

    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;

    span {
        font-weight: 400;
        color: #32b86a;
    }

    &.clickable {
        span {
            cursor: pointer;
        }
    }
`

const List = styled.ul`
    padding-left: 20px;
    color: #000000;
    margin-bottom: 40px;
`

export const EmailContainer = styled.div`
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    border-radius: 8px;
    background-color: #ecf7f1;
    padding: 16px 40px;
    font-family: 'Noto sans';
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #149954;
    margin: 0px 0px 40px 0px;
    word-break: break-all;
    display: flex;
    width: fit-content;
    align-items: center;
    svg {
        margin-right: 8px;
    }

    ${mobile`
       width: 100%;
       text-align: left;
    `}
`

const InfoBox = styled.div`
    border-radius: 8px;
    backdrop-filter: blur(17px);
    background-color: #ECF7F1;
    // background-color: ${({ bgColor }) =>
        bgColor || 'rgba(82, 150, 255, 0.08)'};
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-family: 'Noto sans';
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #149954;

        margin: 0;

        ${desktop`
            padding-right: 10px;
        `}

        span {
            font-weight: bold;
            // color: ${({ textColor }) => textColor || '#353d51'};
             color: #149954;
        }
    }

    ${desktop`
        flex-direction: row;
    `}

      ${mobile`
        padding: 20px;
    `}
`

const DesktopBtn = styled.button`
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    border: 0;
    min-width: 104px;
    min-height: 48px;
    border-radius: 24px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ theme }) =>
        `${
            theme.colors.actionButton
                ? `background-color: ${theme.colors.actionButton};`
                : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.quaternaryColor})};`
        }`}
    color: #ffffff;
    outline: 0 none;
    font-size: 14px;

    &:hover {
        filter: brightness(105%);
    }

    &:focus {
        outline: 0 none;
    }
`

const DesktopBorderBtn = styled(DesktopBtn)`
    background: transparent;
    border-radius: 16px;
    height: 48px;
    border: solid 1px #149954;
    width: 100%;
    // color: #353d51;
    color: #149954;
    width: 100%;
    height: auto;
    margin-top: 16px;

    ${desktop`
        max-width: 205px;
        margin-top: 0;
    `}
`

const InfoBoxContent = styled.div`
    display: flex;
`

const IconBox = styled.div`
    display: flex;
    margin-right: 16px;
    align-items: center;

    ${mobile`
         align-items: start;
         padding-top: 6px;
    `}
`

const ListTitleText = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    color: #717171;
    margin-bottom: 40px;
`

const TextBox = styled.div`
    p {
        font-family: 'Noto sans';
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        // color: ${({ textColor }) => textColor || '#7f8fa4'};
        color: #149954;

        margin: 0;
    }

    span {
        font-weight: 600;
        // color: ${({ textColor }) => textColor || '#353d51'};
        color: #149954;

    }
`

const TitleContainer = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right2px;
    }

    ${mobile`
        flex-direction: column;

        svg {
            margin-bottom: 12px;
            margin-right: 0px;
        }
    `}
`

class ThankYou extends React.Component {
    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {}

        const { authenticated, postedClaim, showModal } = this.props
        scrollComponentToView('pageTopNavbar', false, true)

        this.props.setParentState({
            nextDisabled: false,
            noBack: true,
            buttonText: t`menu.my_claims`,
            hideButton: !authenticated,
            saveDraftEnabled: false,
            renderSocialShare: true,
            renderVirtualCards: true,
            renderTrustPilot: true,
            hideMobileTrustPilot: true,
            avoidShrink: true,
        })

        const email = _get(
            postedClaim,
            'user.email',
            _get(postedClaim, 'draft.user.email', '')
        )

        if (!email && isProduction()) {
            browserHistory.replace(V2_prePath)
        }

        // Feedback modal, temporary disabled becouse of skycop care modal
        // const claimId = _get(
        //     postedClaim,
        //     'id',
        //     _get(postedClaim, 'draft.id', '')
        // )
        // showRandomModal(claimId)

        if (isFeatureEnabled(featureToggles.about_us_survey_enabled)) {
            showModal(modalTypes.congratsSurveyModal)
        } else {
            if (isFeatureEnabled(featureToggles.claimCompletePromoCodes)) {
                showModal(modalTypes.promoGiftModal)
            } else {
                showModal(modalTypes.skycopCareModal)
            }
        }
    }

    componentWillUnmount() {
        this.props.setParentState({
            renderVirtualCards: false,
            renderSocialShare: false,
            renderTrustPilot: false,
            avoidShrink: false,
        })
    }

    _handleNextStep = () => {
        const { authenticated } = this.props
        if (authenticated) {
            browserHistory.push('/claims')
        }
    }

    openHelpChat = () => {
        const $zopim = window.$zopim

        $zopim.livechat.window.show()
    }

    renderTitle = () => {
        return (
            <TitleContainer>
                {/* <V2CheckBubble /> */}
                {t`v2_claim.thank_you.title`}
            </TitleContainer>
        )
    }

    getDisplayCase = () => {
        const { authenticated, user } = this.props
        const hasPayment = _get(user, 'user_payment', null)

        if (authenticated) {
            if (!hasPayment) {
                return 'enter_bank_details'
            } else {
                return 'check_bank_details'
            }
        } else {
            return 'register_bank_details'
        }
    }

    getFastBgColor = () => {
        switch (this.getDisplayCase()) {
            case 'check_bank_details':
                return null
            case 'enter_bank_details':
                return '#f16464'
            case 'register_bank_details':
                return '#f16464'
            default:
                return null
        }
    }

    render() {
        const {
            trans,
            postedClaim,
            authenticated,
            showModal,
            isFastSelected,
            nav,
        } = this.props
        const email = _get(
            postedClaim,
            'user.email',
            _get(postedClaim, 'draft.user.email', '')
        )
        const fastPayoutCase = this.getDisplayCase()

        if (!trans) return null

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                noBack={true}
                nav={{ ...nav, saveDraftEnabled: false }}
                title={this.renderTitle}
                titleText={t`v2_claim.thank_you.title`}
                disableTopBorder={true}
                buttonText={t`menu.my_claims`}
                hideButton={!authenticated}
                hideBorder={!authenticated}
                renderSocialShare={true}
                renderTrustPilot={true}
                renderVirtualCards={true}
                Sidebar={
                    <SidebarProgress
                        paddingTop={0}
                        activeIndex={6}
                        checkedIndexes={[0, 1, 2, 3, 4, 5]}
                        hideFast={true}
                    />
                }
            >
                <CongratsSurveyModal />
                <StyledStepContent>
                    <Row>
                        <ListTitleText>{t`v2_thank_you.information_title`}</ListTitleText>
                    </Row>
                    <Row>
                        {this.props.trans && (
                            <div>
                                <List>
                                    <li>
                                        <Text>
                                            {t`v2_thank_you.email_information`}
                                        </Text>
                                    </li>
                                </List>
                                <EmailContainer>
                                    <LetterIcon />
                                    {email}
                                </EmailContainer>
                                <List>
                                    <li>
                                        <Text className="clickable">
                                            {applyTranslationAction(
                                                t`thank_you.contact_support`,
                                                '<a>',
                                                '</a>',
                                                this.openHelpChat
                                            )}
                                        </Text>
                                    </li>
                                    <li>
                                        <Text
                                            dangerouslySetInnerHTML={{
                                                __html: t`v2_thank_you.check_spam`,
                                            }}
                                        />
                                    </li>
                                </List>
                            </div>
                        )}
                    </Row>
                    {isFastSelected && (
                        <Row>
                            <InfoBox
                                bgColor={this.getFastBgColor()}
                                textColor={
                                    fastPayoutCase !== 'check_bank_details'
                                        ? '#fff'
                                        : null
                                }
                            >
                                <Row>
                                    <InfoBoxContent>
                                        <IconBox>
                                            {fastPayoutCase ===
                                            'register_bank_details' ? (
                                                <RabbitLargeIcon />
                                            ) : (
                                                <CardIcon
                                                    dark={
                                                        fastPayoutCase ===
                                                        'check_bank_details'
                                                    }
                                                />
                                            )}
                                        </IconBox>
                                        <TextBox
                                            textColor={
                                                fastPayoutCase !==
                                                'check_bank_details'
                                                    ? '#fff'
                                                    : null
                                            }
                                        >
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: t`v2_claim.thank_you.fast.${fastPayoutCase}`,
                                                }}
                                            />
                                        </TextBox>
                                    </InfoBoxContent>
                                </Row>
                                <DesktopBorderBtn
                                    onClick={() => {
                                        if (
                                            fastPayoutCase ===
                                            'register_bank_details'
                                        ) {
                                            showModal(modalTypes.loginModal, {
                                                register: true,
                                            })
                                        } else {
                                            browserHistory.replace(
                                                paths.PAYMENT
                                            )
                                        }
                                    }}
                                >
                                    {t`v2_claim.thank_you.fast.button.${fastPayoutCase}`}
                                </DesktopBorderBtn>
                            </InfoBox>
                        </Row>
                    )}
                    {!authenticated && !isFastSelected && (
                        <Row>
                            <InfoBox style={{ width: '100%' }}>
                                <Row>
                                    <InfoBoxContent>
                                        <IconBox>
                                            <InfoIcon2 />
                                        </IconBox>
                                        <TextBox>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: t`v2_claim.thank_you.track_status`,
                                                }}
                                            />
                                        </TextBox>
                                    </InfoBoxContent>
                                </Row>
                                <DesktopBorderBtn
                                    onClick={() =>
                                        showModal(modalTypes.loginModal, {
                                            register: true,
                                            user:
                                                _get(
                                                    this,
                                                    'props.postedClaim.user'
                                                ) ||
                                                _get(
                                                    this,
                                                    'props.postedClaim.draft.user'
                                                ),
                                        })
                                    }
                                >
                                    {t`v2_claim.thank_you.create_account`}
                                </DesktopBorderBtn>
                            </InfoBox>
                        </Row>
                    )}
                </StyledStepContent>
            </StepView>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        postedClaim: state.v2_posted_claim,
        authenticated: state.auth.authenticated,
        isFastSelected: state.isFastSelected,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(ThankYou)
