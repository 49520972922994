import React, { Component } from 'react'
import { connect } from 'react-redux'
import { featureToggles } from '../../../shared/constants'
import { isFeatureEnabled } from '../../../main'
import { V2_CORE } from '../../../shared/constants'

const TRUSTPILOT_LOCALES = {
    da: 'da-DK', // Danish-Denmark
    de: 'de-DE', // German-Germany
    en: 'en-GB', // English-United Kingdom
    es: 'es-ES', // Spanish-Spain
    f: 'fi-FI', // Finnish-Finland
    fr: 'fr-FR', // French-France
    it: 'it-IT', // Italian-Italy
    no: 'nb-NO', // Norwegian Bokmål-Norway
    nl: 'nl-NL', // Dutch-Netherlands
    pl: 'pl-PL', // Polish-Poland
    pt: 'pt-PT', // Portuguese-Portugal
    sv: 'sv-SE', // Swedish-Sweden
    au: 'en-AU', // English-Australia
    ie: 'en-IE', // English-Ireland
    at: 'de-AT', // German-Austria
    ch: 'de-CH', // German-Switzerland
    be: 'nl-BE', // Dutch-Belgium
    jp: 'ja-JP', // Japanese-Japan
    br: 'pt-BR', // Brazilian Portuguese-Brazil
    us: 'en-US', // English-United States
    ca: 'en-CA', // English-Canada
    nz: 'en-NZ', // English-New Zealand
    ru: 'ru-RU',
}

const REVIEWS_LANG = ['ru', 'es', 'it', 'fr', 'pt', 'lt', 'lv', 'ro']

const TrustHorizontalBox = ({ trustBoxRef, lang }) => {
    let style = { width: '100%' }
    if (
        !isFeatureEnabled(featureToggles.trastpilot_widget) ||
        window.location.pathname === V2_CORE.STEP_7.path
    ) {
        style.display = 'none'
    }

    return (
        <div
            style={style}
            ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
            className="trustpilot-widget"
            data-locale={widgedLocale(lang)}
            data-template-id="5406e65db0d04a09e042d5fc"
            data-businessunit-id="5901b3980000ff0005a14fc6"
            data-style-height="28px"
            data-style-width="100%"
            data-theme="light"
        >
            <a
                href="https://www.trustpilot.com/review/example.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
    )
}

const TrustCarousel = ({ trustBoxRef, lang, enabled }) => {
    let style = { width: '100%' }
    if (
        !isFeatureEnabled(featureToggles.trastpilot_widget) ||
        window.location.pathname === V2_CORE.STEP_7.path
    ) {
        style.display = 'none'
    }

    return (
        <div
            style={style}
            ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
            data-locale={widgedLocale(lang)}
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5901b3980000ff0005a14fc6"
            data-style-height="140px"
            data-style-width="100%"
            data-theme="light"
            data-stars="4,5"
            data-review-languages={REVIEWS_LANG.includes(lang) ? lang : 'en'}
        >
            <a
                href="https://www.trustpilot.com/review/example.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
    )
}

class TrustBoxContainer extends Component {
    constructor(props) {
        super(props)
        this.trustBoxRef = React.createRef()
    }
    componentDidMount() {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang !== this.props.lang) {
            if (window.Trustpilot) {
                window.Trustpilot.loadFromElement(
                    this.trustBoxRef.current,
                    true
                )
            }
        }
    }

    render() {
        const { type, lang } = this.props

        return type === 'carousel' ? (
            <TrustCarousel trustBoxRef={this.trustBoxRef} lang={lang} />
        ) : (
            <TrustHorizontalBox trustBoxRef={this.trustBoxRef} lang={lang} />
        )
    }
}

function widgedLocale(lang) {
    const locale = TRUSTPILOT_LOCALES[lang]

    return locale ? locale : TRUSTPILOT_LOCALES['en']
}

function mapStateToProps(state) {
    return {
        lang: state.language,
        trans: state.translations,
        webLanguages: state.web_languages,
    }
}

export default connect(mapStateToProps)(TrustBoxContainer)
