import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CheckMarkContainer = styled.span`
    vertical-align: middle;
    display: inline-block;
    width: 30px;
    height: 30px;
    left: 0;
    position: absolute;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
`

const CheckMarkStem = styled.span`
    position: absolute;
    width: 3px;
    height: 15px;
    background-color: #d9534f;
    left: 9px;
    top: 7px;
`

const CheckMarkKick = styled.span`
    position: absolute;
    width: 9px;
    height: 3px;
    background-color: #d9534f;
    left: 2px;
    top: 20px;
`

export const CheckMarkText = ({ label }) => (
    <Container style={{ cursor: 'default', marginBottom: 5 }}>
        <StyledLabel style={{ cursor: 'default' }}>
            <CheckMarkContainer>
                <CheckMarkStem />
                <CheckMarkKick />
            </CheckMarkContainer>
            <span style={{ verticalAlign: 'middle' }}>{label}</span>
        </StyledLabel>
    </Container>
)

const StyledCheckmark = styled.span`
    border-radius: 6px;

    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: white;
    border: 1px solid #149954;
    &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        width: 5px;
        height: 9px;
        border: 1px solid #149954;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`

const StyledCheckBox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ ${StyledCheckmark}:after {
        display: block;
        color: red;
    }
    &:checked ~ ${StyledCheckmark} {
        background-color: #ffffff;
    }
`

const StyledLabel = styled.label`
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    ${({ isDisabled }) =>
        isDisabled &&
        `
        cursor: not-allowed;
    `}
`

const Container = styled.p`
    margin-bottom: 0px;
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
`

const CheckBox = ({ label, checked, disabled, ...props }) => (
    <Container>
        <StyledLabel isDisabled={disabled}>
            <StyledCheckBox
                type="checkbox"
                {...props}
                checked={checked}
                disabled={disabled}
            />
            <StyledCheckmark />
            <span>{typeof label === 'string' ? label : label()}</span>
        </StyledLabel>
    </Container>
)

CheckBox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
}

CheckBox.defaultProps = {
    checked: false,
    label: '',
}

export default CheckBox
