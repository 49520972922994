import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Datepicker from './DatePicker';

class BirthDateField extends Component {

    valid(current) {
        const daysBefore = 365 * 150;
        const today = moment();
        const last = moment().subtract(daysBefore, 'days');
        return current.isBefore(today) && current.isAfter(last);
    };

    render() {
        const { value, onChange,  ...props } = this.props

        return(
            <Datepicker 
                innerRef={ref => {
                    this.dateTimeWrapperRef = ref;
                }}
                value={value}
                dateFormat="YYYY-MM-DD"
                viewMode="years"
                viewDate={value || new Date("1980")}
                isValidDate={this.valid}
                startDate={2020}
                endDate={150}
                handleChange={onChange}
                {...props}
            />
        )
    }
}

BirthDateField.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    viewDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    compactLabel: PropTypes.bool,
    errorText: PropTypes.string,
}

export default BirthDateField;
