import React from 'react'
import styled from 'styled-components'
import { AlertIcon } from '../../../../shared/components/V2/V2Icons'

const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 12px;
`

const IconWrapper = styled.div`
    color: #f16964;
    margin-right: 10px;
    display: flex;
`

const MessageWrapper = styled.div`
    flex-grow: 1;
    color: #f16964;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Open Sans';
`

export const ErrorMessage = ({ errorText }) => (
    <ErrorWrapper>
        <IconWrapper>
            <AlertIcon />
        </IconWrapper>
        <MessageWrapper>{errorText}</MessageWrapper>
    </ErrorWrapper>
)
