import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showModal } from '../../../shared/actions/v2_actions'
import { DesktopBorderBtn } from './StepNavigations'
import { AbsoluteCorner, StopWatch } from './InfoSidebar'
import {
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import t from '../../../shared/translations'
import { modalTypes } from '../../../shared/constants'
import { logRaw, logLevel } from '../../../shared/services/logger'
import _get from 'lodash/get'

const FastPayoutSidebar = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 16px;
    padding: 24px;
    background: #32b86a;
    color: #fff;
    box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    border-radius: 8px;
    overflow: hidden;
`

export const RabbitIcon = styled.div`
    min-width: 54px;
    background: url('/images/rabbit${({ dark }) => (dark ? '_dark' : '')}.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 24px;
    align-self: center;

    ${tablet`
        height: 20px;
    `}

    ${mobile`
        height: 20px;
    `}
`

export const InfoArea = styled.div`
    display: flex;
    padding-top: 16px;
`

export const InfoText = styled.div`
    font-size: 12px;
    padding-left: 16px;
    text-align: left;

    span {
        font-weight: bold;
    }

    ${mobile`
        color: #353d51;
    `}
`

export const StyledButton = styled(DesktopBorderBtn)`
    line-height: 1.41;
    margin: 0;
    margin-top: 24px;
    color: #353d51;
    font-weight: bold;
    background: #fff;
    border: 0 none;
    height: auto;
    padding: 10px 16px;

    &:hover {
        filter: unset;
        box-shadow: 0 5px 9px -5px rgba(255, 255, 255, 0.75);
        color: #646f8a;
    }

    ${tablet`
        border: solid 1px #98a3bf;
        font-size: 12px;
        padding: 8px 12px;
    `}

    ${mobile`
        border: solid 1px #98a3bf;
        font-size: 12px;
        padding: 8px 12px;
    `}
`

class FastPayoutSideBox extends React.Component {
    componentDidUpdate(prevProps) {
        const { fastPayouts, showModalOnLoad, callBack, showModal } = this.props

        if (
            fastPayouts.hasFast &&
            !prevProps.fastPayouts.hasFast &&
            showModalOnLoad
        ) {
            showModal(modalTypes.paymentPlanModal, { callBack })
        }
    }

    render() {
        const { fastPayouts, showModal, callBack, claim } = this.props

        if (fastPayouts && fastPayouts.hasFast) {
            this.logPayoutOptionShow(claim.id)

            return (
                <FastPayoutSidebar>
                    <AbsoluteCorner />
                    <StopWatch />
                    <InfoArea>
                        <RabbitIcon />
                        <InfoText
                            dangerouslySetInnerHTML={{
                                __html: t`v2_claim.sidebar.fast_payout_available`,
                            }}
                        />
                    </InfoArea>
                    <StyledButton
                        id="choose-payout-option"
                        onClick={() => {
                            showModal(modalTypes.paymentPlanModal, { callBack })
                            this.logChoosePayoutOptionClick(claim.id)
                        }}
                    >
                        {t`claim.complete.step_two.description_choose_payout_option`}
                    </StyledButton>
                </FastPayoutSidebar>
            )
        } else {
            return null
        }
    }

    logChoosePayoutOptionClick(claimId) {
        const step = _get(window, 'location.pathname', '/')
        logRaw(logLevel.warning, 'Payout options', {
            sStep: step,
            sClaimId: claimId,
            sAction: 'click',
        })
    }

    logPayoutOptionShow(claimId) {
        const step = _get(window, 'location.pathname', '/')
        logRaw(logLevel.warning, 'Payout options', {
            sStep: step,
            sClaimId: claimId,
            sAction: 'show',
        })
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        fastPayouts: state.v2_hasFastPayout,
        amount: state.v2_amount,
        totalAmount: state.v2_total_amount,
        distance: state.v2_distance,
        trans: state.translations,
        claim: state.V2Claim,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FastPayoutSideBox)
