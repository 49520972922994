import { SET_LANDING_PARAMETERS } from '../actions/actionTypes';


export default function (state = null, action) {
    switch (action.type) {
        case SET_LANDING_PARAMETERS:
            return {...state, ...action.payload};
        default:
    }

    return state;
};