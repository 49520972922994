import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import FileDragAndDrop from 'react-file-drag-and-drop'
import t from '../../../shared/translations'
import {
    Col,
    Row,
    PageViewContainer,
    PageContent,
} from '../../../shared/components/V2/Layout'
import {
    bytesToSize,
    scrollComponentToView,
    getApiClient,
    isMobileDevice,
    loadScreenOn,
    loadScreenOff,
} from '../../../main'
import {
    CloseIcon,
    DocumentIcon,
    UploadIcon,
    FailedDocIcon,
} from '../../../shared/components/V2/V2Icons'
import { showModal } from '../../../shared/actions/v2_actions'
import {
    StyledErrorsBox,
    DragDropContainer,
    StyledP,
    FileInputLabel,
    FileInput,
    FileUploadText,
    DragFile,
    TextContainer,
    MobileUploadContainer,
    IconContainer,
    CameraIcon,
    FilesIcon,
    MobileIconText,
    LineBreak,
    DocumentsContainer,
    DocumentOuter,
    DocumentInner,
    DocumentName,
    RemoveButtonContainer,
    DocumentSize,
    StyledStepContent,
    IconDisplay,
    DocsRow,
} from '../../V2/steps/FlightDocuments/flightDocumentsStyles'
import { logKibana } from '../../../shared/actions'
import TitleHeaderInfoBox from '../../V2/components/TitleHeaderInfoBox'
import { PageTitle } from '../../../shared/components/V2/TextElements'

/* global Raven */

class FlightDocuments extends React.Component {
    state = {
        filesList: [],
        componentState: {
            uploadErrors: [],
        },
        innerWidth: window.innerWidth,
    }

    isFaqOpen = false
    dragContainer = React.createRef()

    preventDragDrop = e => {
        if (!e.target.closest('#dd-box')) {
            // eslint-disable-next-line no-restricted-globals
            ;(e || event).preventDefault()
            e.dataTransfer.effectAllowed = 'none'
            e.dataTransfer.dropEffect = 'none'
        } else {
            e.dataTransfer.effectAllowed = 'initial'
            e.dataTransfer.dropEffect = 'initial'
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)
        const leadId = urlParams.get('leadId')
        const passengerId = urlParams.get('passengerId')

        this.setState({ leadId, passengerId })
        scrollComponentToView('pageTopNavbar', false, true)
        window.addEventListener('resize', this._onResize)
        window.addEventListener('dragenter', this.preventDragDrop)
        window.addEventListener('dragover', this.preventDragDrop)
        window.addEventListener('drop', this.preventDragDrop)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize)
        window.removeEventListener('dragenter', this.preventDragDrop)
        window.removeEventListener('dragover', this.preventDragDrop)
        window.removeEventListener('drop', this.preventDragDrop)
    }

    _onResize = () => {
        const width = window.innerWidth

        this.setState({ innerWidth: width })
    }

    fileOnDragOver = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el) {
            el.classList.add('hover')
        }
    }

    fileOnDragLeave = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el && el.classList.contains('hover')) {
            el.classList.remove('hover')
        }
    }

    handleDrop = dataTransfer => {
        let e = { target: { files: [] } }
        e.target.files = dataTransfer.files
        this.fileOnDragLeave()
        this.handleFileChange(e)
    }

    handleChange = e => {
        const { target } = e

        this.setState({ [target.name]: target.value })
    }

    handleFileChange = e => {
        const { logKibana } = this.props
        const { leadId } = this.state

        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                uploadErrors: [],
            },
        }))
        const { files } = e.target
        const fileTypeReg = new RegExp(
            '(.*?).(docx|doc|pdf|xml|bmp|jpg|png|jpeg|odt)$'
        )

        Array.prototype.forEach.call(files, (file, i) => {
            if (file.name && !fileTypeReg.test(file.name.toLowerCase())) {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        uploadErrors: [
                            ...prevState.componentState.uploadErrors,
                            {
                                name: file.name,
                                error: t`v2_claim.file.type.not.supported`,
                            },
                        ],
                    },
                }))
                logKibana(
                    `file_upload_failed_type: ${file.name}, leadId: ${leadId}`,
                    '/lead/documents',
                    'warning'
                )
            } else {
                const fileSize = (file.size / 1024 / 1024).toFixed(4)

                if (fileSize < 5) {
                    this.setState(prevState => ({
                        filesList: [...prevState.filesList, file],
                    }))
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            uploadErrors: [
                                ...prevState.componentState.uploadErrors,
                                {
                                    name: file.name,
                                    error: t`v2_claim.file.size.limit.reached`,
                                },
                            ],
                        },
                    }))
                    logKibana(
                        `file_upload_failed_size: ${fileSize}, leadId: ${leadId}`,
                        '/lead/documents',
                        'warning'
                    )
                }
            }
        })

        e.target.value = []
    }

    handleSubmit = () => {
        loadScreenOn()
        const { leadId, passengerId, filesList } = this.state
        const apiClient = getApiClient()
        const url = `/api/leads/${leadId}/passengers/${passengerId}/documents`
        const fd = new FormData()

        filesList.forEach((file, index) => {
            fd.append(`attachment[file${index + 1}]`, file)
        })

        apiClient
            .post(url, fd)
            .then(res => {
                browserHistory.push(`lead/list?leadId=${leadId}`)
            })
            .catch(err => {
                Raven.setUserContext({
                    leadId: leadId,
                    passengerId: passengerId,
                    filesLength: filesList.length
                });
                Raven.captureException("UPLOAD LEAD DOCUMENT ERROR: " + JSON.stringify(err));
                console.warn(err)
                browserHistory.push(`lead/list?leadId=${leadId}`)
            })
            .finally(() => {
                loadScreenOff()
            })
    }

    renderDocumentsList = (documents, failedDocuments) => {
        const { innerWidth } = this.state
        const handleName = name => {
            let availableLength = 25
            let lettersCount = 10
            let docName = ''

            if (innerWidth < 960) {
                if (innerWidth < 420) {
                    availableLength = 29
                } else if (innerWidth < 520) {
                    availableLength = 45
                } else if (innerWidth < 720) {
                    availableLength = 70
                    lettersCount = 20
                } else {
                    availableLength = 60
                    lettersCount = 20
                }
            } else {
                if (innerWidth >= 960 && innerWidth < 1145) {
                    availableLength = 15
                }
            }

            if (name) {
                if (name.length > availableLength) {
                    const firstPart = name.substring(0, lettersCount)
                    const last = name.substring(lettersCount)
                    const lastPart = last.substring(
                        last.length - (availableLength - 3 - lettersCount)
                    )
                    docName = `${firstPart}...${lastPart}`
                } else {
                    docName = name
                }
            }

            return docName ? docName : ''
        }

        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { size, name } = document
                const docName = handleName(name)

                return (
                    <DocumentOuter key={index} id={index}>
                        <DocumentIcon />
                        <DocumentInner>
                            <DocumentName>{docName}</DocumentName>
                            <DocumentSize>
                                {size && bytesToSize(size)}
                            </DocumentSize>
                        </DocumentInner>
                        <RemoveButtonContainer
                            done={!!document.id}
                            onClick={() => {
                                const filesList = [...this.state.filesList]
                                filesList.splice(index, 1)

                                this.setState(prevState => ({
                                    filesList,
                                }))
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            const failedDocs = (failedDocuments || []).map((doc, i) => {
                const { name } = doc
                const docName = handleName(name)

                return (
                    <DocumentOuter key={i}>
                        <FailedDocIcon />
                        <DocumentInner>
                            <DocumentName className="document-name">
                                {docName}
                            </DocumentName>
                        </DocumentInner>
                        <RemoveButtonContainer
                            onClick={() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    componentState: {
                                        ...prevState.componentState,
                                        uploadErrors: [],
                                    },
                                }))
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            return (
                <DocumentsContainer>
                    <StyledP className="mb-16">{t`v2_common.uploaded_files`}</StyledP>
                    {docs}
                    {failedDocs}
                </DocumentsContainer>
            )
        } else {
            return null
        }
    }

    _renderDocList = () => {
        const resultDocs = [
            { documentType: 'ticket', status: 'optional' },
            { documentType: 'id', status: 'optional' },
            { documentType: 'boarding_pass', status: 'required' },
        ]

        const docTypeComponents = {
            id: 'passport',
            ticket: 'ticket',
            boarding_pass: 'email',
        }

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <StyledP style={{ marginBottom: 0 }}>
                            {/* {t`skycop_lead_documents.required_documents`} */}
                        </StyledP>
                    </Col>
                </Row>
                <DocsRow>
                    {resultDocs.map((doc, i) => {
                        const Icon = docTypeComponents[doc.documentType]
                        if (Icon) {
                            return (
                                <Col key={doc.documentType}>
                                    <img
                                        src={`/images/${
                                            isMobileDevice() ? 'mobile-' : ''
                                        }${
                                            docTypeComponents[doc.documentType]
                                        }.svg`}
                                        width="48px"
                                        alt={
                                            docTypeComponents[doc.documentType]
                                        }
                                    />
                                    <span className="item-title">{t`icon_label.${
                                        docTypeComponents[doc.documentType]
                                    }`}</span>
                                </Col>
                            )
                        } else {
                            return null
                        }
                    })}
                </DocsRow>
            </React.Fragment>
        )
    }

    render() {
        const { componentState, filesList } = this.state
        const { trans } = this.props

        const documentsExist = !!filesList.length
        if (!trans) return null
        
        return (
            <PageViewContainer
                title={t`flight_documents.add_documents`}
                renderFPMobile={true}
                titleBottomPadding="22px"
            >
                <PageContent>
                    <PageTitle
                        title={t`flight_documents.add_documents`}
                    ></PageTitle>
                    <FileDragAndDrop
                        onDrop={this.handleDrop}
                        onDragOver={this.fileOnDragOver}
                        onDragLeave={this.fileOnDragLeave}
                        onDragEnd={this.fileOnDragLeave}
                    >
                        <StyledStepContent>
                            {this._renderDocList()}
                            {!!_.get(
                                componentState,
                                'uploadErrors.length',
                                0
                            ) && (
                                <Row>
                                    <Col>
                                        <StyledErrorsBox>
                                            <ul>
                                                {componentState.uploadErrors.map(
                                                    (err, i) => (
                                                        <li key={i}>
                                                            {err.name}
                                                            &nbsp;-&nbsp;
                                                            {err.error.toLowerCase()}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </StyledErrorsBox>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col
                                    md={documentsExist ? 7 : 12}
                                    lg={documentsExist ? 7 : 12}
                                >
                                    <DragDropContainer
                                        innerRef={this.dragContainer}
                                    >
                                        <FileInputLabel>
                                            <FileInput
                                                type="file"
                                                id="file"
                                                name="fileselect[]"
                                                multiple="multiple"
                                                onChange={this.handleFileChange}
                                                accept="image/*,application/msword,application/vnd.oasis.opendocument.text,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            />
                                            <MobileUploadContainer>
                                                <IconContainer>
                                                    <CameraIcon />
                                                    <MobileIconText>
                                                        {t`v2_claim.flight_documents.take_photo`}
                                                    </MobileIconText>
                                                </IconContainer>
                                                <LineBreak>
                                                    <span />
                                                    <p>{t`v2_common.label.or`}</p>
                                                    <span />
                                                </LineBreak>
                                                <IconContainer>
                                                    <FilesIcon />
                                                    <MobileIconText>
                                                        {t`v2_claim.flight_documents.select_files`}
                                                    </MobileIconText>
                                                </IconContainer>
                                            </MobileUploadContainer>
                                            <FileUploadText
                                                className="inputText"
                                                id="dd-box"
                                            >
                                                <TextContainer>
                                                    {
                                                        <IconDisplay>
                                                            <UploadIcon />
                                                        </IconDisplay>
                                                    }
                                                    <DragFile
                                                        dangerouslySetInnerHTML={{
                                                            __html: t`v2_claim.flight_documents.drag_drop`,
                                                        }}
                                                    />
                                                </TextContainer>
                                            </FileUploadText>
                                        </FileInputLabel>
                                    </DragDropContainer>
                                </Col>
                                {(!!_.get(
                                    componentState,
                                    'uploadErrors.length',
                                    0
                                ) ||
                                    documentsExist) && (
                                    <Col className="pdt-0 pdb-0" md={5} lg={5}>
                                        {this.renderDocumentsList(
                                            filesList,
                                            componentState.uploadErrors
                                        )}
                                    </Col>
                                )}
                            </Row>
                            {isMobileDevice() && (
                                <Row>
                                    <Col
                                        sm={12}
                                        md={12}
                                        xs={12}
                                        style={{ width: '100%' }}
                                    >
                                        <TitleHeaderInfoBox
                                            style={{ marginTop: 0 }}
                                            ignoreLang={true}
                                            fullWidth
                                            toggleOpen={isOpen => {
                                                this.isFaqOpen = isOpen
                                                this.forceUpdate()
                                            }}
                                            isOpen={this.isFaqOpen}
                                            header={t`FAQ.flight_documents.find_docs`}
                                            HtmlContent={() => (
                                                <React.Fragment>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t`v2_claim.flight_documents.tooltip_text`,
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                            marginBottom: 8,
                                                        }}
                                                    >
                                                        <img
                                                            src="/images/reservation-number.svg"
                                                            width="100%"
                                                            style={{
                                                                height: '130px',
                                                            }}
                                                            alt="reservation-number"
                                                        />
                                                    </div>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t`v2_claim.flight_documents.bottom_tooltip_text`,
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </StyledStepContent>
                    </FileDragAndDrop>
                    <Row>
                        <Col className="button-container">
                            <V2Button
                                disabled={!documentsExist}
                                onClick={this.handleSubmit}
                            >{t`skycop_lead_documents.submit`}</V2Button>
                        </Col>
                         <Col className="button-container">
                            <V2Button
                                type="borderBtn"
                                onClick={this.handleSubmit}
                            >{t`skycop_lead_documents.do_later`}</V2Button>
                        </Col>
                        <Col></Col>
                    </Row>
                </PageContent>
            </PageViewContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        errors: state.v2_step_five_errors.errors,
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logKibana,
            showModal,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(FlightDocuments)
