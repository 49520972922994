import React, { Component } from 'react';
import PropTypes from "prop-types";
import Datetime from 'react-datetime';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/min/locales';
import { getStorage, mapCalendarLocale } from '../../../main'

const CompactLabel = styled.label`
    color: ${({hasDanger, labelColor}) => (hasDanger ? "#d9534f" : labelColor)};
    font-weight: normal;
    display: block;
    font-size: 13px;
    margin-bottom: .2rem;
`;

const Container = styled.div`
    margin-bottom: 2.7rem;

    .form-control {
        color: ${({ theme }) => theme.colors.secondaryColor};
        padding-left: 1.7rem;
        background-color: ${({ disabled }) => disabled ? "#eceeef!important" : "#fff!important"};
        
        &:focus {
            border-color: ${({ theme }) => theme.colors.secondaryColor};
        }
    }

    .rdtOpen .rdtPicker {
        right: 0;
    }

    .rdtPicker {
        min-width: 250px;
        widtH: 100%;

      thead {
        tr {
          &:first-child {
            th {
              &:hover {
                background: ${({ theme }) => theme.colors.lightHoverColor};
              }
    
            }
          }
        }
      }
      td {
        &.rdtActive {
          background-color: ${({ theme }) => theme.colors.secondaryColor};
          color: #fff!important;
          &:hover {
            background-color: ${({ theme }) => theme.colors.secondaryColor};
            color: #fff;
          }
        }
        &.rdtNew,
        &.rdtOld {
          color: #999;
        }
        span {
          &.rdtDisabled {
            background: 0 0;
            color: #999;
            &:hover {
              background: 0 0;
              color: #999;
            }
          }
        }
        &.rdtDisabled {
          background: 0 0;
          color: #999;
          &:hover {
            background: 0 0;
            color: #999;
          }
        }
      }
      th {
        .rdtDisabled {
          background: 0 0;
          color: #999;
          &:hover {
            background: 0 0;
            color: #999;
          }
        }
      }
    }
    
    .rdtCounter {
      .rdtBtn {
        &:hover {
          background: ${({ theme }) => theme.colors.lightHoverColor};
        }
      }
    }
    td {
      &.rdtMonth,
      &.rdtYear,
      &.rdtDay {
        &:hover {
          background: ${({ theme }) => theme.colors.lightHoverColor};
        }
      }
    }
    
    .inner-form-control-datepicker {
      color: #333;
    }
`;

class Datepicker extends Component {
    state = {
        isOpen: false,
        date: "",
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick, false);
        document.addEventListener('click', this.handleCalendarBtns, false);
        document.addEventListener('touchend', this.handleOutsideClick, false);
        document.addEventListener('touchend', this.handleCalendarBtns, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
        document.removeEventListener('click', this.handleCalendarBtns, false);
        document.removeEventListener('touchend', this.handleOutsideClick, false);
        document.removeEventListener('touchend', this.handleCalendarBtns, false);
    }

    handleOutsideClick = (e) => {
        const { isOpen } = this.state;
        const { target } = e;
        const targetClassList = target.classList;

        if (!this.dateTimeWrapperRef) {
            return false;
        }
        if (target && target.type === "radio") {
            return false;
        }

        if (isOpen &&
            !this.dateTimeWrapperRef.contains(target) &&
            !targetClassList.contains('rdtSwitch') &&
            !targetClassList.contains('rdtYear') &&
            !targetClassList.contains('rdtMonth') &&
            !targetClassList.contains('rdtDay')) {
            this.setState({isOpen: false});
        }
    }

    toggleDatePicker = (e) => {
        const { target } = e;
        const { isOpen } = this.state;
        
        if (isOpen) {
            target.blur();
        }

        this.setState({isOpen: !isOpen});
    }

    onTouchEnd = (e) => {
        e.preventDefault();
        document.activeElement.blur();
        this.toggleDatePicker(e);
    }

    onChange = (date, cb) => {
        const { isOpen } = this.state;
        const { name } = this.props;
        date.locale('en');
        this.setState(moment(date));

        if(cb) {
            cb({name, value: moment(date).format("YYYY-MM-DD")});
        }

        if (isOpen) {
            this.setState({isOpen: false});
        }
    }

    handleCalendarBtns = () => {
        const { name } = this.props;
        const backBtns = document.querySelectorAll(`.${name} .rdtPicker .rdtPrev`);
        const nextBtns = document.querySelectorAll(`.${name} .rdtPicker .rdtNext`);
        const th = document.querySelectorAll(`.${name} .rdtPicker .rdtSwitch`);

        const items = [...th].map((item, i) => {
            return {
                th: item, 
                backBtn: backBtns[i], 
                nextBtn: nextBtns[i]
            } 
        });
        items.forEach(item => this.addCalendarEvents(item));
    }

    addCalendarEvents = ({th, backBtn, nextBtn}) => {
        const { startDate, endDate } = this.props;
        const splittedByDash = th.innerHTML.split('-');
        const isSplittedByDash = splittedByDash.length === 2;
        const isSplitBySpace = th.innerHTML.split(' ').length === 2;
        const spaceSplitYears = th.innerHTML.substr(th.innerHTML.length -4);
        const nowYears = startDate || moment().year();
        const minYears = nowYears - endDate;

        // Checking months tab view
        if(isSplitBySpace) {
            this.handleButtonDisable((spaceSplitYears < minYears), backBtn);
            this.handleButtonDisable((spaceSplitYears >= nowYears), nextBtn);
        } else if(isSplittedByDash) {
         // Cheking period of a year view
            this.handleButtonDisable((splittedByDash[0] < minYears), backBtn);
            this.handleButtonDisable((splittedByDash[1] >= nowYears), nextBtn);
        } else {
        // Checking year tab view
            this.handleButtonDisable((th.innerHTML <= minYears), backBtn);
            this.handleButtonDisable((th.innerHTML >= nowYears), nextBtn)
        }
    }

    handleButtonDisable = (shouldDisable, button) => {
        if (shouldDisable) {
            button.addEventListener('click', this.disableBtn);
        } else {
            button.removeEventListener('click', this.disableBtn);
        }
    }

    disableBtn = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    renderLabel() {
        const { compactLabel, label, labelColor } = this.props;

        if (!compactLabel) {
            return (
                <label style={{color: labelColor}} className="h-c1">{label}</label>
            );
        } else return (
            <CompactLabel labelColor={labelColor}>{label}</CompactLabel>
        );
    }

    renderErrorLabel() {
        const { compactLabel, errorText } = this.props;

        if (!compactLabel) {
            return (
                <div className="form-control-feedback has-danger">
                    { errorText ? errorText : undefined}
                </div>
            )
        } else return (
            <CompactLabel hasDanger={true}>
                { errorText || null}
            </CompactLabel>
        );
    }

    render() {
        const { name, placeholder, value, readOnly, locale, viewMode, timeFormat, viewDate, handleChange, classNames, ...props } = this.props
        const { isOpen, date } = this.state;

        return(
            <Container 
                className={`detail-input needsclick ${name} ${classNames}`} 
                name={name}
                disabled={readOnly}
                innerRef={ref => {
                    this.dateTimeWrapperRef = ref;
                }}>
                {this.renderLabel()}
                <Datetime
                    ref={ref => {
                        this.dateTimeRef = ref;
                    }}
                    value={(value && moment(value)) || date}
                    viewMode={viewMode || "days"}
                    viewDate={viewDate}
                    timeFormat={timeFormat || false}
                    closeOnSelect={true}
                    open={isOpen}
                    locale={moment.locale(mapCalendarLocale(locale || getStorage("lang") || "en"))}
                    className={`inner-form-control-datepicker needsclick ${props.errorText ? 'has-danger' : ''}`}
                    inputProps={{
                        name,
                        placeholder,
                        readOnly: true,
                        className: "form-control form-control--datepicker js-datepicker js-checkable bg-white needsclick data-hj-whitelist",
                        onClick: (e) => readOnly ? () => null : this.toggleDatePicker(e),
                        onTouchEnd: (e) => readOnly ? () => null : this.onTouchEnd(e),
                    }}
                    onChange={(date) => this.onChange(date, handleChange)}
                    onBlur={() => {
                        if (isOpen) {
                            this.setState({isOpen: false});
                        }
                    }}
                    {...props}
                />
                {this.renderErrorLabel()}
            </Container>
        )
    }
}

Datepicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    viewMode: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    viewDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    timeFormat: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    locale: PropTypes.string,
    isValidDate: PropTypes.func,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    errorText: PropTypes.string,
    label: PropTypes.string,
    dateFormat: PropTypes.string
}

export default Datepicker;