import React from 'react'
import styled from 'styled-components'
import CheckBox from './CheckBox'

const TermsP = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #7f8fa4;
    margin-left: 8px;

    span,
    a {
        color: #043d5d;
        text-decoration: underline;

        &:hover {
            color: #043d5d;
            filter: brightness(110%);
        }
    }
`

const TermsAndConditions = ({ text, ...props }) => (
    <CheckBox
        label={() => (
            <TermsP
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )}
        type="checkbox"
        name="termsConditions"
        {...props}
    />
)

export default TermsAndConditions
