import { V2_ACTIONS } from '../../actions/actionTypes'
import { isValidBookingNumber } from '../../../main'

const INITIAL_STATE = {
    validations: {
        reservationNumber: {
            type: 'string',
            reservationNumber_valid: true,
            errorMessages: {
                type_error: 'type_error',
                reservationNumber_valid_error:
                    'common.label.is_required.latin_numbers_only',
            },
        },
    },
    errors: {},
}

const validateInputs = (validations, payload) => {
    return Object.keys(validations).reduce((errors, validator) => {
        if (typeof payload[validator] !== validations[validator].type) {
            errors[validator] = validations[validator].errorMessages.type_error
        }

        if (
            validations[validator].reservationNumber_valid &&
            !isValidBookingNumber(payload[validator])
        ) {
            errors[validator] =
                validations[
                    validator
                ].errorMessages.reservationNumber_valid_error
        }

        return errors
    }, {})
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_STEP_FIVE_ERRORS:
            const errors = validateInputs(state.validations, action.payload)
            return {
                ...state,
                errors,
            }
        default:
    }

    return state
}
