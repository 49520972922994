import ApiClient from './client';
import envConfig from '../../../env.json';
import store from '../store';

const API_ROOT_URL = envConfig[process.env.REACT_APP_ENV || "development"].API;

class ApiClientProxy {

    apiClient = ApiClient;

    getProcessedConfig = (config, caller) => {
        const cpActions = store.getState().cp_actions;
        const safeCaller = (caller || "").trim();

        if (!safeCaller) {
            return config;
        }

        if (cpActions && cpActions.includes(safeCaller)) {
            config.baseURL = API_ROOT_URL;
        }

        return config;
    };

    get = (url, config, caller) => {
        return this.apiClient.get(url, this.getProcessedConfig(config || {}, caller));
    };

    post = (url, data, config, caller) => {
        return this.apiClient.post(url, data, this.getProcessedConfig(config || {}, caller));
    };

    put = (url, data, config, caller) => {
        return this.apiClient.put(url, data, this.getProcessedConfig(config || {}, caller));
    };

    patch = (url, data, config, caller) => {
        return this.apiClient.patch(url, data, this.getProcessedConfig(config || {}, caller));
    };

    delete = (url, config, caller) => {
        return this.apiClient.delete(url, this.getProcessedConfig(config || {}, caller));
    };

    request = (config, caller) => {
        return this.apiClient.request(this.getProcessedConfig(config || {}, caller));
    };
}

export default ApiClientProxy;
