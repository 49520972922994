import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = {
    failedEmailSent: false,
    failedDraftPost: false,
    claimDraftPosted: false,
};

export default function(state = INITIAL_STATE, action) {
    const { type } = action;

    switch (type) {
        case V2_ACTIONS.DRAFT_SUCCESS:
            return {
                ...state,
                claimDraftPosted: true,
            };
        case V2_ACTIONS.SET_DRAFT_SAVE_ERROR:
            return {
                ...state,
                failedDraftPost: true,
            };
        case V2_ACTIONS.SET_DRAFT_EMAIL_ERROR:
            return {
                ...state,
                failedEmailSent: true,
            };
        case V2_ACTIONS.CLEAR_DRAFT_ERRORS:
            return INITIAL_STATE;
        default:
    }
    return state;
}
