import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

class NotFoundPage extends Component {

    UNSAFE_componentWillMount() {
        const { location, redirect } = this.props;
        const { pathname } = location;

        if (redirect) {
            browserHistory.push(redirect);
        } else if (pathname.split('/').length > 2) {
            window.location.href = '/';
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (!this.props.redirect) {
                window.location.href = '/';
            }
        }, 3000);
    }

    render() {
        if (this.props.trans) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-xs-24 col-lg-16 h-pb60">
                            <h1 className="h-pt10 h-mb10 h-mb40-md">Sorry, page not found!</h1>
                            <h1 className="h-pt10 h-mb10 h-mb40-md">Redirecting...</h1>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(NotFoundPage);