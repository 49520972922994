import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from '../../../shared/components/V2/Modal'
import t from '../../../shared/translations'
import {
    hideModal,
    showModal,
    resetV2Claim,
} from '../../../shared/actions/v2_actions'
import { CloseButton, V2Button } from '../../../shared/components/V2/V2Buttons'
import { modalTypes } from '../../../shared/constants'
import { isProduction } from '../../../main'
import {
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-family: 'Open Sans';
    padding: 40px 32px;
    background: url('images/skycopCare/illustration.png') no-repeat fixed center;

    .select-mb-1 {
        margin-bottom: 10px;
    }

    .mid-content {
        display: flex;

        .side {
            flex: 1;
        }
    }

    .title {
        margin: 0;
        margin-left: 22px;
        margin-top: 14px;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: #1d194d;
    }

    .text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        margin: 0;
        color: #353d51;

        &.upper {
            margin-bottom: 24px;
        }

        &.bold {
            font-weight: bold;
        }
    }

    .small-text {
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 140%;
        color: #525f7f;
        margin: 0;
    }

    ${mobile`
        background: none;

        .side {
            &.flex-0 {
                flex: 0;
            }
        }

        .title {
            margin: 0;
        }

        .mid-content {
            align-items: flex-end;
            margin-bottom: 24px;
        }
    `}

    ${tablet`
        background: none;
        padding: 80px 32px 40px;

        .side {
            &.flex-0 {
                flex: 0;
            }
        }

        .title {
            margin: 0;
        }

        .mid-content {
            align-items: flex-end;
            margin-bottom: 24px;
        }
    `}
`

const Button = styled(V2Button)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 24px;
    gap: 10px;
    background: #0ddf93;
    border-radius: 24px;
    width: auto;
    margin-top: 32px;
`

class SkycopCareModal extends React.Component {
    closeModal = () => {
        const { hideModal } = this.props
        if (window && window.dataLayer) {
            window.dataLayer.push({
                event: 'SkycopCareLightboxClosed',
            })
        }
        hideModal()
    }

    render() {
        const { trans } = this.props

        if (!trans) return null

        return (
            <Modal
                keepDesktopStyles
                allowOverflow={true}
                width="640px"
                height="544px"
                maxWidth="640px"
                maxHeight="544px"
                modalType={modalTypes.skycopCareModal}
                style={{
                    borderRadius: '16px',
                    background:
                        'linear-gradient(0deg,rgba(127, 143, 164, 0.2),rgba(127, 143, 164, 0.2)),linear-gradient(0deg, #f6f5ff, #f6f5ff)               ',
                }}
            >
                <CloseButton onClick={this.closeModal} />
                <Container className="enabledTouch">
                    <h1 className="title">{t`sc_modal.title`}</h1>
                    <div className="mid-content">
                        <div className="side flex-0"></div>
                        <div className="side">
                            <p className="text upper">
                                {t`sc_modal.normal_text`}
                            </p>
                            <p className="text bold">
                                {t`sc_modal.bold_text_part_1`}
                            </p>
                            <p className="text bold">
                                {t`sc_modal.bold_text_part_2`}
                            </p>
                            <div>
                                <Button
                                    onClick={() => {
                                        if (window && window.dataLayer) {
                                            window.dataLayer.push({
                                                event:
                                                    'SkycopCareLightboxButton',
                                            })
                                        }
                                        if (isProduction()) {
                                            window.open(
                                                'https://secure.skycop.com/skycop-care?type=promo',
                                                '_blank'
                                            )
                                            this.closeModal()
                                            return
                                        }

                                        window.open(
                                            '/skycop-care?type=promo',
                                            '_blank'
                                        )
                                        this.closeModal()
                                    }}
                                >
                                    {t`sc_modal.button`}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <p
                        className="small-text"
                        dangerouslySetInnerHTML={{
                            __html: t`sc_modal.text_under`,
                        }}
                    ></p>
                </Container>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        error: state.error.errorMessage,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ hideModal, showModal, resetV2Claim }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SkycopCareModal)
