import styled from 'styled-components'
import {
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import { Col } from '../../../shared/components/V2/Layout'

export const MainContainer = styled.div`
    max-width: 992px;
    width: 100%;
    margin: 40px auto 80px;

    .h-mb-8 {
        margin-bottom: 8px;
    }

    .h-mt-4 {
        margin-top: 4px;
    }

    h3 {
        font-family: 'Open Sans', sans-serif;
    }

    ${tablet`
        margin-bottom: 100px;
    `}

    ${mobile`
        margin-bottom: 100px;
    `}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

export const CountryInputCol = styled(Col)`
    div.form-group {
        margin-bottom: 0;
    }
`

export const CurrencyInputCol = styled(Col)`
  div.form-group {
    margin-bottom: 0;
  }
`

export const HeaderContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-bottom: 36px;

    h1 {
        text-align: center;
        font-size: 28px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #353d51;

        span {
            font-weight: bold;
        }
    }
`
export const TermsContainer = styled.div`
    display: flex;
    justify-content: left;
`

export const SubscribeContainer = styled.div`
  display: flex;
  justify-content: left;
`
export const AgreementContainer = styled.div`
    border: solid 1px #d7d7e0;
    padding: 66px;
    color: #353d51;
    float: left;
    width: 100%;

    table {
        table-layout: fixed;

        tr {
            td {
                h2 {
                    font-size: 16px;
                    text-align: center;
                    word-break: break-word;
                }
            }
        }
    }

    td {
        padding: 0 25px;
        vertical-align: top;
        text-align: justify;
        page-break-inside: auto;

        hr {
            width: 100% !important;

            & + div {
                ${mobile`
                    margin-left: 0px!important;
                `}
            }
        }
    }

    overflow: auto;

    ${tablet`
       padding: 0;
    `}

    ${mobile`
        padding: 16px 0;
        font-size: 8px;
        overflow: hidden;

        tbody {
            tr {
                td {
                    font-size: 8px!important;
                    padding: 0 8px;

                    div {
                        font-size: 8px!important;
                    }

                    h2 {
                        font-size: 2rem;
                        word-break: break-word;
                    }

                    img {
                        width: 127px!important;
                        margin-left: unset!important;
                    }
                }
            }
        }
    `}
`
