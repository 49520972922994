import React from 'react'
import styled, { keyframes } from 'styled-components'
import { NextIcon, PrevIcon } from '../../../shared/components/V2/V2Icons'
import Swipe from 'react-easy-swipe'

const fade = keyframes`
  from {
    opacity: 0.4;
  }

  to {
    opacity:1;
  }
`

const Next = styled.a`
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    transition: 0.6s ease;
    user-select: none;
    right: 0;
`

const Prev = styled.a`
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    transition: 0.6s ease;
    user-select: none;
`

const SlideshowContainer = styled.div`
    /* max-width: 1000px; */
    position: relative;
    margin: auto;
`

const MySlide = styled.div`
    display: ${props => (props.isActive ? 'flex' : 'none')};
    animation: ${fade} 1.5s;
    // display: flex;
    justify-content: center;
    width: 100%;
`

const DotContainer = styled.div`
    text-align: center;
`

const Dot = styled.span`
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 15px 10px 0px 0px;
    background-color: ${props => (props.isActive ? '#493FC2' : '#525F7F66')};
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    &:hover {
        background-color: #493fc2;
    }
`

class MobileCarousel extends React.Component {
    state = {
        counter: 0,
    }

    handleNext = () => {
        const { counter } = this.state
        const { childrenArray } = this.props

        if (counter >= childrenArray.length - 1) {
            this.setState({ counter: 0 })
        } else {
            this.setState({ counter: counter + 1 })
        }
    }

    handlePrev = () => {
        const { counter } = this.state
        const { childrenArray } = this.props

        if (counter <= 0) {
            this.setState({ counter: childrenArray.length - 1 })
        } else {
            this.setState({ counter: counter - 1 })
        }
    }

    handleDotClick = index => {
        this.setState({ counter: index })
    }

    render() {
        const { counter } = this.state
        const { childrenArray } = this.props

        return (
            <div>
                <Swipe
                    style={{ minHeight: '100%' }}
                    tolerance={60}
                    onSwipeRight={() => this.handleNext()}
                    onSwipeLeft={() => this.handlePrev()}
                >
                    <SlideshowContainer>
                        {childrenArray.map((e, i) => (
                            <MySlide key={i} isActive={i === counter}>
                                {e}
                            </MySlide>
                        ))}
                        <Prev onClick={this.handlePrev}>
                            <PrevIcon />
                        </Prev>
                        <Next onClick={this.handleNext}>
                            <NextIcon />
                        </Next>
                    </SlideshowContainer>

                    <DotContainer>
                        {childrenArray.map((e, i) => (
                            <Dot
                                isActive={i === counter}
                                key={i}
                                onClick={() => this.handleDotClick(i)}
                            ></Dot>
                        ))}
                    </DotContainer>
                </Swipe>
            </div>
        )
    }
}

export default MobileCarousel
