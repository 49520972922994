import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import t from '../../../translations'

const GiftIcon = () => (
    <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="54"
            height="54"
            rx="27"
            fill="url(#paint0_linear_297_949)"
        />
        <path
            d="M24.25 14.0835C22.386 14.0835 20.875 15.5386 20.875 17.3335C20.875 17.7134 20.9427 18.078 21.067 18.4168H16.375C14.511 18.4168 13 19.8719 13 21.6668C13 23.4618 14.511 24.9168 16.375 24.9168L16.375 34.6668C16.375 36.4618 17.886 37.9168 19.75 37.9168H26.5H33.25C35.114 37.9168 36.625 36.4618 36.625 34.6668V24.9168C38.489 24.9168 40 23.4618 40 21.6668C40 19.8719 38.489 18.4168 36.625 18.4168H31.933C32.0573 18.078 32.125 17.7134 32.125 17.3335C32.125 15.5386 30.614 14.0835 28.75 14.0835C27.8856 14.0835 27.0971 14.3964 26.5 14.911C25.9029 14.3964 25.1144 14.0835 24.25 14.0835ZM27.625 35.7502V24.9168H34.375V34.6668C34.375 35.2651 33.8713 35.7502 33.25 35.7502H27.625ZM25.375 24.9168V35.7502H19.75C19.1287 35.7502 18.625 35.2651 18.625 34.6668V24.9168H25.375ZM27.625 22.7502V20.5835H36.625C37.2463 20.5835 37.75 21.0685 37.75 21.6668C37.75 22.2651 37.2463 22.7502 36.625 22.7502H27.625ZM25.375 20.5835V22.7502H16.375C15.7537 22.7502 15.25 22.2651 15.25 21.6668C15.25 21.0685 15.7537 20.5835 16.375 20.5835H25.375ZM27.625 18.4168V17.3335C27.625 16.7352 28.1287 16.2502 28.75 16.2502C29.3713 16.2502 29.875 16.7352 29.875 17.3335C29.875 17.9318 29.3713 18.4168 28.75 18.4168H27.625ZM25.375 18.4168H24.25C23.6287 18.4168 23.125 17.9318 23.125 17.3335C23.125 16.7352 23.6287 16.2502 24.25 16.2502C24.8713 16.2502 25.375 16.7352 25.375 17.3335V18.4168Z"
            fill="#F6FAFA"
        />
        <defs>
            <linearGradient
                id="paint0_linear_297_949"
                x1="27"
                y1="0"
                x2="27"
                y2="54"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#61DDA3" />
                <stop offset="0.671875" stopColor="#32B86A" />
            </linearGradient>
        </defs>
    </svg>
)

const Box = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 22px;
    padding: 14px;
    background: linear-gradient(
            0deg,
            rgba(73, 63, 194, 0.06) 0%,
            rgba(73, 63, 194, 0.06) 100%
        ),
        #fff;
    &:before {
        padding: 2px;
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 1.25rem;
        background: linear-gradient(
            170deg,
            rgb(115, 222, 143, 1),
            rgb(136, 126, 255, 1)
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
    & .promo-container {
        display: flex;
        padding-bottom: 16px;

        & .icon-container {
            display: flex;
            align-items: center;
        }

        & .text-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 0 16px;

            p {
                width: 100%;
                margin: 0;
                color: #525f7f;
                font-family: Open Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;

                & span {
                color: #35b56a;
                font-style: normal;
                font-weight: 700;
            }
            }
        }
    }

    & .info-text-container {
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 16px;
        padding-bottom: 8px;
        p {
            margin: 0;
            color: rgba(82, 95, 127, 0.7);
            font-family: Open Sans;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
        }

        &:before {
            content: '';
            width: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(
                to right,
                black 33%,
                rgba(255, 255, 255, 0) 0%
            );
            background-position: top;
            background-size: 5px 1px;
            background-repeat: repeat-x;
        }
    }
`

const PromoMobileBox = props => {
    return (
        <Box>
            <div className="promo-container">
                <div className="icon-container">
                    <GiftIcon />
                </div>
                <div className="text-container">
                <p>{t`skycop_care.promo_box.text_1`}<span> {t`skycop_care.promo_box.text_2`}</span></p>
                </div>
            </div>
            <div className="info-text-container">
                <p>{t`skycop_care.promo_box.info_text`}</p>
            </div>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        lang: state.language,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoMobileBox)
