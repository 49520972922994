import React from 'react'
import styled from 'styled-components'

const BoxHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 18px 18px 10px 18px;
    width: 100%;
    position: relative;

    h3 {
        flex: 1;
        color: #353d51;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
`

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    height: 100%;

    p {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        color: #7f8fa4;
        margin-bottom: 8px;
    }

    .mb-24 {
        margin-bottom: 24px;
    }

    .bold {
        font-weight: 600;
        color: #353d51;
    }
`

const BoxLine = styled.div`
    position: relative;
    height: 16px;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border: solid 1px;
        border-color: ${({ active }) => (active ? '#32b86a' : '#cbd3df')};
        border-radius: 50%;
        z-index: 10;
        background: #fff;
    }

    &:before {
        border-bottom-color: transparent;
        border-left-color: transparent;
        left: -8px;
        transform: rotate(45deg);
    }

    .dashed-line {
        position: absolute;
        z-index: 9;
        top: 8px;
        width: 100%;
        margin: 0;
        border-color: ${({ active }) => (active ? '#32b86a' : '#cbd3df')};
    }
`

const IconContainer = styled.div`
    margin-right: 12px;
`

const MainContainer = styled.div`
    border-radius: 8px;
    border: solid 1px;
    border-color: ${({ active }) => (active ? '#32b86a' : '#cbd3df')};

    & .header {
        display: flex;
    }

    &.danger {
        border-color: #f16464;

        ${BoxLine} {
            &:before {
                border-color: #f16464;
                border-bottom-color: transparent;
                border-left-color: transparent;
                left: -8px;
                transform: rotate(45deg);
            }
        }
    }
`

export const Card = ({
    title,
    icon,
    className,
    children,
    headerElement,
    id,
    menu,
    active,
    ...props
}) => (
    <MainContainer className={className} id={id} {...props} active={active}>
        <div className="header">
            <BoxHeader>
                {icon && <IconContainer>{icon()}</IconContainer>}
                <h3>{title}</h3>
                {menu && menu()}
            </BoxHeader>
            {headerElement && headerElement}
        </div>
        <BoxLine active={active}>
            <hr className="dashed-line" />
        </BoxLine>
        <BoxContent>{children}</BoxContent>
    </MainContainer>
)

const SectionContainer = styled.div`
    color: ${({ active }) => (active ? '#32b86a' : '#525F7F')};
    border-bottom: 1px solid;
    border-color: #cbd3df;
    margin-bottom: 40px;

    p {
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`

export const StatusSection = ({ active, text }) => {
    return (
        <SectionContainer active={active}>
            <p>{text}</p>
        </SectionContainer>
    )
}
