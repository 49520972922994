import React from 'react'
import styled from 'styled-components'

const OptionsContainer = styled.div`
    font-family: Noto Sans;
    display: flex;
    padding: 0px 0px;
    position: relative;
    padding-left: 16px;
    padding-right: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 100%;
    em {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.secondaryColor};
        font-style: normal;
    }

    .select-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        top: calc(50% + 0px);
        left: 0px;
        transform: translateY(-50%);
        z-index: 1;
    }
    &:hover {
        background: #ecf7f1;
        border-radius: 16px;
        p {
            background: #ffffff;
        }
    }

    display: flex;
    justify-content: center;
    align-items: center;
`

const OptionsHeader = styled.div`
    line-height: 1.2 !important;
    margin-bottom: 5px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
`

const OptionsElement = styled.div`
    font-size: 1.3rem !important;
    line-height: 1 !important;
    color: #999;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
`

const OptionNameWrapper = styled.div`
    flex-grow: 1;
    display: table;
`

const AirportIataWrapper = styled.p`
    margin-top: 10px;
    display: flex;
    padding: 10px;
    border-radius: 24px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    color: #333333;
    align-items: center;
    text-align: center;

    background: #fafafa;

    text-align: center;
`

const NewSelectFieldOptions = ({
    optionsTopLabel,
    optionsBottomLabel,
    title,
    iata,
}) => (
    <OptionsContainer>
        <OptionNameWrapper>
            <OptionsHeader dangerouslySetInnerHTML={{ __html: `${title}` }} />
            <OptionsElement
                dangerouslySetInnerHTML={{
                    __html: `${optionsBottomLabel}`,
                }}
            />
        </OptionNameWrapper>
        <AirportIataWrapper>{iata}</AirportIataWrapper>
    </OptionsContainer>
)

export default NewSelectFieldOptions
