import React from "react";
import styled from "styled-components";
import { InfoIcon } from "../../../shared/components/V2/V2Icons";
import { tablet, desktop } from "../../../shared/components/helpers/styled_queries";

const Container = styled.div`
    svg {
        min-width: 24px;
    }

    background-color: rgba(82, 150, 255, 0.08);
    border-radius: 8px;
    position: relative;
    padding: 16px;
    margin-bottom: 16px;

    ${tablet`
        margin: unset;
        margin-bottom: 16px;
    `}

    ${desktop`
        margin: unset;
        margin-bottom: 16px;
    `}
`;

const HeaderContainer = styled.div`
    display: flex;

    h1 {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-stretch: normal;
        font-weight: 600;
        line-height: 1.38;
        letter-spacing: normal;
        color: #7f8fa4;
        margin: 0;
        margin-left: 8px;
        padding: 0;
        padding-right: 12px;
        text-align: left;
        
        ${tablet`
            margin-left: 12px;
            padding-right: 20px;
            `}

        ${desktop`
            margin-left: 12px;
            padding-right: 30px;
        `}
    }
`

const InfoBox = ({ title, onClick, children, ...rest }) => (
    <Container {...rest}>
        <HeaderContainer>
            <InfoIcon />
            <h1>
                {children}
            </h1>
        </HeaderContainer>
    </Container>
);

export default InfoBox;
