import { CLAIM_HISTORY } from '../actions/actionTypes';


export default function (state = null, action) {
    switch (action.type) {
        case CLAIM_HISTORY:
            return action.payload;
        default:
    }
    return state;
};