import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import t from '../../../../shared/translations'
import {
    getApiClient,
    getStorage,
    formatDate,
    loadScreenOff,
    loadScreenOn,
} from '../../../../main'
import {
    CloseIcon,
    CompletedStatus,
} from '../../../../shared/components/V2/StatusIcons'
import { Members, Member } from '../../../../shared/components/V2/V2Icons'
import {
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'
import { PlaneIcon1 } from '../../../../shared/components/svgIcons'
import { ErrorMessage } from './ErrorMessage'
import { modalTypes } from '../../../../shared/constants'
import { showModal } from '../../../../shared/actions/v2_actions'
import CancelListSmartDelayModal from './CancelListSmartDelayModal'
import { hideModal } from '../../../../shared/actions/v2_actions'

const Wrapper = styled.div`
    width: 100%;
    margin-left: 12px;
    margin-top: 20px;

    ${mobile`
font-size: 26px;
margin-left: 18px;
width: unset;
`}

    ${tablet`
font-size: 26px;
margin-left: unset;
`}
`

const Title = styled.div`
    color: #333333;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 30px;
`

const Container = styled.div`
    margin-bottom: 12px;
    border: 1px solid #525f7f;
    background: #f6fafa;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    & .status-block {
        display: flex;
        background-color: #35b56a;
        padding: 18px 24px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        box-sizing: border-box;
        border: #525f7f;
        border-right: none;

        ${mobile`
            padding: 4px;
        `}

        & .status-block-icon {
            display: flex;
            align-items: center;

            & svg {
                ${mobile`
                    display: none;
                `}
            }
        }
    }

    & .content-block {
        display: flex;
        justify-content: space-between;

        flex: 1;
        padding: 22px 12px 22px 16px;

        & .contents-block-info {
            height: 100%;
            justify-content: center;
            align-items: center;
            display: flex;

            ${tablet`
                display: ${({ status }) =>
                    status === 'claimable' ? 'flex' : 'none'};
                justify-content: flex-end;
                margin-right: -44px;
            `}

            ${mobile`
                 display: ${({ status }) =>
                     status === 'claimable' ? 'flex' : 'none'};
                justify-content: flex-end;
                margin-right: -44px;
            `}
        }

        & .content-block-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & .flights {
                display: flex;
                flex-direction: row;

                p {
                    color: #353d51;
                    font-family: Open Sans;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    margin: 0;

                    ${tablet`
                        flex: 1;
                    `}

                    ${mobile`
                        flex: 1;
                    `}
                }

                svg {
                    flex-shrink: 0;
                    margin: 0 12px;
                }

                ${tablet`
                    margin-bottom: 8px;
                    
                `}

                ${mobile`
                    margin-bottom: 8px;
                `}
            }

            & .flight-info {
                color: #7f8fa4;
                font-family: Open Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                p {
                    margin: 0;
                }
            }
        }
        ${tablet`
            flex-direction: column;
        `}

        ${mobile`
            flex-direction: column;
        `}
    }

    & .close-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 30px;

        & .contents-block-info-mobile {
            display: none;
            height: 100%;
            justify-content: center;
            align-items: center;
            padding: 4px;
            position: relative;

            ${tablet`
                width: unset;
                height: unset;
                display: ${({ status }) =>
                    status === 'claimable' ? 'none' : 'flex'};
                justify-content: flex-end;
                margin-bottom: 22px;
            `}

            ${mobile`
                width: unset;
                height: unset;
                display: ${({ status }) =>
                    status === 'claimable' ? 'none' : 'flex'};
                justify-content: flex-end;
                margin-bottom: 22px;
            `}
        }

        ${tablet`
            align-items: flex-start;
            margin-top: 16px;
            margin-right: 16px;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        `}

        ${mobile`
            align-items: flex-start;
            margin-top: 16px;
            margin-right: 16px;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        `}

        & .icon {
            cursor: pointer;
            border-radius: 100%;
            display: flex;
            align-items: center;
            flex-shrink: 0;
        }
    }
`

const MembersWrapper = styled.span`
    margin-left: 10px;
    font-family: 'Open Sans';
    font-size: 12px;
    color: #353d51;
    font-weight: 400;
`

const ErrorWrapper = styled.div`
    margin-top: 8px;
    margin-bottom: 16px;
`

class RegisteredFlights extends React.Component {
    state = {
        registeredFlights: [],
        error: {},
    }

    componentDidMount() {
        loadScreenOn()
        this.fetchSmartDelayList()
    }

    fetchSmartDelayList = () => {
        const authString = `Bearer ${getStorage('token')}`

        const url = 'api/account/registered-flights'
        const apiClient = getApiClient()

        apiClient
            .get(url, { headers: { Authorization: authString } })
            .then(({ data }) => {
                this.setState({ registeredFlights: data })
            })
            .catch(error => console.error(error))
            .finally(() => loadScreenOff())
    }

    handleSmartDelayDelete = id => {
        const { hideModal } = this.props
        loadScreenOn()
        const authString = `Bearer ${getStorage('token')}`
        this.setState({ error: {} })
        const apiClient = getApiClient()
        const url = 'api/account/smart-delay-cancel-flights'

        apiClient
            .post(url, [{ flightId: id }], {
                headers: { Authorization: authString },
            })
            .then(() => {
                this.fetchSmartDelayList()
            })
            .catch(error => {
                console.error(error)
                this.setState({ error: { id } })
            })
            .finally(() => {
                loadScreenOff()
                hideModal()
            })
    }

    render() {
        const { error, registeredFlights } = this.state
        const { trans, showModal } = this.props

        if (!trans) {
            return null
        }

        return (
            <>
                <Wrapper>
                    {registeredFlights.length !== 0 && (
                        <Title>{t`smart_delay_registered_flights_title`}</Title>
                    )}
                    {registeredFlights.map(flight => (
                        <>
                            <Container key={flight.id}>
                                <div className="status-block">
                                    <div className="status-block-icon">
                                        <CompletedStatus />
                                    </div>
                                </div>
                                <div className="content-block">
                                    <div className="content-block-content">
                                        <div className="flights">
                                            <p>{`${flight.departure_airport_name} (${flight.departure_airport_code})`}</p>
                                            <PlaneIcon1
                                                fill="#a4abbd"
                                                height="100%"
                                                width={16}
                                            />
                                            <p>{`${flight.arrival_airport_name} (${flight.arrival_airport_code})`}</p>
                                        </div>
                                        <div className="flight-info">
                                            <p>
                                                {formatDate(
                                                    flight.departure_datetime_utc
                                                )}
                                                , {flight.flight_code}
                                            </p>
                                        </div>
                                        <div>
                                            {flight.additional_passengers
                                                .length === 0 ? (
                                                <Member />
                                            ) : (
                                                <Members />
                                            )}
                                            <MembersWrapper>
                                                {`${flight.main_passenger.first_name} ${flight.main_passenger.last_name}`}
                                                {!!flight.additional_passengers
                                                    .length &&
                                                    Object.keys(
                                                        flight.additional_passengers
                                                    ).map(
                                                        key =>
                                                            `, ${flight.additional_passengers[key].first_name} ${flight.additional_passengers[key].last_name}`
                                                    )}
                                            </MembersWrapper>
                                        </div>
                                    </div>
                                </div>

                                <div className="close-block">
                                    <div
                                        className="icon"
                                        onClick={() =>
                                            showModal(
                                                modalTypes.smartDelayCancelListRegistration,
                                                {
                                                    cancelId: flight.id,
                                                }
                                            )
                                        }
                                    >
                                        <CloseIcon />
                                    </div>
                                </div>
                            </Container>
                            {error.id && error.id === flight.id && (
                                <ErrorWrapper>
                                    <ErrorMessage
                                        errorText={t`smart_delay_delete_flight_error`}
                                    />
                                </ErrorWrapper>
                            )}
                        </>
                    ))}
                </Wrapper>
                <CancelListSmartDelayModal
                    handleDelete={this.handleSmartDelayDelete}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showModal, hideModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(RegisteredFlights)
