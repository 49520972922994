import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case V2_ACTIONS.SET_PAYOUT_DETAILS:
            return { ...payload };
        case V2_ACTIONS.CLEAR_PAYOUT_DETAILS:
            return INITIAL_STATE;
        default:
    }
    return state;
}
