import React from 'react';
import { connect } from 'react-redux';
import t from '../../shared/translations';
import { PlaneIcon } from './svgIcons';
import styled from "styled-components";
import { PrimaryText } from './styledComponents';

const StyledPlaneIcon = styled(PlaneIcon)`
    width: 118px;
    height: 62px;
    transform: translate(-100vw,0);
    animation: plane 6s ease infinite;
`;

const LoaderContainer = styled.div`
    height: 100%;
`;

const Loader = ({ trans, children, loadingTextState }) => {
    const setText = () => {
        switch (loadingTextState) {
            case "1":
                return t`loader.loading_files`;
            case "2":
                return  t`loader.slow_connection`;
            default: 
                return t`loader.please_wait`;
        }
    }

    return (
        <LoaderContainer>
            {children}
            <div className="loader-text">
                <div className="clounds clounds--1"/>
                <div className="clounds clounds--2"/>
                <div className="clounds clounds--3"/>
                <p>
                    {
                        trans &&
                        <span>
                            <StyledPlaneIcon/>
                            <br/>
                            {setText()}
                            <br/>
                            <PrimaryText fontWeight="bold">{t`loader.processing_your_data`}</PrimaryText>
                        </span>
                    }
                </p>
            </div>
        </LoaderContainer>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        lang: state.language,
        trans: state.translations,
        loadingTextState: state.loading_text,
    }
}

export default connect(mapStateToProps)(Loader);