import React, { Component } from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { loginPageShow, loginPageHide } from "../../../main";
import t from "../../translations";
import { paths } from "../../constants";
import { PageWrapper, Title, ButtonContainer, Text } from "../../../views/V2/steps/common";
import { V2Button } from "../V2/V2Buttons";
import { HappyIcon } from "../V2/V2Icons";

class AgreementAlreadySigned extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCentered: true,
        };
    }

    componentDidMount() {
        loginPageShow(true);
        window.addEventListener("resize", this._resize);
        this.props.setParentState({
            isCentered: true,
            hideNav: true,
        });
    }

    componentWillUnmount() {
        loginPageHide();
        window.removeEventListener("resize", this._resize);
        this.props.setParentState({
            isCentered: false,
            hideNav: false,
        });
    }

    _resize = () => {
        if (this.state.isCentered && window.innerWidth < 961) {
            this.props.setParentState({
                isCentered: false,
            });
            this.setState({
                isCentered: false,
            });
        } else if (!this.state.isCentered && window.innerWidth >= 960) {
            this.props.setParentState({
                isCentered: true,
            });
            this.setState({
                isCentered: true,
            });
        }
    }

    render() {
        const { trans } = this.props;

        if (!trans) {
            return null;
        }

        return (
            <PageWrapper>
                <Helmet>
                    <title>{`Skycop - ${t`agreement.signed.title`}`}</title>
                </Helmet>
                <HappyIcon />
                <Title>{t`agreement.signed.title`}</Title>
                <Text>{t`agreement.signed.text`}</Text>
                <ButtonContainer>
                    <V2Button onClick={() => { browserHistory.push(paths.CLAIMS); }}>
                        {t`signature_form.button.close`}
                    </V2Button>
                </ButtonContainer>
            </PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        isAuthenticated: state.auth.authenticated,
        duplicate: state.duplicate,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AgreementAlreadySigned);
