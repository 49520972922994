import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import styled from "styled-components";
import _get from "lodash/get";
import {
    showModal,
    resetPassword,
    clearAuthErrors,
    hideModal,
} from "../../../shared/actions/v2_actions";
import Modal from "../../../shared/components/V2/Modal";
import {CloseButton} from "../../../shared/components/V2/V2Buttons";
import InputField from "../../../shared/components/V2/InputField";
import t from "../../../shared/translations";
import {isValidEmail} from "../../../main";
import {V2CheckBubble} from "../../../shared/components/V2/V2Icons";
import {Col} from "../../../shared/components/V2/Layout";
import {modalTypes, paths} from "../../../shared/constants";
import { browserHistory } from 'react-router'

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #353d51;
  text-align: center;

  padding-top: 16px;
  padding-bottom: 25px;
`;

const Text = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #7f8fa4;

  span {
    font-weight: bold;
    color: #32b86a;
    cursor: pointer;
  }
`;

const DesktopBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
  border: 0;
  min-width: 200px;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  padding: 10px 30px;
  text-align: center;
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  ${({disabled, theme}) =>
          disabled
                  ? "background-color: #f3f5f7;"
                  : theme.colors.actionButton
                          ? `background-color: ${theme.colors.actionButton};`
                          : `background-image: linear-gradient(to top, ${
                                  theme.colors.primaryColor
                          }, ${theme.colors.quaternaryColor})};`}
  color: ${({disabled}) => (disabled ? "#c7cdd4" : "#ffffff")};
  outline: 0 none;
  font-size: 14px;
  pointer-events: ${({disabled}) => (disabled ? "none" : "auto")};

  div {
    display: flex;
    align-items: center;
  }

  &:hover {
    filter: brightness(105%);
  }

  &:focus {
    outline: 0 none;
  }
`;

const SuccessContainer = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
`;

class ForgotPasswordModal extends React.Component {
    state = {
        email: "",
        successs: false,
        disabled: true,
    };

    resetState = () => {
        this.setState({
            email: "",
            successs: false,
            disabled: true,
        });
    };

    onChange = (e) => {
        const {target} = e;

        if (isValidEmail(target.value)) {
            this.setState({
                [target.name]: [target.value],
                disabled: false,
            });
        } else {
            this.setState({
                [target.name]: [target.value],
                disabled: true,
            });
        }
    };

    handleReset = async () => {
        const {resetPassword} = this.props;
        const {email, disabled} = this.state;
        if (!disabled) {
            await this.setState({disabled: true});
            resetPassword(email).then((res) => {
                const msg = _get(res, "message", "");
                if (msg === "email.send") {
                    this.setState({successs: "reset_pass.success.description"});
                }
                this.setState({disabled: false});
            }).catch(() => {
                this.setState({disabled: false});
            });
        }

    };

    closeModal = () => {
        const {hideModal, clearAuthErrors} = this.props;
        const currLocation = _get(window, "location.pathname", "");
        hideModal();

        if (currLocation !== paths.V2.LOGIN) {
            browserHistory.push(paths.V2.LOGIN);
        }

        clearAuthErrors();
        this.resetState();
    };

    render() {
        const {email, successs, disabled} = this.state;
        const {
            error,
            trans,
            unavailableService,
            clearAuthErrors,
        } = this.props;
        const resetError = _get(error, "errorMessage", "");

        if (!trans) return null;

        return (
            <Modal
                width="476px"
                height="auto"
                modalType={modalTypes.forgotPasswordModal}
                onEscClick={this.closeModal}
                onEnterClick={this.handleReset}
                handleClose={clearAuthErrors}
            >
                <Container>
                    <CloseButton onClick={this.closeModal}/>
                    {successs && (
                        <IconContainer>
                            <V2CheckBubble width="56" height="56"/>
                        </IconContainer>
                    )}
                    <Title>{t`reset_pass.title.reset_password`}</Title>
                    {successs ? (
                        <SuccessContainer>
                            <Text>
                                {t`${successs}`}
                                &nbsp;
                                <span onClick={this.closeModal}>
                                    {t`reset_pass.success.description_link`}
                                </span>
                            </Text>
                        </SuccessContainer>
                    ) : unavailableService ? (
                        <Text>{t`v2_claim.unavailable_service`}</Text>
                    ) : (
                        <React.Fragment>
                            <InnerContainer>
                                <Text>{t`v2_claim.password_reset.text`}</Text>
                                <InputField
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    label={t`reset_pass.description`}
                                    placeholder={t`common.label.is_required.email`}
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={this.onChange}
                                    errorText={resetError && t`${resetError}`}
                                />
                            </InnerContainer>
                            <Col>
                                <DesktopBtn
                                    disabled={disabled}
                                    onClick={this.handleReset}
                                >
                                    {t`reset_pass.button.send_new_pass`}
                                </DesktopBtn>
                            </Col>
                        </React.Fragment>
                    )}
                </Container>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        error: state.error,
        unavailableService: state.unavailableService,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            showModal,
            hideModal,
            resetPassword,
            clearAuthErrors,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordModal);
