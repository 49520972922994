import { SUCCESS_MESSAGE, RESET_MESSAGE } from '../actions/actionTypes';

const INITIAL_STATE = { message: null };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SUCCESS_MESSAGE:
            return { state, message: action.payload, level: SUCCESS_MESSAGE };
        case RESET_MESSAGE:
            return { state, message: null };
        default:
    }

    return state;
}
