import React from "react";
import styled from "styled-components";
import CircularProgressbar from "react-circular-progressbar";

const Circle = styled(CircularProgressbar)`
    &.CircularProgressbar {
        width: 100%;

        .CircularProgressbar-path {
            stroke: ${({strokeColor}) => strokeColor ? strokeColor : "#353d51"};

            stroke-linecap: round;
            transition: stroke-dashoffset 0.5s ease 0s;
        }

        .CircularProgressbar-trail {
            stroke: ${({backgroundColor}) => backgroundColor ? backgroundColor : "#dfe3e9"};
        }

        .CircularProgressbar-text {
            fill: ${({strokeColor}) => strokeColor ? strokeColor : "#353d51"};
            font-size: 20px;
            dominant-baseline: middle;
            text-anchor: middle;
        }

        .CircularProgressbar-background {
            fill: ${({backgroundColor}) => backgroundColor ? backgroundColor : "#dfe3e9"};
        }
    }
`;

const CircleProgressBar = ({
    strokeColor,
    backgroundColor,
    percentage,
    ...props
}) => {
    return (
        <Circle
            strokeColor={strokeColor}
            backgroundColor={backgroundColor}
            percentage={percentage}
            {...props}
        />
    );
};

export default CircleProgressBar;
