import { V2_ACTIONS } from "../../actions/actionTypes";
import { isValidEmail, isValidLatinChars, isValidSpecialChars } from "../../../main";

const INITIAL_STATE = {
    validations: {
        user: {
            name: {
                type: "string",
                required: true,
                validLatin: true,
                minLength: 2,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.name",
                    validLatin_error: "common.label.is_required.latin_only",
                    min_error: `min.length.error.text`,
                },
            },
            surname: {
                type: "string",
                required: true,
                validLatin: true,
                minLength: 2,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.surname",
                    validLatin_error: "common.label.is_required.latin_only",
                    min_error: `min.length.error.text`,
                },
            },
            birthdate: {
                type: "string",
                required: true,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.birthdate",
                },
            },
            email: {
                type: "string",
                required: true,
                emailValid: true,
                minLength: 2,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.email",
                    emailValid_error: "common.label.email_invalid",
                    min_error: `min.length.error.text`,
                },
            },
            address: {
                type: "string",
                required: true,
                validSymbols: true,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.address",
                    validSymbols_error: "common.label.is_required.special_symbols"
                },
            },
            city: {
                type: "string",
                required: true,
                validSymbols: true,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.city",
                    validSymbols_error: "common.label.is_required.special_symbols"
                },
            },
            country: {
                type: "string",
                required: true,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.country",
                },
            },
            phone: {
                type: "string",
                required: true,
                minLength: 5,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "common.label.is_required.phone",
                    min_error: `min.length.error.text`,
                },
            },
            locale: {
                type: "string",
                required: true,
                errorMessages: {
                    type_error: "type_error",
                    required_error: "v2_common.label.is_required.language",
                },
            }
        }
    },
    errors: {},
};

const validateUserInputs = (validations, payload, checkRequired = true) => {
    let errors = {};

        const { password, oldPass, newPass, newPassRepeat} = payload;

        if (password && newPass !== "" && oldPass.trim() === "") {
            errors.oldPass = `common.label.is_required.password`;
        }
        if (newPass && newPass !== newPassRepeat) {
            errors.newPass = "common.error.passwords_dont_match"
            errors.newPassRepeat = "common.error.passwords_dont_match"
        }
        if (newPass && newPass.length < 8 && newPass === newPassRepeat) {
            errors.newPass = `common.label.too_short.password`;
            errors.newPassRepeat = `common.label.too_short.password`;
        }

    return Object.keys(validations).reduce((errors, validator) => {

        if (typeof payload[validator] !== validations[validator].type) {
            errors[validator] = validations[validator].errorMessages.type_error;
        }

        if (validations[validator].minLength && payload[validator].length < validations[validator].minLength){
            errors[validator] = validations[validator].errorMessages.min_error;
        }

        if (checkRequired && !payload[validator]) {
            errors[validator] =
                validations[validator].errorMessages.required_error;
        }

        if (validations[validator].emailValid && !isValidEmail(payload[validator])) {
            errors[validator] = validations[validator].errorMessages.emailValid_error;
        }

        if (validations[validator].validLatin && !isValidLatinChars(payload[validator])) {
            errors[validator] = validations[validator].errorMessages.validLatin_error;
        }

        if (validations[validator].validSymbols && !isValidSpecialChars(payload[validator])) {
            errors[validator] = validations[validator].errorMessages.validSymbols_error;
        }

        return errors;
    }, errors);
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_PROFILE_ERRORS:
            const user = validateUserInputs(
                state.validations.user,
                action.payload,
                action.checkRequired,
            );

            return {
                ...state,
                errors: {
                    ...user
                },
            };
        default:
    }

    return state;
}
