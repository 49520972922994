import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import t from '../../../shared/translations'
import { getClaimReasons } from '../../../shared/actions'
import {
    Rai,
    Rbw,
    Rof,
    Rst,
    Rte,
} from '../../../shared/components/V2/SkycopPay/icons'
import { applyTranslationElement, isMobileDevice } from '../../../main'
import {
    setV2Claim,
    getReasonsByAirline,
    setProgress,
    setStepThree,
    setNavStep,
    setClaimDraftProps,
    setPreloadedClaimStep,
    nextStep,
    saveClaimDraft,
} from '../../../shared/actions/v2_actions'
import { Col, Row, StepContent } from '../../../shared/components/V2/Layout'
import {
    VerticalRadio,
    ImageRadio,
} from '../../../shared/components/V2/RadioButtons'
import StepView from '../components/StepView'
import {
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import _get from 'lodash/get'
import {
    scrollComponentToView,
    handleMobileInputFocus,
    handleMobileInputBlur,
    isProduction,
} from '../../../main'
import { mapSteps } from '../../../shared/reducers/V2/reducer_steps'
import { V2_prePath, V2_STEPS, paths } from '../../../shared/constants'
import SidebarProgress from '../components/SidebarProgress'
import { fireGtagEvent } from '../../../shared/services/events'

const Textarea = styled.textarea`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    min-height: 105px;
    border-radius: 6px;
    border: solid 1px #717171;
    padding: 10px 64px 10px 16px;
    outline: 0 none;

    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    background: #fafafa;

    &:focus {
        border: 1px solid #36b56a;
        outline: 0 none;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #aaa;
    }
`

const StyledP = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-family: 'Noto Sans';
    line-height: 1.71;
    letter-spacing: normal;
    color: #7f8fa4;
`

const AfterHeaderP = styled(StyledP)`
    padding: 0px 12px 8px;
    margin: 0%;

    ${mobile`
        padding-left: 16px;
        padding-right: 16px;
    `}

    ${tablet`
        padding-left: 16px;
        padding-right: 16px;
    `}
`

const StyledH2 = styled.h2`
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;

    color: #000000;
    display: block;
    margin: 40px 0 40px 0 !important;

    padding: 0px 12px;
`

const DottedLine = styled.div`
    margin-left: 8px;
    margin-right: 8px;
    width: 98%;
    height: 1px;
    background-image: linear-gradient(
        to right,
        #717171 25%,
        rgba(255, 255, 255, 0) 0%
    );
    margin-right: 50px;
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    margin-top: 32px;
`

const TextareaContainer = styled.div`
    position: relative;
    flex: 1;
`

const StyledRow = styled(Row)`
    margin-bottom: 24px;
    ${mobile`
        padding-right: 16px;
        `}
`

const StyledStepContent = styled(StepContent)`
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
`

const maxCount = 5000

class DisruptionReason extends React.Component {
    state = {
        comment: '',
        componentState: {
            has_reason: true,
            reason_4: '',
            ...this.props.step_3_state,
        },
        ...this.props.V2Claim,
    }

    componentDidUpdate(prevProps, prevState) {
        const { details, componentState } = this.state
        const { setParentState, isDisabled } = this.props
        const firstReason = _get(
            (details || []).filter(r => r.level === 1),
            '[0].value',
            ''
        )

        if (isDisabled && (firstReason === 'dbo' || componentState.reason_4)) {
            setParentState({
                nextDisabled: false,
            })
        }
    }

    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {}

        const {
            getClaimReasons,
            getReasonsByAirline,
            setParentState,
            V2Claim,
            claimDraft,
            claimPreloaded,
        } = this.props
        if (
            (!V2Claim ||
                !V2Claim.airportArrival ||
                !V2Claim.airportDeparture) &&
            isProduction()
        ) {
            setNavStep(0)
            browserHistory.replace(V2_prePath)
        }

        scrollComponentToView('pageTopNavbar', false, true)

        if (claimDraft && !claimPreloaded[V2_STEPS.STEP_3]) {
            this.setState(
                {
                    componentState: {
                        ...claimDraft.componentState,
                    },
                },
                () => {
                    this.props.setPreloadedClaimStep([V2_STEPS.STEP_3])
                }
            )
        }

        setParentState({
            nextDisabled: true,
        })
        getReasonsByAirline()
        getClaimReasons('3b5', 4)
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleRsnChange = e => {
        const { componentState } = this.state
        const { name, value } = e.target

        handleRsnGaEvents(name, value)

        this.setState({
            componentState: {
                ...componentState,
                [name]: value,
            },
        })
        handleMobileInputFocus('comment-box', false, 100, 0.4)
    }

    handleTopReasonChange = e => {
        const { componentState } = this.state
        const { name, value } = e.target

        handleTopGaEvents(name, value)

        this.setState({
            componentState: {
                ...componentState,
                has_reason: false,
                [name]: value,
            },
        })

        handleMobileInputFocus('comment-box', false, 100, 0.4)
    }

    getInitialDetails = () => {
        const { details } = this.state
        const { v2ReasonsByAirlines } = this.props
        const { firstRow, secondRow } = v2ReasonsByAirlines

        const allReasons = ([...firstRow, ...secondRow] || []).map(r => r.value)
        const initialReasons = (details || []).filter(
            r => !allReasons.includes(r.value)
        )

        return initialReasons
    }

    _handleNextStep = () => {
        const { details, comment } = this.state
        const { setV2Claim, setProgress, setStepThree, nextStep } = this.props
        const { reason_4 } = this.state.componentState
        const firstReason = _get(
            (details || []).filter(r => r.level === 1),
            '[0].value',
            ''
        )

        if (firstReason !== 'dbo' && !reason_4) {
            return false
        }

        if (!!comment.length) {
            fireGtagEvent(
                { event: 'step3_rftd_briefly_describe_success' },
                true
            )
        }

        this._handleSaveDraft()

        const newState = { ...this.state }
        newState.details = this.getInitialDetails()

        if (newState.details && newState.componentState.reason_4) {
            newState.details.push({
                value: newState.componentState.reason_4,
                level: newState.details.length + 1,
            })
        }

        setStepThree(this.state.componentState || {})
        setV2Claim(newState)
        setProgress(48)
        this.props.triggerAnalytics()
        nextStep(
            false,
            paths.V2.STEP_4_EMAIL_DETAILS.replace('(:email)', 'email')
        )
        return false
    }

    _handlePrevStep = () => {
        const { setV2Claim, setStepThree } = this.props

        const newState = { ...this.state }
        newState.details = this.getInitialDetails()

        this._handleSaveDraft()

        if (newState.details && newState.componentState.reason_4) {
            newState.details.push({
                value: newState.componentState.reason_4,
                level: newState.details.length + 1,
            })
        }

        setStepThree(this.state.componentState || {})
        setV2Claim(newState)
    }

    _handleSaveDraft = () => {
        const { setClaimDraftProps } = this.props
        const stateObj = { ...this.state }

        setClaimDraftProps(stateObj)
        this.props.saveClaimDraft()
    }

    renderReasons = (reasons, name, level, titleLabel) => {
        const reasonsArr = Object.keys(reasons)
        const { details } = this.state
        const reasonState = details
            .filter(r => r.level === level)
            .map(r => r.value)[0]

        const reasonItems = reasonsArr.map((reason, index) => ({
            name: name,
            value: reason,
            onChange: this.handleChange,
            checked: reasonState === reason,
            label: t`${reason}`,
        }))

        return (
            <VerticalRadio label={titleLabel} options={reasonItems} required />
        )
    }

    toggleSecondRowDisplay = () => {
        const { componentState, has_reason } = this.state

        if (!has_reason) {
            this.setState({
                componentState: {
                    ...componentState,
                    reason_4: '',
                    has_reason: true,
                },
            })
            handleMobileInputFocus('second-row-reasons', false, 500, 0.3)
        } else {
            this.setState({
                componentState: {
                    ...componentState,
                    has_reason: !has_reason,
                },
            })
        }
    }

    getCustomReasons = () => {
        const { componentState } = this.state
        const { has_reason, reason_4 } = componentState
        const { v2ReasonsByAirlines } = this.props
        const { firstRow } = v2ReasonsByAirlines
        const results = []

        results.push({
            name: 'has_reason',
            value: has_reason,
            onChange: this.toggleSecondRowDisplay,
            checked: !!has_reason,
            defaultChecked: !reason_4 && has_reason,
            label: t`yes`,
            id: 'yes-provided',
        })

        const firstMappedRow = (firstRow || []).map((reason, index) => ({
            name: 'reason_4',
            value: reason.value,
            onChange: this.handleTopReasonChange,
            checked: reason.value === reason_4,
            label: t`${reason.titleKey}`,
            id: `reason-${reason.value}`,
        }))

        return [...results, ...firstMappedRow]
    }

    render() {
        const { comment, componentState, details } = this.state
        const { has_reason, reason_4 } = componentState
        const { v2ReasonsByAirlines, trans, nav } = this.props
        const firstReason = _get(
            (details || []).filter(r => r.level === 1),
            '[0].value',
            ''
        )
        const { secondRow } = v2ReasonsByAirlines

        const IconsMap = isChecked => ({
            rte: <Rte stroke={isChecked && '#149954'} />,
            rbw: <Rbw stroke={isChecked && '#149954'} />,
            rst: <Rst stroke={isChecked && '#149954'} />,
            rai: <Rai stroke={isChecked && '#149954'} />,
            rof: <Rof stroke={isChecked && '#149954'} />,
        })

        if (!trans) return null

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                nav={{
                    ...nav,
                    nextDisabled: firstReason !== 'dbo' && !reason_4,
                }}
                Sidebar={
                    <SidebarProgress
                        activeIndex={2}
                        checkedIndexes={[0, 1]}
                        paddingTop={0}
                    />
                }
                title={
                    firstReason === 'dbo'
                        ? t`v2_claim.disruption_reasons.tell_us_more`
                        : t`v2_claim.disruption_reasons.title`
                }
            >
                {firstReason === 'dbo' && (
                    <Row>
                        <AfterHeaderP>
                            {applyTranslationElement(
                                t`v2_claim.disruption_reasons.briefly_describe_what_happened`,
                                '%count%',
                                comment.length
                            )}
                        </AfterHeaderP>
                    </Row>
                )}
                <StyledStepContent>
                    {firstReason !== 'dbo' && (
                        <React.Fragment>
                            <StyledRow>
                                <VerticalRadio
                                    label={t`v2_claim.disruption_reasons.canceled`}
                                    required
                                    options={this.getCustomReasons()}
                                />
                            </StyledRow>
                            {has_reason && (
                                <Row>
                                    {isMobileDevice() ? (
                                        <>
                                            <ImageRadio
                                                noPaddingB={true}
                                                required
                                                id="second-row-reasons"
                                                label={t`v2_claim.disruption_reasons.provided_reasons`}
                                                options={(
                                                    secondRow.slice(0, 2) || []
                                                ).map(reason => ({
                                                    name: 'reason_4',
                                                    checked:
                                                        reason.value ===
                                                        reason_4,
                                                    value: reason.value,
                                                    onChange: this
                                                        .handleRsnChange,
                                                    label: t`v2_reason_${reason.titleKey}`,
                                                    src: `/images/v2/${reason.titleKey}.svg`,
                                                    id: `reason-${reason.value}`,
                                                    icon: IconsMap(
                                                        reason.value ===
                                                            reason_4
                                                    )[reason.value],
                                                }))}
                                            />
                                            <ImageRadio
                                                noPaddingB={true}
                                                noPaddingT={true}
                                                required
                                                id="second-row-reasons"
                                                options={(
                                                    secondRow.slice(2, 4) || []
                                                ).map(reason => ({
                                                    name: 'reason_4',
                                                    checked:
                                                        reason.value ===
                                                        reason_4,
                                                    value: reason.value,
                                                    onChange: this
                                                        .handleRsnChange,
                                                    label: t`v2_reason_${reason.titleKey}`,
                                                    src: `/images/v2/${reason.titleKey}.svg`,
                                                    id: `reason-${reason.value}`,
                                                    icon: IconsMap(
                                                        reason.value ===
                                                            reason_4
                                                    )[reason.value],
                                                }))}
                                            />
                                            <ImageRadio
                                                noPaddingB={true}
                                                noPaddingT={true}
                                                required
                                                id="second-row-reasons"
                                                options={(
                                                    secondRow.slice(4, 5) || []
                                                ).map(reason => ({
                                                    name: 'reason_4',
                                                    checked:
                                                        reason.value ===
                                                        reason_4,
                                                    value: reason.value,
                                                    onChange: this
                                                        .handleRsnChange,
                                                    label: t`v2_reason_${reason.titleKey}`,
                                                    src: `/images/v2/${reason.titleKey}.svg`,
                                                    id: `reason-${reason.value}`,
                                                    icon: IconsMap(
                                                        reason.value ===
                                                            reason_4
                                                    )[reason.value],
                                                }))}
                                            />
                                        </>
                                    ) : (
                                        <ImageRadio
                                            required
                                            id="second-row-reasons"
                                            label={t`v2_claim.disruption_reasons.provided_reasons`}
                                            options={(secondRow || []).map(
                                                reason => ({
                                                    name: 'reason_4',
                                                    checked:
                                                        reason.value ===
                                                        reason_4,
                                                    value: reason.value,
                                                    onChange: this
                                                        .handleRsnChange,
                                                    label: t`v2_reason_${reason.titleKey}`,
                                                    src: `/images/v2/${reason.titleKey}.svg`,
                                                    id: `reason-${reason.value}`,
                                                    icon: IconsMap(
                                                        reason.value ===
                                                            reason_4
                                                    )[reason.value],
                                                })
                                            )}
                                        />
                                    )}
                                </Row>
                            )}
                            <DottedLine />
                            <Row>
                                <StyledH2>{t`v2_claim.disruption_reasons.tell_us_more`}</StyledH2>
                            </Row>
                            <Row>
                                <AfterHeaderP>
                                    {applyTranslationElement(
                                        t`v2_claim.disruption_reasons.briefly_describe_what_happened`,
                                        '%count%',
                                        comment.length
                                    )}
                                </AfterHeaderP>
                            </Row>
                        </React.Fragment>
                    )}
                    <Row>
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{ paddingTop: 0 }}
                        >
                            <TextareaContainer id="comment-box">
                                <Textarea
                                    data-hj-whitelist=""
                                    placeholder={t`v2_claim.disruption_reasons.comment_placeholder`}
                                    value={comment}
                                    name="comment"
                                    id="comment"
                                    onChange={this.handleChange}
                                    maxLength={maxCount}
                                    onFocus={e => {
                                        e.preventDefault()
                                        handleMobileInputFocus()
                                    }}
                                    onBlur={e => {
                                        e.preventDefault()
                                        handleMobileInputBlur()
                                    }}
                                />
                            </TextareaContainer>
                        </Col>
                    </Row>
                </StyledStepContent>
            </StepView>
        )
    }
}

function handleTopGaEvents(reasonName, value) {
    if (window && window.dataLayer) {
        const eventList = window.dataLayer
            .map(layer => layer.event)
            .filter(Boolean)

        if (reasonName === 'reason_4') {
            const reasonOneEvents = [
                'step3_rftd_disruption_yes',
                'step3_rftd_disruption_no',
                'step3_rftd_disruption_dont_remember',
            ]
            const reasonOneEventsValues = ['yes', 'rwi', 'rdr']
            const found = reasonOneEvents.some(e => eventList.includes(e))

            if (!found) {
                const valueIndex = reasonOneEventsValues.findIndex(v =>
                    value ? v === value : v === 'yes'
                )
                fireGtagEvent({ event: reasonOneEvents[valueIndex] }, true)
            }
        }
    }
}

function handleRsnGaEvents(reasonName, value) {
    if (window && window.dataLayer) {
        const eventList = window.dataLayer
            .map(layer => layer.event)
            .filter(Boolean)

        if (reasonName === 'reason_4') {
            const reasonsEvents = [
                'step3_rftd_aircraft_technical_problem',
                'step3_rftd_bad_weather_conditions',
                'step3_rftd_strike',
                'step3_rftd_airport_issues',
                'step3_rftd_other_reasons',
            ]
            const reasonOneEvents = [
                'step3_rftd_disruption_yes',
                'step3_rftd_disruption_no',
                'step3_rftd_disruption_dont_remember',
            ]
            const reasonOneEventsValues = ['rte', 'rbw', 'rst', 'rai', 'rof']
            const found = reasonsEvents.some(e => eventList.includes(e))
            const foundReasonOne = reasonOneEvents.some(e =>
                eventList.includes(e)
            )
            if (!found) {
                const valueIndex = reasonOneEventsValues.findIndex(
                    v => v === value
                )

                if (!foundReasonOne) {
                    fireGtagEvent({ event: 'step3_rftd_disruption_yes' }, true)
                }
                fireGtagEvent({ event: reasonsEvents[valueIndex] }, true)
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        reasons: state.reason_4,
        trans: state.translations,
        V2Claim: state.V2Claim,
        v2ReasonsByAirlines: state.v2_reasonByAirlines,
        step_3_state: state.step_3_state,
        claimDraft: state.claimDraft,
        claimPreloaded: state.claimPreloaded,
        authenticated: state.auth.authenticated,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getClaimReasons,
            setV2Claim,
            setStepThree,
            getReasonsByAirline,
            setProgress,
            setClaimDraftProps,
            setPreloadedClaimStep,
            nextStep,
            saveClaimDraft,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(DisruptionReason)
