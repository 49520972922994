import React from 'react'
import PropTypes from 'prop-types'
import ErrorText from './ErrorText'
import SuggestedText, { Text } from './SuggestedText'
import { StyledInput, StyledFormGroup } from '../styledComponents'
import t from '../../translations'

const InputField = ({
    errorText,
    label,
    type,
    className,
    suggestedText,
    isValidSmtp,
    hasSmtpValidation,
    ...props
}) => (
    <StyledFormGroup hasDanger={!!errorText} className={className}>
        {label && <label>{label}</label>}
        <StyledInput type={type} {...props} />
        <ErrorText text={errorText} />
        {suggestedText && (
            <SuggestedText
                text={!errorText && suggestedText.text}
                callback={suggestedText.callback}
            />
        )}
        {!isValidSmtp &&
            !errorText &&
            suggestedText &&
            !suggestedText.text &&
            hasSmtpValidation && (
                <Text>{t`common.label.could_not_verify_email`}</Text>
            )}
    </StyledFormGroup>
)

InputField.propTypes = {
    readOnly: PropTypes.bool,
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    label: PropTypes.string,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    suggestedText: PropTypes.shape({
        text: PropTypes.string,
        callback: PropTypes.func,
    }),
}

InputField.defaultProps = {
    errorText: '',
    label: '',
    type: 'text',
    className: '',
    readOnly: false,
    maxLength: 255,
}

export default InputField
