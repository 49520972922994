import reducer_steps from './reducer_steps'
import reducer_v2_translations from './reducer_v2_translations'
import reducer_top_bar_info from './reducer_top_bar_info'
import reducer_toggle_modal from './reducer_toggle_modal'
import reducer_step_one from './reducer_step_one'
import reducer_posted_claim from './reducer_posted_claim'
import reducer_step_one_validation from './reducer_step_one_validation'
import reducer_step_two_validation from './reducer_step_two_validation'
import reducer_step_2_state from './reducer_step_2_state'
import reducer_step_3_state from './reducer_step_3_state'
import reducer_step_4_state from './reducer_step_4_state'
import reducer_step_5_state from './reducer_step_5_state'
import reducer_step_6_state from './reducer_step_6_state'
import reducer_step_three_validation from './reducer_step_three_validation'
import reducer_step_four_validation from './reducer_step_four_validation'
import reducer_step_five_validation from './reducer_step_five_validation'
import reducer_step_six_validation from './reducer_step_six_validation'
import reducer_step_two from './reducer_step_two'
import reducer_step_three from './reducer_step_three'
import reducer_step_four from './reducer_step_four'
import reducer_step_five from './reducer_step_five'
import reducer_step_six from './reducer_step_six'
import reducer_step_seven from './reducer_step_seven'
import reducer_user from './reducer_user'
import reducer_details from './reducer_details'
import reducer_flights from './reducer_flights'
import reducer_passengers from './reducer_passengers'
import reducer_claimable from './reducer_claimable'
import reducer_comment from './reducer_comment'
import reducer_stops from './reducer_stops'
import reducer_reservation_number from './reducer_reservation_number'
import reducer_departure_time from './reducer_departure_time'
import reducer_departure_airport from './reducer_departure_airport'
import reducer_arrival_airport from './reducer_arrival_airport'
import reducer_partner_id from './reducer_partner_id'
import reducer_price from './reducer_price'
import reducer_documents from './reducer_documents'
import reducer_payout_plan from './reducer_payout_plan'
import reducer_signature from './reducer_signature'
import reducer_user_documents from './reducer_user_documents'
import reducer_v2_claim from './reducer_v2_claim'
import reducer_claim_UUID from './reducer_claim_UUID'
import reducer_used_airports from './reducer_used_airports'
import reducer_selected_airports from './reducer_selected_airports'
import reducer_used_airlines from './reducer_used_airlines'
import reducer_selected_airlines from './reducer_selected_airlines'
import reducer_airlines_reasons from './reducer_airlines_reasons'
import reducer_flight_distance from './reducer_flight_distance'
import reducer_claim_amount from './reducer_claim_amount'
import reducer_login_loading from './reducer_login_loading'
import reducer_progress_bar from './reducer_progress_bar'
import reducer_onboarding_slider from './reducer_onboarding_slider'
import reducer_skip_signature from './reducer_skip_signature'
import reducer_total_amount from './reducer_total_amount'
import reducer_hide_mobile_cookies from './reducer_hide_mobile_cookies'
import reducer_unavailable_service from './reducer_unavailable_service'
import reducer_payout_details from './reducer_payout_details'
import reducer_claim_draft from './reducer_claim_draft'
import reducer_draft_errors from './reducer_claim_draft_errors'
import reducer_has_fast_payout from './reducer_has_fast_payout'
import reducer_selected_plan from './reducer_selected_plan'
import reducer_fast_selected from './reducer_fast_selected'
import reducer_enabled_postcode from './reducer_enabled_postcode'
import reducer_profile_errors from './reducer_profile_errors'
import reducer_claim_draft_props from './reducer_claim_draft_props'
import reducer_claim_draft_id from './reducer_claim_draft_id'
import reducer_claim_draft_preload from './reducer_claim_draft_preload'
import reducer_payment_errors from './reducer_payment_errors'
import reducer_not_supported_airlines from './reducer_not_supported_airlines'
import reducer_draft_email from './reducer_draft_email'
import reducer_regulation from './reducer_regulation'
import reducer_all_drafts from './reducer_all_drafts'
import reducer_suggested_flights from './reducer_suggested_flights'
import reducer_hide_elements from './reducer_hide_elements'
import reducer_skycopcare_user from './reducer_skycopcare_user'
import reducer_skycopcare_subscriptions from './reducer_skycopcare_subscriptions'
import reducer_skycopcare_products from './reducer_skycopcare_products'
import reducer_skycopcare_products_v2 from './reducer_skycopcare_products_v2'
import reducer_skycopcare from './reducer_skycopcare'
import reducer_leaving_modal from './reducer_leaving_modal'
import reducer_promo_codes from './reducer_promo_codes'
import reducer_smart_delay from './reducer_smart_delay'
import reducer_signature_page_config from './reducer_signature_page_config'
import reducer_referral_modal from './reducer_referral_modal'

export default {
    reducer_referral_modal,
    reducer_signature_page_config,
    reducer_smart_delay,
    reducer_leaving_modal,
    reducer_promo_codes,
    reducer_skycopcare,
    reducer_skycopcare_user,
    reducer_skycopcare_subscriptions,
    reducer_skycopcare_products,
    reducer_skycopcare_products_v2,
    reducer_draft_email,
    reducer_all_drafts,
    reducer_regulation,
    reducer_suggested_flights,
    reducer_hide_mobile_cookies,
    reducer_steps,
    reducer_v2_translations,
    reducer_top_bar_info,
    reducer_toggle_modal,
    reducer_has_fast_payout,
    reducer_step_one,
    reducer_step_one_validation,
    reducer_step_two_validation,
    reducer_step_three_validation,
    reducer_step_four_validation,
    reducer_step_five_validation,
    reducer_step_six_validation,
    reducer_step_two,
    reducer_step_three,
    reducer_step_four,
    reducer_step_five,
    reducer_step_six,
    reducer_step_seven,
    reducer_user,
    reducer_details,
    reducer_flights,
    reducer_passengers,
    reducer_claimable,
    reducer_comment,
    reducer_stops,
    reducer_reservation_number,
    reducer_departure_time,
    reducer_departure_airport,
    reducer_arrival_airport,
    reducer_partner_id,
    reducer_price,
    reducer_documents,
    reducer_payout_plan,
    reducer_signature,
    reducer_user_documents,
    reducer_v2_claim,
    reducer_claim_UUID,
    reducer_used_airports,
    reducer_selected_airports,
    reducer_used_airlines,
    reducer_selected_airlines,
    reducer_airlines_reasons,
    reducer_flight_distance,
    reducer_claim_amount,
    reducer_total_amount,
    reducer_login_loading,
    reducer_progress_bar,
    reducer_onboarding_slider,
    reducer_step_2_state,
    reducer_step_3_state,
    reducer_step_4_state,
    reducer_step_5_state,
    reducer_step_6_state,
    reducer_posted_claim,
    reducer_skip_signature,
    reducer_unavailable_service,
    reducer_payout_details,
    reducer_draft_errors,
    reducer_fast_selected,
    reducer_selected_plan,
    reducer_enabled_postcode,
    reducer_profile_errors,
    reducer_claim_draft,
    reducer_claim_draft_props,
    reducer_claim_draft_id,
    reducer_claim_draft_preload,
    reducer_payment_errors,
    reducer_not_supported_airlines,
    reducer_hide_elements,
}
