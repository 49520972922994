import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ErrorText from "./ErrorText";
import Select from 'react-select';

const Container = styled.div`
    .Select {
        position: relative;
        box-sizing: border-box;
        line-height: 2.6rem;
        font-size: 1.4rem;
        font-family: 'Open Sans', sans-serif;

        div, input, span {
            box-sizing: border-box;
            line-height: 2.6rem;
            font-size: 1.4rem;
        }

        &.is-disabled {
            > .Select-control {
                background-color: #eceeef;
                    &:hover {
                        box-shadow: none;
                }
            }

            .Select-arrow-zone {
                cursor: default;
                pointer-events: none;
                opacity: 0.35;
            }
        }

        .Select-aria-only {
            display: inline-block;
            height: 1px;
            width: 1px;
            margin: -1px;
            clip: rect(0, 0, 0, 0);
            overflow: hidden;
            float: left;
        }
    }

    .Select-control {
        background-color: #fff;
        border-radius: .2rem;
        border: solid 0.1rem #a4bbbb;
        box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
        color: #333;
        cursor: default;
        display: table;
        border-spacing: 0;
        border-collapse: separate;
        height: 5.2rem;
        font-size: 1.4rem;
        outline: none;
        font-weight:bold;
        overflow: hidden;
        position: relative;
        width: 100%;

        &:hover {
            border-color: ${({ theme }) => theme.colors.primaryColor};
            box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
        }

        .Select-input {
            &:focus {
            outline: none;
            }
        }

        &:not(.is-searchable) { > .Select-input {
            outline: none;
            border-color: ${({ theme }) => theme.colors.primaryColor};
            box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
            }
        }
    }

    .is-searchable {
        &.is-open {
            > .Select-control {
                cursor: text;
                border-color: ${({ theme }) => theme.colors.primaryColor};
                box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
            }
        }

        &.is-focused {
            &:not(.is-open) {
            > .Select-control {
                border-color: ${({ theme }) => theme.colors.primaryColor};
                box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
                cursor: text;
          }
        }
      }
    }

    .is-open {
          > .Select-control {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            background: #fff;
            border-color: ${({ theme }) => theme.colors.primaryColor};
            box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);

            .Select-arrow {
                top: -2px;
                border-color: transparent transparent ${({ theme }) => theme.colors.primaryColor};;
                border-width: 0 5px 5px;
            }
        }

        .Select-arrow {
            &:hover  {
                > .Select-arrow {
                    border-top-color: ${({ theme }) => theme.colors.primaryColor};
                }
            }
        }
    }

    .is-focused {
        &:not(.is-open) {
            > .Select-control {
                border-color: ${({ theme }) => theme.colors.primaryColor};
                box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
            }
      }

      .Select-input {
          > input {
              cursor: text;
            }
        }
    }

    .Select-placeholder {
        bottom: 0;
        color: #aaa;
        left: 0;
        padding: 1.2rem 1.7rem;
        line-height: 3.2rem !important;
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: normal;
    }

    .Select--single {
      > .Select-control {
            .Select-value {
                bottom: 0;
                color: #aaa;
                left: 0;
                padding: 1.2rem 1.7rem;
                line-height: 2.6rem;
                vertical-align: middle;
                position: absolute;
                right: 0;
                top: 0;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: normal;
            }
        }
    }

    .has-value {
        &.Select--single {
            > .Select-control {
                .Select-value {
                    .Select-value-label {
                        color: ${({ theme }) => theme.colors.secondaryColor};
                        font-weight: bold;
                        line-height: 3.2rem;
                    }
                    a {
                    &.Select-value-label {
                            cursor: pointer;
                            text-decoration: none;
                            &:hover,
                            &:focus {
                                color: ${({ theme }) => theme.colors.secondaryColor};
                                outline: none;
                                text-decoration: underline;
                            }
                        }
                    }
                }
        }
      }

        &.is-pseudo-focused {
            &.Select--single {
                > .Select-control {
                    .Select-value {
                        .Select-value-label {
                            color: ${({ theme }) => theme.colors.secondaryColor};
                            font-weight: bold;
                            &:hover {
                
                            }
                        }
                    }

                    a {
                        &.Select-value-label {
                            cursor: pointer;
                            text-decoration: none;
                            &:hover,
                            &:focus {
                            color: ${({ theme }) => theme.colors.secondaryColor};
                            outline: none;
                            text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .Select-input {
                opacity: 0;
            }
        }
    }

    .Select-input {
        max-height: 5.2rem;
        padding: 1.2rem 1.7rem;
        vertical-align: middle;
        > input {
            width: 100%;
            background: none transparent;
            border: 0 none;
            box-shadow: none;
            cursor: default;
            display: inline-block;
            font-family: 'Open Sans', sans-serif;
            font-size: inherit;
            margin: 0;
            outline: none;
            line-height: 14px;
            /* For IE 8 compatibility */
            padding: 6px 0 12px;
            /* For IE 8 compatibility */
            -webkit-appearance: none;
        }
    }

    .Select-loading-zone {
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        padding-right: 7px;
        width: 16px;
    }

    .Select-loading {
        -webkit-animation: Select-animation-spin 400ms infinite linear;
        -o-animation: Select-animation-spin 400ms infinite linear;
        animation: Select-animation-spin 400ms infinite linear;
        width: 16px;
        padding-right: 7px;
        height: 16px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px solid #ccc;
        border-right-color: #333;
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }

    .Select-clear-zone {
        -webkit-animation: Select-animation-fadeIn 200ms;
        -o-animation: Select-animation-fadeIn 200ms;
        animation: Select-animation-fadeIn 200ms;
        color: #999;
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 17px;
        padding-right: 10px;
        &:hover {
            color: #D0021B;
        }
    }

    .Select-clear {
        display: inline-block;
        font-size: 18px;
        line-height: 1;
    }
    
    .Select--multi {
        .Select-clear-zone {
            width: 17px;
        }
        .Select-multi-value-wrapper {
            display: inline-block;
        }
        .Select-input {
            vertical-align: middle;
            margin-left: 10px;
            padding: 0;
        }
        &.has-value {
            .Select-input {
                margin-left: 5px;
            }
        }

        .Select-value {
            background-color: #ebf5ff;
            /* Fallback color for IE 8 */
            background-color: rgba(0, 126, 255, 0.08);
            border-radius: 2px;
            border: 1px solid #c2e0ff;
            /* Fallback color for IE 8 */
            border: 1px solid rgba(0, 126, 255, 0.24);
            color: #007eff;
            display: inline-block;
            font-size: 0.9em;
            line-height: 1.4;
            margin-left: 5px;
            margin-top: 5px;
            vertical-align: top;
        }

        .Select-value-icon,
        .Select-value-label {
            display: inline-block;
            vertical-align: middle;
        }
        .Select-value-label {
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
            cursor: default;
            padding: 2px 5px;
        }
        a {
            &.Select-value-label {
                color: #007eff;
                cursor: pointer;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .Select-value-icon {
            cursor: pointer;
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;
            border-right: 1px solid #c2e0ff;
            /* Fallback color for IE 8 */
            border-right: 1px solid rgba(0, 126, 255, 0.24);
            padding: 1px 5px 3px;
            &:hover,
            &:focus {
                background-color: #d8eafd;
                /* Fallback color for IE 8 */
                background-color: rgba(0, 113, 230, 0.08);
                color: #0071e6;
            }
            &:active {
                background-color: #c2e0ff;
                /* Fallback color for IE 8 */
                background-color: rgba(0, 126, 255, 0.24);
            }
        }

        &.is-disabled {
            .Select-value {
                background-color: #fcfcfc;
                border: 1px solid #e3e3e3;
                color: #333;
            }
            .Select-value-icon {
                cursor: not-allowed;
                border-right: 1px solid #e3e3e3;
                &:hover,
                &:focus,
                &:active {
                    background-color: #fcfcfc;
                }
            }
        }
    }

    .Select-arrow-zone {
        cursor: pointer;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 25px;
        padding-right: 5px;
        &:hover {
            > .Select-arrow {
                border-top-color: ${({ theme }) => theme.colors.primaryColor};;
            }
        }
    }

    .Select-arrow {
        border-color: ${({ theme }) => theme.colors.primaryColor} transparent transparent;
        border-style: solid;
        border-width: 5px 5px 2.5px;
        display: inline-block;
        height: 0;
        width: 0;
        position: relative;
    }
    
    @-webkit-keyframes Select-animation-fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes Select-animation-fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .Select-menu-outer {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-top-color: #e6e6e6;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: -1px;
        margin-bottom:20px;
        max-height: 200px;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 9999999;
        -webkit-overflow-scrolling: touch;
        border-color: ${({ theme }) => theme.colors.primaryColor};
        border-top: 1px solid #eee;
    }

    .Select-menu {
        max-height: 198px;
        overflow-y: auto;
    }

    .Select-option {
        box-sizing: border-box;
        background-color: #fff;
        color: #666666;
        cursor: pointer;
        display: block;
        padding: ${({ showDisabled }) => !!showDisabled ? '4px 10px' : '8px 10px'};
        border-bottom: 1px solid #eee;

        &:last-child {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &.is-disabled {
            display: ${({ showDisabled }) => !!showDisabled ? 'block' : 'none'};
            background-color: ${({ showDisabled }) => !!showDisabled ? '#F5F5F5' : '#fff'};
            color: #cccccc;
            cursor: default;
        }

        &.is-focused {
            background-color: #fafafa;
            /* Fallback color for IE 8 */
            color: ${({ theme }) => theme.colors.secondaryColor};
        }
    }

    .Select-noresults {
        box-sizing: border-box;
        color: #999999;
        cursor: default;
        display: block;
        padding: 6px 10px;
    }

    ${({ hasDanger }) => hasDanger && `
        .Select-control { 
            border-color: #d9534f;
        }
    `}
`;

class SelectField extends Component {

    generateSelectInput = (props) => {
        const { isAsync, ...restProps } = props;

        if (isAsync) {
            return <Select.Async {...restProps} />;
        }

        return <Select {...restProps} />;
    };

    render() {
        const { label, errorText, className, selectProps, showDisabled, hasDanger, ...props } = this.props;
        
        return (
            <Container
                hasDanger={!!errorText || !!selectProps.errorText || hasDanger}
                className={`form-group ${className}`}
                showDisabled={!!showDisabled}
                {...props}
            >
                { label && <label>{label}</label> }
                {this.generateSelectInput(selectProps)}
                <ErrorText text={errorText || selectProps.errorText} />
            </Container>
        );
    }
}

SelectField.propTypes = {
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    label: PropTypes.string,
    selectProps: PropTypes.object.isRequired,
    className: PropTypes.string,
};

SelectField.defaultProps = {
    errorText: "",
    label: "",
    className: "",
};

export default SelectField;

