import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { hideModal } from '../../../shared/actions/v2_actions'
import Modal from '../../../shared/components/V2/Modal'
import { CloseButton } from '../../../shared/components/V2/V2Buttons'
import {
    tablet,
    mobile,
    desktop,
    tabletLG,
    tabletSM,
} from '../../../shared/components/helpers/styled_queries'
import { modalTypes } from '../../../shared/constants'
import t from '../../../shared/translations'
import { Col, Row } from '../../../shared/components/V2/Layout'
import _ from 'lodash'
import _get from 'lodash/get'
import { logKibana } from '../../../shared/actions'
import { PlaneIcon1 } from '../../../shared/components/svgIcons'
import { PlaneIconContainer } from '../steps/FlightDetails'

const StyledRowCol = styled(Col)`
    span {
        font-weight: 600;
    }

    span:last-child {
        margin-right: 2px;
    }

    span:first-child {
        margin-right: 6px;
    }
`

class SuggestedRow extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {
            flight_time,
            airline_title,
            airline_iata,
            flight_number,
            isSelected,
        } = this.props

        return (
            flight_time !== nextProps.flight_time ||
            airline_title !== nextProps.airline_title ||
            airline_iata !== nextProps.airline_iata ||
            flight_number !== nextProps.flight_number ||
            isSelected !== nextProps.isSelected
        )
    }

    render() {
        const {
            airline_title,
            airline_iata,
            flight_number,
            flight_time,
            flight_arrive_time,
            isSelected,
            onClick,
        } = this.props
        return (
            <Row
                className={`suggest-row ${isSelected ? 'selected' : ''}`}
                onClick={onClick}
            >
                <Col
                    className="right-container"
                    style={{
                        fontWeight: isSelected ? '600' : 'normal',
                        transition: 'unset',
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    skipTabletRestyle
                >
                    <StyledRowCol className="suggest-airline">
                        <span>
                            <img
                                src="/images/dark-watch.svg"
                                width="15px"
                                height="15px"
                                style={{
                                    marginRight: '6px',
                                    marginBottom: '3px',
                                }}
                                alt="stopwatch"
                            />
                        </span>
                        <span>
                            {flight_time} - {flight_arrive_time}
                        </span>
                        | {airline_title} | {airline_iata} {flight_number}
                    </StyledRowCol>

                    {/* <Col className="suggest-airline" sm={6} md={6} lg={7}>
                        {airline_title}
                    </Col>
                    <Col className="suggest-number" sm={6} md={2} lg={2}>
                        {airline_iata} {flight_number}
                    </Col> */}
                </Col>
            </Row>
        )
    }
}

const ShowMoreRow = ({ onClick, count }) => (
    <Row className="show-more" onClick={onClick}>
        {t`draft_exists.button.show_more`} ({count}){' '}
        <img
            alt="..."
            style={{ marginLeft: '8px' }}
            src="/images/dots.svg"
            width="16"
            height="4"
        />
    </Row>
)

const FlightsContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 50px 32px;
    padding-bottom: 32px;
    display: flex;
    font-family: "Open Sans", sans-serif;
    color: #343d53;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    ${tablet`
        padding: 48px 20px;
    `}

    ${mobile`
        padding: 24px 20px;
    `}

    .show-more {
        color: #32b86a;
        font-size: 13px;
        font-weight: 600;
        text-decoration: underline;
        margin-bottom: 12px;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.9;
        }
    }

    .table-label {
        display: block;

        ${Col} {
            font-size: 13px;
            color: #7f8fa4;
            display: inline-block;
            width: 50%;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                text-align: right;
                padding-right: 20px;
            }
        }
    }

    .suggest-row {
        border: 1px solid #cbd3df;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        padding: 8px 8px;
        cursor: pointer;

        &:hover, &.selected {
            border-color: #32b86a;
            background-color: #f5fbf7;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        ${Col} {
            color: #353d51;
            font-size: 14px;
            clear: both;

            &.suggest-time {
                margin: auto;
                font-weight: 600;
            }

            &.suggest-number, &.suggest-airline {
                display: inline-flex;
                width: unset;
                padding: 0;
            }

            &.suggest-number {
                justify-content: flex-end;
                float: right;
            }
        }
    }

    .button-container {
        margin-top: 32px;
        justify-content: center;

        span {
            width: fit-content;
            margin: 0px 8px;

            button {
                width: fit-content;
            }
        }
    }

    ${mobile`
        ${Row} {
            flex-direction: row;
        }

        .suggest-row .item-col {
            padding: 2px 4px;
        }

        .button-container {
            margin-top: 16px;
            flex-direction: column;
            align-items: center;
            display: block;
            text-align: center;

            span {
                margin: 8px 0px;
                display: inline-block;
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }

        .table-label {
            ${Col} {
                &:first-child {
                    padding-left: 12px;
                }

                &:last-child {
                    text-align: right;
                    padding-right: 12px;
                }
            }
        }

        .result-container {
            box-shadow: unset !important;
        }
    `}

    ${tablet`
        ${Row} {
            flex-direction: row;
        }

        .result-container {
            box-shadow: unset !important;
        }

        .suggest-row .item-col {
            padding: 2px 4px;
        }

        .table-label {
            ${Col} {
                &:first-child {
                    padding-left: 12px;
                }

                &:last-child {
                    text-align: right;
                    padding-right: 12px;
                }
            }
        }
    `}

    ${tabletSM`
        .button-container {
            margin-top: 24px;
            flex-direction: column;
            align-items: center;

            span {
                margin: 8px 0px;
            }
        }
    `}

    ${tabletLG`
        width: 80%;
        margin: auto;

        .right-container {
            display: flex;
        }

        .suggest-row {
            padding: 8px 8px;

            ${Col} {
                &.suggest-number, &.suggest-airline {
                    display: inline-flex;
                    width: unset;
                }

                .suggest-number {
                    justify-content: flex-end;
                }
            }
        }
    `}

    .result-container {
        width: 100%;
        padding-bottom: 16px;
        height: fit-content;
        ${({ moreResults }) =>
            moreResults
                ? 'box-shadow: inset 0px -15px 15px -15px rgba(0,0,0,0.07), inset 0px 15px 15px -15px rgba(0,0,0,0.07);'
                : ''}
    }

    ${desktop`
        .result-container {
            max-height: 300px;
            overflow-y: auto;
        }

        .suggest-row {
            padding: 8px 8px;

            ${Col} {
                &.suggest-number, &.suggest-airline {
                    display: inline-flex;
                }

                &.suggest-number {
                    text-align: right;
                    margin-left: auto;
                    justify-content: flex-end;
                }
            }
        }

        .right-container {
            display: flex;
        }
    `}
`

const Title = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #353d51;
    text-align: center;

    padding-top: 16px;
    ${({ hasSubheader }) => (!hasSubheader ? 'padding-bottom: 25px;' : '')}
`

const StyledHeaderContainer = styled.div`
    display: flex;
    padding: 16px 20px;
    padding-top: 0px;
    align-items: center;

    svg {
        margin: 0px;
    }

    p {
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        font-weight: 600;
        line-height: 2.13;
        color: #353d51;
        margin: 0;
    }
`

class FlightSuggestModalCodeshares extends React.Component {
    state = {
        selected: _get(this, 'props.modal.props.selected', null),
        openGroups: [],
    }

    setSelected = (key, i) => {
        this.setState({ selected: `${key}-${i}` }, () => {
            this.submitSelection()
        })
    }

    submitSelection = () => {
        const { selected } = this.state
        const { hideModal, modal, logKibana } = this.props
        const data = _get(modal, 'props.data', [])

        if (modal && data && Object.keys(data).length > 0) {
            const { props } = modal
            if (props.callBack && typeof props.callBack === 'function') {
                const [key, i] = selected.split('-')
                const item = data[key][i]
                props.callBack(item, selected)
            }
        }

        logKibana('SUBMIT_PRESUGGEST', '/flight-details', 'warning')
        hideModal()
    }

    render() {
        const { hideModal, modal } = this.props
        const { selected, openGroups } = this.state
        const data = _get(modal, 'props.data', [])
        const route = _get(modal, 'props.route', null)
        const mappedData = Object.keys(data)
            .map(key => data[key].length)
            .reduce((prev, curr) => prev + curr, 0)
        const sortedData = {}
        _(data)
            .keys()
            .sort()
            .each(function(key) {
                sortedData[key] = data[key]
            })

        return (
            <Modal
                modalType={modalTypes.flightSuggestModalCodeshares}
                width="769px"
                height="auto"
                fadeIn={true}
            >
                <CloseButton
                    onClick={hideModal}
                    id="btn-flight-suggest-close"
                />
                <FlightsContainer moreResults={mappedData > 4 ? true : false}>
                    <div>
                        <img
                            src="/images/suggest-icon.svg"
                            width="80px"
                            alt="Flight suggest"
                        />
                    </div>
                    <Title
                        style={{ paddingTop: 0, marginTop: -6 }}
                        hasSubheader={!!route}
                    >{t`flight_suggest.select_flight`}</Title>
                    {route && route.depIata && route.arrIata && (
                        <StyledHeaderContainer>
                            <p>{route.depIata}</p>
                            <PlaneIconContainer>
                                <PlaneIcon1 fill="#cbd3df" />
                            </PlaneIconContainer>
                            <p>{route.arrIata}</p>
                        </StyledHeaderContainer>
                    )}
                    {/* <Row className="table-label">
                        <Col xs={6} md={6} sm={6} lg={6}>
                            {t`v2_common.label.airline`}
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={6}>
                            {t`v2_common.label.flight_number`}
                        </Col>
                    </Row> */}
                    <div className="result-container">
                        {Object.keys(sortedData).map((key, i) => {
                            // const flightTime = _get(
                            //     data,
                            //     `${[key]}[0].flight_time`,
                            //     ''
                            // )
                            // let timeRow = <span key="timeRow" />
                            // if (!!flightTime) {
                            //     timeRow = (
                            //         <TimeStampRow key="timeRow">
                            //             <span>
                            //                 <img
                            //                     src="/images/dark-watch.svg"
                            //                     width="15px"
                            //                     height="15px"
                            //                     style={{
                            //                         marginRight: '6px',
                            //                         marginBottom: '3px',
                            //                     }}
                            //                     alt="stopwatch"
                            //                 />
                            //             </span>
                            //             <span>{flightTime}</span>
                            //         </TimeStampRow>
                            //     )
                            // }
                            const firstTwo = data[key]
                                .slice(0, 2)
                                .map((item, i) => (
                                    <SuggestedRow
                                        key={`row-${key}-${i}`}
                                        isSelected={`${key}-${i}` === selected}
                                        {...item}
                                        onClick={() => this.setSelected(key, i)}
                                    />
                                ))
                            let rest = []

                            if (data[key].length > 2) {
                                if (!openGroups.includes(i)) {
                                    rest.push(
                                        <ShowMoreRow
                                            key={`row-${key}-show-more`}
                                            count={data[key].length - 2}
                                            onClick={() => {
                                                this.setState({
                                                    openGroups: [
                                                        ...openGroups,
                                                        i,
                                                    ],
                                                })
                                            }}
                                        />
                                    )
                                } else {
                                    const restData = data[key].slice(
                                        2,
                                        data[key].length
                                    )
                                    rest = restData.map((item, i) => (
                                        <SuggestedRow
                                            key={`row-${key}-${i + 2}`}
                                            isSelected={
                                                `${key}-${i + 2}` === selected
                                            }
                                            {...item}
                                            onClick={() =>
                                                this.setSelected(key, i + 2)
                                            }
                                        />
                                    ))
                                    rest.push(
                                        <Row
                                            key={`row-${key}-spacer`}
                                            style={{ marginBottom: '31px' }}
                                        />
                                    )
                                }
                            }

                            return [...firstTwo, ...rest]
                        })}
                    </div>
                    {/* <Row className="button-container">
                    <span>
                        <V2Button
                            onClick={this.submitSelection}
                            disabled={!selected}
                        >
                            {t`payment_page.button.submit`}
                        </V2Button>
                    </span>
                    <span>
                        <V2Button
                            onClick={hideModal}
                            type="borderBtn"
                            style={{ color: '#36b56a', borderColor: '#36b56a' }}
                        >
                            {t`flight_suggest.none_of_above`}
                        </V2Button>
                    </span>
                </Row> */}
                </FlightsContainer>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal,
            logKibana,
        },
        dispatch
    )
}

export default connect(
    state => ({
        modal: state.toggle_modal,
    }),
    mapDispatchToProps
)(FlightSuggestModalCodeshares)
