import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { mobile } from '../helpers/styled_queries'

const StyledToolTip = styled(ReactTooltip)`
    background-color: ${({ color }) =>
        color === 'light' ? '#ffffff!important' : '#353d51 !important'};
    border-radius: 24px !important;
    color: ${({ color }) =>
        color === 'light' ? '#353d51!important' : '#ffffff!important'};
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    padding: 12px 16px;
    max-width: 450px;
    ${mobile`
        max-width: 250px;
    `}
    box-shadow: ${({ color }) =>
        color === 'light' ? '0 4px 9px 1px rgba(53, 61, 81, 0.23)' : 'none'};

    &.styled-tooltip {
        ${mobile`
            width: 90%;

            &:after {
                ${({ questionId }) =>
                    `left: ${
                        (
                            document.getElementById(questionId) || {
                                getBoundingClientRect: () => 200,
                            }
                        ).getBoundingClientRect().x
                    }px;`}
                bottom: -5px;
                margin-left: -12px;
                border-top-color: ${({ color }) =>
                    color === 'light'
                        ? 'transparent!important'
                        : '#495363!important;'};
            }
        `}
    }

    &.place-top {
        &:after {
            background: #353d51 !important;
            border: 0 !important;
            border-radius: 3px;
            width: 20px;
            height: 20px;
            bottom: -6px;
            margin-left: -8px;
            transform: rotate(45deg);
            top: calc(100% - 12px);
        }
    }

    &.place-right {
        &:after {
            border-right-color: ${({ color }) =>
                color === 'light'
                    ? 'transparent!important'
                    : '#353d51 !important'};
        }
    }

    &.place-left {
        &:after {
            border-left-color: ${({ color }) =>
                color === 'light'
                    ? 'transparent!important'
                    : '#353d51 !important'};
        }
    }

    &.__react_component_tooltip.place-bottom:after,
    &.__react_component_tooltip.place-top:after,
    &.__react_component_tooltip.place-right:after,
    &.__react_component_tooltip.place-left:after {
        border-bottom-color: ${({ color }) =>
            color === 'light' ? 'transparent!important' : '#353d51 !important'};
    }

    &.__react_component_tooltip {
        pointer-events: all;

        .customHover {
            &:hover {
                visibility: visible !important;
                opacity: 1 !important;
            }
        }
    }
    &.__react_component_tooltip.show {
        opacity: 1 !important;
    }

    &.__react_component_tooltip.place-top:before,
    &.__react_component_tooltip.place-top:after,
    &.__react_component_tooltip.type-info.place-top:after {
        content: ${({ color }) => (color === 'light' ? 'none' : '')};
    }
`

const StyledTooltipTitle = styled.div`
    font-size: 16px;
    line-height: 1.13;
    margin-bottom: 6px;
    color: #ffffff !important;
`

const StyledTooltipContent = styled.div`
    font-size: 11px;
    line-height: 1.64;
    color: #ffffff !important;
`

const StyledTooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #ffffff !important;
`

export const TooltipContainer = ({ children, props = {} }) => (
    <StyledTooltipContainer {...props}>{children}</StyledTooltipContainer>
)

export const TooltipTitle = ({ children, props = {} }) => (
    <StyledTooltipTitle {...props}>{children}</StyledTooltipTitle>
)

export const TooltipContent = ({ children, props = {} }) => (
    <StyledTooltipContent {...props}>{children}</StyledTooltipContent>
)

const ToolTip = props => (
    <StyledToolTip
        {...props}
        className={`styled-tooltip ${props.className || ''}`}
        place={window.innerWidth < 768 ? props.force || 'top' : props.place}
    />
)

export default ToolTip
