import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SuggestedText, { Text } from '../Form/SuggestedText'
import { Col } from '../V2/Layout'
import t from '../../translations'
import { handleMobileInputBlur, handleMobileInputFocus } from '../../../main'
import { ErrorIcon, QuestionMarkTooltip } from './V2Icons'
import ToolTip from './V2Tooltip'

const TooltipIconWrapper = styled.span`
    background: #ecf7f1;
    padding: 4px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
`

const TooltipIconWrapperCustom = styled.span`
    display: flex;
    align-items: center;
`

const InputCol = styled(Col)`
    position: relative;
    input {
        border: ${({ hasDanger }) => hasDanger && '1px solid #FE6050'};

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    label {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 600;

        font-style: normal;
        font-stretch: normal;
        line-height: 22.39px;
        color: #717171;
        letter-spacing: normal;
        margin-bottom: 16px;
        display: flex;

        span {
            &.required {
                margin-left: 3px;
                color: #fe6050;
            }
        }
    }
`

const borderWidth = '1px'

const Input = styled.input`
    display: relative;
    border-radius: ${({ borderRadius }) => borderRadius || ' 12px'};
    width: 100%;
    height: 56px;
    font-size: 16px;
    background: #fafafa !important;
    font-family: 'Noto Sans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    line-height: 1.71;
    letter-spacing: normal;
    color: #333333;
    padding: 12px 16px;
    ${({ borderColor }) =>
        borderColor
            ? `border: 1px solid ${borderColor};`
            : 'border: 1px solid #717171;'}
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    ${({ StatusIcon }) => (StatusIcon ? 'padding-right: 32px;' : '')}

    &:hover,
    &:active,
    &:focus {
        border: ${borderWidth} solid ${({ theme }) => theme.colors.primaryColor};
        box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
    }

    &:disabled,
    &.disabled {
        background: #f2f5f9;
        color: #7f8fa4;
        border: ${borderWidth} solid #f2f5f9;
        font-weight: 600;
        opacity: 1;
        -webkit-text-fill-color: #7f8fa4;

        &:hover {
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &.warn {
        border: ${borderWidth} solid #fe6050;
    }

    &.danger {
        border: ${borderWidth} solid #fe6050;
    }

    &::placeholder {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;

        color: #717171;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

export const ErrorTextBox = styled.div`
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;

    margin: 0;
    padding-top: 16px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #fe6050;

    &.warn {
        color: #fe6050;
    }
`

const InputField = ({
    errorText,
    warning,
    hasDanger,
    label,
    LabelComponent,
    type,
    className,
    suggestedText,
    isValidSmtp,
    required,
    onChange,
    disabled,
    onFocus = () => null,
    onBlur = () => null,
    lg = 6,
    sm = 8,
    xs = 12,
    tooltip,
    StatusIcon,
    inlineColStyle = {},
    borderColor = null,
    borderRadius,
    customTooltip,
    errorIconVisible = true,
    forceErrorIconVisible = false,
    ...props
}) => (
    <InputCol
        hasDanger={!!errorText || hasDanger}
        className={className}
        style={inlineColStyle}
        xs={xs}
        sm={sm}
        lg={lg}
    >
        {label && (
            <label>
                {label}
                {required ? <span className="required"> *</span> : ''}
                {customTooltip && customTooltip}
                {tooltip && (
                    <div
                        style={{
                            display: 'inline-flex',
                            marginLeft: '8px',
                            verticalAlign: 'middle',
                        }}
                    >
                        {tooltip.tooltipIcon ? (
                            <TooltipIconWrapperCustom
                                data-tip={tooltip.dataTip}
                                data-for={tooltip.dataFor}
                                id={tooltip.questionId}
                            >
                                {tooltip.tooltipIcon}
                            </TooltipIconWrapperCustom>
                        ) : (
                            <TooltipIconWrapper
                                data-tip={tooltip.dataTip}
                                data-for={tooltip.dataFor}
                                id={tooltip.questionId || 'question-mark'}
                            >
                                <QuestionMarkTooltip />
                            </TooltipIconWrapper>
                        )}
                        <ToolTip {...tooltip}>
                            <span>
                                {tooltip.children ? (
                                    <tooltip.children />
                                ) : (
                                    tooltip.text
                                )}
                            </span>
                        </ToolTip>
                    </div>
                )}
            </label>
        )}
        {LabelComponent && <LabelComponent />}
        <Input
            borderRadius={borderRadius}
            borderColor={borderColor}
            onChange={disabled ? () => null : onChange}
            className={errorText && warning && 'warn'}
            type={type}
            disabled={disabled}
            onFocus={e => {
                e.preventDefault()
                handleMobileInputFocus()
                onFocus(e)
            }}
            onBlur={e => {
                e.preventDefault()
                handleMobileInputBlur()
                onBlur(e)
            }}
            StatusIcon={!!StatusIcon}
            {...props}
        />
        {!!StatusIcon && (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '24px',
                    transform: 'translateY(-8px)',
                }}
            >
                <StatusIcon />
            </div>
        )}
        {!!errorText ||
            (hasDanger && errorIconVisible && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '24px',
                        transform: 'translateY(-2px)',
                    }}
                >
                    <ErrorIcon />
                </div>
            ))}
        {errorText && (
            <ErrorTextBox className={warning && 'warn'}>
                {errorText}
            </ErrorTextBox>
        )}
        {forceErrorIconVisible && errorText && (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '24px',
                    transform: 'translateY(-6px)',
                }}
            >
                <ErrorIcon />
            </div>
        )}
        {suggestedText && (
            <SuggestedText
                text={!errorText && suggestedText.text}
                callback={suggestedText.callback}
            />
        )}
        {!isValidSmtp && !errorText && suggestedText && !suggestedText.text && (
            <Text>{t`common.label.could_not_verify_email`}</Text>
        )}
    </InputCol>
)

InputField.propTypes = {
    readOnly: PropTypes.bool,
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    label: PropTypes.string,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    suggestedText: PropTypes.shape({
        text: PropTypes.string,
        callback: PropTypes.func,
    }),
}

InputField.defaultProps = {
    errorText: '',
    label: '',
    type: 'text',
    className: '',
    readOnly: false,
    maxLength: 255,
}

export default InputField
