import { V2_ACTIONS } from '../../actions/actionTypes'
import { V2_CORE, V2_prePath } from '../../constants'
import _get from 'lodash/get'

export const mapSteps = [
    V2_CORE.STEP_1.path,
    V2_CORE.STEP_2.path,
    V2_CORE.STEP_3.path,
    V2_CORE.STEP_4.path,
    V2_CORE.STEP_5.path,
    V2_CORE.STEP_6.path,
    V2_CORE.STEP_7.path,
    V2_CORE.STEP_8.path,
]

function INITIAL_STATE(path) {
    let currLocation = ''

    if (_get(window, 'location.pathname', false)) {
        currLocation = window.location.pathname
    }

    if (path) {
        currLocation = path
    }

    const replaceTrailingSlash =
        currLocation.charAt(currLocation.length - 1) === '/'
            ? currLocation.slice(0, currLocation.length - 1)
            : currLocation

    const findStep = mapSteps.indexOf(replaceTrailingSlash)
    const initStepIndex = findStep !== -1 ? findStep : 0
    const nextStepIndex =
        initStepIndex < mapSteps.length - 1 ? initStepIndex + 1 : initStepIndex
    const prevStepIndex = initStepIndex > 0 ? initStepIndex - 1 : 0

    return {
        current: mapSteps[initStepIndex],
        next: mapSteps[nextStepIndex],
        prev: mapSteps[prevStepIndex],
        stepIndex: initStepIndex,
        latestStepIndex: initStepIndex,
    }
}

export default function(state = INITIAL_STATE(), action) {
    const { type, payload } = action
    const max = mapSteps.length - 1

    if (payload < 0 || payload > max) {
        return state
    }

    const latestStepIndex =
        payload < max && state.latestStepIndex <= payload
            ? payload
            : state.latestStepIndex
    const initialCurrIndex = payload < 0 ? 0 : payload > max ? max : payload
    const initialMinIndex = payload - 1 < 0 ? 0 : payload - 1
    const initialMaxIndex = payload + 1 > max ? max : payload + 1

    switch (type) {
        case V2_ACTIONS.STEP_STATE:
            return {
                current: mapSteps[initialCurrIndex],
                next: mapSteps[initialMaxIndex],
                prev: mapSteps[initialMinIndex],
                stepIndex: initialCurrIndex,
                latestStepIndex,
            }
        case V2_ACTIONS.STEP_RESET:
            return INITIAL_STATE(V2_prePath)
        default:
    }
    return state
}
