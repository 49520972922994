import { REASON_COEFFICIENTS } from '../actions/actionTypes';
import { reasonCoefficients } from '../constants';

export default function (state = reasonCoefficients, action) {
    switch (action.type) {
        case REASON_COEFFICIENTS:
            return { ...reasonCoefficients, ...action.payload };
        default:
    }
    return state;
}