import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Slider from './components/Slider'
import SideInfo from './components/SideInfo'
import AdvancedSettings from './components/AdvancedSettings'
import { getCookie } from '../../main'
import { BorderButton } from '../../shared/components/Buttons/Button'
import { CloseIcon2 } from '../../shared/components/V2/V2Icons'
import t from '../../shared/translations'
import {
    tablet,
    desktop,
    mobile,
} from '../../shared/components/helpers/styled_queries'
import { V2Button } from '../../shared/components/V2/V2Buttons'

const PopUpContainer = styled.div`
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
`

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
`

const InfoContainer = styled.div`
    position: relative;
    box-shadow: 1px 1px 15px #000;
    max-height: 84vh;
    width: 70%;
    border-radius: 24px;
    padding: 64px 40px;
    height: 100%;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    color: #000000;
    font-family: sans-serif !important;
    @media (max-width: 768px) {
        width: 95%;
        min-height: 80%;
    }

    ${mobile`
        padding: 30px 10px;
        width: 100%;
    `}
`

const CloseDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const SliderContainer = styled.div`
    width: 180px;
    transform: rotate(90deg);
    left: -60px;
    top: 78px;
    position: absolute;
    z-index: 100;
`

const SliderInfoContainer = styled.div`
    display: block;
    padding-left: 40px;
    padding-right: 10px;
    border-right: 2px solid gray;
    height: 100%;
    padding-top: 3px;

    span {
        display: block;
        padding-bottom: 5px;
        line-height: 11pt;
        font-size: 11px;
    }

    span:first-child {
        font-size: 13px;
        font-weight: bold;
    }

    @media (max-width: 992px) {
        border: 0 none;
    }
`

const Title = styled.div`
    color: #000000;
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;

    ${mobile`
          font-size: 24px;
        `}
`

const SliderItem = styled.div`
    height: 80px;
    color: ${({ active }) => (active ? 'black' : 'darkgray')};
    cursor: pointer;

    &:hover {
        filter: brightness(105%);
    }
`

const LandingInfo = styled.div``

const BasicContainer = styled.div`
    position: relative;
`

const ButtonContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    ${mobile`
        flex-direction: column;
        `}
`

const TitleDescription = styled.div`
    padding: 10px 0px;
    margin-bottom: 5px;
    color: #717171;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
`

const LandingDescription = styled.div`
    font-size: 12px;
    width: 100%;
    display: block;
    padding: 0px 10px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.7);
`

const CloseIconContainer = styled.div`
    z-index: 9999;
    top: 30px;
    right: 62px;
    position: absolute;

    &:hover {
        cursor: pointer;
    }

    ${mobile`
            right: 36px;
        `}
`

const PrivacyButton = styled.a`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    color: #149954;
    text-decoration: underline;

    text-align: left;
    cursor: pointer;

    &:hover {
        filter: brightness(110%);
        text-decoration: none;
    }
    :first-child {
        margin-right: 24px;
    }
`

const PrivacyPolicyBtnWrapper = styled.div`
    margin-top: 24px;
`

const StyledDashedLine = styled.div`
    background-image: linear-gradient(
        to right,
        #717171 40%,
        rgb(113, 113, 113, 0) 0%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;

    height: 1px;
    width: 100%;

    margin: 24px 0px;
`

const V2ButtonStyledAccept = styled(V2Button)`
    border-radius: 16px;
    font-family: 'Noto sans';
    width: fit-content;
    color: #ffffff;
    background: #149954;

    :hover {
        color: #ffffff;
    }

    ${mobile`
       width: 100%;
        `}
`

const V2ButtonStyledBorder = styled(V2Button)`
    border-radius: 16px;
    font-family: 'Noto sans';
    width: fit-content;
    color: #149954;
    margin-right: 12px;
    border-color: #149954;

    :hover {
        color: #149954;
        border-color: #149954;
    }

    ${mobile`
        margin-bottom: 6px;
       width: 100%;
       margin-right: unset;
        `}
`

const Header = styled.div`
    padding: 24px;
    width: 100%;
    position: relative;

    span {
        text-align: left;
        padding-right: 34px;
    }
`

const Content = styled.div`
    padding: 10px 20px 0px;
    height: calc(100% - 137px);
    overflow-y: scroll;
    scrollbar-color: #149954 #149954;

    ${tablet`
        height: calc(100% - 98px);
    `}

    ${desktop`
        height: calc(100% - 98px);
    `}
`

class CookieSettings extends Component {
    constructor(props) {
        super(props)

        let cookiesArr = []
        if (getCookie('catAccCookiesCats')) {
            cookiesArr = getCookie('catAccCookiesCats').split(',')
        }

        const hasSettings = cookiesArr.length > 0

        this.state = {
            showBasic: false,
            showAdvanced: true,
            sliderVal: hasSettings ? cookiesArr.length : 3,
            requiredChecked: true,
            functionalChecked: hasSettings ? cookiesArr.length > 1 : false,
            advertisingChecked: hasSettings ? cookiesArr.length > 2 : false,
        }
    }

    _cookieFunctionality = () => {
        return [
            {
                category: 1,
                cookies: [
                    t`cookies.shared.info.required_cookies.functionality.func_1`,
                    t`cookies.shared.info.required_cookies.functionality.func_2`,
                    t`cookies.shared.info.required_cookies.functionality.func_3`,
                    t`cookies.shared.info.required_cookies.functionality.func_4`,
                    t`cookies.shared.info.required_cookies.functionality.func_5`,
                ],
            },
            {
                category: 2,
                cookies: [
                    t`cookies.shared.info.functional_cookies.functionality.func_1`,
                    t`cookies.shared.info.functional_cookies.functionality.func_2`,
                    t`cookies.shared.info.functional_cookies.functionality.func_3`,
                ],
            },
            {
                category: 3,
                cookies: [
                    t`cookies.shared.info.advertising_cookies.functionality.func_1`,
                    t`cookies.shared.info.advertising_cookies.functionality.func_2`,
                    t`cookies.shared.info.advertising_cookies.functionality.func_3`,
                ],
            },
        ]
    }

    _cookieInfo = () => {
        return [
            {
                title: t`cookies.shared.info.required_cookies.title`,
                description: t`cookies.shared.info.required_cookies.description`,
                id: 1,
                advancedState: this.state.requiredChecked,
                name: 'requiredChecked',
                details: [
                    {
                        title: t`cookies.shared.info.required_cookies.details.skycop.title`,
                        info: t`cookies.shared.info.required_cookies.details.skycop.description`,
                    },
                    {
                        title: t`cookies.shared.info.required_cookies.details.idev.title`,
                        info: t`cookies.shared.info.required_cookies.details.idev.description`,
                    },
                    {
                        title: t`cookies.shared.info.required_cookies.details.wordpress.title`,
                        info: t`cookies.shared.info.required_cookies.details.wordpress.description`,
                    },
                    {
                        title: t`cookies.shared.info.required_cookies.details.zopim.title`,
                        info: t`cookies.shared.info.required_cookies.details.zopim.description`,
                    },
                    {
                        title: t`cookies.shared.info.required_cookies.details.cloudflare.title`,
                        info: t`cookies.shared.info.required_cookies.details.cloudflare.description`,
                    },
                    {
                        title: t`cookies.shared.info.required_cookies.details.google_analytics.title`,
                        info: t`cookies.shared.info.required_cookies.details.google_analytics.description`,
                    },
                    {
                        title: t`cookies.shared.info.required_cookies.details.facebook.title`,
                        info: t`cookies.shared.info.required_cookies.details.facebook.description`,
                    },
                    {
                        title: t`cookies.shared.info.required_cookies.details.google.title`,
                        info: t`cookies.shared.info.required_cookies.details.google.description`,
                    },
                ],
            },
            {
                title: t`cookies.shared.info.functional_cookies.title`,
                description: t`cookies.shared.info.functional_cookies.description`,
                id: 2,
                advancedState: this.state.functionalChecked,
                name: 'functionalChecked',
                details: [
                    {
                        title: t`cookies.shared.info.functional_cookies.details.youtube.title`,
                        info: t`cookies.shared.info.functional_cookies.details.youtube.description`,
                    },
                    {
                        title: t`cookies.shared.info.functional_cookies.details.twitter.title`,
                        info: t`cookies.shared.info.functional_cookies.details.twitter.description`,
                    },
                    {
                        title: t`cookies.shared.info.functional_cookies.details.hotjar.title`,
                        info: t`cookies.shared.info.functional_cookies.details.hotjar.description`,
                    },
                    {
                        title: t`cookies.shared.info.functional_cookies.details.yandex.title`,
                        info: t`cookies.shared.info.functional_cookies.details.yandex.description`,
                    },
                    {
                        title: t`cookies.shared.info.functional_cookies.details.google_maps.title`,
                        info: t`cookies.shared.info.functional_cookies.details.google_maps.description`,
                    },
                    {
                        title: t`cookies.shared.info.functional_cookies.details.google_optimize.title`,
                        info: t`cookies.shared.info.functional_cookies.details.google_optimize.description`,
                    },
                ],
            },
            {
                title: t`cookies.shared.info.advertising_cookies.title`,
                description: t`cookies.shared.info.advertising_cookies.description`,
                id: 3,
                advancedState: this.state.advertisingChecked,
                name: 'advertisingChecked',
                details: [
                    {
                        title: t`cookies.shared.info.advertising_cookies.details.doubleclick.title`,
                        info: t`cookies.shared.info.advertising_cookies.details.doubleclick.description`,
                    },
                    {
                        title: t`cookies.shared.info.advertising_cookies.details.microsoft_advertising.title`,
                        info: t`cookies.shared.info.advertising_cookies.details.microsoft_advertising.description`,
                    },
                    {
                        title: t`cookies.shared.info.advertising_cookies.details.yandex.title`,
                        info: t`cookies.shared.info.advertising_cookies.details.yandex.description`,
                    },
                    {
                        title: t`cookies.shared.info.advertising_cookies.details.twitter.title`,
                        info: t`cookies.shared.info.advertising_cookies.details.twitter.description`,
                    },
                    {
                        title: t`cookies.shared.info.advertising_cookies.details.facebook.title`,
                        info: t`cookies.shared.info.advertising_cookies.details.facebook.description`,
                    },
                    {
                        title: t`cookies.shared.info.advertising_cookies.details.google_tag_manager.title`,
                        info: t`cookies.shared.info.advertising_cookies.details.google_tag_manager.description`,
                    },
                ],
            },
        ]
    }

    _toggleCheckBox = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] })
        if (
            e.target.name === 'advertisingChecked' &&
            !this.state.advertisingChecked &&
            !this.state.functionalChecked
        ) {
            this.setState({ functionalChecked: true })
        }
        if (
            e.target.name === 'functionalChecked' &&
            this.state.functionalChecked &&
            this.state.advertisingChecked
        ) {
            this.setState({ advertisingChecked: false })
        }
    }

    _toggleAdvanced = e => {
        const { showAdvanced, showBasic } = this.state
        this.setState({
            showAdvanced: !showAdvanced,
            showBasic: !showBasic,
        })
    }

    _toggleBasic = e => {
        const { showBasic } = this.state
        this.setState({ showBasic: !showBasic })
    }

    _cancelClick = e => {
        this.setState({
            showBasic: false,
            showAdvanced: false,
        })
    }

    _sliderChange = e => {
        this.setState({ sliderVal: parseInt(e.target.value, 10) })
    }

    _handleSubmit = () => {
        const { handleSubmit } = this.props
        const {
            showBasic,
            showAdvanced,
            sliderVal,
            functionalChecked,
            advertisingChecked,
        } = this.state
        let levels = [1]
        if (showBasic) {
            if (sliderVal === 2) {
                levels = [1, 2]
            }
            if (sliderVal === 3) {
                levels = [1, 2, 3]
            }
        } else {
            if (functionalChecked) {
                levels = [1, 2]
            }
            if (advertisingChecked) {
                levels = [1, 2, 3]
            }
        }

        let settings = 'none'
        if (showBasic) {
            settings = 'basic'
        } else if (showAdvanced) {
            settings = 'advanced'
        }

        handleSubmit(levels, settings)
    }

    render() {
        const { sliderVal, showAdvanced, showBasic } = this.state
        const { handleSubmit } = this.props
        return (
            <PopUpContainer>
                <ContentWrapper>
                    <CloseDiv onClick={() => this.props.closeModal()} />
                    <InfoContainer>
                        <CloseIconContainer
                            onClick={() => this.props.closeModal()}
                        >
                            <CloseIcon2 width={32} height={32} />
                        </CloseIconContainer>
                        <Header>
                            <Title>{t`cookies.popup.header`}</Title>
                        </Header>
                        <Content>
                            {!showAdvanced && !showBasic && (
                                <LandingInfo>
                                    <LandingDescription>
                                        {t`cookies.popup.cookies_explained`}
                                        <br />
                                        <br />
                                        {t`cookies.popup.actions_explained`}
                                    </LandingDescription>
                                    <ButtonContainer>
                                        <BorderButton
                                            background={({ theme }) =>
                                                theme.colors.primaryColor
                                            }
                                            color="white"
                                            onClick={() =>
                                                handleSubmit([1, 2, 3], 'none')
                                            }
                                        >
                                            {t`cookies.shared.label.accept_cookies`}
                                        </BorderButton>
                                        <BorderButton
                                            background="white"
                                            color={({ theme }) =>
                                                theme.colors.primaryColor
                                            }
                                            onClick={this._toggleBasic}
                                        >
                                            {t`cookies.shared.label.view_cookies_settings`}
                                        </BorderButton>
                                    </ButtonContainer>
                                </LandingInfo>
                            )}
                            {(showAdvanced || showBasic) && (
                                <TitleDescription>
                                    {t`cookies.settings.basic.description`}
                                </TitleDescription>
                            )}
                            {!showAdvanced && showBasic && (
                                <BasicContainer className="row">
                                    <SliderContainer>
                                        <Slider
                                            onChange={this._sliderChange}
                                            value={sliderVal}
                                        />
                                    </SliderContainer>
                                    <div className="col-lg-16">
                                        <SliderInfoContainer>
                                            {this._cookieInfo().map(
                                                (
                                                    { title, description, id },
                                                    index
                                                ) => (
                                                    <SliderItem
                                                        key={title}
                                                        active={
                                                            sliderVal >=
                                                            index + 1
                                                        }
                                                        onClick={e =>
                                                            this.setState({
                                                                sliderVal: id,
                                                            })
                                                        }
                                                    >
                                                        <span>{title}</span>
                                                        <span>
                                                            {description}
                                                        </span>
                                                    </SliderItem>
                                                )
                                            )}
                                        </SliderInfoContainer>
                                    </div>
                                    <div className="col-lg-8">
                                        <SideInfo
                                            cookies={this._cookieFunctionality()}
                                            value={sliderVal}
                                        />
                                    </div>
                                </BasicContainer>
                            )}
                            {showAdvanced && !showBasic && (
                                <div>
                                    <AdvancedSettings
                                        onChange={this._toggleCheckBox}
                                        cookies={this._cookieInfo()}
                                    />
                                </div>
                            )}
                            <StyledDashedLine />
                            {(showAdvanced || showBasic) && (
                                <ButtonContainer>
                                    <V2ButtonStyledBorder
                                        type="borderBtn"
                                        onClick={() => this.props.closeModal()}
                                    >
                                        {t`common.label.cancel`}
                                    </V2ButtonStyledBorder>
                                    <V2ButtonStyledAccept
                                        onClick={() => this._handleSubmit()}
                                    >
                                        {t`cookies.shared.label.accept`}
                                    </V2ButtonStyledAccept>
                                </ButtonContainer>
                            )}
                            <PrivacyPolicyBtnWrapper>
                                <PrivacyButton
                                    target="_blank"
                                    href={t`cookies.shared.privacy_policy.url`}
                                >
                                    {t`cookies.shared.privacy_policy.title`}
                                </PrivacyButton>
                                <PrivacyButton
                                    target="_blank"
                                    href={t`terms.url`}
                                >
                                    {t`terms.link_text`}
                                </PrivacyButton>
                            </PrivacyPolicyBtnWrapper>
                        </Content>
                    </InfoContainer>
                </ContentWrapper>
            </PopUpContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(CookieSettings)
