import React from 'react'
import PropTypes from 'prop-types'
import { Link, IndexLink } from 'react-router'
import styled from 'styled-components'

const NavbarList = styled.ul`
    box-sizing: border-box;
    flex: 1;
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    min-height: 72px;
    text-align: center;

    @media (max-width: 990.5px) {
        display: none;
    }
`

const NavbarLink = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    min-height: 72px;
    color: #666;
    font-size: 1.4rem;
    line-height: 1.86;
    white-space: nowrap;

    a {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #101b35;
        text-decoration: none;
        padding: 0 16px;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        margin-bottom: -2px;
        justify-content: center;
        align-items: center;
    }

    a.active + span {
        width: 50%;
        border-bottom: 0.2rem solid ${({ theme }) => theme.colors.primaryColor};
    }

    a:hover {
        color: ${({ theme }) => theme.colors.secondaryColor};
    }

    span {
        border-bottom: 0.2rem solid transparent;
    }

    &:hover {
        span {
            width: 50%;
            border-bottom: 0.2rem solid
                ${({ theme, signatureConfigs }) =>
                    signatureConfigs && signatureConfigs.menuItemColor
                        ? signatureConfigs.menuItemColor
                        : theme.colors.primaryColor};
        }
    }
`

const activeClassName = 'active'

const StyledIndexLink = styled(IndexLink).attrs({
    activeClassName: activeClassName,
})`
    &.${activeClassName} {
        color: ${({ theme, signatureConfigs }) =>
            signatureConfigs && signatureConfigs.menuItemColor
                ? signatureConfigs.menuItemColor
                : theme.colors.secondaryColor};
    }
`

const StyledLink = styled(Link).attrs({
    activeClassName: activeClassName,
})`
    &.${activeClassName} {
        color: ${({ theme }) => theme.colors.secondaryColor};
    }

    &:hover {
        color: ${({ theme }) => theme.colors.secondaryColor};
    }
`

const NavMenu = ({ navLinks, signatureConfigs }) => {
    const mappedLinks = navLinks
        .filter(link => !link.hidden)
        .map((link, index) => {
            if (link.index) {
                return (
                    <NavbarLink
                        signatureConfigs={signatureConfigs}
                        key={index}
                        onClick={link.onClick}
                    >
                        <StyledIndexLink
                            style={{
                                color:
                                    signatureConfigs &&
                                    signatureConfigs.menuItemColor &&
                                    signatureConfigs.menuItemColor,
                            }}
                            to={link.path}
                        >
                            {link.name}
                        </StyledIndexLink>
                        <span></span>
                    </NavbarLink>
                )
            } else {
                return (
                    <NavbarLink signatureConfigs={signatureConfigs} key={index}>
                        <StyledLink
                            style={{
                                color:
                                    signatureConfigs &&
                                    signatureConfigs.menuItemColor,
                            }}
                            to={link.path}
                        >
                            {link.name}
                        </StyledLink>
                        <span></span>
                    </NavbarLink>
                )
            }
        })

    return <NavbarList id="menu-bar">{mappedLinks}</NavbarList>
}

NavMenu.propTypes = {
    navLinks: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.bool,
            path: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            onClick: PropTypes.func,
        })
    ),
}

NavMenu.defaultProps = {
    navLinks: PropTypes.arrayOf(
        PropTypes.shape({
            index: false,
            onClick: () => null,
        })
    ),
}

export default NavMenu
