import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import isArray from 'lodash/isArray'

import {
    tablet,
    mobile,
} from '../../../../shared/components/helpers/styled_queries'
import styled from 'styled-components'
import {
    V2Button,
    TransparentBtn,
} from '../../../../shared/components/V2/V2Buttons'
import t from '../../../../shared/translations'
import { paths } from '../../../..//shared/constants.js'
import {
    getApiClient,
    loadScreenOn,
    loadScreenOff,
    getStorage,
} from '../../../../main'
import {
    setSmartDelayFlightDetails,
    setSmartDelayPassengerDetails,
    setSmartDelayFlightDetailsErrors,
    setSmartDelayFlightDetailsRegisteredFlightIds,
} from '../../../../shared/actions/v2_actions'

const NavigatorContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;

    margin-left: 12px;

    ${mobile`
    font-size: 26px;
    margin-left: 18px;
`}

    ${tablet`
    font-size: 26px;
    margin-left: unset;
`}
`

const LabelWrapper = styled.span`
    white-space: nowrap;
`

const StyledV2Button = styled(V2Button)`
    min-width: unset;
`

const StyledTransparentBtn = styled(TransparentBtn)`
    color: #525f7f;
    ${mobile`
    margin-left: 22px;
`}
`

class SmartDelayHandler extends React.Component {
    componentDidMount() {
        const { flightDetailsData, passengerDetailsData } = this.props

        switch (browserHistory.getCurrentLocation().pathname) {
            case paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS: {
                if (
                    this.isObjectEmpty(flightDetailsData) ||
                    !!this.isFieldsValid(flightDetailsData)
                ) {
                    browserHistory.push(paths.V2_SMART_DELAY.STEP_SMART_DELAY)
                }
                break
            }
            case paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE: {
                if (
                    this.isObjectEmpty(flightDetailsData) ||
                    this.isObjectEmpty(passengerDetailsData)
                ) {
                    browserHistory.push(paths.V2_SMART_DELAY.STEP_SMART_DELAY)
                }
                break
            }
            default:
                return
        }
    }

    isObjectEmpty = obj =>
        Object.keys(obj).some(key => values(obj[key]).some(isEmpty))

    onCancelClick = () => {
        switch (browserHistory.getCurrentLocation().pathname) {
            case paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS: {
                browserHistory.push(paths.V2_SMART_DELAY.STEP_SMART_DELAY)
                break
            }
            case paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS: {
                browserHistory.push(paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS)
                break
            }
            case paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE: {
                browserHistory.push(
                    paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS
                )
                break
            }
            default:
                return
        }
    }

    onNextClick = () => {
        switch (browserHistory.getCurrentLocation().pathname) {
            case paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS: {
                this.handleFlightDetailsNext()
                break
            }
            case paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS: {
                this.handlePassengerDetailsNext()
                break
            }
            case paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE: {
                this.handleConfirmationNext()
                break
            }
            default:
                return
        }
    }

    handleFlightDetailsNext = () => {
        const {
            flightDetails,
            setSmartDelayFlightDetailsErrors,
            setSmartDelayFlightDetails,
        } = this.props
        setSmartDelayFlightDetails(flightDetails)
        const apiClient = getApiClient()

        const postData = Object.keys(flightDetails).reduce((res, curr) => {
            const currFlight = flightDetails[curr]
            res.push({
                flightNumber: currFlight.flightNumber,
                departureDate: currFlight.flightDate,
                airline: currFlight.airline,
                departureAirport: currFlight.departureAirport,
                arrivalAirport: currFlight.arrivalAirport,
            })
            return res
        }, [])

        const authString = `Bearer ${getStorage('token')}`
        const url = '/api/account/smart-delay-validate-flights'

        loadScreenOn()
        apiClient
            .post(
                url,
                { flights: postData },
                { headers: { Authorization: authString } }
            )
            .then(() => {
                browserHistory.push(
                    paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS
                )
                setSmartDelayFlightDetailsErrors({})
            })
            .catch(error => {
                if (error && error.response && error.response.data) {
                    const errorsMap =
                        isArray(error.response.data) &&
                        error.response.data.reduce((res, cur, i) => {
                            return {
                                ...res,
                                [i + 1]: cur.error_code,
                            }
                        }, {})
                    setSmartDelayFlightDetailsErrors(errorsMap)
                }
            })
            .finally(() => {
                loadScreenOff()
            })
    }

    handlePassengerDetailsNext = () => {
        const { passengerDetails, setSmartDelayPassengerDetails } = this.props
        setSmartDelayPassengerDetails(passengerDetails)
        browserHistory.push(paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE)
    }

    handleConfirmationNext = () => {
        const {
            passengerDetailsData,
            flightDetailsData,
            setSmartDelayFlightDetailsErrors,
            setSmartDelayFlightDetailsRegisteredFlightIds,
        } = this.props

        const apiClient = getApiClient()

        const authString = `Bearer ${getStorage('token')}`
        const url = '/api/account/smart-delay-flight'

        const postFlightsData = Object.keys(flightDetailsData).reduce(
            (res, curr) => {
                const currFlight = flightDetailsData[curr]
                res.push({
                    flightNumber: currFlight.flightNumber,
                    departureAirport: currFlight.departureAirport,
                    arrivalAirport: currFlight.arrivalAirport,
                    departureDate: currFlight.flightDate,
                    airline: currFlight.airline,
                })
                return res
            },
            []
        )

        const postPassengersData = Object.keys(passengerDetailsData).reduce(
            (res, curr) => {
                const currPassenger = passengerDetailsData[curr]

                res.push({
                    title: currPassenger.salutation,
                    firstName: currPassenger.name,
                    lastName: currPassenger.surname,
                    email: currPassenger.email,
                    mobileNumber: currPassenger.phone,
                    phoneCode: currPassenger.phoneCode,
                    type: curr === '1' ? 'main' : 'additional',
                })
                return res
            },
            []
        )

        loadScreenOn()
        apiClient
            .post(
                url,
                { flights: postFlightsData, passengers: postPassengersData },
                { headers: { Authorization: authString } }
            )
            .then(({ data }) => {
                browserHistory.push(
                    paths.V2_SMART_DELAY.STEP_4_REGISTRATION_COMPLETED
                )
                setSmartDelayFlightDetailsErrors({})
                setSmartDelayFlightDetailsRegisteredFlightIds(data)
            })
            .catch(error => {
                console.log('error', error)
                if (error && error.response && error.response.data) {
                    const errorsMap =
                        isArray(error.response.data) &&
                        error.response.data.reduce((res, cur, i) => {
                            return {
                                ...res,
                                [i + 1]: cur.error_code,
                            }
                        }, {})
                    setSmartDelayFlightDetailsErrors(errorsMap)
                }
            })
            .finally(() => {
                loadScreenOff()
            })
    }

    isNextBtnDisabled = () => {
        const { flightDetails, passengerDetails } = this.props

        switch (browserHistory.getCurrentLocation().pathname) {
            case paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS: {
                return (
                    this.isObjectEmpty(flightDetails) ||
                    !this.isFieldsValid(flightDetails)
                )
            }
            case paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS: {
                return this.isObjectEmpty(passengerDetails)
            }
            default:
        }
    }

    isFieldsValid = flightDetails => {
        switch (browserHistory.getCurrentLocation().pathname) {
            case paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS: {
                return Object.keys(flightDetails).every(key =>
                    /^(?=.{3,6}$)[A-Za-z]{1,2}\s*\d{3,5}$/.test(
                        flightDetails[key].flightNumber
                    )
                )
            }
            default:
        }
    }

    render() {
        const { isDisabledNext } = this.props
        return (
            <NavigatorContainer>
                <StyledV2Button
                    id="btn-next"
                    onClick={() => this.onNextClick()}
                    disabled={this.isNextBtnDisabled() || isDisabledNext}
                >
                    <LabelWrapper>
                        {browserHistory.getCurrentLocation().pathname ===
                        paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE
                            ? t`smart_delay.confirmation_page_confirm_button`
                            : t`smart_delay.flight_details_next_btn`}
                    </LabelWrapper>
                </StyledV2Button>
                <StyledTransparentBtn
                    id="btn-back"
                    onClick={() => this.onCancelClick()}
                >
                    {browserHistory.getCurrentLocation().pathname !==
                    paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS
                        ? t`smart_delay.flight_details_back_btn`
                        : t`smart_delay.flight_details_cancel_btn`}
                </StyledTransparentBtn>
            </NavigatorContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        passengerDetailsData: state.smartDelay.passengerDetails,
        flightDetailsData: state.smartDelay.flightDetails,
        flightDetailsErrors: state.smartDelay.flightDetailsErrors,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSmartDelayFlightDetails,
            setSmartDelayPassengerDetails,
            setSmartDelayFlightDetailsErrors,
            setSmartDelayFlightDetailsRegisteredFlightIds,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(SmartDelayHandler)
