import React from 'react';
import styled from 'styled-components';

const OptionsContainer = styled.div`
    padding: ${({ padding }) => !!padding ? padding : '5px 0px'};

    em {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.secondaryColor};
        font-style: normal;
    }
`;

const OptionsHeader = styled.div`
    line-height: 1.2 !important;
    margin-bottom: 5px;
`;

const OptionsElement = styled.div`
    font-size: 1.3rem !important;
    line-height: 1 !important;
    color: #999;

`;

const SubtitleElement = styled.div`
    padding: 3px 0px !important;
    font-size: 1.2rem !important;
    line-height: 1 !important;
    color: #242424 !important;
`;

const SelectFieldOptions = ({ optionsTopLabel, optionsBottomLabel, grayedOutLabel }) => (
    <OptionsContainer padding={!!grayedOutLabel ? '2px 0px' : null}>
        {
            grayedOutLabel && <SubtitleElement/>
        }
        {!grayedOutLabel && <OptionsHeader dangerouslySetInnerHTML={{ __html: `${optionsTopLabel}` }} />}
        {!grayedOutLabel && <OptionsElement dangerouslySetInnerHTML={{ __html: `${optionsBottomLabel}` }} />}
    </OptionsContainer>
)

export default SelectFieldOptions;
