import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { injectGlobal, ThemeProvider } from 'styled-components'
import _get from 'lodash/get'
import NavBar from './shared/components/Navbar/'
import Footer from './shared/components/footer'
import Loader from './shared/components/loader'
import { logCookiePreference } from './shared/actions/index'
import {
    getCookie,
    setCookie,
    deleteCookie,
    loadScreenOff,
    isMobileDevice,
    isProduction,
} from './main'
import { setLeavingModalClaimId, showModal } from './shared/actions/v2_actions'
import Cookies from './views/cookies/Cookies'
import { deleteCookies, level2List, level3List } from './cookieList'
import CookieSettings from './views/cookies/CookieSettings'
import defaultTheme, { getTheme } from './shared/theme/config'
import {
    CLAIM_FORM_VERSIONS,
    V2_BREAKPOINTS,
    modalTypes,
} from './shared/constants'
import LoginModal from './views/V2/components/LoginModal'
import ForgotPasswordModal from './views/V2/components/ForgotPasswordModal'
import StripePassengerModal from './views/V2/components/StripePassengerModal'
import StripeFailedModal from './views/V2/components/StripeFailedModal'
import LeavingModal from './views/V2/steps/LeavingModal'
import { fireGtagEvent } from './shared/services/events'

// eslint-disable-next-line
export const globalInjectBackground = (
    providedTheme,
    hideZenDesk
) => injectGlobal`
    html,
    body,
    #wrap {
        position: relative !important;
     
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        @media (max-width: ${V2_BREAKPOINTS.LG}px) {
            background: #fff !important;
        }
        
    }

    .zEWidget-webWidget--active {
        z-index: 9999999 !important;
    }

    ${
        hideZenDesk
            ? `
        iframe#launcher {
            display: none;
        }`
            : ''
    }
`

// function setCookieConsent(
//     isAlreadyAcceptedCookies,
//     catAccCookiesCat2,
//     catAccCookiesCat3,
//     cookieSettings
// ) {
//     if (!!cookieSettings && cookieSettings.toString() === '0') {
//         if (window && window.gtag) {
//             window.gtag('consent', 'update', {
//                 ad_storage: 'granted',
//                 ad_user_data: 'granted',
//                 ad_personalization: 'granted',
//                 analytics_storage: 'granted',
//                 security_storage: 'granted',
//             })
//         }
//         return
//     }

//     if (!!cookieSettings && cookieSettings.toString() === '1') {
//         if (window && window.gtag) {
//             window.gtag('consent', 'update', {
//                 ad_storage: 'granted',
//                 ad_user_data: 'granted',
//                 ad_personalization: 'denied',
//                 analytics_storage: 'granted',
//                 security_storage: 'granted',
//             })
//         }
//         return
//     }

//     if (!isAlreadyAcceptedCookies) {
//         if (window && window.gtag) {
//             window.gtag('consent', 'update', {
//                 ad_storage: 'granted',
//                 ad_user_data: 'granted',
//                 ad_personalization: 'denied',
//                 analytics_storage: 'granted',
//                 security_storage: 'granted',
//             })
//         }
//         return
//     }
//     if (!!catAccCookiesCat2 && !!catAccCookiesCat3) {
//         if (window && window.gtag) {
//             window.gtag('consent', 'update', {
//                 ad_storage: 'granted',
//                 ad_user_data: 'granted',
//                 ad_personalization: 'granted',
//                 analytics_storage: 'granted',
//                 security_storage: 'granted',
//             })
//         }
//         return
//     } else {
//         if (window && window.gtag) {
//             window.gtag('consent', 'update', {
//                 ad_storage: 'granted',
//                 ad_user_data: 'granted',
//                 ad_personalization: 'denied',
//                 analytics_storage: 'granted',
//                 security_storage: 'granted',
//             })
//         }
//     }
// }

function getSettingsCookie() {
    const cookieSettings = getCookie('catAccCookiesCats')
    const isAlreadyAcceptedCookies = getCookie('catAccCookiesMain0')
    // const catAccCookiesCat2 = getCookie('catAccCookiesCat2')
    // const catAccCookiesCat3 = getCookie('catAccCookiesCat3')

    // setCookieConsent(
    //     isAlreadyAcceptedCookies,
    //     catAccCookiesCat2,
    //     catAccCookiesCat3,
    //     cookieSettings
    // )
    if (!!isAlreadyAcceptedCookies) {
        if (cookieSettings) {
            submitCookies(cookieSettings.split(','))
        }
        return true
    }

    if (cookieSettings) {
        submitCookies(cookieSettings.split(','))
        return true
    }

    return false
}

function submitCookies(levels, settings, logCookiePreference) {
    // eslint-disable-next-line
    const hasLevel2 = levels.filter(level => level == 2).length > 0
    // eslint-disable-next-line
    const hasLevel3 = levels.filter(level => level == 3).length > 0

    if (!hasLevel2) {
        deleteCookies(level2List)
        setCookie(`ga-disable-UA-98219445-1`, 'true', 7300)
    }
    if (!hasLevel3) {
        deleteCookies(level3List)
        setCookie(`ga-disable-UA-98219445-1`, 'true', 7300)
    }

    const hadCookies = getCookie('catAccCookiesCats')
    deleteCookie('catAccCookiesCats')
    setCookie('catAccCookiesCats', levels.toString(), 1)
    if (logCookiePreference) {
        logCookiePreference(settings, levels.length.toString())
    }

    return new Promise(function(resolve, reject) {
        if (hadCookies && (hasLevel2 || hasLevel3)) {
            resolve(true)
        } else {
            resolve(false)
        }
    })
}

const performWidgetReplacement = showCookiesState => {
    try {
        const GDPRIcon = document.getElementById('shield-icon')
        const barHeight = document.getElementById('cookies-bar')
        const helpChat = document.getElementById('launcher')
        const currentBottom = _get(helpChat, 'style.bottom', null)
        const getOffsetSetting = height => ({
            webWidget: {
                offset: {
                    mobile: {
                        vertical: `${height}px`,
                    },
                },
            },
        })
        if (
            showCookiesState &&
            barHeight &&
            ((currentBottom &&
                currentBottom !== `${barHeight.clientHeight}px`) ||
                GDPRIcon)
        ) {
            if (GDPRIcon && GDPRIcon.style) {
                GDPRIcon.style.bottom = `${barHeight.clientHeight + 14}px`
            }
            window.zE(
                'webWidget',
                'updateSettings',
                getOffsetSetting(barHeight.clientHeight)
            )
        } else if (
            !showCookiesState &&
            barHeight &&
            currentBottom &&
            currentBottom !== '0px'
        ) {
            if (GDPRIcon && GDPRIcon.style) {
                GDPRIcon.style.bottom = '14px'
            }
            window.zE('webWidget', 'updateSettings', getOffsetSetting(0))
        }
    } catch (e) {
        if (!isProduction()) {
            console.log(e)
        }
    }
}

class App extends Component {
    cookieBarTimeout = null

    constructor(props) {
        super(props)

        this.state = {
            cookiesVisible: false,
            showCookies: !getSettingsCookie(),
            isLeavingModalVisible: false,
        }
    }

    UNSAFE_componentWillMount() {
        const claimFormV1 = getCookie('cf') === CLAIM_FORM_VERSIONS.V1
        const isZenDeskHidden = claimFormV1
            ? true
            : _get(this, 'props.hideElements.hideChat', false)
        globalInjectBackground(defaultTheme, isZenDeskHidden)
    }

    _toggleCookies = () => {
        this.setState({ cookiesVisible: !this.state.cookiesVisible })
    }

    _toggleCookiesBox = () => {
        this.setState({ showCookies: !this.state.showCookies })
    }

    _handleSubmit = (levels, settings) => {
        const { logCookiePreference } = this.props

        submitCookies(levels, settings || 'none', logCookiePreference).then(
            needsRefresh => {
                this.setState({ cookiesVisible: false, showCookies: false })
                if (needsRefresh) {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload()
                }
            }
        )
    }

    _handleMouseLeave = () => {
        const {
            leaveClaimId,
            claimId,
            v2_step,
            setLeavingModalClaimId,
            showModal,
        } = this.props

        if (v2_step && 4 === v2_step.stepIndex && leaveClaimId !== claimId) {
            setLeavingModalClaimId(claimId)
            showModal(modalTypes.leavingModal)
            this.setState({ isLeavingModalVisible: true })
        } else {
            this.setState({ isLeavingModalVisible: false })
        }
    }

    componentDidMount() {
        const { claimDraft } = this.props

        window &&
            window.addEventListener('beforeunload', () => {
                fireGtagEvent({ event: 'beforeunload' })
            })

        setTimeout(() => {
            if (!claimDraft) {
                loadScreenOff()
            }
            if (isMobileDevice()) {
                setTimeout(
                    () => performWidgetReplacement(this.state.showCookies),
                    2000
                )
            }
        }, 800)
    }

    componentDidUpdate(prevProps, prevState) {
        const { showCookies } = this.state
        const { config } = this.props
        const claimFormV1 = getCookie('cf') === CLAIM_FORM_VERSIONS.V1
        const isZenDeskHidden = claimFormV1
            ? true
            : _get(this, 'props.hideElements.hideChat', false)

        if (config !== prevProps.config) {
            globalInjectBackground(getTheme(), isZenDeskHidden)
        }

        if (
            isMobileDevice() &&
            window.zE &&
            typeof window.zE === 'function' &&
            (showCookies || prevState.showCookies)
        ) {
            if (this.cookieBarTimeout) {
                clearTimeout(this.cookieBarTimeout)
            }
            this.cookieBarTimeout = setTimeout(
                () => {
                    performWidgetReplacement(this.state.showCookies)
                },
                prevState.showCookies !== showCookies && !showCookies ? 1 : 2000
            )
        }
    }

    render() {
        const {
            cookiesVisible,
            showCookies,
            isLeavingModalVisible,
        } = this.state

        const {
            logCookiePreference,
            location,
            propsTheme,
            modal,
            isSkycopCare,
            isSkycopPay,
        } = this.props

        return (
            <ThemeProvider theme={propsTheme}>
                <Loader>
                    {isLeavingModalVisible && <LeavingModal />}
                    <Cookies
                        handleSubmit={(levels, settings) =>
                            submitCookies(levels, settings, logCookiePreference)
                        }
                        toggleCookiesBox={this._toggleCookiesBox}
                        showCookies={showCookies}
                    />
                    {cookiesVisible && (
                        <CookieSettings
                            handleSubmit={(levels, settings) =>
                                this._handleSubmit(levels, settings)
                            }
                            closeModal={() => this._toggleCookies()}
                        />
                    )}
                    <div
                        id="wrap"
                        onMouseLeave={() => this._handleMouseLeave()}
                        className="wrap"
                        style={{ height: 'auto' }}
                    >
                        {!isSkycopPay && (
                            <NavBar
                                showCookies={showCookies}
                                location={location}
                                isSkycopCare={isSkycopCare}
                            />
                        )}
                        {_get(modal, 'modalType', '') ===
                            modalTypes.loginModal && <LoginModal />}
                        {_get(modal, 'modalType', '') ===
                            modalTypes.forgotPasswordModal && (
                            <ForgotPasswordModal />
                        )}
                        {_get(modal, 'modalType', '') ===
                            modalTypes.stripePassengerModal && (
                            <StripePassengerModal />
                        )}
                        {_get(modal, 'modalType', '') ===
                            modalTypes.stripeFailedModal && (
                            <StripeFailedModal />
                        )}
                        {this.props.children}
                        {!isSkycopPay && (
                            <Footer
                                toggleCookies={() => this._toggleCookies()}
                                showCookies={showCookies}
                            />
                        )}
                    </div>
                </Loader>
            </ThemeProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { logCookiePreference, setLeavingModalClaimId, showModal },
        dispatch
    )
}

export default connect(
    state => ({
        config: state.config,
        propsTheme: state.propsTheme,
        modal: state.toggle_modal,
        hideElements: state.hideElements,
        v2_step: state.v2_step,
        claimId: state.V2Claim.id,
        leaveClaimId: state.leaving_modal.claimId,
    }),
    mapDispatchToProps
)(App)
