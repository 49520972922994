import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import styled from 'styled-components'
import t from '../../../../shared/translations'
import { modalTypes } from '../../../constants'
import { getCookie } from '../../../../main'
import { CLAIM_FORM_VERSIONS } from '../../../constants'

const StyledLink = styled(Link)`
    border-radius: 20px;
    border: solid 1px #101b35;
    background-color: #ffffff;
    padding: 8px 12px;
    min-height: 40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #101b35;
    opacity: 0.9;
`

const NavbarLogin = styled.div`
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    img {
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }

    a {
        text-decoration: none;

        &:hover {
            color: ${({ theme }) => theme.colors.secondaryColor};
        }
    }

    @media (max-width: 768px) {
        justify-content: flex-end;
        white-space: normal;
    }
`

const Login = ({ showModal }) => {
    if (getCookie('cf') === CLAIM_FORM_VERSIONS.V1) {
        return null
    } else {
        return (
            <NavbarLogin>
                <StyledLink
                    id="login-menu"
                    onClick={e => {
                        e.preventDefault()
                        showModal(modalTypes.loginModal)
                    }}
                >
                    {' '}
                    {t`menu.login`}
                </StyledLink>
            </NavbarLogin>
        )
    }
}

Login.propTypes = {
    backUrl: PropTypes.string.isRequired,
}

export default Login
