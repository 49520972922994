import React from 'react'
import styled from 'styled-components'
import { StopIcon } from '../../svgIcons'

const OptionsContainer = styled.div`
    padding: 5px 0px;
    position: relative;
    padding-left: 37px;
    width: calc(100% - 18px);

    em {
        font-weight: 600;
        color: #043d5d;
        font-style: normal;
    }

    .select-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        top: calc(50% + 0px);
        left: 0px;
        transform: translateY(-50%);
        z-index: 1;
    }
`

const OptionsHeader = styled.div`
    line-height: 1.2 !important;
    margin-bottom: 5px;
`

const OptionsElement = styled.div`
    font-size: 1.3rem !important;
    line-height: 1 !important;
    color: #043d5d;
`

const SelectFieldOptions = ({ optionsTopLabel, optionsBottomLabel }) => (
    <OptionsContainer>
        <span className="select-icon">
            <StopIcon className="select-icon" fill="#CBD3DF" />
        </span>
        <OptionsHeader
            dangerouslySetInnerHTML={{ __html: `${optionsTopLabel}` }}
        />
        <OptionsElement
            dangerouslySetInnerHTML={{ __html: `${optionsBottomLabel}` }}
        />
    </OptionsContainer>
)

export default SelectFieldOptions
