import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { hideModal } from '../../../shared/actions/v2_actions'
import Modal from '../../../shared/components/V2/Modal'
import {
    mobile,
    tablet,
    desktop,
} from '../../../shared/components/helpers/styled_queries'
import { H1 } from '../../../shared/components/V2/TextElements'
import { CloseButton2 } from '../../../shared/components/V2/V2Buttons'

const AgreementContainer = styled.div`
    width: 100%;
    height: 100%;
    overscroll-behavior: contain;
    scrollbar-color: #149954 #149954;

    ${desktop`
        padding-bottom: 24px;
    `}
`

const Content = styled.div`
    position: relative;
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    height: calc(100% - 100px);
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    margin: 0 24px;

    ${desktop`
        padding: 56px;
        
    `}
`

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 24px 24px 24px 32px;
    border-bottom: solid 1px #f3f5f7;
    position: relative;
    height: 100px;
    overflow: hidden;

    span {
        flex: 1;
    }

    &:after {
        position: absolute;
        bottom: -21px;
        left: 0;
        content: '';
        width: 99%;
        height: 20px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.34637605042016806) 100%
        );
    }

    ${tablet`
        span {
            font-size: 24px;
        }
    `}

    ${mobile`
        span {
            font-size: 24px;
        }
    `}
`

const CloseContainer = styled.div`
    cursor: pointer;
    flex: 0 0 24px;
    position: absolute;
    right: 10px;
    top: 15px;
`

class AgreementModalSig extends React.Component {
    render() {
        const { title, children, hideModal } = this.props

        return (
            <Modal modalType="agreementModalSig" radius="28px">
                <AgreementContainer>
                    <TitleContainer>
                        <H1>{title}</H1>
                        <CloseContainer onClick={hideModal}>
                            <CloseButton2
                                stroke="#717171"
                                width={24}
                                height={24}
                            />
                        </CloseContainer>
                    </TitleContainer>
                    <Content className="enabledTouch">{children}</Content>
                </AgreementContainer>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ hideModal }, dispatch)
}

export default connect(null, mapDispatchToProps)(AgreementModalSig)
