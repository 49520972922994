import { featureToggles } from "./constants";
import { isFeatureEnabled } from "../main";

//* EMAIL SUGGESTION VALIDATOR *//

const trustyDomains = ['gmail', 'hotmail', 'outlook', 'yahoo', 'icloud', 'mail', 'yandex', 'protonmail'];

function checkDomainEnd(inputDomain, trustedDomain) {
    const extraLettersInTheEndRegEx = new RegExp(`\\b${trustedDomain}`);
    if (extraLettersInTheEndRegEx.test(inputDomain)) {
        return trustedDomain;
    }
}

function checkForCloseMatch(inputDomain, trustedDomain) {
    // too many false positives with very short strings
    if (!inputDomain || inputDomain.length < 3) { return ''; }
    // test if the Trusted Domain is in the input (so everything is fine)
    if (inputDomain === trustedDomain) { return ''; }

    // split the trustedDomain string into two at each postion e.g. g|mail gm|ail gma|il gmai|l
    for (let i = 1; i < trustedDomain.length; i++) {
        const firstPart = trustedDomain.substring(0, i);
        const secondPart = trustedDomain.substring(i);

        // test for wrong letter
        const wrongLetterRegEx = new RegExp(`${firstPart}.${secondPart.substring(1)}`);
        if (wrongLetterRegEx.test(inputDomain)) {
            return inputDomain.replace(wrongLetterRegEx, trustedDomain);
        }

        // test for extra letter
        const extraLetterRegEx = new RegExp(`${firstPart}.${secondPart}`);
        if (extraLetterRegEx.test(inputDomain)) {
            return inputDomain.replace(extraLetterRegEx, trustedDomain);
        }

        // test for missing letter
        if (secondPart !== 'mail') {
            const missingLetterRegEx = new RegExp(`${firstPart}{0}${secondPart}`);
            if (missingLetterRegEx.test(inputDomain)) {
                return inputDomain.replace(missingLetterRegEx, trustedDomain);
            }
        }

        // test for switched letters
        const switchedLetters = [
            trustedDomain.substring(0, i - 1),
            trustedDomain.charAt(i),
            trustedDomain.charAt(i - 1),
            trustedDomain.substring(i + 1),
        ].join('');

        if (inputDomain.includes(switchedLetters)) {
            return inputDomain.replace(switchedLetters, trustedDomain);
        }
    }

    // if nothing was close, then there wasn't a typo
    return '';
}

function checkForDomainTypo(inputDomain) {
    if (!inputDomain) {
        return '';
    }

    for (let i = 0; i < trustyDomains.length; i++) {
        const domain = trustyDomains[i];
        let result = checkDomainEnd(inputDomain, domain);

        if (result) {
            return result;
        } else {
            result = checkForCloseMatch(inputDomain, domain);
            if (result) {
                return result;
            }
        }
    }

    return inputDomain;
}

// To be used in the future
// function checkForCommonMistakes(userInput) {
//     const commonMistakes = [
//         {
//             pattern: /,com$/, // e.g. name@example,com
//             fix: str => str.replace(/,com$/, '.com'),
//         },
//         {
//             pattern: /,co\.\w{2}$/, // e.g. name@example,co.uk
//             fix: str => str.replace(/,(co\.\w{2}$)/, '.$1'),
//         },
//         {
//             pattern: /@\w*$/, // e.g. name@gmail
//             fix: str => str + '.com',
//         },
//     ];

//     // the below will return undefined if the userInput doesn't match any of the patterns
//     // if the input does match a pattern, then it returns the 'mistake object'
//     const mistake = commonMistakes.find(mistake => mistake.pattern.test(userInput));

//     // if a mistake was found, we have access to its 'fix' function. Or is that a method?
//     if (mistake) {
//         return mistake.fix(userInput);
//     }

//     return userInput;
// }

function suggestProviderSuffix(parsedEmail, domains = [], suffix) {
    for (let i = 0; i < domains.length; i++) {
        if (parsedEmail.domain === domains[i] && parsedEmail.suffix !== suffix) {
            return suffix;
        } else {
            return parsedEmail.suffix;
        }
    }
}

function parseEmail(input) {
    if (!input) {
        return {
            name: "",
            domain: "",
            suffix: "",
        };
    }

    // To parse the domain we will replace the suffix in the second part
    const name = input.split("@")[0] || ""; // Name is the first (left) part
    const rightPart = input.split("@")[1] || ""; // Second part (right) contains Domain and Suffix
    const domain = (rightPart || "").split(".")[0] || rightPart; // Domain is the first part (To support .co.uk)
    const suffix = rightPart.replace(domain, "") || ""; // Suffix is the what is left

    return {
        name,
        domain,
        suffix,
    }
}

export function emailMagic(inputEmail) {
    if (!isFeatureEnabled(featureToggles.emailMagic)) {
        return '';
    }

    try {
        const parsedEmail = parseEmail(inputEmail);
        const domain = checkForDomainTypo(parsedEmail.domain);
        const suffix = suggestProviderSuffix({ ...parsedEmail, domain }, ["gmail", "yahoo"], ".com");
        const suggestedEmail = `${parsedEmail.name}@${domain}${suffix}`;
        const returnSuggestion = `${suggestedEmail}`;
        const parsedSuggestion = parseEmail(suggestedEmail);

        if (parsedEmail.name && domain && parsedSuggestion.suffix) {
            if (trustyDomains.includes(parsedSuggestion.domain) && (returnSuggestion !== inputEmail)) {
                return returnSuggestion;
            } else {
                return "";
            }
        }
    } catch (err) {}
}

//* end of EMAIL SUGGESTION VALIDATOR *//