import React from 'react'
import styled from 'styled-components'
import SuggestedText, { Text } from '../../Form/SuggestedText'
import { Col } from '../Layout'
import t from '../../../translations'
import { handleMobileInputBlur, handleMobileInputFocus } from '../../../../main'

const Container = styled('div')`
    position: relative;

    .select-icon {
        position: absolute;
        top: 18px;
        left: 18px;
    }
`

const InputCol = styled(Col)`
    input {
        border: ${({ hasDanger }) => hasDanger && '1px solid #FE6050'};

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    label {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        color: #7f8fa4;
        letter-spacing: normal;
        margin-bottom: 8px;

        span {
            &.required {
                color: #fe6050;
            }
        }
    }
`

const borderWidth = '1px'

const Input = styled.input`
    background: #fff;
    width: 100%;
    height: 56px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: normal;
    color: #043d5d;
    padding: 12px 16px;
    padding-left: 50px;
    border: 1px solid #cbd3df;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    ${({ StatusIcon }) => (StatusIcon ? 'padding-right: 32px;' : '')}

    &:hover,
    &:active,
    &:focus {
        border: 1px solid #043d5d;
        box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
    }

    &:disabled,
    &.disabled {
        background: #f2f5f9;
        color: #7f8fa4;
        border: ${borderWidth} solid #f2f5f9;
        font-weight: 600;
        opacity: 1;
        -webkit-text-fill-color: #7f8fa4;

        &:hover {
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &.warn {
        border: ${borderWidth} solid #ff713b;
    }

    &.danger {
        border: ${borderWidth} solid #fe6050;
    }

    &::placeholder {
        color: #043d5d;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

export const ErrorTextBox = styled.div`
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fe6050;

    &.warn {
        color: #ff713b;
    }
`

const InputField = ({
    errorText,
    warning,
    hasDanger,
    label,
    LabelComponent,
    type,
    className,
    suggestedText,
    isValidSmtp,
    required,
    onChange,
    disabled,
    onFocus = () => null,
    onBlur = () => null,
    lg = 6,
    sm = 8,
    xs = 12,
    tooltip,
    Icon,
    StatusIcon,
    inlineColStyle = {},
    ...props
}) => (
    <InputCol
        hasDanger={!!errorText || hasDanger}
        className={className}
        style={inlineColStyle}
        xs={xs}
        sm={sm}
        lg={lg}
    >
        {LabelComponent && <LabelComponent />}
        <Container>
            <span className="select-icon">
                {Icon ? <Icon className="select-icon" /> : null}
            </span>
            <Input
                placeholder={label}
                onChange={disabled ? () => null : onChange}
                className={errorText && warning && 'warn'}
                type={type}
                disabled={disabled}
                onFocus={e => {
                    e.preventDefault()
                    handleMobileInputFocus()
                    onFocus(e)
                }}
                onBlur={e => {
                    e.preventDefault()
                    handleMobileInputBlur()
                    onBlur(e)
                }}
                StatusIcon={!!StatusIcon}
                {...props}
            />
        </Container>
        {!!StatusIcon && (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '24px',
                    transform: 'translateY(-8px)',
                }}
            >
                <StatusIcon />
            </div>
        )}
        {errorText && (
            <ErrorTextBox className={warning && 'warn'}>
                {errorText}
            </ErrorTextBox>
        )}
        {suggestedText && (
            <SuggestedText
                text={!errorText && suggestedText.text}
                callback={suggestedText.callback}
            />
        )}
        {!isValidSmtp && !errorText && suggestedText && !suggestedText.text && (
            <Text>{t`common.label.could_not_verify_email`}</Text>
        )}
    </InputCol>
)

export default InputField
