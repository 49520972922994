import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import _get from 'lodash/get'
import {
    loadScreenOn,
    dataURItoBlob,
    loginPageShow,
    loginPageHide,
    isValidSpecialChars,
    V2handleSignatureLength,
    scrollTo,
    loadScreenOff,
    isMobileDevice,
    addBeforeUnload,
    removeBeforeUnload,
} from '../../../main'
import {
    uploadUserSignature,
    getUserAgreement,
    uploadPassengerSignature,
    getOwnerInfo,
    getPassengerName,
    saveSignature,
    getCountries,
    logKibana,
} from '../../actions'
import { renderCountries } from './../../../render'
import t from '../../translations'
import InputField from './InputField'
import DatePicker from './DatePicker'
import CountryInput from '../../../views/V2/components/CountryInput'
import moment from 'moment'
import { paths } from '../../constants'
import { PageContent, Row, Col } from '../V2/Layout'
import { V2Button } from '../V2/V2Buttons'
import { mobile, tablet } from '../helpers/styled_queries'
import V2SignBox from '../V2/V2SignBox'
import TermsAndConditions from '../../../views/V2/components/TermsAndConditions'
import { DownArrow } from '../svgIcons'
import store from '../../services/store'
import { V2_ACTIONS } from '../../actions/actionTypes'

const CountryInputCol = styled(Col)`
    div.form-group {
        margin-bottom: 0;
    }
`

const MainContainer = styled.div`
    max-width: 992px;
    margin: 40px auto 80px;

    .h-mb-8 {
        margin-bottom: 8px;
    }

    .h-mt-4 {
        margin-top: 4px;
    }

    h3 {
        font-family: 'Open Sans', sans-serif;
    }

    ${tablet`
        margin-bottom: 100px;
    `}

    ${mobile`
        margin-bottom: 100px;
    `}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

export const HeaderContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-bottom: 36px;

    h1 {
        text-align: center;
        font-size: 28px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #353d51;

        span {
            font-weight: bold;
        }
    }
`

export const AgreementContainer = styled.div`
    border: solid 1px #d7d7e0;
    padding: 66px;
    color: #353d51;
    float: left;
    width: 100%;

    table {
        table-layout: fixed;

        tr {
            td {
                h2 {
                    font-size: 16px;
                    text-align: center;
                    word-break: break-word;
                }
            }
        }
    }

    td {
        padding: 0 25px;
        vertical-align: top;
        text-align: justify;
        page-break-inside: auto;

        hr {
            width: 100% !important;

            & + div {
                ${mobile`
                    margin-left: 0px!important;
                `}
            }
        }
    }

    overflow: auto;

    ${tablet`
       padding: 0;
    `}

    ${mobile`
        padding: 16px 0;
        font-size: 8px;
        overflow: hidden;

        tbody {
            tr {
                td {
                    font-size: 8px!important;
                    padding: 0 8px;

                    div {
                        font-size: 8px!important;
                    }

                    h2 {
                        font-size: 2rem;
                        word-break: break-word;
                    }

                    img {
                        width: 127px!important;
                        margin-left: unset!important;
                    }
                }
            }
        }
    `}
`

const TermsContainer = styled.div`
    display: flex;
    justify-content: center;
`

const ScrollDownContainer = styled.div`
    position: fixed;
    bottom: 10px;
    margin: 0 auto;
    right: 50%;
    transform: translateX(50%);
    z-index: 100;
    max-width: 300px;

    button {
        border-radius: 40px;
    }

    @-webkit-keyframes mover {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-5px);
        }
    }

    @keyframes mover {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-5px);
        }
    }

    .vert-move {
        -webkit-animation: mover 1s infinite alternate;
        animation: mover 1s infinite alternate;
    }

    svg {
        display: block;
        margin: 8px auto 0px;
    }

    ${tablet`
        max-width: 250px;
        
        button {
            padding: 12px;
            min-width: 150px;
        }

        svg {
            margin-top: 4px;
        }
    `}

    ${mobile`
        max-width: 200px;
        
        button {
            padding: 12px;
            min-width: 150px;
        }

        svg {
            margin-top: 4px;
        }
    `}
`

let selectErrors = {}
let changeTimeout

class Agreement extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            passenger: '',
            signature: '',
            signatureDate: '',
            termsCheck: false,
            claimId: '',
            birthdate: '',
            fullAddress: '',
            address: '',
            city: '',
            country: '',
            showScroll: true,
            isEnabledPostcode: false,
            postCode: '',
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initProperties(nextProps)

        const { supportedPostcodes, passengerInfo, passengerType } = nextProps
        let airlineIata = []

        if (passengerType === 'owner') {
            airlineIata = _get(passengerInfo, 'airlinesIata', [])
        } else {
            airlineIata = _get(passengerInfo, 'airlines_iata', [])
        }

        airlineIata.forEach(iata => {
            if (supportedPostcodes.includes(iata)) {
                this.setState({ isEnabledPostcode: true })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { userAgreement, trans } = this.props
        if (!prevProps.userAgreement && userAgreement && trans) {
            loadScreenOff()
        }
    }

    componentDidMount() {
        const { hideCookies, userAgreement } = this.props
        this.props.getCountries(null, 'AGREEMENT')

        if (!userAgreement) {
            loadScreenOn()
        }

        loginPageShow(true)
        this.initProperties()
        window.addEventListener('scroll', () => {
            const { showScroll } = this.state
            const innerHeight = window.innerHeight
            const scrollY = window.scrollY
            const offset = document.body.offsetHeight
            const elem = document.getElementById('scroll-top-div')
            const elementOffset = offset - (elem ? elem.offsetTop : -500)
            const scrollPosDiff = innerHeight + scrollY - offset

            if (!showScroll && scrollPosDiff < -elementOffset) {
                this.setState({ showScroll: true })
            }

            if (showScroll && !hideCookies && isMobileDevice()) {
                store.dispatch({ type: V2_ACTIONS.HIDE_COOKIES, payload: true })
            }

            if (showScroll && scrollPosDiff > -elementOffset) {
                this.setState({ showScroll: false })
                if (isMobileDevice()) {
                    store.dispatch({
                        type: V2_ACTIONS.HIDE_COOKIES,
                        payload: false,
                    })
                }
            }
        })
    }

    componentWillUnmount() {
        loginPageHide()
    }

    setSignatureState = signature => {
        V2handleSignatureLength(signature, 5000)
        this.setState({ signature })
    }

    initProperties = props => {
        const { passengerType, passengerInfo, isLegal } = props || this.props
        const { name } = this.state

        if (passengerType && passengerInfo && !name) {
            const {
                legalSignature,
                signature,
                has_signature,
                has_legal_signature,
            } = passengerInfo
            let hasSignature = false

            if (isLegal) {
                hasSignature = legalSignature || has_legal_signature
            } else {
                hasSignature = signature || has_signature
            }

            if (hasSignature) {
                browserHistory.push(paths.AGREEMENT_SIGNED)
            } else {
                const mappedPassenger = this.mapAgreements(
                    passengerType,
                    passengerInfo
                )
                this.setState(mappedPassenger)
            }
        }
    }

    handleCheckboxChange = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
    }

    handleChangeEvent = e => {
        if (!e) return

        const target = e.target || e

        if (target.name === 'birthdate') {
            const val = target.value
            target.value = moment(val).format('YYYY-MM-DD')
        }

        if (['address', 'city'].includes(target.name)) {
            if (!isValidSpecialChars(target.value)) {
                selectErrors[
                    target.name
                ] = t`common.label.is_required.special_symbols`
                this.forceUpdate()
                return
            }
        }

        this.setState({ [target.name]: target.value }, () => {
            clearTimeout(changeTimeout)
            changeTimeout = setTimeout(() => {
                this.handleFormChange()
            }, 1000)
        })
        selectErrors = {}
    }

    clearCountry = () => {
        this.setState({ country: '' })
    }

    handleFormChange = () => {
        const {
            passengerInfo,
            getUserAgreement,
            countries,
            agreementType,
            passengerType,
        } = this.props
        const {
            address,
            city,
            country,
            birthdate,
            name,
            surname,
            postCode,
            claimId,
        } = this.state

        if (passengerInfo) {
            const fullName = `${name} ${surname}`
            const { payload } = countries || {}

            if (passengerType === 'passenger') {
                const passengerId = passengerInfo.claim.id
                const { country_title } = passengerInfo
                const countryTitle =
                    (country &&
                        payload &&
                        payload.filter(item => country === item.id)[0].title) ||
                    country_title

                if (address && city && countryTitle && postCode) {
                    const passengerAddress = `${address}, ${city} ${postCode}, ${countryTitle}`
                    getUserAgreement(
                        passengerId,
                        fullName,
                        agreementType,
                        birthdate,
                        passengerAddress
                    )
                }
            } else {
                const countryTitle =
                    (country &&
                        payload &&
                        _get(
                            payload.filter(item => country === item.id),
                            '[0].title',
                            ''
                        )) ||
                    country

                const userAddress = `${address}, ${city} ${postCode}, ${countryTitle}`
                getUserAgreement(
                    claimId,
                    fullName,
                    agreementType,
                    birthdate,
                    userAddress
                )
            }
        }
    }

    completeSigning = () => {
        const { passengerInfo, saveSignature, passengerType } = this.props
        const { signature, termsCheck, birthdate, claimId } = this.state
        const signatureDate = new Date()

        this.validateSubmit()
        if (
            !termsCheck ||
            !signature ||
            V2handleSignatureLength(signature, 5000)
        ) {
            const SignatureLength = signature => {
                const length = signature.length
                const dpi = window.devicePixelRatio
                const dpiDivision = dpi / 1.5
                return length / (dpiDivision < 1 ? 1 : dpiDivision)
            }
            const dpi = window.devicePixelRatio
            const minLength = 6000 * dpi

            const errorObject = {
                termsCheck: termsCheck,
                signature: signature,
                signatureActualLength: SignatureLength(signature),
                signatureMinLength: minLength,
                claimId: claimId,
                passengerType: passengerType,
                browser: navigator.userAgent,
            }

            if (V2handleSignatureLength(signature, 5000)) {
                store.dispatch(
                    logKibana(
                        'Signature validation',
                        '/legal-signature',
                        'critical',
                        null,
                        JSON.stringify(errorObject)
                    )
                )
            }

            selectErrors.termsCheck = t`common.label.is_required.terms_and_conditions`
            this.setState({ errors: 'true' })
        } else if (
            signature &&
            !V2handleSignatureLength(signature, 5000) &&
            (birthdate || passengerInfo.has_birth_date) &&
            Object.keys(selectErrors).length === 0 &&
            selectErrors.constructor === Object
        ) {
            selectErrors = {}
            loadScreenOn()

            this.setState({ signatureDate })
            saveSignature({ signature, signatureDate })
            this.uploadSignature({ signature })
        }
    }

    checkRequired = (field, translation) => {
        if (!this.state[field] || this.state[field].trim() === '') {
            selectErrors[field] = translation
        }
    }

    validateSubmit = () => {
        const { passengerInfo, passengerType } = this.props
        const { isEnabledPostcode } = this.state
        const hasAddress =
            (passengerType === 'owner' && passengerInfo.hasAddress) ||
            passengerInfo.has_address
        selectErrors = {}

        if (!passengerInfo.has_birth_date) {
            this.checkRequired(
                'birthdate',
                t`common.info.passenger_birthdate_required`
            )
        }

        if (!hasAddress) {
            this.checkRequired('address', t`common.label.is_required.address`)
            this.checkRequired('city', t`common.label.is_required.city`)
            this.checkRequired('country', t`common.label.is_required.country`)
        }

        if (!passengerInfo.postCode && isEnabledPostcode) {
            this.checkRequired('postCode', t`v2_common.placeholder.postcode`)
        }

        this.setState({ errors: true })
    }

    uploadSignature = obj => {
        const {
            uploadUserSignature,
            uploadPassengerSignature,
            agreementType,
            passengerType,
            isLegal,
        } = this.props
        const {
            birthdate,
            address,
            city,
            country,
            passengerId,
            hasAddress,
            claimId,
            postCode,
            hasBirthdate,
            isEnabledPostcode,
        } = this.state
        var blob = dataURItoBlob(obj.signature)
        const isOwner = passengerType === 'owner'

        const generateData = agreementType => {
            const fd = new FormData()
            fd.append('claim_signature[file]', blob)
            fd.append('claim_signature[type]', agreementType)

            if (isOwner) {
                fd.append('claim_signature[claim]', claimId || '')
            } else {
                fd.append('claim_signature[passenger]', passengerId || '')
            }
            if (!hasBirthdate && birthdate) {
                fd.append(
                    'additional_parameters[birthDate]',
                    moment(birthdate).format('YYYY-MM-DD')
                )
            }
            if (isEnabledPostcode && postCode) {
                fd.append('additional_parameters[postCode]', postCode)
            }
            if (!hasAddress) {
                if (address) {
                    fd.append('additional_parameters[address]', address)
                }
                if (city) {
                    fd.append('additional_parameters[city]', city)
                }
                if (country) {
                    fd.append('additional_parameters[country]', country)
                }
            }

            return fd
        }

        if (!isLegal) {
            if (isOwner) {
                uploadUserSignature(generateData(agreementType)).then(res =>
                    this.handlePostRedirect(res)
                )
            } else {
                uploadPassengerSignature(
                    generateData(agreementType)
                ).then(res => this.handlePostRedirect(res, true))
            }
        } else {
            addBeforeUnload()
            if (isOwner) {
                uploadUserSignature(generateData('sls'), true).then(res => {
                    if (this.checkIsSigned(res)) {
                        removeBeforeUnload()
                        loadScreenOff()
                        browserHistory.push(paths.AGREEMENT_SIGNED)
                    } else {
                        uploadUserSignature(generateData('sil'), true).then(
                            res => {
                                removeBeforeUnload()
                                loadScreenOff()
                                this.handlePostRedirect(res)
                            }
                        )
                    }
                })
            } else {
                uploadPassengerSignature(generateData('sls'), true).then(
                    res => {
                        if (this.checkIsSigned(res)) {
                            removeBeforeUnload()
                            loadScreenOff()
                            browserHistory.push(paths.AGREEMENT_SIGNED)
                        } else {
                            uploadPassengerSignature(
                                generateData('sil'),
                                true
                            ).then(res => {
                                removeBeforeUnload()
                                loadScreenOff()
                                this.handlePostRedirect(res, true)
                            })
                        }
                    }
                )
            }
        }
    }

    checkIsSigned = res =>
        res && res.status === 400 && res.data === 'agreement.already_signed'

    handlePostRedirect = (res, isPassenger) => {
        if (this.checkIsSigned(res)) {
            browserHistory.push(paths.AGREEMENT_SIGNED)
        } else {
            browserHistory.push(
                `/${isPassenger ? 'passenger-' : ''}signature-completed`
            )
        }
    }

    renderAgreement = () => {
        const { userAgreement } = this.props
        if (userAgreement) {
            return (
                <AgreementContainer
                    className="h-mb-8"
                    dangerouslySetInnerHTML={{ __html: userAgreement }}
                />
            )
        } else {
            return <div />
        }
    }

    getOptions = name => {
        const { countries } = this.props
        return renderCountries(countries, name)
    }

    renderMissingInfo = () => {
        const { address, city, birthdate, country } = this.state

        return (
            <React.Fragment>
                <Row className="h-mb-8">
                    <InputField
                        name="address"
                        label={t`v2_common.label.address`}
                        required
                        placeholder={t`common.placeholder.address`}
                        value={address}
                        onChange={this.handleChangeEvent}
                        errorText={selectErrors['address']}
                        autoComplete="street-address"
                    />
                    <InputField
                        name="city"
                        label={t`v2_common.label.city`}
                        placeholder={t`common.placeholder.city`}
                        required
                        value={city}
                        onChange={this.handleChangeEvent}
                        errorText={selectErrors['city']}
                        autoComplete="address-level2"
                    />
                </Row>
                <Row className="h-mb-8">
                    <CountryInputCol xs={12} md={12} lg={6}>
                        <CountryInput
                            name="country"
                            required
                            value={country}
                            defaultValue={country}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['country']}
                            clear={this.clearCountry}
                            autoComplete="country"
                        />
                    </CountryInputCol>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <DatePicker
                            dateFormat="YYYY-MM-DD"
                            label={t`v2_common.label.birthdate`}
                            required
                            type="text"
                            name="birthdate"
                            isValidDate={this.validBirthdate}
                            initialViewDate={moment('1990')}
                            startDate={2020}
                            endDate={120}
                            InitialViewMode="years"
                            placeholder={t`v2_common.placeholder.birthdate`}
                            value={birthdate}
                            handleChange={this.handleChangeEvent}
                            errorText={selectErrors['birthdate']}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderMissingBirthdate = () => {
        const { birthdate } = this.state

        return (
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <DatePicker
                        dateFormat="YYYY-MM-DD"
                        label={t`v2_common.label.birthdate`}
                        required
                        type="text"
                        name="birthdate"
                        initialViewDate={moment('1990')}
                        isValidDate={this.validBirthdate}
                        startDate={2020}
                        endDate={150}
                        InitialviewMode="years"
                        placeholder={t`v2_common.placeholder.birthdate`}
                        value={birthdate}
                        handleChange={this.handleChangeEvent}
                        errorText={selectErrors['birthdate']}
                    />
                </Col>
            </Row>
        )
    }

    renderMissingPostCode = () => {
        const { passengerInfo, passengerType } = this.props
        const { postCode, isEnabledPostcode } = this.state
        const showPostCodeField =
            isEnabledPostcode &&
            (passengerType === 'passenger'
                ? !passengerInfo.post_code
                : !passengerInfo.postCode)

        if (showPostCodeField) {
            return (
                <Row>
                    <InputField
                        label={t`v2_common.label.postcode`}
                        required
                        name="postCode"
                        maxLength={10}
                        placeholder={t`v2_common.placeholder.postcode`}
                        value={postCode}
                        onChange={this.handleChangeEvent}
                        errorText={selectErrors['postCode']}
                        autoComplete="postal-code"
                    />
                </Row>
            )
        } else {
            return null
        }
    }

    handlePassengerInfo = () => {
        const { hasAddress, hasBirthdate } = this.state

        if (!hasBirthdate || !hasAddress) {
            if (!hasAddress) {
                return this.renderMissingInfo()
            } else {
                return this.renderMissingBirthdate()
            }
        } else {
            return null
        }
    }

    userName = () => {
        const { passengerInfo } = this.props
        if (passengerInfo) {
            return (
                <h5>
                    {passengerInfo.name} {passengerInfo.surname}
                </h5>
            )
        }
    }

    mapAgreements = (passengerType, passengerInfo) => {
        if (passengerType === 'owner') {
            return {
                name: passengerInfo.name,
                surname: passengerInfo.surname,
                fullAddress: passengerInfo.address || '',
                birthdate: passengerInfo.birthdate || '',
                claimId: passengerInfo.id,
                hasBirthdate: passengerInfo.has_birth_date,
                hasAddress: passengerInfo.hasAddress,
                legalServicesSignature: passengerInfo.legalServicesSignature,
                legalSignature: passengerInfo.legalSignature,
                hasSignature: passengerInfo.signature || '',
                postCode: passengerInfo.postCode || '',
                airlinesIata: passengerInfo.airlinesIata,
                city: passengerInfo.city || '',
                country: passengerInfo.country || '',
                address: passengerInfo.notFormattedAddress || '',
            }
        } else if (passengerType === 'passenger') {
            return {
                name: passengerInfo.name,
                surname: passengerInfo.surname,
                fullAddress: passengerInfo.passenger_address || '',
                address: passengerInfo.address || '',
                city: passengerInfo.city || '',
                country:
                    (passengerInfo.country && passengerInfo.country.id) || '',
                countryTitle: passengerInfo.country_title || '',
                birthdate: passengerInfo.birthdate || '',
                claimId: passengerInfo.claim.id,
                hasBirthdate: passengerInfo.has_birth_date,
                hasAddress: passengerInfo.has_address,
                legalServicesSignature:
                    passengerInfo.has_legal_services_signature,
                legalSignature: passengerInfo.has_legal_signature,
                passengerId: passengerInfo.id,
                hasSignature: passengerInfo.has_signature || '',
                airlinesIata: passengerInfo.airlines_iata,
                postCode: passengerInfo.post_code || '',
            }
        }
    }

    validBirthdate = current => {
        const daysBefore = 365 * 150
        const today = moment()
        const last = moment().subtract(daysBefore, 'days')
        return current.isBefore(today) && current.isAfter(last)
    }

    scrollDown = () => {
        const offset = document.body.offsetHeight

        const elem = document.getElementById('scroll-top-div')
        const elementOffset = offset - (elem ? elem.offsetTop : -500)
        scrollTo(elem, offset - elementOffset, 300)
    }

    render() {
        const { trans, passengerInfo, headerProps, userAgreement } = this.props
        const { termsCheck, signature, showScroll } = this.state

        if (trans && passengerInfo && userAgreement) {
            return (
                <MainContainer>
                    {showScroll && (
                        <ScrollDownContainer>
                            <V2Button
                                onClick={this.scrollDown}
                                className="vert-move"
                            >
                                {t`common.label.scroll_down_sign`}
                                <DownArrow />
                            </V2Button>
                        </ScrollDownContainer>
                    )}
                    <PageContent>
                        <Helmet>
                            <title>{`Skycop - ${t`sign_form.title_part1`} ${t`sign_form.title_part2_bold`}`}</title>
                        </Helmet>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <HeaderContainer>
                                    <h1>
                                        {headerProps.part1}{' '}
                                        <span>{headerProps.part2}</span>
                                    </h1>
                                </HeaderContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                {this.renderAgreement()}
                            </Col>
                        </Row>
                        <div id="scroll-top-div" />
                        {this.handlePassengerInfo()}
                        {this.renderMissingPostCode()}
                        <Row className="h-mb-8 h-mt-4">
                            <Col md={12} lg={12}>
                                <V2SignBox
                                    legalGuardianFullName={
                                        passengerInfo.legal_guardian_full_name
                                    }
                                    border
                                    name={passengerInfo.name}
                                    surname={passengerInfo.surname}
                                    handleSignatureState={
                                        this.setSignatureState
                                    }
                                    clearLabel={t`v2_common.clear`}
                                    signature={signature}
                                    hasBox={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TermsContainer>
                                    <TermsAndConditions
                                        text={t`v2_claim.passenger_details.terms_and_conditions_price_list`}
                                        onChange={this.handleCheckboxChange}
                                        checked={termsCheck}
                                    />
                                </TermsContainer>
                            </Col>
                        </Row>
                        {selectErrors['termsCheck'] ? (
                            <Row>
                                <Col className="form-control-feedback has-danger text-center h-mb15">
                                    {selectErrors['termsCheck']}
                                </Col>
                            </Row>
                        ) : null}
                        <Row style={{ justifyContent: 'center' }}>
                            <Col md={4} lg={4}>
                                <V2Button
                                    disabled={V2handleSignatureLength(
                                        signature,
                                        5000
                                    )}
                                    onClick={this.completeSigning}
                                >
                                    {t`signature_form.button.complete_signing`}
                                </V2Button>
                            </Col>
                        </Row>
                    </PageContent>
                </MainContainer>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        signature: state.signature,
        trans: state.translations,
        userAgreement: state.userAgreement,
        hideCookies: state.hideCookies,
        supportedPostcodes: state.supportedPostcodes,
        countries: state.countries,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            saveSignature,
            uploadUserSignature,
            uploadPassengerSignature,
            getUserAgreement,
            getOwnerInfo,
            getPassengerName,
            getCountries,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Agreement)
