import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const LogoContainer = styled.figure`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 48px 0 0;

    a {
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 100px;
        height: 40px;
        object-fit: contain;
    }
`

const Logo = styled.img.attrs({
    src: ({ theme, signatureConfigs, skycopLogoType }) =>
        (signatureConfigs && signatureConfigs.skycopLogo === 'white') ||
        skycopLogoType === 'white'
            ? '/images/logo_white_transparent.png'
            : theme.logo.url,
    alt: ({ theme }) => theme.logo.alt,
})`
    width: 100px;
    height: 40px;
    object-fit: contain;
`
const NavLogo = ({ logoLink, signatureConfigs, skycopLogoType }) => (
    <LogoContainer>
        <a href={logoLink}>
            <Logo
                signatureConfigs={signatureConfigs}
                skycopLogoType={skycopLogoType}
            />
        </a>
    </LogoContainer>
)

NavLogo.propTypes = {
    data: PropTypes.shape({
        logoLink: PropTypes.string.isRequired,
    }),
}

export default NavLogo
