import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import t from '../../../../shared/translations'
import { InfoBlock } from '../../../../shared/components/V2/Layout'
import {
    tablet,
    mobile,
} from '../../../../shared/components/helpers/styled_queries'
import { DownSharpArrow } from '../../../../shared/components/svgIcons'
import InfoBox from '../../components/InfoBox'
import { CloseButtonIcon } from '../../../../shared/components/V2/V2Icons'
import { removeDraftItem } from '../../../../shared/actions/v2_actions'

export const RemoveButtonContainer = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    opacity: ${done => (done ? 1 : 0)};
    cursor: pointer;

    &:hover {
        opacity: 80%;
    }
`

const DraftsContainer = styled.div`
    @keyframes appearBox {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    transition: all 0.5s ease;
    animation: appearBox;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
`

const DashedLine = styled.hr`
    margin: 32px 12px 24px;
    border: 0;
    z-index: 9;
    background-image: linear-gradient(
        to right,
        #c2c2c2 66%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 22px 1px;
    background-repeat: repeat-x;
`

const DraftRow = styled.div`
    @keyframes appearBox {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    transition: all 0.5s ease;
    animation: appearBox;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    display: flex;
    margin: 0px 12px 12px;
    padding: 13px;
    border-radius: 8px;
    border: solid 1px #cbd3df;
`

const StyledButton = styled(V2Button)`
    margin-left: auto;
    width: fit-content;
    height: fit-content;
    align-self: flex-start;

    img {
        margin-right: 8px;
        vertical-align: middle;
    }

    ${tablet`
        margin: 0;
        margin-top: 12px;
    `}

    ${mobile`
        margin: 0;
        margin-top: 12px;
    `}
`

const LeftContainer = styled.div`
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    ${tablet`
         flex-wrap: wrap;
    `}

    ${mobile`
       flex-wrap: wrap;
    `}
`

const Airports = styled.div`
    display: flex;
    line-height: 1.6;
    font-weight: 600;
    font-size: 15px;
    color: #353d51;

    .icon {
        font-size: 16px;
        line-height: 1.6;
        margin: 0px 12px;
        display: inline-flex;
        align-items: center;
    }

    .iata {
        display: none;
    }

    .full {
        display: inline-flex;
    }
`

const LastUpdated = styled.div`
    display: flex;
    font-size: 12px;
    line-height: 1.33;
    color: #7f8fa4;
    line-height: 2;
`

const ButtonShowMore = styled(V2Button)`
    width: fit-content;
    border-color: #32b86a;
    color: #32b86a;

    &:hover {
        color: #32b86a;
    }

    svg {
        margin-left: 8px;
        vertical-align: middle;
    }

    ${tablet`
        width: 40%;
    `}

    ${mobile`
        width: 100%;
    `}
`

const StyledInfoBox = styled(InfoBox)`
    margin: 24px 12px;
    display: flex;

    div,
    h1 {
        align-items: center;
        color: #353d51;
        font-weight: normal;
    }
`

const DraftBox = ({
    updatedAt,
    id,
    airportArrival,
    airportDeparture,
    removeDraftItem,
}) => {
    return (
        <DraftRow>
            <LeftContainer>
                <div className="details-container">
                    <Airports>
                        <span className="full">{`${airportDeparture.city ||
                            ''} (${airportDeparture.iata || ''})`}</span>
                        <span className="iata">
                            {airportDeparture.iata || ''}
                        </span>
                        <span className="icon icon--plane" />
                        <span className="full">{`${airportArrival.city ||
                            ''} (${airportArrival.iata || ''})`}</span>
                        <span className="iata">
                            {airportArrival.iata || ''}
                        </span>
                    </Airports>
                    <LastUpdated>
                        {t`draft_exists.last_update`}:{' '}
                        {moment.utc(updatedAt).format('YYYY-MM-DD')}
                    </LastUpdated>
                </div>
                <StyledButton
                    onClick={() => window.open(`/?draftId=${id}`, '_blank')}
                >
                    <img src="/images/fast.svg" alt="fast" />
                    <span
                        style={{ verticalAlign: 'middle' }}
                    >{t`draft_exists.button.finish_claim`}</span>
                </StyledButton>
            </LeftContainer>

            <RemoveButtonContainer onClick={() => removeDraftItem(id)}>
                <CloseButtonIcon />
            </RemoveButtonContainer>
        </DraftRow>
    )
}

class DraftClaims extends React.Component {
    state = {
        showMore: false,
    }

    render() {
        const { trans, allDrafts, removeDraftItem } = this.props
        const { showMore } = this.state

        if (!trans) return null

        if (!allDrafts || (allDrafts || []).length === 0) return null

        const sortedDrafts = allDrafts.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt)
        })

        return (
            <DraftsContainer>
                <InfoBlock title={t`draft_exists.title`} />
                <StyledInfoBox>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t`draft_exists.subtitle`,
                        }}
                    />
                </StyledInfoBox>
                {sortedDrafts.slice(0, 3).map((item, i) => (
                    <DraftBox
                        key={item.id}
                        {...item}
                        removeDraftItem={removeDraftItem}
                    />
                ))}
                {showMore &&
                    sortedDrafts
                        .slice(3, sortedDrafts.length)
                        .map((item, i) => (
                            <DraftBox
                                key={item.id}
                                {...item}
                                removeDraftItem={removeDraftItem}
                            />
                        ))}
                {!showMore && sortedDrafts.length > 3 && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '24px 12px 0px',
                        }}
                    >
                        <ButtonShowMore
                            type="borderBtn"
                            onClick={() => this.setState({ showMore: true })}
                        >
                            {t`draft_exists.button.show_more`}
                            <DownSharpArrow />
                        </ButtonShowMore>
                    </div>
                )}
                <DashedLine />
            </DraftsContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        user: state.user,
        allDrafts: state.allDrafts,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ removeDraftItem }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DraftClaims)
