import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Modal from '../V2/Modal'
import { modalTypes } from '../../constants'
import { browserHistory } from 'react-router'
import {
    hideModal,
    showModal,
    setModalPromoCode,
} from '../../actions/v2_actions'
import { CloseButton2 } from '../V2/V2Buttons'
import { getApiClient } from '../../../main'
import { desktop, mobile } from '../helpers/styled_queries'
import SurveyQuestions from './SurveyQuestions'
import SkycopCareRedirect from './SkycopCareRedirect'
import { paths } from '../../constants'

export const QUESTION_TYPE = {
    Radio: 'radio',
}

const Container = styled.div`
    ${mobile`
    padding: unset;
 `}

    ${desktop`
    display: flex;
 `}

    ${({ noBackground }) =>
        noBackground &&
        `
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    `}
`

class AboutUsSurveyModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSurveyCompleted: false,
            promoCode: null,
            surveyQuestions: {},
        }
    }

    componentDidMount() {
        const { lang } = this.props
        const apiClient = getApiClient()
        const url = 'api/survey/options/'

        apiClient
            .get(`${url}${lang}`)
            .then(({ data }) => {
                const mappedQuestions = Object.keys(data).reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: {
                            ...data[key],
                            isChecked: false,
                        },
                    }),
                    {}
                )
                this.setState({ surveyQuestions: mappedQuestions })
            })
            .catch(e => {
                console.error(e)
                this.setState({ surveyQuestions: {} })
            })
    }

    handleRedirectClick = () => {
        const { hideModal, setModalPromoCode } = this.props
        const { promoCode } = this.state
        setModalPromoCode(promoCode)
        hideModal()
        const subscriptionsRedirect = paths.V2.SC_SUBSCRIPTIONS
        browserHistory.push(subscriptionsRedirect)
    }

    handleSurveySubmit = () => {
        const { surveyQuestions } = this.state
        const { claimId, v2_step } = this.props
        const getSelectedAnswerKey = Object.keys(surveyQuestions).filter(
            key => surveyQuestions[key].isChecked
        )
        const activeAnswer = surveyQuestions[getSelectedAnswerKey[0]]
        const apiClient = getApiClient()
        const url = 'api/survey/save'

        const data = {
            survey: {
                answer_key: getSelectedAnswerKey[0],
                answer_value: activeAnswer.value,
                claim: claimId,
            },
        }

        apiClient
            .post(url, data)
            .then(() => {
                const url = '/api/promo-code/generate'
                const step = v2_step && v2_step.current.substring(1)

                apiClient
                    .post(url, {
                        claimDraft: claimId,
                        event: 'survey_complete',
                        step,
                    })
                    .then(({ data }) => {
                        this.setState({ promoCode: data.promo_code })
                        this.setState({ isSurveyCompleted: true })
                    })
            })

            .catch(e => {
                console.log('e', e)
            })
    }

    isButtonDisabled = () => {
        const { surveyQuestions } = this.state

        return Object.values(surveyQuestions).every(item => {
            return !item.isChecked
        })
    }

    handleCommentChange = (value, id) => {
        this.setState(prevState => ({
            ...prevState,
            surveyQuestions: {
                ...prevState.surveyQuestions,
                [id]: {
                    ...prevState.surveyQuestions[id],
                    value: value,
                    isChecked: true,
                },
            },
        }))
    }

    changeRadioValue = id => {
        const { surveyQuestions } = this.state

        const updatedRadios = Object.keys(surveyQuestions).reduce(
            (value, key) => {
                return {
                    ...value,
                    [key]: {
                        ...surveyQuestions[key],
                        value:
                            surveyQuestions[key].type === QUESTION_TYPE.Radio
                                ? key === id
                                : surveyQuestions[key].value,
                        isChecked: key === id,
                    },
                }
            },
            {}
        )
        this.setState({ surveyQuestions: updatedRadios })
    }

    handleCloseButton = () => {
        const { hideModal } = this.props
        this.handleModalClose()
        hideModal()
    }

    handleModalClose = () => {
        const { isSurveyCompleted } = this.state
        if (!isSurveyCompleted) {
            this.saveDiscardedSurvey()
        }
    }

    saveDiscardedSurvey = () => {
        const { claimId } = this.props

        const apiClient = getApiClient()
        const url = 'api/survey/save'

        const data = {
            survey: {
                answer_key: 'none',
                answer_value: true,
                claim: claimId,
            },
        }

        apiClient
            .post(url, data)
            .then(() => {})

            .catch(e => {
                console.log('e', e)
            })
    }

    render() {
        const { trans, hideModal } = this.props
        const { surveyQuestions, isSurveyCompleted } = this.state

        if (!trans || !Object.keys(surveyQuestions).length) {
            return null
        }
        return (
            <Modal
                modalType={modalTypes.congratsSurveyModal}
                width="1030px"
                height="auto"
                fadeIn={true}
                radius="28px"
            >
                <CloseButton2
                    stroke="#717171"
                    onClick={() => hideModal()}
                    width="24"
                    height="24"
                />
                <Container>
                    {!isSurveyCompleted ? (
                        <SurveyQuestions
                            changeRadioValue={this.changeRadioValue}
                            handleCommentChange={this.handleCommentChange}
                            isButtonDisabled={this.isButtonDisabled}
                            handleSurveySubmit={this.handleSurveySubmit}
                            surveyQuestions={surveyQuestions}
                        />
                    ) : (
                        <SkycopCareRedirect
                            onRedirectClick={this.handleRedirectClick}
                        />
                    )}
                </Container>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { showModal, hideModal, setModalPromoCode },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        lang: state.language,
        claimId: state.v2_posted_claim && state.v2_posted_claim.id,
        v2_step: state.v2_step,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsSurveyModal)
