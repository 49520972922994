import React from 'react'
import styled from 'styled-components'
import { desktop, tablet, mobile } from '../../helpers/styled_queries'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #f3f6fa;
    padding: 24px 20px;

    .bubble {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ bubbleColor }) => bubbleColor};
        color: #fff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        font-size: 11px;
        
    }

    .text {
        font-size: 21px;
        color: #043d5d;
        padding: 0;
        margin: 0;
        margin-left: 12px;
    }

    ${desktop`
      .text {
        padding: 0;
        margin: 0;
        margin-top: 20px;
      }
    `}

    ${tablet`
        
        align-items: center;
        flex-direction: row;
    `}

    ${mobile`
        
        align-items: center;
        flex-direction: row;
    `}
`

export const InfoBox = ({ bubbleColor, text, number }) => {
    return (
        <Container bubbleColor={bubbleColor}>
            <div className="bubble">{number}</div>
            <h3 className="text">{text}</h3>
        </Container>
    )
}
