import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SocialButton from '../Buttons/SocialButton'
import { loginExternalUser } from '../../actions/v2_actions'
import styled from 'styled-components'
import t from '../../translations'
import ErrorBox from '../../../views/V2/components/ErrorsBox'

const loginErrors = [
    '[google][auth] Authentication failed',
    '[facebook][auth] Authentication failed',
    'Fetching user...',
]

const ErrorText = styled.p`
    padding: 0 0 0 16px;
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #ffffff;

    span {
        font-weight: bold;
    }
`

class SocialLogin extends Component {
    handleSocialLoginSuccess = (user, provider, callback = () => null) => {
        if (user) {
            var userObj = {
                socialId: user._profile.id,
                email: user._profile.email,
                firstName: user._profile.firstName,
                lastName: user._profile.lastName,
                type: provider,
            }

            this.props.loginExternalUser(userObj, callback)
        }
    }

    handleSocialLoginFailure = error => {
        if (loginErrors.filter(item => item === error).length < 0) {
            console.log(
                'Social Login Error: ',
                error,
                ', Strigified: ',
                JSON.stringify(error)
            )
        }
    }

    render() {
        return null
    }
}

class GoogleSocialLogin extends SocialLogin {
    render() {
        const {
                translationKey,
                onLoginSuccess,
                classes,
                tabIndex,
                callback,
            } = this.props,
            provider = 'google'
        if (this.props.trans) {
            return (
                <SocialButton
                    className={`btn btn--gp btn-block ${classes}`}
                    type="button"
                    provider={provider}
                    appId="908673247004-140fvk4ipe7lcd40m4eo72lo18mtki1d.apps.googleusercontent.com"
                    tabIndex={tabIndex}
                    onLoginSuccess={user => {
                        this.handleSocialLoginSuccess(user, provider, callback)
                        onLoginSuccess && onLoginSuccess(user)
                    }}
                    onLoginFailure={this.handleSocialLoginFailure}
                >
                    <i className="fa fa-google" aria-hidden="true" />
                    {t`${translationKey}`}
                </SocialButton>
            )
        } else {
            return null
        }
    }
}

class FacebookSocialLogin extends SocialLogin {
    render() {
        const {
                translationKey,
                onLoginSuccess,
                classes,
                tabIndex,
                callback,
                error,
            } = this.props,
            provider = 'facebook'
        if (this.props.trans) {
            const errorMsg = _.get(
                error,
                'errorResponse.response.data.payload.message',
                ''
            )
            return (
                <>
                    <SocialButton
                        className={`btn btn--fb btn-block ${classes}`}
                        type="button"
                        provider={provider}
                        appId="1448183652612501"
                        tabIndex={tabIndex}
                        onLoginSuccess={user => {
                            this.handleSocialLoginSuccess(
                                user,
                                provider,
                                callback
                            )
                            onLoginSuccess && onLoginSuccess(user)
                        }}
                        onLoginFailure={this.handleSocialLoginFailure}
                    >
                        <i className="fa fa-facebook" aria-hidden="true" />
                        {t`${translationKey}`}
                    </SocialButton>
                    {_.get(error, 'errorResponse.response.status', false) ===
                        400 &&
                        errorMsg && (
                            <ErrorBox noTitle style={{ margin: '16px 0' }}>
                                <ErrorText>{t`${errorMsg}`}</ErrorText>
                            </ErrorBox>
                        )}
                </>
            )
        } else {
            return null
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loginExternalUser }, dispatch)
}

SocialLogin.propTypes = {
    onLoginSuccess: PropTypes.func,
    translationKey: PropTypes.string,
    classes: PropTypes.string,
    callback: PropTypes.func,
}

SocialLogin.defaultProps = {
    onLoginSuccess: null,
    callback: () => null,
    translationKey: '',
    classes: '',
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        error: state.error,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin)

export const Google = connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleSocialLogin)

export const Facebook = connect(
    mapStateToProps,
    mapDispatchToProps
)(FacebookSocialLogin)
