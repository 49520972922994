import React, { Component } from 'react'
import styled from 'styled-components'
import InputField from '../../../shared/components/V2/InputField'
var debounceChange = require('throttle-debounce/debounce')

const Container = styled.div`
    display: flex;
    .form-control {
        color: ${({ theme }) => theme.colors.secondaryColor};
        font-weight: 600;

        &:focus {
            border-color: ${({ theme }) => theme.colors.secondaryColor};
        }
    }

    div {
        width: 100%;
    }
`

const StyledInput = styled(InputField)`
    width: 100%;
    max-width: unset;
    margin: 0;
    padding: 0;

    input {
        border-radius: 0 12px 12px 0 !important;
        border-left: none !important;
        background: #fafafa;
    }

    &::placeholder {
        color: #f3f5f7;
    }

    &.mr-0 {
        margin: 0;
    }
`

const FlightNumberInput = styled(InputField)`
    width: 56px;
    max-width: 60px !important;
    font-family: 'Noto sans';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    padding: 0;
    margin: 0;

    input {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;

        border-right: unset !important;
        border-radius: 12px 0 0 12px;
        text-align: center;

        &:disabled {
            font-weight: 400 !important;
            color: #333333 !important;
            background-color: #fafafa !important;
            border: 1px solid #717171;
            -webkit-text-fill-color: #333333 !important;
        }
    }
`

const InputsContainer = styled.div`
    display: flex;
    width: 100%;

    &:focus-within {
        ${FlightNumberInput} {
            input {
                border: 1px solid ${({ theme }) => theme.colors.primaryColor};
                // border-right-color: #cbd3df !important;
                border-right: unset;
                box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
            }
        }
    }

    &:hover,
    &:active,
    &:focus {
        ${FlightNumberInput} {
            input {
                border-right: unset;
                // border-right-color: #cbd3df !important;
            }
        }

        input {
            border: 1px solid ${({ theme }) => theme.colors.primaryColor};
            box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
        }
    }
`

const StyledLabel = styled.label`
    color: #717171;
    margin-bottom: 16px;
    margin-bottom: 16px;
    font-weight: 600;
    font-family: 'Noto Sans';
    font-size: 16px;
    line-height: 22.4px;
    text-align: left;
    span {
        color: #fe6050;
    }
`

const ErrorTextBox = styled.div`
    margin: 0;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fe6050;
`

var classes
class V2FlightNumber extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flightNumberLetters: '',
            flightNumberDigits: '',
        }

        this.references = {}
        this.handleLocalChange = debounceChange(
            500,
            this.handleLocalChange.bind(this)
        )
    }

    onlyNumbers(e) {
        const re = /^[0-9]+/g
        if (!re.test(e.key)) {
            e.preventDefault()
        }
    }

    onlyAlphabets(e) {
        const re = /[a-zA-Z0-9-]+/g
        if (!re.test(e.key)) {
            e.preventDefault()
        }
    }

    handleLocalChange(e) {
        this.props.onChange(e)
    }

    _handlePaste = e => {
        const pastedValue = e.clipboardData.getData('Text')
        const digitRegex = /^\d+$/

        if (!digitRegex.test(pastedValue)) {
            e.preventDefault()
        }
        return false
    }

    render() {
        const {
            errorText,
            prefillLetters,
            name,
            lettersName,
            label,
            required,
            id,
        } = this.props
        return (
            <Container className={`${classes} detail-input`}>
                <div>
                    {label && (
                        <StyledLabel>
                            {label}
                            {required ? <span> *</span> : ''}
                        </StyledLabel>
                    )}
                    <InputsContainer className={errorText ? 'has-danger' : ''}>
                        <FlightNumberInput
                            data-hj-whitelist=""
                            style={{
                                textTransform: 'uppercase',
                                backgroundColor: '#f6f6f6',
                            }}
                            type="text"
                            maxLength={2}
                            placeholder="VA"
                            disabled
                            className=""
                            name={lettersName}
                            onChange={this.props.onChange}
                            value={prefillLetters || ''}
                            autoComplete="off"
                        />
                        <StyledInput
                            type="tel"
                            data-hj-whitelist=""
                            autoComplete="off"
                            placeholder="1234"
                            onKeyPress={e => this.onlyNumbers(e)}
                            className="mr-0"
                            maxLength={4}
                            id={id}
                            name={name}
                            onChange={this.props.onChange}
                            onBlur={this.props.onChange}
                            onPaste={this._handlePaste}
                            onDrop={e => e.preventDefault()}
                            value={this.props.value || ''}
                            hasDanger={!!errorText}
                        />
                    </InputsContainer>
                    {errorText && <ErrorTextBox>{errorText}</ErrorTextBox>}
                </div>
            </Container>
        )
    }
}

export default V2FlightNumber
