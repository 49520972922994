import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import t from '../../../../shared/translations'
import {
    Row,
    PageViewContainer,
    InfoBlock,
} from '../../../../shared/components/V2/Layout'
import { ProfileContainer } from '../MyProfile'
import {
    getSkycopCareSubscriptions,
    cancelSkycopCareSubscriptions,
} from '../../../../shared/actions/v2_actions'
import {
    mobile,
    tabletSM,
} from '../../../../shared/components/helpers/styled_queries'
import { paths } from '../../../../shared/constants'
import { browserHistory, withRouter } from 'react-router'
import moment from 'moment'
import { DarkButton } from './Button'
import { loadScreenOn, loadScreenOff } from '../../../../main'

const StyledProfileContainer = styled(ProfileContainer)`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 25px;

    p {
        font-size: 14px;
        font-weight: normal;
        color: #353d51;
    }

    ${mobile`
        padding-left: 12px;
        padding-right: 12px;
    `}

    ${tabletSM`
        padding-left: 24px;
        padding-right: 24px;
    `}

    h1 {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 32px;
    }
`

const ButtonsRow = styled(Row)`
    margin-top: 12px;

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: #6750a4;
        display: flex;
        align-items: center;
        margin: 0;
        margin-right: 12px;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }

        ${mobile`
            text-align:center
        `}
    }

    button {
        width: auto;
    }
`

const Container = styled(InfoBlock)``

class CancelSubscription extends React.Component {
    async componentDidMount() {
        const { user } = this.props

        if (user && !user.active) {
            browserHistory.push(paths.PROFILE)
        } else {
            try {
                loadScreenOn()
                await getSkycopCareSubscriptions()
            } catch (e) {
                console.error(e)
            } finally {
                loadScreenOff()
            }
        }
    }

    render() {
        const { trans, subscriptions, params } = this.props

        if (!trans) {
            return null
        }

        const subscription = subscriptions.find(
            s => s.careRegistryId === params.id
        )
        const mainBuyer = _get(subscription, 'mainBuyer', false)

        if (!subscription && !mainBuyer) return null

        const familyMembersNames = _get(
            subscription,
            'childCustomersFullName',
            []
        )
        return (
            <PageViewContainer>
                <StyledProfileContainer>
                    <Helmet>
                        <title>{`Skycop - ${t`sc_cancel_subscription.title`}`}</title>
                    </Helmet>
                    <Container
                        className="skycop-care-cancel"
                        title={t`sc_cancel_subscriptions.title`}
                    >
                        <Row>
                            <p>
                                {t`sc_cancel_subscriptions.cancel_text`.replace(
                                    '%PLAN_NAME%',
                                    t`sc_subscriptions.plan.${subscription.productName}`
                                )}
                            </p>
                        </Row>
                        <Row>
                            <p>
                                {t`sc_cancel_subscriptions.cancel_text_2`
                                    .replace(
                                        '%TILL_DATE%',
                                        formatDate(subscription.validTill)
                                    )
                                    .replace(
                                        '%NAME%',
                                        [
                                            subscription.customerFullName,
                                            ...familyMembersNames,
                                        ].join(', ')
                                    )}
                            </p>
                        </Row>
                        <ButtonsRow>
                            <p
                                onClick={() => {
                                    try {
                                        cancelSkycopCareSubscriptions(
                                            subscription.careRegistryId
                                        )
                                        browserHistory.push('/subscriptions')
                                    } catch (e) {
                                        browserHistory.push('/subscriptions')
                                    }
                                }}
                            >
                                {t`sc_cancel_subscriptions.cancel_subscription`}
                            </p>
                            <DarkButton
                                onClick={() =>
                                    browserHistory.push('/skycop-care')
                                }
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.15 6.867C10.989 7.488 9.927 8.28 9 9.234C8.073 8.271 7.011 7.479 5.85 6.867C6.066 4.374 7.128 1.926 9.054 0C10.962 1.908 11.979 4.356 12.15 6.867ZM9 12.105C10.935 9.153 14.238 7.2 18 7.2C18 16.2 9.612 17.892 9 18C8.388 17.901 0 16.2 0 7.2C3.762 7.2 7.065 9.153 9 12.105ZM9.045 2.871C8.451 3.807 8.037 4.842 7.812 5.922L9 6.795L10.215 5.913C10.008 4.833 9.612 3.798 9.045 2.871ZM9 16.173C9 16.173 14.4 15.3 15.966 9.225C10.8 10.8 9 15.39 9 15.39C9 15.39 6.3 9.9 2.034 9.234C3.6 15.3 9 16.173 9 16.173Z"
                                        fill="white"
                                    />
                                </svg>
                                {t`sc_cancel_subscriptions.button`}
                            </DarkButton>
                            {/* </Col> */}
                        </ButtonsRow>
                    </Container>
                </StyledProfileContainer>
            </PageViewContainer>
        )
    }
}

function formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : ''
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        authenticated: state.auth.authenticated,
        user: state.user,
        subscriptions: state.subscriptions,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(withRouter(CancelSubscription))
