import { deleteCookie } from "./main";

export function deleteCookies(list) {
    list.forEach(item => {
        deleteCookie(item);
    });
}

export const level2List = [
    "hubspotutk",
    "mp_hj_mixpanel",
    "intercom-lou-c5ke8zbr",
    "optimizelyEndUserId",
    "optimizelyBuckets",
    "optimizelySegments",
    "intercom-session-c5ke8zbr",
    "__hstc",
    "mp_6d7c50ad560e01715a871a117a2fbd90_mixpanel",
    "__hssrc",
    "_hjIncludedInSample",
    "tfw_exp",
    "personalization_id",
    "yabs-sid",
    "dpr",
    "xs",
    "sb",
    "wd",
    "_fbp",
    "_hjid",
    "mp_hj_mixpanel",
    "AID",
    "optimize_editor",
];

export const level3List = [
    "DSID",
    "IDE",
    "__gfp_64b",
    "i",
    "_ym_isad",
    "i",
    "id",
    "_ym_uid",
    "yandexuid",
    "yp",
    "eu_cn",
    "syndication_guest_id",
    "MUID",
    "MUIDB",
    "_uetsid",
    "TOptOut",
    "fr",
    "presence",
    "datr",
    "act",
    "SSID",
    "_drt_",
];