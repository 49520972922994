import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";
import t from '../../../shared/translations';

const CenterText = styled.div`
    text-align: center;
    font-weight: bold;
    color: ${({ color }) => (color || "black ")};
    font-size: 14px;
    margin: 5px 0px;

    ${({ visible }) => (!visible && 'opacity: 0;')}
`;

const Item = styled.div`
    font-size: 11px;
    color: ${({ color }) => (color || "black ")};
`;

const SideInfo = ({ cookies, value }) => {
    
    const filterAllowed = cookies.filter((cookie) => value >= cookie.category);
    const filterNotAllowed = cookies.filter((cookie) => value < cookie.category);
    const allowed = filterAllowed.reduce((result, currValue) => {
        const prependResult = _get(currValue, "cookies", []);
        return [...prependResult, ...result];
    }, []).reverse();
    const notAllowed = filterNotAllowed.reduce((result, currValue) => {
        const prependResult = _get(currValue, "cookies", []);
        return [...prependResult, ...result];
    }, []).reverse();
    return (
        <div>
            {
                allowed.length > 0 &&
                <div>
                    <CenterText visible={allowed.length > 0}>
                        {t`cookies.shared.label.functionality_allowed`}
                    </CenterText>
                    {
                        allowed.map((item) => <Item key={item}>- {item}</Item>)
                    }
                </div>
            }
            <CenterText color="#bf2424" visible={notAllowed.length > 0}>
                {t`cookies.shared.label.functionality_not_allowed`}
            </CenterText>
            {
                notAllowed.length > 0 && notAllowed.map((item) => <Item key={item} color="#bf2424">- {item}</Item>)
            }
        </div>
    );
}

SideInfo.propTypes = {
    allowed: PropTypes.arrayOf(PropTypes.string),
    notAllowed: PropTypes.arrayOf(PropTypes.string),
};

SideInfo.defaultProps = {
    allowed: [],
    notAllowed: [],
};

function mapStateToProps(state) {
    return {
        trans: state.translations
    }
}

export default connect(mapStateToProps)(SideInfo);
