import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import t from '../../../../shared/translations'
import { ProfileContainer } from '../../steps/MyProfile'
import { browserHistory } from 'react-router'

import {
    mobile,
    tabletSM,
    tablet,
    desktop,
} from '../../../../shared/components/helpers/styled_queries'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import { v2DefaultConfig } from '../../../../shared/theme/config'
import {
    Row,
    PageViewContainer,
    Col,
} from '../../../../shared/components/V2/Layout'
import { isMobileDevice } from '../../../../main'
import { SmartDelayExplainCard } from './SmartDelayExplainCard'
import { paths } from '../../../../shared/constants'
import RegisteredFlights from './RegisteredFlights'

export const StyledSmartDelayContainer = styled(ProfileContainer)`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;

    ${mobile`
        padding-left: 12px;
        padding-right: 12px;

        .info-block {
            padding-bottom: 60px;
        }
    `}

    ${tabletSM`
        padding-left: 24px;
        padding-right: 24px;
    `}

    ${desktop`
    padding-left: 24px;
    padding-right: 24px;
`}

    h1 {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 32px;
    }
`

const Subtitle = styled.p`
    color: #353d51;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
`

export const StyledH1 = styled.span`
    font-size: 28px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: ${v2DefaultConfig.colors.darkText};
    display: block;

    ${mobile`
        font-size: 26px;
    `}

    ${tablet`
        font-size: 26px;
    `}
`

const CardsHeader = styled.p`
    color: #333333;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
`

const ButtonContainer = styled.div`
    flex: 0;
`

const CurveContainer = styled.div`
    position: absolute;

    ${desktop`
        top: 130px;
        padding-left: 165px;
    `}

    ${mobile`
        top:240px;
    `}
`

const ExplainCardsContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${desktop`
    margin-left: 12px;
`}
`

const SmartCard = styled.div`
    margin-bottom: 6px;
    margin-right: 10px;
    ${tablet`
        width: 100%;
    `}
    ${mobile`
    width: 100%;
    margin-right: 16px;
    margin-left: 16px;
`}
`

export const TitleContainer = styled.div`
    margin-left: 12px;

    ${mobile`
    font-size: 26px;
    margin-left: 18px;
`}

    ${tablet`
    font-size: 26px;
    margin-left: unset;
`}
`

const DesktopCurve = (
    <svg
        width="554"
        height="226"
        viewBox="0 0 554 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.6"
            d="M1 46.9503C34.3032 22.9269 116.296 -17.9128 177.84 10.9152C254.77 46.9503 243.281 215.26 365.67 224.049C463.581 231.08 531.353 197.389 553 179.665"
            stroke="#525F7F"
            stroke-dasharray="7 7"
        />
    </svg>
)

const MobileCurve = (
    <svg
        width="390"
        height="360"
        viewBox="0 0 390 360"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.6"
            d="M340.549 1.04243C379.475 27.4725 427.407 92.5846 373.966 135.757C307.318 189.599 68.5436 144.444 10.3575 223.844C-37.6554 289.361 18.223 342.296 46.9516 359.488"
            stroke="#525F7F"
            stroke-dasharray="7 7"
        />
    </svg>
)

const StyledV2Button = styled(V2Button)`
    text-wrap: nowrap;
`

class SmartDelayPage extends React.Component {
    render() {
        const { trans } = this.props

        if (!trans) {
            return null
        }

        return (
            <PageViewContainer>
                <StyledSmartDelayContainer>
                    <TitleContainer>
                        <Helmet>
                            <title>{`Skycop - ${t`smart_delay.title`}`}</title>
                        </Helmet>
                        <StyledH1>{t`smart_delay.title`}</StyledH1>
                        <Subtitle>{t`smart_delay.description`}</Subtitle>
                        <br />
                        <CardsHeader>{t`smart_delay.cards_description`}</CardsHeader>
                    </TitleContainer>
                    <Row>
                        <CurveContainer>
                            {isMobileDevice() ? MobileCurve : DesktopCurve}
                        </CurveContainer>
                        <ExplainCardsContainer>
                            <SmartCard>
                                <SmartDelayExplainCard
                                    title={t`smart_delay.card.register_flight.title`}
                                    content={t`smart_delay.card.register_flight.content`}
                                    number="1"
                                />
                            </SmartCard>
                            <SmartCard>
                                <SmartDelayExplainCard
                                    title={t`smart_delay.card.monitor_delay.title`}
                                    content={t`smart_delay.card.monitor_delay.content`}
                                    number="2"
                                />
                            </SmartCard>
                            <SmartCard>
                                <SmartDelayExplainCard
                                    title={t`smart_delay.card.enjoy_travel.title`}
                                    content={t`smart_delay.card.enjoy_travel.content`}
                                    number="3"
                                />
                            </SmartCard>
                        </ExplainCardsContainer>
                    </Row>
                    <Row>
                        <ButtonContainer>
                            <Col xs={12} md={12}>
                                <StyledV2Button
                                    onClick={() =>
                                        browserHistory.push(
                                            paths.V2_SMART_DELAY
                                                .STEP_1_FLIGHT_DETAILS
                                        )
                                    }
                                >
                                    {t`smart_delay.start_registration`}
                                </StyledV2Button>
                            </Col>
                        </ButtonContainer>
                    </Row>
                    <Row>
                        <RegisteredFlights />
                    </Row>
                </StyledSmartDelayContainer>
            </PageViewContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(SmartDelayPage)
