import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CheckMarkContainer = styled.span`
    vertical-align: middle;
    display: inline-block;
    width: 30px;
    height: 30px;
    left: 0;
    position: absolute;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
`

const CheckMarkStem = styled.span`
    position: absolute;
    width: 3px;
    height: 15px;
    background-color: #d9534f;
    left: 9px;
    top: 7px;
`

const CheckMarkKick = styled.span`
    position: absolute;
    width: 9px;
    height: 3px;
    background-color: #d9534f;
    left: 2px;
    top: 20px;
`

export const CheckMarkText = ({ label }) => (
    <Container style={{ cursor: 'default', marginBottom: 5 }}>
        <StyledLabel style={{ cursor: 'default' }}>
            <CheckMarkContainer>
                <CheckMarkStem />
                <CheckMarkKick />
            </CheckMarkContainer>
            <span style={{ verticalAlign: 'middle' }}>{label}</span>
        </StyledLabel>
    </Container>
)

const StyledCheckmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;

    background-color: white;
    border: ${({ checked }) => (checked ? 'none' : 'solid 1px #cbd3df')};
    animation: unset;
    ${({ alert }) =>
        alert &&
        `
        border: 1px solid  #FE6050;
        animation: pulse 2s infinite;
    `}

    &:hover {
        border: 1px solid blue;
    }

    &:after {
        content: '';
        position: absolute;
        display: none;
        left: 9px;
        top: 4px;
        width: 7px;
        height: 12px;
        border: 1px solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`

const StyledCheckBox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ ${StyledCheckmark}:after {
        display: block;
    }
    &:checked ~ ${StyledCheckmark} {
        background-color: #043d5d;
    }
`

const StyledLabel = styled.label`
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    ${({ isDisabled }) =>
        isDisabled &&
        `
        cursor: not-allowed;
    `}

    .pulse {
        box-shadow: 0 0 0 rgba(241, 60, 60, 0.4);
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
        }
    }
    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
            box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
            box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
            box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
        }
    }
`

const Container = styled.div`
    margin-bottom: 0px;
    display: block;
    position: relative;
    padding-left: ${({ paddingLeft }) => paddingLeft || '30px'};
    cursor: pointer;
    user-select: none;

    &:hover {
        ${StyledCheckmark} {
            border: 1px solid
                ${({ theme, checked }) => (checked ? '#cbd3df' : '#043D5D')};

            &:after {
                left: 8px;
                top: 3px;
            }
        }
    }
`

const CheckBox = ({
    label,
    checked,
    disabled,
    alert,
    paddingLeft,
    ...props
}) => (
    <Container checked={checked} paddingLeft={paddingLeft}>
        <StyledLabel isDisabled={disabled}>
            <StyledCheckBox
                type="checkbox"
                {...props}
                checked={checked}
                disabled={disabled}
            />
            <StyledCheckmark
                checked={checked}
                alert={!!alert}
                className={!!alert ? 'pulse' : ''}
            />
            <span>{typeof label === 'string' ? label : label()}</span>
        </StyledLabel>
    </Container>
)

CheckBox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
}

CheckBox.defaultProps = {
    checked: false,
    label: '',
}

export default CheckBox
