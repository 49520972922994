import React from 'react'
import styled from 'styled-components'

const Container = styled.button`
    width: 100%;
    height: 56px;
    color: ${({ variant }) => (variant === 'primary' ? 'white' : '#043D5D')};
    background-color: ${({ variant }) =>
        variant === 'primary' ? '#F1955B' : '#ECF4F9'};
    box-shadow: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &:hover {
        opacity: 0.7;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
`

export const Button = ({ text, onClick, variant = 'primary', disabled }) => (
    <Container variant={variant} onClick={onClick} disabled={disabled}>
        {text}
    </Container>
)
