import { SET_SUBMITED_CLAIM_DATA, CLEAR_SUBMITED_CLAIM_DATA } from '../actions/actionTypes';

export default function (state = null, action) {
    switch (action.type) {
        case SET_SUBMITED_CLAIM_DATA:
            return {...state, ...action.payload};
        case CLEAR_SUBMITED_CLAIM_DATA: {
            return null;
        }
        default:
    }
    return state;
};