import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    flightDocuments: null,
    flightReservationNumber: null,
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_RESERVATION_NUMBER:
            return {...state, flightReservationNumber: action.payload};
        case V2_ACTIONS.CLEAR_FLIGHT_DOCUMENTS:
            return {...state, flightDocuments: null};
        case V2_ACTIONS.CLEAR_RESERVATION_NUMBER:
            return {...state, flightReservationNumber: null};
        default:
    }
    return state;
}