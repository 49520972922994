import { V2_ACTIONS } from '../../actions/actionTypes'

const INITIAL_STATE = null

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_SIGNATURE_PAGE_CONFIG:
            return {
                ...state,
                ...action.payload,
            }
        case V2_ACTIONS.RESET_SIGNATURE_PAGE_CONFIG:
            return {
                ...state,
                signaturePageConfig: null,
            }
        default:
    }
    return state
}
