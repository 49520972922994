import { V2_ACTIONS } from '../../actions/actionTypes';
import _ from "lodash";

export const groupPayload = (payload) => {
    return _.groupBy(_.orderBy(payload, ['airline_title'], ['asc']), 'flight_time');
}

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
    const { type, payload, arrayIndex } = action;
    const result = [...state];

    switch (type) {
        case V2_ACTIONS.SUGGESTED_FLIGHTS:
            const groupedPayload = groupPayload(payload);
            result[arrayIndex] = groupedPayload;
            return result;
        case V2_ACTIONS.CLEAR_SUGGESTED_FLIGHTS:
            return INITIAL_STATE;
        case V2_ACTIONS.CLEAR_SPECIFIC_SUGGESTIONS:
            delete result[arrayIndex];
            return result;
        default:
    }
    return state;
}