import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import _get from 'lodash/get'
import t from '../../../../shared/translations'
import { emailMagic } from '../../../../shared/validators'
import { validateEmailSmtp } from '../../../../shared/actions'
import { Col, Row } from '../../../../shared/components/V2/Layout'
import { CloseIcon2 } from '../../../../shared/components/V2/V2Icons'
import DatePicker from '../../../../shared/components/V2/DatePicker'
import V2PhoneField from '../../../../shared/components/V2/V2PhoneField'
import AgeBox from '../../components/AgeBox'
import InputField from '../../../../shared/components/V2/InputField'
import NewSelectField from '../../../../shared/components/V2/NewSelectField'
import { PagePadding } from './PassengerDetails'
import { H1 } from '../../../../shared/components/V2/TextElements'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import {
    desktop,
    tablet,
    mobile,
    tabletLG,
} from '../../../../shared/components/helpers/styled_queries'
import {
    adultAge,
    additionalPaxGuardianOptions,
} from '../../../../shared/constants'
import { getAgeInYears } from '../../../../main'

const StyledFieldsRow = styled(Row)`
    margin-bottom: 16px;

    ${tablet`
        margin-bottom: 0px;
        `}

    ${mobile`
        margin-bottom: 0px;
        `}
`

export const RightContainer = styled.div`
    display: inline-flex;
    margin-left: auto;

    svg {
        &:hover {
            filter: brightness(110%);
            cursor: pointer;
        }
    }
`

export const LeftContainer = styled.div`
    display: inline-flex;
    align-items: center;
    margin-right: auto;

    svg {
        margin-right: 12px;
    }

    .passenger-title {
        color: #000000;
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 600;
    }
`

export const BlockContainer = styled.div`
    padding: 24px 0px 24px 0px;

    ${tabletLG`
        // padding: 0px 24px 12px;
    `}

    ${mobile`
        // padding: 0px 4px 12px;
    `}

    // .mobile-compact {
    //     padding: 12px;

    //     ${tabletLG`
    //         padding: 12px 15px;
    //     `}

    //     ${tablet`
    //         padding: 12px 20px;
    //     `}

    //     ${mobile`
    //         padding: 12px 15px;
    //     `}
    // }
`

export const PassengerInfoContainer = styled.div`
    background-color: #FAFAFA;
    border-radius: 20px;
    padding: 12px 0px;
    border: 1px solid #cbd3df;

    ${desktop`
        width: 100%;
    `}

    ${tablet`
        padding: 12px 3px 16px;
    `}

    ${mobile`
        .item-col {
            padding: 12px 0px;
        }
            border-radius: unset;
            border: none;

        padding: 12px 15px 16px 15px;
    `}

    ${tabletLG`
        margin: auto;
        width: 66.66666666666667%;

        ${Col} {
            max-width: 100%;
        }
    `}

    .hide-mobile {
        ${mobile`
            display: none;
        `}
        ${tablet`
            display: none;
        `}
    }

    .show-mobile {
        ${desktop`
            display: none;
        `}
    }

    .info-row {
        flex-direction: row;
        padding: 0px 12px 8px;

        ${tabletLG`
            padding: 0px 16px 8px;
        `}

        ${tablet`
            padding: 0px 17px 8px;
        `}

        ${mobile`
            padding: 0px;
        `}
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
`

const ButtonRow = styled(Row)`
    margin-top: 32px;
`

const V2ButtonStyled = styled(V2Button)`
    border-radius: 16px;
    font-family: 'Noto sans';
    background-imag: none;
    background: #149954;
    font-weight: 400;
`

class AdditionalPax extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const {
            index,
            name,
            surname,
            birthdate,
            email,
            phone,
            phoneCode,
            trans,
            paxSmtp,
            paxErrors,
            emailErrorText,
        } = this.props

        return (
            trans['v2.sidebar_step.flight_itinerary'] !==
                nextProps.trans['v2.sidebar_step.flight_itinerary'] ||
            name !== nextProps.name ||
            surname !== nextProps.surname ||
            birthdate !== nextProps.birthdate ||
            email !== nextProps.email ||
            phone !== nextProps.phone ||
            phoneCode !== nextProps.phoneCode ||
            nextProps.phoneCode === '' ||
            emailErrorText !== nextProps.emailErrorText ||
            index !== nextProps.index ||
            paxSmtp !== nextProps.paxSmtp ||
            Object.keys(paxErrors[0] || {}).length !==
                Object.keys(nextProps.paxErrors[0] || {}).length
        )
    }

    render() {
        const {
            index,
            removePassenger,
            paxErrors,
            emailErrorText,
            handlePassengerState,
            flightDate,
            countries,
            paxSmtp,
            name,
            surname,
            birthdate,
            email,
            phone,
            phoneCode,
            mainUserCountry,
            legalGuardian,
            legalGuardianFullName,
            v2_amount,
            regulation,
            addPassenger,
            isAddEnabled,
            passengersLength,
        } = this.props

        return (
            <BlockContainer>
                <PassengerInfoContainer>
                    <PagePadding>
                        {index === 0 && (
                            <Row className="info-row">
                                <H1>{t`v2.passenger_details.other_passengers`}</H1>
                            </Row>
                        )}

                        <Row className="info-row">
                            <LeftContainer>
                                <span className="passenger-title">
                                    {t`v2_common.label.passenger`}&nbsp;
                                    {index + 1}
                                </span>
                            </LeftContainer>
                            <RightContainer>
                                <CloseIcon2
                                    onClick={e => removePassenger(e, index)}
                                    onTouchEnd={e => removePassenger(e, index)}
                                />
                            </RightContainer>
                        </Row>
                        <StyledFieldsRow>
                            <InputField
                                required
                                name="name"
                                label={t`v2_common.label.name`}
                                placeholder={t`v2_common.placeholder.name`}
                                id={`passenger-${index}-name`}
                                value={name}
                                onChange={e => handlePassengerState(e, index)}
                                errorText={t`${_get(
                                    paxErrors,
                                    '[0].name',
                                    ''
                                )}`}
                            />
                            <InputField
                                required
                                name="surname"
                                id={`passenger-${index}-surname`}
                                label={t`v2_common.label.surname`}
                                placeholder={t`v2_common.placeholder.surname`}
                                value={surname}
                                onChange={e => handlePassengerState(e, index)}
                                errorText={t`${_get(
                                    paxErrors,
                                    '[0].surname',
                                    ''
                                )}`}
                            />
                        </StyledFieldsRow>
                        <StyledFieldsRow>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <DatePicker
                                    required
                                    dateFormat="YYYY-MM-DD"
                                    type="text"
                                    name="birthdate"
                                    id={`passenger-${index}-birthdate`}
                                    label={t`v2_common.label.birthdate`}
                                    placeholder={t`v2_common.placeholder.birthdate`}
                                    initialViewDate={
                                        birthdate
                                            ? moment.utc(birthdate)
                                            : moment.utc('1989')
                                    }
                                    initialViewMode="years"
                                    isValidDate={this.validPaxBirthdate}
                                    startDate={2020}
                                    endDate={150}
                                    value={
                                        !birthdate ? '' : moment.utc(birthdate)
                                    }
                                    handleChange={e =>
                                        handlePassengerState(e, index)
                                    }
                                    errorText={t`${_get(
                                        paxErrors,
                                        '[0].birthdate',
                                        ''
                                    )}`}
                                />
                            </Col>
                            {birthdate && (
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AgeBox
                                        bgColor="#ffffff"
                                        age={getAgeInYears(
                                            birthdate,
                                            flightDate
                                        )}
                                    />
                                </Col>
                            )}
                        </StyledFieldsRow>
                        {getAgeInYears(birthdate, flightDate) >= adultAge && (
                            <StyledFieldsRow>
                                <InputField
                                    label={t`v2_common.label.email`}
                                    name="email"
                                    id={`passenger-${index}-birthdate`}
                                    type="email"
                                    placeholder={t`v2_common.placeholder.email`}
                                    value={email}
                                    onChange={e =>
                                        handlePassengerState(e, index)
                                    }
                                    errorText={
                                        emailErrorText ||
                                        t`${_get(paxErrors, '[0].email', '')}`
                                    }
                                    suggestedText={{
                                        text: emailMagic(email),
                                        callback: () => {
                                            handlePassengerState(
                                                {
                                                    target: {
                                                        name: 'email',
                                                        value: emailMagic(
                                                            email
                                                        ),
                                                    },
                                                },
                                                index
                                            )
                                            validateEmailSmtp(
                                                emailMagic(email),
                                                `passenger${index}`
                                            )
                                        },
                                    }}
                                    onBlur={() =>
                                        validateEmailSmtp(
                                            email,
                                            `passenger${index}`
                                        )
                                    }
                                    isValidSmtp={!paxSmtp}
                                    hasSmtpValidation={true}
                                />
                                <Col xs={12} md={12} lg={6}>
                                    <V2PhoneField
                                        isFocusHighligh={true}
                                        stickyOptionsList={true}
                                        onChange={e =>
                                            handlePassengerState(e, index)
                                        }
                                        maxLength={16}
                                        label={t`v2_common.label.phone`}
                                        placeholder={t`v2_common.placeholder.phone`}
                                        autoComplete="off"
                                        phoneCode={phoneCode || mainUserCountry}
                                        value={phone}
                                        prefixInputName="phoneCode"
                                        numberInputName="phone"
                                        idPrefix={`passenger-${index}`}
                                        countries={countries}
                                        onDrop={e => e.preventDefault()}
                                        errorText={t`${_get(
                                            paxErrors,
                                            '[0].phone',
                                            ''
                                        )}`}
                                    />
                                </Col>
                            </StyledFieldsRow>
                        )}
                        {!!birthdate &&
                            getAgeInYears(birthdate, flightDate) < adultAge && (
                                <StyledFieldsRow>
                                    <Col xs={12} md={12} lg={6}>
                                        <NewSelectField
                                            required
                                            label={t`v2_common.label.legal_guardian`}
                                            selectProps={{
                                                placeholder: t`v2_common_legal_guardian_select_placeholder`,
                                                clearable: false,
                                                options: additionalPaxGuardianOptions(
                                                    t
                                                ),
                                                name: 'legalGuardian',
                                                value: legalGuardian,
                                                onChange: e =>
                                                    handlePassengerState(
                                                        e,
                                                        index
                                                    ),
                                                loadingPlaceholder: t`common.placeholder.loading`,
                                            }}
                                            errorText={t`${_get(
                                                paxErrors,
                                                '[0].legalGuardian',
                                                ''
                                            )}`}
                                        />
                                    </Col>
                                    <InputField
                                        required
                                        placeholder={t`v2_common.label.legal_guardian_full_name_placeholder`}
                                        name="legalGuardianFullName"
                                        id={`passenger-${index}-legal_full_name`}
                                        label={t`v2_common.label.legal_guardian_full_name`}
                                        value={legalGuardianFullName}
                                        onChange={e =>
                                            handlePassengerState(e, index)
                                        }
                                        errorText={t`${_get(
                                            paxErrors,
                                            '[0].legalGuardianFullName',
                                            ''
                                        )}`}
                                    />
                                </StyledFieldsRow>
                            )}
                        {index + 1 === passengersLength && (
                            <ButtonRow className="info-row">
                                <V2ButtonStyled
                                    onClick={e => {
                                        if (isAddEnabled) {
                                            addPassenger(e)
                                        }
                                    }}
                                >
                                    {t`v2_claim_additional_passengers_add_btn`}
                                    {regulation === 'ca' && v2_amount === 0
                                        ? ''
                                        : ` + €${v2_amount}`}
                                </V2ButtonStyled>
                            </ButtonRow>
                        )}
                    </PagePadding>
                </PassengerInfoContainer>
            </BlockContainer>
        )
    }
}

export default AdditionalPax
