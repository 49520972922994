import React, { Component } from "react";
import { connect } from 'react-redux';
import styled from "styled-components";
import PropTypes from "prop-types";
import { CheckBox } from "../../../shared/components/Form";
import t from '../../../shared/translations';


const ReadMore = styled.div`
    max-height: ${({ visible }) => (!visible ? "100px" : "0px")};

    transition: max-height 0.4s;
    color: ${({ theme }) => theme.colors.primaryColor};
    overflow: hidden;
`

const Description = styled.div`
    max-height: ${({ visible }) => (visible ? "100px" : "0px")};
    overflow: hidden;
    transition-timing-function: ease;
    transition: max-height 0.4s;
`;

const Container = styled.div`
    margin-top: 10px;
`;

const MoreInfoLink = styled.div`
    margin-left: 31px;
    font-size: 12px;
    cursor: pointer;
`;

export const Item = styled.div`
    padding: 10px 0;
    ${({ isBlurred }) => isBlurred && `
        opacity: 0.8;
    `}
`;

class LetterPreferences extends Component {
    state = {
        visible1: this.props.showReadMore,
    };

    toggleReadMore = (id) => {
        const { disableReadMore } = this.props;
        if (disableReadMore) {
            return;
        } else {
            this.setState({ [`visible${id}`]: !this.state[`visible${id}`] });
        }
    }

    transDelay = (visible) => visible ? "0.4s" : "0s";

    render() {
        const { onChange, subscribe } = this.props;
        const { visible1} = this.state;
        return (
            <Container className="h-mb15">
                <Item isBlurred={!subscribe}>
                    <CheckBox
                        onChange={onChange}
                        label={t`subscription.subscribe`}
                        name="subscribe"
                        checked={subscribe}
                    />
                    <MoreInfoLink onClick={() => this.toggleReadMore(1)}>
                        <ReadMore visible={visible1} style={{ transitionDelay: this.transDelay(!visible1) }}>
                            {t`common.label.read_more`}
                        </ReadMore>
                        <Description visible={visible1} style={{ transitionDelay: this.transDelay(visible1) }}>
                            {t`subscription.description.subscribe`}
                        </Description>
                    </MoreInfoLink>

                </Item>
            </Container>
        );
    }
}

LetterPreferences.propTypes = {
    onChange: PropTypes.func.isRequired,
    subscribe: PropTypes.bool.isRequired,
    showReadMore: PropTypes.bool,
    disableReadMore: PropTypes.bool,
};

LetterPreferences.defaultProps = {
    showReadMore: false,
    disableReadMore: false,
};

export default connect(
    state => ({
        trans: state.translations,
    }))(LetterPreferences);