import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import Helmet from "react-helmet";
import { getUserData } from '../../../shared/actions/index';
import { loginPageShow, loginPageHide } from '../../../main';
import { browserHistory } from 'react-router';
import { PrimaryLinkText } from '../../../shared/components/styledComponents';
import t from '../../../shared/translations';
import { paths } from '../../../shared/constants';
import { showModal } from '../../../shared/actions/v2_actions'
import { modalTypes } from '../../../shared/constants';

class ResetPasswordSuccess extends Component {

    componentDidMount() {
        if (this.props.isAuthenticated) {
            browserHistory.push(paths.CLAIMS);
        }
        else {
            loginPageShow();
        }
    }

    componentDidUpdate() {
        loginPageShow();
    }

    componentWillUnmount() {
        loginPageHide();
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>{`Skycop - ${t`reset_pass.success.title`}`}</title>
                </Helmet>
                <a className="navbar-brand" href="/">skycop24</a>
                <div className="row">
                    <div className="col-md-16 offset-md-4 col-lg-10 offset-lg-7 h-pb60">
                        <div className="panel-box">
                            <h2 className="h-c0 h-mb20">{t`reset_pass.success.title`}</h2>
                            <p className="panel-box-desc">
                                {t`reset_pass.success.description`}
                                <Link to="/" onClick={() => this.props.showModal(modalTypes.loginModal)}> <PrimaryLinkText hoverColor="#333">{t`reset_pass.success.description_link`}</PrimaryLinkText></Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getUserData, showModal }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordSuccess);