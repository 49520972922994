import React, { Component } from 'react'
import t from '../../../shared/translations'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Col, Row, PageContent } from '../../../shared/components/V2/Layout'
import Helmet from 'react-helmet'
import V2SignBox from '../../../shared/components/V2/V2SignBox'
import TermsAndConditions from '../../../views/V2/components/TermsAndConditions'
import { browserHistory } from 'react-router'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import DOMPurify from 'dompurify'
import { getCurrencies, setLanguage } from '../../../shared/actions/index'
import _ from 'lodash'
import {
    getApiClient,
    V2handleSignatureLength,
    loadScreenOn,
    loadScreenOff,
    dataURItoBlob,
} from '../../../main'
import {
    MainContainer,
    HeaderContainer,
    TermsContainer,
    AgreementContainer,
} from './styles'

let selectErrors = {}

class ChatbotAgreement extends Component {
    state = {
        address: '',
        town: '',
        country: '',
        birthdate: '',
        initialData: {},
        termsCheck: false,
        manualBankValidation: false,
        signature: '',
        agreement: '',
        subscribe: false,
        type: '',
        bankDataRequired: false,
        bankDataApproved: false,
        needManualValidation: false,
        alertCheckbox: false,
        airlineTitle: 'airline',
        ibanLabel: 'payment_page.iban',
        isCountrySupportIban: true,
        ibanValid: true,
    }
    componentDidMount() {
        const { getCurrencies } = this.props
        getCurrencies()
        loadScreenOn()
        const urlParams = new URLSearchParams(window.location.search)
        const claimDraftId = urlParams.get('claimDraftId')
        const apiClient = getApiClient()
        const url = `/api/claim-draft/${claimDraftId}`
        const agrreementUrl = `/api/claim-draft/${claimDraftId}/agreements/sig`

        apiClient
            .get(url)
            .then(res => {
                this.setState({
                    ...mapInitialData(res.data),
                    initialData: res.data,
                    claimDraftId,
                })
            })
            .catch(err => console.warn(err))

        apiClient
            .get(agrreementUrl)
            .then(res => {
                this.setState({ agreement: DOMPurify.sanitize(res.data) })
            })
            .catch(err => console.warn(err))

        apiClient
            .get(`api/claim-draft/${claimDraftId}`)
            .then(res => {
                const locale = _.get(res, 'data[user][locale]', 'en')
                this.props.setLanguage(locale)
                this.setState({
                    bankDataRequired: _.get(
                        res,
                        'data[bank_data_required]',
                        false
                    ),
                    airlineTitle: _.get(res, 'data[airline_title]', 'airline'),
                })
            })
            .catch(err => console.warn(err))

        loadScreenOff()
    }

    componentWillUnmount() {
        this.setState({})
    }

    componentDidCatch(err) {
        loadScreenOff()
        console.log(err)
    }
    handleTermsAndConditionsChange = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
    }

    setSignatureState = signature => {
        V2handleSignatureLength(signature, 5000)
        this.setState({ signature })
    }

    clearCountry = () => {
        this.setState({ country: '' })
    }

    clearBankCountry = () => {
        this.setState({
            bankCountry: '',
            isCountrySupportIban: true,
            needManualValidation: false,
        })
    }

    clearAccountCurrency = () => {
        this.setState({ currency: '' })
    }

    checkRequired = (field, translation) => {
        if (!this.state[field] || this.state[field].trim() === '') {
            selectErrors[field] = translation
        }
    }

    accountLabel = () => {
        return `${t`${this.state.ibanLabel}`}`
    }

    submitData = data => {
        const { claimDraftId, signature } = this.state
        const apiClient = getApiClient()
        const url = `/api/add-claim-draft-document`
        const fd = new FormData()

        const blob = dataURItoBlob(signature)

        fd.append('claim_draft_document[claimDraft]', claimDraftId)

        fd.append('claim_draft_document[file]', blob)
        fd.append('claim_draft_document[type]', 'sig')

        apiClient
            .post(url, fd)
            .then(res => {
                setTimeout(() => {
                    window.open('about:blank', '_self')
                    window.close()
                }, 2000)
            })
            .catch(err => {
                console.warn(err)
                browserHistory.push(
                    `claim/chatbot?claimDraftId=${claimDraftId}`
                )
            })
            .finally(() => {
                loadScreenOff()
            })
    }

    completeSigning = () => {
        const { signature, termsCheck } = this.state

        const signatureDate = new Date()

        if (
            !termsCheck ||
            !signature ||
            V2handleSignatureLength(signature, 5000)
        ) {
            selectErrors.termsCheck = t`common.label.is_required.terms_and_conditions`
            this.setState({ errors: 'true' })
        } else if (
            signature &&
            !V2handleSignatureLength(signature, 5000) &&
            Object.keys(selectErrors).length === 0 &&
            selectErrors.constructor === Object
        ) {
            selectErrors = {}
            loadScreenOn()

            this.setState({ signatureDate })
            this.submitData(this.state)
        }
    }

    render() {
        const { name, surname, termsCheck, signature, agreement } = this.state

        if (!this.props.trans || !agreement) return null

        return (
            <MainContainer>
                <PageContent>
                    <Helmet>
                        <title>{`Skycop - ${t`sign_form.title_part1`} ${t`sign_form.title_part2_bold`}`}</title>
                    </Helmet>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <HeaderContainer>
                                <h1>
                                    {t`sign_form.title_part1`}
                                    <span>
                                        {' '}
                                        {t`sign_form.title_part2_bold`}
                                    </span>
                                </h1>
                            </HeaderContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <AgreementContainer
                                className="h-mb-8"
                                dangerouslySetInnerHTML={{
                                    __html: agreement,
                                }}
                            />
                        </Col>
                    </Row>
                    <div id="scroll-top-div" />
                    <Row className="h-mb-8 h-mt-4">
                        <Col md={12} lg={12}>
                            <V2SignBox
                                border
                                name={name}
                                surname={surname}
                                handleSignatureState={this.setSignatureState}
                                clearLabel={t`v2_common.clear`}
                                signature={signature}
                                hasBox={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TermsContainer>
                                <TermsAndConditions
                                    text={t`v2_claim.passenger_details.terms_and_conditions_price_list`}
                                    onChange={
                                        this.handleTermsAndConditionsChange
                                    }
                                    checked={termsCheck}
                                />
                            </TermsContainer>
                        </Col>
                    </Row>
                    {selectErrors['termsCheck'] ? (
                        <Row>
                            <Col className="form-control-feedback has-danger text-center h-mb15">
                                {selectErrors['termsCheck']}
                            </Col>
                        </Row>
                    ) : null}
                    <Row style={{ justifyContent: 'center' }}>
                        <Col md={4} lg={4}>
                            <V2Button onClick={this.completeSigning}>
                                {t`signature_form.button.complete_signing`}
                            </V2Button>
                        </Col>
                    </Row>
                </PageContent>
            </MainContainer>
        )
    }
}

function mapInitialData(data) {
    return {
        name: data.first_name,
        surname: data.last_name,
        type: data.type,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLanguage, getCurrencies }, dispatch)
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        modal: state.toggle_modal,
        lang: state.language,
        currencies: state.currencies,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotAgreement)
