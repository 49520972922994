import { V2_ACTIONS } from '../../actions/actionTypes'

const INITIAL_STATE = {
    claimId: '',
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.LEAVING_MODAL:
            return {
                ...state,
                claimId: action.payload,
            }
        default:
    }
    return state
}
