import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import latinize from 'latinize'
import _get from 'lodash/get'
import { Container, ContentContainer } from '../../V2'
import {
    PageViewContainer,
    PageContent,
    Row,
    Col,
    SidebarContainer,
} from '../../../shared/components/V2/Layout'
import { PageTitle } from '../../../shared/components/V2/TextElements'
import InputField from '../../../shared/components/V2/InputField'
import t from '../../../shared/translations'
import V2PhoneField from '../../../shared/components/V2/V2PhoneField'
import { emailMagic } from '../../../shared/validators'
import { validateEmailSmtp, getUserData } from '../../../shared/actions'
import { SPACE_KEY } from '../../../shared/components/V2/Modal'
import { V2Button2 } from '../../../shared/components/V2/V2Buttons'
import { SlideInput } from './SlideInput'
import { TermsAndConditionsCheckbox } from '../../V2/components/TermsAndConditions'
import {
    showModal,
    validateSkycopCareView,
    getSkycopCareProducts,
    submitStripePayment,
    registerUser,
} from '../../../shared/actions/v2_actions'
import { bindActionCreators } from 'redux'
import {
    desktop,
    desktopLG,
    desktopSM,
    mobile,
    tablet,
    tabletSM,
} from '../../../shared/components/helpers/styled_queries'
import { scrollTo, isValidEmailInput } from '../../../main'
import store from '../../../shared/services/store'
import { V2_ACTIONS } from '../../../shared/actions/actionTypes'
import CountryInput from '../../V2/components/CountryInput'
import { FamilyMembers } from './FamilyMembers'
import { fireGtagEvent } from '../../../shared/services/events'
import { PersonalProducts } from './PersonalProducts'
import { FamilyProducts } from './FamilyProducts'
import ToolTip from '../../../shared/components/V2/V2Tooltip'
import CreateAccountCheckbox from './CreateAccountCheckbox'
import { modalTypes } from '../../../shared/constants'
import { authUserExternal } from '../../../shared/actions/actions'
import { QuestionMarkIcon2 } from '../../../shared/components/V2/SkycopPay/icons'

export const QuestionMarkIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.995 12.2947V12.9944C10.995 12.9944 9.19567 12.9944 8.99574 12.9944V12.3947C8.99574 11.7949 9.0957 10.9952 9.79543 10.2955C10.4952 9.59576 11.3948 9.09596 11.3948 8.19631C11.3948 7.29666 10.6951 6.79686 9.99535 6.79686C8.69586 6.79686 8.5959 8.19631 8.49594 8.49619H6.59668C6.59668 7.09674 7.19645 4.99756 9.99535 4.99756C12.3944 4.99756 13.394 6.59693 13.394 7.99639C13.394 10.3955 10.995 10.7953 10.995 12.2947Z"
            fill="#32325D"
        />
        <path
            d="M9.99512 15.9932C10.5474 15.9932 10.9951 15.5454 10.9951 14.9932C10.9951 14.4409 10.5474 13.9932 9.99512 13.9932C9.44283 13.9932 8.99512 14.4409 8.99512 14.9932C8.99512 15.5454 9.44283 15.9932 9.99512 15.9932Z"
            fill="#32325D"
        />
        <path
            d="M9.995 1.999C14.3928 1.999 17.991 5.5972 17.991 9.995C17.991 14.3928 14.3928 17.991 9.995 17.991C5.5972 17.991 1.999 14.3928 1.999 9.995C1.999 5.5972 5.5972 1.999 9.995 1.999ZM9.995 0C4.49775 0 0 4.49775 0 9.995C0 15.4922 4.49775 19.99 9.995 19.99C15.4922 19.99 19.99 15.4922 19.99 9.995C19.99 4.49775 15.4922 0 9.995 0Z"
            fill="#32325D"
        />
    </svg>
)

export const TooltipInner = styled.div`
    color: #717171;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    padding: 20px;
    text-align: left;
    p {
        color: #717171;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px
        margin: 0;
        text-align: left;
    }

    .svg-close {
        display: none;
    }

    a {
        font-weight: bold;
        cursor: pointer;
        color: #717171;
        transition: none;
    }
`
export const InfoBox = styled.div`
    position: relative;
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 8px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
`

const StyledPageContent = styled(PageContent)`
    ${desktop`
        padding: 52px 32px;
        max-width: 870px;
        width: ${({ width }) => width && width}
    `}

    ${mobile`
        padding: 0px 4px;
        `}

    .form-group {
        margin-bottom: 0;
    }

    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            display: flex;
        }
    }

    .slider-row {
        flex-direction: row;
        margin-bottom: 16px;
    }
`

export const ProcessBubble = styled.div`
    width: 36px;
    height: 40px;
    min-width: 40px;
    min-height: 36px;
    margin-right: 12px;
    color: #ffffff;
    background: #149954;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 100%;
    font-size: 16px;
`

export const BubbleSubtitle = styled.div`
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #333333;
    display: inline-flex;
    font-family: Noto Sans;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '20')}px;
    font-weight: 600;
    text-align: left;
    align-items: center;
    text-transform: ${({ capitalize }) => capitalize && 'capitalize'};
    margin-top: ${({ marginTop }) => marginTop && marginTop}px;
`

const TotalText = styled.div`
    display: flex;

    .amt {
        margin-left: auto;
        display: inline-flex;
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.4px;
        color: #717171;
    }
`

const SummaryContainer = styled.div`
    display: flex;

    .amt {
        margin-left: auto;
        display: inline-flex;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.57;
        color: #717171;
        padding-left: 40px;
    }
`

const SummaryTitle = styled.div`
    color: #717171;
    display: inline-flex;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
`

const StyledFieldsRow = styled(Row)`
    margin-bottom: 16px;

    ${tablet`
        margin-bottom: 0px;
        `}

    ${mobile`
        margin-bottom: 0px;
        `}
`

const OrderSummaryMobile = styled.div`
    margin: 0px -4px;
    ${desktop`
    display: none;
`}
    ${desktopSM`
    display: none;
`}
    ${desktopLG`
    display: none;
`}

background: #F5F5F5; 
padding-bottom: 32px;
padding-top: 12px;
`

export const PlusIcon = ({ fill }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
    >
        <path
            d="M13 7.99805H8V12.998C8 13.2633 7.89464 13.5176 7.70711 13.7052C7.51957 13.8927 7.26522 13.998 7 13.998C6.73478 13.998 6.48043 13.8927 6.29289 13.7052C6.10536 13.5176 6 13.2633 6 12.998V7.99805H1C0.734784 7.99805 0.48043 7.89269 0.292893 7.70515C0.105357 7.51762 0 7.26326 0 6.99805C0 6.73283 0.105357 6.47848 0.292893 6.29094C0.48043 6.1034 0.734784 5.99805 1 5.99805H6V0.998047C6 0.73283 6.10536 0.478476 6.29289 0.29094C6.48043 0.103403 6.73478 -0.00195313 7 -0.00195312C7.26522 -0.00195313 7.51957 0.103403 7.70711 0.29094C7.89464 0.478476 8 0.73283 8 0.998047V5.99805H13C13.2652 5.99805 13.5196 6.1034 13.7071 6.29094C13.8946 6.47848 14 6.73283 14 6.99805C14 7.26326 13.8946 7.51762 13.7071 7.70515C13.5196 7.89269 13.2652 7.99805 13 7.99805Z"
            fill={fill || '#525F7F'}
        />
    </svg>
)

const PlansText = styled.p`
    color: #717171;
    font-weight: 400;
    font-family: Noto Sans;
    font-size: 20px;
    line-height: 29.6px;

    &.selected {
        color: #000000;
        font-weight: 600;
    }
`

const PaymentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const PaymentText = styled.div`
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    text-align: left;
    color: #333333;
    :first-child {
        margin-bottom: 16px;
    }
`

const StyledAddMemberBtn = styled(V2Button2)`
    height: 52px;
    // width: 100%;
    padding: 16px 32px 16px 32px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    margin-top: 16px;

    ${mobile`
           margin-top: 12px;
        `}
    svg {
        margin-left: 8px;
    }
`

export const DashedLine = styled.div`
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '16px')};
    margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
    height: 2px;
    background-image: linear-gradient(
        to right,
        #717171 40%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
`

const MobileViewStyledRow = styled(Row)`
 ${desktop`
    display: none;
`}
    ${desktopSM`
    display: none;
`}
    ${desktopLG`
    display: none;
`}
    margin-top: 32px;
`

const StyledSidebarContainer = styled(SidebarContainer)`
    height: fit-content;

    ${tabletSM`
            display: none;
        `}
    ${mobile`
            display: none;
        `}
`

const StyledCol = styled(Col)`
    margin-top: 24px;
`

const SecondBubleRow = styled(Row)`
    margin-top: 24px;
    margin-bottom: 8px;

    ${mobile`
        margin-top: 16px;
        `}
`

class SkycopCare extends Component {
    constructor(props) {
        super(props)

        this.state = {
            registrationErrors: null,
            passwordError: null,
            loading: false,
            clientSecret: '',
            errors: {},
            coupon: '',
            validCoupon: null,
            user: {
                name: '',
                surname: '',
                email: '',
                phone: '',
                phoneCode: '',
                country: '',
                passengersCount: 0,
            },
            selectedPlan: '',
            termsCheck: false,
            passengers: [],
            stripeErrors: {},
            promoType: '',
            familyPlans: false,
            isCreateAccChecked: false,
            preload: false,
        }
    }

    setLoading = (loading = true) => {
        this.setState({
            loading,
        })
    }

    componentDidMount() {
        const { location, getUserData, postedClaim } = this.props
        const type = _get(location, 'query.type', null)
        const isFamily = _get(location, 'query.family', null)
        const activeProductName = _get(location, 'query.plan-type', null)

        if (isFamily) {
            this.setState({ familyPlans: true })
        }

        window &&
            window.addEventListener('beforeunload', () => {
                fireGtagEvent({ event: 'skycop_care_beforeunload' })
            })

        if (
            location &&
            location.pathname &&
            location.pathname !== '/skycop-care'
        ) {
            browserHistory.push('/skycop-care')
        }

        if (postedClaim && postedClaim.user) {
            const mappedUserData = mapUser(postedClaim.user)
            this.setState({ user: mappedUserData })
        }

        getUserData().then(response => {
            if (response) {
                const mappedUserData = mapUser(response)
                this.setState({ user: mappedUserData, preload: true })
            }
        })

        getSkycopCareProducts(type).then(products => {
            if (products) {
                const activePlanId = products.filter(
                    product => product.name === activeProductName
                )
                this.setState({
                    selectedPlan:
                        _get(activePlanId[0], 'id', null) ||
                        _get(products, '[0].id', ''),
                    promoType: type,
                })

                if (window && window.dataLayer) {
                    window.dataLayer.push({
                        event: 'SkycopCare-Entering',
                    })

                    window.dataLayer.push({
                        event: 'view_item',
                        ecommerce: {
                            items: mapItems(products),
                        },
                    })
                }
            } else {
                browserHistory.replace('/not-found')
            }
        })

        scrollTo(document.body, 0)
    }

    componentDidUpdate(props) {
        if (props.isAuthenticated && !this.state.preload) {
            if (props.user) {
                const mappedUserData = mapUser(props.user)
                this.setState({ user: mappedUserData, preload: true })
            }
        }

        if (!props.isAuthenticated && this.state.preload) {
            this.setState({ preload: false })
        }
    }

    submitPayment = async () => {
        const { products, lang, registerUser } = this.props
        const {
            selectedPlan,
            user,
            passengers,
            promoType,
            familyPlans,
            isCreateAccChecked,
        } = this.state

        if (!isCreateAccChecked) {
            delete user.password
        }
        const childCustomers = mapFamilyMember(passengers)

        if (isCreateAccChecked) {
            let fd = new FormData()

            fd.append('user[email]', user.email)
            fd.append('user[plainPassword][first]', user.password)
            fd.append('user[plainPassword][second]', user.password)
            fd.append('user[name]', user.name)
            fd.append('user[surname]', user.surname)
            fd.append('user[phone]', user.phone)
            fd.append('user[country]', user.country)
            fd.append('user[phoneCodeCountryId]', user.phoneCode)

            registerUser(
                fd,
                t`login.error.unable_to_register`,
                t`login.error.email_exists`,
                lang
            )
                .then(error => {
                    if (error === 'email.exists') {
                        this.setState({
                            passwordError: t`login.error.email_exists`,
                            registrationErrors: {
                                email: ' ',
                            },
                        })
                    } else {
                        this.setState({ registrationErrors: error })
                    }

                    return error
                })
                .then(error => {
                    if (!!error) return
                    this.setState({ registrationErrors: null })
                    submitStripePayment(
                        selectedPlan,
                        1,
                        user,
                        familyPlans,
                        childCustomers,
                        promoType
                    ).then(response => {
                        const paymentUrl = _get(response, 'paymentUrl', null)

                        if (paymentUrl) {
                            if (window && window.dataLayer) {
                                const item = products.find(
                                    prod => prod.id === selectedPlan
                                )
                                sessionStorage.setItem(
                                    'sc',
                                    JSON.stringify(mapItems([item]))
                                )

                                window.dataLayer.push({
                                    event: 'begin_checkout',
                                    ecommerce: {
                                        items: mapItems([item]),
                                    },
                                })

                                window.open(paymentUrl, '_self')
                            }
                        }
                    })
                })
        } else {
            const response = await submitStripePayment(
                selectedPlan,
                1,
                user,
                familyPlans,
                childCustomers,
                promoType
            )
            const paymentUrl = _get(response, 'paymentUrl', null)

            if (paymentUrl) {
                if (window && window.dataLayer) {
                    const item = products.find(prod => prod.id === selectedPlan)
                    sessionStorage.setItem(
                        'sc',
                        JSON.stringify(mapItems([item]))
                    )

                    window.dataLayer.push({
                        event: 'begin_checkout',
                        ecommerce: {
                            items: mapItems([item]),
                        },
                    })

                    window.open(paymentUrl, '_self')
                }
            }
        }
    }

    onlyNumbers = e => {
        const re = /^[0-9]+/g

        if (!re.test(e.key)) {
            e.preventDefault()
        }
    }

    handleUserState = e => {
        if (!e) {
            return
        }

        const { name, value } = _get(e, 'target', e)
        let newValue = value

        if (['name', 'surname'].includes(name)) {
            newValue = latinize(value)
        } else if (name === 'email') {
            if (!isValidEmailInput(value)) {
                return
            }
        }

        if (['email'].includes(name)) {
            this.setState({ registrationErrors: null })
        }

        this.setState(
            prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    [name]: newValue,
                },
            }),
            () => this.recheckErrors(name)
        )
    }

    onChangePlan = selectedPlan => {
        this.setState({ selectedPlan })
    }

    onChangeTerms = () => {
        this.setState({ termsCheck: !this.state.termsCheck })
    }

    elemScrollIfPossible = elem => {
        if (elem && elem.getBoundingClientRect()) {
            scrollTo(
                document.body,
                elem.getBoundingClientRect().top + window.scrollY - 110,
                1000
            )
        }
    }

    recheckErrors = name => {
        const { sc_errors } = this.props
        const { errors } = sc_errors

        if (name && errors && errors[name]) {
            store.dispatch({
                type: V2_ACTIONS.SET_SKYCOPCARE_ERRORS,
                payload: this.state.user,
            })
        }
    }

    clearUserCountry = () => {
        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                country: '',
                countryCode: '',
            },
        }))
    }

    handleCountryInput = e => {
        if (!e) return

        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                country: e.value,
                countryCode: e.code,
                phoneCode:
                    !!prevState.user.phoneCode || !!prevState.user.phone
                        ? prevState.user.phoneCode
                        : e.value,
            },
        }))
    }

    validateStep = () => {
        const { user, passengers, familyPlans, isCreateAccChecked } = this.state

        const { isAuthenticated } = this.props
        const validFamilyMembers = passengers.every(
            passenger => passenger.name && passenger.surname
        )
        const validUser = !!(
            user &&
            user.name &&
            user.surname &&
            user.email &&
            user.phone &&
            user.country
        )

        const isSignInValid =
            isCreateAccChecked && !isAuthenticated ? !!user.password : true

        return familyPlans
            ? isSignInValid && validUser && validFamilyMembers
            : validUser && isSignInValid
    }

    handleAddNewPassenger = () => {
        const { passengers } = this.state

        if (passengers.length >= 4) return

        this.setState({
            ...this.state,
            passengers: [
                ...passengers,
                {
                    id: passengers.length + 1,
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    phoneCode: '',
                    country: '',
                    sc_errors: [],
                },
            ],
        })
    }

    handlePassengerRemove = id => {
        if (!id) return

        const filteredPassenger = this.state.passengers.filter(p => p.id !== id)
        const updatedPassengers = filteredPassenger.reduce(
            (acc, curr, index) => {
                return [...acc, { ...curr, id: index + 1 }]
            },
            []
        )

        this.setState({ ...this.state, passengers: updatedPassengers })
    }

    handlePassengerChange = (e, id) => {
        if (!e || !id) return

        const { name, value } = _get(e, 'target', e)
        let newValue = value

        if (['name', 'surname'].includes(name)) {
            newValue = latinize(value)
        } else if (name === 'email') {
            if (!isValidEmailInput(value)) {
                return
            }
        }

        const updatedPassengers = this.state.passengers.map(passenger =>
            passenger.id === id
                ? {
                      ...passenger,
                      [name]: newValue,
                  }
                : passenger
        )

        this.setState(prevState => ({
            ...prevState,
            passengers: updatedPassengers,
        }))
    }

    handleSlideChange = () => {
        const { products } = this.props

        this.setState(prev => ({
            familyPlans: !prev.familyPlans,
            selectedPlan: !prev.familyPlans
                ? _get(
                      products.filter(p => p.family),
                      '[0].id'
                  )
                : _get(products, '[0].id', ''),
        }))
    }

    handleCreateAccCheckbox = () => {
        this.setState({ isCreateAccChecked: !this.state.isCreateAccChecked })
    }

    handleSignIn = () => {
        const { showModal } = this.props
        showModal(modalTypes.loginModal)
    }

    validateRegisterPasswords = () => {
        const { user, isCreateAccChecked } = this.state

        if (!isCreateAccChecked) {
            this.setState({ passwordError: null })
        } else {
            if (user.password && user.password.length < 8) {
                this.setState({
                    passwordError: t`common.label.too_short.password`,
                })
            } else {
                this.setState({ passwordError: null })
            }
        }
    }

    render() {
        const {
            trans,
            countries,
            is_valid_smtp,
            sc_errors,
            products,
            isAuthenticated,
            authUserExternal,
        } = this.props
        const {
            user,
            selectedPlan,
            termsCheck,
            loading,
            promoType,
            passengers,
            familyPlans,
            isCreateAccChecked,
            passwordError,
            registrationErrors,
        } = this.state

        if (!trans) {
            return null
        }

        const currPlan = products.find(prod => prod.id === selectedPlan)
        const price = (currPlan || { price: 0 }).price
        const priceWithDiscount = calculatePrice(
            price / 100,
            _get(currPlan, 'discount.value', 0) / 100
        )
        const disabledButton = !termsCheck || !this.validateStep() || loading

        return (
            <Container>
                <ContentContainer>
                    <PageViewContainer id="step-page-view">
                        <StyledPageContent paddingTop={'0px'}>
                            <PageTitle
                                title={t`sc.title.checkout_page_title`}
                                metaDesc={
                                    'Depending on your travel habits and flying routine, you can choose the best plan from our extended portfolio - starting from €1.99 per month.'
                                }
                            />

                            <Row>
                                <Col
                                    style={{
                                        display: 'flex',
                                        marginBottom: '12px',
                                    }}
                                >
                                    <ProcessBubble>1</ProcessBubble>
                                    <BubbleSubtitle>{t`sc.title.choose_plan`}</BubbleSubtitle>
                                </Col>
                            </Row>
                            {!promoType && (
                                <Row>
                                    <Col>
                                        <Row className="slider-row">
                                            <PlansText
                                                className={
                                                    !familyPlans && 'selected'
                                                }
                                            >{t`sc.plans.standart`}</PlansText>
                                            <div style={{ margin: '0 12px' }}>
                                                <SlideInput
                                                    checked={familyPlans}
                                                    onChange={
                                                        this.handleSlideChange
                                                    }
                                                />
                                            </div>
                                            <PlansText
                                                className={
                                                    familyPlans && 'selected'
                                                }
                                            >{t`sc.plans.family`}</PlansText>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                            {familyPlans ? (
                                <FamilyProducts
                                    products={products}
                                    currPlan={currPlan}
                                    selectedPlan={selectedPlan}
                                    calculatePrice={calculatePrice}
                                    promoType={promoType}
                                    onChangePlan={this.onChangePlan}
                                    t={t}
                                />
                            ) : (
                                <PersonalProducts
                                    products={products}
                                    currPlan={currPlan}
                                    selectedPlan={selectedPlan}
                                    calculatePrice={calculatePrice}
                                    onChangePlan={this.onChangePlan}
                                    promoType={promoType}
                                    t={t}
                                />
                            )}
                            <Col sm={12} md={12} lg={12}>
                                <DashedLine marginTop="8px" />
                            </Col>
                            <SecondBubleRow>
                                <Col
                                    style={{
                                        display: 'flex',
                                        marginBottom: '12px',
                                    }}
                                >
                                    <ProcessBubble>2</ProcessBubble>
                                    <BubbleSubtitle>{t`sc.title.passenger_info`}</BubbleSubtitle>
                                </Col>
                            </SecondBubleRow>

                            <StyledFieldsRow>
                                <InputField
                                    name="name"
                                    id="user-name"
                                    label={t`v2_common.label.name`}
                                    required
                                    placeholder={t`v2_common.placeholder.name`}
                                    value={user.name}
                                    onChange={this.handleUserState}
                                    errorText={
                                        t`${_get(
                                            sc_errors,
                                            'errors.name',
                                            ''
                                        )}` ||
                                        _get(registrationErrors, 'name', '')
                                    }
                                    autoComplete="name-first"
                                />
                                <InputField
                                    required
                                    label={t`v2_common.label.surname`}
                                    name="surname"
                                    id="user-surname"
                                    placeholder={t`v2_common.placeholder.surname`}
                                    value={user.surname}
                                    onChange={this.handleUserState}
                                    errorText={
                                        t`${_get(
                                            sc_errors,
                                            'errors.surname',
                                            ''
                                        )}` ||
                                        _get(registrationErrors, 'surname', '')
                                    }
                                    autoComplete="name-last"
                                />
                            </StyledFieldsRow>
                            <StyledFieldsRow>
                                <InputField
                                    label={t`v2_common.label.email`}
                                    name="email"
                                    type="email"
                                    id="user-email"
                                    required
                                    placeholder={t`v2_common.placeholder.email`}
                                    value={user.email}
                                    onChange={this.handleUserState}
                                    errorText={
                                        t`${_get(
                                            sc_errors,
                                            'errors.email',
                                            ''
                                        )}` ||
                                        _get(registrationErrors, 'email', '')
                                    }
                                    suggestedText={{
                                        text: emailMagic(user.email),
                                        callback: () => {
                                            this.handleUserState({
                                                target: {
                                                    name: 'email',
                                                    value: emailMagic(
                                                        user.email
                                                    ),
                                                },
                                            })
                                            validateEmailSmtp(
                                                emailMagic(user.email)
                                            )
                                        },
                                    }}
                                    onBlur={() => validateEmailSmtp(user.email)}
                                    isValidSmtp={!!is_valid_smtp}
                                    hasSmtpValidation={true}
                                    autoComplete="email"
                                    onKeyDown={e => {
                                        if (e.keyCode === SPACE_KEY) {
                                            e.preventDefault()
                                            return false
                                        }
                                    }}
                                    hasDanger={
                                        !!is_valid_smtp &&
                                        !!_get(
                                            this,
                                            'state.componentState.repeatEmailError',
                                            ''
                                        )
                                    }
                                />
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <CountryInput
                                        isFocusHighligh={true}
                                        stickyOptionsList={true}
                                        label={t`v2_common.label.country`}
                                        required
                                        value={user.country}
                                        defaultValue={user.country}
                                        onChange={this.handleCountryInput}
                                        name="country"
                                        id="user-country"
                                        errorText={
                                            t`${_get(
                                                sc_errors,
                                                'errors.country',
                                                ''
                                            )}` ||
                                            _get(
                                                registrationErrors,
                                                'country',
                                                ''
                                            )
                                        }
                                        clear={this.clearUserCountry}
                                        autoComplete="country"
                                    />
                                </Col>
                            </StyledFieldsRow>
                            <StyledFieldsRow>
                                <Col xs={12} md={12} lg={6}>
                                    <V2PhoneField
                                        isFocusHighligh={true}
                                        required
                                        label={t`v2_common.label.phone`}
                                        placeholder={t`v2_common.placeholder.phone`}
                                        onChange={this.handleUserState}
                                        maxLength={16}
                                        autoComplete="off"
                                        phoneCode={user.phoneCode}
                                        value={user.phone}
                                        prefixInputName="phoneCode"
                                        numberInputName="phone"
                                        idPrefix="user"
                                        countries={countries}
                                        onDrop={e => e.preventDefault()}
                                        stickyOptionsList={true}
                                        errorText={
                                            t`${_get(
                                                sc_errors,
                                                'errors.phone',
                                                ''
                                            )}` ||
                                            '' ||
                                            _get(
                                                registrationErrors,
                                                'phone',
                                                ''
                                            )
                                        }
                                    />
                                </Col>

                                {isCreateAccChecked && !isAuthenticated && (
                                    <InputField
                                        name="password"
                                        type="password"
                                        id="user-password"
                                        label={t`v2_common.label.password`}
                                        required
                                        placeholder={t`v2_common.placeholder.password`}
                                        value={user.password}
                                        onChange={this.handleUserState}
                                        onBlur={() => {
                                            this.validateRegisterPasswords()
                                        }}
                                        errorText={
                                            t`${_get(
                                                sc_errors,
                                                'errors.password',
                                                ''
                                            )}` || passwordError
                                        }
                                        autoComplete="password"
                                        hasDanger={!!passwordError}
                                    />
                                )}
                            </StyledFieldsRow>
                            {!isAuthenticated && (
                                <Row>
                                    <StyledCol>
                                        <CreateAccountCheckbox
                                            checked={isCreateAccChecked}
                                            onChange={
                                                this.handleCreateAccCheckbox
                                            }
                                            handleSignIn={this.handleSignIn}
                                            onLoginExternalSuccess={() =>
                                                authUserExternal()
                                            }
                                        />
                                    </StyledCol>
                                </Row>
                            )}

                            {familyPlans && (
                                <>
                                    <Row
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <Col style={{ display: 'flex' }}>
                                            <ProcessBubble>{3}</ProcessBubble>
                                            <BubbleSubtitle>{t`sc.title.family_member_info`}</BubbleSubtitle>
                                            <InfoBox
                                                data-tip
                                                data-for={`tooltip-family-members`}
                                            >
                                                <QuestionMarkIcon2 />
                                                <ToolTip
                                                    clickable={true}
                                                    dataTip="disabled"
                                                    place="bottom"
                                                    color="light"
                                                    effect="solid"
                                                    id={`tooltip-family-members`}
                                                    delayHide={100}
                                                    className="customHover"
                                                >
                                                    <TooltipInner
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${t`sc.family_plan.tooltip`}`,
                                                        }}
                                                    />
                                                </ToolTip>
                                            </InfoBox>
                                        </Col>
                                    </Row>
                                    {passengers.map((passenger, index) => (
                                        <React.Fragment key={index}>
                                            <FamilyMembers
                                                user={passenger}
                                                index={index + 1}
                                                sc_errors={passenger.sc_errors}
                                                s
                                                handleChange={event =>
                                                    this.handlePassengerChange(
                                                        event,
                                                        index + 1
                                                    )
                                                }
                                                handleRemove={() =>
                                                    this.handlePassengerRemove(
                                                        index + 1
                                                    )
                                                }
                                            />
                                            <Row>
                                                {passengers.length >
                                                    index + 1 && (
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <DashedLine marginTop="16px" />
                                                    </Col>
                                                )}
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                    <Row style={{ flexDirection: 'column' }}>
                                        {passengers.length > 0 && (
                                            <Col sm={12} md={12} lg={12}>
                                                <DashedLine marginTop="16px" />
                                            </Col>
                                        )}
                                        {passengers.length < 4 && (
                                            <Col xs={8} sm={8} md={7} lg={5}>
                                                <StyledAddMemberBtn
                                                    disabledStyle={
                                                        passengers.length >= 4
                                                    }
                                                    type="borderBtn"
                                                    onClick={() =>
                                                        this.handleAddNewPassenger()
                                                    }
                                                >
                                                    {t`sc.family_member.add_new`}
                                                    <PlusIcon fill="#149954" />
                                                </StyledAddMemberBtn>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            )}

                            <MobileViewStyledRow>
                                <Col xs={12} sm={12} md={12} lg={12}></Col>
                            </MobileViewStyledRow>

                            {selectedPlan && (
                                <OrderSummaryMobile>
                                    <Row>
                                        <Col>
                                            <BubbleSubtitle
                                                capitalize={true}
                                                fontSize={24}
                                            >{t`sc.title.order_summary`}</BubbleSubtitle>
                                        </Col>
                                        <Col sm={12} md={12} lg={12}>
                                            <DashedLine marginTop="unset" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            style={{ paddingBottom: 0 }}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                        >
                                            <SummaryContainer>
                                                <SummaryTitle>
                                                    {`${t`sc.subtitle.skycop_care_plan`} (${
                                                        familyPlans
                                                            ? t`sc.title.plan.family.${currPlan.name}`
                                                            : t`sc.title.plan.${currPlan.name}`
                                                    }) x1`}
                                                </SummaryTitle>
                                                <span className="amt">
                                                    {priceWithDiscount}
                                                    &euro;
                                                </span>
                                            </SummaryContainer>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            {/* <SplitRow bg={'#e9ecf1'} /> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} lg={12}>
                                            <TotalText>
                                                <BubbleSubtitle>{t`sc.subtitle.total`}</BubbleSubtitle>
                                                <span className="amt">
                                                    <BubbleSubtitle>
                                                        {priceWithDiscount}
                                                        &euro;
                                                    </BubbleSubtitle>
                                                </span>
                                            </TotalText>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '24px' }}>
                                        <Col>
                                            <TermsAndConditionsCheckbox
                                                text={t`sc.checkbox.terms_and_conditions_price_list`}
                                                onChange={this.onChangeTerms}
                                                checked={termsCheck}
                                                required={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <DashedLine />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col sm={12} md={4} lg={4}>
                                            <V2Button2
                                                disabled={disabledButton}
                                                onClick={() => {
                                                    if (disabledButton) return
                                                    this.submitPayment()
                                                }}
                                            >
                                                {loading ? (
                                                    <Loader
                                                        type="Oval"
                                                        color="#848484"
                                                        height="23"
                                                        width="23"
                                                    />
                                                ) : (
                                                    t`sc.button.buy_now`
                                                )}
                                            </V2Button2>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '24px' }}>
                                        <Col sm={12} md={12} lg={12}>
                                            <PaymentsWrapper>
                                                <PaymentText>{t`skycop.care.purschase.charge.part1`}</PaymentText>
                                                <PaymentText>{t`skycop.care.purschase.charge.part2`}</PaymentText>
                                            </PaymentsWrapper>
                                        </Col>
                                    </Row>
                                </OrderSummaryMobile>
                            )}
                        </StyledPageContent>
                        <StyledSidebarContainer>
                            <StyledPageContent paddingTop={'0px'} width="400px">
                                {selectedPlan && (
                                    <>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <BubbleSubtitle
                                                    marginTop={12}
                                                    capitalize={true}
                                                    fontSize={24}
                                                >{t`sc.title.order_summary`}</BubbleSubtitle>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <DashedLine
                                                    marginTop="14px"
                                                    marginBottom={15}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{ paddingBottom: 0 }}
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                            >
                                                <SummaryContainer>
                                                    <SummaryTitle>
                                                        {`${t`sc.subtitle.skycop_care_plan`} (${
                                                            familyPlans
                                                                ? t`sc.title.plan.family.${currPlan.name}`
                                                                : t`sc.title.plan.${currPlan.name}`
                                                        }) x1`}
                                                    </SummaryTitle>
                                                    <span className="amt">
                                                        {priceWithDiscount}
                                                        &euro;
                                                    </span>
                                                </SummaryContainer>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                            ></Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} lg={12}>
                                                <TotalText>
                                                    <BubbleSubtitle>{t`sc.subtitle.total`}</BubbleSubtitle>
                                                    <span className="amt">
                                                        <BubbleSubtitle>
                                                            {priceWithDiscount}
                                                            &euro;
                                                        </BubbleSubtitle>
                                                    </span>
                                                </TotalText>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <DashedLine />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '24px' }}>
                                            <Col>
                                                <TermsAndConditionsCheckbox
                                                    text={t`sc.checkbox.terms_and_conditions_price_list`}
                                                    onChange={
                                                        this.onChangeTerms
                                                    }
                                                    checked={termsCheck}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '24px' }}>
                                            <Col sm={12} md={12} lg={12}>
                                                <V2Button2
                                                    disabled={disabledButton}
                                                    onClick={() => {
                                                        if (disabledButton)
                                                            return
                                                        this.submitPayment()
                                                    }}
                                                >
                                                    {loading ? (
                                                        <Loader
                                                            type="Oval"
                                                            color="#848484"
                                                            height="23"
                                                            width="23"
                                                        />
                                                    ) : (
                                                        t`sc.button.buy_now`
                                                    )}
                                                </V2Button2>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '24px' }}>
                                            <Col sm={12} md={12} lg={12}>
                                                <PaymentsWrapper>
                                                    <PaymentText>{t`skycop.care.purschase.charge.part1`}</PaymentText>
                                                    <PaymentText>{t`skycop.care.purschase.charge.part2`}</PaymentText>
                                                </PaymentsWrapper>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </StyledPageContent>
                        </StyledSidebarContainer>
                    </PageViewContainer>
                </ContentContainer>
            </Container>
        )
    }
}

function mapUser(data) {
    return {
        name: _get(data, 'name', ''),
        surname: _get(data, 'surname', ''),
        email: _get(data, 'email', ''),
        phone: _get(data, 'phone', ''),
        phoneCode: _get(data, 'phone_code.id', ''),
        country: _get(data, 'country.id', ''),
        passengersCount: 0,
    }
}

function mapFamilyMember(members) {
    return members.map(member => ({
        name: _get(member, 'name', ''),
        lastname: _get(member, 'surname', ''),
        email: _get(member, 'email', ''),
    }))
}

function mapItems(items) {
    return items.map(i => ({
        item_name: i.name,
        item_id: i.id,
        price: i.price / 100,
        currency: 'EUR',
        quantity: 1,
    }))
}

function calculatePrice(price, discount) {
    const discountAmount = price * (discount / 100)
    return (price - discountAmount).toFixed(2)
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            validateSkycopCareView,
            getUserData,
            registerUser,
            authUserExternal,
        },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
        countries: state.countries,
        is_valid_smtp: state.valid_email_smtp,
        sc_errors: state.sc_errors,
        productsV2: state.sc_products,
        products: state.sc_v2_products,
        postedClaim: state.v2_posted_claim,
        lang: state.language,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkycopCare)
