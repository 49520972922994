import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import t from '../../../shared/translations'
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from 'react-share'
import styled from 'styled-components'
import _get from 'lodash/get'
import { sendFeedback, hideModal } from '../../../shared/actions/v2_actions'
import { toggleFooter } from '../../../main'
import {
    desktop,
    tablet,
    mobile,
} from '../../../shared/components/helpers/styled_queries'
import {
    LinkIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIconNew,
    WhatsAppIconNew,
    FacebookIconNew,
} from '../../../shared/components/V2/V2Icons'
import { logKibana } from '../../../shared/actions'

const SocialContainerWrapper = styled.div`
    background: url('/images/thank-you-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
`

const SocialContainer = styled.div`
    display: flex;
    // justify-content: center;
    align-items: center;
    // margin-left: auto;
    flex-wrap: wrap;

    ${tablet`
        margin: auto;
        margin-top: 12px;
    `}

    ${mobile`
        // margin: auto;
        margin-top: 12px;
         width: 70%;
    `}
`

const IconContainer = styled.div`
    margin: 0 8px;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    svg {
        width: 40px;
        height: 40px;
    }

    ${mobile`
        margin: 0 32px 32px 0;
        `}
`

const H1 = styled.h1`
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42.24px;
    text-align: left;
    color: #000000;
    margin-bottom: 24px;
`

const Subheader = styled.p`
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 29.6px;
    text-align: left;
    color: #333333;
`

const InfoBox = styled.div`
    position: absolute;
    top: -88px;
    margin-top: 12px;
    left: 2px;
    white-space: nowrap;
    background-color: #353d51;
    border-radius: 8px;
    color: #fff;
    padding: 8px 20px;
    font-size: 14px;

    &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: 20%;
        top: 100%;
        border-radius: 3px;
        width: 14px;
        background-color: #353d51;
        margin: -8px -7px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        height: 14px;
    }
`

const TitlesWrapper = styled.div`
    ${desktop`
width: 360px;
margin-right: 80px;
    `}
`

const IconsWrapper = styled.div`
    justify-content: start;
    display: flex;
    ${mobile`
    margin-bottom: -38px;
        `}
`

class SocialPage extends React.Component {
    state = {
        showCopiedTooltip: false,
    }

    componentWillUnmount() {
        toggleFooter().show()
    }

    showTooltip = () => {
        this.setState({ showCopiedTooltip: true }, () => {
            setTimeout(() => {
                this.setState({ showCopiedTooltip: false })
            }, 2000)
        })
    }

    copyShareUrl = () => {
        let textArea = document.createElement('textarea')
        textArea.value = `https://www.skycop.com`
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            document.execCommand('copy')
            this.showTooltip()
        } catch (err) {}

        document.body.removeChild(textArea)
        this.logAction('share_copy_url')
    }

    logAction = message => {
        const { postedClaim, logKibana } = this.props
        const claimId = _get(
            postedClaim,
            'id',
            _get(postedClaim, 'draft.id', '')
        )

        logKibana(`${message}, claimId: ${claimId}`, '/thanks', 'warning')
    }

    renderShareOptions = () => {
        const shareOptions = [
            {
                name: 'link',
                icon: (
                    <span style={{ position: 'relative' }}>
                        {this.state.showCopiedTooltip && (
                            <InfoBox>
                                {t`v2_common.copied_to_clipboard`}
                            </InfoBox>
                        )}
                        <LinkIcon />
                    </span>
                ),
                onClick: this.copyShareUrl,
            },
            {
                name: 'facebook',
                icon: (
                    <FacebookShareButton
                        quote={t`v2_feedback.social.facebook.text`}
                        hashtag="#skycop"
                        url={t`v2_feedback.social.facebook.url`}
                    >
                        {FacebookIconNew()}
                    </FacebookShareButton>
                ),
                onClick: () => this.logAction('share_facebook'),
            },
            {
                name: 'linkedin',
                icon: (
                    <LinkedinShareButton
                        url={t`v2_feedback.social.linkedin.url`}
                    >
                        {LinkedinIcon()}
                    </LinkedinShareButton>
                ),
                onClick: () => this.logAction('share_linkedin'),
            },
            {
                name: 'twitter',
                icon: (
                    <TwitterShareButton
                        title={t`v2_feedback.social.twitter.text`}
                        hashtags={['skycop', 'compensation']}
                        url={t`v2_feedback.social.twitter.url`}
                    >
                        {TwitterIconNew()}
                    </TwitterShareButton>
                ),
                onClick: () => this.logAction('share_twitter'),
            },
            {
                name: 'whatsappp',
                icon: (
                    <WhatsappShareButton
                        title={t`v2_feedback.social.twitter.text`}
                        hashtags={['skycop', 'compensation']}
                        url={t`v2_feedback.social.twitter.url`}
                    >
                        {WhatsAppIconNew()}
                    </WhatsappShareButton>
                ),
                onClick: () => this.logAction('share_whatsapp'),
            },
            {
                name: 'telegram',
                icon: (
                    <TelegramShareButton
                        title={t`v2_feedback.social.twitter.text`}
                        hashtags={['skycop', 'compensation']}
                        url={t`v2_feedback.social.twitter.url`}
                    >
                        {TelegramIcon()}
                    </TelegramShareButton>
                ),
                onClick: () => this.logAction('share_telegram'),
            },
        ]

        return shareOptions.map((option, i) => {
            return (
                <IconContainer key={i} onClick={option.onClick}>
                    {option.icon}
                </IconContainer>
            )
        })
    }

    render() {
        return (
            <Fragment>
                <SocialContainerWrapper>
                    <TitlesWrapper>
                        <H1>{t`v2_feedback.social.page_title`}</H1>
                        <Subheader>
                            {t`v2_feedback.social.page_description`}
                        </Subheader>
                    </TitlesWrapper>
                    <IconsWrapper>
                        <SocialContainer>
                            {this.renderShareOptions()}
                        </SocialContainer>
                    </IconsWrapper>
                </SocialContainerWrapper>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        postedClaim: state.v2_posted_claim,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ sendFeedback, hideModal, logKibana }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialPage)
