import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = [];

export default function(state = INITIAL_STATE, action) {
    const { type, payload = true } = action;

    switch (type) {
        case V2_ACTIONS.SET_NOT_SUPPORTED_AIRLINES:
            return payload;
        case V2_ACTIONS.CLEAR_NOT_SUPPORTED_AIRLINES:
            return INITIAL_STATE;
        default:
    }
    return state;
}
