import { V2_ACTIONS } from '../../actions/actionTypes'

export const initialFlightDetailsEntity = {
    departureAirport: '',
    arrivalAirport: '',
    airline: '',
    flightDate: '',
    flightNumber: '',
}

export const initialPassengerDetailsEntity = {
    salutation: '',
    name: '',
    surname: '',
    email: '',
    phoneCode: '',
    phone: '',
}

export const additionalPassengerDetailsEntity = {
    salutation: '',
    name: '',
    surname: '',
}

const INITIAL_STATE = {
    flightDetails: {
        '1': initialFlightDetailsEntity,
    },
    passengerDetails: {
        '1': initialPassengerDetailsEntity,
    },
    flightDetailsErrors: {},
    registeredFlightIds: {},
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_SMART_DELAY_FLIGHT_DETAILS:
            return {
                ...state,
                flightDetails: action.payload,
            }
        case V2_ACTIONS.SET_SMART_DELAY_PASSENGER_DETAILS:
            return {
                ...state,
                passengerDetails: action.payload,
            }
        case V2_ACTIONS.SET_SMART_DELAY_FLIGHT_DETAILS_ERRORS:
            return {
                ...state,
                flightDetailsErrors: action.payload,
            }

        case V2_ACTIONS.SET_SMART_DELAY_FLIGHT_DETAILS_REGISTERED_FLIGHT_IDS:
            return {
                ...state,
                registeredFlightIds: action.payload,
            }

        default:
    }
    return state
}
