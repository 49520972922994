import axios from 'axios'
//import axiosRetry from 'axios-retry';
import { getStorage } from '../../../main'
import envConfig from '../../../env.json'

const singleton = Symbol()
const API_V2_ROOT_URL =
    envConfig[process.env.REACT_APP_ENV || 'development'].API_V2

class ApiClient {
    static config = {
        axios: {
            baseURL: `${API_V2_ROOT_URL}`,
            timeout: 300000,
            headers: {
                Accept: 'application/json',
                'Accept-language': getStorage('lang') || 'en',
            },
        },
        // axiosRetry: {
        //     retries: 3
        // }
    }

    constructor(singletonToken) {
        if (singleton !== singletonToken) {
            console.log('Cannot construct singleton')

            return false
        }
        this.session = axios.create(ApiClient.config.axios)
        //axiosRetry(this.session, ApiClient.config.axiosRetry);
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new ApiClient(singleton)
        }

        return this[singleton]
    }

    get = (...params) => this.session.get(...params)
    post = (...params) => this.session.post(...params)
    put = (...params) => this.session.put(...params)
    patch = (...params) => this.session.patch(...params)
    delete = (...params) => this.session.delete(...params)
    request = (...params) => this.session.request(...params)
}

export default ApiClient.instance
