import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    flightDetails: null,
    isLaterBtnClicked: false,
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_FLIGHT_DETAILS:
            return {...state, flightDetails: [...state.flightDetails, ...action.payload]};
        case V2_ACTIONS.CLEAR_FLIGHT_DETAILS:
            return {...state, flightDetails: null};
        case V2_ACTIONS.DO_IT_LATER_BTN_CLICK:
            return {...state, isLaterBtnClicked: true};
                
        default:
    }
    return state;
};