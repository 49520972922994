import styled from 'styled-components'
import { Link } from 'react-router'
import ReactTooltip from 'react-tooltip'

export const ToolTip = styled(ReactTooltip)`
    background-color: #f9e4e3 !important;
    color: black !important;
    border: 1px solid #d9534f;

    &.place-top {
        &:after {
            border: 0 !important;
            border-bottom: 1px solid #d9534f !important;
            border-right: 1px solid #d9534f !important;
            width: 16px;
            bottom: -6px;
            left: 50%;
            margin-left: -8px;
            height: 16px;
            transform: rotate(45deg);
            top: calc(100% - 7px);
            background: #f9e4e3 !important;
        }
    }
`

export const StyledInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 5.2rem;
    padding: 1.2rem 1.7rem;
    border: 0.1rem solid #a4bbbb;
    border-radius: 0.2rem;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    color: ${({ theme }) => theme.colors.secondaryColor};
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.6rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    &:focus {
        box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
        border: 0.1rem solid ${({ theme }) => theme.colors.primaryColor};
        color: ${({ theme }) => theme.colors.secondaryColor};
        background-color: #fff;
        outline: 0;
    }
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }
    &::placeholder {
        font-weight: normal;
        color: #717171;
        opacity: 1;
    }

    &:disabled,
    &[readonly] {
        background-color: #eceeef;
        opacity: 1;
    }
    &:disabled {
        cursor: not-allowed;
    }
`

export const StyledFormGroup = styled.div`
    margin-bottom: 2.7rem;
    ${StyledInput} {
        ${({ hasDanger }) =>
            hasDanger &&
            `
            border-color: #d9534f;
        `}
    }
`

export const PrimaryText = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    color: ${({ color, theme }) => color || theme.colors.secondaryColor};
    &:hover {
        text-decoration: none;
    }
`

export const PrimaryLinkText = PrimaryText.extend`
    &:hover,
    &:focus {
        color: ${({ hoverColor, theme }) =>
            hoverColor || theme.colors.primaryColor};
        cursor: pointer;
    }
`

export const BoxContainer = styled.div`
    border-color: ${({ theme, secondary }) =>
        secondary
            ? theme.colors.secondaryBorderColor
            : theme.colors.primaryBorderColor};
`

export const CheckIcon = styled.span`
    &.icon {
        &.icon--check {
            color: ${({ theme }) => theme.colors.secondaryColor};
        }
    }
`

export const CloseLink = styled(Link)`
    border-color: ${({ theme }) => theme.colors.primaryBorderColor};

    &:before {
        color: ${({ theme }) => theme.colors.primaryBorderColor};
    }
`

export const AgreementContainer = styled.div`
    overflow: auto;
    padding: 15px;

    @media (max-width: 575px) {
        padding: 0;
        overflow-x: hidden;
    }

    div {
        height: 100%;

        .scaled {
            height: 100%;

            @media (max-width: 625px) {
                transform-origin: left top;
                transform: scale(calc(1 / 1.1));
            }
            @media (max-width: 515px) {
                transform: scale(calc(1 / 1.3));
                width: 500px;
            }
            @media (max-width: 450px) {
                transform: scale(calc(1 / 1.5));
            }
            @media (max-width: 400px) {
                transform: scale(calc(1 / 1.8));
            }
            @media (max-width: 350px) {
                transform: scale(calc(1 / 2));
            }
            @media (max-width: 330px) {
                transform: scale(calc(1 / 2.1));
            }
        }
    }
`
