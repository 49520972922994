import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import debounce from 'lodash/debounce'
import { Experiment, Variant } from 'react-optimize'
import { StepContent, Row, Col } from '../../../shared/components/V2/Layout'
import SidebarProgress from '../components/SidebarProgress'
import t from '../../../shared/translations'
import StepView from '../components/StepView'
import { browserHistory } from 'react-router'
import { paths, featureToggles, modalTypes } from '../../../shared/constants'
import { connect } from 'react-redux'
import {
    setNavStep,
    setDraftEmail,
    setV2Claim,
    showModal,
    checkIfHasAccount,
} from '../../../shared/actions/v2_actions'
import { mapSteps } from '../../../shared/reducers/V2/reducer_steps'
import InputField from '../../../shared/components/V2/InputField'
import {
    isFeatureEnabled,
    isValidEmailInput,
    isValidEmail,
    applyTranslationAction,
    getStorage,
    scrollComponentToView,
    getConfigVal,
} from '../../../main'
import { bindActionCreators } from 'redux'
import { SPACE_KEY } from '../../../shared/components/V2/Modal'
import { validateEmailSmtp } from '../../../shared/actions'
import { emailMagic } from '../../../shared/validators'
import TermsAndConditions from '../components/TermsAndConditions'
import { SMTP_VALIDATION } from '../../../shared/actions/actionTypes'
import store from '../../../shared/services/store'
import GrayInfoBox from '../components/GrayInfoBox'
import { SubscribeContainer } from '../../other/ClaimAgreementLead/styles'
import Subscribe from '../../V2/components/Subscribe'

const StyledRow = styled(Row)`
    margin-bottom: 32px;
`

const StyledTermsCol = styled(Col)`
    margin-bottom: 4px;
`

const SubTitle = styled.div`
    color: #353d51;
    font-size: 14px;
    line-height: 1.71;
    margin-bottom: 12px;
`
const EmailDescription = styled.div`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: left;

    color: #717171;
    margin-bottom: 32px;
`

class EmailDetails extends React.Component {
    state = {
        email: '',
        termsConditions: false,
        emailErrors: '',
        hasAccount: false,
        subscribe: false,
        isSelectAllChecked: false,
    }

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentWillMount() {
        const { authenticated, draftEmail, claimDraft } = this.props
        if (
            !isFeatureEnabled(featureToggles.featureEmailPartial) ||
            draftEmail ||
            !!authenticated ||
            !!claimDraft
        ) {
            browserHistory.replace(paths.V2.STEP_4_FLIGHT_DETAILS)
        } else {
            scrollComponentToView('pageTopNavbar', false, true)
        }
    }

    handleEmailChange = e => {
        const email = _get(e, 'target.value', '')
        if (!isValidEmailInput(email)) {
            return
        }

        this.setState({
            email: _get(e, 'target.value', ''),
        })
    }

    handleEmailFocus = e => {
        const { emailErrors } = this.state
        if (emailErrors) {
            this.setState({
                emailErrors: '',
            })
        }
    }

    handleTermsChange = e => {
        const { termsConditions, subscribe } = this.state
        this.isSelectAllCheckboxChecked(!termsConditions, subscribe)

        this.setState({
            termsConditions: !termsConditions,
        })
    }

    handleSubscribe = () => {
        const { termsConditions, subscribe } = this.state
        this.isSelectAllCheckboxChecked(termsConditions, !subscribe)
        this.setState({ subscribe: !subscribe })
    }

    isSelectAllCheckboxChecked = (terms, subscribe) => {
        this.setState({ isSelectAllChecked: terms && subscribe })
    }

    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(paths.V2.STEP_4_FLIGHT_DETAILS))
        } catch (e) {}
    }

    componentWillUnmount() {
        store.dispatch({
            type: SMTP_VALIDATION,
            payload: true,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { authenticated } = this.props
        if (!prevProps.authenticated && authenticated) {
            browserHistory.replace(paths.V2.STEP_4_FLIGHT_DETAILS)
        }
    }

    _handleNextStep = () => {
        const { email, subscribe } = this.state
        const { authenticated, V2Claim } = this.props
        if (this.emailValidate()) {
            if (!authenticated && email && !_get(V2Claim, 'user.email', '')) {
                setDraftEmail(email)
                this.props.setV2Claim({
                    ...V2Claim,
                    user: {
                        ...(V2Claim.user || {}),
                        email,
                        repeatEmail: email,
                        locale: getStorage('lang') || 'en',
                        subscribe: subscribe,
                    },
                })
            }

            /* global dataLayer */
            if (dataLayer && dataLayer.push) {
                dataLayer.push({
                    event: 'Step3b',
                    emailSubmitted: email,
                })
            }
            browserHistory.replace(paths.V2.STEP_4_FLIGHT_DETAILS)
        }
        return false
    }

    _handlePrevStep = () => {
        browserHistory.replace(paths.V2.STEP_3_DISRUPTION_REASON)
        // return true;
    }

    emailValidate = debounce(() => {
        const { email, emailErrors } = this.state
        const { checkIfHasAccount } = this.props

        if (email && !emailErrors && !isValidEmail(email)) {
            this.setState({
                emailErrors: t`common.label.email_invalid`,
                hasAccount: false,
            })
            return false
        } else if (isValidEmail(email)) {
            if (emailErrors) {
                this.setState({
                    emailErrors: '',
                })
            }
            checkIfHasAccount(email)
                .then(hasAccount => {
                    this.setState({
                        hasAccount,
                    })
                })
                .catch(() => {
                    this.setState({
                        hasAccount: false,
                    })
                })
        }

        return true
    }, 500)

    handleSelectAll = () => {
        const { isSelectAllChecked } = this.state
        if (!isSelectAllChecked) {
            this.setState({ subscribe: true })

            this.setState({
                termsConditions: true,
            })
        } else {
            this.setState({ subscribe: false })

            this.setState({
                termsConditions: false,
            })
        }
        this.setState(prevState => ({
            ...prevState,
            isSelectAllChecked: !prevState.isSelectAllChecked,
        }))
    }

    render() {
        const { nav, is_valid_smtp, showModal } = this.props
        const {
            email,
            termsConditions,
            emailErrors,
            hasAccount,
            subscribe,
            isSelectAllChecked,
        } = this.state
        const experimentId = getConfigVal('AB_email-details')

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                nav={{
                    ...nav,
                    nextDisabled:
                        !email || !termsConditions || (email && !!emailErrors),
                }}
                Sidebar={
                    <SidebarProgress
                        activeIndex={3}
                        checkedIndexes={[0, 1, 2]}
                        paddingTop={0}
                    />
                }
                title={t`v2_common.placeholder.email`}
                titleBottomPadding={'32px'}
            >
                <StepContent style={{ marginBottom: 0 }}>
                    {!experimentId ? null : (
                        <Experiment id={experimentId}>
                            <Variant id="0" />
                            <Variant id="1">
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <SubTitle>
                                            {t`email_details.enter_email.description`}
                                        </SubTitle>
                                    </Col>
                                </Row>
                            </Variant>
                            <Variant id="2">
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <SubTitle>
                                            {t`email_details.enter_email.description`}
                                            &nbsp;
                                            {t`email_details.enter_email.description.extended`}
                                            &nbsp;
                                        </SubTitle>
                                    </Col>
                                </Row>
                            </Variant>
                        </Experiment>
                    )}
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <EmailDescription>
                                {t`email_details.enter_email.info_description`}
                            </EmailDescription>
                        </Col>
                    </Row>
                    <StyledRow>
                        <InputField
                            label={t`v2_common.label.email`}
                            name="email"
                            type="email"
                            id="user-email"
                            required
                            placeholder={t`v2_common.placeholder.email`}
                            value={email}
                            onChange={this.handleEmailChange}
                            onFocus={this.handleEmailFocus}
                            //errorText={t`${_get(errors, "user.email", "")}`}
                            autoComplete="email"
                            hasSmtpValidation={true}
                            errorText={emailErrors}
                            forceErrorIconVisible={true}
                            suggestedText={{
                                text: emailMagic(email),
                                callback: () => {
                                    this.handleEmailChange({
                                        target: {
                                            name: 'email',
                                            value: emailMagic(email),
                                        },
                                    })
                                    validateEmailSmtp(emailMagic(email))
                                },
                            }}
                            onBlur={() => {
                                validateEmailSmtp(email)
                                this.emailValidate()
                            }}
                            isValidSmtp={!!is_valid_smtp}
                            onKeyDown={e => {
                                if (e.keyCode === SPACE_KEY) {
                                    e.preventDefault()
                                    return false
                                }
                            }}
                        />
                    </StyledRow>
                    {hasAccount && (
                        <StyledRow>
                            <Col xs={12} sm={8} lg={6}>
                                <GrayInfoBox>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t`draft_email_info_box_login_title`,
                                        }}
                                    />
                                    {applyTranslationAction(
                                        t`draft_email_info_box_login`,
                                        '<span>',
                                        '</span>',
                                        () =>
                                            showModal(modalTypes.loginModal, {
                                                email,
                                            }),
                                        'inline'
                                    )}
                                </GrayInfoBox>
                            </Col>
                        </StyledRow>
                    )}
                    <Row>
                        <StyledTermsCol
                            style={{ paddingBottom: 'unset', height: '36px' }}
                        >
                            <Subscribe
                                fontWeight={400}
                                text={t`v2_claim.select_all_terms`}
                                onChange={this.handleSelectAll}
                                checked={isSelectAllChecked}
                            />
                        </StyledTermsCol>
                    </Row>
                    <Row>
                        <StyledTermsCol
                        // style={{
                        //     paddingBottom: '4px',
                        //     paddingTop: '4px',
                        // }}
                        >
                            <TermsAndConditions
                                text={t`v2_claim.register.terms_and_conditions`}
                                onChange={this.handleTermsChange}
                                checked={termsConditions}
                                id="email_terms"
                                required={true}
                            />
                        </StyledTermsCol>
                    </Row>
                    <Row style={{ marginTop: '-12px', marginBottom: '-14px' }}>
                        <StyledTermsCol>
                            <SubscribeContainer>
                                <Subscribe
                                    fontWeight="400"
                                    text={t`subscription.description.subscribe`}
                                    onChange={this.handleSubscribe}
                                    checked={subscribe}
                                />
                            </SubscribeContainer>
                        </StyledTermsCol>
                    </Row>
                </StepContent>
            </StepView>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setV2Claim,
            showModal,
            checkIfHasAccount,
        },
        dispatch
    )
}

export default connect(
    state => ({
        draftEmail: state.draftEmail,
        authenticated: state.auth.authenticated,
        V2Claim: state.V2Claim,
        is_valid_smtp: state.valid_email_smtp,
        claimDraft: state.claimDraft,
    }),
    mapDispatchToProps,
    null,
    { withRef: true }
)(EmailDetails)
