import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = false;

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.UNAVAILABLE_SERVICE:
            return action.payload;
        default:
    }
    return state;
}