import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import t from '../../../translations'
import {
    flightResults,
    handleMobileInputFocus,
    handleMobileInputBlur,
} from '../../../../main'
import SelectField from './SelectField'
import SelectFieldOptions from './SelectFieldOptions'
import { StopIcon, ClearIconV2 } from '../../svgIcons'
import { ArrivalPlaneIcon, DeparturePlaneIcon } from './icons'
import store from '../../../services/store'
var debounce = require('es6-promise-debounce')

export const flightTypes = {
    arrival: 'arrival',
    departure: 'departure',
    stop: 'stop',
}

const getInputIcon = type => {
    const iconMaps = {
        arrival: ArrivalPlaneIcon,
        departure: DeparturePlaneIcon,
        stop: StopIcon,
    }

    return iconMaps[type]
}

class FlightInput extends React.Component {
    render() {
        const {
            type = flightTypes.arrival,
            autoload = true,
            className = '',
            ignoreAccents = false,
            clearable = true,
            cache = false,
            callback = () => null,
            clear = () => null,
            isAsync = true,
            hasIcon = true,
            hideArrow = true,
            required = false,
            label,
            name,
            value,
            defaultValue,
            onChange,
            errorText,
            autofocus,
            id,
            setRef = () => null,
        } = this.props
        return (
            <SelectField
                Icon={getInputIcon(type)}
                label={label}
                errorText={errorText}
                hasIcon={!!hasIcon}
                hideArrow={hideArrow}
                required={required}
                id={id}
                data-hj-whitelist=""
                selectProps={{
                    'data-hj-whitelist': '',
                    inputProps: {
                        'data-hj-whitelist': '',
                    },
                    autoFocus: !!autofocus,
                    autofocus: !!autofocus,
                    id: id,
                    name,
                    value,
                    defaultValue,
                    autoload,
                    className: `${className} data-hj-whitelist`,
                    ignoreAccents,
                    clearable,
                    cache,
                    isAsync: !!isAsync,
                    onBlurResetsInput: true,
                    loadingPlaceholder: t`v2_common.no_results`,
                    searchPromptText: t`v2_common.no_results`,
                    placeholder: label,
                    optionRenderer: SelectFieldOptions,
                    arrowRenderer: () => null,
                    clearRenderer: () => {
                        if (!value) {
                            return null
                        }
                        return (
                            <span
                                className="clear-item"
                                onClick={() => clear()}
                                onTouchEnd={() => clear()}
                            >
                                <ClearIconV2 fill="#717171"  />
                            </span>
                        )
                    },
                    loadOptions: debounce(e => {
                        return flightResults(e, name, [
                            ...store.getState().v2_UsedAirports,
                            ...store.getState().v2_selectedAirports,
                        ])
                    }, 200),
                    onFocus: e => {
                        e.preventDefault()
                        handleMobileInputFocus()
                    },
                    onBlur: e => {
                        e.preventDefault()
                        handleMobileInputBlur()
                    },
                    onClick: e => e.preventDefault(),
                    filterOptions: (options, filter, current_values) => options,
                    onChange: e => {
                        onChange(e, callback)
                        const elem = _get(document, 'activeElement', null)

                        if (elem && typeof elem.blur === 'function') {
                            elem.blur()
                        }
                    },
                    ref: ref => setRef(name, ref),
                }}
            />
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(FlightInput)
