import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const StyledLabel = styled.label`
    margin: 0;
    cursor: pointer;
    position: relative;
    display: block;
    user-select: none;
    > span {
        background-color: #fff;
        margin-bottom: 2rem;
        border-radius: .2rem;
        box-shadow: 0.1rem 0.3rem 0.4rem 0 rgba(0,0,0,.07);
        border: .1rem solid #dfdfdf;
        color: #666;
        padding: 1.5rem 1rem;
        transition: all .2s ease;
        text-align: center;
        line-height: 2rem;
        display: block;
        min-height: 5.2rem;
        position: relative;
        cursor: pointer;
        user-select: none;
    }
`;

const StyledCheckBox = styled.input`
    position: absolute;
    left: -999em;
    top: 0;
    margin-left: -1.25rem;
    margin-top: .25rem;
    &:checked+span {
        border-color: ${({ theme }) => theme.colors.midHoverColor};
        color: ${({ theme }) => theme.colors.primaryColor};
        background-color: ${({ theme }) => theme.colors.activeItemBackground};
        box-shadow: inset 0 0 0 0.1rem #fff, inset 0 0.1rem 0.3rem 0.1rem rgba(0,0,0,.15);
        font-weight: 700;
    }
`;

const RadioButton = ({ label, onChange, name, value, className, children,  ...props }) => (
    <StyledLabel className={className}>
        <StyledCheckBox
            className="form-check-input js-checkable"
            value={value}
            type="radio"
            name={name}
            onChange={onChange}
            {...props}/>
        {children || <span>{label}</span>}
    </StyledLabel>
);

RadioButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
}

RadioButton.defaultProps = {
    label: "",
    className: "",
};

export default RadioButton;