import { browserHistory } from 'react-router'
import _get from 'lodash/get'
import { logKibana } from './index'
import {
    loadScreenOff,
    loadScreenOn,
    loginPageShow,
    getStorage,
    removeStorage,
    getApiClient,
    getStatus,
} from '../../main'
import {
    SELECTED_CLAIM,
    UNAUTH_USER,
    AUTH_ERROR,
    AUTH_USER,
    USER,
    USER_CLAIMS,
    USER_RELATED_CLAIMS,
} from './actionTypes'
import { getSkycopCareSubscriptions } from './v2_actions'

/* global Raven */

export function updateUserInfo(data, redirect, lang, ignoreResolve) {
    const url = '/api/account/edit'
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .post(url, data, {
                headers: {
                    Authorization: authString,
                    'Accept-Language': lang || 'en',
                },
            })
            .then(response => {
                if (!ignoreResolve) {
                    dispatch({ type: AUTH_USER })
                    if (!redirect) dispatch(getUserData('true'))
                    else dispatch(getUserData('false', redirect))
                }
                loadScreenOff()
                return response
            })
            .catch(error => {
                loadScreenOff()

                const errors = _get(error, 'response.data.form', null)
                dispatch({
                    type: AUTH_ERROR,
                    payload: errors,
                })
                if (!errors) {
                    Raven.captureException(
                        'UPDATE USER INFO ERROR. TYPE UNKNOWN: ' +
                            JSON.stringify(error)
                    )
                    browserHistory.push(`/error?q=other&ref=profile`)
                }
            })
    }
}

export function getSingleClaim(id, isRelated) {
    const url = `/api/account/${isRelated ? 'related-claim' : 'claim'}/${id}`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url, { headers: { Authorization: authString } })
            .then(response => {
                const err = _get(response, 'data.payload.error', '')

                if (err) {
                    browserHistory.push('/claims')
                } else {
                    dispatch({
                        type: SELECTED_CLAIM,
                        payload: {
                            isRelated: !!isRelated,
                            ...response.data,
                        },
                    })
                }
            })
            .catch(error => {
                const statusCode = getStatus(error)
                const stringError = JSON.stringify(error)
                const isAborted =
                    _get(error, 'code', '').toLowerCase() ===
                    'ECONNABORTED'.toLowerCase()

                if (statusCode && statusCode === 401) {
                    resetUserLogin(dispatch)
                } else {
                    loadScreenOff()
                    if (getStorage('token') && !isAborted) {
                        Raven.setUserContext({
                            claimId: id,
                        })
                        Raven.captureException(
                            `VIEW USER CLAIM ERROR ${statusCode ||
                                ''}: ${stringError}`
                        )
                        logKibana(
                            'view_user_claim_error',
                            'account/claim/{claimId}',
                            'error',
                            stringError
                        )
                    }
                    browserHistory.push('/claims')
                }
            })
    }
}

export function getUserClaims() {
    const url = `/api/account/claims`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url, { headers: { Authorization: authString } })
            .then(response => {
                dispatch({
                    type: USER_CLAIMS,
                    payload: response.data,
                })
                return response.data
            })
            .catch(error => {
                const statusCode = getStatus(error)
                const stringError = JSON.stringify(error)
                const isAborted =
                    _get(error, 'code', '').toLowerCase() ===
                    'ECONNABORTED'.toLowerCase()
                if (statusCode && statusCode === 401) {
                    resetUserLogin(dispatch)
                } else {
                    loadScreenOff()
                    if (getStorage('token') && !isAborted && !!statusCode) {
                        Raven.captureException(
                            `GET CLAIMS ERROR ${statusCode}: ${stringError}`
                        )
                        logKibana(
                            'get_claims_error',
                            'account/claims',
                            'error',
                            stringError
                        )
                    }
                }
            })
    }
}

export function getEmailPreferences(user) {
    const url = `/api/get-email-preferences/${user}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url)
            .then(response => {
                return response.data
            })
            .catch(error => {
                return false
            })
    }
}

export function setEmailPreferences(data, user) {
    const url = `/api/email-preferences/${user}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .post(url, data)
            .then(response => {
                return true
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function acceptClaimOffer(payoutOfferId, fd, lang) {
    const url = '/api/account/payout-offer/'
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    const urlPaymentDetails = `/api/account/update-payment-info`

    return function() {
        if (!getStorage('token')) {
            return Promise.resolve()
        } else {
            return apiClient
                .post(urlPaymentDetails, fd, {
                    headers: {
                        Authorization: authString,
                        'Accept-language': lang,
                    },
                })
                .then(() => {
                    return apiClient.post(
                        `${url}${payoutOfferId}/accept`,
                        null,
                        {
                            headers: { Authorization: authString },
                        }
                    )
                })
                .then(() => {
                    return { isSuccess: true }
                })
                .catch(e => {
                    console.error(e)
                    return {
                        isFormErrors: true,
                        form: e.response.data.form,
                        isSuccess: false,
                        message: e.response.data.messageKey,
                    }
                })
        }
    }
}

export function getClaimOffer(claimId) {
    const url = `/api/account/payout-offer/claim/${claimId}`
    const urlOpened = '/api/account/payout-offer/'
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function() {
        if (!getStorage('token')) {
            return Promise.resolve()
        } else {
            return apiClient
                .get(url, { headers: { Authorization: authString } })
                .then(({ data }) => {
                    if (data.payoutOfferId) {
                        apiClient
                            .post(
                                `${urlOpened}${data.payoutOfferId}/opened`,
                                null,
                                {
                                    headers: { Authorization: authString },
                                }
                            )
                            .then(() => {})
                    }
                    return data
                })
                .catch(e => {
                    console.error(e)
                    loadScreenOff()
                    return {
                        error: true,
                        message: e.response.data.messageKey,
                    }
                })
        }
    }
}

export function getUserData(toClaims, redirect) {
    const url = '/api/account/get-info/'
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        if (!getStorage('token')) {
            return Promise.resolve()
        } else {
            return apiClient
                .get(url, { headers: { Authorization: authString } })
                .then(response => {
                    const email = _get(response, 'data.payload.email', '')
                    const data = _get(response, 'data.payload', {})
                    const payload = {
                        ...data,
                        email: email === 'undefined' ? '' : email,
                    }

                    Raven.setUserContext({
                        email: payload.email || '',
                        id: payload.id || '',
                    })
                    dispatch({
                        type: USER,
                        payload,
                    })
                    if (toClaims === 'true') {
                        browserHistory.push(`/claims`)
                    }
                    if (redirect) {
                        browserHistory.push(redirect)
                    }

                    getSkycopCareSubscriptions()

                    return payload
                })
                .catch(error => {
                    const statusCode = getStatus(error)
                    if (statusCode && statusCode !== 401) {
                        resetUserLogin(dispatch)
                        Raven.captureException(
                            'GET USER DATA ERROR: ' + JSON.stringify(error)
                        )
                    }
                    browserHistory.push('/')
                    loadScreenOff()
                })
        }
    }
}

export function getUserRelatedClaims() {
    const url = `/api/account/related-claims`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .get(url, { headers: { Authorization: authString } })
            .then(response => {
                dispatch({
                    type: USER_RELATED_CLAIMS,
                    payload: response.data,
                })
            })
            .catch(error => {
                const statusCode = getStatus(error)
                const stringError = JSON.stringify(error)
                const isAborted =
                    _get(error, 'code', '').toLowerCase() ===
                    'ECONNABORTED'.toLowerCase()
                if (statusCode && statusCode === 401) {
                    resetUserLogin(dispatch)
                } else {
                    loadScreenOff()
                    if (getStorage('token') && !isAborted && !!statusCode) {
                        Raven.captureException(
                            `GET RELATED CLAIMS ERROR ${statusCode}: ${stringError}`
                        )
                        logKibana(
                            'get_related_claims_error',
                            'account/related-claims',
                            'error',
                            stringError
                        )
                    }
                }
            })
    }
}

export function uploadPaymentDetails(data, lang) {
    const url = `/api/account/update-payment-info`
    const authString = `Bearer ${getStorage('token')}`
    const apiClient = getApiClient()

    return function(dispatch) {
        return apiClient
            .post(url, data, {
                headers: { Authorization: authString, 'Accept-language': lang },
            })
            .then(response => {
                loadScreenOff()
                return response
            })
            .catch(error => {
                loadScreenOff()
                const errorMessage = _get(error, 'response.data.form.iban[0]')
                if (errorMessage) {
                    dispatch({
                        type: AUTH_ERROR,
                        payload: _get(error, 'response.data.form'),
                    })
                } else {
                    const statusCode = getStatus(error)
                    if (statusCode && statusCode === 401) {
                        resetUserLogin(dispatch)
                    } else {
                        loadScreenOff()
                        Raven.setUserContext({
                            userName: data.get('user_payment[receiverName]'),
                        })
                        Raven.captureException(
                            'UPDATE PAYMENT INFO UNKNOWN ERROR: ' +
                                JSON.stringify(error)
                        )
                        browserHistory.push(
                            '/error?q=other&ref=payment&type=invalid.payment.info'
                        )
                    }
                }
            })
    }
}

// Helper //
export function resetUserLogin(dispatch) {
    loadScreenOn()
    loginPageShow()
    dispatch({ type: UNAUTH_USER })
    removeStorage('login')
    removeStorage('token')
    loadScreenOff('login-page')
}
