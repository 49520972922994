import React from 'react'
import styled from 'styled-components'
import { DarkButton } from './Button'

const Block = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    p {
      margin-bottom: 17px;
    }
`

export const NoPlansBlock = ({ handleClick, text, buttonText }) => {
    return (
        <Block>
            <p>{text}</p>
            <DarkButton onClick={handleClick}>{buttonText}</DarkButton>
        </Block>
    )
}
