import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import {
    WaitingStatus,
    CompletedStatus,
    QuestionStatus,
    CloseIcon,
} from '../../../shared/components/V2/StatusIcons'
import { PlaneIcon } from './icons'
import { formatDate } from '../../../main'
import {
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import { QuestionMark } from '../../../shared/components/V2/V2Icons'
import { TooltipInner } from './styles'
import ToolTip from '../../../shared/components/V2/V2Tooltip'

const InfoBox = styled.div`
    position: relative;
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: rgba(53, 181, 106, 0.1);
    color: var(--main-green, #35b56a);
    text-align: center;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.5px; /* 150% */

    &:hover {
        cursor: pointer;
    }
`

const QuestionMarkBlock = styled.div`
    display: flex;
    position: relative;
    margin-right: 12px;

    &:hover {
        cursor: pointer;
    }

    ${tablet`
        margin-right: 0px;
    `}

    ${mobile`
        margin-right: 0px;
    `}
`

const Container = styled.div`
    border: ${({ status }) => `1px solid ${borderColor(status)}`};
    background: #fff;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    & .status-block {
        display: flex;
        background-color: ${({ status }) => statusColor(status)};
        padding: 18px 24px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        box-sizing: border-box;
        border: ${({ status }) => `1px solid ${statusColor(status)}`};
        border-right: none;
        margin: -1px;

        ${mobile`
            padding: 4px;
        `}

        & .status-block-icon {
            display: flex;
            align-items: center;

            & svg {
                ${mobile`
                    display: none;
                `}
            }
        }
    }

    & .content-block {
        display: flex;
        justify-content: space-between;

        flex: 1;
        padding: 22px 12px 22px 16px;

        & .contents-block-info {
            height: 100%;
            justify-content: center;
            align-items: center;
            display: flex;

            ${tablet`
                display: ${({ status }) => status === 'claimable' ? 'flex' : 'none'};
                justify-content: flex-end;
                margin-right: -44px;
            `}

            ${mobile`
                 display: ${({ status }) => status === 'claimable' ? 'flex' : 'none'};
                justify-content: flex-end;
                margin-right: -44px;
            `}
        }

        & .content-block-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & .flights {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    color: #353d51;
                    font-family: Open Sans;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    margin: 0;

                    ${tablet`
                        flex: 1;
                    `}

                    ${mobile`
                        flex: 1;
                    `}
                }

                svg {
                    flex-shrink: 0;
                    margin: 0 12px;
                }

                ${tablet`
                    margin-bottom: 8px;
                    
                `}

                ${mobile`
                    margin-bottom: 8px;
                `}
            }

            & .flight-info {
                color: #7f8fa4;
                font-family: Open Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                p {
                    margin: 0;
                }
            }
        }
        ${tablet`
            flex-direction: column;
        `}

        ${mobile`
            flex-direction: column;
        `}
    }

    & .close-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 30px;

        & .contents-block-info-mobile {
            display: none;
            height: 100%;
            justify-content: center;
            align-items: center;
            padding: 4px;
            position: relative;

            ${tablet`
                width: unset;
                height: unset;
                display: ${({ status }) => status === 'claimable' ? 'none' : 'flex'};
                justify-content: flex-end;
                margin-bottom: 22px;
            `}

            ${mobile`
                width: unset;
                height: unset;
                display: ${({ status }) => status === 'claimable' ? 'none' : 'flex'};
                justify-content: flex-end;
                margin-bottom: 22px;
            `}
        }

        ${tablet`
            align-items: flex-start;
            margin-top: 16px;
            margin-right: 16px;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        `}

        ${mobile`
            align-items: flex-start;
            margin-top: 16px;
            margin-right: 16px;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        `}

        & .icon {
            cursor: pointer;
            border-radius: 100%;
            display: flex;
            align-items: center;
            flex-shrink: 0;
        }
    }
`

export const TripItem = ({ trip, tripId, status, onDeleteClick, t }) => {
    const departureAirport = _get(trip, 'departure_airport.iata', '')
    const departureAirportTitle = _get(trip, 'departure_airport.title', '')
    const arraivalAirport = _get(trip, 'arrival_airport.iata', '')
    const arraivalAirportTitle = _get(trip, 'arrival_airport.title', '')
    const flightDate = _get(trip, 'flight_date', '')
    const flightNumber = _get(trip, 'flight_number', '').toUpperCase()

    if (!tripId) return null

    return (
        <Container status={status}>
            <div className="status-block">
                <div className="status-block-icon">{statusIcon(status)}</div>
            </div>
            <div className="content-block">
                <div className="content-block-content">
                    <div className="flights">
                        <p>
                            {departureAirportTitle} ({departureAirport})
                        </p>
                        <PlaneIcon />
                        <p>
                            {arraivalAirportTitle} ({arraivalAirport})
                        </p>
                    </div>
                    <div className="flight-info">
                        <p>
                            {formatDate(flightDate)}, {flightNumber}
                        </p>
                    </div>
                </div>
                {renderTooltip(status, tripId, 'contents-block-info', t)}
            </div>

            <div className="close-block">
                <div className="icon" onClick={() => onDeleteClick(tripId)}>
                    <CloseIcon />
                </div>
                {renderTooltip(status, tripId, 'contents-block-info-mobile', t)}
            </div>
        </Container>
    )
}

function renderTooltip(status, tripId, className, t) {
    return (
        <div className={className}>
            {status === 'claimable' ? (
                <InfoBox data-tip data-for={`tooltip-${tripId}`}>
                    {t`trip_item.${status}_info_box`}{' '}
                    <QuestionMark color="#35B56A" />
                    <ToolTip
                        clickable={true}
                        dataTip="disabled"
                        place="bottom"
                        color="light"
                        effect="solid"
                        id={`tooltip-${tripId}`}
                        delayHide={100}
                        className="customHover"
                    >
                        <TooltipInner
                            dangerouslySetInnerHTML={{
                                __html: `${t`${statusKey(status)}`}`,
                            }}
                        />
                    </ToolTip>
                </InfoBox>
            ) : (
                <>
                    <QuestionMarkBlock data-tip data-for={`tooltip-${tripId}`}>
                        <QuestionMark />
                    </QuestionMarkBlock>
                    <ToolTip
                        clickable={true}
                        dataTip="disabled"
                        place="bottom"
                        color="light"
                        effect="solid"
                        id={`tooltip-${tripId}`}
                        delayHide={100}
                        className="customHover"
                    >
                        <TooltipInner
                            dangerouslySetInnerHTML={{
                                __html: `${t`${statusKey(status)}`}`,
                            }}
                        />
                    </ToolTip>
                </>
            )}
        </div>
    )
}

function statusKey(status) {
    switch (status) {
        case 'not_claimable':
        case 'not_clear':
            return `trip_item.not_claimable_info_box_html`
        default:
            return `trip_item.${status}_info_box_html`
    }
}

function statusIcon(status) {
    switch (status) {
        case 'claimable':
            return <CompletedStatus />
        case 'not_claimable':
        case 'not_clear':
            return <QuestionStatus />
        default:
            return <WaitingStatus />
    }
}

function statusColor(status) {
    switch (status) {
        case 'claimable':
            return '#35B56A'
        case 'not_claimable':
        case 'not_clear':
            return '#7F8FA4'
        default:
            return '#FFBA0A'
    }
}

function borderColor(status) {
    switch (status) {
        case 'claimable':
            return '#35B56A'
        default:
            return '#CBD3DF'
    }
}
