import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import t from '../../../shared/translations'
import NewSelectField from '../../../shared/components/V2/NewSelectField'
import { renderCountries } from '../../../render'
import { ClearIconV2 } from '../../../shared/components/svgIcons'

const CountryInput = ({
    autoload = true,
    className = '',
    ignoreAccents = false,
    clearable = true,
    clear = () => null,
    cache = false,
    callback = () => null,
    isAsync = false,
    hasIcon = false,
    required = false,
    countries,
    name,
    label,
    placeholder,
    value,
    defaultValue,
    onChange,
    onInputChange,
    disabled,
    errorText,
    autoComplete = 'country',
    borderRadius,
    id,
    stickyOptionsList,
    isFocusHighligh,
}) => {
    function sortOptions(arr, word) {
        return arr.sort((a, b) => {
            const aStartsWithWord = a.label
                .toLowerCase()
                .startsWith(word.toLowerCase())
            const bStartsWithWord = b.label
                .toLowerCase()
                .startsWith(word.toLowerCase())

            if (aStartsWithWord && !bStartsWithWord) {
                return -1
            } else if (!aStartsWithWord && bStartsWithWord) {
                return 1
            } else if (aStartsWithWord && bStartsWithWord) {
                const aWordCount = a.label.split(/\s+/).length
                const bWordCount = b.label.split(/\s+/).length

                return aWordCount - bWordCount
            } else {
                return 0
            }
        })
    }

    const filterOptions = (options, filterString) => {
        if (filterString.length > 1) {
            const escapedInput = filterString.replace(
                /[.*+?^${}()|[\]\\]/g,
                '\\$&'
            )

            const regexPattern = new RegExp(
                `^(?:${escapedInput}|\\w+\\s+${escapedInput}|\\w+\\s+\\w+\\s+${escapedInput})`,
                'i'
            )
            return sortOptions(
                options.filter(item => regexPattern.test(item.label)),
                filterString
            )
        }

        return options
    }

    const convertedValue =
        countries &&
        countries.payload.find(
            c =>
                !!value &&
                (c.id.toLowerCase() === value.toLowerCase() ||
                    c.code.toLowerCase() === value.toLowerCase())
        )
    const updatedValue = _get(convertedValue, 'id', value)
    return (
        <NewSelectField
            isCountrySelect={true}
            isFocusHighligh={isFocusHighligh}
            stickyOptionsList={stickyOptionsList}
            borderRadius="12px"
            label={label || t`v2_common.label.country`}
            errorText={errorText}
            hasIcon={hasIcon}
            required={required}
            disabled={disabled}
            data-hj-whitelist=""
            id={id}
            selectProps={{
                'data-hj-whitelist': '',
                inputProps: {
                    'data-hj-whitelist': '',
                },
                disabled,
                id: id,
                name,
                value: updatedValue,
                defaultValue,
                autoload,
                className,
                ignoreAccents,
                clearable,
                cache,
                options: renderCountries(countries, name || 'country'),
                filterOptions: filterOptions,
                isAsync: !!isAsync,
                onBlurResetsInput: true,
                loadingPlaceholder: t`v2_common.no_results`,
                searchPromptText: t`v2_common.no_results`,
                placeholder: placeholder || t`v2_common.placeholder.country`,
                onFocus: e => e.preventDefault(),
                onBlur: e => e.preventDefault(),
                onClick: e => e.preventDefault(),
                onChange: e => {
                    onChange(e, callback)
                    const elem = _get(document, 'activeElement', null)

                    if (elem && typeof elem.blur === 'function') {
                        elem.blur()
                    }
                },
                clearRenderer: () => {
                    if (!value) {
                        return null
                    }
                    return (
                        <span
                            className="clear-item"
                            onClick={() => clear()}
                            onTouchEnd={() => clear()}
                        >
                            <ClearIconV2 fill="#333333" />
                        </span>
                    )
                },
                onInputChange,
                autoComplete,
            }}
        />
    )
}

function mapStateToProps(state) {
    return {
        countries: state.countries,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryInput)
