import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import t from '../../../../shared/translations'
import { CircleCheckmark } from '../../../../shared/components/V2/V2Icons'
import { paths } from '../../../..//shared/constants'
import { browserHistory } from 'react-router'
import { mobile } from '../../../../shared/components/helpers/styled_queries'

const StepStatus = {
    Completed: 'completed',
    Incomplete: 'incomplete',
}

const MainContainer = styled.div`
    padding-top: 20px;
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
`

const StepContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;

    :before {
        content: '';
        position: absolute;
        height: 1px;
        width: ${({ widthCompleted }) => widthCompleted}%;
        top: 51%;
        transform: translateY(-50%);
        left: 0;
        border-bottom: 1px solid #35b56a;
    }
    :after {
        content: '';
        position: absolute;
        height: 1px;
        width: 96%;
        top: 51%;
        transform: translateY(-50%);
        left: 10px;
        background-image: linear-gradient(
            to right,
            #aab0bf99 0%,
            #aab0bf99 50%,
            transparent 50%
        );
        background-size: 15px 1px;
        background-repeat: repeat-x;
    }
`

const StepWrapper = styled.div`
    position: relative;
    z-index: 1;
`

const StepStyle = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: ${({ step }) =>
        step === 'completed' ? '#d7f0e1' : '#eeeff2'};
    transition: 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StepCount = styled.span`
    font-size: 18px;
    font-family: 'Open Sans';
    font-weight: 700;
    color: ${({ step }) =>
        step === StepStatus.Completed ? '#35B56A' : '#aab0bf'};
`

const StepsLabelContainer = styled.div`
    text-align: center;
    position: absolute;
    top: 80px;
    transform: translate(-50%, -50%);
    left: 56%;
`

const StepLabel = styled.span`
    font-weight: 600;
    font-size: 12px;
    font-family: 'Open Sans';
    color: ${({ step }) =>
        step === StepStatus.Completed ? '#353D51' : '#aab0bf'};

    ${mobile`
    white-space: wrap;
    display: flex;
`}
`

const NextStepLine = styled.div`
    display: flex;
    position: absolute;
    height: 1px;
    background-image: linear-gradient(
        to right,
        #35b56a 0%,
        #35b56a 50%,
        transparent 50%
    );
    background-size: 15px 1px;
    background-repeat: repeat-x;
    top: 50%;
    width: ${({ widthActive, isLast }) => (isLast ? 0 : widthActive)}%;
    left: 10px;
`

class SmartDelayStepper extends React.Component {
    render() {
        const { trans } = this.props

        const stepsMap = {
            [paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS]: 1,
            [paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS]: 2,
            [paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE]: 3,
            [paths.V2_SMART_DELAY.STEP_4_REGISTRATION_COMPLETED]: 4,
        }

        const steps = [
            {
                label: t`smart_delay.flight_details_header`,
                step: stepsMap[paths.V2_SMART_DELAY.STEP_1_FLIGHT_DETAILS],
            },
            {
                label: t`smart_delay.passenger_details_header`,
                step: stepsMap[paths.V2_SMART_DELAY.STEP_2_PASSENGER_DETAILS],
            },
            {
                label: t`smart_delay_navigation_confirmation_step`,
                step: stepsMap[paths.V2_SMART_DELAY.STEP_3_CONFIRMATION_PAGE],
            },
            {
                label: t`smart_delay_navigation_done_step`,
                step:
                    stepsMap[
                        paths.V2_SMART_DELAY.STEP_4_REGISTRATION_COMPLETED
                    ],
            },
        ]

        const activeStep =
            stepsMap[browserHistory.getCurrentLocation().pathname]

        const widthActive = `${(97 / (steps.length - 1)) * activeStep}`
        const widthCompleted = `${(100 / (steps.length - 1)) *
            (activeStep - 1)}`

        if (!trans) {
            return null
        }

        return (
            <MainContainer>
                <StepContainer widthCompleted={widthCompleted}>
                    <NextStepLine
                        widthActive={widthActive}
                        isLast={steps.length === activeStep}
                    />
                    {steps.map(({ step, label }) => (
                        <StepWrapper key={step}>
                            <StepStyle
                                step={
                                    activeStep >= step
                                        ? StepStatus.Completed
                                        : StepStatus.Incomplete
                                }
                            >
                                {activeStep > step ? (
                                    <CircleCheckmark />
                                ) : (
                                    <StepCount
                                        step={
                                            activeStep >= step
                                                ? StepStatus.Completed
                                                : StepStatus.Incomplete
                                        }
                                    >
                                        {step}
                                    </StepCount>
                                )}
                            </StepStyle>
                            <StepsLabelContainer>
                                <StepLabel
                                    step={
                                        activeStep >= step
                                            ? StepStatus.Completed
                                            : StepStatus.Incomplete
                                    }
                                    key={step}
                                >
                                    {label}
                                </StepLabel>
                            </StepsLabelContainer>
                        </StepWrapper>
                    ))}
                </StepContainer>
            </MainContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(SmartDelayStepper)
