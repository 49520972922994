import React from 'react'
import styled from 'styled-components'
import {
    desktop,
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'

const CardContainer = styled.div`
    width: 246px;
    height: 165px;
    background: #f6fafa;

    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    position: relative;
    border: 1px solid #525f7f;
    overflow: hidden;

    &:before {
        padding: 2px;
    }

    ${mobile`
      width: 100%;
      max-width: unset;
  `}
    ${tablet`
      width: 100%;
      max-width: unset;
  `}
    padding: 16px;
    position: relative;
`

const Header = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #353d51;
    padding-bottom: 10px;

    ${desktop`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`}

    ${mobile`
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
padding-bottom: 16px;

`}
`

const Content = styled.p`
    color: #525f7f;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0px;

    ${desktop`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;
`}
`

const DottedLine = styled.div`
  background-image: linear-gradient(to right, grey 15%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  height: 1px;
  width: 100%;
  left: 0;
  padding-bottom: 10px;
}
`

const NumberContainer = styled.div`
    color: #525f7f;
    position: absolute;
    font-size: 225px;
    top: -93px;
    left: -16px;
    opacity: 7%;
    font-weight: 700;
    &:after {
        ${({ number }) => `content: '${number}'`}
    }
`

export const SmartDelayExplainCard = ({ title, content, number }) => (
    <CardContainer>
        <NumberContainer number={number} />
        <Header title={title}>{title}</Header>
        <DottedLine />
        <Content>{content}</Content>
    </CardContainer>
)
