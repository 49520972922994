import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import moment from 'moment'
import {
    showModal,
    setV2Claim,
    uploadUserSignatures,
    nextStep,
    setNavStep,
} from '../../../shared/actions/v2_actions'
import { getUserAgreements, logKibana } from '../../../shared/actions'
import {
    dataURItoBlob,
    loadScreenOff,
    loadScreenOn,
    scrollComponentToView,
    V2handleSignatureLength,
    isProduction,
    applyTranslationElement,
} from '../../../main'
import { Col, Row, StepContent } from '../../../shared/components/V2/Layout'
import AgreementModal from '../components/AgreementModal'
import AgreementModalSig from '../components/AgreementModalSig'
import StepView from '../components/StepView'
import V2SignBox from '../../../shared/components/V2/V2SignBox'
import {
    mobile,
    tablet,
    desktop,
} from '../../../shared/components/helpers/styled_queries'
import t from '../../../shared/translations'
import { mapSteps } from '../../../shared/reducers/V2/reducer_steps'
import { V2_prePath } from '../../../shared/constants'
import store from '../../../shared/services/store'
import { V2_ACTIONS } from '../../../shared/actions/actionTypes'
import { AgreementContainer } from '../../../shared/components/V2/V2Agreement'
import SidebarProgress from '../components/SidebarProgress'
import { FloatGdprIcon } from '../components/GdprModal'
import { fireGtagEvent } from '../../../shared/services/events'
import PassengersClaimSignature from '../PassengersClaimSignature'

const StyledAgreementContainer = styled(AgreementContainer)`
    border: none;
    padding: unset;
`

const StyledRow = styled(Row)`
    border-radius: 16px;
    background-color: #FAFAFA;
    padding: 20px 40px;
    margin-bottom: 32px;

    ${desktop`
        border: solid 1px #f1f2f5;
        margin-bottom: 16px;
        ${({ mobileOnly }) => mobileOnly && 'display: none;'}
    `}

    ${tablet`
        ${({ desktopOnly }) => desktopOnly && 'display: none;'}
    `}

    ${mobile`
        padding: 20px 24px;
        ${({ desktopOnly }) => desktopOnly && 'display: none;'}
    `}

    ${Col} {
        padding: 0 !important;
    }
`

const RevStyledRow = styled(Row)`
    width: 100%;
    flex: 1 1 auto;
    margin-bottom: 12px;

    ${Col} {
        padding: 0 !important;
    }

    ${mobile`
        flex-direction: column-reverse;
    `}

    ${tablet`
        flex-direction: column-reverse;
    `}
`

const StyledColAgreement = styled(Col)`
    ${desktop`
        margin-left: 24px;
        `}
    ${mobile`
        display: flex;
        justify-content: center;
    `}

    ${tablet`
        display: flex;
        justify-content: center;
    `}
`

const Content = styled(StepContent)`
    font-family: 'Noto sans';
    display: flex;
    flex-direction: column;
    padding: 0px 12px;
    margin: 0 0 0 0;
`

const Subheader = styled.p`
    font-family: 'Noto sans';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #717171;
    margin: 0;
    margin-bottom: 8px;
    // text-align: center;

    span {
        font-weight: 400;
        color: #149954;
    }

    &.ml-8 {
        margin-left: 8px;
    }

    ${mobile`
        &.mobile-text-left {
            text-align: left;
        }

        span {
            color: #36a56b;
            font-weight: bold;
        }

        span:hover {
            cursor: pointer;
            filter: brightness(105%);
        }
    `}

    ${tablet`
        span {
            color: #36a56b;
            font-weight: bold;
        }

        span:hover {
            color: #36a56b;
            cursor: pointer;
            filter: brightness(105%);
        }
    `}

    ${desktop`
        text-align: left;
        margin-bottom: 0px;
    `}
`

const DesktopBtn = styled.button`
    font-family: 'Noto sans';
    cursor: pointer;
    border: 0;
    min-width: 104px;
    min-height: 48px;
    border-radius: 16px;
    text-align: center;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    ${({ theme }) =>
        `${
            theme.colors.actionButton
                ? `background-color: ${theme.colors.actionButton};`
                : `background-image: linear-gradient(to top, ${theme.colors.primaryColor}, ${theme.colors.quaternaryColor})};`
        }`}
    color: #ffffff;
    outline: 0 none;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        filter: brightness(105%);
    }

    &:focus {
        outline: 0 none;
    }
`

const DesktopBorderBtn = styled(DesktopBtn)`
    background: transparent;
    border: solid 1px ${({ theme }) => theme.colors.primaryColor};
    width: 100%;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-weight: 500 ${desktop`
        width: 100%;
    `};
`

const AirlineName = styled.div`
    display: inline;
    font-weight: 700;
`

const AgreementLabel = styled.span`
    font-weight: 700;
`

class ClaimSignature extends React.Component {
    state = {
        componentState: {
            signature: '',
        },
        ...this.props.V2Claim,
    }

    componentDidUpdate(prevProps, prevState) {
        const { setParentState } = this.props
        const { componentState } = this.state

        if (prevState.componentState.signature !== componentState.signature) {
            if (
                componentState.signature &&
                !V2handleSignatureLength(componentState.signature)
            ) {
                setParentState({
                    nextDisabled: false,
                })
            } else {
                setParentState({
                    nextDisabled: true,
                })
            }
        }
    }

    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {}

        const {
            getUserAgreements,
            skipSignature,
            nextStep,
            landingParameters,
        } = this.props
        setTimeout(() => {
            const { postedClaim } = this.props
            const postedClaimCopy =
                postedClaim || store.getState().v2_posted_claim
            const hasNextStep = _get(
                postedClaimCopy,
                'problem_claim_flight.airline.representativeAgreement',
                false
            )

            this.props.setParentState({
                nextDisabled: true,
                buttonText: hasNextStep
                    ? t`v2_button.continue`
                    : t`v2_button.complete_claim`,
            })

            scrollComponentToView('pageTopNavbar', false, true)

            const fullName = this.getUserName()
            const userAddress = this.getUserAddress()
            const userBirthdate = this.getUserBirthdate()

            if (skipSignature) {
                nextStep(true)
                return
            }
            if (fullName && postedClaimCopy && postedClaimCopy.id) {
                loadScreenOn()
                getUserAgreements(
                    postedClaimCopy.id,
                    fullName,
                    userBirthdate,
                    userAddress
                )
            } else {
                if (isProduction()) {
                    store.dispatch(
                        logKibana(
                            'v2_POSTED_CLAIM',
                            'PAGE /CLAIM-SIGNATURE',
                            'warning',
                            JSON.stringify({ postedClaimCopy, fullName })
                        )
                    )
                    setNavStep(0)
                    browserHistory.replace(V2_prePath)
                }
            }

            this.props.setParentState({
                noBack: true,
                isFinish: false,
                saveDraftEnabled: false,
            })

            const expediaId = '1151'
            if (landingParameters && landingParameters.idev_id === expediaId) {
                if (window && window.expediaGtag) {
                    window.expediaGtag('js', new Date())
                    window.expediaGtag('config', 'DC-9163167')
                }
            }
        }, 3000)
    }

    componentWillUnmount() {
        store.dispatch({
            type: V2_ACTIONS.SET_FAST_PAYOUT,
            payload: {},
        })
        store.dispatch({
            type: V2_ACTIONS.SELECTED_PAYOUT,
            payload: null,
        })
    }

    getUserName = () => {
        const { postedClaim } = this.props
        const { name, surname } = _get(postedClaim, 'user', {})

        if (name && surname) {
            return `${name} ${surname}`
        } else {
            return ''
        }
    }

    getUserAddress = () => {
        const { postedClaim, user } = this.props

        const { address, city, country, post_code } = _get(
            postedClaim,
            'user',
            {}
        )

        if (address && city && country) {
            return `${address}, ${city} ${_get(user, 'postCode', '') ||
                post_code ||
                ''}, ${country && country.title}`
        } else {
            return ''
        }
    }

    getUserBirthdate = () => {
        const { postedClaim } = this.props

        const { birthdate } = _get(postedClaim, 'user', {})

        if (birthdate) {
            return moment(birthdate).format('YYYY-MM-DD')
        } else {
            return ''
        }
    }

    _handleNextStep = () => {
        const {
            postedClaim,
            uploadUserSignatures,
            skipSignature,
            nextStep,
            userAgreement,
        } = this.props
        const { componentState } = this.state

        if (skipSignature) {
            return true
        }

        if (postedClaim && postedClaim.id && componentState.signature) {
            var blob = dataURItoBlob(componentState.signature)
            var fd = new FormData()
            fd.append('claim_agreements_signature[claim]', postedClaim.id)
            fd.append('claim_agreements_signature[types][]', 'sig')
            if (userAgreement && userAgreement.sil) {
                fd.append('claim_agreements_signature[types][]', 'sil')
                fd.append('claim_agreements_signature[types][]', 'sls')
            }
            fd.append('claim_agreements_signature[file]', blob)

            loadScreenOn()
            uploadUserSignatures(fd, false).then(() => {
                this.props.triggerAnalytics()
                nextStep(true)
                loadScreenOff()
            })
        }
        return false
    }

    handleSignatureState = e => {
        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                signature: e,
            },
        }))
    }

    render() {
        const { componentState } = this.state
        const {
            trans,
            nav,
            postedClaim,
            airlineTitle,
            userAgreement,
        } = this.props

        const userName = _get(postedClaim, 'user.name', '')
        const userSurname = _get(postedClaim, 'user.surname', '')
        const hasNextStep = _get(
            postedClaim,
            'problem_claim_flight.airline.representativeAgreement',
            false
        )
        if (!trans) {
            return null
        }

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                noBack={true}
                isFinish={false}
                disableTopBorder={true}
                titleBottomPadding={'32px'}
                nav={{
                    ...nav,
                    saveDraftEnabled: false,
                    nextDisabled: V2handleSignatureLength(
                        componentState.signature
                    ),
                }}
                Sidebar={
                    <SidebarProgress
                        paddingTop={0}
                        activeIndex={6}
                        checkedIndexes={[0, 1, 2, 3, 4, 5]}
                        hideFast={true}
                    />
                }
                title={t`v2_claim.claim_signature.title`}
                buttonText={
                    hasNextStep
                        ? t`common.button.check_your_claim`
                        : t`v2_button.complete_claim`
                }
                renderFPMobile={true}
            >
                <FloatGdprIcon
                    showModal={this.props.showModal}
                    path="/claim-signature"
                />
                <bold></bold>
                <Content>
                    <AgreementModal title={t`sign_form.contract_signed`}>
                        {userAgreement && (
                            <StyledAgreementContainer
                                dangerouslySetInnerHTML={{
                                    __html: userAgreement.sig,
                                }}
                            />
                        )}
                    </AgreementModal>
                    {userAgreement && userAgreement.sil && (
                        <AgreementModalSig title={t`sign_form.contract_signed`}>
                            {userAgreement && (
                                <StyledAgreementContainer
                                    dangerouslySetInnerHTML={{
                                        __html: userAgreement.sil,
                                    }}
                                />
                            )}
                        </AgreementModalSig>
                    )}
                    <StyledRow>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <Subheader
                                dangerouslySetInnerHTML={{
                                    __html: t`v2_claim.claim_signature.please_sign`,
                                }}
                            />
                        </Col>
                        <StyledColAgreement xs={12} sm={12} md={4} lg={4}>
                            <DesktopBorderBtn
                                onClick={() => {
                                    fireGtagEvent(
                                        { event: 'step7_sig_read_agreement' },
                                        true
                                    )
                                    this.props.showModal('agreementModal')
                                }}
                            >
                                {t`v2_claim.claim_signature.read_agreement`}
                            </DesktopBorderBtn>
                        </StyledColAgreement>
                    </StyledRow>
                    {userAgreement && userAgreement.sil && (
                        <StyledRow desktopOnly>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <Subheader>
                                    <>
                                        {applyTranslationElement(
                                            t`v2_claim.claim_signature.please_sign_sil`,
                                            '%airlineName%',
                                            <AirlineName>
                                                {airlineTitle}
                                            </AirlineName>
                                        )}
                                        &nbsp;
                                        <AgreementLabel>{t`v2_claim.claim_signature.please_sign_agreement_on_legal`}</AgreementLabel>
                                    </>
                                </Subheader>
                            </Col>
                            <StyledColAgreement xs={12} sm={12} md={4} lg={4}>
                                <DesktopBorderBtn
                                    onClick={() => {
                                        fireGtagEvent(
                                            {
                                                event:
                                                    'step7_sig_read_agreement',
                                            },
                                            true
                                        )
                                        this.props.showModal(
                                            'agreementModalSig'
                                        )
                                    }}
                                >
                                    {t`v2_claim.claim_signature.read_agreement`}
                                </DesktopBorderBtn>
                            </StyledColAgreement>
                        </StyledRow>
                    )}
                    <RevStyledRow>
                        <Col md={12} lg={12}>
                            <V2SignBox
                                name={userName}
                                surname={userSurname}
                                handleSignatureState={this.handleSignatureState}
                                clearLabel={t`v2_common.clear`}
                                signature={componentState.signature}
                                hasBox={true}
                                downloadEnabled={true}
                                userBirthdate={this.getUserBirthdate()}
                                userAddress={this.getUserAddress()}
                                userName={this.getUserName()}
                                countries={this.props.countries}
                                agreementType="sig"
                                claimId={_get(postedClaim, 'id', '')}
                            />
                        </Col>
                    </RevStyledRow>
                    <PassengersClaimSignature
                        claimId={_get(postedClaim, 'id', '')}
                    />
                </Content>
            </StepView>
        )
    }
}

function mapStateToProps(state) {
    return {
        landingParameters: state.landing_parameters,
        v2_step: state.v2_step,
        V2Claim: state.V2Claim,
        claimUUID: state.claim_UUID,
        userAgreement: state.userAgreement,
        trans: state.translations,
        postedClaim: state.v2_posted_claim,
        skipSignature: state.v2_skip_signature,
        user: state.user,
        countries: state.countries,
        airlineTitle:
            state.v2_posted_claim &&
            state.v2_posted_claim.problem_claim_flight &&
            state.v2_posted_claim.problem_claim_flight.airline.title,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            getUserAgreements,
            uploadUserSignatures,
            setV2Claim,
            nextStep,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(ClaimSignature)
