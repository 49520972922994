import STEP_1_FlightItinerary from './FlightItinerary'
import STEP_2_DisruptionDetails from './DisruptionDetails'
import STEP_3_DisurptionReason from './DisruptionReason'
import STEP_4_EmailDetails from './EmailDetails'
import STEP_4_FlightDetails from './FlightDetails'
import STEP_6_ClaimSignature from './ClaimSignature'
import STEP_5_PassengerDetails from './PassengerDetails/PassengerDetails'
import STEP_7_FlightDocuments from './FlightDocuments/'
import STEP_8_ThankYou from './ThankYou'
import DUPLICATE_PAGE from './DuplicatePage'
import MY_PROFILE from './MyProfile'
import IMPROVE_PAGE from './ImprovePage'
import SOCIAL_PAGE from './SocialPage'
import SHARE_KNOWLEDGE_PAGE from './ShareKnowledge'
import LOGIN from './Login'
import REGISTER from './Register'
import DRAFT_EXPIRED from './DraftExpiredPage'
import MY_CLAIMS from './MyClaims'
import CLAIM_INNER from './ClaimInner'
import PAYMENT from './PaymentPage/'
import NOT_SUPPORTED_AIRLINES from './NotSupportedAirlines'
import REMARKETING_UNSUBSCRIBE from './RemarketingUnsubscribe'
import SC_SUBSCRIPTIONS from './SkycopCare/ScSubscriptions'
import SC_SUBSCRIPTIONS_HISTORY from './SkycopCare/SubscriptionsHistory'
import SC_CANCEL_SUBSCRIPTIONS from './SkycopCare/CancelSubscription'
import SMART_DELAY_PAGE from '../../V2/components/SmartDelay/SmartDelayPage'
import STEP_1_FLIGHT_DETAILS from '../../V2/components/SmartDelay/FlightDetails'
import STEP_2_PASSENGER_DETAILS from '../../V2/components/SmartDelay/PassengerDetails'
import STEP_3_CONFIRMATION_PAGE from '../../V2/components/SmartDelay/Confirmation'
import STEP_4_REGISTRATION_COMPLETED from '../../V2/components/SmartDelay/RegistrationCompleted'

export default {
    STEP_1_FlightItinerary,
    STEP_2_DisruptionDetails,
    STEP_3_DisurptionReason,
    STEP_4_EmailDetails,
    STEP_4_FlightDetails,
    STEP_7_FlightDocuments,
    STEP_5_PassengerDetails,
    STEP_6_ClaimSignature,
    STEP_8_ThankYou,
    DUPLICATE_PAGE,
    MY_PROFILE,
    IMPROVE_PAGE,
    SOCIAL_PAGE,
    SHARE_KNOWLEDGE_PAGE,
    LOGIN,
    REGISTER,
    DRAFT_EXPIRED,
    MY_CLAIMS,
    SC_SUBSCRIPTIONS,
    SC_CANCEL_SUBSCRIPTIONS,
    SC_SUBSCRIPTIONS_HISTORY,
    CLAIM_INNER,
    PAYMENT,
    NOT_SUPPORTED_AIRLINES,
    REMARKETING_UNSUBSCRIBE,
    SMART_DELAY_PAGE,
    STEP_1_FLIGHT_DETAILS,
    STEP_2_PASSENGER_DETAILS,
    STEP_3_CONFIRMATION_PAGE,
    STEP_4_REGISTRATION_COMPLETED,
}
