import React from 'react'
import styled from 'styled-components'
import V2CheckBox from '../../../shared/components/V2/V2CheckBox'

const SubscribeP = styled.p`
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #333333;
    margin-left: 8px;
    font-weight: ${({ fontWeight }) => fontWeight};

    span,
    a {
        color: ${({ theme }) => theme.colors.primaryColor};
        font-weight: bold;

        &:hover {
            color: ${({ theme }) => theme.colors.primaryColor};
            filter: brightness(110%);
        }
    }
`

const Subscribe = ({ text, fontWeight, ...props }) => (
    <V2CheckBox
        label={() => (
            <SubscribeP
                fontWeight={fontWeight}
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )}
        type="checkbox"
        name="termsConditions"
        {...props}
    />
)

export default Subscribe
