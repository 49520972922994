import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from "styled-components";
import Helmet from "react-helmet";
import { loginPageShow, loginPageHide } from '../../../main';
import t from '../../../shared/translations';
import { paths } from '../../../shared/constants';
import { NewPasswordWrapper } from './NewPassword';
import { LoginContainer } from '../../V2/components/LoginModal';
import { Row, Col } from '../../../shared/components/V2/Layout';
import { NonModalCloseButton } from '../../../shared/components/V2/V2Buttons';
import { Title } from '../../V2/steps/common';

const Description = styled.p`
    font-size: 1.5rem;
    line-height: 1.8;
    margin-bottom: 1.8rem;
    text-align: center;
    margin-bottom: 0px;

    a {
        color: ${({ theme }) => theme.colors.primaryColor} !important;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            color: ${({ theme }) => theme.colors.primaryColor} !important;
            text-decoration: none;
            filter: brightness(110%);
        }
    }
`;

class NewPasswordSuccess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCentered: true,
        };
    }

    componentDidMount() {
        const { setParentState, isAuthenticated } = this.props;

        if (isAuthenticated) {
            browserHistory.push(paths.CLAIMS);
        } else {
            loginPageShow(true);
            window.addEventListener("resize", this._resize);
            setParentState({
                isCentered: true,
                hideNav: true,
            });
        }
    }

    componentDidUpdate() {
        loginPageShow(true);
    }

    componentWillUnmount() {
        loginPageHide();
        window.removeEventListener("resize", this._resize);
        this.props.setParentState({
            isCentered: false,
            hideNav: false,
        });
    }

    _resize = () => {
        if (this.state.isCentered && window.innerWidth < 961) {
            this.props.setParentState({
                isCentered: false,
            });
            this.setState({
                isCentered: false,
            });
        } else if (!this.state.isCentered && window.innerWidth >= 960) {
            this.props.setParentState({
                isCentered: true,
            });
            this.setState({
                isCentered: true,
            });
        }
    }

    handleCancel = () => {
        browserHistory.replace("/");
    }

    render() {
        const { trans } = this.props;

        if (!trans) {
            return null;
        }

        return (
            <NewPasswordWrapper>
                <LoginContainer style={{ overflow: 'unset' }}>
                    <Helmet>
                        <title>{`Skycop - ${t`change_pass.success.title`}`}</title>
                    </Helmet>
                    <NonModalCloseButton onClick={this.handleCancel} />
                    <Row>
                        <Col lg={12}>
                            <Title>
                                {t`change_pass.success.title`}
                            </Title>
                        </Col>
                    </Row>
                    <Description>
                        {t`change_pass.success.description`}
                        <Link onClick={() => browserHistory.replace(paths.V2.LOGIN)}> {t`reset_pass.success.description_link`}</Link>
                    </Description>
                </LoginContainer>
            </NewPasswordWrapper >
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.error.errorMessage,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordSuccess);