import React from 'react'
import styled from 'styled-components'
import t from '../../../shared/translations'
import { Container } from '../../../shared/components/V2/V2CheckBox'
import { Google, Facebook } from '../../../shared/components/Buttons'
import { Col } from '../../../shared/components/V2/Layout'
import {
    desktop,
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'

const Label = styled.p`
    vertical-align: middle;
    display: inline;
    cursor: pointer;

    .pulse {
        box-shadow: 0 0 0 rgba(241, 60, 60, 0.4);
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
        }
    }
    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
            box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
            box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
            box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
        }
    }
`

const LabelText = styled.p`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.01em;

    font-stretch: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    color: #333333;
    display: inline;
`

const ButtonWrapper = styled.span`
    position: relative;
    display: inline;
`

const StyledTransparentBtn = styled.div`
    display: inline-table;
    margin: 0;
    border: 0 none;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-weight: 400;
    font-size: 16px;
    background: transparent;
    outline: 0 none;
    box-shadow: none;
    line-height: 34px;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    text-align: center;
    cursor: pointer;
    margin-left: 6px;
    position: absolute;
    top: -7px;
    text-wrap: nowrap;
`

const StyledCheckmark = styled.span`
    display: inline;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: white;
    border: ${({ checked }) => (checked ? 'none' : 'solid 1px #cbd3df')};
    animation: unset;
    ${({ alert }) =>
        alert &&
        `
        border: 1px solid  #FE6050;
        animation: pulse 2s infinite;
    `}

    &:hover {
        border: 1px solid #cbd3df;
        border: ${({ theme }) => `1px solid ${theme.colors.primaryColor}`};
    }

    &:after {
        content: '';
        position: absolute;
        display: none;
        left: 9px;
        top: 4px;
        width: 7px;
        height: 12px;
        border: 1px solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`

const StyledCheckBox = styled.input`
    display: inline;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ ${StyledCheckmark}:after {
        display: block;
    }
    &:checked ~ ${StyledCheckmark} {
        background-color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const SocialColFb = styled(Col)`
    ${desktop`
    padding-left: 0px;
`}

    button {
        padding: 0;
        height: 100%;
        width: 100%;
        border-radius: 24px;
        height: 30px;
        font-family: 'Open Sans', sans-serif;
        font-size: 10px !important;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;

        &:before {
            top: 9px !important;
            left: -11px !important;
            font-size: 10px !important;
        }
    }
`

const SocialBtnWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${mobile`
    flex-wrap: wrap !important;
`}

    ${tablet`
flex-wrap: wrap !important;
`}
`

const GoogleCol = styled(Col)`
    button {
        padding: 0;
        height: 100%;
        width: 100%;
        border-radius: 24px;
        height: 30px;
        font-family: 'Open Sans', sans-serif;
        font-size: 10px !important;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;

        &:before {
            top: 0px !important;
            left: -11px !important;
            font-size: 5px !important;
            transform: scale(0.5);
        }
    }
`

const CreateAccountCheckbox = ({
    checked,
    onChange,
    handleSignIn,
    onLoginExternalSuccess,
}) => {
    return (
        <div>
            <Container checked={checked} paddingLeft="38px">
                <Label onClick={onChange}>
                    <StyledCheckBox type="checkbox" checked={checked} />
                    <StyledCheckmark checked={checked} />
                    <LabelText>{t`create.acc`}</LabelText>
                </Label>
                <ButtonWrapper>
                    <StyledTransparentBtn onClick={handleSignIn}>
                        {t`login.label.sign_in`}
                    </StyledTransparentBtn>
                </ButtonWrapper>
            </Container>
            {checked && (
                <SocialBtnWrapper>
                    <SocialColFb>
                        <Facebook
                            onLoginSuccess={onLoginExternalSuccess}
                            translationKey={'login.label.sign_up_with_facebook'}
                        />
                    </SocialColFb>
                    <GoogleCol>
                        <Google
                            onLoginSuccess={onLoginExternalSuccess}
                            translationKey={'login.label.sign_up_with_google'}
                        />
                    </GoogleCol>
                    <Col>{t`create.acc.fields.above`}</Col>
                </SocialBtnWrapper>
            )}
        </div>
    )
}

export default CreateAccountCheckbox
