import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import debounce from 'lodash/debounce'
import t from '../../../shared/translations'
import { V2_CORE } from '../../../shared/constants'
import { PageTitle } from '../../../shared/components/V2/TextElements'
import {
    SidebarContainer,
    PageViewContainer,
    PageContent,
    Col,
    Row,
} from '../../../shared/components/V2/Layout'
import { DesktopStepNav } from './StepNavigations'
import {
    desktop,
    mobile,
    tablet,
    tabletLG,
    tabletSM,
} from '../../../shared/components/helpers/styled_queries'
import {
    showModal,
    doItLaterBtnClick,
    setReferralModalShareCode,
} from '../../../shared/actions/v2_actions'
import {
    RightArrow,
    StopWatchRound,
    RightPointerArrow,
    ExclamationMark,
} from '../../../shared/components/V2/V2Icons'
import { InfoArea, RabbitIcon, InfoText } from './FastPayoutSideBox'
import { modalTypes, featureToggles } from '../../../shared/constants'
import {
    applyTranslationElement,
    isFeatureEnabled,
    isMobileDevice,
    getCookie,
} from '../../../main'
import { CloseButton } from '../../../shared/components/V2/V2Buttons'
import { AlertBox } from '../steps/RemarketingUnsubscribe'
import TrustBoxContainer from '../../../views/V2/components/TrustPilot'
import SkycopCarePromoBox from '../../../shared/components/V2/skycopCarePromo/SkycopCarePromoBox'
import SkycopCarePromoMobileBox from '../../../shared/components/V2/skycopCarePromo/SkycopCarePromoMobileBox'
import { PageTitleMobile } from '../../../shared/components/V2/TextElementForMobile'
import { CLAIM_FORM_VERSIONS } from '../../../shared/constants'

const LineBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #d9f3e4;
    display: none;

    ${desktop`
        display: block;
        width: calc(100% + 56px);
        left: -28px;
    `}
`

export const PriceListContainer = styled.div`
    display: flex;
    padding: 24px 40px;
    align-items: center;

    background: url('/images/top-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: #ECF7F1;
    border-radius: 24px;
        flex-direction: column;
        align-items: start;

    ${desktop`
        margin-bottom: 16px;
        // border-radius: 8px;
        // left: -28px;
        position: relative;
        width: calc(100% + 56px);
        // border-bottom-left-radius: 0px;
        // border-bottom-right-radius: 0px;
        margin-bottom: 30px;
    `}

    .news-text {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.4px;

        color: #333333;

        a {
            color: #333333;
            text-decoration: underline;
            font-weight: 600;

            &:hover {
                filter: brightness(110%);
            }
            
        }
    }

    ${tabletLG`
        max-width: 66.66666666666667%;
        border-radius: 24px;
    `}

    ${tabletSM`
        max-width: 100%;
        padding: 14px;
    `}

    ${tablet`
        margin: auto;
        padding: 24px;

        div, span, button {
            margin: 8px 0px;
        }

        .news-text {
            margin-left: 12px;
        }
    `}

    ${mobile`
        margin: auto;
        padding: 28px;

        div, span, button {
            margin: 8px 0px;
        }

        button {
            min-width: 200px;
        }
    `}
`

const FastPayoutMobile = styled(Col)`
    cursor: pointer;
`

const MobileInfoArea = styled(InfoArea)`
    padding: 16px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.primaryColor};
    color: #fff;

    ${InfoText} {
        color: #fff !important;
        padding-right: 8px;
    }

    svg {
        align-self: center;
        margin-left: auto;
    }
`

const FloatingDraftBox = styled.div`
    position: fixed;

    &.hide-notif {
        bottom: -150px;
        top: unset;
    }

    &.show-draft-notif {
        bottom: 0px;
        top: unset;
    }

    transition: top 0.7s ease, bottom 0.7s ease;
    width: ${({ width }) => width}px;
    min-height: 110px;
    z-index: 99999;
    display: flex;
    align-items: center;
    color: #353d51;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 0 -2px 9px -5px rgba(53, 61, 81, 0.23);
    background: #fff;
    padding: 16px 36px;
    bottom: -150px;
    top: unset;

    a {
        margin-left: 3px;
        color: #32b86a;
        font-weight: bold;

        &:hover {
            text-decoration: none;
            filter: brightness(1.1);
            cursor: pointer;
        }
    }

    svg {
        margin: 0px 8px;
    }

    button {
        svg {
            margin: 0;
        }
    }

    .iata {
        display: none;
    }

    .full {
        display: inline-flex;
    }

    .sw-timer {
        min-width: 36px;
        margin: 0;
        margin-right: 8px;
    }

    ${tablet`
        padding: 12px;
        padding-right: 36px;
        width: ${({ width }) => width + 32}px;

        .iata {
            display: inline-flex;
        }

        .full {
            display: none;
        }
    `}

    ${mobile`
        min-height: unset;
        z-index: 899999;
        bottom: unset;
        padding: 16px 12px;
        padding-right: 40px;
        background: #fff;
        width: 100%;
        top: 0px;
        bottom: unset;
        border-radius: unset;
        font-size: 12px;
        box-shadow: 0 4px 9px -5px rgba(53, 61, 81, 0.23);

        .iata {
            display: inline-flex;
        }

        .full {
            display: none;
        }

        &.hide-notif {
            top: -150px;
            bottom: unset;
        }

        button {
            right: 12px;
        }

        &.show-draft-notif {
            top: 0px;
            bottom: unset;
        }
    `}
`

const PriceListTitle = styled.div`
    font-family: Noto Sans;
    font-size: 40px;
    font-weight: 600;
    color: #333333;
`

const MobileDraftBox = styled(FloatingDraftBox)`
    position: relative;
    margin: 0px 12px;
    padding: 12px;
    border-radius: 4px;
    border: solid 1px #e4e7eb;
    width: auto;
    box-sizing: border-box;
    min-height: unset;
    box-shadow: unset;
`

const StyledAlertBox = styled(AlertBox)`
    margin: auto;
    margin-bottom: 24px;

    ${tablet`
        display: none;
    `}

    ${mobile`
        display: none;
    `}

    a {
        color: #373f53;
        font-weight: 600;
    }
`

const DraftFlight = ({ draft }) => (
    <span style={{ alignItems: 'center', display: 'inline-block' }}>
        <span className="full">{`${draft.airportDeparture.city || ''} (${draft
            .airportDeparture.iata || ''})`}</span>
        <span className="iata">{draft.airportDeparture.iata || ''}</span>
        <RightPointerArrow />
        <span className="full">{`${draft.airportArrival.city || ''} (${draft
            .airportArrival.iata || ''})`}</span>
        <span className="iata">{draft.airportArrival.iata || ''}</span>
    </span>
)

class StepView extends React.Component {
    state = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        popupPosition: 0,
        hideDraftBox: true,
        showMobile: false,
    }

    componentDidMount() {
        window.addEventListener('resize', this._onResize)
        window.addEventListener('scroll', this.calcMobilePos)
        this._onResize()
        setTimeout(() => {
            this.setState({
                hideDraftBox: false,
            })
        }, 800)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize)
        window.removeEventListener('scroll', this.calcMobilePos)
    }

    _onResize = debounce(() => {
        const width = window.innerWidth
        const height = window.innerHeight
        const parentWidth =
            (document.getElementById('step-page-view') || { offsetWidth: 800 })
                .offsetWidth - 32

        this.setState({ innerWidth: width, innerHeight: height, parentWidth })
    })

    getInfoTranslation = () => {
        const { regulation, v2_amount } = this.props

        if (regulation === 'ca' && v2_amount === 0) {
            return `${t`v2.price_list_amount_description_canada.eligibile`} ${t`v2.price_list_amount_description_canada.renumeration`}`
        }

        return t`v2.price_list_amount_description.with.amount`.replace(
            '%AMOUNT%',
            `€${v2_amount}`
        )
    }

    calcMobilePos = debounce(() => {
        const scrollTop = document.documentElement.scrollTop
        const { showMobile } = this.state

        if (scrollTop > 130 && !showMobile) {
            this.setState({
                showMobile: true,
            })
        } else if (scrollTop <= 130 && showMobile) {
            this.setState({
                showMobile: false,
            })
        }
    })

    calcBottomPos = debounce(() => {
        const parent = document.documentElement
        const offsetHeight = parent.offsetHeight
        const scrollTop = parent.scrollTop
        const scrollHeight = parent.scrollHeight
        const scrollDiff = scrollHeight - offsetHeight
        const { popupPosition } = this.state

        if (scrollDiff - scrollTop > 97 + 16) {
            if (popupPosition !== 0) {
                this.setState({
                    popupPosition: 0,
                })
            }
        } else {
            const newPopUpPos = 97 + 16 - (scrollDiff - scrollTop)
            if (popupPosition !== newPopUpPos) {
                this.setState({
                    popupPosition: newPopUpPos,
                })
            }
        }
    })

    resolveFlight = (sortedDrafts, getId) => {
        if (getId) {
            return sortedDrafts[0].id
        }

        return sortedDrafts[0]
    }

    handlePressLater = () => {
        const { doItLaterBtnClick, isLaterBtnClicked } = this.props
        if (isLaterBtnClicked) return
        // eslint-disable-next-line
        dataLayer.push({
            event: 'step5_afd_do_it_later',
        })
        doItLaterBtnClick()
    }

    handleReferralClick = () => {
        const { lang, setReferralModalShareCode } = this.props
        if (window.SkycopReferral) {
            window.SkycopReferral.ReferralModal.call(
                lang || 'en',
                shareData => {
                    if (shareData.code) {
                        setReferralModalShareCode(shareData.code)
                    }
                }
            )
        }
    }

    render() {
        const {
            Sidebar,
            title,
            nav,
            noBack,
            isFinish,
            buttonText,
            titleBottomPadding,
            hideButton,
            hideBorder,
            titleText,
            showBorder = false,
            renderPriceList = false,
            renderFPMobile = false,
            renderSocialShare = false,
            renderTrustPilot = false,
            showModal,
            fastPayouts,
            disabledClick,
            paddingTop = null,
            HeaderInfoBox,
            allDrafts,
            v2_step,
            padding,
            sideBarPaddingTop,
            noDoLaterBtn,
        } = this.props
        const { innerWidth, hideDraftBox, showMobile } = this.state
        const sortedDrafts = allDrafts.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt)
        })

        const isClaimV1 = getCookie('cf') === CLAIM_FORM_VERSIONS.V1

        const isPromoEnabled =
            [
                '/flight-details/email',
                V2_CORE.STEP_4.path,
                V2_CORE.STEP_7.path,
                V2_CORE.STEP_6.path,
            ].includes(window.location.pathname) &&
            isFeatureEnabled(featureToggles.claimCompletePromoCodes)

        return (
            <PageViewContainer
                paddingBottom={renderSocialShare ? '32px' : null}
                id="step-page-view"
                style={{ flexDirection: 'column' }}
            >
                {window.location.pathname === '/' &&
                    isFeatureEnabled(featureToggles.extraordinary_alert) && (
                        <StyledAlertBox
                            bgColor="#F1F7FF"
                            color="#7F8FA4"
                            icon={() => (
                                <ExclamationMark
                                    color="#ffffff"
                                    fill="#5296FF"
                                />
                            )}
                            content={t`alert.extraordinary_info`}
                        />
                    )}

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {Sidebar && !isClaimV1 && (
                        <SidebarContainer
                            paddingTop={
                                noBack
                                    ? sideBarPaddingTop || '0px'
                                    : sideBarPaddingTop || '40px'
                            }
                        >
                            {Sidebar}
                        </SidebarContainer>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            width: '100%',
                        }}
                    >
                        {renderPriceList && (
                            <PriceListContainer>
                                {/* <Bubble
                                    active={true}
                                    style={{
                                        minWidth: '40px',
                                        minHeight: '40px',
                                    }}
                                >
                                    <SuccessMark
                                        bgColor="transparent"
                                        color="#fff"
                                    />
                                </Bubble> */}
                                <PriceListTitle>{t`v2.price_list_amount_title`}</PriceListTitle>
                                <span className="news-text">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: this.getInfoTranslation(),
                                        }}
                                    />
                                </span>
                            </PriceListContainer>
                        )}

                        <div style={{ width: 'inherit' }}>
                            {isPromoEnabled &&
                                (innerWidth < 720 ? (
                                    <Row>
                                        <Col>
                                            <SkycopCarePromoMobileBox />
                                        </Col>
                                    </Row>
                                ) : (
                                    <SkycopCarePromoBox />
                                ))}
                            <div>
                                <PageContent
                                    id="step-page-content"
                                    paddingTop={paddingTop}
                                    padding={padding}
                                >
                                    {innerWidth < 482 &&
                                        window.location.pathname === '/' &&
                                        sortedDrafts &&
                                        sortedDrafts.length !== 0 && (
                                            <MobileDraftBox>
                                                <StopWatchRound className="timer" />
                                                <span>
                                                    {applyTranslationElement(
                                                        t`draft_exists.you_have_unfinished_claim`,
                                                        '%FLIGHT%',
                                                        <DraftFlight
                                                            draft={this.resolveFlight(
                                                                sortedDrafts
                                                            )}
                                                        />,
                                                        true,
                                                        ' '
                                                    )}
                                                    <a
                                                        href={`${
                                                            window.location
                                                                .origin
                                                        }/?draftId=${this.resolveFlight(
                                                            sortedDrafts,
                                                            true
                                                        )}`}
                                                    >{t`v2_button.continue`}</a>
                                                </span>
                                            </MobileDraftBox>
                                        )}
                                    {renderFPMobile &&
                                        fastPayouts &&
                                        fastPayouts.hasFast &&
                                        innerWidth < 961 && (
                                            <Row>
                                                <FastPayoutMobile
                                                    sm={12}
                                                    md={8}
                                                    onClick={() =>
                                                        showModal(
                                                            modalTypes.paymentPlanModal
                                                        )
                                                    }
                                                >
                                                    <MobileInfoArea>
                                                        <RabbitIcon />
                                                        <InfoText
                                                            dangerouslySetInnerHTML={{
                                                                __html: t`v2_claim.sidebar.fast_payout_available`,
                                                            }}
                                                        />
                                                        <RightArrow
                                                            color="#fff"
                                                            strokeWidth="2"
                                                        />
                                                    </MobileInfoArea>
                                                </FastPayoutMobile>
                                            </Row>
                                        )}

                                    {isClaimV1 ? (
                                        <PageTitleMobile
                                            title={title}
                                            hideTitle={false}
                                            titleText={titleText}
                                            bottomPadding={0}
                                            disableTopBorder={true}
                                            hasHeaderInfo={!!HeaderInfoBox}
                                        />
                                    ) : (
                                        <PageTitle
                                            title={title}
                                            // hideTitle={innerWidth < 960}
                                            hideTitle={false}
                                            titleText={titleText}
                                            bottomPadding={titleBottomPadding}
                                            disableTopBorder={!showBorder}
                                            hasHeaderInfo={!!HeaderInfoBox}
                                        >
                                            {showBorder && <LineBorder />}
                                            {HeaderInfoBox && <HeaderInfoBox />}
                                        </PageTitle>
                                    )}
                                    {this.props.children}

                                    <DesktopStepNav
                                        noDoLaterBtn={noDoLaterBtn}
                                        paddingDefined={!!padding}
                                        isClaimFormV1Enabled={isClaimV1}
                                        {...nav}
                                        noBack={!!noBack}
                                        isFinish={!!isFinish}
                                        buttonText={buttonText}
                                        hideButton={!!hideButton}
                                        hideBorder={!!hideBorder}
                                        disabledClick={disabledClick}
                                        step={v2_step}
                                        handlePressLater={this.handlePressLater}
                                        handleReferralClick={
                                            this.handleReferralClick
                                        }
                                    />
                                </PageContent>
                            </div>
                        </div>
                        {!isMobileDevice() && !renderTrustPilot && (
                            <Row style={{ marginTop: '30px' }}>
                                <TrustBoxContainer />
                            </Row>
                        )}
                    </div>
                    {window.location.pathname === '/' &&
                        sortedDrafts &&
                        sortedDrafts.length !== 0 && (
                            <FloatingDraftBox
                                className={
                                    !hideDraftBox &&
                                    (innerWidth < 482 ? !!showMobile : true)
                                        ? 'show-draft-notif'
                                        : 'hide-notif'
                                }
                                width={this.state.parentWidth}
                            >
                                <StopWatchRound className="timer" />
                                <span>
                                    {applyTranslationElement(
                                        t`draft_exists.you_have_unfinished_claim`,
                                        '%FLIGHT%',
                                        <DraftFlight
                                            draft={this.resolveFlight(
                                                sortedDrafts
                                            )}
                                        />,
                                        true,
                                        ' '
                                    )}
                                    <a
                                        href={`${
                                            window.location.origin
                                        }/?draftId=${this.resolveFlight(
                                            sortedDrafts,
                                            true
                                        )}`}
                                    >{t`v2_button.continue`}</a>
                                </span>
                                <CloseButton
                                    id="btn-hide-draft-notif"
                                    onClick={() =>
                                        this.setState({ hideDraftBox: true })
                                    }
                                />
                            </FloatingDraftBox>
                        )}
                </div>
            </PageViewContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        progressState: state.v2_progressState,
        fastPayouts: state.v2_hasFastPayout,
        v2_amount: state.v2_amount,
        regulation: state.regulation,
        allDrafts: state.allDrafts,
        v2_step: state.v2_step,
        isLaterBtnCLicked: state.v2_step_four.isLaterBtnClicked,
        lang: state.language,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { showModal, doItLaterBtnClick, setReferralModalShareCode },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(StepView)
