import { SIGNATURE } from '../actions/actionTypes';


export default function (state = null, action) {
    switch (action.type) {
        case SIGNATURE:
            return { ...state, data: action.payload};
        default:
    }
    return state;
};