import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { loginPageShow, loginPageHide, callAsyncActionWithConfig } from '../../main';
import { getOwnerInfo, getAgreementCombinedInfo } from '../../shared/actions/index';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import t from '../../shared/translations';
import V2Agreement from "../../shared/components/V2/V2Agreement";

class OwnerAgreement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            claimId: "",
        };
    }

    UNSAFE_componentWillMount() {
        const { location, getAgreementCombinedInfo } = this.props;
        const { query } = location;
        loginPageShow(true);

        if (query) {
            const claimId = query["claimId"];
            if (claimId) {
                this.setState({ claimId });
                callAsyncActionWithConfig(getAgreementCombinedInfo, "owner", "sil", claimId);
            } else {
                browserHistory.push("/");
                loginPageHide();
            }
        }
    }

    componentWillUnmount() {
        loginPageHide();
    }

    componentDidMount() {
        loginPageShow(true);
    }

    render() {
        const { location, passengerInfo, trans } = this.props;
        const { claimId } = this.state;

        if (trans) {
            return (
                <V2Agreement
                    claimId={claimId}
                    location={location}
                    agreementType="sil"
                    isLegal={true}
                    passengerInfo={passengerInfo}
                    passengerType="owner"
                    headerProps={{
                        part1: t`sign_form.legal.title_part1`,
                        part2: t`sign_form.legal_combined.title_part2_bold`,
                    }}>
                </V2Agreement>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        passengerInfo: state.passenger_name,
        userAgreement: state.userAgreement,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getOwnerInfo,
        getAgreementCombinedInfo
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerAgreement);