import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import _get from 'lodash/get'
import {
    Row,
    PageViewContainer,
    Col,
} from '../../../../shared/components/V2/Layout'
import {
    StyledSmartDelayContainer,
    TitleContainer,
    StyledH1,
} from './SmartDelayPage'
import t from '../../../../shared/translations'
import { V2BinIcon } from '../../../../shared/components/V2/V2Icons'
import { scrollComponentToView } from '../../../../main'
import {
    desktop,
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'
import NewSelectField from '../../../../shared/components/V2/NewSelectField'
import InputField from '../../../../shared/components/V2/InputField'
import V2PhoneField from '../../../../shared/components/V2/V2PhoneField'
import {
    DottedLine,
    AddButton,
    Hr,
    RegisterUpToDescription,
} from './FlightDetails'
import SmartDelayHandler from './SmartDelayHandler'
import { PlusIcon } from '../../../../views/V2/steps/ClaimInner/icons.js'
import { additionalPassengerDetailsEntity } from '../../../../shared/reducers/V2/reducer_smart_delay'
import SmartDelayStepper from './SmartDelayStepper'

const PageHeader = styled.p`
    color: #353d51;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
`

const AdditionalPassengerContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 12px;

    ${mobile`
font-size: 26px;
margin-left: 18px;
`}

    ${tablet`
font-size: 26px;
margin-left: unset;
`}
`

const BinIconWrapper = styled.div`
    display: flex;
    margin-left: 8px;
    &:hover {
        cursor: pointer;
    }
`

const AdditionalPassenger = styled.p`
    color: #525f7f;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: unset;
`

const StyledCol = styled(Col)`
    ${desktop`
    padding: 0px 12px;
    `}
`

const AddPassengerWrapper = styled.div`
    ${mobile`
    margin: 20px 14px;
`}
`

class PassengerDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            passengerDetails: props.passengerDetails,
        }
    }

    componentDidMount() {
        this.initUserData()
        scrollComponentToView('pageTopNavbar', false, true)
    }

    initUserData = () => {
        const { user } = this.props
        if (!user) return
        this.setState(prevState => ({
            passengerDetails: {
                ...prevState.passengerDetails,
                1: {
                    ...prevState.passengerDetails[1],
                    name: !prevState.passengerDetails[1].name
                        ? user.name
                        : prevState.passengerDetails[1].name,
                    surname: !prevState.passengerDetails[1].surname
                        ? user.surname
                        : prevState.passengerDetails[1].surname,
                    email: !prevState.passengerDetails[1].email
                        ? user.email
                        : prevState.passengerDetails[1].email,
                    phoneCode: !prevState.passengerDetails[1].phoneCode
                        ? user.phone_code.id
                        : prevState.passengerDetails[1].phoneCode,
                    phone: !prevState.passengerDetails[1].phone
                        ? user.phone
                        : prevState.passengerDetails[1].phone,
                },
            },
        }))
    }

    handleAddPassengerDetails = () => {
        const { passengerDetails } = this.state

        const nextPassengerDetailsId = Object.keys(passengerDetails).length + 1
        if (nextPassengerDetailsId) {
            this.setState(prevState => ({
                ...prevState,
                passengerDetails: {
                    ...prevState.passengerDetails,
                    [nextPassengerDetailsId]: additionalPassengerDetailsEntity,
                },
            }))
        }
    }

    handleRemovePassengerDetails = key => {
        const { passengerDetails } = this.state

        const newPassengerDetails = Object.keys(passengerDetails).reduce(
            (acc, val) => {
                if (val !== key) {
                    return {
                        ...acc,
                        [val]: passengerDetails[val],
                    }
                }
                return acc
            },
            {}
        )

        const updatePassengers = Object.keys(newPassengerDetails).reduce(
            (acc, val, i) => {
                return {
                    ...acc,
                    [i + 1]: passengerDetails[val],
                }
            },
            {}
        )
        this.setState(prevState => ({
            ...prevState,
            passengerDetails: updatePassengers,
        }))
    }

    handlePassengerDetailsValue = (event, key) => {
        const e = _get(event, 'target', event)

        if (e && e.name && (e.value || e.value === '')) {
            this.setState(prevState => ({
                ...prevState,
                passengerDetails: {
                    ...prevState.passengerDetails,
                    [key]: {
                        ...prevState.passengerDetails[key],
                        [e.name]: e.value,
                    },
                },
            }))
        }
    }

    render() {
        const { trans, countries } = this.props
        const { passengerDetails } = this.state

        const salutationOptions = [
            {
                id: 1,
                value: 'mr',
                label: t`user.title_mr`,
                name: 'salutation',
            },
            {
                id: 2,
                value: 'ms',
                label: t`user.title_ms`,
                name: 'salutation',
            },
            {
                id: 3,
                value: 'mrs',
                label: t`user.title_mrs`,
                name: 'salutation',
            },
        ]

        if (!trans) {
            return null
        }

        return (
            <PageViewContainer>
                <StyledSmartDelayContainer>
                    <TitleContainer>
                        <Helmet>
                            <title>{`Skycop - ${t`smart_delay.title`} - ${t`smart_delay.passenger_details_header`}`}</title>
                        </Helmet>
                        <StyledH1>{t`smart_delay.title`}</StyledH1>
                        <SmartDelayStepper />
                        <PageHeader>{t`smart_delay.passenger_details_header`}</PageHeader>
                    </TitleContainer>
                    {passengerDetails &&
                        Object.keys(passengerDetails).map(key => (
                            <div key={key}>
                                {key !== '1' && (
                                    <AdditionalPassengerContainer>
                                        <AdditionalPassenger>{t`smart_delay.passenger_details_additional_passenger`}</AdditionalPassenger>
                                        <BinIconWrapper
                                            onClick={() =>
                                                this.handleRemovePassengerDetails(
                                                    key
                                                )
                                            }
                                        >
                                            <V2BinIcon height={16} width={16} />
                                        </BinIconWrapper>
                                    </AdditionalPassengerContainer>
                                )}
                                <Row>
                                    <StyledCol sm={12} md={6} lg={6}>
                                        <NewSelectField
                                            className="mb-2"
                                            label={t`common.placeholder.salutation`}
                                            errorText=""
                                            required
                                            selectProps={{
                                                name: 'salutation',
                                                value:
                                                    passengerDetails[key]
                                                        .salutation,
                                                clearable: false,
                                                isSearchable: false,
                                                isOpen: false,
                                                options: salutationOptions,
                                                onChange: e =>
                                                    this.handlePassengerDetailsValue(
                                                        e,
                                                        key
                                                    ),
                                            }}
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <InputField
                                        required
                                        name="name"
                                        label={t`v2_common.label.name`}
                                        placeholder={t`v2_common.placeholder.name`}
                                        value={passengerDetails[key].name}
                                        onChange={e =>
                                            this.handlePassengerDetailsValue(
                                                e,
                                                key
                                            )
                                        }
                                        autoComplete="name-first"
                                    />
                                    <InputField
                                        required
                                        label={t`v2_common.label.surname`}
                                        name="surname"
                                        placeholder={t`v2_common.placeholder.surname`}
                                        value={passengerDetails[key].surname}
                                        onChange={e =>
                                            this.handlePassengerDetailsValue(
                                                e,
                                                key
                                            )
                                        }
                                        autoComplete="name-last"
                                    />
                                </Row>
                                {key !== '2' && (
                                    <Row>
                                        <InputField
                                            required
                                            sm={6}
                                            label={t`v2_common.label.email`}
                                            name="email"
                                            type="email"
                                            placeholder={t`v2_common.placeholder.email`}
                                            value={passengerDetails[key].email}
                                            onChange={e =>
                                                this.handlePassengerDetailsValue(
                                                    e,
                                                    key
                                                )
                                            }
                                            autoComplete="do-not-autofill"
                                        />
                                        <Col xs={12} md={12} lg={6}>
                                            <V2PhoneField
                                                required
                                                label={t`v2_common.label.phone`}
                                                placeholder={t`v2_common.placeholder.phone`}
                                                onChange={e =>
                                                    this.handlePassengerDetailsValue(
                                                        e,
                                                        key
                                                    )
                                                }
                                                maxLength={16}
                                                autoComplete="off"
                                                phoneCode={
                                                    passengerDetails[key]
                                                        .phoneCode
                                                }
                                                value={
                                                    passengerDetails[key].phone
                                                }
                                                prefixInputName="phoneCode"
                                                numberInputName="phone"
                                                countries={countries}
                                                onDrop={e => e.preventDefault()}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {key !==
                                    Object.keys(
                                        passengerDetails
                                    ).length.toString() && <DottedLine />}
                            </div>
                        ))}
                    <AddPassengerWrapper>
                        {Object.keys(passengerDetails).length !== 2 && (
                            <>
                                <AddButton
                                    type="borderBtn"
                                    onClick={this.handleAddPassengerDetails}
                                >
                                    <PlusIcon fill="#525f7f" />
                                    {t`smart_delay.passenger_details_add_passenger`}
                                </AddButton>
                                <RegisterUpToDescription>{t`smart_delay.passenger_details_register_up_to`}</RegisterUpToDescription>
                            </>
                        )}
                    </AddPassengerWrapper>
                    <Hr />
                    <SmartDelayHandler passengerDetails={passengerDetails} />
                </StyledSmartDelayContainer>
            </PageViewContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        passengerDetails: state.smartDelay.passengerDetails,
        countries: state.countries,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(PassengerDetails)
