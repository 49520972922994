import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _get from 'lodash/get'
import DatePicker from '../../../shared/components/V2/DatePicker'
import FlightInput, {
    flightTypes,
} from '../../../views/V2/components/FlightInput'
import {
    PageViewContainer,
    Row,
    Col,
} from '../../../shared/components/V2/Layout'
import InputField from '../../../shared/components/V2/InputField'
import t from '../../../shared/translations'
import { getUserData } from '../../../shared/actions'
import { SPACE_KEY } from '../../../shared/components/V2/Modal'
import { modalTypes } from '../../../shared/constants'
import {
    showModal,
    validateSkycopCareView,
    setArrivalAirport,
    setDepartureAirport,
    asyncPreloadAirport,
    setSelectedAirports,
    setUsedAirlines,
    submitPlannedTrip,
    getPlannedTrips,
    deletePlannedTrip,
} from '../../../shared/actions/v2_actions'
import { bindActionCreators } from 'redux'
import { loadScreenOn, loadScreenOff } from '../../../main'
import { FlightNumberIcon } from '../../../shared/components/V2/SkycopPay/icons'
import { getAirports } from '../../../shared/actions'
import {
    StyledPageContent,
    FormContainer,
    Button,
    Header,
    Subtitle,
    BodyText,
    TooltipInner,
    H2,
} from './styles'
import { TripItem } from './TripItem'
// import UploadModal from './UploadModal'

class PlannedTrips extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            errors: {},
            departureAirport: '',
            arrivalAirport: '',
            flightNumber: '',
            flightDate: '',
            trips: [],
        }
    }

    async componentDidMount() {
        const {
            getAirports,
            setDepartureAirport,
            setArrivalAirport,
        } = this.props
        const { iata_from, iata_to } = this.props.location.query

        getPlannedTrips().then(trips => {
            this.setState({ trips })
        })
        const getAirportsOptions = { range: 100 }
        getAirports(
            iata_from,
            'airportDeparture',
            null,
            getAirportsOptions
        ).then(e => {
            this.preloadAirport(
                iata_from,
                'airportDeparture',
                setDepartureAirport
            )
            const airline = e.payload.find(item => item.iata === iata_from)

            if (airline) {
                this.setState({ airportDeparture: airline.id })
            }
        })
        getAirports(iata_to, 'airportArrival', null, getAirportsOptions).then(
            e => {
                this.preloadAirport(
                    iata_to,
                    'airportArrival',
                    setArrivalAirport
                )
                const airline = e.payload.find(item => item.iata === iata_to)

                if (airline) {
                    this.setState({ airportArrival: airline.id })
                }
            }
        )
    }

    setDate = date => {
        this.setState({ flightDate: moment(date).format('YYYY-MM-DD') })
    }

    handleChangeEvent = event => {
        const e = _get(event, 'target', event)

        if (e.name === 'flightNumber') {
            if (/^(?=.{3,6}$)[A-Za-z]{1,2}\s*\d{3,5}$/.test(e.value)) {
                this.setState({
                    flightNumberError: '',
                })
            } else {
                this.setState({
                    flightNumberError: 'flight_number_error',
                })
            }
        }
        this.setState({ [e.name]: e.value })
    }

    preloadAirport = (query, name, callback) => {
        const { asyncPreloadAirport } = this.props
        const inputRef = this[`${name}Ref`]

        if (inputRef) {
            inputRef.loadOptions(query)
            asyncPreloadAirport(query, airport =>
                this.handleAirportChange({ ...airport, name }, callback)
            )
        }
    }

    handleAirportChange = (event, callback) => {
        const e = _get(event, 'target', event)

        if (e && e.name && e.value) {
            this.setState({ [e.name]: e.value })
        }
    }

    clearAirport = name => {
        if (name) {
            this.setState({ [name]: '' })
        }
    }

    setRef = (name, ref) => {
        const refName = `${name}Ref`
        this[refName] = ref
    }

    isDisabled = () => {
        const {
            departureAirport,
            arrivalAirport,
            flightNumber,
            flightDate,
            flightNumberError,
        } = this.state

        return !(
            departureAirport &&
            arrivalAirport &&
            flightNumber &&
            flightDate &&
            !flightNumberError
        )
    }

    submitTrip = async () => {
        const {
            departureAirport,
            arrivalAirport,
            flightNumber,
            flightDate,
        } = this.state

        const data = {
            plannedTripFlights: [
                {
                    departureAirport,
                    arrivalAirport,
                    flightDate,
                    flightNumber,
                },
            ],
        }

        try {
            loadScreenOn()
            await submitPlannedTrip(data)
            const trips = await getPlannedTrips()
            this.setState({ trips })
            this.resetFormState()
        } catch (e) {
            console.log(e)
        } finally {
            loadScreenOff()
        }
    }

    resetFormState = () => {
        this.setState({
            departureAirport: '',
            arrivalAirport: '',
            flightNumber: '',
            flightDate: '',
        })
    }

    deleteTrip = async id => {
        try {
            loadScreenOn()
            await deletePlannedTrip(id)
            const trips = await getPlannedTrips()
            this.setState({ trips })
        } catch (e) {
            console.log(e)
        } finally {
            loadScreenOff()
        }
    }

    uploadDocumentsLink = () => {
        const { showModal } = this.props
        const [first, second] = t`planned_trips.upload_text`.split('[[')
        const [uploadText, last] = second ? second.split(']]') : ['', '']

        return (
            <BodyText>
                {first}
                <span
                    onClick={() =>
                        showModal(modalTypes.uploadPlannedTripModal, {
                            cb: () => console.log('hello'),
                        })
                    }
                >
                    {uploadText}
                </span>
                {last}
            </BodyText>
        )
    }

    valid = current => {
        const today = new Date()
        let now = new Date()
        now.setDate(today.getDate() - 1)

        return current.isAfter(now)
    }

    render() {
        const { trans } = this.props
        const {
            flightNumber,
            departureAirport,
            arrivalAirport,
            flightDate,
            trips,
        } = this.state

        if (!trans) {
            return null
        }

        return (
            <PageViewContainer id="step-page-view">
                <StyledPageContent paddingTop={'0px'}>
                    <Header
                        title={t`planned_trips.title`}
                        metaDesc={'Your planned trips'}
                        bottomPadding="2px"
                    ></Header>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Subtitle>{t`planned_trips.subtitle`}</Subtitle>
                        </Col>
                    </Row>
                    <Col>
                        <FormContainer xs={12} sm={12} md={6} lg={6}>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <DatePicker
                                        innerRef={ref => {
                                            this.dateTimeWrapperRef = ref
                                        }}
                                        required
                                        type="text"
                                        name="flightDate"
                                        label={t`v2_common.label.date`}
                                        placeholder={t`planned_trips.placeholder.date`}
                                        value={flightDate}
                                        dateFormat="YYYY-MM-DD"
                                        viewMode="years"
                                        startDate={new Date().getFullYear()}
                                        endDate={0}
                                        enabledNext={true}
                                        handleChange={this.handleChangeEvent}
                                        isValidDate={this.valid}
                                    />
                                </Col>
                                <InputField
                                    name="flightNumber"
                                    label={t`v2_common.label.flight_number`}
                                    placeholder={t`v2_common.placeholder.flight_number`}
                                    type="string"
                                    required
                                    value={flightNumber}
                                    onChange={this.handleChangeEvent}
                                    Icon={FlightNumberIcon}
                                    onKeyDown={e => {
                                        if (e.keyCode === SPACE_KEY) {
                                            e.preventDefault()
                                            return false
                                        }
                                    }}
                                    onFocus={() => {
                                        this.setState({ flightNumberError: '' })
                                    }}
                                    tooltip={{
                                        children: () => (
                                            <TooltipInner
                                                dangerouslySetInnerHTML={{
                                                    __html: t`planned_trips.flight_number_box_text`,
                                                }}
                                            />
                                        ),
                                        dataTip: 'disabled',
                                        dataFor: 'flightNumber',
                                        place: 'bottom',
                                        id: 'flightNumber',
                                        questionId: 'flight-number',
                                        type: 'info',
                                        color: 'light',
                                        effect: 'solid',
                                    }}
                                    errorText={t`${_get(
                                        this.state,
                                        'flightNumberError'
                                    )}`}
                                />
                            </Row>
                            <Row>
                                <Col sm={12} md={8} lg={6}>
                                    <FlightInput
                                        setRef={this.setRef}
                                        autofocus={false}
                                        hasIcon
                                        required
                                        clear={() =>
                                            this.clearAirport(
                                                'departureAirport'
                                            )
                                        }
                                        autoload={!!departureAirport}
                                        type={flightTypes.departure}
                                        label={t`planned_trips.label.departed_from`}
                                        name="departureAirport"
                                        value={departureAirport}
                                        defaultValue={departureAirport}
                                        onChange={this.handleAirportChange}
                                    />
                                </Col>
                                <Col sm={12} md={8} lg={6}>
                                    <FlightInput
                                        setRef={this.setRef}
                                        hasIcon
                                        required
                                        clear={() =>
                                            this.clearAirport('arrivalAirport')
                                        }
                                        autoload={!!arrivalAirport}
                                        label={t`planned_trips.label.arrived_to`}
                                        name="arrivalAirport"
                                        value={arrivalAirport}
                                        defaultValue={arrivalAirport}
                                        onChange={this.handleAirportChange}
                                    />
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>{this.uploadDocumentsLink()}</Col>
                            </Row> */}

                            <Row>
                                <Col
                                    xs={12}
                                    md={12}
                                    className="button-container"
                                >
                                    <Button
                                        disabled={this.isDisabled()}
                                        onClick={() => this.submitTrip()}
                                    >
                                        {t`planned_trips.button.add_trip`}
                                    </Button>
                                </Col>
                            </Row>
                        </FormContainer>
                    </Col>
                    {!!trips.length && (
                        <Row>
                            <Col style={{ paddingTop: '50px' }}>
                                <H2>{t`planned_trips.2nd_section`}</H2>
                            </Col>
                        </Row>
                    )}
                    {trips.sort(sortDates).map((trip, index) => (
                        <Row key={index}>
                            <Col>
                                <TripItem
                                    key={trip.id}
                                    t={t}
                                    onDeleteClick={this.deleteTrip}
                                    trip={_get(trip, 'planned_trip_flights[0]')}
                                    tripId={trip.id}
                                    status={_get(
                                        trip,
                                        'planned_trip_flights[0].check_result'
                                    )}
                                />
                            </Col>
                        </Row>
                    ))}
                    {/* <Row>
                        <Col>
                            <TripItem
                                status="claimable"
                                t={t}
                                onDeleteClick={this.deleteTrip}
                                tripId={'sdasdasdasd'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TripItem status="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TripItem status="question" />
                        </Col>
                    </Row>
                    <Row>{JSON.stringify(this.state.trips)}</Row> */}
                </StyledPageContent>
                {/* {_get(modal, 'modalType', '') ===
                    modalTypes.uploadPlannedTripModal && (
                    <UploadModal submit={() => console.log('hello')} />
                )} */}
            </PageViewContainer>
        )
    }
}

function sortDates(a, b) {
    const firstDate = _get(a, 'planned_trip_flights[0].flight_date')
    const secondDate = _get(b, 'planned_trip_flights[0].flight_date')
    return new Date(firstDate) - new Date(secondDate)
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            validateSkycopCareView,
            getUserData,
            getAirports,
            asyncPreloadAirport,
            setDepartureAirport,
            setArrivalAirport,
            setSelectedAirports,
            setUsedAirlines,
        },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
        countries: state.countries,
        is_valid_smtp: state.valid_email_smtp,
        sc_errors: state.sc_errors,
        productsV2: state.sc_products,
        products: state.sc_v2_products,
        usedAirports: state.v2_UsedAirports,
        airportArrival: state.v2_airportArrival,
        airportDeparture: state.v2_airportDeparture,
        selectedAirports: state.v2_selectedAirports,
        postedClaim: state.v2_posted_claim,
        modal: state.toggle_modal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlannedTrips)
