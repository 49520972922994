import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import t from '../../../shared/translations'
import { IconWhatsapp } from '../../../shared/components/svgIcons'
import { CheckBox } from '../../../shared/components/Form'

const Container = styled.div`
    background: #ecf7f1;
    border-radius: 16px;
    padding: 14px 16px;
    display: flex;
`

const SubCol = styled.div`
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;

    padding: 0px 6px 0px 0px;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    color: #333333;
    opacity: ${({ opacity }) => opacity || '1'};

    &:first-child {
        padding-left: 0px;
        padding-top: 2px;
    }

    &:last-child {
        padding-right: 0px;
        margin-left: auto;
        display: inline-flex;
    }
`

const WhatsAppBox = ({ ...props }) => {
    console.log('props', props)
    return (
        <Container>
            <SubCol>
                <CheckBox {...props} />
            </SubCol>
            <SubCol
                opacity={!props.checked ? '0.5' : '1'}
                dangerouslySetInnerHTML={{
                    __html: t`v2_whatsapp_enable_text`,
                }}
            />
            <SubCol opacity={!props.checked ? '0.5' : '1'}>
                <IconWhatsapp />
            </SubCol>
        </Container>
    )
}

WhatsAppBox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default WhatsAppBox
