import React from 'react'
import styled from 'styled-components'
import { Col } from '../Layout'
import { handleMobileInputBlur, handleMobileInputFocus } from '../../../../main'
import { Button } from './Button'

const Container = styled('div')`
    position: relative;
    display: flex;
    background: #fff;
    width: 100%;
    height: 56px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: normal;
    color: #043d5d;
    padding-left: 50px;
    border: 1px solid #cbd3df;
    align-items: center;
    padding-right: 5px;
    cursor: pointer;

    .info-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            font-family: 'Aeonik';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: #043d5d;

            p {
                margin: 0;
            }
        }

        .button-container {
            button {
                padding: 12px 24px;
                height: 45px;
            }
        }
    }

    .select-icon {
        position: absolute;
        top: 18px;
        left: 18px;
    }
`

const InputCol = styled(Col)`
    input {
        position: absolute;
        border: ${({ hasDanger }) => hasDanger && '1px solid #FE6050'};
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
`

const borderWidth = '1px'

const Input = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 56px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: normal;
    color: #043d5d;
    padding: 12px 16px;
    padding-left: 50px;
    border: 1px solid #cbd3df;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    ${({ StatusIcon }) => (StatusIcon ? 'padding-right: 32px;' : '')}

    &:hover,
    &:active,
    &:focus {
        border: 1px solid #043d5d;
        box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
    }

    &:disabled,
    &.disabled {
        background: #f2f5f9;
        color: #7f8fa4;
        border: ${borderWidth} solid #f2f5f9;
        font-weight: 600;
        opacity: 1;
        -webkit-text-fill-color: #7f8fa4;

        &:hover {
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &.warn {
        border: ${borderWidth} solid #ff713b;
    }

    &.danger {
        border: ${borderWidth} solid #fe6050;
    }

    &::placeholder {
        color: #043d5d;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

export const ErrorTextBox = styled.div`
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fe6050;

    &.warn {
        color: #ff713b;
    }
`

export const FileInput = ({
    errorText,
    warning,
    hasDanger,
    label,
    LabelComponent,
    className,
    suggestedText,
    isValidSmtp,
    required,
    onChange,
    disabled,
    onFocus = () => null,
    onBlur = () => null,
    lg = 6,
    sm = 8,
    xs = 12,
    tooltip,
    Icon,
    StatusIcon,
    inlineColStyle = {},
    buttonLabel,
    ...props
}) => (
    <InputCol
        hasDanger={!!errorText || hasDanger}
        className={className}
        style={inlineColStyle}
        xs={xs}
        sm={sm}
        lg={lg}
    >
        {LabelComponent && <LabelComponent />}
        <Container>
            <div>
                <span className="select-icon">
                    {Icon ? <Icon className="select-icon" /> : null}
                </span>
            </div>
            <div className="info-container">
                <div className="text">
                    <p>{label}</p>
                </div>
                <div className="button-container">
                    <Button text={buttonLabel} variant="secondary" />
                </div>
                <Input
                    placeholder={label}
                    onChange={disabled ? () => null : onChange}
                    className={errorText && warning && 'warn'}
                    type="file"
                    disabled={disabled}
                    onFocus={e => {
                        e.preventDefault()
                        handleMobileInputFocus()
                        onFocus(e)
                    }}
                    onBlur={e => {
                        e.preventDefault()
                        handleMobileInputBlur()
                        onBlur(e)
                    }}
                    {...props}
                />
            </div>
        </Container>

        {errorText && (
            <ErrorTextBox className={warning && 'warn'}>
                {errorText}
            </ErrorTextBox>
        )}
    </InputCol>
)
