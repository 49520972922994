import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { V2ArrowUp, V2ArrowDown } from '../../V2/V2Icons'
import { desktop } from '../../helpers/styled_queries'

const Container = styled.div`
    display: none;

    ${desktop`
        display: flex;
    `}
`

const Button = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.4rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    font-weight: 400;
    min-width: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #101b35;
    background-color: white;
    border-color: transparent;
    touch-action: manipulation;
    border-radius: 0;
    white-space: nowrap;

    &:hover {
        color: ${({ theme }) => theme.colors.secondaryColor};
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    background: ${({ signatureConfigs }) =>
        signatureConfigs && signatureConfigs.navigationBarColor};
`

const Bubble = styled.div`
    align-self: center;
    width: 40px;
    height: 40px;
    /* background-color: #e3f5ea; */
    background-color: ${({ theme }) => theme.colors.primaryColor}20;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0.9;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme, signatureConfigs }) =>
        signatureConfigs && signatureConfigs.menuItemColor
            ? signatureConfigs.menuItemColor
            : theme.colors.primaryColor};
    margin-right: 8px;
`

const ArrowContainer = styled.div`
    display: flex;
    padding-top: 2px;
`

const NavUserButton = ({ showMenu, nickname, active, signatureConfigs }) => (
    <Container>
        <Button onClick={showMenu} signatureConfigs={signatureConfigs}>
            <Bubble signatureConfigs={signatureConfigs}>{nickname}</Bubble>
            <ArrowContainer>
                {active ? (
                    <V2ArrowUp
                        width="10"
                        height="6"
                        strokeWidth="2"
                        stroke={
                            signatureConfigs && signatureConfigs.menuItemColor
                        }
                    />
                ) : (
                    <V2ArrowDown
                        width="10"
                        height="6"
                        strokeWidth="2"
                        stroke={
                            signatureConfigs && signatureConfigs.menuItemColor
                        }
                    />
                )}
            </ArrowContainer>
        </Button>
    </Container>
)

NavUserButton.propTypes = {
    showMenu: PropTypes.func.isRequired,
}
export default NavUserButton
