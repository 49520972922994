import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col } from '../V2/Layout'
import t from '../../translations'
import { handleMobileInputBlur, handleMobileInputFocus } from '../../../main'
import { desktop } from '../../../shared/components/helpers/styled_queries'

const InputCol = styled(Col)`
    position: relative;

    input {
        border: ${({ hasDanger }) => hasDanger && '1px solid #FE6050'};

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    label {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        color: #7f8fa4;
        letter-spacing: normal;
        margin-bottom: 8px;

        span {
            &.required {
                color: #fe6050;
            }
        }
    }
`

const borderWidth = '1px'

const Input = styled.input`
    border-radius: 8px;
    background: #fff;
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: normal;
    color: #353d51;
    padding: 12px 16px;
    border: 1px solid #cbd3df;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover,
    &:active,
    &:focus {
        border: ${borderWidth} solid ${({ theme }) => theme.colors.primaryColor};
        box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
    }

    &:disabled,
    &.disabled {
        background: #f2f5f9;
        color: #7f8fa4;
        border: ${borderWidth} solid #f2f5f9;
        font-weight: 600;
        opacity: 1;
        -webkit-text-fill-color: #7f8fa4;

        &:hover {
            box-shadow: none;
            cursor: not-allowed;
        }
    }

    &.warn {
        border: ${borderWidth} solid #ff713b;
    }

    &.danger {
        border: ${borderWidth} solid #fe6050;
    }

    &::placeholder {
        color: #7f8fa4;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
    }
`

const ErrorTextBox = styled.div`
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fe6050;

    &.warn {
        color: #ff713b;
    }
`

const ShowButton = styled.button`
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    right: 24px;
    top: 50px;
    min-width: 60px;
    padding: 0px 8px;
    height: 32px;
    border-radius: 16px;
    border: solid 1px #cbd3df;
    background-color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #353d51;

    &:hover {
        opacity: 0.6;
    }

    ${desktop`
        right: 20px;
        top: 46px;
    `}
`

class V2PasswordField extends React.Component {
    state = {
        showPassword: false,
    }

    _toggleShow = () => {
        const { showPassword } = this.state
        this.setState({ showPassword: !showPassword })
    }

    render() {
        const {
            errorText,
            warning,
            hasDanger,
            label,
            LabelComponent,
            className,
            suggestedText,
            isValidSmtp,
            required,
            onChange,
            disabled,
            onFocus = () => null,
            onBlur = () => null,
            lg = 6,
            sm = 8,
            xs = 12,
            ...props
        } = this.props

        const { showPassword } = this.state

        return (
            <InputCol
                hasDanger={!!errorText || hasDanger}
                className={className}
                xs={xs}
                sm={sm}
                lg={lg}
            >
                {label && (
                    <label>
                        {label}
                        {required ? <span className="required"> *</span> : ''}
                    </label>
                )}
                {LabelComponent && <LabelComponent />}
                <ShowButton
                    tabIndex="-1"
                    onMouseOver={this._toggleShow}
                    onMouseOut={this._toggleShow}
                    onMouseUp={e => {
                        e.preventDefault()
                        this._toggleShow()
                    }}
                    onTouchEnd={e => {
                        e.preventDefault()
                        this._toggleShow()
                    }}
                    onClick={e => e.preventDefault()}
                    // className="btn btn-outline-secondary btn-sm btn--show js-toggle-pass h-pos-a h-r"
                >
                    <span>{t`login.label.show_password` || 'Show'}</span>
                </ShowButton>
                <Input
                    onChange={disabled ? () => null : onChange}
                    className={errorText && warning && 'warn'}
                    type={showPassword ? 'text' : 'password'}
                    disabled={disabled}
                    onFocus={e => {
                        e.preventDefault()
                        handleMobileInputFocus()
                        onFocus(e)
                    }}
                    onBlur={e => {
                        e.preventDefault()
                        handleMobileInputBlur()
                        onBlur(e)
                    }}
                    {...props}
                />
                {errorText && (
                    <ErrorTextBox className={warning && 'warn'}>
                        {errorText}
                    </ErrorTextBox>
                )}
            </InputCol>
        )
    }
}

V2PasswordField.propTypes = {
    readOnly: PropTypes.bool,
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    label: PropTypes.string,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    suggestedText: PropTypes.shape({
        text: PropTypes.string,
        callback: PropTypes.func,
    }),
}

V2PasswordField.defaultProps = {
    errorText: '',
    label: '',
    className: '',
    readOnly: false,
    maxLength: 255,
}

export default V2PasswordField
