import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    currentIndex: 0,
    translateValue: 0,
};

export default function (state = INITIAL_STATE, action) {
    const { type, currentIndex, translateValue,  } = action;

    switch (type) {
        case V2_ACTIONS.SET_ONBOARDING_SLIDER_STEPS:
            return {
                currentIndex,
                translateValue,
            };
        default:
    }
    return state;
}