import React, { useEffect } from 'react'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter, browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import { NextStepCircle, CurrentStepCircle } from './icons'
import {
    Text,
    NavItem,
    NavList,
    NavListContainer,
    TextWrapper,
    NextStepIndex,
    NextStepWrapper,
    CurrentStepWrapper,
    CurrentStepIndex,
    RightHr,
    LeftHr,
} from './styles'
import t from '../../../../shared/translations'
import { STEPS } from './constants'
import { CLAIM_FORM_VERSIONS } from '../../../../shared/constants'
import { getCookie } from '../../../../main'

export const MobileNavigation = ({
    activeIndex,
    checkedIndexes,
    location,
    nextStepCB,
    ...props
}) => {
    useEffect(() => {
        const element = document.querySelector('.scroll-container')

        if (element && element.scrollWidth) {
            if (props.v2_step.stepIndex) {
                element.scrollTo(70 * props.v2_step.stepIndex, 0)
            }
        }
    }, [location.pathname, props.v2_step.index])

    const handleClick = step => {
        const numberType = _get(step, 'type', '') === 'number'

        if (numberType) return

        if (
            step.index <= props.v2_step.latestStepIndex &&
            props.v2_step.stepIndex < 5
        ) {
            if (
                step.index > props.v2_step.stepIndex ||
                props.v2_step.current === '/thanks'
            ) {
                return
            }
            browserHistory.push(step.path)
        }
    }

    if (getCookie('cf') === CLAIM_FORM_VERSIONS.V1) {
        return null
    }

    return (
        <NavListContainer>
            <div className="scroll-container" id="sc">
                <NavList id="mobile-step-nav">
                    {STEPS.map((step, index) => {
                        const active =
                            _get(step, 'path', '') === location.pathname
                        const numberType = _get(step, 'type', '') === 'number'
                        const lastIndex = props.v2_step.latestStepIndex
                        const isChecked =
                            (numberType && step.checkedIndex <= lastIndex) ||
                            (numberType && props.v2_step.current === '/thanks')
                        const isCurrentStep =
                            (numberType &&
                                step.checkedIndex - 1 <= lastIndex) ||
                            (numberType &&
                                props.v2_step.current === '/thanks') ||
                            !!step.initial

                        const isCircleChecked =
                            step.index <=
                                (numberType ? lastIndex : lastIndex - 1) ||
                            props.v2_step.current === '/thanks'

                        return (
                            <NavItem
                                key={index}
                                className={active ? 'active' : ''}
                                disabled={step.disabled}
                                onClick={() => !isChecked && handleClick(step)}
                            >
                                {index + 1 !== STEPS.length && (
                                    <RightHr isChecked={isChecked} />
                                )}
                                {index !== 0 && (
                                    <LeftHr
                                        isChecked={
                                            step.index <=
                                                (numberType
                                                    ? lastIndex
                                                    : lastIndex - 1) ||
                                            props.v2_step.current === '/thanks'
                                        }
                                    />
                                )}

                                <div className="icon">
                                    {isCircleChecked ? (
                                        <CurrentStepWrapper>
                                            <CurrentStepCircle />
                                            <CurrentStepIndex>
                                                {index + 1}
                                            </CurrentStepIndex>
                                        </CurrentStepWrapper>
                                    ) : isCurrentStep ? (
                                        <CurrentStepWrapper>
                                            <CurrentStepCircle />
                                            <CurrentStepIndex>
                                                {index + 1}
                                            </CurrentStepIndex>
                                        </CurrentStepWrapper>
                                    ) : (
                                        <NextStepWrapper>
                                            <NextStepCircle />
                                            <NextStepIndex>
                                                {index + 1}
                                            </NextStepIndex>
                                        </NextStepWrapper>
                                    )}
                                </div>
                                <TextWrapper>
                                    <Text>{t`${step.text}`}</Text>
                                </TextWrapper>
                            </NavItem>
                        )
                    })}
                </NavList>
            </div>
        </NavListContainer>
    )
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        V2Claim: state.V2Claim,
        representativeAgreementRequired: _get(
            state,
            'state.v2_posted_claim.problem_claim_flight.airline.representativeAgreement',
            false
        ),
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MobileNavigation))
