import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import _get from "lodash/get";
import { hideModal } from "../../../shared/actions/v2_actions";
import { CloseButton, V2Button } from "../../../shared/components/V2/V2Buttons";
import Modal from "../../../shared/components/V2/Modal";
import t from "../../../shared/translations";
import { modalTypes } from "../../../shared/constants";
import { Row, Col } from "../../../shared/components/V2/Layout";
import { Title } from "../steps/common";
import { LargeExclamationMark } from "../../../shared/components/V2/V2Icons";

const Container = styled.div`
    padding: 32px 60px;

    ${({ noBackground }) =>
        noBackground &&
        `
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    `}
`;

const StyledTitle = styled(Title)`
    padding: 20px;
    padding-bottom: 12px;
    margin-bottom: 0;
`;

const SplitRow = styled(Row)`
    height: 1px;
    background: #eee;
    width: 100%;
    margin: 24px 0px;
`;

class StripeFailedModal extends React.Component {
    render() {
        const { hideModal } = this.props;
        const message = _get(this, "props.modal.props.message", "An unknown error has occurred!");

        return (
            <Modal modalType={modalTypes.stripeFailedModal} height="fit-content" maxWidth='600px' maxHeight="fit-content">
                <CloseButton onClick={hideModal} width="34" height="34" />
                <Container>
                    <Row style={{ justifyContent: 'center', padding: '12px 0px' }}>
                        <LargeExclamationMark />
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                        <StyledTitle>
                            {t`sc.error.request_failed`}
                        </StyledTitle>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            {message}
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-60px', width: 'calc(100% + 120px)' }}>
                        <SplitRow />
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                        <Col sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <V2Button
                                onClick={hideModal}
                                style={{
                                    width: 'fit-content'
                                }}
                            >
                                {t`v2_button.close`}
                            </V2Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        hideModal,
    }, dispatch);
}

export default connect(
    (state) => ({
        modal: state.toggle_modal,
    }),
    mapDispatchToProps
)(StripeFailedModal);
