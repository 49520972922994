import { V2_ACTIONS } from '../../actions/actionTypes'
const INITIAL_STATE = {
    errorModalPromoCode: '',
}
export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_MODAL_PROMO_CODE:
            return {
                ...state,
                errorModalPromoCode: action.payload,
            }
        default:
    }
    return state
}
