import { V2_ACTIONS } from '../../actions/actionTypes'

const INITIAL_STATE = false

export default function(state = INITIAL_STATE, action) {
    const { type, payload } = action

    switch (type) {
        case V2_ACTIONS.SET_CLAIMABLE:
            return true

        case V2_ACTIONS.SET_IS_TRANSPORT_AVAILABLE:
            return {
                ...state,
                isTransportAvailable: payload,
            }
        default:
    }
    return state
}
