import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// eslint-disable-next-line
import { browserHistory } from 'react-router'
import styled, { injectGlobal } from 'styled-components'
import {
    getUserData,
    toggleBrandedGdpr,
    setThemeConfigOptions,
    logKibana,
} from '../../shared/actions'
import {
    prevStep,
    nextStep,
    showModal,
    getClaimDraft,
    doItLaterBtnClick,
    setReferralModalShareCode,
} from '../../shared/actions/v2_actions'
import _get from 'lodash/get'
import { MobileStepNav } from './components/StepNavigations'
import { desktop, mobile } from '../../shared/components/helpers/styled_queries'
import store from '../../shared/services/store'
import { V2_ACTIONS } from '../../shared/actions/actionTypes'
import './v2_globals.css'
import {
    paths,
    V2_prePath,
    modalTypes,
    featureToggles,
} from '../../shared/constants'
import ClaimDraftModal from '../V2/components/ClaimDraftModal'
import OnboardingModal from './components/OnboardingModal'
import {
    setAffiliateCookie,
    pushDatalayerByPath,
    isProduction,
    isFeatureEnabled,
    loadScreenOff,
    loadScreenOn,
    getCookie,
} from '../../main'
import PaymentPlanModal from '../V2/components/PaymentPlanModal'
import FlightItinerary from './steps/FlightItinerary'
import InfoMobileModal from '../V2/components/InfoMobileModal'
import MobileNavigation from '../V2/components/mobileNavigation'
import PayoutDetailsMobile from '../V2/components/PayoutDetailsMobile'
import SocialShareModal from './components/SocialShareModal'
import RatingModal from './components/RatingModal'
import SkycopCareModal from './components/SkycopCareModal'
import {
    SocialShareContainer,
    TrustpilotContainer,
} from '../../shared/components/V2/Layout'
import GdprModal from './components/GdprModal'
import TrustBoxContainer from './components/TrustPilot'
import PromoGiftModal from './components/PromoGiftModal'
import { CLAIM_FORM_VERSIONS } from '../../shared/constants'
import SocialPageUpdated from './steps/SocialPageUpdated'
import VirtualCardsUpdated from './components/virtualCards/VirtualCardsUpdated'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    font-family: 'Open Sans', sans-serif;
    padding: 0px;
    ${({ isCentered }) => isCentered && 'justify-content: center;'}

    ${desktop`
        padding: 0px 10px!important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .m-y10-d {
            margin-left: 10px;
            margin-right: 10px;
        }

        .mb-10 {
            margin-bottom: 10px;
        }
    `}

    ${mobile`
        .mb-m-10 {
            margin-bottom: 10px;
        }
    `}

    .mt-10 {
        margin-top: 10px;
    }

    .m-y10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mt-12 {
        margin-top: 12px;
    }

    .mt-2 {
        margin-top: 2px;
    }

    @media (min-width: 1200px) { width: 1380px; max-width: 100%; margin-left: auto; margin-right: auto; } 
`

export const ContentContainer = styled.div`
    ${({ avoidShrink }) =>
        avoidShrink &&
        `
        display: flex;
        flex-shrink: 0;
    `}

    ${desktop`
        padding: 0px 10px;
    `}
`

class IndexV2 extends React.Component {
    nextTimeout = null

    state = {
        noBack: false,
        nextDisabled: true,
        didPush: false,
        preventDoubleClick: false,
        saveDraftEnabled: false,
        isCentered: false,
        hideNav: false,
        disabledClick: null,
    }

    nextStepHandler = () => {
        let callBack = () => false
        const ref = this.stepRef
        if (
            ref &&
            typeof ref.getWrappedInstance === 'function' &&
            typeof ref.getWrappedInstance()._handleNextStep === 'function'
        ) {
            callBack = ref.getWrappedInstance()._handleNextStep
        }
        if (
            ref &&
            isFeatureEnabled(featureToggles.autoSaveDraft) &&
            typeof ref.getWrappedInstance === 'function' &&
            typeof ref.getWrappedInstance()._handleSaveDraft === 'function'
        ) {
            ref.getWrappedInstance()._handleSaveDraft()
        }

        return callBack()
    }

    prevStepHandler = () => {
        let callBack = () => false
        const ref = this.stepRef
        if (
            ref &&
            typeof ref.getWrappedInstance === 'function' &&
            typeof ref.getWrappedInstance()._handlePrevStep === 'function'
        ) {
            callBack = ref.getWrappedInstance()._handlePrevStep
        }
        if (
            ref &&
            isFeatureEnabled(featureToggles.autoSaveDraft) &&
            typeof ref.getWrappedInstance === 'function' &&
            typeof ref.getWrappedInstance()._handleSaveDraft === 'function'
        ) {
            ref.getWrappedInstance()._handleSaveDraft()
        }

        callBack()
    }

    handleSaveDraft = () => {
        let callBack = () => false
        const ref = this.stepRef
        if (
            ref &&
            typeof ref.getWrappedInstance === 'function' &&
            typeof ref.getWrappedInstance()._handleSaveDraft === 'function'
        ) {
            callBack = ref.getWrappedInstance()._handleSaveDraft
        }
        callBack()
    }

    nextStepCB = e => {
        const { nextStep } = this.props
        const { preventDoubleClick } = this.state

        if (preventDoubleClick) {
            return
        } else {
            const proceed = this.nextStepHandler()
            if (proceed && _get(e, 'target', null)) {
                nextStep()
                e.target.blur()
            }
            this.setState({ preventDoubleClick: true }, () => {
                if (this.nextTimeout) {
                    clearTimeout(this.nextTimeout)
                }

                this.nextTimeout = setTimeout(() => {
                    this.setState({ preventDoubleClick: false })
                }, 1500)
            })
        }
    }

    prevStepCB = e => {
        const { prevStep } = this.props
        const ignoreProceed = this.prevStepHandler()
        if (!ignoreProceed) {
            prevStep()
            if (_get(e, 'target', null)) {
                e.target.blur()
            }
        }
    }

    setNoBack = val => {
        this.setState({
            noBack: val,
        })
    }

    saveDraft = () => {
        this.handleSaveDraft()
        const { showModal, logKibana } = this.props

        showModal(modalTypes.claimDraftModal)
        logKibana(
            'draft_modal_opened',
            _get(window, 'location.pathname', '/'),
            'warning'
        )
    }

    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        if (!this.props.user && nextProps.user) {
            this.redirectInactiveUser()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.redirectInactiveUser()
        }

        const draftPreloadRedirect = _get(
            this,
            "props.location.query['r']",
            null
        )
        if (draftPreloadRedirect === 'f') {
            loadScreenOff()
            browserHistory.replace(
                _get(window, 'location.origin', '') +
                    _get(window, 'location.pathname', '')
            )
        }

        const prevPath = prevProps.location.pathname
        const currPath = this.props.location.pathname
        const finalPaths = [
            paths.V2.STEP_7_CLAIM_SIGNATURE,
            paths.V2.STEP_8_THANK_YOU,
        ]
        if (
            prevPath !== currPath &&
            currPath !== V2_prePath &&
            isProduction()
        ) {
            if (
                finalPaths.includes(prevPath) &&
                !finalPaths.includes(currPath)
            ) {
                browserHistory.push(prevPath)
            }
        }
    }

    redirectInactiveUser = () => {
        const { user, getUserData } = this.props
        if (!user) {
            getUserData().then(user => {
                if (user && !user.active) {
                    browserHistory.push(paths.PROFILE)
                }
            })
        } else {
            if (user && !user.active) {
                browserHistory.push(paths.PROFILE)
            }
        }
    }

    handleUrlParams = async () => {
        const {
            location,
            setThemeConfigOptions,
            getClaimDraft,
            logKibana,
            setReferralModalShareCode,
        } = this.props
        let reqObj = location.query

        const idev_tid1 = reqObj['idev_tid1']
        const medium = reqObj['medium']
        const draftId = reqObj['draftId']
        const shareCode = reqObj['shareCode']
        const claimNow = reqObj['claimNow']
        const lang = reqObj['lang']

        if (!!shareCode) {
            setReferralModalShareCode(shareCode)

            if (
                window.location.origin ===
                process.env.REACT_APP_REFERRAL_CLAIM_NOW_URL
            ) {
                if (window.SkycopReferral && !claimNow) {
                    window.SkycopReferral.ReferralModal.call(
                        lang || 'en',
                        () => {},
                        '',
                        shareCode,
                        () => setReferralModalShareCode('')
                    )
                }
                return
            }

            if (claimNow === 'true') {
                window.location.href = `${process.env.REACT_APP_REFERRAL_CLAIM_NOW_URL}?shareCode=${shareCode}&claimNow=false`
                return
            }

            const claimNowLink = `${process.env.REACT_APP_REFERRAL_CLAIM_NOW_URL}?shareCode=${shareCode}&claimNow=true`
            if (window.SkycopReferral && !claimNow) {
                window.SkycopReferral.ReferralModal.call(
                    lang || 'en',
                    () => {},
                    claimNowLink,
                    shareCode,
                    () => setReferralModalShareCode('')
                )
            }
        }

        if (idev_tid1) {
            setAffiliateCookie('partner_tid1', idev_tid1)
        }
        if (medium) {
            setAffiliateCookie('partner_medium', medium)
        }
        if (reqObj['theme']) {
            toggleBrandedGdpr(true)

            try {
                const theme = window.atob(reqObj['theme'])
                const themeObj = JSON.parse(theme)
                setThemeConfigOptions({
                    colors: {
                        ...(themeObj || {}).colors,
                    },
                    logo: {
                        ...(themeObj || {}).logo,
                    },
                })
            } catch (err) {}
        }
        if (draftId) {
            loadScreenOn()
            await getClaimDraft(draftId)
            store.dispatch({
                type: V2_ACTIONS.CLAIM_UUID,
                payload: draftId,
            })
            logKibana(
                'draft_loaded',
                _get(window, 'location.pathname', '/'),
                'warning'
            )
        }
    }

    async componentDidMount() {
        const { airportDeparture, airportArrival } = this.props

        await this.handleUrlParams()

        if (!airportArrival || !airportDeparture) {
            store.dispatch({
                type: V2_ACTIONS.STEP_RESET,
                payload: 0,
            })
        }

        if (!airportArrival && !airportDeparture) {
            store.dispatch({
                type: V2_ACTIONS.SET_FAST_PAYOUT,
                payload: {},
            })
            store.dispatch({
                type: V2_ACTIONS.SELECTED_PAYOUT,
                payload: null,
            })
        }

        // eslint-disable-next-line
        injectGlobal`
            body {
                overflow-y: scroll;
                overflow-x: hidden;
                position: relative !important;
            }
        `
    }

    setParentState = data => {
        this.setState(data)
    }

    triggerAnalytics = () => {
        const {
            location: { pathname },
        } = this.props
        pushDatalayerByPath(pathname)
    }

    handlePressLater = () => {
        const { doItLaterBtnClick, isLaterBtnClicked } = this.props
        if (isLaterBtnClicked) return
        // eslint-disable-next-line
        dataLayer.push({
            event: 'step5_afd_do_it_later',
        })
        doItLaterBtnClick()
    }

    handleReferralClick = () => {
        const { lang, setReferralModalShareCode } = this.props
        if (window.SkycopReferral) {
            window.SkycopReferral.ReferralModal.call(
                lang || 'en',
                shareData => {
                    if (shareData.code) {
                        setReferralModalShareCode(shareData.code)
                    }
                }
            )
        }
    }
    render() {
        const {
            hideNav,
            isCentered,
            renderSocialShare,
            renderTrustPilot,
            renderVirtualCards,
            hideMobileTrustPilot,
        } = this.state
        const { trans, user, modal, location, route, v2_step } = this.props
        let childs = this.props.children

        if (!childs) {
            childs = <FlightItinerary location={location} route={route} />
        }

        const isClaimV1 = getCookie('cf') === CLAIM_FORM_VERSIONS.V1

        const propsChildren = React.Children.map(childs, child =>
            React.cloneElement(child, {
                ref: ref => (this.stepRef = ref),
                setNoBack: this.setNoBack,
                nav: {
                    nextStepCB: this.nextStepCB,
                    prevStepCB: this.prevStepCB,
                    saveDraft: this.saveDraft,
                },
                setParentState: this.setParentState,
                isDisabled: this.state.nextDisabled,
                triggerAnalytics: this.triggerAnalytics,
            })
        )

        if (!trans) {
            return null
        }

        if (user && !user.active) {
            browserHistory.push(paths.PROFILE)
        }

        return (
            <Container isCentered={isCentered}>
                {_get(modal, 'modalType', '') ===
                    modalTypes.onboardingModal && <OnboardingModal />}
                {_get(modal, 'modalType', '') ===
                    modalTypes.paymentPlanModal && <PaymentPlanModal />}
                {_get(modal, 'modalType', '') ===
                    modalTypes.infoMobileModal && <InfoMobileModal />}
                {_get(modal, 'modalType', '') ===
                    modalTypes.payoutDetailsMobileModal && (
                    <PayoutDetailsMobile />
                )}
                {_get(modal, 'modalType', '') ===
                    modalTypes.claimDraftModal && <ClaimDraftModal />}
                {_get(modal, 'modalType', '') ===
                    modalTypes.socialShareModal && <SocialShareModal />}
                {_get(modal, 'modalType', '') === modalTypes.ratingModal && (
                    <RatingModal />
                )}
                {_get(modal, 'modalType', '') === modalTypes.gdprModal && (
                    <GdprModal />
                )}
                {_get(modal, 'modalType', '') ===
                    modalTypes.skycopCareModal && <SkycopCareModal />}
                {_get(modal, 'modalType', '') === modalTypes.promoGiftModal && (
                    <PromoGiftModal />
                )}
                {!hideNav && <MobileNavigation />}
                <ContentContainer avoidShrink={this.state.avoidShrink}>
                    {propsChildren}
                </ContentContainer>
                {!hideNav && (
                    <MobileStepNav
                        isClaimFormV1Enabled={isClaimV1}
                        hideMobileTrustPilot={hideMobileTrustPilot}
                        nextStepCB={this.nextStepCB}
                        prevStepCB={this.prevStepCB}
                        saveDraft={this.saveDraft}
                        noBack={this.state.noBack}
                        isFinish={this.state.isFinish}
                        nextDisabled={this.state.nextDisabled}
                        buttonText={this.state.buttonText}
                        hideButton={this.state.hideButton}
                        saveDraftEnabled={this.state.saveDraftEnabled}
                        disabledClick={this.state.disabledClick}
                        step={v2_step}
                        handlePressLater={this.handlePressLater}
                        handleReferralClick={this.handleReferralClick}
                    />
                )}
                {renderVirtualCards && <VirtualCardsUpdated />}
                {renderTrustPilot && (
                    <TrustpilotContainer>
                        <TrustBoxContainer type="carousel" />
                    </TrustpilotContainer>
                )}
                {renderSocialShare && (
                    <SocialShareContainer>
                        <SocialPageUpdated />
                    </SocialShareContainer>
                )}
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        trans: state.translations,
        airportArrival: state.v2_airportArrival,
        airportDeparture: state.v2_airportDeparture,
        progressState: state.v2_progressState,
        user: state.user,
        modal: state.toggle_modal,
        uuid: state.claim_UUID,
        claim: state.V2Claim,
        claimDraftProps: state.claimDraftProps,
        isLaterBtnCLicked: state.v2_step_four.isLaterBtnClicked,
        lang: state.language,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            nextStep,
            prevStep,
            getUserData,
            setThemeConfigOptions,
            showModal,
            getClaimDraft,
            logKibana,
            doItLaterBtnClick,
            setReferralModalShareCode,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexV2)
