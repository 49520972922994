import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import t from '../../../shared/translations'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setLanguage } from '../../../shared/actions/index'
import _ from 'lodash'
import { browserHistory } from 'react-router'
import {
    Col,
    Row,
    PageContent,
    PageViewContainer,
} from '../../../shared/components/V2/Layout'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import { ContentCopyIcon } from './'
import { useLeadData } from './hooks'
import { loadScreenOn, loadScreenOff } from '../../../main'
import ReactTooltip from 'react-tooltip'
import {
    setSignaturePageConfig,
    resetSignaturePageConfig,
} from '../../../shared/actions/v2_actions'

const CenteredRow = styled(Row)`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 14px;
`
const DraftRow = styled.div`
    @keyframes appearBox {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    transition: all 0.5s ease;
    animation: appearBox;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    display: flex;
    margin: 12px 0px;
    padding: 13px;
    border-radius: 8px;
    border: solid 1px #cbd3df;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .tooltip {
            position: relative;
            display: inline-block;
        }

        .tooltip .tooltiptext {
            visibility: hidden;
            width: 140px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 150%;
            left: 50%;
            margin-left: -75px;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .tooltip .tooltiptext::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }

        .tooltip:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
        }

        .name-container {
            .text {
                line-height: 1.6;
                font-weight: 600;
                font-size: 15px;
                color: #353d51;
            }
        }

        .share-container {
            svg {
                margin-left: 4px;
            }
            &:hover {
                cursor: pointer;
            }
        }

        .button-container {
            button {
                width: 100%;
                min-width: 140px !important;
            }
        }

        p {
            margin: 0;
        }
    }
`

const StyledH2 = styled.h2`
    color: ${({ signaturePageConfig }) =>
        signaturePageConfig && signaturePageConfig.menuItemColor};
`

const V2ButtonBasedOnConfig = styled(V2Button)`
    background: ${({ signaturePageConfig, ownerSigned }) =>
        !ownerSigned && signaturePageConfig && signaturePageConfig.buttonColor};

    border-radius: ${({ signaturePageConfig }) =>
        signaturePageConfig && signaturePageConfig.buttonBorderRadius};

   };
`

export const LeadList = ({
    t,
    leadId,
    loadScreenOn,
    loadScreenOff,
    setLanguage,
    setSignaturePageConfig,
    signaturePageConfig,
}) => {
    const { data, loading, error } = useLeadData(leadId)
    const passengers = _.get(data, 'passengers', [])
    const owner = passengers.find(passenger => passenger.type === 'usr')
    const ownerDocuments = _.get(owner, 'documents', [])
    const signedDocuments = _.get(owner, 'signed_agreements', [])
    const isOwnerSigned = !!Object.keys(signedDocuments).find(
        key => signedDocuments[key] === 'sig'
    )
    const ownerSigned =
        ownerDocuments.filter(element => element.type === 'sig').length > 0

    useEffect(() => {
        if (loading) {
            loadScreenOn()
        }

        if (data) {
            const locale = _.get(data, 'user[locale]', 'en')
            setLanguage(locale)
            setSignaturePageConfig(_.get(data, 'signature_page_config', null))

            const urlParams = new URLSearchParams(window.location.search)
            if (urlParams.get('preview') === 'true') {
                const previewPageConfigs = {
                    agreementButtonRadius: urlParams.get(
                        'agreementButtonRadius'
                    ),
                    buttonBorderRadius: urlParams.get('buttonBorderRadius'),
                    buttonColor: urlParams.get('buttonColor'),
                    claimButtonColor: urlParams.get('claimButtonColor'),
                    claimButtonRadius: urlParams.get('claimButtonRadius'),
                    claimTextColor: urlParams.get('claimTextColor'),
                    componentsColor: urlParams.get('componentsColor'),
                    menuItemColor: urlParams.get('menuItemColor'),
                    navigationBarColor: urlParams.get('navigationBarColor'),
                    skycopLogo: urlParams.get('skycopLogo'),
                }
                setSignaturePageConfig(previewPageConfigs)
            }
        }

        return () => loadScreenOff()
    }, [loading])

    useEffect(() => {
        if (passengers.length === 1 && !isOwnerSigned) {
            goToSignPage(owner.uid)
        }
    }, [data])

    if (loading) return null
    if (error || !data) return <PageContent>Something went wrong</PageContent>

    return (
        <PageViewContainer>
            <PageContent>
                <CenteredRow>
                    <h1>{t`skycop_lead_list.title`}</h1>
                </CenteredRow>
                <CenteredRow>
                    <p>{`${t`v2_common.label.flight_number`}: ${data.flightNumber ||
                        '-'}, ${t`v2_common.label.departed_from`}: ${data.departureAirport ||
                        '-'}, ${t`v2_common.label.arrived_to`}: ${data.arrivalAirport ||
                        '-'}`}</p>
                </CenteredRow>
                <Row>
                    <p>{t`skycop_lead_list.owner`}</p>
                </Row>
                <DraftRow>
                    <Row className="container">
                        <Col className="name-container">
                            <p className="text" id="happyFace">
                                {[
                                    _.get(data, 'user.person.first_name', ''),
                                    _.get(data, 'user.person.last_name', ''),
                                ]
                                    .filter(Boolean)
                                    .join(' ')}
                            </p>
                        </Col>
                        <Col></Col>
                        <Col className="button-container">
                            <V2ButtonBasedOnConfig
                                signaturePageConfig={signaturePageConfig}
                                ownerSigned={ownerSigned}
                                disabled={ownerSigned}
                                onClick={() => goToSignPage(owner.uid)}
                            >
                                {ownerSigned
                                    ? t`skycop_lead_list.signed`
                                    : t`skycop_lead_list.sign_now`}
                            </V2ButtonBasedOnConfig>
                        </Col>
                    </Row>
                </DraftRow>
                <Row>{t`skycop_lead_list.passengers_list`}</Row>
                {passengers
                    .filter(p => p.type !== 'usr')
                    .map((passenger, index) => (
                        <DraftRow key={index}>
                            <Row className="container">
                                <Col className="name-container">
                                    <p className="text">
                                        {[
                                            _.get(passenger, 'first_name', ''),
                                            _.get(passenger, 'last_name', ''),
                                        ]
                                            .filter(Boolean)
                                            .join(' ')}
                                    </p>
                                </Col>
                                <Col className="share-container">
                                    {!isSigned(passenger) && (
                                        <Row
                                            data-tip
                                            data-event="click"
                                            data-for={`copyToClipboard-${index}`}
                                            onClick={() =>
                                                copyToClipboard(
                                                    _.get(passenger, 'uid', '')
                                                )
                                            }
                                            onMouseOut={outFunc}
                                        >
                                            <p>{t`skycop_lead_list.copy`}</p>

                                            <ContentCopyIcon />
                                        </Row>
                                    )}
                                    <ReactTooltip
                                        event="no-event"
                                        place="bottom"
                                        id={`copyToClipboard-${index}`}
                                        globalEventOff={'click'}
                                        afterShow={() =>
                                            copyToClipboard(
                                                _.get(passenger, 'uid', '')
                                            )
                                        }
                                    >
                                        <span>
                                            {t`skycop_lead_list.copied.tooltip`}
                                        </span>
                                    </ReactTooltip>
                                </Col>
                                <Col className="button-container">
                                    <V2ButtonBasedOnConfig
                                        signaturePageConfig={
                                            signaturePageConfig
                                        }
                                        ownerSigned={isSigned(passenger)}
                                        disabled={isSigned(passenger)}
                                        onClick={e => {
                                            e.stopPropagation()
                                            goToSignPage(passenger.uid)
                                        }}
                                    >
                                        {isSigned(passenger)
                                            ? t`skycop_lead_list.signed`
                                            : t`skycop_lead_list.sign_now`}
                                    </V2ButtonBasedOnConfig>
                                </Col>
                            </Row>
                        </DraftRow>
                    ))}
                <CenteredRow>
                    <StyledH2
                        signaturePageConfig={signaturePageConfig}
                    >{t`skycop_lead_list.submitted`}</StyledH2>
                </CenteredRow>
                <CenteredRow>
                    <p>{t`skycop_lead_list.submitted_description`}</p>
                </CenteredRow>
            </PageContent>
        </PageViewContainer>
    )

    function isSigned(passenger) {
        const documents = _.get(passenger, 'documents', [])

        return documents.filter(element => element.type === 'sig').length > 0
    }

    function copyToClipboard(id) {
        const baseUrl = _.get(data, 'document_sign_url', '')
        if (!baseUrl || !id) {
            return
        }

        const url = `${window.location.host}/lead/agreement?leadId=${leadId}&passengerId=${id}`
        navigator.clipboard.writeText(url)
    }

    function goToSignPage(id) {
        if (!leadId || !id) return

        browserHistory.push(
            `/lead/agreement?leadId=${leadId}&passengerId=${id}`
        )
    }

    function outFunc() {
        // var tooltip = document.getElementById('myTooltip')
        // tooltip.innerHTML = 'Copy to clipboard'
    }
}

class Leeds extends Component {
    componentDidCatch(error, errorInfo) {
        console.warn(error, errorInfo)
    }

    componentWillUnmount() {
        const { resetSignaturePageConfig } = this.props
        resetSignaturePageConfig()
        this.setState({})
    }

    render() {
        const { trans, setSignaturePageConfig, signatureConfigs } = this.props
        const urlParams = new URLSearchParams(window.location.search)
        const leadId = urlParams.get('leadId')

        return trans && leadId ? (
            <LeadList
                t={t}
                leadId={leadId}
                loadScreenOn={loadScreenOn}
                loadScreenOff={loadScreenOff}
                setLanguage={this.props.setLanguage}
                setSignaturePageConfig={setSignaturePageConfig}
                signaturePageConfig={signatureConfigs}
            />
        ) : null
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { setLanguage, setSignaturePageConfig, resetSignaturePageConfig },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        modal: state.toggle_modal,
        signatureConfigs: state.signaturePageConfig,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leeds)
