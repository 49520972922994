import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case V2_ACTIONS.CLAIM_UUID:
            return payload;
        case V2_ACTIONS.RESET_CLAIM_UUID:
            return null;
        default:
    }
    return state;
}