import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { applyTranslationElement, isMobileDevice } from '../../../main'
import t from '../../translations'
import { V2Button2 } from '../V2/V2Buttons'
import { mobile } from '../helpers/styled_queries'

const ContentWrapper = styled.div`
    width: 50%;
    align-self: center;

    padding: ${({ padding }) => (padding ? padding : '')};

    ${mobile`
   width: 100%;
`}
`

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
`

const SurveyTitle = styled.h2`
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    text-align: left;
    color: #000000;
    margin-bottom: 32px;

    ${mobile`
    line-height: 42.24px;
    text-align: left;
    font-size: 32px;
    margin-bottom: 24px;
 `}
`

const SurveyTitlePart = styled(SurveyTitle)`
    color: #149954;
    display: inline;
`

const SurveyDescription = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #000000;

    margin-bottom: 24px;
`
const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 16px;
`
class SkycopCareRedirect extends React.Component {
    render() {
        const { trans, onRedirectClick } = this.props

        if (!trans) {
            return null
        }
        return (
            <>
                {isMobileDevice() && (
                    <ContentWrapper>
                        <ImageContainer>
                            <img
                                width="100%"
                                src="/images/mobileSurvey.jpg"
                                alt="survey"
                            />
                        </ImageContainer>
                    </ContentWrapper>
                )}
                <ContentWrapper
                    padding={
                        isMobileDevice() ? '20px 20px' : '32px 32px 32px 60px'
                    }
                >
                    <SurveyTitle>
                        {applyTranslationElement(
                            t`survey.modal.confirmation.title`,
                            '%part%',
                            <SurveyTitlePart>{t`survey.modal.confirmation.title.part`}</SurveyTitlePart>
                        )}
                    </SurveyTitle>
                    <SurveyDescription>
                        {t`survey.modal.confirmation.description`}
                    </SurveyDescription>
                    <ButtonContainer>
                        <V2Button2 onClick={() => onRedirectClick()}>
                            {t`survey.modal.confirmation.activate.skycop.care.btn`}
                        </V2Button2>
                    </ButtonContainer>
                </ContentWrapper>
                {!isMobileDevice() && (
                    <ContentWrapper padding="40px 0px 136px 72px">
                        <ImageContainer>
                            <img
                                width="100%"
                                src="/images/desktopSurvey.jpg"
                                alt="survey"
                            />
                        </ImageContainer>
                    </ContentWrapper>
                )}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(SkycopCareRedirect)
