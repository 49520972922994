/* global Raven */
import {getApiClient} from '../../main'

export const logLevel = {
    debug: 'DEBUG',
    info: 'INFO',
    notice: 'NOTICE',
    warning: 'WARNING',
    error: 'ERROR',
    critical: 'CRITICAL',
    alert: 'ALERT',
    emergency: 'EMERGENCY'
}

export function logError(
    message,
    endpoint,
    level = 'critical',
    exception = ''
) {
    const apiClient = getApiClient()

    const fd = new FormData()
    fd.append('message', message)
    fd.append('level', level)
    fd.append('sEndpoint', endpoint)
    fd.append('sException', exception)

    return apiClient
        .post(`/api/log`, fd)
        .then(() => {
            return true
        })
        .catch(error => {
            Raven.captureException('LOG KIBANA ERROR: ' + JSON.stringify(error))
            return false
        })
}

export async function logRaw(level, message, payload) {
    const apiClient = getApiClient();
    const url = `/api/log-raw`;
    let fd = new FormData();
    fd.append("level", message);
    fd.append("message", message);
    fd.append("payload", JSON.stringify(payload));
    try {
        apiClient.post(url, fd);
    } catch (e) {}
}
