import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case V2_ACTIONS.SET_DETAILS:
            return payload;
        default:
    }
    return state;
}