import { AUTH_USER, AUTH_USER_EXTERNAL, UNAUTH_USER } from '../actions/actionTypes';

const INITIAL_STATE = { authenticated: false, external: null }

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER:
            return { ...state, authenticated: true, external: false };
        case AUTH_USER_EXTERNAL:
            return { ...state, authenticated: true, external: true };
        case UNAUTH_USER:
            return { ...state, authenticated: false, external: null };
        default:
    }
    return state;
}