import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { applyTranslationElement, isMobileDevice } from '../../../main'
import t from '../../translations'
import { RadioButton } from '../V2/RadioButtons'
import { Row } from '../V2/Layout'
import { V2Button2 } from '../V2/V2Buttons'
import { mobile } from '../helpers/styled_queries'
import { QUESTION_TYPE } from './AboutUsSurveyModal'

const StyledRow = styled(Row)`
    .radio-outter {
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        width: 100%;
    }

    .is-selected {
        label {
            background-color: rgba(50, 184, 106, 0.05);
        }

        .radio-outter {
            border-color: #43c67f;
        }
    }

    span {
        justify-content: start;
        width: 100%;
    }
`

const ContentWrapper = styled.div`
    width: 50%;
    align-self: center;

    padding: ${({ padding }) => (padding ? padding : '')};

    ${mobile`
   width: 100%;
`}
`

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
`

const SurveyTitle = styled.h2`
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    text-align: left;
    color: #000000;

    ${mobile`
    line-height: 42.24px;
    text-align: left;
    font-size: 32px;
 `}
`

const SurveyTitlePart = styled(SurveyTitle)`
    color: #149954;
    display: inline;
`

const SurveyDescription = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #000000;
`

const SurveyDescriptionPart = styled(SurveyDescription)`
    font-weight: 600;
    display: inline;
`

const Textarea = styled.textarea`
    margin-top: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    min-height: 48px;
    height: 48px;
    border-radius: 6px;
    border: solid 1px #bcc5d3;
    padding: 10px 16px 10px 16px;
    outline: 0 none;
    margin: unset;

    &:focus {
        border: 1px solid #36b56a;
        outline: 0 none;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #b4bdca;
    }

    .radio-outter {
        height: unset;
    }
`

const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 16px;
`
class SurveyQuestions extends React.Component {
    render() {
        const {
            trans,
            changeRadioValue,
            handleCommentChange,
            isButtonDisabled,
            handleSurveySubmit,
            surveyQuestions,
        } = this.props

        if (!trans || !Object.keys(surveyQuestions).length) {
            return null
        }
        return (
            <>
                {isMobileDevice() && (
                    <ContentWrapper>
                        <ImageContainer>
                            <img
                                width="100%"
                                src="/images/mobileSurvey.jpg"
                                alt="survey"
                            />
                        </ImageContainer>
                    </ContentWrapper>
                )}
                <ContentWrapper
                    padding={
                        isMobileDevice() ? '16px 16px' : '32px 50px 32px 60px'
                    }
                >
                    <SurveyTitle>
                        {applyTranslationElement(
                            t`survey.modal.title`,
                            '%part%',
                            <SurveyTitlePart>{t`survey.modal.title.part`}</SurveyTitlePart>
                        )}
                    </SurveyTitle>
                    <SurveyDescription>
                        {applyTranslationElement(
                            t`survey.modal.description`,
                            '%part%',
                            <SurveyDescriptionPart>{t`survey.modal.description.part`}</SurveyDescriptionPart>
                        )}
                    </SurveyDescription>
                    {Object.keys(surveyQuestions).map(key =>
                        surveyQuestions[key].type === QUESTION_TYPE.Radio ? (
                            <StyledRow style={{ marginTop: '5px' }}>
                                <RadioButton
                                    padding="5px 18px"
                                    height="unset"
                                    checked={surveyQuestions[key].isChecked}
                                    onChange={() => changeRadioValue(key)}
                                    name={key}
                                    shrinkLabel={true}
                                    marginLabelLeft="16"
                                >
                                    {surveyQuestions[key].label}
                                </RadioButton>
                            </StyledRow>
                        ) : (
                            <StyledRow style={{ marginTop: '5px' }}>
                                <RadioButton
                                    checked={surveyQuestions[key].isChecked}
                                    onChange={() => changeRadioValue(key)}
                                    name={key}
                                    height="unset"
                                    padding="5px 18px"
                                    marginLabelLeft="16"
                                >
                                    <Textarea
                                        placeholder={
                                            surveyQuestions[key].placeholder
                                        }
                                        value={surveyQuestions[key].value}
                                        name={key}
                                        onChange={e =>
                                            handleCommentChange(
                                                e.target.value,
                                                key
                                            )
                                        }
                                    />
                                </RadioButton>
                            </StyledRow>
                        )
                    )}
                    <ButtonContainer>
                        <V2Button2
                            disabled={isButtonDisabled()}
                            onClick={() => handleSurveySubmit()}
                        >
                            {t`survey.modal.confirm.survey.btn`}
                        </V2Button2>
                    </ButtonContainer>
                </ContentWrapper>
                {!isMobileDevice() && (
                    <ContentWrapper>
                        <ImageContainer>
                            <img
                                width="100%"
                                src="/images/desktopSurvey.jpg"
                                alt="survey"
                            />
                        </ImageContainer>
                    </ContentWrapper>
                )}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps)(SurveyQuestions)
