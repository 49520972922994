import { STOPS } from '../actions/actionTypes';

const INITIAL_STATE = { stopsCount: 0 }

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case STOPS:
            return { state, stopsCount: action.payload };
        default:
    }
    return state;
}