export const fireGtagEvent = ({ event }, once) => {
  
    if (window && window.dataLayer) {
      const alreadyRegisteredEvent = !!window.dataLayer.find(e => e.event === event)
      if (once && alreadyRegisteredEvent) return 

        window.dataLayer.push({
            event,
        })
    }
}
