import React, { Component } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import { Container } from '../../V2'
import SuccessBox from '../../../views/V2/components/SuccessBox'
import { PageContent, Row, Col } from '../../../shared/components/V2/Layout'
import { PageTitle } from '../../../shared/components/V2/TextElements'
import t from '../../../shared/translations'
import { bindActionCreators } from 'redux'
import VirtualCards from '../../V2/components/virtualCards/VirtualCards'

class SkycopCareSuccess extends Component {
    componentDidMount() {
        if (window && window.dataLayer) {
            const item = sessionStorage.getItem('sc')

            if (item) {
                const items = JSON.parse(item)

                window.dataLayer.push({
                    event: 'purchase',
                    transaction_id: Date.now(),
                    value: _get(items, '[0].price', 0),
                    tax: '0.00',
                    shipping: 0,
                    currency: 'EUR',
                    ecommerce: {
                        items,
                    },
                })
            }

            window.dataLayer.push({
                event: 'purchase',
                transaction_id: Date.now(),
            })
        }
    }

    render() {
        const { isAuthenticated } = this.props

        return (
            <Container>
                <Row style={{ 'margin-bottom': '44px' }}>
                    <PageContent paddingTop={'0px'}>
                        <PageTitle title={t`sc.title.success_page_title`} />
                        <Row>
                            <Col>
                                <SuccessBox title={t`sc.success_order`} />
                            </Col>
                        </Row>
                    </PageContent>
                </Row>
                {isAuthenticated && (
                    <Row>
                        <VirtualCards />
                    </Row>
                )}
            </Container>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        isAuthenticated: state.auth.authenticated,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkycopCareSuccess)
