import React from "react";
import styled from "styled-components";

const StyledSlider = styled.input`
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%; 
        background: ${({ theme }) => theme.colors.primaryColor};
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.primaryColor};
        cursor: pointer;
    }
    
    &::-moz-range-track {
        background: #d3d3d3;
    }
    
    &::-moz-focus-outer { 
        border: 0; 
    }
`;


const Slider = (props) => (
    <StyledSlider type="range" min="1" max="3" {...props}/>
);


export default Slider;