import React from 'react'
import styled from 'styled-components'
import { CheckMarkIcon } from './icons'

const Container = styled.div`
    display: flex;
    align-items: center;

    .text-container {
        margin-left: 8px;
        display: flex;
        align-items: center;

        p {
            margin-top: 20px;
            font-size: 13px;
            color: #043d5d;
            margin: 0;
        }
    }
`

export const ListItem = ({ bubbleColor, text, number }) => {
    return (
        <Container bubbleColor={bubbleColor}>
            <div className="icon">
                <CheckMarkIcon />
            </div>
            <div className="text-container">
                <p>{text}</p>
            </div>
        </Container>
    )
}
