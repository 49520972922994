import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import t from '../../../shared/translations'
import store from '../../../shared/services/store'
import {
    airlineResults,
    handleMobileInputFocus,
    handleMobileInputBlur,
} from '../../../main'
import NewSelectField from '../../../shared/components/V2/NewSelectField'
import { ClearIconV2 } from '../../../shared/components/svgIcons'
var debounce = require('es6-promise-debounce')

const AirlineInput = ({
    autoload = true,
    className = 'show-disabled',
    ignoreAccents = false,
    clearable = true,
    cache = false,
    callback = () => null,
    clear = () => null,
    isAsync = true,
    hasIcon = false,
    required = false,
    hideArrow = true,
    searchPromptText = 'v2_common.no_results',
    label,
    name,
    value,
    defaultValue,
    onChange,
    onInputChange = () => null,
    errorText,
    refIndex,
    id,
    onBlur = () => null,
    setRef = () => null,
    borderRadius,
    stickyOptionsList,
    stickyOptionsListTopPadding,
    isFocusHighligh,
}) => {
    return (
        <NewSelectField
            isFocusHighligh={isFocusHighligh}
            stickyOptionsListTopPadding={stickyOptionsListTopPadding}
            stickyOptionsList={stickyOptionsList}
            borderRadius={borderRadius}
            label={label}
            errorText={errorText}
            hasIcon={!!hasIcon}
            required={required}
            hideArrow={hideArrow}
            data-hj-whitelist=""
            id={id}
            selectProps={{
                'data-hj-whitelist': '',
                inputProps: {
                    'data-hj-whitelist': '',
                },
                name,
                value,
                defaultValue,
                autoload,
                className,
                ignoreAccents,
                clearable,
                cache,
                isAsync: !!isAsync,
                onBlurResetsInput: true,
                loadingPlaceholder: '',
                searchPromptText: t`${searchPromptText}`,
                placeholder: t`v2_common.placeholder.airline`,
                onInputChange,
                loadOptions: debounce(e => {
                    return airlineResults(
                        e,
                        name,
                        store.getState().v2_UsedAirlines
                    )
                }, 200),
                arrowRenderer: () => null,
                clearRenderer: () => {
                    if (!value) {
                        return null
                    }
                    return (
                        <span
                            className="clear-item"
                            onClick={() => clear()}
                            onTouchEnd={() => clear()}
                        >
                            <ClearIconV2 fill="#717171" />
                        </span>
                    )
                },
                onFocus: e => {
                    e.preventDefault()
                    handleMobileInputFocus()
                },
                onBlur: e => {
                    e.preventDefault()
                    onBlur(e, callback)
                    handleMobileInputBlur()
                },
                onClick: e => e.preventDefault(),
                filterOptions: (options, filter, current_values) =>
                    filter.length < 2 ? [] : options,
                onChange: e => {
                    onChange(e, callback)
                    const elem = _get(document, 'activeElement', null)

                    if (elem && typeof elem.blur === 'function') {
                        elem.blur()
                    }
                },
                ref: ref => setRef(name, ref, refIndex),
            }}
        />
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AirlineInput)
