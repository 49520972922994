import { V2_ACTIONS } from '../../actions/actionTypes';
import _get from 'lodash/get';

export default function (state = { firstRow: [], secondRow: [] }, action) {
    switch (action.type) {
        case V2_ACTIONS.REASONS_BY_AIRLINE:
            const firstRowReasons = [
                {
                    value: "rwi",
                    titleKey: "bno",
                },
                {
                    value: "rdr",
                    titleKey: "rdr",
                },
            ];
            const { payload } = action;
            const items =_get(payload, 'details.items', {});
            const mappedPayload = Object.keys(items).filter((key) => !["rwi", "rdr"].includes(key));
            const secondRowReasons = (mappedPayload || []).map((item) => ({
                value: item,
                titleKey: item,
            }));
            return {
                ...state,
                firstRow: firstRowReasons,
                secondRow: secondRowReasons,
            };
        default:
    }
    return state;
};
