import { V2_ACTIONS } from '../../actions/actionTypes';
import { arrayUnique } from '../../../render';

export default function (state = [], action, reset) {
    switch (action.type) {
        case V2_ACTIONS.USED_AIRPORTS:
            if (!reset) {
                return arrayUnique([
                    ...state,
                    ...action.payload,
                ]);
            } else {
                return arrayUnique((action.payload || []));
            }
        default:
    }
    return state;
}