import { browserHistory } from 'react-router';
import _get from "lodash/get";
import {
    loadScreenOff,
    loadScreenOn,
    getConfigParamValue,
    getStorage,
    getApiClient,
    getStatus,
} from '../../main';
import { getFieldErrors, resetUserLogin, getAirlines, getAirports } from "./index";
import moment from 'moment';
import { paths, oopsUrl } from '../constants';
import { 
    CLAIM,
    SIGNATURE,
    PAYMENT_PLAN,
    CLAIMABLE_AMOUNT,
    SELECTED_CLAIM,
    CLAIM_HISTORY,
    AUTH_ERROR,
    DATEFORMAT,
    CLAIM_ERRORS,
    CLAIM_ERRORS_CLEAR,
    CLAIM_ERRORS_CLEAR_FLIGHTS,
    CLAIM_ERRORS_CLEAR_USER,
    SET_SUBMITED_CLAIM_DATA,
    CLEAR_SUBMITED_CLAIM_DATA,
} from './actionTypes';
import store from "../services/store";

/* global Raven */

export function saveUserClaim(claim) {
    return {
        type: CLAIM,
        payload: claim
    }
}

export function resetSingleClaim() {
    return function (dispatch) {
        dispatch({
            type: SELECTED_CLAIM,
            payload: null
        })
    }
}

export function saveSignature(signature) {
    return {
        type: SIGNATURE,
        payload: signature
    }
}

export function savePaymentPlan(paymentPlan) {
    return {
        type: PAYMENT_PLAN,
        payload: paymentPlan
    }
}

export function claimableAmount(data) {
    const url = `/api/claim/claimable-amount `;
    let apiClient = getApiClient();

    return function (dispatch) {
        apiClient.post(url, data)
            .then((response) => {
                dispatch({
                    type: CLAIMABLE_AMOUNT,
                    payload: response.data.payload
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

export function setClaimableAmount(amt) {
    return function (dispatch) {
        dispatch({
            type: CLAIMABLE_AMOUNT,
            payload: amt
        });
    }
}

function convertDate(date) {
    if (date) {
        return moment(date).format(DATEFORMAT);
    }
}

export function checkIfClaimable(claim, lang) {
    const isScoringEnabled = getConfigParamValue('isScoringEnabled');

    if (isScoringEnabled === '0') {
        return function () {
            const promise = new Promise((resolve) => resolve()).then(() => browserHistory.push(paths.FLIGHT_INFO));
            return promise;
        }
    } else {
        const apiClient = getApiClient();
        const urlAirline = '/api/airlines/0/0';
        const url = `/api/claim/claimable`;
        let data = new FormData();

        data.append('claimable_array[airportArrival]', claim.arrivedTo);
        data.append('claimable_array[airportDeparture]', claim.departedFrom);

        if(claim.connectingFlights !== "direct") {
            if (claim.firstFlightTo) {
                data.append('claimable_array[connectingFlights][0][airportDeparture]', claim.firstFlightFrom);
                data.append('claimable_array[connectingFlights][0][airportArrival]', claim.firstFlightTo);
            }
            if (claim.secondFlightTo) {
                data.append('claimable_array[connectingFlights][1][airportDeparture]', claim.secondFlightFrom);
                data.append('claimable_array[connectingFlights][1][airportArrival]', claim.secondFlightTo);
            }
            if (claim.thirdFlightTo) {
                data.append('claimable_array[connectingFlights][2][airportDeparture]', claim.thirdFlightFrom);
                data.append('claimable_array[connectingFlights][2][airportArrival]', claim.thirdFlightTo);
            }
            if (claim.fourthFlightTo) {
                data.append('claimable_array[connectingFlights][3][airportDeparture]', claim.fourthFlightFrom);
                data.append('claimable_array[connectingFlights][3][airportArrival]', claim.fourthFlightTo);
            }
            if (claim.fifthFlightTo) {
                data.append('claimable_array[connectingFlights][4][airportDeparture]', claim.fifthFlightFrom);
                data.append('claimable_array[connectingFlights][4][airportArrival]', claim.fifthFlightTo);
            }
        }

        if (claim.reason1) {
            data.append('claimable_array[reasons][0][parentKey]', 'reasons');
            data.append('claimable_array[reasons][0][value]', claim.reason1);
        }
        if (claim.reason2 ) {
            data.append('claimable_array[reasons][1][parentKey]', claim.reason1);
            data.append('claimable_array[reasons][1][value]', claim.reason2);
        }
        if (claim.reason3) {
            data.append('claimable_array[reasons][2][parentKey]', claim.reason2);
            data.append('claimable_array[reasons][2][value]', claim.reason3);
        }
        if (claim.reason4) {
            data.append('claimable_array[reasons][3][parentKey]', claim.reason3);
            data.append('claimable_array[reasons][3][value]', claim.reason4);
        }
        data.append('claimable_array[flightDate]', convertDate(claim.failedFlightDate));

        return function (dispatch) {
            return apiClient.get(urlAirline, {
                params: {
                    'airline_api_filter[iata]': claim.failedFlightNumberLetters
                }
            })
                .then(response => {
                    const airlines = _get(response, "data.payload", []);
                    if (airlines.length > 0) {
                        const iata = claim.failedFlightNumberLetters;
                        const filteredAirlines = airlines.filter((airline) => airline.iata === iata);
                        if (filteredAirlines[0]) {
                            data.append('claimable_array[airline]', airlines[0].id);
                        }
                    }
                    return apiClient.post(url, data, { headers: { "Accept-language": lang } })
                        .then((resp) => {
                            const claimable = _get(resp, "data.payload.claimable", false);
                            if (claimable) {
                                browserHistory.push(paths.FLIGHT_INFO);
                            } else {
                                const airlineError = _get(resp, "data.payload.reason.data.airline", null);
                                if (airlineError) {
                                    dispatch({
                                        type: AUTH_ERROR,
                                        payload: "airline.invalid",
                                    });
                                    browserHistory.push(paths.SORRY);
                                } else {
                                    const errorMessage = getFieldErrors(_get(resp, "data.payload.reason.data", {})).slice(0, 1);
                                    dispatch({
                                        type: AUTH_ERROR,
                                        payload: errorMessage
                                    });
                                    browserHistory.push(paths.SORRY);
                                }
                            }
                        
                        })
                        .catch((error) => {
                            const statusCode = getStatus(error);

                            if (statusCode >= 500) {
                                Raven.captureException("CHECK IF CLAIMABLE POST ERROR. MINIMAL REDIRECT: " + JSON.stringify(error));
                                const isEnabledOopsRedirect = getConfigParamValue('oopsRedirect');
                                
                                if (oopsUrl && (isEnabledOopsRedirect !== "0")) {
                                    setTimeout(() => {
                                        window.location.href = oopsUrl;
                                    }, 1000);
                                }
                                return;
                            } else {
                                Raven.captureException("CHECK IF CLAIMABLE POST ERROR: " + JSON.stringify(error));
                                browserHistory.push(paths.SORRY);
                            }
                        })
                })
                .catch(() => {
                    apiClient.post(url, data)
                        .then((response) => {
                            if (response.data.payload.claimable) {
                                browserHistory.push(paths.FLIGHT_INFO);
                            } else {
                                browserHistory.push(paths.SORRY);
                            }
                        })
                        .catch((error) => {
                            const statusCode = getStatus(error);
                            const errorMessage = _get(error, "data.payload.reason.data", []);

                            if (statusCode >= 500) {
                                Raven.captureException("CHECK IF CLAIMABLE GET AIRLINE ERROR. MINIMAL REDIRECT: " + JSON.stringify(error));
                                const isEnabledOopsRedirect = getConfigParamValue('oopsRedirect');

                                if (oopsUrl && (isEnabledOopsRedirect !== "0")) {
                                    setTimeout(() => {
                                        window.location.href = oopsUrl;
                                    }, 1000);
                                }
                                return;
                            } else {
                                dispatch({
                                    type: AUTH_ERROR,
                                    payload: errorMessage
                                });
                                Raven.captureException("CHECK IF CLAIMABLE GET AIRLINE ERROR: " + JSON.stringify(error));
                                browserHistory.push(paths.SORRY);
                            }
                        })
                    })
        }
    }
}

export const getBufferedClaimsData = (draft) => (dispatch)=> {
    const assets = store.getState().idAssets;

    const getData = (id, action) => {
        if (assets[id]){
            return Promise.resolve()
        } else {
            return dispatch(action(false, false, id))
        }
    }

    return new Promise((resolve, reject) => {
        const airportArrivalId = draft.airportArrival;
        const airportDepartureId = draft.airportDeparture;
        let airlineId;
        if (_get(draft, "flights[0].airline", "")) {
            airlineId = _get(draft, "flights[0].airline", "");
        }
        
    getData(airportArrivalId, getAirports)
        .then(() => {
            getData(airportDepartureId, getAirports)
                .then(() => {
                    if (airlineId) {
                        getData(airlineId, getAirlines)
                            .then(() => resolve())
                            .catch(err => reject(err))
                    }
                }).catch(err => reject(err))
        }).catch(err => reject(err))
    })
}

export const getBufferedClaims = () => (dispatch) => {
    const url = `api/account/buffered-claims`;
    const authString = `Bearer ${getStorage('token')}`;
    const apiClient = getApiClient();

    return apiClient.get(url, { headers: { Authorization: authString } })
        .then((res) => {
            const { data } = res;

            data.map((item) => {
                const { draft } = item;

                return dispatch(getBufferedClaimsData(draft))
            })

            dispatch({
                type: "SET_BUFFERED_CLAIMS",
                payload: res.data,
            })
            return res;
        })
        .catch((err) => console.log(err))
}

export function getClaimHistory(id) {
    const url = `api/account/claim-history/${id}`;
    const authString = `Bearer ${getStorage('token')}`;
    const apiClient = getApiClient();
    loadScreenOn();

    return function (dispatch) {
        return apiClient.get(url, { headers: { Authorization: authString } })
            .then(response => {
                dispatch({
                    type: CLAIM_HISTORY,
                    payload: response.data,
                });
                loadScreenOff();
            })
            .catch(error => {
                const statusCode = getStatus(error);
                if (statusCode && statusCode === 401) {
                    resetUserLogin(dispatch);
                } else {
                    Raven.setUserContext({
                        claimId: id,
                    });
                    Raven.captureException("VIEW USER CLAIM HISTORY ERROR: " + JSON.stringify(error));
                    browserHistory.push("/claims");
                }
                loadScreenOff();
            });
    }
}

export function resetClaimHistory() {
    return function (dispatch) {
        dispatch({
            type: CLAIM_HISTORY,
            payload: null,
        });
    }
}

export function setSubmitedClaimData(data) {
    return function (dispatch) {
        dispatch({
            type: SET_SUBMITED_CLAIM_DATA,
            payload: data,
        })
    }
}

export function clearSubmitedClaimData() {
    return function (dispatch) {
        dispatch({
            type: CLEAR_SUBMITED_CLAIM_DATA,
        })
    }
}

export function setClaimErrors(errors) {
    return function(dispatch) {
        dispatch({
            type: CLAIM_ERRORS,
            payload: errors,
        })
    }
}

export function clearClaimErrors() {
    return function(dispatch) {
        dispatch({
            type: CLAIM_ERRORS_CLEAR,
        })
    }
}

export function clearClaimErrorsFlights() {
    return function(dispatch) {
        dispatch({
            type: CLAIM_ERRORS_CLEAR_FLIGHTS,
        })
    }
}

export function clearClaimErrorsUser() {
    return function(dispatch) {
        dispatch({
            type: CLAIM_ERRORS_CLEAR_USER,
        })
    }
}
