import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import { Row, Col } from '../../../shared/components/V2/Layout'
import { SkycopCarePlanRadio } from '../../../shared/components/V2/RadioButtons'
import {
    desktop,
    mobile,
} from '../../../shared/components/helpers/styled_queries'

const StyledRow = styled(Row)`
    display: flex;
    flex-wrap: wrap;
`

const PlanPrice = styled.div`
    color: #149954;
    font-family: Noto Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42.24px;
`

const PlanPerItem = styled.div`
    color: ${({ checked }) => (checked ? '#333333' : '#717171')};
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    text-align: left;
`

const PlanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`

const FooterBadge = styled.div`
    background: ${({ checked }) => (checked ? '#149954' : '#F5F5F5')};
    width: fit-content;
    padding: 6px 16px;
    border-radius: 24px;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 12px;

    span:first-child {
        margin-right: 4px;
    }

    .type {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 700;
        line-height: 22.4px;

        color: ${({ checked }) => (checked ? '#fff' : '#333333')};
        padding-left: 0;
        display: block;
    }

    .description-type {
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;

        color: ${({ checked }) => (checked ? '#fff' : '#333333')};
        padding-left: 0;
        display: block;
    }
`

const StyledOptionsList = styled(Col)`
    ${desktop`
    display: flex;
    height: auto;
    margin-bottom: 24px;
    `}
    ${mobile`
    &:last-child {
    margin-bottom: 8px;
    }
        `}
`

export const PersonalProducts = ({
    products,
    currPlan,
    selectedPlan,
    calculatePrice,
    promoType,
    onChangePlan,
    t,
}) => (
    <StyledRow>
        {products
            .filter(p => !p.family)
            .map(plan => (
                <StyledOptionsList sm={12} md={12} lg={6}>
                    <SkycopCarePlanRadio
                        name={plan.id}
                        onChange={() => onChangePlan(plan.id)}
                        checked={selectedPlan === plan.id}
                        content={
                            <PlanWrapper>
                                <PlanPrice>
                                    &euro;
                                    {(
                                        calculatePrice(
                                            plan.price / 100,
                                            _get(
                                                currPlan,
                                                'discount.value',
                                                0
                                            ) / 100
                                        ) / plan.periodInMonths
                                    ).toFixed(2)}
                                </PlanPrice>
                                <PlanPerItem checked={selectedPlan === plan.id}>
                                    {t`sc.subtitle.per_person`}
                                </PlanPerItem>
                            </PlanWrapper>
                        }
                        footer={
                            <FooterBadge checked={selectedPlan === plan.id}>
                                <span className="description-type">
                                    {t`sc.title.plan.${plan.name.replace(
                                        / /g,
                                        '-'
                                    )}`}
                                </span>
                                <span className="type">
                                    &euro;
                                    {calculatePrice(
                                        plan.price / 100,
                                        _get(currPlan, 'discount.value', 0) /
                                            100
                                    )}
                                </span>
                            </FooterBadge>
                        }
                    />
                </StyledOptionsList>
            ))}
    </StyledRow>
)
