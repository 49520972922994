import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    flights: null,
    reasons: null,
    alternativeFlight: false,
    acceptedAlternative: false,

}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_DISRUPTED_FLIGHTS:
            return {...state, flights: action.payload};
        case V2_ACTIONS.SET_REASONS:
            return {...state, reasons: action.payload};
        case V2_ACTIONS.SET_ALTERNATIVE_FLIGHT:
            return {...state, alternativeFlight: true};
        case V2_ACTIONS.SET_ACCEPTED_ALTERNATIVE:
            return {...state, acceptedAlternative: true};
        case V2_ACTIONS.CLEAR_DISRUPTED_FLIGHTS:
            return {...state, flights: null};
        case V2_ACTIONS.CLEAR_REASONS:
            return {...state, reasons: null};
        case V2_ACTIONS.CLEAR_ALTERNATIVE_FLIGHT:
            return {...state, alternativeFlight: false};
        case V2_ACTIONS.CLEAR_ACCEPTED_ALTERNATIVE:
            return {...state, acceptedAlternative: false};
        default:
    }
    return state;
}