import { SMTP_PASSENGER_VALIDATION } from '../actions/actionTypes';

// Should only save if email is Invalid
export default function (state = {}, action) {
    switch (action.type) {
        case SMTP_PASSENGER_VALIDATION:
            return action.payload;
        default:
    }
    return state;
}