import React from 'react'
import styled from 'styled-components'

const NavbarInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1 0 72px;

    @media (min-width: 990px) {
        max-width: 119rem;
    }
`

const BrandedContainer = styled.div`
    width: 100%;
    background: #eaeeee;
`

const BrandedTerms = styled(NavbarInner)`
    flex: 1;
    margin: auto;
    padding: 7px 37px;
    position: relative;
    justify-content: flex-start;
    color: #353d51;

    .info-text {
        font-family: sans-serif;
        font-size: 13px;

        span,
        a {
            font-weight: bold;
            text-decoration: none;
        }

        a:hover {
            filter: brightness(120%);
        }
    }
`

const InfoIcon = styled.div`
    position: absolute;
    left: 10px;
    width: 21px;
    height: 21px;
    background: url('/images/info-gray.svg');
    background-size: contain;
    background-repeat: no-repeat;
`

const CloseIcon = styled.div`
    position: absolute;
    right: 10px;
    height: 21px;
    width: 21px;
    opacity: 0.7;
    background: #dadddd;
    border-radius: 50%;
    color: #3f4659;
    padding: 4px;
    line-height: 10px;
    cursor: pointer;
    text-align: center;
    user-select: none;

    &:hover {
        filter: brightness(120%);
    }
`

export const GdrpMessage = ({ onClose, html }) => (
    <BrandedContainer>
        <BrandedTerms className="branded-container">
            <InfoIcon />
            <span
                className="info-text"
                dangerouslySetInnerHTML={{ __html: html }}
            />
            <CloseIcon onClick={onClose}>x</CloseIcon>
        </BrandedTerms>
    </BrandedContainer>
)
