import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import t from "../../../shared/translations";
import { showModal, setNavStep } from "../../../shared/actions/v2_actions";
import { HappyIcon } from "../../../shared/components/V2/V2Icons";
import { V2Button } from "../../../shared/components/V2/V2Buttons";
import { paths, modalTypes } from "../../../shared/constants";
import { mapSteps } from "../../../shared/reducers/V2/reducer_steps";
import { Text, Title, PageWrapper, ButtonContainer } from "./common";

class DuplicatePage extends Component {
    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path));
        } catch (e) { }

        const { duplicate } = this.props;

        if (!duplicate || !duplicate.data) {
            browserHistory.push('/');
        }

        this.props.setParentState({
            nextDisabled: false,
            noBack: true,
            hideButton: true,
        });
    }

    render() {
        const { duplicate, isAuthenticated, showModal } = this.props;
        return (
            <PageWrapper>
                <HappyIcon />
                {isAuthenticated ? (
                    <Fragment>
                        <Title>{t`duplicate.authorized.title`}</Title>
                        <Text>{t`duplicate.authorized.description`}</Text>
                        <ButtonContainer>
                            <V2Button
                                onClick={() => {
                                    browserHistory.push(
                                        `${paths.CLAIM}?id=${duplicate.data}`
                                    );
                                }}
                            >{t`duplicate.authorized.button.view_claim`}</V2Button>
                        </ButtonContainer>
                    </Fragment>
                ) : (
                        <Fragment>
                            <Title>{t`duplicate.unauthorized.title`}</Title>
                            <Text style={{ maxWidth: "100%" }}>{t`duplicate.unauthorized.description`}</Text>
                            <ButtonContainer>
                                <V2Button
                                    onClick={() => showModal(modalTypes.loginModal)}
                                >{t`v2_claim.common.sign_in`}</V2Button>
                            </ButtonContainer>
                        </Fragment>
                    )}
            </PageWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        isAuthenticated: state.auth.authenticated,
        duplicate: state.duplicate,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DuplicatePage);
