import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import _get from 'lodash/get'
import InputField from '../../../../shared/components/V2/InputField'
import { FlightNumberIcon } from '../../../../shared/components/V2/SkycopPay/icons'
import { V2BinIcon } from '../../../../shared/components/V2/V2Icons'
import { PlusIcon } from '../../../../views/V2/steps/ClaimInner/icons.js'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import SmartDelayHandler from './SmartDelayHandler'
import { ErrorMessage } from './ErrorMessage'

import {
    desktop,
    mobile,
    tablet,
} from '../../../../shared/components/helpers/styled_queries'
import FlightInput, {
    flightTypes,
} from '../../../../views/V2/components/FlightInput'
import DatePicker from '../../../../shared/components/V2/DatePicker'
import { scrollComponentToView } from '../../../../main'
import {
    Row,
    PageViewContainer,
    Col,
} from '../../../../shared/components/V2/Layout'
import t from '../../../../shared/translations'
import AirlineInput from '../AirlineInput'

import {
    StyledSmartDelayContainer,
    TitleContainer,
    StyledH1,
} from './SmartDelayPage'
import { initialFlightDetailsEntity } from '../../../../shared/reducers/V2/reducer_smart_delay'
import SmartDelayStepper from './SmartDelayStepper'
import { setSmartDelayFlightDetailsErrors } from '../../../../shared/actions/v2_actions'

const PageHeader = styled.p`
    color: #353d51;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
`

const FlightNumber = styled.p`
    color: #525f7f;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: unset;
`

const FlightNumberContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 12px;

    ${mobile`
font-size: 26px;
margin-left: 18px;
`}

    ${tablet`
font-size: 26px;
margin-left: unset;
`}
`
const StyledCol = styled(Col)`
    ${desktop`
    padding: 0px 12px;
    `}
`

const StyledDatePickerCol = styled(Col)`
    ${desktop`
    padding: 0px 12px;
    `}
    margin-bottom: 2.7rem;
`

const BinIconWrapper = styled.div`
    display: flex;
    margin-left: 8px;
    &:hover {
        cursor: pointer;
    }
`

export const DottedLine = styled.hr`
    border: none;
    border-top: 1px dashed #cbd3df;
    color: #fff;
    height: 1px;
    margin: 20px 14px;
`

export const TooltipInner = styled.div`
    p {
        color: #495363;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.5px; /* 150% */
        margin: 0;
        text-align: left;
    }

    .svg-close {
        display: none;
    }

    a {
        font-weight: bold;
        cursor: pointer;
        color: #35b56a;
        transition: none;
    }
`

export const RegisterUpToDescription = styled.div`
    font-family: Open Sans;
    font-weight: 400;
    font-size: 12px;
    color: #353d51;
    line-height: 20px;
    ${desktop`
    padding: 0px 12px;
    `}
`

export const AddButton = styled(V2Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: #525f7f;

    margin-top: 30px;
    margin-bottom: 10px;
    ${desktop`
    margin: 30px 0px 12px 10px;
    `}
`

const AddFlightWrapper = styled.div`
    ${mobile`
    margin: 20px 14px;
`}
`

export const Hr = styled.hr`
    border: none;
    border-top: 1px solid #cbd3df;
    height: 1px;
    margin: 20px 14px;
`

class FlightDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            flightDetails: props.flightDetailsData,
            errors: {},
        }
    }

    componentDidMount() {
        scrollComponentToView('pageTopNavbar', false, true)
    }

    handleFlightDetailsValue = (event, key) => {
        const e = _get(event, 'target', event)

        if (e && e.name && (e.value || e.value === '')) {
            this.setState(prevState => ({
                ...prevState,
                flightDetails: {
                    ...prevState.flightDetails,
                    [key]: {
                        ...prevState.flightDetails[key],
                        [e.name]: e.value,
                    },
                },
            }))
        }
    }

    handleFlightNumberValue = (event, key) => {
        const e = _get(event, 'target', event)

        if (e.name === 'flightNumber') {
            if (/^(?=.{3,6}$)[A-Za-z]{1,2}\s*\d{3,5}$/.test(e.value)) {
                this.setState({
                    [`${key}flightNumberError`]: '',
                })
            } else {
                this.setState({
                    [`${key}flightNumberError`]: 'flight_number_error',
                })
            }
        }
        this.handleFlightDetailsValue(event, key)
    }

    clearAirport = (key, name) => {
        if (name && key) {
            this.setState(prevState => ({
                ...prevState,
                flightDetails: {
                    ...prevState.flightDetails,
                    [key]: {
                        ...prevState.flightDetails[key],
                        [name]: '',
                    },
                },
            }))
        }
    }

    handleAddFlightDetails = () => {
        const { flightDetails } = this.state
        const { setSmartDelayFlightDetailsErrors } = this.props

        const nextFlightDetailsId = Object.keys(flightDetails).length + 1
        if (nextFlightDetailsId) {
            this.setState(prevState => ({
                ...prevState,
                flightDetails: {
                    ...prevState.flightDetails,
                    [nextFlightDetailsId]: initialFlightDetailsEntity,
                },
            }))
            setSmartDelayFlightDetailsErrors({})
        }
    }

    handleRemoveFlightDetails = key => {
        const { flightDetails } = this.state
        const { setSmartDelayFlightDetailsErrors } = this.props

        const newFlightDetails = Object.keys(flightDetails).reduce(
            (acc, val) => {
                if (val !== key) {
                    return {
                        ...acc,
                        [val]: flightDetails[val],
                    }
                }
                return acc
            },
            {}
        )

        const updateFlights = Object.keys(newFlightDetails).reduce(
            (acc, val, i) => {
                return {
                    ...acc,
                    [i + 1]: newFlightDetails[val],
                }
            },
            {}
        )
        this.setState(prevState => ({
            ...prevState,
            flightDetails: updateFlights,
        }))
        setSmartDelayFlightDetailsErrors({})
    }

    getAirlineSearchPromptText = key => {
        const { flightDetails } = this.state

        return flightDetails[key] && flightDetails[key].airline.length < 2
            ? ''
            : t`v2_common.no_results`
    }

    render() {
        const { trans, flightDetailsErrors } = this.props
        const { flightDetails } = this.state

        const yesterday = moment().subtract(1, 'day')
        const disablePastDt = current => {
            return current.isAfter(yesterday)
        }

        if (!trans) {
            return null
        }

        return (
            <PageViewContainer>
                <StyledSmartDelayContainer>
                    <TitleContainer>
                        <Helmet>
                            <title>{`Skycop - ${t`smart_delay.title`} - ${t`smart_delay.flight_details_header`}`}</title>
                        </Helmet>
                        <StyledH1>{t`smart_delay.title`}</StyledH1>
                        <SmartDelayStepper />
                        <PageHeader>{t`smart_delay.flight_details_header`}</PageHeader>
                    </TitleContainer>
                    {flightDetails &&
                        Object.keys(flightDetails).map(key => (
                            <div key={key}>
                                {flightDetailsErrors[key] && (
                                    <ErrorMessage
                                        errorText={t`${flightDetailsErrors[key]}`}
                                    />
                                )}
                                {Object.keys(flightDetails).length > 1 && (
                                    <FlightNumberContainer>
                                        <FlightNumber>{`${t`smart_delay.flight_details_number`} #${key}`}</FlightNumber>
                                        {key !== '1' && (
                                            <BinIconWrapper
                                                onClick={() =>
                                                    this.handleRemoveFlightDetails(
                                                        key
                                                    )
                                                }
                                            >
                                                <V2BinIcon
                                                    height={16}
                                                    width={16}
                                                />
                                            </BinIconWrapper>
                                        )}
                                    </FlightNumberContainer>
                                )}
                                <Row>
                                    <StyledCol sm={12} md={8} lg={6}>
                                        <FlightInput
                                            autofocus={false}
                                            hasIcon
                                            required
                                            clear={() =>
                                                this.clearAirport(
                                                    key,
                                                    'departureAirport'
                                                )
                                            }
                                            autoload={
                                                !!flightDetails[key]
                                                    .departureAirport
                                            }
                                            type={flightTypes.departure}
                                            label={t`planned_trips.label.departed_from`}
                                            name="departureAirport"
                                            value={
                                                flightDetails[key]
                                                    .departureAirport
                                            }
                                            defaultValue={
                                                flightDetails[key]
                                                    .departureAirport
                                            }
                                            onChange={e =>
                                                this.handleFlightDetailsValue(
                                                    e,
                                                    key
                                                )
                                            }
                                        />
                                    </StyledCol>
                                    <StyledCol sm={12} md={8} lg={6}>
                                        <FlightInput
                                            setRef={this.setRef}
                                            hasIcon
                                            required
                                            clear={() =>
                                                this.clearAirport(
                                                    key,
                                                    'arrivalAirport'
                                                )
                                            }
                                            autoload={
                                                !!flightDetails[key]
                                                    .arrivalAirport
                                            }
                                            label={t`planned_trips.label.arrived_to`}
                                            name="arrivalAirport"
                                            value={
                                                flightDetails[key]
                                                    .arrivalAirport
                                            }
                                            defaultValue={
                                                flightDetails[key]
                                                    .arrivalAirport
                                            }
                                            onChange={e =>
                                                this.handleFlightDetailsValue(
                                                    e,
                                                    key
                                                )
                                            }
                                        />
                                    </StyledCol>
                                </Row>
                                <Row>
                                    <StyledCol sm={12} md={8} lg={6}>
                                        <AirlineInput
                                            setRef={this.setRef}
                                            required
                                            name="airline"
                                            id={`flight-${key + 1}-airline`}
                                            label={t`v2_common.label.airline`}
                                            autoload={
                                                !!flightDetails[key].airline
                                            }
                                            clear={() =>
                                                this.clearAirport(
                                                    key,
                                                    'airline'
                                                )
                                            }
                                            value={
                                                flightDetails[key].airline || ''
                                            }
                                            onChange={e =>
                                                this.handleFlightDetailsValue(
                                                    e,
                                                    key
                                                )
                                            }
                                            searchPromptText={this.getAirlineSearchPromptText(
                                                key
                                            )}
                                        />
                                    </StyledCol>
                                    <StyledDatePickerCol sm={12} md={8} lg={6}>
                                        <DatePicker
                                            required
                                            type="text"
                                            name="flightDate"
                                            id={`flight-${key + 1}-date`}
                                            label={t`v2_common.label.date`}
                                            placeholder={t`v2_common.placeholder.departure_time`}
                                            value={
                                                !flightDetails[key].flightDate
                                                    ? ''
                                                    : moment.utc(
                                                          flightDetails[key]
                                                              .flightDate
                                                      )
                                            }
                                            defaultValue={
                                                !flightDetails[key].flightDate
                                                    ? ''
                                                    : moment.utc(
                                                          flightDetails[key]
                                                              .flightDate
                                                      )
                                            }
                                            handleChange={e =>
                                                this.handleFlightDetailsValue(
                                                    e,
                                                    key
                                                )
                                            }
                                            initialViewDate={
                                                flightDetails[key].flightDate
                                            }
                                            startDate={new Date().getFullYear()}
                                            endDate={10}
                                            dateFormat="YYYY-MM-DD"
                                            isValidDate={disablePastDt}
                                        />
                                    </StyledDatePickerCol>
                                </Row>
                                <Row>
                                    <InputField
                                        sm={6}
                                        name="flightNumber"
                                        label={t`v2_common.label.flight_number`}
                                        placeholder={t`v2_common.placeholder.flight_number`}
                                        type="string"
                                        required
                                        value={flightDetails[key].flightNumber}
                                        onChange={e =>
                                            this.handleFlightNumberValue(e, key)
                                        }
                                        Icon={FlightNumberIcon}
                                        tooltip={{
                                            children: () => (
                                                <TooltipInner
                                                    dangerouslySetInnerHTML={{
                                                        __html: t`smart_delay.flight_details_flight_number_tooltip_text`,
                                                    }}
                                                />
                                            ),
                                            dataTip: 'disabled',
                                            dataFor: 'flightNumber',
                                            place: 'bottom',
                                            id: 'flightNumber',
                                            questionId: 'flight-number',
                                            type: 'info',
                                            color: 'light',
                                            effect: 'solid',
                                        }}
                                        errorText={t`${_get(this.state, [
                                            `${key}flightNumberError`,
                                        ])}`}
                                    />
                                </Row>
                                {key !==
                                    Object.keys(
                                        flightDetails
                                    ).length.toString() && <DottedLine />}
                            </div>
                        ))}
                    <AddFlightWrapper>
                        {Object.keys(flightDetails).length !== 4 && (
                            <>
                                <AddButton
                                    type="borderBtn"
                                    onClick={this.handleAddFlightDetails}
                                >
                                    <PlusIcon fill="#525f7f" />
                                    {t`smart_delay.flight_details_add_flight`}
                                </AddButton>
                                <RegisterUpToDescription>{t`smart_delay.flight_details_register_up_to`}</RegisterUpToDescription>
                            </>
                        )}
                    </AddFlightWrapper>
                    <Hr />
                    <SmartDelayHandler flightDetails={flightDetails} />
                </StyledSmartDelayContainer>
            </PageViewContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        flightDetailsData: state.smartDelay.flightDetails,
        flightDetailsErrors: state.smartDelay.flightDetailsErrors,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setSmartDelayFlightDetailsErrors }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(FlightDetails)
