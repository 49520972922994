import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModal } from "../../../shared/actions/v2_actions";
import { CloseButton } from "../../../shared/components/V2/V2Buttons";
import Modal from "../../../shared/components/V2/Modal";
import FlightPayoutDetailsBox from "../../../shared/components/V2/FlightPayoutDetailsBox";
import { modalTypes } from "../../../shared/constants";

class PayoutDetailsMobile extends React.Component {
    render() {
        const { hideModal } = this.props;

        return (
            <Modal modalType={modalTypes.payoutDetailsMobileModal}>
                <CloseButton onClick={hideModal} width="34" height="34"/>
                <FlightPayoutDetailsBox />
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideModal,
        },
        dispatch
    );
}

export default connect(
    null,
    mapDispatchToProps
)(PayoutDetailsMobile);
