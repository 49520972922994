import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from 'styled-components';
import { getPayoutPlans, setSelectedPayout, setPayoutDetails, setIsFastPayout } from "../../../shared/actions/v2_actions";
import t from "../../translations";
import { Row, Col } from './Layout';
import { AbsoluteCorner, StopWatch } from '../../../views/V2/components/InfoSidebar';
import { serviceFeePercentage } from '../../constants';
import { mobile } from '../helpers/styled_queries';

const AmountText = styled.div`
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    color: #353d51;
`;

const CompItem = styled.div`
    display: flex;
    color: #76869c;

    div:first-child {
        margin-right: auto;
    }

    div:last-child {
        margin-left: auto;
        text-align: right;
    }

    div:not(:last-child) {
        padding-bottom: 8px;
    }

    .bold {
        font-weight: bold;
    }
`;

const AmountDescriptionText = styled.div`
    color: #353d51;
    line-height: 1.23;

    .bold {
        font-weight: bold;
        padding: 0px;
        border: 0 none;
        border-radius: unset;
        width: unset;
    }
`;

const TopHolder = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 24px;

    ${({ hasBorder }) => hasBorder ? 'border-bottom: solid 1px #bcc5d3;' : ''}
`;

const BottomHolder = styled.div`
    padding: 16px;
`;

const StyledCol = styled(Col)`
    ${mobile`
        padding-left: 0px;
        padding-right: 0px;
    `}
`;

const PlanBox = styled.label`
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    min-height: 200px;
    flex: 1 1 100%;

    &:first-child(odd) {
        margin-right: 8px;
    }

    &:last-child(even) {
        margin-left: 8px;
    }

    
    &:hover, &:focus, &:active {
        transition: all 0.3s linear;
        span:last-child:before {
            border: 1px solid ${({ theme }) => theme.colors.primaryColor};
        }
    }

    &:hover {
        transition: all 0.3s linear;
    }

    span {
        height: 100%;
        width: 100%;
        border: solid 1px #bcc5d3;
        border-radius: 8px;
    }
    span:last-child:before {
        transition: all 0.3s linear;
        position: absolute;
        content: "";
        top: 16px;
        left: 16px;
        width: 24px;
        height: 24px;
        border: 1px solid #dfe3e9;
        background-color: #fff;
        border-radius: 50%;
    }

    span:last-child:after {
        transition: all 0.3s linear;
        background-color: #fff;
    }

    [type="radio"]:checked + span:last-child:before {
        transition: all 0.3s linear;
        position: absolute;
        content: "";
        left: 16px;
        width: 24px;
        height: 24px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    }

    [type="radio"]:checked + span:last-child {
        cursor: pointer;
        border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    }

    [type="radio"] {
        opacity: 0;
        position: absolute;
    }

    [type="radio"]:checked + span:last-child:after {
        transition: all 0.3s linear;
        position: absolute;
        content: "";
        top: 24px;
        left: 24px;
        width: 8px;
        height: 8px;

        background-color: ${({ theme }) => theme.colors.primaryColor};
        border-radius: 50%;
    }
`;

const RadioInput = styled.input``;

export const PriceListLink = styled.div`
    display: flex;
    align-self: flex-end;
    justify-content: center;
    margin-top: 16px;

    a {
        color: ${({ theme }) => theme.colors.primaryColor};
        margin: 0px 3px;

        &:hover, :active, :focus {
            filter: brightness(1.1);
            text-decoration: none;
        }

        font-weight: bold;
        display: contents;
    }
`;

class PayoutPlans extends React.Component {

    handleRadioOnChange = ({ payoutId, amount, serviceFee, payout }, isFast) => {
        const { setSelectedPayout, setPayoutDetails, setIsFastPayout } = this.props;
        setSelectedPayout(payoutId);
        setPayoutDetails({ amount, serviceFee, payout }, payoutId);
        setIsFastPayout(isFast);
    }

    componentDidUpdate(prevProps, prevState) {
        const { v2_amount } = this.props;

        if (prevProps.v2_amount !== v2_amount) {
            this.props.getPayoutPlans(v2_amount);
        }
    }

    render() {
        const { payouts, v2_amount, trans, selectedPayout, currPayout, details, hideMobile = false } = this.props;
        const { amount } = details;
        const noAmount = v2_amount === 0 || v2_amount === "0";

        if (!trans) {
            return null;
        }

        if (hideMobile && window.innerWidth < 961) {
            return null;
        }

        return (
            <Row>
                {(payouts || []).sort((p) => p.is_fast_payout ? -1 : 1).map((p, i) => {
                    let amt = v2_amount || amount;
                    if (typeof amt !== "number") {
                        amt = parseFloat(amt).toFixed(2);
                    }
                    const fee = (amt * (p.percent / 100)) * (p.multiplier || serviceFeePercentage);
                    const total = amt - fee;
                    const newFee = parseFloat(Math.floor(fee * 100, 10) / 100).toFixed(2);
                    const newTotal = parseFloat(Math.ceil(total * 100) / 100, 10).toFixed(2);

                    return (
                        <StyledCol sm={12} md={8} lg={6} key={p.id}>
                            <PlanBox>
                                {
                                    p.is_fast_payout &&
                                    <React.Fragment>
                                        <AbsoluteCorner bgColor="#32b86a" />
                                        <StopWatch />
                                    </React.Fragment>
                                }
                                <RadioInput
                                    type="radio"
                                    onChange={() => this.handleRadioOnChange({
                                        payoutId: p.id,
                                        amount: amt,
                                        serviceFee: newFee,
                                        payout: newTotal,
                                    },
                                        !!p.is_fast_payout
                                    )}
                                    id={`select-plan-${i + 1}`}
                                    name={"payoutPlan"}
                                    value={p.id}
                                    defaultChecked={(currPayout || selectedPayout) === p.id}
                                    label={'yes'}
                                />
                                <span>
                                    <TopHolder hasBorder={!noAmount}>
                                        <div style={{ marginBottom: "4px" }}>
                                            {t`v2.payout_rework.you_get`}
                                        </div>
                                        <AmountText>{noAmount ? (p.is_fast_payout ? '50%' : t`canada.fixed.payout_percentage`) : (`€${newTotal}`)}</AmountText>
                                        {
                                            noAmount &&
                                            <AmountDescriptionText style={{ marginBottom: "24px" }}>
                                                {t`v2.price_list_amount_description_canada.payout_description`}
                                            </AmountDescriptionText>
                                        }
                                        <AmountDescriptionText
                                            dangerouslySetInnerHTML={{
                                                __html: p.is_fast_payout
                                                    ? t`v2_claim.sidebar.payout_in.fast_payout`
                                                    :
                                                    t`v2.payout_rework.payout_period`
                                            }}
                                        />
                                    </TopHolder>
                                    {
                                        !noAmount &&
                                        <BottomHolder>
                                            <CompItem>
                                                <div>{t`v2_claim.payout_sidebar.compensation`}</div>
                                                <div className="amt">&euro;{parseFloat(amt).toFixed(2)}</div>
                                            </CompItem>
                                            <CompItem>
                                                <div>{t`v2.payout_rework.skycop_renumeration`}</div>
                                                <div className="amt">-&euro;{newFee}</div>
                                            </CompItem>
                                            {
                                                !p.is_fast_payout &&
                                                <PriceListLink
                                                    dangerouslySetInnerHTML={{
                                                        __html: t`v2.price_list.see_full_info`
                                                    }}
                                                />
                                            }
                                        </BottomHolder>
                                    }
                                </span>
                            </PlanBox>
                        </StyledCol>
                    )
                })}
            </Row>
        )
    }
};

function mapStateToProps(state) {
    return {
        trans: state.translations,
        payouts: state.v2_payoutPlan,
        v2_amount: state.v2_amount,
        hasFastPayout: state.v2_hasFastPayout,
        selectedPayout: state.selectedPayout,
        details: state.v2_payout_details,
        regulation: state.regulation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPayoutPlans,
            setSelectedPayout,
            setPayoutDetails,
            setIsFastPayout,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { withRef: true }
)(PayoutPlans);
