import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleFooter, applyTranslationElement, onlyUnique } from "../../../main";
import t from "../../../shared/translations";
import {
    resetV2Claim,
    clearNotSupportedAirlines,
} from "../../../shared/actions/v2_actions";
import { SorryIcon } from "../../../shared/components/V2/V2Icons";
import { V2Button } from "../../../shared/components/V2/V2Buttons";
import { Text, Title, PageWrapper } from "./common";
import { V2_prePath } from "../../../shared/constants";
import { mobile, tablet } from "../../../shared/components/helpers/styled_queries";
import { isFeatureEnabled } from '../../../main';
import { featureToggles } from '../../../shared/constants';

const StyledPageWrapper = styled(PageWrapper)`
    padding: 20px 43px;

    svg {
        margin-bottom: 16px;
    }

    ${tablet`
        padding: 20px;
    `}

    ${mobile`
        padding: 14px 16px;
    `}
`;

const StyledText = styled(Text.withComponent('div'))`
    padding: 12px 40px;
    margin-bottom: 16px;

    ${tablet`
        padding: 12px 16px;
    `}

    ${mobile`
        padding: 12px;
    `}
`;

const AirlinesContainer = styled.div`
    border-radius: 8px;
    background-color: #f2f5f9;
    padding: 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #353d51;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;

    p {
        margin: 0;
    }
`;

class NotSupportedAirlinesInfo extends Component {
    componentDidMount() {
        this.props.setParentState({
            noBack: true,
            hideButton: true,
            saveDraftEnabled: false,
        });

        const { notSupportedAirlines, resetV2Claim } = this.props;

        if (!notSupportedAirlines.length) {
            browserHistory.push(V2_prePath);
        }

        resetV2Claim();
    }

    componentWillUnmount() {
        const { clearNotSupportedAirlines } = this.props;
        this.props.setParentState({
            noBack: false,
            hideButton: false,
            saveDraftEnabled: isFeatureEnabled(featureToggles.saveDraftFeature) ? true : false,
        });
        clearNotSupportedAirlines();
        toggleFooter().show();
    }

    render() {
        const { notSupportedAirlines } = this.props;

        return (
            <StyledPageWrapper height={'fit-content'}>
                <SorryIcon />
                <Fragment>
                    <Title>{t`v2_claim.sorry_modal.title`}</Title>
                    <StyledText id="not-supported-description">
                    {
                        applyTranslationElement(
                            t`v2_claim.not_supported_airlines_description`,
                            "%AIRLINE%",
                            <AirlinesContainer>
                                {(notSupportedAirlines || []).filter(onlyUnique).map((airline, i) => (
                                    <p key={i}>{airline}</p>
                                ))}
                            </AirlinesContainer>
                        )
                    }
                    </StyledText>
                    <div style={{ margin: 'auto' }}>
                        <V2Button
                            id="btn-try-new-flight"
                            onClick={() => {
                                browserHistory.push("/");
                            }}
                            style={{ maxWidth: 300 }}
                        >{t`v2_claim.sorry_modal.button.try_new`}</V2Button>
                    </div>
                </Fragment>
            </StyledPageWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        notSupportedAirlines: state.notSupportedAirlines,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetV2Claim,
            clearNotSupportedAirlines,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotSupportedAirlinesInfo);
