import React from "react";
import styled from "styled-components";
import { desktop } from "../../../shared/components/helpers/styled_queries";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    ${desktop`
        padding-top: 8px;
    `}
`;

const Box = styled.div`
    width: 100%;
    border-radius: 5px;
    background-color: #f4f6f8;
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px;

    &:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #f4f6f8;
        transform: rotate(45deg);
        top: -8px;
        left: 22px;
    }
`;

const TextContainer = styled.div`
    padding-right: 8px;

    p {
        margin: 0;
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #7f8fa4;
        margin-bottom: 4px;

        span {
            font-weight: 600;
        }

        &.dark {
            color: #495363;
        }
    }

    b {
        display: block;
        color: #353d51;
        font-weight: bold;
        margin-bottom: 4px;
    }

    span {
        color: ${({ theme }) => theme.colors.primaryColor};
        font-weight: bold;
    }

    .inline {
        display: inline-block;
        cursor: pointer;

        &:hover {
            filter: brightness(115%);
        }
    }
`;

const GrayInfoBox = ({ children }) => (
    <Container>
        <Box>
            <TextContainer>
                {children}
            </TextContainer>
        </Box>
    </Container>
);

export default GrayInfoBox;
