import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { LangSelect } from './helpers'
import t from '../../shared/translations'
import {
    mobile,
    desktop,
    minTablet,
} from '../../shared/components/helpers/styled_queries'
import { toggleBrandedGdpr } from '../actions'
import { GdrpMessage } from '../components/GdrpMessage'

const MobileLangWrapper = styled.div`
    ${minTablet`
        display: none;
    `}
`

const DesktopLangWrapper = styled.div`
    ${mobile`
        display: none;
    `}
`

const SettingsLink = styled.div`
    color: #ffffff;
    cursor: pointer;
    font-family: 'Noto sans';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    cursor: pointer;

    ${mobile`
    text-align: start;

`}

    &:hover {
        filter: brightness(110%);
    }

    ${desktop`
        text-align: left;
    `}
`

const FooterContainer = styled.footer`
    ${minTablet`
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    `}

    background: url('/images/footer-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    background-color: #262626;
    padding: 40px 60px 40px 60px;
    bottom: 0px;
    width: 100%;

    ${mobile`
        ${({ showCookies }) => showCookies && 'margin-bottom: 121px;'};
        padding: 40px 20px 40px 20px;
        margin-top: 0px;
    `}
`
const CopyrightText = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    font-family: 'Noto Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    color: #717171;

    span {
        text-align: center;
    }

    ${mobile`
    margin-top: 24px;
    text-align: start;
    flex-direction: column;
    align-items: start;

`}

    ${desktop`
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0px;

        span {
            margin-right: 8px;
        }
    `}
`

const Footer = ({
    trans,
    toggleCookies,
    showCookies,
    showBrandedGdpr,
    location,
}) => {
    const query = location && location.query
    const infoMessagePosition = query && query.msg_position

    if (trans) {
        return (
            <React.Fragment>
                {showBrandedGdpr &&
                    infoMessagePosition &&
                    infoMessagePosition.toLowerCase() === 'bottom' && (
                        <GdrpMessage
                            onClose={() => toggleBrandedGdpr(false)}
                            html={t`common.branded.gdpr_info`}
                        />
                    )}
                <FooterContainer
                    className="blurrable"
                    showCookies={showCookies}
                >
                    <MobileLangWrapper>
                        <LangSelect
                            position={{
                                bottom: '65px',
                                right: '-5px',
                            }}
                        />
                    </MobileLangWrapper>
                    <CopyrightText>
                        <span>{t`footer.copyright.2024`}</span>
                        <SettingsLink onClick={() => toggleCookies()}>
                            {t`label.common.cookie_preferences`}
                        </SettingsLink>
                    </CopyrightText>
                    <DesktopLangWrapper>
                        <LangSelect
                            position={{
                                bottom: '65px',
                                right: '-50px',
                            }}
                        />
                    </DesktopLangWrapper>
                </FooterContainer>
            </React.Fragment>
        )
    } else {
        return null
    }
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        showBrandedGdpr: state.show_branded_gdpr,
    }
}

export default connect(mapStateToProps)(Footer)
