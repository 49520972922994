import React from 'react'
import styled from 'styled-components'
import V2CheckBox from '../../../shared/components/V2/V2CheckBox'
import { Container } from '../../../shared/components/V2/V2CheckBox'

const Label = styled.p`
    vertical-align: middle;
    display: inline;
    cursor: pointer;

    .pulse {
        box-shadow: 0 0 0 rgba(241, 60, 60, 0.4);
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
        }
    }
    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
            box-shadow: 0 0 0 0 rgba(241, 60, 60, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
            box-shadow: 0 0 0 10px rgba(241, 60, 60, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
            box-shadow: 0 0 0 0 rgba(241, 60, 60, 0);
        }
    }
`

const LabelText = styled.p`
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.01em;

    font-stretch: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    color: #333333;
    display: inline;

    a {
        text-decoration: underline;
    }
`

const TermsP = styled.p`
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 22.4px;
    letter-spacing: normal;
    color: #333333;
    margin-left: 8px;
    font-weight: 400;

    a {
        color: ${({ theme }) => theme.colors.primaryColor};
        font-weight: 400;
        text-decoration: underline;

        &:hover {
            color: ${({ theme }) => theme.colors.primaryColor};
            filter: brightness(110%);
        }
    }
    span {
        margin-left: 4px;
        color: #fe6050;
    }
`

const StyledCheckmark = styled.span`
    display: inline;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: white;
    border: ${({ checked }) => (checked ? 'none' : 'solid 1px #cbd3df')};
    animation: unset;
    ${({ alert }) =>
        alert &&
        `
        border: 1px solid  #FE6050;
        animation: pulse 2s infinite;
    `}

    &:hover {
        border: 1px solid #cbd3df;
        border: ${({ theme }) => `1px solid ${theme.colors.primaryColor}`};
    }

    &:after {
        content: '';
        position: absolute;
        display: none;
        left: 9px;
        top: 4px;
        width: 7px;
        height: 12px;
        border: 1px solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`

const StyledCheckBox = styled.input`
    display: inline;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ ${StyledCheckmark}:after {
        display: block;
    }
    &:checked ~ ${StyledCheckmark} {
        background-color: ${({ theme }) => theme.colors.primaryColor};
    }
`

const Asterix = styled.span`
    margin-left: 4px;
    color: #fe6050;
`

const TermsAndConditions = ({ text, required, ...props }) => {
    return (
        <V2CheckBox
            label={() => (
                <>
                    <TermsP
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                </>
            )}
            type="checkbox"
            name="termsConditions"
            {...props}
        />
    )
}

export const TermsAndConditionsCheckbox = ({
    text,
    checked,
    onChange,
    required,
}) => (
    <Container checked={checked} paddingLeft="38px">
        <Label onClick={onChange}>
            <StyledCheckBox type="checkbox" checked={checked} />
            <StyledCheckmark checked={checked} />
            <LabelText
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
            {required && <Asterix>*</Asterix>}
        </Label>
    </Container>
)

export default TermsAndConditions
