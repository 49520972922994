import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import styled from 'styled-components'
import _get from 'lodash/get'
import t from '../../../shared/translations'
import { Col, Row, StepContent } from '../../../shared/components/V2/Layout'
import { SideWaysRadio } from '../../../shared/components/V2/RadioButtons'
import StepView from '../components/StepView'
import SidebarProgress from '../components/SidebarProgress'
import {
    getClaimReasons,
    getAirports,
    getAirlines,
} from '../../../shared/actions'
import {
    setArrivalAirport,
    setDepartureAirport,
    setV2Claim,
    getUUID,
    setStepOne,
    validateStepOne,
    showModal,
    setSelectedAirports,
    getFlightDistance,
    getClaimAmount,
    resetAmountDistance,
    getUserData,
    setUsedAirports,
    setProgress,
    checkIfClaimable,
    nextStep,
    clearStepOne,
    setNavStep,
    resetPostedClaim,
    asyncPreloadAirport,
    setClaimDraftProps,
    getPayoutPlans,
    setPreloadedClaimStep,
    setUsedAirlines,
    saveClaimDraft,
    getRemappedUserFields,
    getAllDrafts,
    clearAllSuggestedFlights,
    getScannedClaimDraft,
} from '../../../shared/actions/v2_actions'
import FlightInput, { flightTypes } from '../components/FlightInput'
import {
    isEmpty,
    isProduction,
    isMobileDevice,
    loadScreenOn,
    isFeatureEnabled,
} from '../../../main'
import SorryModal from '../components/SorryModal'
import {
    V2_prePath,
    modalTypes,
    V2_STEPS,
    paths,
    featureToggles,
} from '../../../shared/constants'
import { mapSteps } from '../../../shared/reducers/V2/reducer_steps'
import {
    desktop,
    mobile,
    tablet,
} from '../../../shared/components/helpers/styled_queries'
import TitleHeaderInfoBox from '../components/TitleHeaderInfoBox'
import { fireGtagEvent } from '../../../shared/services/events'

const StyledStepContent = styled(StepContent)`
    ${desktop`
        margin: 0;
    `}
`

const StyledFieldsRow = styled(Row)`
    margin-bottom: 16px;

    ${tablet`
        margin-bottom: 0px;
        `}

    ${mobile`
        margin-bottom: 0px;
        `}
`

class FlightItinerary extends React.Component {
    enableBack = false
    references = {}
    preloadCount = 2
    preloadFinished = false
    isFaqOpen = false
    isScanLoad = false

    constructor(props) {
        super(props)

        this.state = {
            id: props.claimUUID || '',
            airportArrival: '',
            airportDeparture: '',
            flights: [],
            componentState: {
                isDirect: true,
                stopsCount: 0,
                stopsState: [],
                ...props.stepOneState,
            },
            problemClaimFlight: 0,
            validations: {
                ...props.stepOneValidators,
            },
            errors: {
                ...props.stepOneErrors,
            },
            claimableErrors: [],
            ...props.V2Claim,
            claimableResponse: [],
            innerWidth: window.innerWidth,
        }
    }

    resetState = () => {
        this.setState({
            id: this.props.claimUUID || '',
            airportArrival: '',
            airportDeparture: '',
            flights: [],
            componentState: {
                isDirect: true,
                stopsCount: 0,
                stopsState: [],
                ...this.props.stepOneState,
            },
            problemClaimFlight: 0,
            validations: {
                ...this.props.stepOneValidators,
            },
            errors: {
                ...this.props.stepOneErrors,
            },
            claimableErrors: [],
            ...this.props.V2Claim,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { airportArrival, airportDeparture } = this.state
        const {
            setParentState,
            isDisabled,
            claimDraft,
            setStepOne,
            authenticated,
            fullUserData,
            claimPreloaded,
        } = this.props

        if (claimDraft && this.isScanLoad) {
            this.preloadClaimDraft(claimDraft, claimPreloaded)
            this.isScanLoad = false
        }

        if (
            airportArrival &&
            airportDeparture &&
            this.validateConnecting() &&
            isDisabled
        ) {
            setParentState({
                nextDisabled: false,
                saveDraftEnabled: true,
                buttonText: '',
                isFinish: false,
            })
        } else if (
            !isDisabled &&
            (!airportArrival || !airportDeparture || !this.validateConnecting())
        ) {
            setParentState({
                nextDisabled: true,
                saveDraftEnabled: false,
                buttonText: '',
                isFinish: false,
            })
        }

        if (
            authenticated &&
            !_get(this, 'state.user.email', '') &&
            _get(fullUserData, 'email', '')
        ) {
            const user = getRemappedUserFields(fullUserData)
            this.setState({
                ...this.state,
                user,
            })
        }

        if (claimDraft && this.preloadCount === 0 && !this.preloadFinished) {
            loadScreenOn()
            this.preloadFinished = true
            setStepOne(this.state.componentState)
            this.props.setV2Claim(this.state)
            const arrayPaths = Object.keys(paths.V2).map(key => paths.V2[key])
            const lastPathIndex = arrayPaths.indexOf(
                claimDraft.componentState.lastPath
            )
            if (lastPathIndex > 4) {
                browserHistory.push(
                    `${paths.V2.STEP_4_FLIGHT_DETAILS}?r=${lastPathIndex}`
                )
            } else {
                browserHistory.push(`${claimDraft.componentState.lastPath}?r=f`)
            }
        }
    }

    preloadAirport = (query, name, callback) => {
        const { asyncPreloadAirport } = this.props
        const inputRef = this.references && this.references[name]

        if (inputRef) {
            inputRef.loadOptions(query)
            asyncPreloadAirport(query, airport =>
                this._handleAirportChange({ ...airport, name }, callback)
            )
        }
    }

    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
            window.addEventListener('resize', this._onResize)
        } catch (e) {
            if (!isProduction()) {
                console.log(e)
            }
        }

        if (window && window.dataLayer) {
            window.dataLayer.push({
                event: 'step0',
            })
        }

        const {
            getUUID,
            getClaimReasons,
            setNoBack,
            getUserData,
            claimUUID,
            location,
            setArrivalAirport,
            setParentState,
            setDepartureAirport,
            postedClaim,
            resetPostedClaim,
            claimDraft,
            claimPreloaded,
            getAllDrafts,
            authenticated,
            getScannedClaimDraft,
        } = this.props
        const { componentState } = this.state

        let reqObj = location.query
        const scanCode = reqObj['scan']
        if (scanCode) {
            this.isScanLoad = true
            getScannedClaimDraft(scanCode)
        }

        if (authenticated) {
            getAllDrafts()
        }

        if (postedClaim) {
            resetPostedClaim()
        }

        setParentState({
            buttonText: '',
            isFinish: false,
        })

        if (!claimUUID && !claimDraft) {
            getUUID().then(id => {
                if (!this.state.id) {
                    this.setState({
                        ...this.state,
                        id,
                    })
                }
            })
        }

        if (location && location.query) {
            const iataFrom = location.query.iata_from || ''
            const iataTo = location.query.iata_to || ''

            this.setState({
                componentState: {
                    ...componentState,
                    iataFrom,
                    iataTo,
                },
            })

            if (iataFrom && iataTo) {
                this.preloadAirport(
                    iataFrom,
                    'airportDeparture',
                    setDepartureAirport
                )
                this.preloadAirport(iataTo, 'airportArrival', setArrivalAirport)
                browserHistory.push(V2_prePath)
            }
        }

        getUserData()
        getClaimReasons('reasons', 1)
        setNoBack(true)

        this.preloadClaimDraft(claimDraft, claimPreloaded)
    }

    componentWillUnmount() {
        this.props.setNoBack(false)
        window.removeEventListener('resize', this._onResize)
    }

    _onResize = () => {
        const width = window.innerWidth

        this.setState({ innerWidth: width })
    }

    preloadClaimDraft = (claimDraft, claimPreloaded) => {
        const {
            getAirports,
            asyncPreloadAirport,
            getAirlines,
            setUsedAirlines,
        } = this.props

        if (claimDraft && !claimPreloaded[V2_STEPS.STEP_1]) {
            this.preloadCount = _get(claimDraft, 'flights.length', 1) + 1
            this.setState(
                {
                    ...claimDraft,
                    flights: claimDraft.flights,
                    componentState: {
                        ...this.state.componentState,
                        ...claimDraft.componentState,
                    },
                },
                () => {
                    this.props.setPreloadedClaimStep([V2_STEPS.STEP_1])
                    const getAirportsOptions = { range: 100 }
                    getAirports(
                        null,
                        'airportDeparture',
                        claimDraft.airportDeparture,
                        getAirportsOptions
                    ).then(() => {
                        if (
                            !!this.props.idAssets[claimDraft.airportDeparture]
                        ) {
                            this.preloadAirport(
                                this.props.idAssets[claimDraft.airportDeparture]
                                    .iata,
                                'airportDeparture',
                                e => {
                                    setDepartureAirport(e)
                                    this.preloadCount = this.preloadCount - 1
                                }
                            )
                        }
                    })
                    getAirports(
                        null,
                        'airportArrival',
                        claimDraft.airportArrival,
                        getAirportsOptions
                    ).then(() => {
                        if (!!this.props.idAssets[claimDraft.airportArrival]) {
                            this.preloadAirport(
                                this.props.idAssets[claimDraft.airportArrival]
                                    .iata,
                                'airportArrival',
                                e => {
                                    setArrivalAirport(e)
                                    this.preloadCount = this.preloadCount - 1
                                }
                            )
                        }
                    })

                    if (!!claimDraft.componentState.stopsState) {
                        claimDraft.componentState.stopsState.forEach(
                            (stop, index) => {
                                getAirports(
                                    null,
                                    `stop_${index + 1}`,
                                    stop.value,
                                    getAirportsOptions
                                ).then(() => {
                                    this.props
                                        .setUsedAirports(
                                            this.props.idAssets[stop.value]
                                        )
                                        .then(() => {
                                            const inputRef =
                                                this.references &&
                                                this.references[
                                                    `stop_${index + 1}`
                                                ]
                                            if (inputRef) {
                                                inputRef.loadOptions(
                                                    this.props.idAssets[
                                                        stop.value
                                                    ].iata
                                                )
                                                asyncPreloadAirport(
                                                    this.props.idAssets[
                                                        stop.value
                                                    ].iata,
                                                    airport =>
                                                        this._handleStopChange(
                                                            {
                                                                ...airport,
                                                                name: `stop_${index +
                                                                    1}`,
                                                            },
                                                            () => {
                                                                this.preloadCount =
                                                                    this
                                                                        .preloadCount -
                                                                    1
                                                                return index
                                                            }
                                                        )
                                                )
                                            }
                                        })
                                })
                            }
                        )
                    }

                    if (claimDraft.flights && !!!claimDraft.flights.length) {
                        claimDraft.flights.forEach(flight => {
                            getAirlines(null, null, flight.airline).then(
                                res => {
                                    const airline = res.filter(
                                        asset => asset.id === flight.airline
                                    )
                                    const selectedAirline = airline.map(air => {
                                        air.value = flight.airline
                                        air.name = 'airline'
                                        air.label = `${air.title} (${air.iata})`
                                        return air
                                    })

                                    setUsedAirlines(selectedAirline).then(r => {
                                        this.references &&
                                            Object.keys(
                                                this.references
                                            ).forEach(key => {
                                                if (key.includes('airline')) {
                                                    this.references[key] &&
                                                        this.references[key]
                                                            .loadOptions &&
                                                        this.references[
                                                            key
                                                        ].loadOptions(
                                                            selectedAirline
                                                        )
                                                }
                                            })
                                    })
                                }
                            )
                        })
                    }
                }
            )
        }
    }

    validateConnecting = () => {
        const { flights, componentState } = this.state
        const { isDirect, stopsCount, stopsState } = componentState

        if (!isDirect) {
            if (flights.length !== stopsCount + 1) {
                return false
            }
            if (
                stopsState.length !== stopsCount ||
                stopsState.filter(f => !f.value).length !== 0
            ) {
                return false
            }
        } else {
            if (flights.length === 1) {
                return true
            }
        }

        return true
    }

    validateStep = () => {
        const { validateStepOne } = this.props
        const errors = validateStepOne(this.state)

        return !!isEmpty(errors) && this.validateConnecting()
    }

    _handleNextStep = () => {
        const { componentState } = this.state
        const {
            setV2Claim,
            setStepOne,
            setNoBack,
            claimUUID,
            setProgress,
            checkIfClaimable,
            showModal,
            lang,
            claimDraftId,
        } = this.props
        const claimObj = { ...this.state }

        fireGtagEvent(
            {
                event: componentState.isDirect
                    ? 'step1_fi_direct_flight_yes'
                    : 'step1_fi_direct_flight_no',
            },
            true
        )
        this._handleSaveDraft()

        const claimableCallback = () => {
            if (this.validateStep()) {
                setProgress(18)
                setNoBack(false)

                if (!claimObj.id) {
                    claimObj.id = claimDraftId || claimUUID
                }

                if (
                    (claimObj.flights || []).filter(f => !!f.airline).length ===
                    0
                ) {
                    delete claimObj.flights
                    claimObj.flights = this._mapFlights()
                }

                setV2Claim(claimObj)
                setStepOne(componentState)
                this.props.triggerAnalytics()
                this.props.nextStep()
                this.props.saveClaimDraft()
            }
        }

        checkIfClaimable(claimObj, lang, 1)
            .then(res => {
                const { claimable } = res
                this.setState({ claimableResponse: res })

                if (claimable) {
                    claimableCallback()
                } else {
                    const errors = _get(res, 'reason.data')

                    this.setState({ claimableErrors: errors })
                    showModal(modalTypes.sorryModal)
                    // eslint-disable-next-line
                    dataLayer.push({
                        event: 'ClaimNotEligible',
                    })
                    if (
                        window &&
                        window.hj &&
                        typeof window.hj === 'function'
                    ) {
                        window.hj('vpv', '/sorry')
                    }
                }
            })
            .catch(() => {
                claimableCallback()
            })

        return false
    }

    _handleSaveDraft = () => {
        const { setClaimDraftProps } = this.props
        const stateObj = { ...this.state }

        setClaimDraftProps(stateObj)
    }

    handleRange = ({ target }) => {
        this.setState({ progress: target.value })
    }

    _handleChangeEvent = event => {
        const e = _get(event, 'target', event)

        this.setState({ [e.name]: e.value })
    }

    // getSidebarInfo = () => {
    //     const { airportArrival, airportDeparture } = this.state;
    //     const { getFlightDistance } = this.props;

    //     if (airportArrival && airportDeparture) {
    //         getFlightDistance(airportArrival, airportDeparture);
    //     }
    // }

    _handleAirportChange = (event, callback) => {
        const { selectedAirports } = this.props
        const e = _get(event, 'target', event)
        const name = _get(event, 'name', event)

        if (name === 'airportDeparture') {
            fireGtagEvent({ event: 'step1_fi_departed_from' }, true)
        }
        if (name === 'airportArrival') {
            fireGtagEvent({ event: 'step1_fi_final_destination' }, true)
        }

        if (
            !e ||
            !e.name ||
            !e.value ||
            selectedAirports.find(a => a.id === e.value)
        ) {
            return
        }

        const { validateStepOne, getClaimAmount, V2Claim } = this.props
        const { passengers } = V2Claim

        this.setState({ [e.name]: e.value }, () => {
            const { airportArrival, airportDeparture } = this.state
            this._remapFlights()
            validateStepOne(this.state, e.name)
            getClaimAmount(airportArrival, airportDeparture, passengers)
            this.setState({ flights: this._mapFlights() })
            this.handleSelectedAirports()
        })
        callback(e)
    }

    handleSelectedAirports = () => {
        const { setSelectedAirports, usedAirports } = this.props
        const { airportArrival, airportDeparture, componentState } = this.state
        const { stopsState } = componentState
        const selected = []

        const getAirport = id => usedAirports.find(a => a.id === id)

        ;(stopsState || []).forEach(s => {
            if (s && s.value && getAirport(s.value)) {
                selected.push(getAirport(s.value))
            }
        })

        if (airportArrival && getAirport(airportArrival)) {
            selected.push(getAirport(airportArrival))
        }

        if (airportDeparture && getAirport(airportDeparture)) {
            selected.push(getAirport(airportDeparture))
        }

        clearAllSuggestedFlights()
        setSelectedAirports(selected)
    }

    clearStopAirport = value => {
        const { flights, componentState } = this.state

        const filteredFlights = flights.filter(
            f => ![f.airportArrival, f.airportDeparture].includes(value)
        )

        const filteredStates = componentState.stopsState.filter(
            f => f.value !== value
        )

        this.setState(
            {
                flights: filteredFlights,
                componentState: {
                    ...componentState,
                    stopsState: filteredStates,
                },
            },
            () => {
                this._remapFlights()
                this.handleSelectedAirports()
            }
        )
    }

    clearAirport = (isConnecting, name, value, callback) => {
        if (name) {
            this.setState({ [name]: '' }, () => {
                this._remapFlights()
                this.handleSelectedAirports()
            })
        }

        if (callback) {
            callback('')
        }

        if (isConnecting) {
            this.clearStopAirport(value)
        } else {
            const { selectedAirports, setSelectedAirports } = this.props
            setSelectedAirports(
                (selectedAirports || []).filter(a => a.id !== value)
            )
            this.props.resetAmountDistance()
        }

        this._remapFlights()
        this.props.setUsedAirports([], true)
    }

    _remapFlights = () => {
        let flights = this._mapFlights()

        if (!flights || flights.length === 0) {
            return
        }

        this.setState({ flights })
    }

    _mapFlights = () => {
        const { airportArrival, airportDeparture, componentState } = this.state
        const { getPayoutPlans, amount } = this.props
        const { isDirect, stopsState } = componentState
        const mappedConnectingFlights = []
        const flightsCount = stopsState.length + 1
        const sortedStops = stopsState.sort((a, b) => a.id - b.id)

        if (!airportArrival || !airportDeparture) {
            return []
        }

        if (isDirect && flightsCount === 1) {
            return [
                {
                    airportDeparture,
                    airportArrival,
                    flightOrder: 0,
                },
            ]
        }

        const firstFlight = {
            airportDeparture,
            airportArrival: _get(sortedStops, '[0].value', ''),
            flightOrder: 0,
        }
        const lastFlight = {
            airportDeparture: _get(
                sortedStops,
                `[${sortedStops.length - 1}].value`,
                ''
            ),
            airportArrival,
            flightOrder: sortedStops.length,
        }

        if (sortedStops.length > 1) {
            for (let i = 0; i < sortedStops.length; i++) {
                const from = _get(sortedStops[i], 'value', '')
                const to = _get(sortedStops[i + 1], 'value', '')
                if (from && to) {
                    mappedConnectingFlights.push({
                        airportDeparture: from,
                        airportArrival: to,
                        flightOrder: i + 1,
                    })
                }
            }
        }

        mappedConnectingFlights.unshift(firstFlight)
        mappedConnectingFlights.push(lastFlight)

        if (
            amount &&
            (mappedConnectingFlights || []).filter(f => !!f.airline).length ===
                0
        ) {
            getPayoutPlans(amount)
        }

        return mappedConnectingFlights
    }

    _handleStopChange = (event, callback) => {
        const { selectedAirports } = this.props
        const { componentState } = this.state
        const { stopsState } = componentState
        const id = callback()

        if (
            !event ||
            !event.value ||
            !event.name ||
            selectedAirports.find(a => a.id === event.value)
        ) {
            return
        }

        if (componentState.stopsCount === 1 && event.name === 'stop_1') {
            if (window && window.dataLayer) {
                const isStepEventRegistered = !!window.dataLayer.find(
                    e => e.event && e.event.includes('step1_fi_no_stops')
                )

                if (!isStepEventRegistered) {
                    fireGtagEvent({ event: 'step1_fi_no_stops1' }, true)
                }
            }
            fireGtagEvent({ event: 'step1_fi_where_stop1' }, true)
        }

        if (componentState.stopsCount === 2 && event.name === 'stop_2') {
            fireGtagEvent({ event: 'step1_fi_where_stop2' }, true)
        }

        if (componentState.stopsCount === 3 && event.name === 'stop_3') {
            fireGtagEvent({ event: 'step1_fi_where_stop3' }, true)
        }

        const restStops = stopsState.filter(s => s.id !== id)

        const allStops = [
            {
                value: event.value,
                id,
                error: event.error || '',
            },
            ...restStops,
        ]

        this.setState(
            {
                componentState: {
                    ...componentState,
                    stopsState: allStops,
                },
            },
            () => {
                this.setState({ flights: this._mapFlights() }, () =>
                    this.handleSelectedAirports()
                )
            }
        )
    }

    _handleComponentChangeEvent = e => {
        const { componentState } = this.state

        this.setState({
            componentState: {
                ...componentState,
                [e.target.name]: e.target.value,
            },
        })
    }

    _renderHowManyStops = () => {
        const { componentState, innerWidth } = this.state

        const stopsList = [1, 2, 3, 4]

        if (componentState.isDirect) {
            return null
        } else {
            if (innerWidth < 1194) {
                return (
                    <>
                        <StyledFieldsRow>
                            <SideWaysRadio
                                label={t`v2_common.flight_itinerary.steps`}
                                required
                                smallMobile={stopsList.length > 2}
                                options={stopsList
                                    .slice(0, 2)
                                    .map((stop, i) => ({
                                        name: 'stopsCount',
                                        value: stop,
                                        defaultChecked:
                                            stop === componentState.stopsCount,
                                        label: stop,
                                        onChange: () =>
                                            this.handleStopCountChange(stop, i),
                                        id: `stops-${i + 1}`,
                                    }))}
                            />
                        </StyledFieldsRow>
                        <StyledFieldsRow>
                            <SideWaysRadio
                                required
                                smallMobile={stopsList.length > 2}
                                options={stopsList
                                    .slice(2, 4)
                                    .map((stop, i) => ({
                                        name: 'stopsCount',
                                        value: stop,
                                        defaultChecked:
                                            stop === componentState.stopsCount,
                                        label: stop,
                                        onChange: () =>
                                            this.handleStopCountChange(stop, i),
                                        id: `stops-${i + 1}`,
                                    }))}
                            />
                        </StyledFieldsRow>
                    </>
                )
            }
            return (
                <StyledFieldsRow>
                    <SideWaysRadio
                        label={t`v2_common.flight_itinerary.steps`}
                        required
                        smallMobile={stopsList.length > 2}
                        options={stopsList.map((stop, i) => ({
                            name: 'stopsCount',
                            value: stop,
                            defaultChecked: stop === componentState.stopsCount,
                            label: stop,
                            onChange: () => this.handleStopCountChange(stop, i),
                            id: `stops-${i + 1}`,
                        }))}
                    />
                </StyledFieldsRow>
            )
        }
    }

    _renderStops = () => {
        const { isDirect, stopsCount, stopsState } = this.state.componentState
        let allStops = []

        const stopNumeration = {
            0: t`v2_claim.flight_itinerary.first_stop`,
            1: t`v2_claim.flight_itinerary.second_stop`,
            2: t`v2_claim.flight_itinerary.third_stop`,
            3: t`v2_claim.flight_itinerary.fourth_stop`,
        }

        if (isDirect) {
            return null
        }

        for (let index = 0; index < stopsCount; index++) {
            const currStop = stopsState.find(s => s.id === index)
            const currVal = _get(currStop, 'value', '')
            const name = `stop_${index + 1}`

            allStops.push(
                <StyledFieldsRow key={index}>
                    <Col xs={12} sm={8} md={6} lg={6}>
                        <FlightInput
                            setRef={this.setRef}
                            hasIcon
                            required
                            clear={() => this.clearAirport(true, '', currVal)}
                            autoload={!!currVal}
                            type={flightTypes.stop}
                            label={stopNumeration[index]}
                            name={name}
                            value={currVal}
                            defaultValue={currVal}
                            onChange={this._handleStopChange}
                            callback={() => index}
                            id={`airport-stop-${index + 1}`}
                        />
                    </Col>
                </StyledFieldsRow>
            )
        }

        return <React.Fragment>{allStops}</React.Fragment>
    }

    handleRadioOnChange = (stopsCount, isDirect) => {
        const { componentState } = this.state

        fireGtagEvent(
            {
                event: isDirect
                    ? 'step1_fi_direct_flight_yes'
                    : 'step1_fi_direct_flight_no',
            },
            true
        )
        this.setState(
            {
                componentState: {
                    ...componentState,
                    stopsCount,
                    stopsState: [],
                    isDirect,
                },
            },
            () => {
                this.setState({ flights: this._mapFlights() })
                this.handleSelectedAirports()
            }
        )
    }

    handleStopCountChange = (stop, i) => {
        const { componentState } = this.state

        if (window && window.dataLayer) {
            const isStepEventRegistered = !!window.dataLayer.find(
                e => e.event && e.event.includes('step1_fi_no_stops')
            )

            if (!isStepEventRegistered) {
                fireGtagEvent({ event: `step1_fi_no_stops${stop}` }, true)
            }
        }

        this.setState(
            {
                componentState: {
                    ...componentState,
                    stopsCount: stop,
                    stopsState: componentState.stopsState.filter(
                        s => s.id <= i
                    ),
                },
                problemClaimFlight: 0,
            },
            () => {
                this.setState({ flights: this._mapFlights() }, () =>
                    this.handleSelectedAirports()
                )
            }
        )
    }

    setRef = (name, ref) => {
        if (this.references && !this.references[name]) {
            this.references[name] = ref
        }
    }

    render() {
        const {
            setArrivalAirport,
            setDepartureAirport,
            trans,
            stepOneErrors,
            nav,
            modal,
        } = this.props
        const { airportArrival, airportDeparture, claimableErrors } = this.state
        const { isDirect } = this.state.componentState

        if (!trans) {
            return null
        }

        const isAlert = isFeatureEnabled(featureToggles.extraordinary_alert)

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                noBack={true}
                isFinish={false}
                disableTopBorder={true}
                nav={{
                    ...nav,
                    saveDraftEnabled: airportArrival && airportDeparture,
                    nextDisabled:
                        !airportArrival ||
                        !airportDeparture ||
                        !this.validateConnecting(),
                }}
                Sidebar={
                    <SidebarProgress
                        activeIndex={1}
                        checkedIndexes={[0]}
                        paddingTop={0}
                    />
                }
                // Sidebar={<OnboardingSidebar top={0} />}
                title={t`v2_claim.flight_itinerary.title`}
                HeaderInfoBox={() => (
                    <TitleHeaderInfoBox
                        header={t`FAQ.general.not_sure`}
                        content={t`FAQ.itinerary.text.html`}
                        toggleOpen={isOpen => {
                            fireGtagEvent(
                                { event: 'step1_fi_not_sure_how' },
                                true
                            )
                            this.isFaqOpen = isOpen
                            this.forceUpdate()
                        }}
                        isOpen={this.isFaqOpen}
                        alertHeader={t`alert.extraordinary_title`}
                        alertContent={t`alert.extraordinary_info`}
                        isAlert={isAlert}
                    />
                )}
            >
                <StyledStepContent>
                    {_get(modal, 'modalType', '') === modalTypes.sorryModal && (
                        <SorryModal
                            errors={claimableErrors}
                            newFlight={() => {
                                this.props.clearStepOne()
                                this.resetState()
                            }}
                        />
                    )}
                    <StyledFieldsRow>
                        <Col sm={12} md={8} lg={6}>
                            <FlightInput
                                setRef={this.setRef}
                                autofocus={!isMobileDevice()}
                                hasIcon
                                required
                                clear={() =>
                                    this.clearAirport(
                                        false,
                                        'airportDeparture',
                                        airportDeparture,
                                        setDepartureAirport
                                    )
                                }
                                autoload={!!airportDeparture}
                                type={flightTypes.departure}
                                label={t`v2_common.label.departed_from`}
                                name="airportDeparture"
                                id="airport-departure"
                                value={airportDeparture}
                                defaultValue={airportDeparture}
                                onChange={this._handleAirportChange}
                                callback={setDepartureAirport}
                                errorText={
                                    stepOneErrors &&
                                    stepOneErrors.airportDeparture
                                }
                            />
                        </Col>
                        <Col sm={12} md={8} lg={6}>
                            <FlightInput
                                setRef={this.setRef}
                                hasIcon
                                required
                                clear={() =>
                                    this.clearAirport(
                                        false,
                                        'airportArrival',
                                        airportArrival,
                                        setArrivalAirport
                                    )
                                }
                                autoload={!!airportArrival}
                                label={t`v2_common.label.arrived_to`}
                                name="airportArrival"
                                id="airport-arrival"
                                value={airportArrival}
                                defaultValue={airportArrival}
                                onChange={this._handleAirportChange}
                                callback={setArrivalAirport}
                                errorText={
                                    stepOneErrors &&
                                    stepOneErrors.airportArrival
                                }
                            />
                        </Col>
                    </StyledFieldsRow>
                    <StyledFieldsRow>
                        <SideWaysRadio
                            label={t`v2_common.flight_itinerary.direct_flight`}
                            required
                            options={[
                                {
                                    onChange: () =>
                                        this.handleRadioOnChange(0, true),
                                    name: 'isDirect',
                                    value: !!isDirect,
                                    checked: !!isDirect,
                                    textCenter: true,
                                    label: t`v2_common.label.yes`,
                                    id: 'yes-direct',
                                },
                                {
                                    onChange: () =>
                                        this.handleRadioOnChange(1, false),
                                    name: 'isDirect',
                                    value: !isDirect,
                                    checked: !isDirect,
                                    textCenter: true,
                                    label: t`v2_common.label.no`,
                                    id: 'no-direct',
                                },
                            ]}
                        />
                    </StyledFieldsRow>
                    {this._renderHowManyStops()}
                    {this._renderStops()}
                </StyledStepContent>
            </StepView>
        )
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        airportArrival: state.v2_airportArrival,
        airportDeparture: state.v2_airportDeparture,
        selectedAirports: state.v2_selectedAirports,
        V2Claim: state.V2Claim,
        claimUUID: state.claim_UUID,
        stepOneState: state.v2_step_one,
        stepOneValidators: state.v2_step_one_errors.validations,
        stepOneErrors: state.v2_step_one_errors.errors,
        trans: state.translations,
        usedAirports: state.v2_UsedAirports,
        amount: state.v2_amount,
        distance: state.v2_distance,
        lang: state.language,
        postedClaim: state.v2_posted_claim,
        modal: state.toggle_modal,
        claimDraft: state.claimDraft,
        claimPreloaded: state.claimPreloaded,
        idAssets: state.idAssets,
        claimDraftId: state.claimDraftId,
        authenticated: state.auth.authenticated,
        draftEmail: state.draftEmail,
        fullUserData: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            setArrivalAirport,
            setDepartureAirport,
            setV2Claim,
            getUUID,
            setStepOne,
            validateStepOne,
            setSelectedAirports,
            getClaimReasons,
            getFlightDistance,
            getClaimAmount,
            getUserData,
            setUsedAirports,
            resetAmountDistance,
            setProgress,
            checkIfClaimable,
            nextStep,
            clearStepOne,
            resetPostedClaim,
            asyncPreloadAirport,
            setClaimDraftProps,
            getAirports,
            getPayoutPlans,
            setPreloadedClaimStep,
            getAirlines,
            setUsedAirlines,
            saveClaimDraft,
            getAllDrafts,
            getScannedClaimDraft,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(FlightItinerary)
