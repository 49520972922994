import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import moment from 'moment'
import _get from 'lodash/get'
import debounce from 'lodash/debounce'
import latinize from 'latinize'
import { Col, Row, StepContent } from '../../../shared/components/V2/Layout'
import StepView from '../components/StepView'
import DatePicker from '../../../shared/components/V2/DatePicker'
import V2FlightNumber from '../../../shared/components/V2/V2FlightNumber'
import {
    PlaneIconV2,
    RedExclamation,
    WarnIcon,
} from '../../../shared/components/svgIcons'
import { QuestionMarkTooltip } from '../../../shared/components/V2/V2Icons'
import { getAirlines } from '../../../shared/actions'
import { PageTitle } from '../../../shared/components/V2/TextElements'
import {
    setV2Claim,
    validateStepFour,
    getAirportById,
    setSelectedAirlines,
    postCodeFieldCheck,
    setProgress,
    showModal,
    checkIfClaimable,
    nextStep,
    clearStepOne,
    clearStepTwo,
    clearStepThree,
    clearStepFour,
    setNavStep,
    getPayoutPlans,
    setClaimDraftProps,
    setUsedAirlines,
    setPreloadedClaimStep,
    setNotSupportedAirlines,
    saveClaimDraft,
    getSuggestedFlights,
    clearSuggestedFlight,
} from '../../../shared/actions/v2_actions'
import InputField from '../../../shared/components/V2/InputField'
import AirlineInput from '../components/AirlineInput'
import t from '../../../shared/translations'
import SorryModal, { ErrorContainer } from '../components/SorryModal'
import {
    scrollComponentToView,
    isProduction,
    addBeforeUnload,
    showInactiveDraftModal,
    removeBeforeUnload,
    isFeatureEnabled,
    isMobileDevice,
} from '../../../main'
import { mapSteps } from '../../../shared/reducers/V2/reducer_steps'
import {
    V2_prePath,
    modalTypes,
    V2_STEPS,
    paths,
    inactiveTimeoutAwaitTime,
    featureToggles,
} from '../../../shared/constants'
import SidebarProgress from '../components/SidebarProgress'
import {
    mobile,
    tablet,
    tabletLG,
    desktop,
} from '../../../shared/components/helpers/styled_queries'
import TitleHeaderInfoBox from '../components/TitleHeaderInfoBox'
import FlightSuggestModalCodeshares from '../components/FlightSuggestModalCodeshares'
import { fireGtagEvent } from '../../../shared/services/events'

export const StyledP = styled.p`
    display: flex;
    font-family: 'Noto sans';
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #7f8fa4;

    span {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }

    &.mb-16 {
        margin-bottom: 16px;
    }

    &.align-center {
        text-align: center;
    }

    &.mobile {
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: center;
        color: #728096;
        padding: 0px 16px;

        ${desktop`
            display: none;
        `}
    }
`

const Content = styled(StepContent)`
    flex-direction: column;
    padding: 0;

    .__react_component_tooltip {
        padding: 8px;

        &.show {
            opacity: 1 !important;
        }

        &.styled-tooltip {
            width: 448px;
            word-break: break-word;
            white-space: pre-wrap;
            background: #fafafa !important;
            font-family: Noto Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            text-align: left;
            color: #000000;
            border-radius: 12px;
            padding: 24px;
            border: 1px solid #149954;

            ${mobile`
                left: 12px !important;
                width: 90%;

                &:after {
                    ${() =>
                        `left: ${
                            (
                                document.getElementById('question-mark') || {
                                    getBoundingClientRect: () => 200,
                                }
                            ).getBoundingClientRect().x
                        }px;`}
                    bottom: -5px;
                    margin-left: -12px;
                    border-top-color: #495363!important;
                }
            `}
        }

        &:after {
            border-top-color: #495363 !important;
        }
    }
`

const PageTitleStyledRow = styled(Row)`
    margin-bottom: 12px;
`
// const WarnIcon = styled.div`
//     font-family: 'Open Sans', sans-serif;
//     width: 20px;
//     height: 20px;
//     background-color: #ff713b;
//     margin-right: 16px;
//     border-radius: 50%;
//     color: #fff;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 14px;
// `

const FlightAirportIata = styled.div`
    font-family: Noto Sans;
    font-size: 40px;
    font-weight: 600;
    color: #000000;
`

const FlightAirportIataTitle = styled.div`
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    color: #717171;
    margin-left: 33px;
    width: 100px;

    ${mobile`
         margin-left: 38px;
    `}
`

const FlightAirportIataTitleArrival = styled.div`
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    color: #717171;
    margin-left: 43px;
    width: 100px;
`

export const PlaneIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
`

export const HeaderContainer = styled.div`
    display: flex;
    padding: 24px 16px 32px 12px;

    svg {
        margin: 0px;
    }

    .v2-checkmark {
        margin-right: 16px;
    }

    p {
        font-family: 'Noto sans';
        margin: 0;
        font-size: 28px;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #353d51;
    }

    ${tabletLG`
        width: 66.666666666667%;
    `}
`

const FlightRow = styled(Row)`
    flex-direction: column;

    .simple-select {
        padding: 0px 12px;
        flex-wrap: wrap;

        .simple-item {
            width: fit-content;
            flex: unset;
            border-radius: 32px;
            border: solid 1px #717171;
            background-color: #f7f7f8;
            margin: 0px 8px 8px 0px;
            font-size: 16px;
            color: #728096;
            font-weight: 600;
            padding: 5px 12px;

            &:hover {
                opacity: 0.9;
                cursor: pointer;
                border-color: #43c67f;
            }

            span:first-child {
                color: #3a3f56;
            }
        }

        .simple-selected {
            border-color: #43c67f;
            background-color: rgba(50, 184, 106, 0.05);
        }
    }

    .simple-label {
        font-family: 'Noto sans';
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        color: #7f8fa4;
        letter-spacing: normal;
        margin-bottom: 8px;

        ${Col} {
            padding-bottom: 0px;
        }
    }

    ${tablet`
        .simple-select {
            flex-direction: row;
            width: 66.666666666666%;

            .simple-item {
                margin: 0px 8px 8px 0px;

            }
        }
    `}

    ${mobile`
        .simple-select {
            .simple-item {
                margin: 8px 0px;
            }
        }
    `}
`

const WrapperTitles = styled.div`
    display: flex;
    flex-direction: column;
`

const FlightCounterDesktop = styled.div`
    margin-left: auto;
    color: #717171;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;

    ${mobile`
        text-align: center;
        align-items: center;
        justify-content: center;
        display: none;
    `}

    ${tablet`
        text-align: center;
        align-items: center;
        justify-content: center;
    `}
`

const FLightCounterMobile = styled(FlightCounterDesktop)`
    display: none;
    ${mobile`
        display: unset;
        margin-bottom: 12px;
        margin-left: 16px;
        width: max-content;
    `}
`

const TooltipIconWrapper = styled.span`
    background: #ecf7f1;
    padding: 4px 10px;
    border-radius: 8px;
`

const PresuggestLink = styled.div`
    opacity: 0.9;
    font-family: 'Noto Sans';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    background-color: #149954;
    color: #fff;
    display: flex;
    align-self: flex-end;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
`

const IataIconWrapper = styled.div`
    margin-right: 8px;
    margin-top: 6px;
    ${mobile`
        margin-left: 6pxpx;
    `}
`

const IataTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

class FlightDetails extends React.Component {
    inactiveTimeout = null
    preloadCount = 1
    preloadFinished = false
    isFaqOpen = false

    state = {
        reservationNumber: '',
        flights: isProduction()
            ? []
            : [
                  {
                      airportArrival: 'bb5e4dec-e8c9-11e8-874e-b2970b7c7b36',
                      airportDeparture: 'a0f5dc86-e8c9-11e8-847b-b2970b7c7b36',
                      flightOrder: 0,
                      isOpen: true,
                  },
              ],
        ...this.props.V2Claim,
        airlinesInputValue: '',
        componentState: {
            viewDate: '',
            didCallTimeout: false,
        },
        claimableResponse: [],
    }

    references = {}

    resetState = () => {
        this.setState({
            flights: [],
            airlinesInputValue: '',
            ...this.props.V2Claim,
        })
    }

    toggleDetails = index => {
        const { flights } = this.state
        const newFlights = [...flights]
        const keys = ['airline', 'number', 'timeDeparture']
        const filled = keys.every(key => !!flights[index][key])

        if (filled) {
            newFlights[index].isOpen = !flights[index].isOpen
            this.setState({ flights: newFlights })
        }
    }

    draftModified = () => {
        const { flights } = this.state
        const { claimDraft } = this.props
        if ((flights || []).length !== (claimDraft.flights || []).length) {
            return true
        }

        const flightsDiff = (flights || []).reduce(
            (result, curr, currIndex) => {
                const draftFlight = _get(
                    claimDraft,
                    `flights[${currIndex}]`,
                    {}
                )
                if (
                    curr.airportDeparture !== draftFlight.airportDeparture ||
                    curr.airportArrival !== draftFlight.airportArrival
                ) {
                    return [...result, curr]
                } else {
                    return result
                }
            },
            []
        )

        return flightsDiff.length !== 0
    }

    componentDidMount() {
        const { flights } = this.state
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {}

        const {
            setParentState,
            V2Claim,
            claimDraft,
            getAirlines,
            setUsedAirlines,
            claimPreloaded,
            draftEmail,
            authenticated,
        } = this.props
        if (
            (!V2Claim ||
                !V2Claim.airportArrival ||
                !V2Claim.airportDeparture) &&
            isProduction()
        ) {
            setNavStep(0)
            browserHistory.replace(V2_prePath)
        } else {
            this.fetchPayouts()
        }

        if (claimDraft && !claimPreloaded[V2_STEPS.STEP_4]) {
            const currFlights = this.draftModified()
                ? flights
                : claimDraft.flights
            const updatedflights = currFlights.map(flight => {
                flight.isOpen = true
                return flight
            })

            this.setState(
                {
                    flights: updatedflights,
                    componentState: {
                        ...claimDraft.componentState,
                    },
                },
                () => {
                    this.props.setPreloadedClaimStep([V2_STEPS.STEP_4])
                    this.preloadCount = _get(claimDraft, 'flights.length', 1)
                    claimDraft.flights.forEach(flight => {
                        getAirlines(null, null, flight.airline).then(res => {
                            const airline = res.filter(
                                asset => asset.id === flight.airline
                            )
                            const selectedAirline = airline.map(air => {
                                air.value = flight.airline
                                air.name = 'airline'
                                air.label = `${air.title} (${air.iata})`
                                return air
                            })

                            this.preloadCount = this.preloadCount - 1
                            setUsedAirlines(selectedAirline).then(r => {
                                this.references &&
                                    Object.keys(this.references).forEach(
                                        key => {
                                            if (key.includes('airline')) {
                                                this.references[key] &&
                                                    this.references[key]
                                                        .loadOptions &&
                                                    this.references[
                                                        key
                                                    ].loadOptions(
                                                        selectedAirline
                                                    )
                                            }
                                        }
                                    )
                            })
                        })
                    })
                    this.forceUpdate()
                }
            )
        } else {
            if (flights) {
                const updatedflights = flights.map(flight => {
                    flight.isOpen = true
                    return flight
                })

                this.setState({
                    flights: updatedflights,
                })

                if (!!authenticated || draftEmail) {
                    this._handleSaveDraft()
                }
            }
        }

        setParentState({
            nextDisabled: true,
        })

        scrollComponentToView('pageTopNavbar', false, true)
        addBeforeUnload(() => this.props.showModal(modalTypes.claimDraftModal))
    }

    handleInactiveTimeout = debounce(() => {
        if (!_get(this, 'state.componentState.didCallTimeout', false)) {
            if (this.inactiveTimeout) {
                clearTimeout(this.inactiveTimeout)
            }

            this.inactiveTimeout = setTimeout(() => {
                showInactiveDraftModal(() => {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            didCallTimeout: true,
                        },
                    }))
                    this._handleSaveDraft()
                })
            }, inactiveTimeoutAwaitTime)
        }
    })

    componentWillUnmount() {
        if (this.inactiveTimeout) {
            clearTimeout(this.inactiveTimeout)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { setParentState, isDisabled, claimDraft } = this.props

        if (isDisabled && !this.checkNextStep()) {
            setParentState({
                nextDisabled: false,
            })
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    didCallTimeout: true,
                },
            }))
            if (this.inactiveTimeout) {
                clearTimeout(this.inactiveTimeout)
            }
        } else if (!isDisabled && this.checkNextStep()) {
            setParentState({
                nextDisabled: true,
            })
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    didCallTimeout: false,
                },
            }))
        }

        if (claimDraft && this.preloadCount === 0 && !this.preloadFinished) {
            this.preloadFinished = true
            const { query } = this.props.location
            if (query['r'] && query['r'] !== 'f') {
                this._handleNextStep(true)
                browserHistory.push(`${claimDraft.componentState.lastPath}?r=f`)
            }
        }

        if (isFeatureEnabled(featureToggles.inactiveTimeout)) {
            this.handleInactiveTimeout()
        }
    }

    // handleFlightNumberChange = (e, index) => {
    //     const { flights } = this.state;
    //     const newFlights = [...flights];

    //     if (e.target.value === "na") {
    //         newFlights[index].flightNumber = "";
    //         newFlights[index].isFlightNumber = false;
    //     } else if (e.target.name === "flightNumber_na") {
    //         newFlights[index].flightNumber = e.target.value;
    //     } else {
    //         newFlights[index].flightNumber = e.target.value;
    //         newFlights[index].isFlightNumber = true;
    //     }

    //     this.setState({ flights: newFlights });
    // }

    fetchPayouts = () => {
        const { flights } = this.state
        const { getPayoutPlans, v2_amount, regulation } = this.props

        if (v2_amount || regulation === 'ca') {
            const hasAirlines =
                flights.length === flights.filter(f => !!f.airline).length
            const directFlight = flights.length === 1

            if (hasAirlines) {
                const allAirlines = (flights || []).map(f => f.airline)

                if (directFlight) {
                    const flightNumber = flights[0].number
                    const flightAirline = flights[0].airline
                    const flightDate = flights[0].timeDeparture
                    const airportDeparture = flights[0].airportDeparture
                    const airportArrival = flights[0].airportArrival

                    if (
                        flightNumber &&
                        flightAirline &&
                        airportDeparture &&
                        airportArrival &&
                        flightDate
                    ) {
                        getPayoutPlans(
                            v2_amount,
                            allAirlines,
                            regulation === 'ca',
                            flightNumber,
                            flightAirline,
                            flightDate,
                            airportDeparture,
                            airportArrival
                        )
                    } else {
                        getPayoutPlans(
                            v2_amount,
                            allAirlines,
                            regulation === 'ca'
                        )
                    }
                } else {
                    getPayoutPlans(v2_amount, allAirlines, regulation === 'ca')
                }
            } else {
                getPayoutPlans(v2_amount, [], regulation === 'ca')
            }
        }
    }

    handleAirlineInputChange = inputValue => {
        this.setState({ airlinesInputValue: inputValue })
    }

    handleReservationChange = e => {
        const { validateStepFour } = this.props
        const { target } = e
        fireGtagEvent({ event: 'step5_afd_reservation_number' }, true)
        const value = latinize(target.value.toUpperCase())
        this.setState({ reservationNumber: value }, () =>
            validateStepFour(this.state)
        )
    }

    handleChange = (e, index) => {
        const {
            usedAirlines,
            setSelectedAirlines,
            postCodeFieldCheck,
        } = this.props
        const { flights, componentState } = this.state
        const newFlights = [...flights]
        const target = _get(e, 'target', e)

        if (!e || !target) {
            return
        }

        newFlights[index][target.name] = target.value

        if (target.name === 'number') {
            fireGtagEvent({ event: 'step4_fd_flight_number_input' }, true)
        }

        if (target.name === 'airline') {
            fireGtagEvent({ event: 'step4_fd_airlines_input' }, true)

            const airlines = newFlights.reduce((result, item) => {
                const airline = usedAirlines.filter(
                    a => a.id === item.airline
                )[0]
                return airline ? [...result, airline] : result
            }, [])

            setSelectedAirlines(airlines).then(() => postCodeFieldCheck())
            newFlights[index]['prefillLetters'] = this.getIata(target.value)
            this.setState({ flights: newFlights })
        } else if (target.name === 'timeDeparture') {
            fireGtagEvent({ event: 'step4_fd_flight_date' }, true)

            delete newFlights[index]['preselectId']
            this.setState(
                {
                    flights: newFlights,
                    componentState: {
                        ...componentState,
                        viewDate: target.value,
                    },
                },
                () => {
                    this.validateFlightDates(index)
                }
            )
        } else {
            this.setState({ flights: newFlights })
        }
    }

    fetchSuggestions = arrayIndex => {
        const { flights } = this.state
        const { getSuggestedFlights, showModal } = this.props
        const flight = flights[arrayIndex]

        if (flight && flight.error) {
            clearSuggestedFlight(arrayIndex)
            return
        }

        const callback = (data, depIata, arrIata) => {
            showModal(modalTypes.flightSuggestModalCodeshares, {
                callBack: (item, preselectId) =>
                    this.handlePreselect(arrayIndex, item, preselectId),
                data,
                selected: flight.preselectId,
                route: { depIata, arrIata },
            })
            this.forceUpdate()
        }

        if (
            flight &&
            !flight.error &&
            flight.timeDeparture &&
            flight.airportDeparture &&
            flight.airportArrival
        ) {
            let depAirport = getAirportById(flight.airportDeparture)
            let arrAirport = getAirportById(flight.airportArrival)
            if (depAirport && arrAirport) {
                getSuggestedFlights(
                    {
                        flightDate: flight.timeDeparture,
                        depAirport: depAirport.iata,
                        arrAirport: arrAirport.iata,
                    },
                    arrayIndex,
                    callback
                )
            }
        }
    }

    clearAirline = index => {
        const { flights } = this.state
        const newFlights = [...flights]

        if (newFlights && newFlights[index] && newFlights[index].airline) {
            delete newFlights[index].airline
            delete newFlights[index].prefillLetters
            if (!!newFlights[index].preselectId) {
                delete newFlights[index].preselectId
                delete newFlights[index].number
            }
        }

        this.setState({ flights: newFlights })
    }

    getAirlineSearchPromptText = () => {
        const { airlinesInputValue } = this.state

        return airlinesInputValue.length < 2 ? '' : t`v2_common.no_results`
    }

    renderIcon = flight => {
        // const { airline, timeDeparture, number } = flight

        // if (airline && timeDeparture && number) {
        //     return <V2CheckIcon />
        // } else {
        return <WarnIcon />
        // }
    }

    checkNextStep = () => {
        const { errors } = this.props
        const { flights } = this.state
        const resError = _get(errors, 'reservationNumber', null)

        if (resError) {
            return true
        }

        const missingInfoFlights = flights.filter(
            f => !f.airline || !f.timeDeparture || !f.number
        )

        if (missingInfoFlights.length !== 0) {
            return true
        }

        const flightDates = flights.reduce((acc, current) => {
            if (current.timeDeparture) {
                acc.push(moment(current.timeDeparture))
            }

            return acc
        }, [])

        if (flightDates.length === flights.length) {
            const firstFlightDate = flightDates.shift()

            const wrongDate = flightDates.some(date => {
                return firstFlightDate.diff(date, 'day') > 0
            })

            return wrongDate
        }
    }

    _handleSaveDraft = () => {
        const { setClaimDraftProps } = this.props
        const stateObj = { ...this.state }

        setClaimDraftProps(stateObj)
        this.props.saveClaimDraft()
    }

    getIata = id => {
        const { usedAirlines } = this.props
        let airline
        let iata = ''

        if (id) {
            airline = usedAirlines.filter(airline => airline.id === id)[0]
            iata = airline && airline.iata
        }

        return iata
    }

    validateFlightDates = index => {
        const { flights } = this.state
        const firstFlightDate = _get(flights[0], 'timeDeparture', '')

        if (firstFlightDate) {
            const newFlights = flights.map((flight, i) => {
                let newFlight = { ...flight }
                if (i > 0 && flight.timeDeparture) {
                    if (
                        moment(firstFlightDate).diff(
                            flight.timeDeparture,
                            'day'
                        ) > 0
                    ) {
                        newFlight.error = t`v2_claim.flight_details.invalid_date`
                    } else {
                        newFlight.error = ''
                    }
                }
                return newFlight
            })

            this.setState({ flights: newFlights }, () =>
                this.fetchSuggestions(index)
            )
        } else {
            this.fetchSuggestions(index)
        }
    }

    setRef = (name, ref, index = '') => {
        let refName = `${name}${index}`
        if (this.references && !this.references[refName]) {
            this.references[refName] = ref
        }
    }

    validateFlightDate = current => {
        const today = new Date()
        let now = new Date()
        now.setDate(today.getDate() + 14)

        let last = new Date()
        last.setDate(today.getDate() - 3660)
        return current.isBefore(now) && current.isAfter(last)
    }

    handlePreselect = (flightIndex, item, preselectId) => {
        const {
            getAirlines,
            setUsedAirlines,
            setSelectedAirlines,
            postCodeFieldCheck,
        } = this.props
        const { flights } = this.state
        const modifyFlight = { ...flights[flightIndex] }

        getAirlines(item.airline_iata, true).then(res => {
            const airline = res.filter(
                asset => asset.iata === item.airline_iata
            )
            const selectedAirline = airline.map(air => {
                air.value = air.id
                air.name = 'airline'
                air.label = `${air.title} (${air.iata})`
                return air
            })

            setUsedAirlines(selectedAirline).then(r => {
                this.references &&
                    Object.keys(this.references).forEach(key => {
                        if (key.includes('airline')) {
                            this.references[key] &&
                                this.references[key].loadOptions &&
                                this.references[key].loadOptions(
                                    selectedAirline
                                )
                        }
                    })
                modifyFlight.number = item.flight_number
                modifyFlight.airline = selectedAirline[0].value
                modifyFlight.prefillLetters = item.airline_iata
                modifyFlight.preselectId = preselectId
                const resultFlights = [
                    ...flights.slice(0, flightIndex),
                    modifyFlight,
                    ...flights.slice(flightIndex + 1, flights.length),
                ]
                this.setState(
                    {
                        flights: resultFlights,
                    },
                    () => {
                        const airlines = resultFlights.reduce(
                            (result, item) => {
                                const airline = this.props.usedAirlines.filter(
                                    a => a.id === item.airline
                                )[0]
                                return airline ? [...result, airline] : result
                            },
                            []
                        )

                        setSelectedAirlines(airlines).then(() =>
                            postCodeFieldCheck()
                        )
                        this.fetchPayouts()
                    }
                )
            })
        })
    }

    renderFlightDetails = () => {
        const { errors, showModal, suggestedFlights } = this.props
        const { flights, componentState } = this.state
        const orderedFlights = flights.sort(
            (a, b) => a.flightOrder - b.flightOrder
        )

        return orderedFlights.map((flight, index) => {
            let depAirport = getAirportById(flight.airportDeparture)
            let arrAirport = getAirportById(flight.airportArrival)
            let prevFlightDate = ''

            if (index !== 0) {
                let prevFlight = flights[index - 1]
                if (prevFlight && prevFlight.timeDeparture) {
                    prevFlightDate = prevFlight.timeDeparture
                }
            }

            if (!depAirport || !arrAirport) {
                if (isProduction()) {
                    return null
                } else {
                    depAirport = {}
                    arrAirport = {}
                }
            }

            const suggestCount = Object.keys(
                suggestedFlights[index] || []
            ).reduce((prev, acc) => {
                const dataArrLength = (suggestedFlights[index][acc] || [])
                    .length
                return prev + dataArrLength
            }, 0)

            return (
                <FlightRow className="mb-16" key={index}>
                    {index !== 0 && (
                        <Col
                            xs={12}
                            md={12}
                            lg={12}
                            style={{
                                // borderTop: '1px dashed #cbd3df',
                                padding: 0,
                                margin: '0px 16px',
                                width: '95%',
                                alignSelf: 'center',
                            }}
                        />
                    )}
                    {orderedFlights.length > 1 && (
                        <FLightCounterMobile>
                            {`${index + 1} ${t`v2.flight_details.out_of`} ${
                                orderedFlights.length
                            }`}
                        </FLightCounterMobile>
                    )}
                    <HeaderContainer>
                        <WrapperTitles>
                            <IataTitleWrapper>
                                <IataIconWrapper>
                                    {this.renderIcon(flight, index)}
                                </IataIconWrapper>
                                <FlightAirportIata>
                                    {depAirport.iata}
                                </FlightAirportIata>
                            </IataTitleWrapper>
                            <FlightAirportIataTitle>
                                {depAirport.title}
                            </FlightAirportIataTitle>
                        </WrapperTitles>

                        <WrapperTitles>
                            <IataTitleWrapper>
                                <PlaneIconContainer>
                                    <PlaneIconV2 />
                                </PlaneIconContainer>
                                <FlightAirportIata>
                                    {arrAirport.iata}
                                </FlightAirportIata>
                            </IataTitleWrapper>
                            <FlightAirportIataTitleArrival>
                                {arrAirport.title}
                            </FlightAirportIataTitleArrival>
                        </WrapperTitles>
                        {orderedFlights.length > 1 && (
                            <FlightCounterDesktop>
                                {`${index + 1} ${t`v2.flight_details.out_of`} ${
                                    orderedFlights.length
                                }`}
                            </FlightCounterDesktop>
                        )}
                    </HeaderContainer>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                suggestDate={
                                    index !== 0 && !flight.timeDeparture
                                        ? prevFlightDate
                                        : ''
                                }
                                required
                                type="text"
                                name="timeDeparture"
                                id={`flight-${index + 1}-date`}
                                label={t`v2_common.label.date`}
                                errorText={flight.error && flight.error}
                                placeholder={t`v2_common.placeholder.departure_time`}
                                value={
                                    !flight.timeDeparture
                                        ? ''
                                        : moment.utc(flight.timeDeparture)
                                }
                                defaultValue={
                                    !flight.timeDeparture
                                        ? ''
                                        : moment.utc(flight.timeDeparture)
                                }
                                handleChange={e => this.handleChange(e, index)}
                                initialViewDate={componentState.viewDate}
                                startDate={new Date().getFullYear()}
                                endDate={10}
                                dateFormat="YYYY-MM-DD"
                                isValidDate={this.validateFlightDate}
                            />
                        </Col>
                    </Row>
                    {suggestedFlights[index] &&
                        Object.keys(suggestedFlights[index] || []).length > 0 &&
                        suggestCount > 3 && (
                            <Row>
                                <Col
                                    style={{ display: 'flex' }}
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                >
                                    <PresuggestLink
                                        className="flight-suggest-btn"
                                        onClick={() => {
                                            fireGtagEvent(
                                                {
                                                    event:
                                                        'step4_fd_choose_your_flight',
                                                },
                                                true
                                            )
                                            showModal(
                                                modalTypes.flightSuggestModalCodeshares,
                                                {
                                                    callBack: (
                                                        item,
                                                        preselectId
                                                    ) =>
                                                        this.handlePreselect(
                                                            index,
                                                            item,
                                                            preselectId
                                                        ),
                                                    data:
                                                        suggestedFlights[index],
                                                    selected:
                                                        flight.preselectId,
                                                    route: {
                                                        depIata:
                                                            depAirport.iata,
                                                        arrIata:
                                                            arrAirport.iata,
                                                    },
                                                }
                                            )
                                        }}
                                    >
                                        <span>
                                            {t`flight_suggest.pick_scheduled_time`}
                                        </span>
                                    </PresuggestLink>
                                </Col>
                            </Row>
                        )}
                    {suggestedFlights[index] &&
                        Object.keys(suggestedFlights[index] || []).length > 0 &&
                        suggestCount < 4 && (
                            <React.Fragment>
                                <Row className="simple-label">
                                    <Col>
                                        {t`flight_suggest.one_of_these_flights`}
                                    </Col>
                                </Row>
                                <Row className="simple-select">
                                    {Object.keys(suggestedFlights[index]).map(
                                        key => {
                                            return suggestedFlights[index][
                                                key
                                            ].map(item => {
                                                const isSelected =
                                                    flight.number ===
                                                        item.flight_number &&
                                                    flight.prefillLetters &&
                                                    flight.prefillLetters.toLowerCase() ===
                                                        item.airline_iata.toLowerCase()
                                                return (
                                                    <Col
                                                        key={`pre-index-${index}`}
                                                        className={`simple-item ${
                                                            isSelected
                                                                ? 'simple-selected'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            this.handlePreselect(
                                                                index,
                                                                item,
                                                                `pre-index-${index}`
                                                            )
                                                        }}
                                                    >
                                                        <span>
                                                            {item.flight_time} -{' '}
                                                            {
                                                                item.flight_arrive_time
                                                            }
                                                        </span>
                                                        <span
                                                            style={{
                                                                margin:
                                                                    '0px 6px',
                                                                lineHeight:
                                                                    '19px',
                                                                verticalAlign:
                                                                    'top',
                                                            }}
                                                        >
                                                            <img
                                                                src="/icons/oval.svg"
                                                                width="3"
                                                                height="3"
                                                                alt="oval"
                                                            />
                                                        </span>
                                                        <span>
                                                            {item.airline_title}
                                                        </span>
                                                        <span
                                                            style={{
                                                                margin:
                                                                    '0px 6px',
                                                                lineHeight:
                                                                    '19px',
                                                                verticalAlign:
                                                                    'top',
                                                            }}
                                                        >
                                                            <img
                                                                src="/icons/oval.svg"
                                                                width="3"
                                                                height="3"
                                                                alt="oval"
                                                            />
                                                        </span>
                                                        <span>
                                                            {item.airline_iata}{' '}
                                                            {item.flight_number}
                                                        </span>
                                                    </Col>
                                                )
                                            })
                                        }
                                    )}
                                </Row>
                            </React.Fragment>
                        )}
                    {flight.timeDeparture && (
                        <Row className="mb-10">
                            <Col xs={12} md={6} lg={6}>
                                <AirlineInput
                                    stickyOptionsList={true}
                                    stickyOptionsListTopPadding="1px"
                                    isFocusHighligh={true}
                                    borderRadius="12px"
                                    setRef={this.setRef}
                                    required
                                    refIndex={index}
                                    name="airline"
                                    id={`flight-${index + 1}-airline`}
                                    label={t`v2_common.label.airline`}
                                    errorText={
                                        errors &&
                                        errors[index] &&
                                        errors[index]['airline']
                                    }
                                    autoload={!!flight.airline}
                                    clear={() => this.clearAirline(index)}
                                    value={flight.airline || ''}
                                    onChange={e => this.handleChange(e, index)}
                                    onInputChange={inputValue =>
                                        this.handleAirlineInputChange(
                                            inputValue
                                        )
                                    }
                                    searchPromptText={this.getAirlineSearchPromptText()}
                                    onBlur={() =>
                                        this.handleAirlineInputChange('')
                                    }
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <V2FlightNumber
                                    required
                                    placeholder={t`v2_common.placeholder.flight_number`}
                                    name="number"
                                    id={`flight-${index + 1}-number`}
                                    label={t`v2_common.label.flight_number`}
                                    value={flight.number || ''}
                                    lettersName={'prefillLetters'}
                                    prefillLetters={flight.prefillLetters}
                                    errorText={
                                        errors &&
                                        errors[index] &&
                                        errors[index]['number']
                                    }
                                    onChange={e => this.handleChange(e, index)}
                                />
                            </Col>
                        </Row>
                    )}
                </FlightRow>
            )
        })
    }

    _handleNextStep = skipCheck => {
        const {
            setV2Claim,
            checkIfClaimable,
            lang,
            nextStep,
            showModal,
            validateStepFour,
            setProgress,
            setNotSupportedAirlines,
        } = this.props
        let newState = { ...this.state }

        validateStepFour(newState)

        this.fetchPayouts()

        newState.timeDeparture = _get(
            newState.flights,
            `[${newState.problemClaimFlight}].flightDate`,
            ''
        )

        setV2Claim(newState)

        if (!skipCheck) {
            checkIfClaimable(newState, lang).then(res => {
                const {
                    claimable,
                    airline_not_supported,
                    airlines_titles,
                    has_incidents,
                    incident_message,
                } = res
                this.setState({ claimableResponse: res })

                if (claimable) {
                    setProgress(70)
                    this.props.triggerAnalytics()
                    nextStep()
                    this._handleSaveDraft()
                } else if (airline_not_supported) {
                    this._handleSaveDraft()
                    setNotSupportedAirlines(airlines_titles)
                    browserHistory.replace(paths.V2.NOT_SUPPORTED_AIRLINES)
                    if (
                        window &&
                        window.hj &&
                        typeof window.hj === 'function'
                    ) {
                        window.hj('vpv', '/sorry')
                    }
                } else if (!!has_incidents) {
                    const claimableErrors = () => (
                        <ErrorContainer>
                            <RedExclamation />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t`${incident_message}`,
                                }}
                            />
                        </ErrorContainer>
                    )
                    this.setState({ claimableErrors })
                    showModal(modalTypes.sorryModal)
                    // eslint-disable-next-line
                    dataLayer.push({
                        event: 'ClaimNotEligible',
                    })
                    this._handleSaveDraft()
                    if (
                        window &&
                        window.hj &&
                        typeof window.hj === 'function'
                    ) {
                        window.hj('vpv', '/sorry')
                    }
                } else {
                    const errors = _get(res, 'reason.data')

                    this.setState({ claimableErrors: errors })
                    showModal(modalTypes.sorryModal)
                    // eslint-disable-next-line
                    dataLayer.push({
                        event: 'ClaimNotEligible',
                    })
                    this._handleSaveDraft()
                    if (
                        window &&
                        window.hj &&
                        typeof window.hj === 'function'
                    ) {
                        window.hj('vpv', '/sorry')
                    }
                }
            })
        }

        return false
    }

    _handlePrevStep = () => {
        const { setV2Claim } = this.props
        let newState = { ...this.state }

        this._handleSaveDraft()
        setV2Claim(newState)
        removeBeforeUnload()
    }

    render() {
        const { trans, nav, modal, errors } = this.props
        const { claimableErrors, reservationNumber } = this.state
        const resError = _get(errors, 'reservationNumber', null)

        if (!trans) return null

        return (
            <StepView
                sideBarPaddingTop={'0px'}
                nav={{ ...nav, nextDisabled: this.checkNextStep() }}
                Sidebar={
                    <SidebarProgress
                        activeIndex={3}
                        checkedIndexes={[0, 1, 2]}
                        showModalOnLoad={true}
                        paddingTop={0}
                    />
                }
                title={t`v2_claim.flight_details.title`}
                titleBottomPadding="16px"
                renderFPMobile={true}
                HeaderInfoBox={() => (
                    <TitleHeaderInfoBox
                        toggleOpen={isOpen => {
                            fireGtagEvent(
                                { event: 'step4_fd_information_we_need' },
                                true
                            )
                            this.isFaqOpen = isOpen
                            this.forceUpdate()
                        }}
                        isOpen={this.isFaqOpen}
                        header={t`FAQ.general.what_we_need`}
                        content={t`FAQ.flight_details.text`}
                    />
                )}
            >
                <Content>
                    {_get(modal, 'modalType', '') ===
                        modalTypes.flightSuggestModalCodeshares && (
                        <FlightSuggestModalCodeshares />
                    )}
                    {_get(modal, 'modalType', '') === modalTypes.sorryModal && (
                        <SorryModal
                            newflight={() => {
                                this.props.clearStepOne()
                                this.props.clearStepTwo()
                                this.props.clearStepThree()
                                this.props.clearStepFour()
                                this.resetState()
                            }}
                            errors={claimableErrors}
                        />
                    )}
                    {this.renderFlightDetails()}
                    <PageTitleStyledRow>
                        <PageTitle
                            title={t`flight_documents.add_booking_number`}
                            bottomPadding="22px"
                        />
                    </PageTitleStyledRow>
                    {isMobileDevice() && (
                        <Row>
                            <Col
                                sm={12}
                                md={12}
                                xs={12}
                                style={{ width: '100%' }}
                            >
                                <TitleHeaderInfoBox
                                    style={{ marginTop: 0 }}
                                    ignoreLang={true}
                                    fullWidth
                                    toggleOpen={isOpen => {
                                        this.isFaqOpen = isOpen
                                        this.forceUpdate()
                                    }}
                                    isOpen={this.isFaqOpen}
                                    header={t`FAQ.flight_documents.find_docs`}
                                    HtmlContent={() => (
                                        <React.Fragment>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t`v2_claim.flight_documents.tooltip_text2`,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    marginBottom: 8,
                                                }}
                                            >
                                                <img
                                                    src="/images/reservation-number.svg"
                                                    width="100%"
                                                    alt="reservation-number"
                                                />
                                            </div>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t`v2_claim.flight_documents.bottom_tooltip_text2`,
                                                }}
                                            />
                                        </React.Fragment>
                                    )}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <InputField
                            borderRadius="12px"
                            LabelComponent={() => (
                                <label>
                                    <StyledP>
                                        {t`v2_common.label.reservation_number`}
                                        {!isMobileDevice() && (
                                            <TooltipIconWrapper
                                                data-tip=""
                                                data-for="question"
                                                id="question-mark"
                                            >
                                                <QuestionMarkTooltip />
                                            </TooltipIconWrapper>
                                        )}
                                    </StyledP>
                                    {!isMobileDevice() && (
                                        <ReactTooltip
                                            place="top"
                                            id="question"
                                            className="styled-tooltip"
                                            effect="solid"
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t`v2_claim.flight_documents.tooltip_text`,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    marginBottom: 8,
                                                }}
                                            >
                                                <img
                                                    src="/images/reservation-number.svg"
                                                    width="100%"
                                                    alt="reservation-number"
                                                />
                                            </div>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t`v2_claim.flight_documents.bottom_tooltip_text2`,
                                                }}
                                            />
                                        </ReactTooltip>
                                    )}
                                </label>
                            )}
                            warning={true}
                            maxLength={10}
                            onChange={this.handleReservationChange}
                            value={reservationNumber}
                            id="reservation-number"
                            name="reservationNumber"
                            placeholder={t`v2_common.placeholder.reservation_number`}
                            errorText={resError ? t`${resError}` : null}
                        />
                    </Row>
                </Content>
            </StepView>
        )
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        V2Claim: state.V2Claim,
        hasFastPayout: state.v2_hasFastPayout,
        errors: state.v2_step_four_errors.errors,
        trans: state.translations,
        lang: state.language,
        usedAirlines: state.v2_UsedAirlines,
        v2_amount: state.v2_amount,
        modal: state.toggle_modal,
        idAssets: state.idAssets,
        claimDraft: state.claimDraft,
        claimPreloaded: state.claimPreloaded,
        authenticated: state.auth.authenticated,
        draftEmail: state.draftEmail,
        regulation: state.regulation,
        suggestedFlights: state.suggestedFlights,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setV2Claim,
            validateStepFour,
            setSelectedAirlines,
            postCodeFieldCheck,
            setProgress,
            showModal,
            checkIfClaimable,
            nextStep,
            clearStepOne,
            clearStepTwo,
            clearStepThree,
            clearStepFour,
            getPayoutPlans,
            setClaimDraftProps,
            getAirlines,
            setUsedAirlines,
            setPreloadedClaimStep,
            setNotSupportedAirlines,
            saveClaimDraft,
            getSuggestedFlights,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(FlightDetails)
