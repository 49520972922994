import { CLAIM } from '../actions/actionTypes';


export default function (state = null, action) {
    switch (action.type) {
        case CLAIM:
            return { ...state, data: action.payload};
        default:
    }
    return state;
};