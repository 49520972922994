import { THEME_CONFIG } from '../actions/actionTypes';
import { defaultConfig } from '../theme/config';

const INITIAL_STATE = defaultConfig;

export default function (state = INITIAL_STATE, action) {
    //console.log("from decuer", { colors: { ...state.colors, ...action.payload.colors }, logo: { ...state.colors, ...action.payload.colors } });
    switch (action.type) {
        case THEME_CONFIG:
            return { colors: { ...state.colors, ...action.payload.colors }, logo: { ...state.logo, ...action.payload.logo } };
        default:
    }
    return state;
}