import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from "react-helmet";
import styled from "styled-components";
import { loginPageShow, loginPageHide, loadScreenOff, loadScreenOn } from '../../../main';
import { setNewPass, clearErrors } from '../../../shared/actions/index';
import { browserHistory } from 'react-router';
import t from '../../../shared/translations';
import { tablet, mobile } from '../../../shared/components/helpers/styled_queries';
import { PageWrapper, Title } from '../../V2/steps/common';
import { LoginContainer, DesktopBtn } from '../../V2/components/LoginModal';
import { Row, Col } from '../../../shared/components/V2/Layout';
import { NonModalCloseButton } from '../../../shared/components/V2/V2Buttons';
import InputField, { ErrorTextBox } from '../../../shared/components/V2/InputField';

export const NewPasswordWrapper = styled(PageWrapper)`
    margin: 24px auto;
    height: fit-content;
    padding: 24px;

    ${tablet`
        padding: 16px;
    `}

    ${mobile`
        padding: 8px;
    `}
`;

const StyledDesktopBtn = styled(DesktopBtn)`
    ${mobile`
        width: 100%;
    `}
`;

var selectErrors = {};

class NewPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPass: "",
            newPassRepeat: "",
            userId: "",
            token: "",
            isCentered: true,
        };
    }

    _resize = () => {
        if (this.state.isCentered && window.innerWidth < 961) {
            this.props.setParentState({
                isCentered: false,
            });
            this.setState({
                isCentered: false,
            });
        } else if (!this.state.isCentered && window.innerWidth >= 960) {
            this.props.setParentState({
                isCentered: true,
            });
            this.setState({
                isCentered: true,
            });
        }
    }

    componentDidMount() {
        const { location, setParentState } = this.props;
        loginPageShow(true);
        window.addEventListener("resize", this._resize);

        if (location.query) {
            setParentState({
                isCentered: true,
                hideNav: true,
            });

            const { query } = location;
            if (query["userId"] && query["token"]) {
                this.setState({
                    userId: query["userId"],
                    token: query["token"],
                });
            } else {
                browserHistory.push("/");
            }
        } else {
            browserHistory.push("/");
        }
        loadScreenOff();
    }

    componentWillMount() {
        loginPageShow(true);
        loadScreenOn();
    }

    componentWillUnmount() {
        loginPageHide();
        window.removeEventListener("resize", this._resize);
        this.props.setParentState({
            isCentered: false,
            hideNav: false,
        });
    }

    checkMinLength = (field, minLength) => {
        if (this.state[field] && this.state[field].length < minLength) {
            selectErrors[field] = t`common.label.too_short.password`;
        }
    }

    validateSelect = (checkLength = false) => {
        const { newPass, newPassRepeat } = this.state;
        selectErrors = {};

        if (!newPass || newPass.trim() === "") {
            selectErrors.newPass = t`pass_missing`;
        }
        if (!newPassRepeat || newPassRepeat.trim() === "") {
            selectErrors.newPassRepeat = t`pass_missing`;
        }
        if (newPass && newPassRepeat && newPass !== newPassRepeat) {
            selectErrors.newPassRepeat = t`common.error.passwords_dont_match`;
        } else {
            delete selectErrors.passwords_dont_match;
        }

        if (checkLength) {
            this.checkMinLength("newPass", 8);
            this.checkMinLength("newPassRepeat", 8);
        }

        this.setState({ errors: "true" });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.validateSelect(true);
        const { newPass, userId, token } = this.state;

        if (Object.keys(selectErrors).length === 0 && selectErrors.constructor === Object) {
            this.props.setNewPass(newPass, userId, token);
        }
    }

    validateNewPass = (e) => {
        const { target } = e;
        this.setState({ [target.name]: target.value }, () => {
            // eslint-disable-next-line no-restricted-globals
            if (selectErrors[event.target.name] && event.target.value && event.target.value !== "") {
                // eslint-disable-next-line no-restricted-globals
                delete selectErrors[event.target.name];
            }
            this.validateSelect();
            this.props.clearErrors();
        });
    }

    handleCancel = () => {
        browserHistory.push("/");
    }

    render() {
        const { errorMessage, trans } = this.props;
        const { newPass, newPassRepeat } = this.state;

        if (!trans) {
            return null;
        }

        return (
            <NewPasswordWrapper>
                <LoginContainer style={{ overflow: 'unset' }}>
                    <Helmet>
                        <title>{`Skycop - ${t`reset_pass.title.reset_password`}`}</title>
                    </Helmet>
                    <NonModalCloseButton onClick={this.handleCancel} />
                    <Row>
                        <Col lg={12}>
                            <Title>
                                {t`reset_pass.title.reset_password`}
                            </Title>
                        </Col>
                    </Row>
                    <form onSubmit={(e) => { e.preventDefault(); }}>
                        <Row>
                            <InputField
                                sm={12}
                                md={12}
                                lg={12}
                                label={t`my_profile.label.new_password`}
                                placeholder={t`my_profile.label.new_password`}
                                required
                                name="newPass"
                                type="password"
                                ref="newPass"
                                id="new-password"
                                onChange={this.validateNewPass}
                                value={newPass}
                                errorText={selectErrors && selectErrors.newPass}
                                autoComplete="new-password"
                            />
                        </Row>
                        <Row>
                            <InputField
                                sm={12}
                                md={12}
                                lg={12}
                                label={t`my_profile.label.repeat_new_password`}
                                placeholder={t`my_profile.label.repeat_new_password`}
                                required
                                name="newPassRepeat"
                                type="password"
                                ref="newPassRepeat"
                                id="new-password-repeat"
                                onChange={this.validateNewPass}
                                value={newPassRepeat}
                                errorText={selectErrors && selectErrors.newPassRepeat}
                                autoComplete="new-password"
                            />
                        </Row>
                        <Row>
                            {errorMessage &&
                                <Col sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <ErrorTextBox>{t`${errorMessage}`}</ErrorTextBox>
                                </Col>
                            }
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <StyledDesktopBtn
                                    id="reset-submit"
                                    style={{ margin: "auto" }}
                                    type="submit"
                                    onClick={this.onSubmit}
                                >
                                    {t`reset_pass.button.send_new_pass`}
                                </StyledDesktopBtn>
                            </Col>
                        </Row>
                    </form>
                </LoginContainer>
            </NewPasswordWrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        errorMessage: state.error.errorMessage,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setNewPass, clearErrors }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
