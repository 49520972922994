import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case V2_ACTIONS.POSTED_CLAIM:
            return payload;
        case V2_ACTIONS.RESET_POSTED_CLAIM:
            return INITIAL_STATE
        default:
    }
    return state;
}