import React, { Component } from 'react'
import t from '../../../../shared/translations'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import { PageContent, Row, Col } from '../../../../shared/components/V2/Layout'
import { MainContainer } from '../../../other/ClaimAgreementLead/styles'
import styled from 'styled-components'
import InputField from '../../../../shared/components/V2/InputField'
import { bindActionCreators } from 'redux'
import CountryInput from '../CountryInput'
import CurrencyInput from '../CurrencyInput'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import { featureToggles, modalTypes } from '../../../../shared/constants'
import { showModal } from '../../../../shared/actions/v2_actions'
import SubmitModal from './SubmitModal'
import { values, isEmpty } from 'lodash'
import {
    getCurrencies,
    getUserData,
    getClaimOffer,
    acceptClaimOffer,
} from '../../../../shared/actions'
import {
    getApiClient,
    loadScreenOn,
    applyTranslationElement,
    isFeatureEnabled,
} from '../../../../main'
import InfoModal from './InfoModal'

let selectErrors = {}

const Title = styled.div`
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 28px;
    color: #353d51;
    text-align: center;
`

const CountryInputCol = styled(Col)`
    div.form-group {
        margin-bottom: 0;
    }
`

const CurrencyInputCol = styled(Col)`
    div.form-group {
        margin-bottom: 0;
    }
`

class PayoutOfferSendout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ibanLabel: 'payment_page.iban',
            isAccepted: false,
            bankCountry: '',
            currency: '',
            bankTitle: '',
            holderName: '',
            iban: '',
            swift: '',
            offerPercentage: null,
            payoutOfferId: null,
            isCountrySupportIban: true,
            ibanValid: true,
        }
    }

    componentDidMount() {
        const {
            getCurrencies,
            getUserData,
            getClaimOffer,
            showModal,
        } = this.props
        const urlParams = new URLSearchParams(window.location.search)
        loadScreenOn()
        getCurrencies()
        const claimId = urlParams.get('claim_id')
        if (claimId) {
            getClaimOffer(claimId).then(res => {
                if (!res.error) {
                    this.setState({ offerPercentage: res.percentages })
                    this.setState({ payoutOfferId: res.payoutOfferId })
                } else {
                    showModal(modalTypes.payoutOfferInfoModal, {
                        message: res.message,
                    })
                }
            })
        }

        getUserData().then(response => {
            if (response) {
                const mappedUserData = this.mapUser(response)
                this.setState(prevState => ({
                    ...prevState,
                    ...mappedUserData,
                }))
            }
        })
    }

    mapUser(data) {
        return {
            holderName: _get(data, 'user_payment.receiver_name', ''),
            bankCountry: _get(data, 'user_payment.bank_country.id', ''),
            currency: _get(data, 'user_payment.currency_country.id', ''),
            bankTitle: _get(data, 'user_payment.bank_name', ''),
            iban: _get(data, 'user_payment.iban', ''),
            swift: _get(data, 'user_payment.swift', ''),
        }
    }

    checkRequired = (field, translation) => {
        if (!this.state[field] || this.state[field].trim() === '') {
            selectErrors[field] = translation
        }
    }

    validateSubmit = () => {
        const {
            iban,
            bankCountry,
            bankTitle,
            swift,
            currency,
            holderName,
            isCountrySupportIban,
            ibanValid,
        } = this.state
        selectErrors = {}
        if (
            bankCountry ||
            bankTitle ||
            swift ||
            currency ||
            holderName ||
            iban
        ) {
            if (!iban || (isCountrySupportIban && !ibanValid)) {
                selectErrors['iban'] = t`invalid.iban.number`
            }
            if (!holderName) {
                this.checkRequired(
                    'holderName',
                    t`common.label.is_required.account_holder_name`
                )
            }
            if (holderName && holderName.match('[^a-zA-Z\\s]')) {
                selectErrors[
                    'holderName'
                ] = t`common.label.is_required.latin_only`
            }

            this.validateSwift()
        }

        this.setState({ errors: selectErrors.length > 0 })
    }

    isDisabled = () => {
        const {
            bankCountry,
            currency,
            bankTitle,
            holderName,
            iban,
            swift,
        } = this.state
        if (
            !bankCountry ||
            !currency ||
            !bankTitle ||
            !holderName ||
            !iban ||
            !swift ||
            !values(selectErrors).every(isEmpty)
        ) {
            return true
        }
        return false
    }

    handleSubmit = () => {
        const { showModal, lang, acceptClaimOffer } = this.props
        const {
            bankCountry,
            currency,
            bankTitle,
            holderName,
            iban,
            swift,
            payoutOfferId,
        } = this.state

        this.validateSubmit()

        if (!values(selectErrors).every(isEmpty)) return

        this.setState({ isAccepted: true })
        const fd = new FormData()

        fd.append('user_payment[iban]', iban)
        fd.append('user_payment[swift]', swift)
        fd.append('user_payment[receiverName]', holderName)
        fd.append('user_payment[bankName]', bankTitle)
        fd.append('user_payment[bankCountry]', bankCountry)
        fd.append('user_payment[currencyCountry]', currency)

        acceptClaimOffer(payoutOfferId, fd, lang).then(res => {
            if (res.isSuccess === true) {
                showModal(modalTypes.payoutOfferSubmitModal)
            } else if (!res.isFormErrors) {
                showModal(modalTypes.payoutOfferInfoModal, {
                    message: res.message,
                })
            } else if (res.isFormErrors) {
                selectErrors = res.form
                    ? Object.keys(res.form).reduce((acc, curr) => {
                          return {
                              ...acc,
                              [curr]: res.form[curr][0],
                          }
                      }, {})
                    : {}
                this.setState({ isAccepted: true })
            }
        })
    }

    handleReject = () => {
        const { showModal } = this.props
        this.setState({ isAccepted: false })
        showModal(modalTypes.payoutOfferSubmitModal)
    }

    validateSuggestedIban = value => {
        if (!isFeatureEnabled(featureToggles.featureIsBankPrefillEnabled))
            return

        const apiClient = getApiClient()
        const url = '/api/iban/info'

        apiClient
            .post(url, { iban: value })
            .then(({ data }) => {
                if (data.bic) {
                    this.setState({
                        swift: data.bic,
                    })
                }
                if (data.bankTitle) {
                    this.setState({
                        bankTitle: data.bankTitle,
                    })
                }
            })
            .catch(e => console.error(e))
    }

    validateIban = target => {
        const { isCountrySupportIban } = this.state
        const apiClient = getApiClient()
        const url = '/api/validate/iban'
        const fd = new FormData()

        fd.append('iban', target.value)
        apiClient
            .post(url, fd)
            .then(res => {
                this.setState({ needManualValidation: false, ibanValid: true })
                if (res.data.status === 'valid') {
                    delete selectErrors[target.name]
                }
                if (
                    isCountrySupportIban &&
                    (res.data.status === 'invalid' ||
                        res.data.status === 'invalid_country')
                ) {
                    selectErrors[target.name] = t`invalid.iban.number`
                    this.setState({ ibanValid: false })
                }
                if (
                    !isCountrySupportIban &&
                    res.data.status === 'invalid_country'
                ) {
                    this.setState({
                        needManualValidation: true,
                        ibanValid: false,
                    })
                }

                this.setState({ errors: selectErrors.length > 0 })
            })
            .catch(err => {
                console.warn(err)
            })
    }

    accountLabel = () => {
        return `${t`${this.state.ibanLabel}`}`
    }

    checkBankCountry = countryCode => {
        const url = '/api/validate/iban-country'
        const apiClient = getApiClient()
        const fd = new FormData()
        fd.append('country_code', countryCode)
        apiClient
            .post(url, fd)
            .then(res => {
                this.setState({
                    ibanLabel:
                        res.data.status === 'valid'
                            ? 'payment_page.iban'
                            : 'payment_page.account',
                    isCountrySupportIban: res.data.status === 'valid',
                    needManualValidation: res.data.status !== 'valid',
                })
            })
            .catch(err => {
                console.warn(err)
            })
    }

    validateSwift = () => {
        const { swift } = this.state

        if (!isFeatureEnabled(featureToggles.isSwiftValidationEnabled)) return

        if (!(swift.length > 7 && swift.length < 12)) {
            selectErrors['swift'] = t`commons.label.swift_code_length`
            return
        }

        const onlyLettersAndNumbers = /^\w+$/
        if (!onlyLettersAndNumbers.test(swift)) {
            selectErrors['swift'] = t`commons.label.swift_code_only_latin`
            return
        } else {
            selectErrors['swift'] = null
            return
        }
    }

    handleChangeEvent = e => {
        const { ibanValid, isCountrySupportIban } = this.state
        if (!e) return

        const target = e.target || e

        if ('bankCountry' === target.name) {
            this.checkBankCountry(target.code)
        }

        selectErrors[target.name] = null
        const onlyLetters = /^[a-zA-Z\s]*$/
        const targetValue = target.value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ +(?= )/g, '')
            .replace(/[^a-zA-Z\d\s]/g, '')

        if (
            target.name !== 'iban' &&
            target.name !== 'swift' &&
            target.name !== 'bankCountry' &&
            target.name !== 'currency' &&
            target.name !== 'bankTitle'
        ) {
            if (onlyLetters.test(targetValue)) {
                selectErrors[target.name] = null
                this.setState({
                    [target.name]: targetValue,
                })
            } else {
                selectErrors[
                    target.name
                ] = t`common.label.is_required.latin_only`
            }
        }

        if (
            target.name === 'bankCountry' ||
            target.name === 'currency' ||
            target.name === 'bankTitle'
        ) {
            this.setState({
                [target.name]: target.value,
            })
            return
        }

        if (
            (target.name === 'swift' || target.name === 'iban') &&
            isFeatureEnabled(featureToggles.isSwiftValidationEnabled)
        ) {
            this.setState({
                [target.name]: targetValue
                    .toUpperCase()
                    .replace(/[^a-zA-Z0-9]/g, ''),
            })
        } else {
            this.setState({
                [target.name]: targetValue,
            })
        }

        if (isCountrySupportIban && !ibanValid) {
            selectErrors['iban'] = t`invalid.iban.number`
        }
    }

    clearBankCountry = () => {
        this.setState({
            bankCountry: '',
            isCountrySupportIban: true,
            needManualValidation: false,
        })
    }

    clearAccountCurrency = () => {
        this.setState({ currency: '' })
    }

    render() {
        const { trans } = this.props
        const {
            isAccepted,
            bankTitle,
            bankCountry,
            iban,
            currency,
            holderName,
            swift,
            offerPercentage,
        } = this.state

        if (!trans) return null

        return (
            <MainContainer>
                <PageContent>
                    <Title>
                        {applyTranslationElement(
                            t`payout.page.title`,
                            '{{ payoutPercentage }}',
                            offerPercentage
                        )}
                    </Title>
                    <Row className="h-mb-8 h-mt40">
                        <InputField
                            name="bankTitle"
                            label={t`payment_page.bank_name`}
                            placeholder={t`payment_page.placeholder.bank_name`}
                            value={bankTitle}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['bankTitle']}
                            autoComplete="bankTitle"
                            required
                        />
                        <InputField
                            name="holderName"
                            label={t`payment_page.holder_name`}
                            placeholder={t`payment_page.placeholder.receiver_name`}
                            value={holderName}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['holderName']}
                            autoComplete="holderName"
                            required
                        />
                    </Row>
                    <Row className="h-mb-8">
                        <CountryInputCol xs={12} md={12} lg={6}>
                            <CountryInput
                                name="bankCountry"
                                label={t`payment_page.bank_country`}
                                placeholder={t`payment_page.placeholder.country`}
                                value={bankCountry}
                                defaultValue={bankCountry || 'lt'}
                                onChange={this.handleChangeEvent}
                                errorText={selectErrors['bankCountry']}
                                clear={this.clearBankCountry}
                                autoComplete="bankCountry"
                                required
                            />
                        </CountryInputCol>
                        <CurrencyInputCol xs={12} md={12} lg={6}>
                            <CurrencyInput
                                value={currency}
                                onChange={e => {
                                    this.handleChangeEvent(e)
                                }}
                                clear={this.clearAccountCurrency}
                                name="currency"
                                placeholder={t`payment_page.placeholder.currency`}
                                label={t`payment_page.currency`.replace(
                                    '*',
                                    ''
                                )}
                                errorText={selectErrors['currency']}
                                id="currency"
                                required
                            />
                        </CurrencyInputCol>
                    </Row>

                    <Row className="h-mb-8">
                        <InputField
                            name="iban"
                            label={this.accountLabel()}
                            placeholder={t`payment_page.placeholder.iban`}
                            value={iban}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['iban']}
                            autoComplete="iban"
                            required
                            onBlur={e =>
                                this.validateSuggestedIban(e.target.value)
                            }
                        />
                        <InputField
                            name="swift"
                            label={t`payment_page.swift`}
                            placeholder={t`payment_page.placeholder.swift`}
                            value={swift}
                            onChange={this.handleChangeEvent}
                            errorText={selectErrors['swift']}
                            autoComplete="swift"
                            required
                        />
                    </Row>
                    <Row
                        className="h-mt40"
                        style={{ justifyContent: 'center' }}
                    >
                        <Col md={4} lg={4}>
                            <V2Button
                                onClick={this.handleSubmit}
                                disabled={this.isDisabled()}
                            >
                                {t`payout.accept.btn`}
                            </V2Button>
                        </Col>
                        <Col md={4} lg={4}>
                            <V2Button
                                type="borderBtn"
                                onClick={this.handleReject}
                            >
                                {t`payout.decline.btn`}
                            </V2Button>
                        </Col>
                    </Row>
                </PageContent>
                <SubmitModal isAccepted={isAccepted} />
                <InfoModal />
            </MainContainer>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            getCurrencies,
            getUserData,
            getClaimOffer,
            acceptClaimOffer,
        },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        currencies: state.currencies,
        countries: state.countries,
        lang: state.language,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutOfferSendout)
