import { V2_ACTIONS } from '../../actions/actionTypes'
import {
    isValidEmail,
    isValidLatinChars,
    isValidSpecialChars,
} from '../../../main'

const INITIAL_STATE = {
    validations: {
        user: {
            name: {
                type: 'string',
                required: true,
                validLatin: true,
                errorMessages: {
                    required_error: 'common.label.is_required.name',
                    validLatin_error: 'common.label.is_required.latin_only',
                },
            },
            surname: {
                type: 'string',
                required: true,
                validLatin: true,
                errorMessages: {
                    required_error: 'common.label.is_required.surname',
                    validLatin_error: 'common.label.is_required.latin_only',
                },
            },
            birthdate: {
                type: 'string',
                required: true,
                errorMessages: {
                    required_error: 'common.label.is_required.birthdate',
                },
            },
            email: {
                type: 'string',
                required: true,
                emailValid: true,
                errorMessages: {
                    required_error: 'common.label.is_required.email',
                    emailValid_error: 'common.label.email_invalid',
                },
            },
            address: {
                type: 'string',
                required: true,
                skipTypeCheck: true,
                validSymbols: true,
                errorMessages: {
                    required_error: 'common.label.is_required.address',
                    validSymbols_error:
                        'common.label.is_required.special_symbols',
                },
            },
            city: {
                type: 'string',
                required: true,
                skipTypeCheck: true,
                validSymbols: true,
                errorMessages: {
                    required_error: 'common.label.is_required.city',
                    validSymbols_error:
                        'common.label.is_required.special_symbols',
                },
            },
            country: {
                type: 'string',
                required: true,
                skipTypeCheck: true,
                errorMessages: {
                    required_error: 'common.label.is_required.country',
                },
            },
            phone: {
                type: 'string',
                required: true,
                errorMessages: {
                    required_error: 'common.label.is_required.phone',
                },
            },
        },
        passengers: {
            name: {
                type: 'string',
                required: true,
                validLatin: true,
                errorMessages: {
                    required_error: 'common.label.is_required.name',
                    validLatin_error: 'common.label.is_required.latin_only',
                },
            },
            surname: {
                type: 'string',
                required: true,
                validLatin: true,
                errorMessages: {
                    required_error: 'common.label.is_required.surname',
                    validLatin_error: 'common.label.is_required.latin_only',
                },
            },
            birthdate: {
                type: 'string',
                required: true,
                errorMessages: {
                    required_error: 'common.label.is_required.birthdate',
                },
            },
            email: {
                type: 'string',
                required: false,
                errorMessages: {
                    required_error: 'common.label.is_required.email',
                },
            },
            phone: {
                type: 'string',
                required: false,
                errorMessages: {
                    required_error: 'common.label.is_required.phone',
                },
            },
            legalGuardianFullName: {
                type: 'string',
                checkIfValueExist: true,
                validLatin: true,
                errorMessages: {
                    required_error: 'common.label.is_required.name',
                    validLatin_error: 'common.label.is_required.latin_only',
                },
            },
        },
    },
    errors: {},
}

const validateUserInputs = (validations, payload, checkRequired = true) => {
    return Object.keys(validations).reduce((errors, validator) => {
        if (checkRequired && !payload[validator]) {
            errors[validator] =
                validations[validator].errorMessages.required_error
        }

        // if (!validations[validator].skipTypeCheck && typeof payload[validator] !== validations[validator].type) {
        //     errors[validator] = validations[validator].errorMessages.type_error;
        // }

        if (
            validations[validator].emailValid &&
            !isValidEmail(payload[validator])
        ) {
            errors[validator] =
                validations[validator].errorMessages.emailValid_error
        }

        if (
            validations[validator].validLatin &&
            !isValidLatinChars(payload[validator])
        ) {
            errors[validator] =
                validations[validator].errorMessages.validLatin_error
        }

        if (
            validations[validator].validSymbols &&
            !isValidSpecialChars(payload[validator])
        ) {
            errors[validator] =
                validations[validator].errorMessages.validSymbols_error
        }

        return errors
    }, {})
}

const validatePassengersInputs = (
    validations,
    payload,
    checkRequired = true
) => {
    let errors = []

    payload.forEach((item, i) => {
        const err = Object.keys(validations).reduce((errors, validator) => {
            if (
                checkRequired &&
                validations[validator].required &&
                !item[validator]
            ) {
                errors[validator] =
                    validations[validator].errorMessages.required_error
            }

            if (
                typeof item[validator] !== validations[validator].type &&
                !validations[validator].checkIfValueExist
            ) {
                errors[validator] =
                    validations[validator].errorMessages.type_error
            }

            if (
                validations[validator].emailValid &&
                !isValidEmail(item[validator])
            ) {
                errors[validator] =
                    validations[validator].errorMessages.emailValid_error
            }

            if (validations[validator].checkIfValueExist) {
                if (
                    item[validator] !== undefined &&
                    validations[validator].validLatin &&
                    !isValidLatinChars(item[validator])
                ) {
                    errors[validator] =
                        validations[validator].errorMessages.validLatin_error
                }
            } else {
                if (
                    validations[validator].validLatin &&
                    !isValidLatinChars(item[validator])
                ) {
                    errors[validator] =
                        validations[validator].errorMessages.validLatin_error
                }
            }

            return errors
        }, {})

        if (err && !!Object.keys(err).length) {
            errors.push({
                ...err,
                paxId: i,
            })
        }
    })
    return errors
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_STEP_SIX_ERRORS:
            const user = validateUserInputs(
                state.validations.user,
                action.payload.user,
                action.checkRequired
            )

            const passengers = validatePassengersInputs(
                state.validations.passengers,
                action.payload.passengers,
                action.checkRequired
            )
            return {
                ...state,
                errors: {
                    user,
                    passengers,
                },
            }
        default:
    }

    return state
}
