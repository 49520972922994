import React from 'react'

export const DeparturePlaneIcon = () => (
    <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_271_378)">
            <path
                d="M14.625 11H0.375006C0.167818 11 5.5887e-06 11.1678 5.5887e-06 11.375V12.125C5.5887e-06 12.3322 0.167818 12.5 0.375006 12.5H14.625C14.8322 12.5 15 12.3322 15 12.125V11.375C15 11.1678 14.8322 11 14.625 11ZM1.8879 8.4985C2.03508 8.65881 2.2418 8.74975 2.45813 8.74952L5.51766 8.7453C5.75911 8.74498 5.9971 8.68781 6.21235 8.57842L13.0317 5.11788C13.6584 4.79983 14.2202 4.34585 14.6025 3.75124C15.0316 3.08374 15.0783 2.60069 14.9088 2.25944C14.7399 1.91795 14.329 1.66717 13.5436 1.61608C12.844 1.57061 12.1481 1.75483 11.5214 2.07264L9.21258 3.24428L4.0868 1.321C4.02517 1.27948 3.95357 1.25518 3.8794 1.25061C3.80523 1.24603 3.73119 1.26135 3.66493 1.29499L2.12391 2.0771C1.87383 2.20389 1.81336 2.53764 2.00274 2.74577L5.66415 5.04499L3.24516 6.27264L1.54946 5.41788C1.49104 5.38842 1.42651 5.37312 1.36108 5.3732C1.29566 5.37328 1.23117 5.38875 1.17282 5.41835L0.232271 5.89577C-0.0124163 6.01999 -0.0768694 6.34389 0.101724 6.55389L1.8879 8.4985Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_271_378">
                <rect
                    width="15"
                    height="12"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
)

export const ArrivalPlaneIcon = () => (
    <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_271_382)">
            <path
                d="M14.625 11H0.375C0.167812 11 0 11.1678 0 11.375V12.125C0 12.3322 0.167812 12.5 0.375 12.5H14.625C14.8322 12.5 15 12.3322 15 12.125V11.375C15 11.1678 14.8322 11 14.625 11ZM1.05023 5.32014L3.13008 7.19514C3.30073 7.34924 3.50522 7.46107 3.72703 7.52163L10.4677 9.35796C11.0883 9.52694 11.7464 9.56233 12.3661 9.38983C13.0615 9.196 13.3842 8.89272 13.4735 8.55288C13.5633 8.21303 13.433 7.78624 12.9258 7.26475C12.4739 6.80022 11.8863 6.49624 11.2657 6.32725L8.98031 5.70475L6.62812 1.20827C6.59273 1.0721 6.48867 0.965691 6.35484 0.929128L4.8293 0.513582C4.5818 0.446082 4.33945 0.638269 4.34391 0.898894L5.46703 4.74757L3.07172 4.09507L2.42508 2.50413C2.37984 2.38952 2.28422 2.30327 2.1668 2.27139L1.23563 2.01757C0.993281 1.95147 0.754453 2.13475 0.75 2.38975L0.755391 4.77522C0.759844 4.98405 0.896719 5.18163 1.05023 5.32014Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_271_382">
                <rect
                    width="15"
                    height="12"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
)
