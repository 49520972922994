import { SUPPORTED_POSTCODES } from '../actions/actionTypes';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SUPPORTED_POSTCODES:
            return action.payload;
        default:
    }
    return state;
}