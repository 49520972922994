import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    disruptionReason: null,
    providedReason: null,
    comment: null,
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_DISRUPTION_REASON:
            return {...state, dsruptionReason: action.payload};
        case V2_ACTIONS.SET_PROVIDED_REASON:
            return {...state, providedReason: action.payload};
        case V2_ACTIONS.SET_COMMENT:
            return {...state, comment: action.payload};
        case V2_ACTIONS.CLEAR_DISRUPTION_REASON:
            return {...state, dsruptionReason: null};
        case V2_ACTIONS.CLEAR_PROVIDED_REASON:
            return {...state, providedReason: null};
        case V2_ACTIONS.CLEAR_COMMENT:
            return {...state, comment: null};
        default:
    }
    return state;
}