import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { modalTypes } from '../../../../shared/constants'
import Modal from '../../../../shared/components/V2/Modal'
import { showModal, hideModal } from '../../../../shared/actions/v2_actions'
import { CloseButton } from '../../../../shared/components/V2/V2Buttons'
import { Row, Col } from '../../../../shared/components/V2/Layout'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'
import t from '../../../../shared/translations'

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
`

const Content = styled.div`
    margin-top: 36px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 28px;
    color: #353d51;
    text-align: center;
`

class SubmitModal extends React.Component {
    onModalClose = () => {
        const { hideModal } = this.props
        hideModal()
        window.location.href = 'https://www.skycop.com/'
    }

    render() {
        const { isAccepted } = this.props
        return (
            <Modal
                width="476px"
                height="auto"
                modalType={modalTypes.payoutOfferSubmitModal}
                onEscClick={this.onModalClose}
                onEnterClick={this.onModalClose}
                handleClose={this.onModalClose}
            >
                <Container>
                    <CloseButton onClick={this.onModalClose} />
                    <Content>
                        {isAccepted
                            ? t`payout.accepted.offer.modal.description`
                            : t`payout.declined.offer.modal.description`}
                    </Content>
                    <Row
                        className="h-mt40"
                        style={{ justifyContent: 'center' }}
                    >
                        <Col md={6} lg={6}>
                            <V2Button onClick={this.onModalClose}>
                                {t`payout.offer.modal.close.btn`}
                            </V2Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModal,
            hideModal,
        },
        dispatch
    )
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitModal)
