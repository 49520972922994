import React from 'react'
import styled from 'styled-components'
import { v2DefaultConfig } from '../../../shared/theme/config'
import { desktop } from '../../../shared/components/helpers/styled_queries'

const ReferalDiv = styled.div`
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    span {
        color: ${v2DefaultConfig.colors.darkText};
        font-weight: bold;
        cursor: pointer;

        &:hover {
            color: ${({ theme }) => theme.colors.primaryColor};
            filter: brightness(110%);
        }
        padding-left: 5px;
    }
    img {
        width: 42px;
        height: 42px;
        position: relative;
        top: -7px;
    }

    @media (max-width: 1115px) {
        justify-content: center;
    }

    ${desktop`
        text-align: right;
    `}
`

const ReferalShortDiv = styled.div`
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: 20.8px;
    padding-right: 20px;
    cursor: pointer;
    color: ${v2DefaultConfig.colors.darkText};
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: ${v2DefaultConfig.colors.darkText};
        font-weight: 600;

        &:hover {
            color: ${({ theme }) => theme.colors.primaryColor};
            filter: brightness(110%);
        }
    }
    img {
        width: 38px;
        height: 38px;
        position: relative;
        top: -3px;
    }
    width: 100%;

    @media (max-width: 1115px) {
        display: none;
    }
`

const ReferalLink = ({ text, linkText, handleReferralClick, ...props }) => (
    <ReferalDiv>
        <object type="image/svg+xml" data="/icons/promotion.svg">
            referal promotion
        </object>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
                style={{
                    paddingLeft: '5px',
                    fontWeight: 'normal',
                    display: 'inline',
                    whiteSpace: 'nowrap',
                }}
            >
                {text}
            </div>
            <span
                style={{
                    whiteSpace: 'nowrap',
                }}
                onClick={() => handleReferralClick()}
            >
                {linkText}
            </span>
        </div>
    </ReferalDiv>
)

const ReferalShortLink = ({ linkText, handleReferralClick, ...props }) => (
    <ReferalShortDiv>
        <object type="image/svg+xml" data="/icons/promotion.svg">
            referal promotion
        </object>
        <span onClick={() => handleReferralClick()}>{linkText}</span>
    </ReferalShortDiv>
)

export { ReferalLink, ReferalShortLink }
