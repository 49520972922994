import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loginPageShow, loginPageHide, isValidEmail } from '../../../main'
import { resetPassword, clearErrors } from '../../../shared/actions/index'
import { browserHistory } from 'react-router'
import t from '../../../shared/translations'
import { modalTypes, paths } from '../../../shared/constants'
import {
    showModal,
    hideModal,
    setLoginLoading,
    clearAuthErrors,
} from '../../../shared/actions/v2_actions'

var selectErrors = {}

class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.isAuthenticated) {
            browserHistory.push(paths.CLAIMS)
        } else {
            loginPageShow()
        }
    }

    componentDidMount() {
        loginPageShow()
    }

    UNSAFE_componentWillUpdate() {
        loginPageShow()
    }

    componentWillUnmount() {
        loginPageHide()
        this.props.clearErrors()
    }

    validateSelect = () => {
        selectErrors = {}
        const { email } = this.state

        if (!email || email.trim() === '') {
            selectErrors.email = t`common.label.is_required.email`
        }
        if (email && !isValidEmail(email)) {
            selectErrors.email = t`common.label.email_invalid`
        }

        this.setState({ errors: 'true' })
    }

    onSubmit = e => {
        e.preventDefault()
        this.validateSelect()

        if (
            Object.keys(selectErrors).length === 0 &&
            selectErrors.constructor === Object
        ) {
            this.props.resetPassword(this.state.email)
        }
    }

    handleChange = e => {
        if (this.props.errorMessage) {
            this.props.clearErrors()
        }
        if (selectErrors[e.target.name] && e.target.value)
            delete selectErrors[e.target.name]
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const { showModal } = this.props
        showModal(modalTypes.forgotPasswordModal)
        if (this.props.trans) {
            return <div className="container"></div>
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        errorMessage: state.error.errorMessage,
        isAuthenticated: state.auth.authenticated,
        trans: state.translations,
        lang: state.language,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetPassword,
            showModal,
            hideModal,
            clearAuthErrors,
            setLoginLoading,
            clearErrors,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
