import { SET_ID_ASSETS } from "../actions/actionTypes";

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
    const { type, payload, id } = action;
    switch (type) {
        case SET_ID_ASSETS:
            if (!state[id]) {
                return {...state, [id]: payload };
            } else {
                return state;
            }
        default:
    }
    return state;
}
