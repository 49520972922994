import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Col } from "./Layout";
import { InfoIcon } from "./V2Icons";
import t from "../../translations";

const Container = styled.div`
    padding: 24px;
    box-sizing: border-box;
    ${({ noBackground }) =>
        noBackground &&
        `
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    `}
`;

const HeaderContainer = styled.div`
    h3 {
        font-family: "Open Sans", sans-serif;
        font-size: 23px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.39;
        letter-spacing: normal;
        text-align: center;
        color: ${({ theme }) => theme.colors.primaryColor};
        margin-top: 16px;
        margin-bottom: 24px;
    }
`;

const DetailsContainer = styled.div`
    padding: 24px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    border: solid 1px ${({ theme }) => theme.colors.primaryColor};
    background-color: #ffffff;
    margin-bottom: 24px;
`;

const DetailsText = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;
    margin-bottom: 24px;

    span {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primaryColor};
    }
`;

const AmountText = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #353d51;
    margin: 0;
`;

const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`

const InfoBox = styled.div`
    padding: 12px 14px 12px 12px;
    border-radius: 8px;
    backdrop-filter: blur(17px);
    background-color: rgba(82, 150, 255, 0.08);
    display: flex;

    ${Col} {
        flex: 0 0 auto;
    }

    p {
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #7f8fa4;
        margin: 0;
        text-align: left;

        span {
            font-weight: bold;
            color: #353d51;
        }
    }
`;

class FlightDistanceBox extends React.Component {
    render() {
        const { noBackground, distance, totalAmount, amount } = this.props;

        return (
            <Container noBackground={noBackground}>
                <HeaderContainer>
                    <h3>{t`v2_claim.info_sidebar.title`}</h3>
                </HeaderContainer>
                <DetailsContainer>
                    <DetailsText>
                        {t`v2_claim.info_sidebar.details_part1`}
                        <span>{` ${Math.round(distance)} km`}</span>
                        {t`v2_claim.info_sidebar.details_part2`}
                    </DetailsText>
                    <AmountText>{`€${totalAmount || amount}`}</AmountText>
                </DetailsContainer>
                <InfoBox>
                    <IconContainer>
                        <InfoIcon />
                    </IconContainer>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t`v2_claim.info_sidebar.info_text`,
                        }}
                    />
                </InfoBox>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        amount: state.v2_amount,
        totalAmount: state.v2_total_amount,
        distance: state.v2_distance,
        trans: state.translations,
    };
}

export default connect(mapStateToProps)(FlightDistanceBox);
