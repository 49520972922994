import React from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { V2_prePath, modalTypes } from "../../../shared/constants";
import { showModal } from "../../../shared/actions/v2_actions";

class Register extends React.Component {
    componentDidMount() {
        browserHistory.push(V2_prePath)
        this.props.showModal(modalTypes.loginModal, { register: true });
    }

    render() {
        return <div></div>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({showModal}, dispatch);
}

export default connect(null, mapDispatchToProps)(Register);