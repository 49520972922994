import { applyMiddleware, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import reducers from "../reducers";
import { composeWithDevTools } from 'redux-devtools-extension';
import { isProduction } from '../../main';

const middlewares = 
    !isProduction()
    ? composeWithDevTools(applyMiddleware(ReduxThunk))
    : applyMiddleware(ReduxThunk);

export default createStore(reducers, middlewares);