import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = {
    minLength: 3,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_STEP_TWO:
            return { ...state, ...action.payload };
        case V2_ACTIONS.RESET_STEP_TWO:
            return INITIAL_STATE;
        default:
    }

    return state;
}
