import { V2_ACTIONS } from "../../actions/actionTypes";

const INITIAL_STATE = {
    validations: {
    },
    errors: {},
};

const validateInputs = (validations, payload, singleField) => {
    if (!!singleField) {
        if (typeof payload[singleField] !== validations[singleField].type) {
            return { [singleField]: validations[singleField].errorMessages.type_error };
        }
        if (!payload[singleField]) {
            return { [singleField]: validations[singleField].errorMessages.required_error };
        }
    } else {
        return Object.keys(validations).reduce((errors, validator) => {

            if (typeof payload[validator] !== validations[validator].type) {
                errors[validator] =
                    validations[validator].errorMessages.type_error;
            }

            if (!payload[validator]) {
                errors[validator] =
                    validations[validator].errorMessages.required_error;
            }

            return errors;
        }, {});
    }
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_STEP_TWO_ERRORS:
            const errors = validateInputs(state.validations, action.payload, action.singleField);
            return {
                ...state,
                errors,
            };
        default:
    }

    return state;
}
