import { BRANDED_GDPR } from '../actions/actionTypes';

export default function (state = false, action) {
    switch (action.type) {
        case BRANDED_GDPR:
            return action.payload;
        default:
    }

    return state;
}