import React from "react";
import styled from "styled-components";
import { CloseButton } from "../../../shared/components/V2/V2Buttons";
import { ExclamationMark } from "../../../shared/components/V2/V2Icons";
import {
    tablet,
    desktop,
} from "../../../shared/components/helpers/styled_queries";

const Container = styled.div`
    background-color: #f16464;
    border-radius: 8px;
    position: relative;
    padding: 16px;
    margin-bottom: 16px;
    margin: 16px;

    ${({ noTitle }) => noTitle && "display: flex"};

    ${tablet`
        margin: unset;
        margin-bottom: 16px;
    `}

    ${desktop`
        margin: unset;
        margin-bottom: 16px;
    `}
`;

const HeaderContainer = styled.div`
    display: flex;

    h1 {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0;
        margin-left: 8px;
        padding-right: 30px;
        ${({ css }) => css};

        ${tablet`
            margin-left: 24px;
        `}

        ${desktop`
            margin-left: 24px;
        `}
    }
`;

const ErrorsBox = ({ title, onClick, children, css, ...rest }) => {
    return (
        <Container {...rest}>
            {onClick && (
                <CloseButton onClick={onClick} fill={"#AA4141"} stroke="#FFF" />
            )}
            <HeaderContainer>
                <ExclamationMark />
                {title && <h1>{title}</h1>}
            </HeaderContainer>
            {children}
        </Container>
    );
};

export default ErrorsBox;
