import React from 'react'
import styled from 'styled-components'
import { PageWrapper, Title } from './common'
import t from '../../../shared/translations'
import { EmailContainer } from './ThankYou'
import { Row, Col } from '../../../shared/components/V2/Layout'
import CheckBox from '../../../shared/components/V2/V2CheckBox'
import { isFeatureEnabled, loadScreenOn, loadScreenOff } from '../../../main'
import { featureToggles } from '../../../shared/constants'
import { V2Button } from '../../../shared/components/V2/V2Buttons'
import {
    tablet,
    mobile,
    desktop,
    tabletLG,
} from '../../../shared/components/helpers/styled_queries'
import {
    SuccessMark,
    ExclamationMark,
} from '../../../shared/components/V2/V2Icons'
import { browserHistory } from 'react-router'
import {
    getUnsubscribeInfo,
    postUnsubscribeInfo,
} from '../../../shared/actions/v2_actions'

const CbTitle = styled.div`
    color: #353d51;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.72;
`

const CbDescription = styled.div`
    color: #3c465e;
    font-size: 13px;
    line-height: 1.85;
`

const StyledPageWrapper = styled(PageWrapper)`
    .email-subitem {
        color: #7f8fa4;
        font-size: 14px;
        font-weight: 600;
    }

    ${EmailContainer} {
        ${mobile`
            width: 100%;
        `}
    }

    ${tablet`
        padding: 16px 0px 32px;
        margin-top: 0px;

        ${Col} {
            max-width: 100%;
            text-align: center;
        }
    `}

    ${mobile`
        padding: 16px 0px 32px;
        margin-top: 0px;

        ${Col} {
            max-width: 100%;
            text-align: center;
        }
    `}

    ${Title} {
        text-align: left;

        ${tablet`
            text-align: center;
        `}

        ${mobile`
            text-align: center;
        `}
    }

    .submit-preferences {
        ${desktop`
            width: auto;
        `}

        ${tablet`
            width: auto;
        `}
    }

    .checkbox-container {
        margin: 24px 0px;
        text-align: left;
        
        ${tabletLG`
            max-width: 75%;
        `}
    }
`

const GrayLink = styled.a`
    font-weight: bold;

    &:hover,
    &:active,
    &:focus {
        color: #7f8fa4;
        filter: brightness(115%);
        text-decoration: none;
    }
`

const StyledAlertBox = styled.div`
    color: ${({ color }) => color};
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 334px;
    margin-top: 20px;
    text-align: left;

    div {
        display: inline-flex;
    }

    .icon {
        margin-right: 12px;
    }
`

export const AlertBox = ({
    bgColor = '#d9f3e4',
    color = '#3c465e',
    icon = () => <SuccessMark color="#ffffff" bgColor="#35b56a" />,
    children,
    content,
    ...props
}) => (
    <StyledAlertBox bgColor={bgColor} color={color} {...props}>
        <div className="icon">{icon()}</div>
        {children && <div>{children}</div>}
        {content && (
            <div
                style={{ display: 'block' }}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        )}
    </StyledAlertBox>
)

class RemarketingUnsubscribe extends React.Component {
    state = {
        unsubscribeChecked: false,
        success: false,
        failed: false,
        expired: false,
        email: '',
        token: '',
    }

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentWillMount() {
        loadScreenOn()
    }

    componentDidMount() {
        const { setParentState, location = {} } = this.props
        const { query } = location
        setParentState({
            noBack: true,
            hideButton: true,
            saveDraftEnabled: false,
        })

        if (query && query['token']) {
            this.fetchEmail(query['token'])
            browserHistory.replace(location.pathname)
        } else {
            loadScreenOff()
            this.setState({ expired: true, token: 'empty' })
        }
    }

    fetchEmail = token => {
        getUnsubscribeInfo(token)
            .then(resp => {
                if (resp && (!resp.hasSubscription || !resp.email)) {
                    this.setState({ expired: true, token })
                } else {
                    this.setState({ email: resp.email, token })
                }
                loadScreenOff()
            })
            .catch(() => {
                this.setState({ token })
                loadScreenOff()
            })
    }

    componentWillUnmount() {
        this.props.setParentState({
            noBack: false,
            hideButton: false,
            saveDraftEnabled: !!isFeatureEnabled(
                featureToggles.saveDraftFeature
            ),
        })
    }

    handleCheckboxChange = e => {
        const { unsubscribeChecked } = this.state
        this.setState({ unsubscribeChecked: !unsubscribeChecked })
    }

    submitPreferences = () => {
        const { token, unsubscribeChecked } = this.state
        loadScreenOn()

        if (unsubscribeChecked) {
            postUnsubscribeInfo(token)
                .then(resp => {
                    if (resp && resp.unsubscribed) {
                        this.setState(
                            {
                                success: true,
                            },
                            () => {
                                loadScreenOff()
                            }
                        )
                    } else {
                        this.setState(
                            {
                                failed: true,
                            },
                            () => {
                                loadScreenOff()
                            }
                        )
                    }
                })
                .catch(() => {
                    loadScreenOff()
                })
        } else {
            this.setState(
                {
                    success: true,
                },
                () => {
                    loadScreenOff()
                }
            )
        }
    }

    render() {
        const {
            unsubscribeChecked,
            success,
            failed,
            expired,
            email,
            token,
        } = this.state

        if (!token) {
            return null
        }

        return (
            <StyledPageWrapper
                height={'fit-content'}
                width={'unset'}
                style={{ alignItems: 'flex-start' }}
            >
                {!success && !failed && !expired && (
                    <React.Fragment>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Title
                                    style={{
                                        fontSize: '23px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    {t`subscription.button.update_email_preferences`}
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <span className="email-subitem">
                                    {t`common.subtitle.your_email`}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{ paddingTop: '0px' }}
                            >
                                <EmailContainer
                                    style={{
                                        margin: '0',
                                        color: '#000000',
                                        fontSize: '16px',
                                    }}
                                >
                                    {email}
                                </EmailContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                className="checkbox-container"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                            >
                                <CheckBox
                                    paddingLeft="44px"
                                    label={() => (
                                        <React.Fragment>
                                            <CbTitle>
                                                {t`remarketing.mailing_list_title`}
                                            </CbTitle>
                                            <CbDescription>
                                                {t`remarketing.mailing_list_description`}
                                            </CbDescription>
                                        </React.Fragment>
                                    )}
                                    checked={unsubscribeChecked}
                                    name="unsubscribeChecked"
                                    onChange={this.handleCheckboxChange}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '64px' }}>
                            <Col xs={8} sm={8} md={6} lg={5}>
                                <V2Button
                                    onClick={this.submitPreferences}
                                    disabled={!unsubscribeChecked}
                                    className="submit-preferences"
                                >
                                    {t`payment_page.button.submit`}
                                </V2Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {t`common.info.privacy_policy`}
                                &nbsp;
                                <GrayLink
                                    target="_blank"
                                    href={t`terms.privacy.url`}
                                >
                                    {t`terms.privacy.url_text`}
                                </GrayLink>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                {!!success && (
                    <React.Fragment>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Title
                                    style={{
                                        fontSize: '23px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    {t`common.flash_message.success.email_preferences`}
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <AlertBox>
                                    {t`remarketing.preferences_update_success`}
                                </AlertBox>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                {!!failed && (
                    <React.Fragment>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Title
                                    style={{
                                        fontSize: '23px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    {t`error_page.title.other`}
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <AlertBox
                                    bgColor="#f16464"
                                    color="#ffffff"
                                    icon={() => <ExclamationMark />}
                                >
                                    {t`remarketing.preferences_update_failed`}
                                </AlertBox>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                {!!expired && (
                    <React.Fragment>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Title
                                    style={{
                                        fontSize: '23px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    {t`error_page.title.other`}
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <AlertBox
                                    bgColor="#f16464"
                                    color="#ffffff"
                                    icon={() => <ExclamationMark />}
                                >
                                    {t`remarketing.invalid.expired.token`}
                                </AlertBox>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </StyledPageWrapper>
        )
    }
}

export default RemarketingUnsubscribe
