import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from '../../../../shared/components/V2/Layout'
import { claimStatus } from '../../../../shared/constants'
import t from '../../../../shared/translations'
import { SuccessIcon, ArrowIcon } from './icons'
import moment from 'moment'
import { V2Button } from '../../../../shared/components/V2/V2Buttons'

const Container = styled.div`
    padding: 12px 0 24px;
`

export const InfoCol = styled(Col)`
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #353d51;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.bold {
        font-weight: 600;
    }

    &.grey {
        color: #76869c;
    }

    .sidebar-tooltip {
        text-align: left;
        font-size: 11px;
        max-width: 240px;
    }
`

const TimelineBlock = styled('div')`
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`

const TimelineItemContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        border-bottom: 1px solid #aab0bf4d;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 20%;
        width: 60%;
        border-bottom: ${({ color, last }) =>
            last ? `2px solid ${color}` : `none`};
    }

    & .item-block-icon {
        position: relative;
    }

    & .item-block-content {
        color: #353d51;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 16.8px */
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;

        & .item-block-text {
            margin: 0;
            text-align: center;
        }

        & .item-block-header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 32px;

            & .item-block-status {
                color: #353d51;
                text-align: start;
                font-family: Open Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 14.4px */
                display: flex;
                align-items: left;
                white-space: nowrap;

                svg {
                    margin-left: 4px;
                    transform: rotate(180deg);
                }
            }

            & .item-block-date {
                color: #7f8fa4;
                text-align: left;
                font-family: Open Sans;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 18.62px; /* 186.2% */
            }
        }
    }
`

const ModalPage = styled('div')`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 40%;
    top: 0;
    left: 0;
    z-index: 89;
`
const TimelineModalContainer = styled('div')`
    position: fixed;
    border-radius: 16px 16px 0px 0px;
    background: #fff;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 90;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;

    & .item-block-line {
        background-color: black;
        width: 134px;
        height: 5px;
        flex-shrink: 0;
        border-radius: 100px;
        margin-bottom: 22px;
    }

    & .item-block-text {
        color: #353d51;
        text-align: center;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        margin: 0;
        text-align: center;
        padding: 24px;
    }
    & .item-block-button {
        display: flex;
        margin-top: 24px;
        width: 100%;
        justify-content: center;

        button {
            height: 60px;
            padding: 18px 16px;
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.38px;
            border-radius: 14px;
            background: #35b56a;
        }
    }

    & .item-block-date {
        color: #7f8fa4;
        text-align: right;
        font-family: Open Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.62px; /* 186.2% */
    }

    & .item-block-status {
        color: ${({ color }) => `${color}`};
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
        margin-bottom: 16px;

        svg {
            margin-left: 4px;
            transform: rotate(180deg);
        }
    }
`

export default class ClaimHorizontalTimeline extends Component {
    componentDidMount() {
        const element = document.querySelector('#mobile-timeline-block')

        if (element && element.scrollWidth) {
            element.scrollTo(element.scrollWidth, 0)
        }
    }

    render() {
        const { statusHistory } = this.props

        return (
            <Container>
                <Row>
                    <TimelineBlock id="mobile-timeline-block">
                        {!!statusHistory.length ? (
                            statusHistory.map((item, index) => (
                                <TimelineItem
                                    key={index}
                                    last={statusHistory.length - 1 === index}
                                    iconStatus={item.status}
                                    status={t`${item.status}_web`}
                                    date={formatDate(item.created_at)}
                                    text={t`${item.status}_description_web`}
                                />
                            ))
                        ) : (
                            <TimelineItem
                                last={true}
                                defaultColor={true}
                                iconStatus={'wait_for_review'}
                                status={t`wait_for_review`}
                                text={t`wait_for_review_description`}
                            />
                        )}
                    </TimelineBlock>
                </Row>
            </Container>
        )
    }
}

function formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD, HH:mm') : ''
}

const TimelineItem = ({
    status,
    iconStatus,
    last,
    text,
    date,
    defaultColor,
}) => {
    const [open, setOpen] = useState(false)
    const statusColor = defaultColor
        ? claimStatus.default.color
        : (claimStatus[iconStatus] || claimStatus.default).color

    return (
        <TimelineItemContainer color={statusColor} last={last} open={open}>
            <div className="item-block-icon">
                {last ? (
                    (
                        claimStatus[iconStatus] || claimStatus.default
                    ).timeLineIcon()
                ) : (
                    <SuccessIcon />
                )}
            </div>
            <div className="item-block-content" onClick={() => setOpen(!open)}>
                <div className="item-block-header">
                    <div className="item-block-status">
                        {status}
                        <ArrowIcon />
                    </div>
                    <div className="item-block-date">{date}</div>
                </div>
            </div>
            {open && (
                <TimelineModal
                    text={text}
                    status={status}
                    last={last}
                    date={date}
                    color={last ? statusColor : '#35B56A'}
                    closeModal={() => setOpen(false)}
                    buttonText={t`timeline_button`}
                    icon={
                        last ? (
                            (
                                claimStatus[iconStatus] || claimStatus.default
                            ).timeLineIcon()
                        ) : (
                            <SuccessIcon />
                        )
                    }
                />
            )}
        </TimelineItemContainer>
    )
}

const TimelineModal = ({
    status,
    color,
    icon,
    text,
    buttonText,
    date,
    closeModal,
}) => {
    return (
        <>
            <TimelineModalContainer color={color}>
                <div className="item-block-line" onClick={closeModal} />
                <div>{icon}</div>
                <div className="item-block-status">{status}</div>
                <div className="item-block-date">{date}</div>
                <div className="item-block-text">{text}</div>
                <div className="item-block-button">
                    <V2Button onClick={closeModal}>{buttonText}</V2Button>
                </div>
            </TimelineModalContainer>
            <ModalPage onClick={closeModal} />
        </>
    )
}
