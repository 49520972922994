import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import styled from 'styled-components'
import t from '../../../translations'
import { paths, featureToggles } from '../../../constants'
import store from '../../../services/store'
import { V2_ACTIONS } from '../../../actions/actionTypes'
import { tablet, desktop } from '../../helpers/styled_queries'
import V2MobileLangSelect from '../../V2/V2MobileLangSelect'
import { ReferalLink } from '../../../../views/V2/components/ReferalLink'
import { isFeatureEnabled, isMobileDevice } from '../../../../main'
import V2DesktopLangSelect from '../../V2/V2DesktopLangSelect'

const DropdownContainer = styled.div`
    position: absolute;
    min-width: 320px;
    height: 100vh;
    background-color: #ffffff;
    z-index: 1070;
    font-size: 1rem;
    color: #292b2c;
    text-align: left;
    background-clip: padding-box;
    box-sizing: border-box;
    z-index: 2147483647;
    right: -15px;
    top: 115%;
    padding: 0;
    margin: 0;
    width: 100vw;
    box-shadow: inset 0px -1px 0px 0px #cbd3df;
    overflow-y: auto;
    padding-bottom: 150px;

    ${tablet`
        box-shadow: -3px 4px 10px 2px rgba(53,61,81,0.23);
        padding: 0.2rem 0;
        top: 64px;
        right: right: -10px;;
        border-radius: 5px 5px 0 0;
        margin: 1.1rem 1rem;
        width: 320px;
    `}

    ${desktop`
        box-shadow: -3px 4px 10px 2px rgba(53,61,81,0.23);
        padding: 0.2rem 0;
        top: 64px;
        right: calc(50% - 80px);
        border-radius: 5px 5px 0 0;
        margin: 1.1rem 1rem;
        width: 320px;
    `}

    :before {
        top: -9px;
        right: 38px;
        position: absolute;
        z-index: 0;
        border-radius: 5px;
        background: #fff;
        height: 2rem;
        width: 2rem;
        display: block;
        transform: rotate(45deg);

        ${tablet`
            content: "";
            right: 21px;
        `}

        ${desktop`
            content: "";
        `}
    }

    a {
        text-decoration: none;
    }

    a:last-child {
        border-bottom: solid 0.1rem #e5e5e5;
    }

    ${tablet`
        height: auto;

        a:nth-child(2) {
            border: none;
        }

        a:last-child {
            border-bottom: none;
        }
    `}

    ${desktop`
        height: auto;

        a:nth-child(2) {
            border: none;
        }

        a:last-child {
            border-bottom: none;
        }
    `}
`

const StyledLink = styled(Link)`
    position: relative;
    display: block;
    z-index: 2;
    font-size: 14px;
    padding: 20px 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${({ signatureConfigs }) =>
        signatureConfigs && signatureConfigs.menuItemColor
            ? signatureConfigs.menuItemColor
            : '#101b35;'};
    border-top: solid 0.1rem #e5e5e5;

    &:hover {
        color: ${({ theme, signatureConfigs }) =>
            signatureConfigs && signatureConfigs.menuItemColor
                ? signatureConfigs.menuItemColor
                : theme.colors.primaryColor};
        background: 0 0;
    }
`

const ReferalLinkDiv = styled.div`
    margin: 20px 0px 40px;

    ${desktop`
        display: none;
    `}
`

const Bubble = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0.9;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #32b86a;
    margin-right: 16px;
    background-color: ${({ signatureConfigs }) =>
        (signatureConfigs &&
            signatureConfigs.menuItemColor &&
            signatureConfigs.menuItemColor) ||
        '#e3f5ea'};
`

const UserNameBox = styled.div`
    display: flex;
    align-items: center;
    padding: 24px 31px;

    p {
        margin: 0;
        opacity: 0.9;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #101b35;
    }

    ${tablet`
        display: none;
    `}

    ${desktop`
        display: none;
    `}
`

const NameText = styled.p`
    color: ${({ signatureConfigs }) =>
        signatureConfigs &&
        signatureConfigs.menuItemColor &&
        `${signatureConfigs.menuItemColor} !important`};
`

class Dropdown extends Component {
    render() {
        const {
            nickname,
            renderName,
            location,
            resetAgreement,
            saveUserClaim,
            resetV2Claim,
            closeMenu,
            logout,
            signatureConfigs,
            handleReferralClick,
        } = this.props

        return (
            <DropdownContainer id="menu-dropdown">
                <UserNameBox>
                    <Bubble signatureConfigs={signatureConfigs}>
                        {nickname}
                    </Bubble>
                    <NameText signatureConfigs={signatureConfigs}>
                        {renderName()}
                    </NameText>
                </UserNameBox>
                {isMobileDevice() && (
                    <>
                        <StyledLink
                            signatureConfigs={signatureConfigs}
                            to={'/'}
                            onClick={() => {
                                if (
                                    location &&
                                    location.pathname &&
                                    location.pathname === paths.COMPLETED_SIGN
                                ) {
                                    return
                                }

                                resetAgreement()
                                saveUserClaim(undefined)
                                resetV2Claim()
                                store.dispatch({
                                    type: V2_ACTIONS.STEP_RESET,
                                    payload: 0,
                                })
                            }}
                        >{t`menu.create_new_claim`}</StyledLink>
                        <StyledLink
                            signatureConfigs={signatureConfigs}
                            to="/claims"
                        >{t`menu.my_claims`}</StyledLink>
                        {isFeatureEnabled(featureToggles.sc_menu_item) && (
                            <StyledLink
                                signatureConfigs={signatureConfigs}
                                to="/subscriptions"
                            >{t`menu.sc_subscriptions`}</StyledLink>
                        )}
                        <StyledLink
                            signatureConfigs={signatureConfigs}
                            to="/planned-trips"
                        >{t`menu.your_planned_trips_mobile`}</StyledLink>
                    </>
                )}
                <StyledLink
                    signatureConfigs={signatureConfigs}
                    to="/payment"
                >{t`menu.payment`}</StyledLink>
                <StyledLink
                    signatureConfigs={signatureConfigs}
                    to="/profile"
                >{t`menu.edit_profile`}</StyledLink>
                {/* {getStorage("brandedPreview") && (
                <StyledLink to="/branded?token=NyLSEicWyKGhnhvDwQcx">{t`Branded preview`}</StyledLink>
            )} */}
                <StyledLink
                    signatureConfigs={signatureConfigs}
                    href="#"
                    onClick={logout}
                >{t`menu.logout`}</StyledLink>
                <V2DesktopLangSelect
                    signatureConfigs={signatureConfigs}
                    callback={closeMenu}
                />
                <V2MobileLangSelect
                    signatureConfigs={signatureConfigs}
                    fullWidth={true}
                    callback={closeMenu}
                />
                <ReferalLinkDiv>
                    <ReferalLink
                        id="mobile_global_footer"
                        text={t`referal.text.share_and_earn`}
                        linkText={t`referal.text.refer_friend`}
                        handleReferralClick={handleReferralClick}
                    />
                </ReferalLinkDiv>
            </DropdownContainer>
        )
    }
}

Dropdown.propTypes = {
    saveUserClaim: PropTypes.func.isRequired,
    resetAgreement: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
}
export default Dropdown
