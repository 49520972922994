import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from '../../../../shared/components/V2/Layout'
import { claimStatus } from '../../../../shared/constants'
import t from '../../../../shared/translations'
import { SuccessIcon, ArrowIcon } from './icons'
import moment from 'moment'

const Container = styled.div`
    padding: 36px 12px 24px 12px;
`

const H2 = styled.h2`
    color: #353d51;
    font-family: Open Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34.72px; /* 124% */
    margin-bottom: 20px;
`

export const InfoCol = styled(Col)`
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #353d51;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.bold {
        font-weight: 600;
    }

    &.grey {
        color: #76869c;
    }

    .sidebar-tooltip {
        text-align: left;
        font-size: 11px;
        max-width: 240px;
    }
`

const TimelineBlock = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 12px;
`

const TimelineItemContainer = styled('div')`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;

    & .item-block-icon {
        margin-right: 12px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 36px;
            left: 15px;
            height: calc(100% - 40px);
            border-left: ${({ color, last }) =>
                last ? 'none' : `1px solid ${color}`};
        }
    }

    & .item-block-content {
        color: #353d51;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 16.8px */
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;

        & .item-block-toggle-content {
            & .item-block-date {
                color: #7f8fa4;
                text-align: left;
                font-family: Open Sans;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 18.62px; /* 186.2% */
            }

            & .item-block-text {
                margin: 0;
                text-align: left;
            }
        }

        & .item-block-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;

            & .item-block-status {
                color: #353d51;
                text-align: start;
                font-family: Open Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 14.4px */
                display: flex;
                align-items: center;

                svg {
                    margin-left: 4px;
                    transform: ${({ open }) =>
                        open ? 'rotate(180deg)' : 'rotate(90deg)'};
                }
            }
        }
    }
`

export default class ClaimTimeline extends Component {
    render() {
        const { statusHistory } = this.props

        return (
            <Container>
                <Row>
                    <Col>
                        <H2>{t`inner_claim.summary`}</H2>
                    </Col>
                </Row>
                <Row>
                    <TimelineBlock>
                        {!!statusHistory.length ? (
                            statusHistory.map((item, index) => (
                                <TimelineItem
                                    key={index}
                                    last={statusHistory.length - 1 === index}
                                    iconStatus={item.status}
                                    status={t`${item.status}_web`}
                                    date={formatDate(item.created_at)}
                                    text={t`${item.status}_description_web`}
                                />
                            ))
                        ) : (
                            <TimelineItem
                                last={true}
                                defaultColor={true}
                                iconStatus={'wait_for_review'}
                                status={t`wait_for_review`}
                                text={t`wait_for_review_description`}
                            />
                        )}
                    </TimelineBlock>
                </Row>
            </Container>
        )
    }
}

function formatDate(date) {
    return date ? moment(date).format('YYYY-MM-DD, HH:mm') : ''
}

const TimelineItem = ({
    status,
    iconStatus,
    last,
    text,
    date,
    defaultColor,
}) => {
    const [open, setOpen] = useState(last ? true : false)
    const statusColor = defaultColor
        ? claimStatus.default.color
        : (claimStatus[iconStatus] || claimStatus.default).color

    const finalStatuses = ['paid', 'reject', 'claim_rejected', 'transferred']
    return (
        <TimelineItemContainer
            color={last ? statusColor : '#35B56A'}
            last={(last && !open) || finalStatuses.includes(iconStatus)}
            open={open}
        >
            <div className="item-block-icon">
                {last ? (
                    (
                        claimStatus[iconStatus] || claimStatus.default
                    ).timeLineIcon()
                ) : (
                    <SuccessIcon />
                )}
            </div>
            <div className="item-block-content" onClick={() => setOpen(!open)}>
                <div className="item-block-header">
                    <div className="item-block-status">
                        {status}
                        <ArrowIcon />
                    </div>
                </div>
                {open && (
                    <div className="item-block-toggle-content">
                        <div className="item-block-date">{date}</div>
                        <div className="item-block-text">{text}</div>
                    </div>
                )}
            </div>
        </TimelineItemContainer>
    )
}
