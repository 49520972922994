import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import _get from "lodash/get";
import latinize from "latinize";
import { hideModal } from "../../../shared/actions/v2_actions";
import { CloseButton, V2Button } from "../../../shared/components/V2/V2Buttons";
import Modal, { SPACE_KEY } from "../../../shared/components/V2/Modal";
import t from "../../../shared/translations";
import { modalTypes } from "../../../shared/constants";
import InputField from "../../../shared/components/V2/InputField";
import { Row, Col } from "../../../shared/components/V2/Layout";
import { Title } from "../steps/common";
import { isValidLatinChars, isValidEmail, isValidEmailInput } from "../../../main";

const Container = styled.div`
    padding: 32px 60px;

    ${({ noBackground }) =>
        noBackground &&
        `
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 5px 9px -5px rgba(53, 61, 81, 0.23);
    `}
`;

const StyledTitle = styled(Title)`
    padding: 20px;
    padding-bottom: 12px;
    margin-bottom: 0;
`;

const SplitRow = styled(Row)`
    height: 1px;
    background: #eee;
    width: 100%;
    margin: 24px 0px;
`;

class StripePassengerModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: _get(props, "modal.props.name", ""),
            surname: _get(props, "modal.props.surname", ""),
            email: _get(props, "modal.props.email", ""),
            errors: {},
        }
    }

    checkErrors = () => {
        const { name, surname, email } = this.state;

        let errors = {};

        if (!name) {
            errors['name'] = "common.label.is_required.name";
        } else if (!isValidLatinChars(name)) {
            errors['name'] = "common.label.is_required.latin_only";
        }

        if (!surname) {
            errors['surname'] = "common.label.is_required.surname";
        } else if (!isValidLatinChars(surname)) {
            errors['surname'] = "common.label.is_required.latin_only";
        }

        if (email && !isValidEmail(email)) {
            errors['email'] = "common.label.email_invalid";
        }

        this.setState({ errors });

        return errors;
    }

    handleSubmit = () => {
        const { modal, hideModal } = this.props;

        const errors = this.checkErrors();
        if (modal && Object.keys(errors).filter((key) => !!errors[key]).length === 0) {
            const { props } = modal;
            if (props.callBack && typeof props.callBack === "function") {
                props.callBack(this.state);
            }
            hideModal();
        }
    }

    handleChange = (e) => {
        const { errors } = this.state;
        const { name, value } = _get(e, "target", e);

        let newValue = value;
        if (["name", "surname"].includes(name)) {
            newValue = latinize(value);
        } else if (name === "email") {
            if (!isValidEmailInput(value)) {
                return;
            }
        }

        delete errors[name];

        this.setState({
            [name]: newValue,
            errors,
        });
    }

    render() {
        const { hideModal } = this.props;
        const { name, surname, email, errors } = this.state;

        return (
            <Modal modalType={modalTypes.stripePassengerModal} height="fit-content" maxWidth='800px' maxHeight="fit-content">
                <CloseButton onClick={hideModal} width="34" height="34" />
                <Container>
                    <Row style={{ justifyContent: 'center' }}>
                        <StyledTitle>
                            {t`sc.button.add_passenger`}
                        </StyledTitle>
                    </Row>
                    <Row>
                        <InputField
                            name="name"
                            id="user-name"
                            label={t`v2_common.label.name`}
                            required
                            placeholder={t`v2_common.placeholder.name`}
                            value={name}
                            onChange={this.handleChange}
                            autoComplete="name-first"
                            errorText={t`${_get(errors, "name", "")}`}
                        />
                        <InputField
                            required
                            label={t`v2_common.label.surname`}
                            name="surname"
                            id="user-surname"
                            placeholder={t`v2_common.placeholder.surname`}
                            value={surname}
                            onChange={this.handleChange}
                            autoComplete="name-last"
                            errorText={t`${_get(errors, "surname", "")}`}
                        />
                    </Row>
                    <Row>
                        <InputField
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            label={t`v2_common.label.email`}
                            name="email"
                            type="email"
                            placeholder={t`v2_common.placeholder.email`}
                            value={email}
                            onChange={this.handleChange}
                            autoComplete="do-not-autofill"
                            errorText={t`${_get(errors, "email", "")}`}
                            onKeyDown={(e) => {
                                if (e.keyCode === SPACE_KEY) {
                                    e.preventDefault();
                                    return false;
                                }
                            }}
                        />
                    </Row>
                    <Row style={{ marginLeft: '-60px', width: 'calc(100% + 120px)' }}>
                        <SplitRow />
                    </Row>
                    <Row>
                        <Col sm={6} md={4} lg={4}>
                            <V2Button
                                onClick={this.handleSubmit}
                                style={{ minWidth: 'unset' }}
                                disabled={!name || !surname}
                            >
                                {t`payment_page.button.submit`}
                            </V2Button>
                        </Col>
                        <Col sm={6} md={4} lg={4}>
                            <V2Button
                                type="borderBtn"
                                onClick={hideModal}
                                style={{ minWidth: 'unset' }}
                            >
                                {t`common.label.cancel`}
                            </V2Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        hideModal,
    }, dispatch);
}

export default connect(
    (state) => ({
        modal: state.toggle_modal,
    }),
    mapDispatchToProps
)(StripePassengerModal);
