import { V2_ACTIONS } from '../../actions/actionTypes';

const INITIAL_STATE = {
    curr: null,
    prev: null,
};

export default function (state = INITIAL_STATE, action) {
    const { type, payload, prev } = action;

    switch (type) {
        case V2_ACTIONS.PROGRESS_BAR:
            if (state.curr === null || payload >= state.curr) {
                return {
                    curr: payload,
                    prev: prev || state.curr || 0,
                };
            } else {
                return state;
            }
        case V2_ACTIONS.RESET_PROGRESS_BAR:
            return {
                curr: null,
                prev: null,
            };
        default:
    }
    return state;
}