import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _get from 'lodash/get'
import t from '../../translations'
import { InputField } from '../../../shared/components/Form'
import NewSelectField from './NewSelectField'
import { mobile } from '../../../shared/components/helpers/styled_queries'
import { QuestionMarkTooltip } from './V2Icons'
import ToolTip from './V2Tooltip'

const TooltipIconWrapper = styled.span`
    background: #ecf7f1;
    padding: 4px 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
`

const SearchIconWrapper = styled.div`
    padding: 0 15px;
    position: absolute;
    pointer-events: none;
    z-index: 99;
    line-height: 52px;
    img {
        width: 18px;
    }
`

const StyledRow = styled.div`
    position: relative;
    display: flex;
    margin-right: -10px;
    margin-left: -10px;
    flex-wrap: wrap;

    .phone-number,
    .phone-prefix {
        margin-bottom: 0px !important;
    }

    &:hover,
    &:active,
    &:focus {
        ${({ readOnly, theme }) =>
            !readOnly
                ? `
            .phone-number, .phone-prefix {
                input {
                    border: 1px solid ${theme.colors.primaryColor} !important;
                    box-shadow: 0 2px 7px 0 rgba(127, 143, 164, 0.16);
                }
            }

            .phone-prefix {
                input {
                    border-right-color: #717171 !important;
                }
            }

            .phone-number {
                input {
                    border-left: none !important;
                }
            }
        `
                : `
            cursor: not-allowed;
        `}
    }
`

const SearchItemRow = styled(StyledRow)`
    width: 100%;
`

const SearchItemFlag = styled.div`
    padding-right: 15px;
`

const SearchItem = styled.li`
    position: relative;
    display: flex;
    padding: 0;
    text-align: left;
    cursor: pointer;
    align-items: center;
    ${SearchItemRow} {
        margin: 0px;
        justify-content: space-between;
        align-items: center;
        div {
            float: left;
        }
        > div {
            display: flex;
            align-items: center;
            &:last-of-type {
                float: right;
                padding-right: 15px;
            }
        }
    }
`

const SelectArrow = styled.span`
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    width: 10px;
    height: 10px;
`

const PrefixContainer = styled.div`
    input {
        border-radius: ${({ isOpen }) =>
            isOpen ? '12px 0 0 0' : '12px 0 0 12px'};
        text-align: center !important;
        background: url(${({ isOpen, hasValue }) =>
                `/icons/v2_bottom_arrow${isOpen ? '_hover' : ''}${
                    hasValue ? '' : '_inactive'
                }.svg`})
            no-repeat 90% 52%;
    }
`

const NumberContainer = styled.div``

const Container = styled.div`
    label {
        font-family: 'Noto sans';
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 22.39px;
        color: #717171;
        letter-spacing: normal;
        margin-bottom: 16px;
        height: ${({ isTooltip }) => isTooltip && '22.39px;'};

        span {
            color: #fe6050;
        }
    }

    ${SelectArrow} {
        ${({ isOpen }) =>
            isOpen &&
            `
            border-color: transparent transparent #fafafa;
            border-width: 0 5px 5px;
        `}
    }
    ${NumberContainer} {
        input {
            background-color: #fafafa;
            height: 56px !important;
            font-family: 'Noto sans';
            border: 1px solid #717171;
            color: #353d51;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            border-radius: ${({ isOpen }) =>
                isOpen ? '0 12px 0 0' : '0 12px 12px 0'};
            position: relative;
            padding: 1.2rem 1.7rem;
            border-left: 0 !important;
            box-shadow: none;

            ${({ hasDanger }) =>
                hasDanger &&
                `
                border: solid 0.1rem #d9534f;
            `}

            ${({ readOnly }) =>
                readOnly &&
                `
                cursor: not-allowed;
                border: 1px solid #f2f5f9;
                color: #7f8fa4;
                background: #f2f5f9;

                &:hover {
                    border: 1px solid #f2f5f9;
                }
            `}

            &:disabled {
                background-color: #f2f5f9;
            }
        }
    }
    ${PrefixContainer} {
        position: relative;

        ${SelectArrow} {
            position: absolute;
            top: calc(50% - 8px);
            right: 14px;

            ${mobile`
                top: calc(50% - 10px);
            `}
        }

        input {
            height: 56px !important;
            font-family: 'Noto sans';
            border: 1px solid #717171;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            background-color: #fafafa;
            box-shadow: none;
            color: #353d51;
            padding: 10px 24px 12px 42px;

            ${({ readOnly }) =>
                !readOnly &&
                `
                cursor: pointer;
            `}

            ${({ hasDanger }) =>
                hasDanger &&
                `
                border: solid 1px #d9534f;
            `}

            &:disabled {
                background-color: #f2f5f9;
            }

            &:focus {
                caret-color: transparent;
            }

            ${({ readOnly }) =>
                readOnly &&
                `
                cursor: not-allowed;
                border: 1px solid #f2f5f9;
                color: #7f8fa4;
                background: #f2f5f9;

                &:hover {
                    border: 1px solid #f2f5f9;
                }
            `}
        }
    }
`

const InputFlag = styled.div`
    top: 15px;
    left: 10px;
    background: url('/css/flags1.png');
    width: 24px;
    height: 24px;
    position: absolute;
    background-position: 0 0;
    top: 15px;
    left: 15px;
`

const Dropdown = styled.div`
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 99;
    display: none;
    margin-top: -0.1rem;
    color: #353d51;

    ${({ isOpen }) =>
        isOpen &&
        `
        display: block;

        .Select-placeholder {
            padding-left: 45px;
        }

        .Select-input {
            padding-left: 45px;
            width: 100%;

            input {
                width: 100%!important;
            }
        }
        .Select-menu-outer {
            border: solid 1px ${({ theme }) => theme.colors.primaryColor};
        }

        .Select-control {
            display: block;
            border-top: none;
            border-radius: 0px 0px 12px 12px;
            box-shadow: unset;
            border: solid 1px #717171;
             background: #fafafa;

            &:hover {
                border-color: #717171;
                box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.15);
            }

            .is-searchable {
                &.is-open {
                    > .Select-control {
                        border: solid 1px #717171;
                    }
                }
        
                &.is-focused {
                    &:not(.is-open) {
                    > .Select-control {
                        border: solid 1px #717171;
                  }
                }
              }

        }

        .is-open {
            > .Select-control {
            border: solid 1px #717171;
        }

        .is-focused {
            > .Select-control {
            border: solid 1px #717171;
        }

        .Select-noresults {
            padding-left: 45px;
        }
    `}
`

const ErrorTextBox = styled.div`
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    margin: 0;
    padding-top: 16px;
    font-style: normal;
    font-stretch: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #fe6050;

    padding-left: 10px;

    &.warn {
        color: #fe6050;
    }
`

class V2PhoneField extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            isActive: false,
            countryCodeName: 'default',
        }
    }

    componentWillMount() {
        const { phoneCode, countries } = this.props

        if (phoneCode) {
            const countryCodeName = this.getCountryCode(countries, phoneCode)
            this.setState({ countryCodeName })
        }

        document.addEventListener('click', this.handleOutsideClick, false)
        document.addEventListener('touchend', this.handleOutsideClick, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false)
        document.removeEventListener('touchend', this.handleOutsideClick, false)
    }

    componentDidUpdate(prevProps) {
        const { phoneCode, countries } = this.props

        if (phoneCode !== prevProps.phoneCode) {
            const countryCodeName = this.getCountryCode(countries, phoneCode)
            this.setState({ countryCodeName })
        }
    }

    handleOutsideClick = e => {
        const prefixField = ReactDOM.findDOMNode(this.prefixFieldRef)
        const selectField = ReactDOM.findDOMNode(this.selectRef)
        if (
            (!prefixField || !prefixField.contains(e.target)) &&
            (!selectField || !selectField.contains(e.target))
        ) {
            this.setState({
                isActive: false,
                isOpen: false,
            })
        }
    }

    onlyNumbers = e => {
        const re = /^[0-9]+/g

        if (!re.test(e.key)) {
            e.preventDefault()
        }
    }

    handlePhoneNumberClickEvent = () => {
        this.setState({
            isActive: true,
            isOpen: false,
        })
    }

    handleClick = e => {
        const { readOnly } = this.props

        if (readOnly) {
            e.preventDefault()
        } else {
            const { isOpen } = this.state

            this.setState(
                {
                    isOpen: !isOpen,
                },
                () => {
                    if (this.state.isOpen) {
                        this.handleSelectRef(this.selectRef)
                    }

                    this.selectRef.setState({
                        isOpen: !isOpen,
                    })
                }
            )
        }
    }

    handleTouch = e => {
        const { isOpen } = this.state
        const { readOnly } = this.props
        if (readOnly) return
        e.preventDefault()
        e.target.blur()
        const dropdownRef = this.selectRef

        this.selectRef.setState(
            {
                isOpen: !isOpen,
            },
            () => {
                if (this.state.isOpen) {
                    this.handleSelectRef(dropdownRef)
                }
            }
        )
        this.setState({
            isOpen: !isOpen,
            isActive: true,
        })
    }

    getCountryFlagImg = (code, title) => {
        const flagCode = this.mapExceptionalFlags(code)
        return (
            <img
                src="/images/blank.gif"
                className={`flag flag-${flagCode.toLowerCase()} empty-flag`}
                alt={title}
            />
        )
    }

    mapExceptionalFlags = code => {
        switch (code) {
            case 'AM': //Armenia
                return 'HY'
            case 'AQ': //Antarctica
                return code
            case 'CX': //Christmas Island
                return code
            case 'CC': //Cocos Islands
                return code
            case 'CZ': //Check republic
                return 'CS'
            case 'DK': //Denkmark
                return 'DA'
            case 'GF': //French Guiana
                return 'FR'
            case 'GR': //Greece
                return 'EL'
            case 'GP': //Guadeloupe
                return 'FR'
            case 'HM': //Heard Island and McDonald Islands
                return 'AU'
            case 'RE': //Réunion
                return 'FR'
            case 'PM': //Saint Pierre and Miquelon
                return 'FR'
            case 'SJ': //Svalbard and Jan Mayen
                return 'NO'
            case 'UM': //Wake Island
                return 'US'
            case 'XK': //Kosovo
                return code
            case 'ZR': //Zaire
                return 'CG' //Congo national flag
            default:
                return code
        }
    }

    handlePhonePaste = e => {
        const pastedValue = e.clipboardData.getData('Text')
        const digitRegex = /^\d+$/

        if (
            !digitRegex.test(
                pastedValue
                    .replace('+', '')
                    .replace(/\s+/g, '')
                    .replace(/-/g, '')
            )
        ) {
            e.preventDefault()
        }

        return false
    }

    getOptions = () => {
        const { countries } = this.props

        return (countries ? countries.payload : [])
            .filter(item => !!item.phone_code)
            .map(item => ({
                id: item.id,
                code: item.code,
                value: item.phone_code,
                title: item.title,
                label: `${item.phone_code}`,
                name: 'phone_code',
            }))
    }

    renderOption = option => {
        return (
            <SearchItem>
                <SearchItemRow>
                    <div>
                        <SearchItemFlag>
                            {this.getCountryFlagImg(option.code, option.title)}
                        </SearchItemFlag>
                        <div>{option.title}</div>
                    </div>
                    <div>{option.label}</div>
                </SearchItemRow>
            </SearchItem>
        )
    }

    sortOptions(arr, word) {
        return arr.sort((a, b) => {
            const aStartsWithWord = a.title
                .toLowerCase()
                .startsWith(word.toLowerCase())
            const bStartsWithWord = b.title
                .toLowerCase()
                .startsWith(word.toLowerCase())

            if (aStartsWithWord && !bStartsWithWord) {
                return -1
            } else if (!aStartsWithWord && bStartsWithWord) {
                return 1
            } else if (aStartsWithWord && bStartsWithWord) {
                const aWordCount = a.title.split(/\s+/).length
                const bWordCount = b.title.split(/\s+/).length

                return aWordCount - bWordCount
            } else {
                return 0
            }
        })
    }

    filterOptions = (options, filterString) => {
        if (filterString.length > 1) {
            const escapedInput = filterString.replace(
                /[.*+?^${}()|[\]\\]/g,
                '\\$&'
            )

            const regexPattern = new RegExp(
                `^(?:${escapedInput}|\\w+\\s+${escapedInput}|\\w+\\s+\\w+\\s+${escapedInput})`,
                'i'
            )
            const regexPatternNumbers = new RegExp(`^\\+${escapedInput}`)
            return this.sortOptions(
                options.filter(
                    item =>
                        regexPattern.test(item.title) ||
                        regexPatternNumbers.test(item.label)
                ),
                filterString
            )
        }

        return options
    }

    handleOptionChange = option => {
        const { prefixInputName, onChange } = this.props
        const event = {
            target: {
                value: option.id,
                name: prefixInputName,
            },
        }

        this.setState({
            isOpen: false,
            isActive: false,
        })

        this.numberFieldRef.focus()
        onChange(event)
    }

    handleSelectRef = ref => {
        if (ref && ref.focus) {
            ref.focus()
            ref.setState({ isOpen: true, isFocused: true })
        }
    }

    getCountryCode = (countries, id) => {
        const phoneCode = (countries ? countries.payload : []).filter(
            country => country.id === id
        )[0]

        return phoneCode && phoneCode.code
    }

    getCountryPhoneCode = (countries, code) => {
        const phoneCode = (countries ? countries.payload : []).filter(
            country => country.id === code
        )[0]

        return _get(phoneCode, 'phone_code', '')
    }

    renderDropDown = () => {
        const { isOpen } = this.state
        const { stickyOptionsList, isFocusHighligh } = this.props

        return (
            <Dropdown isOpen={isOpen} className="col-24 col-sm-24 col-md-24">
                <SearchIconWrapper>
                    <img src="/images/icons/search.svg" alt="search" />
                </SearchIconWrapper>
                <NewSelectField
                    isFocusHighligh={isFocusHighligh}
                    stickyOptionsList={stickyOptionsList}
                    selectProps={{
                        ref: ref => {
                            this.selectRef = ref
                        },
                        options: this.getOptions(),
                        name: 'phone_code_option',
                        onChange: this.handleOptionChange,
                        optionRenderer: this.renderOption,
                        filterOptions: this.filterOptions,
                        onBlur: () => {
                            this.setState({ isOpen: false, isActive: false })
                        },
                        clearable: false,
                        closeOnSelect: false,
                        searchable: true,
                        placeholder: '',
                        autoComplete: 'do-not',
                        loadingPlaceholder: t`common.placeholder.loading`,
                        searchPromptText: t`common.placeholder.searchPrompt`,
                        disabled: !!this.props.disabled,
                    }}
                />
            </Dropdown>
        )
    }

    render() {
        const {
            placeholder,
            maxLength,
            minLength,
            onDrop,
            label,
            numberInputName,
            prefixInputName,
            errorText,
            value,
            phoneCode,
            readOnly,
            countries,
            required,
            warning,
            idPrefix,
            tooltip,
            isFocusHighligh,
        } = this.props
        const { isOpen, isActive, countryCodeName } = this.state

        return (
            <Container
                isOpen={isOpen}
                isActive={isActive}
                hasDanger={!!errorText}
                readOnly={readOnly}
                value={value}
                phoneCode={phoneCode}
                isTooltip={!!tooltip}
            >
                <label>
                    {label}
                    {required ? <span> *</span> : null}
                    {tooltip && (
                        <div
                            style={{
                                display: 'inline-flex',
                                marginLeft: '8px',
                                verticalAlign: 'middle',
                                color: '#fff',
                            }}
                        >
                            <TooltipIconWrapper
                                data-tip={tooltip.dataTip}
                                data-for={tooltip.dataFor}
                                id={tooltip.questionId || 'question-mark'}
                            >
                                <QuestionMarkTooltip />
                            </TooltipIconWrapper>
                            <ToolTip {...tooltip}>
                                <span>
                                    {tooltip.children ? (
                                        <tooltip.children />
                                    ) : (
                                        tooltip.text
                                    )}
                                </span>
                            </ToolTip>
                        </div>
                    )}
                </label>
                <StyledRow className="" readOnly={readOnly}>
                    <div className="col-9 col-sm-9 col-md-9 pr-0">
                        <PrefixContainer
                            isOpen={isOpen}
                            readOnly={readOnly}
                            hasValue={
                                !!this.getCountryPhoneCode(
                                    countries,
                                    phoneCode
                                ) || ''
                            }
                        >
                            <InputFlag>
                                {countryCodeName &&
                                    this.getCountryFlagImg(
                                        countryCodeName,
                                        'country_flag'
                                    )}
                            </InputFlag>
                            <InputField
                                isFocusHighligh={isFocusHighligh}
                                innerRef={ref => (this.prefixFieldRef = ref)}
                                className="phone-prefix"
                                type="text"
                                autoComplete="do-not"
                                placeholder={t`v2_common.placeholder.phonecode`}
                                readOnly={readOnly}
                                name={prefixInputName}
                                id={`${idPrefix}-${prefixInputName}`}
                                value={
                                    this.getCountryPhoneCode(
                                        countries,
                                        phoneCode
                                    ) || ''
                                }
                                onMouseDown={e => {
                                    e.preventDefault()
                                }}
                                onChange={e => e.preventDefault()}
                                onClick={this.handleClick}
                                onFocus={this.handleClick}
                                onTouchEnd={this.handleTouch}
                            />
                        </PrefixContainer>
                    </div>
                    <div className="col-15 col-sm-15 col-md-15 pl-0">
                        <NumberContainer>
                            <InputField
                                className="phone-number"
                                type="tel"
                                innerRef={ref => (this.numberFieldRef = ref)}
                                name={numberInputName}
                                id={`${idPrefix}-${numberInputName}`}
                                placeholder={placeholder}
                                value={value}
                                autoComplete="do-not-autofill"
                                readOnly={readOnly}
                                onKeyPress={this.onlyNumbers}
                                maxLength={maxLength}
                                minLength={minLength}
                                onDrop={onDrop}
                                onPaste={this.handlePhonePaste}
                                onClick={this.handlePhoneNumberClickEvent}
                                onChange={this.props.onChange}
                                onFocus={() => {
                                    this.setState({ isActive: true })
                                }}
                                onBlur={() => {
                                    this.setState({ isActive: false })
                                }}
                                // disabled={
                                //     !(
                                //         this.getCountryPhoneCode(
                                //             countries,
                                //             phoneCode
                                //         ) || ''
                                //     ) && !value
                                // }
                            />
                        </NumberContainer>
                    </div>
                    {this.renderDropDown()}
                    {errorText && (
                        <ErrorTextBox className={warning && 'warn'}>
                            {errorText}
                        </ErrorTextBox>
                    )}
                </StyledRow>
            </Container>
        )
    }
}

V2PhoneField.propTypes = {
    countries: PropTypes.shape({}),
    errorText: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    numberInputName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onDrop: PropTypes.func,
    onPaste: PropTypes.func,
    phoneCode: PropTypes.string,
    placeholder: PropTypes.string,
    prefixInputName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

V2PhoneField.defaultProps = {
    label: '',
    placeholder: '',
    autoComplete: 'off',
    value: '',
    phoneCode: '+370',
    maxLength: 14,
    minLength: 4,
    onDrop: e => e.preventDefault(),
    onPaste: null,
    readOnly: false,
    errorText: '',
}

export default V2PhoneField
