import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isMobileDevice } from "../../../main";

const SignatureWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 16rem;
    margin-bottom: 2.5rem;

    canvas {
        border-color: ${({ theme }) => theme.colors.primaryBorderColor};
    }

    &:before {
        content: "";
        background: #e5e5e5;
        height: .1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 2rem 5rem;
        right: 0;
        cursor: crosshair;
        pointer-events: none;
    }

    button {
        user-select: none;
    }

    ${({ isLanscape }) => isLanscape && `
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 99999;

        .signatureCanvas {
            background-color: white;
            height: 99%;
            z-index: 99999;
        }
    `}
`;

const ClearButton = styled.button`
    right: 0;
    top: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.midHoverColor};
    border: 0!important;
    margin: 0!important;
    outline: 0!important;
    z-index: 15;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    position: absolute;
    background: none;
`;

var document_width;
var document_height;
var isResizing;

var getWidth = () => document.body.clientWidth || window.innerWidth || document.documentElement.clientWidth;
var getHeight = () => document.body.clientHeight || window.innerHeight || document.documentElement.clientHeight;
var getPctChange = (oldNo, newNo) => ((oldNo - newNo) / newNo) * 100;

class SignBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dpr: 1,
            isLanscape: false,
        };
    }

    _clearCanvas = (e) => {
        e.preventDefault();
        const { handleSignatureState } = this.props;

        handleSignatureState("");
    }

    _didResize = () => {
        if (isMobileDevice()) {
            return (
                getPctChange(document_width, getWidth()) > 10 ||
                getPctChange(document_height, getHeight()) > 10
            );
        } else {
            return document_width !== getWidth() || document_height !== getHeight();
        }
    }

    _resize = () => {
        const { isLanscape } = this.props;
        clearTimeout(isResizing);
        isResizing = setTimeout(() => {
            if (this._didResize() && !isLanscape) {
                document_width = getWidth();
                document_height = getHeight();
                this._resizeCanvas();
                this.forceUpdate();
            }
        }, 300);
    }

    _resizeCanvas = (skipLandscape) => {
        const { signature } = this.props;
        let ratio =  Math.max(window.devicePixelRatio || 1, 1);
        let canvas = document.getElementById("sigCanvas");
        let canvasContext = canvas.getContext("2d");

        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvasContext.scale(ratio, ratio);
        this.setState({ dpr: window.devicePixelRatio });

        if (canvasContext && signature) {
            var signatureObj = new Image();
            signatureObj.onload = function() {
                canvasContext.drawImage(this, 0, 0);
            };

            signatureObj.src = signature;
        }

        if (!skipLandscape) {
            //this._checkLandscape();
        }
    }

    _handleDrawState = (e) => {
        const { handleSignatureState } = this.props;
        if (this.refs && this.refs.sigCanvas) {
            handleSignatureState(this.refs.sigCanvas.toDataURL());
            if (this.state.dpr !== window.devicePixelRatio) {
                this._resizeCanvas();
            }
        }
    }

    _checkLandscape = () => {
        if(isMobileDevice()) {
            if (window.innerHeight < window.innerWidth && !this.state.isLanscape) {
                this.setState({ isLanscape: true });
                setTimeout(() => {
                    this._resizeCanvas(true);
                    this.forceUpdate();
                }, 300);
                return true;
            } else if (window.innerHeight > window.innerWidth && this.state.isLanscape) {
                this.setState({ isLanscape: false });
                setTimeout(() => {
                    this._resizeCanvas(true);
                    this.forceUpdate();
                }, 300);
                return false;
            }
        } else if (this.state.isLanscape) {
            this.setState({ isLanscape: false });
            return false;
        };
        return false;
    }

    componentDidMount() {
        window.addEventListener('resize', this._resize);

        //eslint-disable-next-line
        if (activateSigPad) {
            //eslint-disable-next-line
            activateSigPad({
                drawOnly:true,
                drawBezierCurves:true,
                lineTop:200,
                bgColour: "transparent",
                defaultAction: "drawIt",
                penCap: "butt",
                lineColour: "#021B8E",
                penColour: "#021B8E",
                lineWidth: 1.5,
                stateChange: this._handleDrawState,
            });
        }
        document_width = getWidth();
        document_height = getHeight();
        this._resizeCanvas();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resize);
    }

    componentDidUpdate() {
        const canvas = document.getElementById("sigCanvas");
        const canvasContext = canvas.getContext("2d");
        if (canvas && canvasContext.strokeStyle !== "#021B8E") {
            canvasContext.strokeStyle = "#021B8E";
            canvasContext.lineWidth = 2;
        }
    }

    render() {
        const { clearLabel } = this.props;
        return (
            <SignatureWrapper id="signaturePadContainer">
                <canvas ref="sigCanvas" id="sigCanvas" className="signatureCanvas"/>
                <ClearButton className="clearButton" onClick={this._clearCanvas}>{clearLabel}</ClearButton>
                <input ref="signatureInput" id="signatureInput" type="hidden" className="output"/>
            </SignatureWrapper>
        )
    }
}

SignBox.propTypes = {
    handleSignatureState: PropTypes.func.isRequired,
    clearLabel:           PropTypes.string.isRequired,
    signature:            PropTypes.any
}

SignBox.defaultProps = {
    signature: ""
}

export default SignBox;