import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import t from '../../../../shared/translations'
import {
    PageViewContainer,
    InfoBlock,
} from '../../../../shared/components/V2/Layout'
import { ProfileContainer } from '../MyProfile'
import {
    getSkycopCareSubscriptionsHistory,
    getSkycopCareSubscriptions,
} from '../../../../shared/actions/v2_actions'
import {
    mobile,
    tabletSM,
} from '../../../../shared/components/helpers/styled_queries'
import { paths } from '../../../../shared/constants'
import { browserHistory } from 'react-router'
import moment from 'moment'
import { loadScreenOn, loadScreenOff } from '../../../../main'

const StyledProfileContainer = styled(ProfileContainer)`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;

    ${mobile`
        padding-left: 12px;
        padding-right: 12px;
    `}

    ${tabletSM`
        padding-left: 24px;
        padding-right: 24px;
    `}

    h1 {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 32px;
    }

    & .table-container {
        overflow-x: scroll;
    }
`

const TableContainer = styled.table`
    transition-timing-function: ease;
    transition: max-height 0.8s;
    color: #000000;
    width: 100%;
    border-collapse: collapse;
    border-style: solid;
    border-color: #b9b9b9;
    border-radius: 5px;

    th {
        background: rgba(0, 0, 0, 0.06);
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
    }

    td,
    th {
        padding: 10px 12px;
        border: 1px solid #b9b9b9;
        border-collapse: collapse;
        border-radius: 5px;
    }
`

class SubscriptionsHistory extends React.Component {
    state = { history: [], id: '' }

    async componentDidMount() {
        const { user, params } = this.props

        if (user && !user.active) {
            browserHistory.push(paths.PROFILE)
        } else {
            try {
                loadScreenOn()
                await getSkycopCareSubscriptions()

                if (params && params.id) {
                    const history = await getSkycopCareSubscriptionsHistory(
                        params.id
                    )
                    this.setState({ history, id: params.id })
                }
            } catch (e) {
                console.error(e)
            } finally {
                loadScreenOff()
            }
        }
    }

    mapStatusTexts = status => {
        switch (status) {
            case 'successful':
                return (
                    <p
                        style={{ color: '#0A833D', margin: 0 }}
                    >{t`sc_subscriptions.successful`}</p>
                )
            case 'failed':
                return (
                    <p
                        style={{
                            color: '#BA0000',
                            fontWeight: 'bold',
                            margin: 0,
                        }}
                    >{t`sc_subscriptions.failed`}</p>
                )
            default:
                return ''
        }
    }

    render() {
        const { trans } = this.props
        const { history } = this.state

        if (!trans || !history.length) {
            return null
        }

        return (
            <PageViewContainer>
                <StyledProfileContainer>
                    <Helmet>
                        <title>{`Skycop - ${t`sc_subscriptions_history.title`}`}</title>
                    </Helmet>
                    <InfoBlock title={t`sc_subscriptions_history.title`}>
                        <div className="table-container">
                            <TableContainer>
                                <thead>
                                    <tr>
                                        <th>{t`sc_subscriptions_history.label.payment_date`}</th>
                                        <th>{t`sc_subscriptions_history.label.protection_period`}</th>
                                        <th>{t`sc_subscriptions_history.label.plan`}</th>
                                        <th>{t`sc_subscriptions_history.label.total_cost`}</th>
                                        <th>{t`sc_subscriptions_history.label.people_covered`}</th>
                                        <th>{t`sc_subscriptions_history.label.status`}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history.map(
                                        ({
                                            id,
                                            status,
                                            planName,
                                            paymentDate,
                                            purchasedFrom,
                                            purchasedTo,
                                            personCount,
                                            totalAmount,
                                        }) => (
                                            <tr key={id}>
                                                <td>
                                                    {formatDate(
                                                        paymentDate.date
                                                    )}
                                                </td>
                                                <td>{`${formatDate(
                                                    purchasedFrom.date
                                                )} - ${formatDate(
                                                    purchasedTo.date
                                                )}`}</td>
                                                <td>{t`sc_subscriptions.plan.${planName}`}</td>
                                                <td>{`€${(
                                                    totalAmount / 100
                                                ).toFixed(2)}`}</td>
                                                <td>
                                                    {personCount}{' '}
                                                    {personCount === 1
                                                        ? t`sc_subscriptions.subheader.person_count`
                                                        : t`sc_subscriptions.subheader.persons_count`}
                                                </td>
                                                <td>
                                                    {this.mapStatusTexts(
                                                        status
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </TableContainer>
                        </div>
                    </InfoBlock>
                </StyledProfileContainer>
            </PageViewContainer>
        )
    }
}

function formatDate(date) {
    return date ? moment(date).format('MMMM DD, YYYY') : ''
}

function mapStateToProps(state) {
    return {
        trans: state.translations,
        authenticated: state.auth.authenticated,
        user: state.user,
        subscriptions: state.subscriptions,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(SubscriptionsHistory)
