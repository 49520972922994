import React from 'react'
import styled from 'styled-components'
import {
    desktop,
    tablet,
    mobile,
} from '../../../../shared/components/helpers/styled_queries'
import { LightButton } from './Button'

const InfoContainer = styled.div`
    h1 {
        padding: 0 8px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;

        ${desktop`
            padding: 8px 12px;
            text-align: left;
        `}

        ${tablet`
            font-size: 24px;
        `}

        ${mobile`
            font-size: 22px;
        `}
    }
    
    &.pdt-40 {
        padding-top: 40px;
    }

    .form-group {
        margin-bottom: 0 !important;
    }

    &.no-items {
        h1 {
            margin-bottom: 2px;
        }
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    h1 {
        margin: 0;
    }

    ${tablet`
            margin-bottom: 32px;
        `}

    ${mobile`
            margin-bottom: 32px;
            flex-direction: column;
            align-items: flex-start;
        `}
`
export const InfoBlock = ({
    title,
    children,
    handleClick,
    buttonText,
    ...props
}) => {
    return (
        <InfoContainer {...props}>
            <Header>
                <h1>{title}</h1>
                {handleClick && (
                    <LightButton onClick={handleClick}>
                        {buttonText}
                    </LightButton>
                )}
            </Header>
            {children}
        </InfoContainer>
    )
}
