import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { hideModal } from "../../../shared/actions/v2_actions";
import Modal from "../../../shared/components/V2/Modal";
import { CloseIcon } from "../../../shared/components/V2/V2Icons";
import OnboardingSlider from "./OnboardingSlider";
import { modalTypes } from "../../../shared/constants";
import { desktop } from "../../../shared/components/helpers/styled_queries";

const ModalContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const CloseBtnContainer = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2400000;

    ${desktop`
        top: 24px;
        right: 24px;
    `}

    &:hover {
        cursor: pointer;
    }
`;

class OnboardingModal extends React.Component {
    render() {
        const { hideModal } = this.props;

        return (
            <Modal width="90%" height="auto" maxHeight={"98%"} maxWidth="1024px" modalType={modalTypes.onboardingModal}>
                <ModalContainer>
                    <CloseBtnContainer onClick={hideModal}>
                        <CloseIcon width={32} height={32} />
                    </CloseBtnContainer>
                    <OnboardingSlider />
                </ModalContainer>
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ hideModal }, dispatch);
}

export default connect(
    null,
    mapDispatchToProps
)(OnboardingModal);
