import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { getApiClient } from '../../main'
import { loadScreenOff, loadScreenOn } from '../../main'
import MissingSignature from './MissingSignature'
import MissingDocuments from './MissingDocuments'
import ThankYou from './ThankYou'
import { loginPageHide, loginPageShow } from '../../main'

export const STEPS = {
    MISSING_DOCUMENTS: 'MISSING_DOCUMENTS',
    MISSING_SIGNATURE: 'MISSING_SIGNATURE',
    THANK_YOU: 'THANK_YOU',
}

export const AGREEMENT_TYPES = {
    PASSENGER: 'PASSENGER',
    USER: 'USER',
}
class MissingDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            step: STEPS.MISSING_SIGNATURE,
            missingDetails: null,
            type: AGREEMENT_TYPES.USER,
        }
    }

    UNSAFE_componentWillUpdate() {
        loginPageShow(true)
    }

    componentWillUnmount() {
        loginPageHide()
    }

    componentDidMount() {
        loginPageShow(true)

        const { location } = this.props
        const { query } = location

        if (location && query) {
            const apiClient = getApiClient()
            loadScreenOn()

            const claimId = query['claimId']

            const urlParams = new URLSearchParams(window.location.search)
            const passengerId = urlParams.get('passengerId')
            let url = `/api/claim/has-signature/${claimId}`
            if (!!passengerId) {
                url += `?passengerId=${passengerId}`
                this.setState({ type: AGREEMENT_TYPES.PASSENGER })
            }

            apiClient
                .get(url)
                .then(({ data }) => {
                    const { type } = this.state
                    if (type === AGREEMENT_TYPES.USER) {
                        const stepVal = data.payload.isMissingSignature
                            ? STEPS.MISSING_SIGNATURE
                            : !data.payload.missingDocuments.length
                            ? STEPS.THANK_YOU
                            : STEPS.MISSING_DOCUMENTS

                        this.setState({
                            step: stepVal,
                        })
                    }
                    if (type === AGREEMENT_TYPES.PASSENGER) {
                        const stepVal = data.payload.isMissingSignature
                            ? STEPS.MISSING_SIGNATURE
                            : STEPS.THANK_YOU

                        this.setState({
                            step: stepVal,
                        })
                    }

                    this.setState({ missingDetails: data.payload })
                })
                .catch(error => {
                    console.error(error)
                    browserHistory.push('/')
                })
                .finally(() => loadScreenOff())
        } else {
            browserHistory.push('/')
            loginPageHide()
        }
    }

    handleNext = () => {
        const { step, missingDetails, type } = this.state

        if (type === AGREEMENT_TYPES.PASSENGER) {
            this.setState({ step: STEPS.THANK_YOU })
            return
        }

        if (step === STEPS.MISSING_SIGNATURE) {
            if (!missingDetails.missingDocuments.length) {
                this.setState({ step: STEPS.THANK_YOU })
            } else {
                this.setState({ step: STEPS.MISSING_DOCUMENTS })
            }
        }
        if (step === STEPS.MISSING_DOCUMENTS) {
            this.setState({ step: STEPS.THANK_YOU })
        }
    }

    render() {
        const { step, missingDetails, type } = this.state

        if (!missingDetails) return null

        switch (step) {
            case STEPS.MISSING_SIGNATURE: {
                return (
                    <MissingSignature
                        handleNext={this.handleNext}
                        missingDetails={missingDetails}
                        type={type}
                    />
                )
            }
            case STEPS.MISSING_DOCUMENTS: {
                return (
                    <MissingDocuments
                        handleNext={this.handleNext}
                        missingDetails={missingDetails}
                    />
                )
            }
            case STEPS.THANK_YOU: {
                return <ThankYou />
            }
            default: {
                browserHistory.push('/')
            }
        }
    }
}

export default MissingDetails
