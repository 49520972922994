import {
    Icon1,
    Icon2,
    Icon3,
} from './icons'

const stepTitles = [
    `v2.sidebar_category.eligibility_check`,
    `v2.sidebar_step.flight_itinerary`,
    `v2.sidebar_step.disruption_details`,
    `v2.sidebar_step.disruption_reason`,
    `v2.sidebar_step.flight_details`,
    `v2.sidebar_category.additional_details`,
    `v2.sidebar_step.passenger_info`,
    `v2.sidebar_step.signature`,
    `v2.sidebar_step.documents`,
    `v2.sidebar_category.done`,
]
export const STEPS = [
    {
        text: stepTitles[0],
        icon: Icon1,
        type: 'number',
        index: 1,
        checkedIndex: 4,
        initial: true,
    },
    //   {
    //       text: stepTitles[1],
    //       icon: FlightItineraryIcon,
    //       path: mapSteps[0],
    //       index: 0,
    //   },
    //   {
    //       text: stepTitles[2],
    //       icon: DisruptionDetailsIcon,
    //       path: mapSteps[1],
    //       index: 1,
    //   },
    //   {
    //       text: stepTitles[3],
    //       icon: DisruptionReasonIcon,
    //       path: mapSteps[2],
    //       index: 2,
    //   },
    //   {
    //       text: stepTitles[4],
    //       icon: FLightDetailsIcon,
    //       path: mapSteps[3],
    //       index: 3,
    //   },
    {
        text: stepTitles[5],
        icon: Icon2,
        type: 'number',
        index: 4,
        checkedIndex: 8,
    },
    //   {
    //       text: stepTitles[6],
    //       icon: PassengerInformationIcon,
    //       path: mapSteps[4],
    //       index: 4,
    //   },
    //   {
    //       text: stepTitles[7],
    //       icon: SignatureIcon,
    //       path: mapSteps[5],
    //       index: 5,
    //   },
    //   {
    //       text: stepTitles[8],
    //       icon: DocumentsIcon,
    //       path: mapSteps[6],
    //       index: 6,
    //   },
    {
        text: stepTitles[9],
        icon: Icon3,
        path: '/thanks',
        type: 'number',
        index: 8,
        checkedIndex: 8,
    },
]
