import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import Helmet from "react-helmet";
import styled from "styled-components";
import { loadScreenOff } from '../../main';
import { saveUserClaim, clearErrors } from '../../shared/actions';
import { Button } from '../../shared/components/Buttons';
import t from '../../shared/translations';
import { paths } from '../../shared/constants';

const ChangeDetailsButton = styled(Button)`
    float: left;
    margin-top: 2.5rem;
    width: auto;
    @media screen and (max-width: 767px) {
        float: unset;
    }
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
    }
`;

const SaveUserClaimButton = ChangeDetailsButton.extend`
    margin-right: 2.5rem;
`;

class SorryPage extends Component {
    componentDidMount() {
        setTimeout(function () {
            loadScreenOff();
        }, 1);
        if (this.props.claim && this.props.claim.data) {
            this.setState(this.props.claim.data) 
        } else {
            this.props.saveUserClaim(undefined);
            browserHistory.push("/");
        }
    }

    componentWillUnmount() {
        this.props.clearErrors();
    }

    UNSAFE_componentWillMount() {
        if (this.props.claim && this.props.claim.data) {
            this.setState(this.props.claim.data);
        }
        if (this.props.user && !this.props.user.active) {
            browserHistory.push(paths.PROFILE);
        }
    }

    generateSorryFlights() {
        if (this.state && this.state.departedFromCity) {
            return (
                `${this.state.departedFromCity} (${this.state.departedFromIata}) → ${this.state.arrivedToCity} (${this.state.arrivedToIata})`
            )
        }
    }

    renderErrorReasons = () => {
        const { errorMessage } = this.props;
        if (errorMessage === "airline.invalid") {
            return <li>{t`airline.invalid`}</li>
        } else {
            var result = [];

            errorMessage.forEach((item) => {
                if (result.indexOf(item) < 0) {
                    result.push(item);
                }
            });

            return result.map((item) =>
                <li key={item}>{item}</li>
            );
        }
    }

    render() {
        const { trans, errorMessage } = this.props;
        if (trans) {
            return (
                <div className="container">
                    <Helmet>
                        <title>{`Skycop - ${t`sorry_page.title.sorry_to_say`}`}</title>
                    </Helmet>
                    <div className="row">
                        <div className="h-ml20 claim-panel col-xs-24 col-lg-16 h-pb60">
                            <h1 className="h-pt10 h-mb10 h-mb40-md">{t`sorry_page.title.sorry_to_say`}</h1>
                            <div className="col-md-16 h-c0 h-fs14 sorry-holder h-mb25">
                                <b>{t`sorry_page.description_bold_part1`} </b>
                                <div className="sorry-flights">{this.generateSorryFlights()}&nbsp;</div>
                                <br/>
                                <ul>
                                    {
                                        errorMessage && this.renderErrorReasons()
                                    }
                                </ul>
                            </div>
                            <SaveUserClaimButton
                                onClick={() => {
                                    this.props.saveUserClaim(undefined);
                                    browserHistory.push("/");
                                }}>
                                {t`sorry_page.button.try_new_flight`}
                            </SaveUserClaimButton>
                            <ChangeDetailsButton
                                onClick={() => {
                                    browserHistory.push("/");
                                }}>
                                {t`sorry_page.button.change_details`}
                            </ChangeDetailsButton>
                        </div>
                    </div>
                </div>
            );
        } else return null;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ saveUserClaim, clearErrors }, dispatch);
}

export default connect(
    state => ({
        claim: state.claim,
        trans: state.translations,
        errorMessage: state.error.errorMessage,
    }),
    mapDispatchToProps
)(SorryPage);
