import { V2_ACTIONS } from '../../actions/actionTypes';

export default function (state = [], action) {
    const { type, payload } = action;
    
    switch (type) {
        case V2_ACTIONS.SELECTED_AIRLINES:
            return payload;
        default:
    }
    return state;
}