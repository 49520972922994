import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { bindActionCreators } from 'redux'
import latinize from 'latinize'
import moment from 'moment'
import _ from 'lodash'
import _get from 'lodash/get'
import FileDragAndDrop from 'react-file-drag-and-drop'
import t from '../../../../shared/translations'
import { Col, Row } from '../../../../shared/components/V2/Layout'
import StepView from '../../components/StepView'
import {
    bytesToSize,
    scrollComponentToView,
    isProduction,
    addBeforeUnload,
    isMobileDevice,
    getApiClient,
} from '../../../../main'
import {
    CloseIcon,
    DocumentIcon,
    UploadIconV2,
    FailedDocIcon,
    Document,
} from '../../../../shared/components/V2/V2Icons'

import {
    resetClaimDocuments,
    uploadDocumentAlternative,
    uploadDocument,
    setV2Claim,
    addUserDocuments,
    removeUserDocument,
    validateStepFive,
    setProgress,
    checkUUID,
    setNavStep,
    getClaimDraftDocuments,
    setPreloadedClaimStep,
    getAirlineById,
    showModal,
    setSelectedAirports,
    setUsedAirports,
    postCodeFieldCheck,
    setSelectedAirlines,
    clearSuggestedFlight,
    getAirportById,
    getSuggestedFlights,
    setUsedAirlines,
} from '../../../../shared/actions/v2_actions'
import { mapSteps } from '../../../../shared/reducers/V2/reducer_steps'
import { V2_prePath, V2_STEPS, modalTypes } from '../../../../shared/constants'

import {
    StyledErrorsBox,
    DragDropContainer,
    StyledP,
    FileInputLabel,
    FileInput,
    FileUploadText,
    TextContainer,
    DocumentsContainer,
    DocumentOuter,
    DocumentInner,
    DocumentProgress,
    DocumentName,
    RemoveButtonContainer,
    DocumentSize,
    StyledStepContent,
    IconDisplay,
    DocsRow,
    DragTitle,
    DragDescription,
    UploadedDocsWrapper,
    DocumentWrapper,
    IconWrapper,
    DocumentStatusWrapper,
    ClearIconWrapper,
    DocumentTitle,
    DocumentStatus,
    ReuploadWrapper,
    ReuploadTitle,
} from './flightDocumentsStyles'
import SidebarProgress from '../../components/SidebarProgress'
import {
    getAirlines,
    getPayoutPlans,
    logKibana,
} from '../../../../shared/actions'
import { fireGtagEvent } from '../../../../shared/services/events'
import { Reupload } from '../../../../shared/components/svgIcons'
import AlternativeTransportation from './alternativeTransportation'

// const labelExperimentId = '0XDCrX-JQ22frMoLSEa6PA'

export const CloseCircle = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.75"
            y="0.75"
            width="14.5"
            height="14.5"
            rx="7.25"
            stroke="#717171"
            stroke-width="1.5"
        />
        <path
            d="M10.1842 10.0002L5.82031 6"
            stroke="#717171"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="square"
            stroke-linejoin="round"
        />
        <path
            d="M10.1842 6L5.82031 10.0002"
            stroke="#717171"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="square"
            stroke-linejoin="round"
        />
    </svg>
)

export const alternativeTransports = {
    HAS_NO_ALTERNATIVE_REASON: 'has_no_alternative_reason',
    HAS_ALTERNATIVE_REASON: 'has_alternative_reason',
    ALTERNATIVE_TRANSPORT_FLIGHT: 'atf',
    ALTERNATIVE_TRANSPORT_TRAIN: 'att',
    ALTERNATIVE_TRANSPORT_BUS_CAR: 'atb',
    ALTERNATIVE_TRANSPORT_DR: 'atd',
}
class FlightDocuments extends React.Component {
    state = {
        reservationNumber: '',
        preselectLoading: false,
        componentState: {
            flightNumber: '',
            canRender: false,
            uploadErrors: [],
            // change to false
            hasAlternativeTransport: null,
            alternativeTransportType: null,
            isDocumentsUploadClicked: false,
            uploadErrorsAlternative: [],
            canRenderAlternative: false,
            alternativeFlightComponentState: {
                isDirect: true,
                stopsCount: 0,
                stopsState: [],
                flights: [],
                airportArrival: '',
                airportDeparture: '',
                airlinesInputValue: '',
                viewDate: '',
            },
        },
        innerWidth: window.innerWidth,
        ...this.props.V2Claim,
    }

    isFaqOpen = false
    dragContainer = React.createRef()
    dragAlternativeContainer = React.createRef()

    preventDragDrop = e => {
        if (!e.target.closest('#dd-box')) {
            // eslint-disable-next-line no-restricted-globals
            ;(e || event).preventDefault()
            e.dataTransfer.effectAllowed = 'none'
            e.dataTransfer.dropEffect = 'none'
        } else {
            e.dataTransfer.effectAllowed = 'initial'
            e.dataTransfer.dropEffect = 'initial'
        }
    }

    componentDidMount() {
        try {
            setNavStep(mapSteps.indexOf(this.props.route.path))
        } catch (e) {}

        scrollComponentToView('pageTopNavbar', false, true)
        window.addEventListener('resize', this._onResize)
        window.addEventListener('dragenter', this.preventDragDrop)
        window.addEventListener('dragover', this.preventDragDrop)
        window.addEventListener('drop', this.preventDragDrop)

        const {
            claim,
            claimId,
            claimPreloaded,
            flightDocs,
            setParentState,
        } = this.props

        if (claim && !claimPreloaded[V2_STEPS.STEP_5]) {
            if (claimId) {
                this.props.getClaimDraftDocuments(claimId)
            }

            this.setState(
                {
                    componentState: {
                        ...claim.componentState,
                        alternativeFlightComponentState: {
                            ...this.state.componentState
                                .alternativeFlightComponentState,
                        },
                    },
                },
                () => {
                    this.props.setPreloadedClaimStep([V2_STEPS.STEP_5])
                }
            )
        }

        if (
            (!claim || !claim.airport_arrival || !claim.airport_departure) &&
            isProduction()
        ) {
            setNavStep(0)
            browserHistory.replace(V2_prePath)
        }

        setParentState({
            nextDisabled: false,
            buttonText: '',
        })

        if (!!flightDocs.documents.length) {
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    canRender: true,
                    canRenderAlternative: true,
                },
            }))
        }

        addBeforeUnload(() => this.props.showModal(modalTypes.claimDraftModal))
    }

    componentDidUpdate(prevProps) {
        const { setParentState, flightDocs, isDisabled, trans } = this.props

        if (
            prevProps.flightDocs.documents.length !==
            flightDocs.documents.length
        ) {
            if (!!flightDocs.documents.length) {
                setTimeout(() => {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            canRender: true,
                            canRenderAlternative: true,
                        },
                    }))
                }, 300)
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        canRender: false,
                        canRenderAlternative: false,
                    },
                }))
            }
        }

        if (prevProps.flightDocs.uploading !== flightDocs.uploading) {
            setParentState({
                nextDisabled: flightDocs.uploading,
            })
        }

        if (!isDisabled && this.checkNextStep()) {
            setParentState({
                nextDisabled: true,
            })
        } else if (isDisabled && !this.checkNextStep()) {
            setParentState({
                nextDisabled: false,
            })
        }

        if (
            trans['v2.sidebar_step.flight_itinerary'] !==
            prevProps.trans['v2.sidebar_step.flight_itinerary']
        ) {
            this.forceUpdate()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize)
        window.removeEventListener('dragenter', this.preventDragDrop)
        window.removeEventListener('dragover', this.preventDragDrop)
        window.removeEventListener('drop', this.preventDragDrop)
    }

    _onResize = () => {
        const width = window.innerWidth

        this.setState({ innerWidth: width })
    }

    _handleNextStep = () => {
        const {
            setV2Claim,
            flightDocs,
            setProgress,
            claimId,
            alternativeTransEnabled,
            resetClaimDocuments,
        } = this.props
        const { documents } = flightDocs
        const newState = { ...this.state }

        const { componentState } = this.state
        const {
            isDocumentsUploadClicked,
            hasAlternativeTransport,
        } = componentState

        if (alternativeTransEnabled && !isDocumentsUploadClicked) {
            this.setState(prevState => ({
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    isDocumentsUploadClicked: true,
                },
            }))
            resetClaimDocuments()
            return
        }

        const docs = documents
            .filter(doc => !!doc.id)
            .map(doc => ({
                originalName: doc.file.name,
                id: doc.id,
                type: 'res',
            }))

        newState.documents = docs

        if (!!newState.reservationNumber) {
            const url = `/api/claim/${claimId}/booking-reference`
            const apiClient = getApiClient()

            apiClient
                .post(url, { reservationNumber: newState.reservationNumber })
                .catch(error => {
                    console.error(error)
                })
        }

        if (!!newState.componentState.alternativeTransportType) {
            const { alternativeFlightComponentState } = componentState
            const { flights } = alternativeFlightComponentState
            let data = new FormData()
            if (
                newState.componentState.alternativeTransportType === 'atf' &&
                hasAlternativeTransport ===
                    alternativeTransports.HAS_ALTERNATIVE_REASON
            ) {
                flights &&
                    flights.length > 0 &&
                    flights.forEach(val => {
                        data.append(
                            `alternativeFlights[${val.flightOrder}][airportDeparture]`,
                            val.airportDeparture
                        )
                        data.append(
                            `alternativeFlights[${val.flightOrder}][airportArrival]`,
                            val.airportArrival
                        )
                        data.append(
                            `alternativeFlights[${val.flightOrder}][timeDeparture]`,
                            val.timeDeparture
                        )
                        data.append(
                            `alternativeFlights[${val.flightOrder}][airline]`,
                            val.airline
                        )
                        data.append(
                            `alternativeFlights[${val.flightOrder}][number]`,
                            val.number
                        )
                        data.append(
                            `alternativeFlights[${val.flightOrder}][flightOrder]`,
                            val.flightOrder
                        )
                    })
            }

            const url = `/api/claim/${claimId}/alternative-transport`
            const apiClient = getApiClient()

            data.append(
                'alternativeTransport',
                newState.componentState.alternativeTransportType
            )

            apiClient.post(url, data).catch(error => {
                console.error(error)
            })
        }

        setV2Claim(newState)
        setProgress(90)
        this.props.triggerAnalytics()
        return true
    }

    fetchSuggestions = arrayIndex => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { flights } = alternativeFlightComponentState

        const { getSuggestedFlights, showModal } = this.props

        const flight = flights[arrayIndex]

        if (flight && flight.error) {
            clearSuggestedFlight(arrayIndex)
            return
        }

        const callback = (data, depIata, arrIata) => {
            showModal(modalTypes.flightSuggestModalCodeshares, {
                callBack: (item, preselectId) =>
                    this.handlePreselect(arrayIndex, item, preselectId),
                data,
                selected: flight.preselectId,
                route: { depIata, arrIata },
            })
            this.forceUpdate()
        }

        if (
            flight &&
            !flight.error &&
            flight.timeDeparture &&
            flight.airportDeparture &&
            flight.airportArrival
        ) {
            let depAirport = getAirportById(flight.airportDeparture)
            let arrAirport = getAirportById(flight.airportArrival)
            if (depAirport && arrAirport) {
                getSuggestedFlights(
                    {
                        flightDate: flight.timeDeparture,
                        depAirport: depAirport.iata,
                        arrAirport: arrAirport.iata,
                    },
                    arrayIndex,
                    callback
                )
            }
        }
    }

    fetchPayouts = () => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { flights } = alternativeFlightComponentState

        const { getPayoutPlans, v2_amount, regulation } = this.props

        if (v2_amount || regulation === 'ca') {
            const hasAirlines =
                flights.length === flights.filter(f => !!f.airline).length
            const directFlight = flights.length === 1

            if (hasAirlines) {
                const allAirlines = (flights || []).map(f => f.airline)

                if (directFlight) {
                    const flightNumber = flights[0].number
                    const flightAirline = flights[0].airline
                    const flightDate = flights[0].timeDeparture
                    const airportDeparture = flights[0].airportDeparture
                    const airportArrival = flights[0].airportArrival

                    if (
                        flightNumber &&
                        flightAirline &&
                        airportDeparture &&
                        airportArrival &&
                        flightDate
                    ) {
                        getPayoutPlans(
                            v2_amount,
                            allAirlines,
                            regulation === 'ca',
                            flightNumber,
                            flightAirline,
                            flightDate,
                            airportDeparture,
                            airportArrival
                        )
                    } else {
                        getPayoutPlans(
                            v2_amount,
                            allAirlines,
                            regulation === 'ca'
                        )
                    }
                } else {
                    getPayoutPlans(v2_amount, allAirlines, regulation === 'ca')
                }
            } else {
                getPayoutPlans(v2_amount, [], regulation === 'ca')
            }
        }
    }

    handlePreselect = (flightIndex, item, preselectId) => {
        const {
            getAirlines,
            setUsedAirlines,
            setSelectedAirlines,
            postCodeFieldCheck,
        } = this.props

        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { flights } = alternativeFlightComponentState

        this.setState({ preselectLoading: true })

        const modifyFlight = { ...flights[flightIndex] }

        getAirlines(item.airline_iata, true).then(res => {
            const airline = res.filter(
                asset => asset.iata === item.airline_iata
            )
            const selectedAirline = airline.map(air => {
                air.value = air.id
                air.name = 'airline'
                air.label = `${air.title} (${air.iata})`
                return air
            })

            setUsedAirlines(selectedAirline).then(r => {
                this.references &&
                    Object.keys(this.references).forEach(key => {
                        if (key.includes('airline')) {
                            this.references[key] &&
                                this.references[key].loadOptions &&
                                this.references[key].loadOptions(
                                    selectedAirline
                                )
                        }
                    })
                modifyFlight.number = item.flight_number
                modifyFlight.airline = selectedAirline[0].value
                modifyFlight.prefillLetters = item.airline_iata
                modifyFlight.preselectId = preselectId
                const resultFlights = [
                    ...flights.slice(0, flightIndex),
                    modifyFlight,
                    ...flights.slice(flightIndex + 1, flights.length),
                ]
                this.setState(
                    {
                        componentState: {
                            ...componentState,
                            alternativeFlightComponentState: {
                                ...alternativeFlightComponentState,
                                flights: resultFlights,
                            },
                        },
                    },
                    () => {
                        const airlines = resultFlights.reduce(
                            (result, item) => {
                                const airline = this.props.usedAirlines.filter(
                                    a => a.id === item.airline
                                )[0]
                                return airline ? [...result, airline] : result
                            },
                            []
                        )

                        setSelectedAirlines(airlines).then(() =>
                            postCodeFieldCheck()
                        )
                        this.fetchPayouts()
                        this.setState({ preselectLoading: false })
                    }
                )
            })
        })
    }

    validateFlightDates = index => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { flights } = alternativeFlightComponentState

        const firstFlightDate = _get(flights[0], 'timeDeparture', '')

        if (firstFlightDate) {
            const newFlights = flights.map((flight, i) => {
                let newFlight = { ...flight }
                if (i > 0 && flight.timeDeparture) {
                    if (
                        moment(firstFlightDate).diff(
                            flight.timeDeparture,
                            'day'
                        ) > 0
                    ) {
                        newFlight.error = t`v2_claim.flight_details.invalid_date`
                    } else {
                        newFlight.error = ''
                    }
                }
                return newFlight
            })

            this.setState(
                {
                    componentState: {
                        ...componentState,
                        alternativeFlightComponentState: {
                            ...alternativeFlightComponentState,
                            flights: newFlights,
                        },
                    },
                },
                () => this.fetchSuggestions(index)
            )
        } else {
            this.fetchSuggestions(index)
        }
    }

    getIata = id => {
        const { usedAirlines } = this.props
        let airline
        let iata = ''

        if (id) {
            airline = usedAirlines.filter(airline => airline.id === id)[0]
            iata = airline && airline.iata
        }

        return iata
    }

    handleAirlineChange = (e, index) => {
        const {
            usedAirlines,
            setSelectedAirlines,
            postCodeFieldCheck,
        } = this.props
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { flights } = alternativeFlightComponentState

        const newFlights = [...flights]
        const target = _get(e, 'target', e)

        if (!e || !target) {
            return
        }

        newFlights[index][target.name] = target.value

        if (target.name === 'airline') {
            const airlines = newFlights.reduce((result, item) => {
                const airline = usedAirlines.filter(
                    a => a.id === item.airline
                )[0]
                return airline ? [...result, airline] : result
            }, [])

            setSelectedAirlines(airlines).then(() => postCodeFieldCheck())
            newFlights[index]['prefillLetters'] = this.getIata(target.value)
            this.setState({
                componentState: {
                    ...componentState,
                    alternativeFlightComponentState: {
                        ...alternativeFlightComponentState,
                        flights: newFlights,
                    },
                },
            })
        } else if (target.name === 'timeDeparture') {
            delete newFlights[index]['preselectId']
            this.setState(
                {
                    componentState: {
                        ...componentState,
                        alternativeFlightComponentState: {
                            ...alternativeFlightComponentState,
                            flights: newFlights,
                            viewDate: target.value,
                        },
                    },
                },
                () => {
                    this.validateFlightDates(index)
                }
            )
        } else {
            this.setState({
                componentState: {
                    ...componentState,
                    alternativeFlightComponentState: {
                        ...alternativeFlightComponentState,
                        flights: newFlights,
                    },
                },
            })
        }
    }

    setAirport = event => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { selectedAirports } = this.props
        const e = _get(event, 'target', event)

        if (
            !e ||
            !e.name ||
            !e.value ||
            selectedAirports.find(a => a.id === e.value)
        ) {
            return
        }
        this.setState(
            {
                componentState: {
                    ...componentState,
                    alternativeFlightComponentState: {
                        ...alternativeFlightComponentState,
                        [e.name]: e.value,
                    },
                },
            },
            () => {
                this._remapFlights()
                this.handleSelectedAirports()
            }
        )
    }

    _remapFlights = () => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState

        let flights = this._mapFlights()

        if (!flights || flights.length === 0) {
            return
        }

        this.setState({
            componentState: {
                ...componentState,
                alternativeFlightComponentState: {
                    ...alternativeFlightComponentState,
                    flights,
                },
            },
        })
    }

    _mapFlights = () => {
        const { componentState } = this.state
        const {
            alternativeFlightComponentState: {
                airportArrival,
                airportDeparture,
                isDirect,
                stopsState,
            },
        } = componentState
        const mappedConnectingFlights = []
        const flightsCount = stopsState.length + 1
        const sortedStops = stopsState.sort((a, b) => a.id - b.id)

        if (!airportArrival || !airportDeparture) {
            return []
        }

        if (isDirect && flightsCount === 1) {
            return [
                {
                    airportDeparture,
                    airportArrival,
                    flightOrder: 0,
                },
            ]
        }

        const firstFlight = {
            airportDeparture,
            airportArrival: _get(sortedStops, '[0].value', ''),
            flightOrder: 0,
        }
        const lastFlight = {
            airportDeparture: _get(
                sortedStops,
                `[${sortedStops.length - 1}].value`,
                ''
            ),
            airportArrival,
            flightOrder: sortedStops.length,
        }

        if (sortedStops.length > 1) {
            for (let i = 0; i < sortedStops.length; i++) {
                const from = _get(sortedStops[i], 'value', '')
                const to = _get(sortedStops[i + 1], 'value', '')
                if (from && to) {
                    mappedConnectingFlights.push({
                        airportDeparture: from,
                        airportArrival: to,
                        flightOrder: i + 1,
                    })
                }
            }
        }

        mappedConnectingFlights.unshift(firstFlight)
        mappedConnectingFlights.push(lastFlight)

        return mappedConnectingFlights
    }

    clearStopAirport = value => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { flights } = alternativeFlightComponentState

        const filteredFlights = flights.filter(
            f => ![f.airportArrival, f.airportDeparture].includes(value)
        )

        const filteredStates = alternativeFlightComponentState.stopsState.filter(
            f => f.value !== value
        )

        this.setState(
            {
                componentState: {
                    ...componentState,
                    alternativeFlightComponentState: {
                        ...alternativeFlightComponentState,
                        stopsState: filteredStates,
                        flights: filteredFlights,
                    },
                },
            },
            () => {
                this._remapFlights()
                this.handleSelectedAirports()
            }
        )
    }

    handleSelectedAirports = () => {
        const { setSelectedAirports, usedAirports } = this.props
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const {
            stopsState,
            airportArrival,
            airportDeparture,
        } = alternativeFlightComponentState
        const selected = []

        const getAirport = id => usedAirports.find(a => a.id === id)
        ;(stopsState || []).forEach(s => {
            if (s && s.value && getAirport(s.value)) {
                selected.push(getAirport(s.value))
            }
        })

        if (airportArrival && getAirport(airportArrival)) {
            selected.push(getAirport(airportArrival))
        }

        if (airportDeparture && getAirport(airportDeparture)) {
            selected.push(getAirport(airportDeparture))
        }

        setSelectedAirports(selected)
    }

    clearAirport = (isConnecting, name, value) => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        if (name) {
            this.setState(
                {
                    componentState: {
                        ...componentState,
                        alternativeFlightComponentState: {
                            ...alternativeFlightComponentState,
                            [name]: '',
                        },
                    },
                },
                () => {
                    this._remapFlights()
                    this.handleSelectedAirports()
                }
            )
        }

        if (isConnecting) {
            this.clearStopAirport(value)
        } else {
            const { selectedAirports, setSelectedAirports } = this.props
            setSelectedAirports(
                (selectedAirports || []).filter(a => a.id !== value)
            )
            // this._remapFlights()
        }

        this.props.setUsedAirports([], true)
    }

    _handlePrevStep = () => {
        const { setV2Claim } = this.props
        setV2Claim(this.state)
    }

    fileOnDragOver = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el) {
            el.classList.add('hover')
        }
    }

    fileOnDragLeave = () => {
        const el = this.dragContainer && this.dragContainer.current

        if (el && el.classList.contains('hover')) {
            el.classList.remove('hover')
        }
    }

    fileOnDragOverAlternative = () => {
        const el =
            this.dragAlternativeContainer &&
            this.dragAlternativeContainer.current

        if (el) {
            el.classList.add('hover')
        }
    }

    fileOnDragLeaveAlternative = () => {
        const el =
            this.dragAlternativeContainer &&
            this.dragAlternativeContainer.current

        if (el && el.classList.contains('hover')) {
            el.classList.remove('hover')
        }
    }

    handleDrop = dataTransfer => {
        let e = { target: { files: [] } }
        e.target.files = dataTransfer.files
        this.fileOnDragLeave()
        this.handleFileChange(e)
    }

    handleDropAlternative = dataTransfer => {
        let e = { target: { files: [] } }
        e.target.files = dataTransfer.files
        this.fileOnDragLeaveAlternative()
        this.handleFileChangeAlternative(e)
    }

    handleChange = e => {
        const { target } = e
        if (target.name === 'reservationNumber') {
            fireGtagEvent({ event: 'step5_afd_reservation_number' }, true)
            const value = latinize(target.value.toUpperCase())
            this.setState({ [target.name]: value }, () => {
                this.props.validateStepFive(this.state)
            })
        } else {
            this.setState({ [target.name]: target.value })
        }
    }

    checkNextStep = () => {
        const { flightDocs, errors, alternativeTransEnabled } = this.props
        const { componentState } = this.state
        const {
            hasAlternativeTransport,
            alternativeTransportType,
            isDocumentsUploadClicked,
        } = componentState
        const { alternativeFlightComponentState } = componentState
        const {
            flights,
            stopsCount,
            stopsState,
            airportDeparture,
            airportArrival,
        } = alternativeFlightComponentState

        const isAlternativeTrans =
            alternativeTransEnabled && isDocumentsUploadClicked
        if (isAlternativeTrans) {
            if (
                hasAlternativeTransport ===
                    alternativeTransports.HAS_ALTERNATIVE_REASON &&
                !alternativeTransportType
            ) {
                return true
            }

            if (!hasAlternativeTransport) {
                return true
            }

            if (
                alternativeTransportType ===
                alternativeTransports.ALTERNATIVE_TRANSPORT_FLIGHT
            ) {
                if (stopsCount !== stopsState.length) {
                    return true
                }

                if (!airportDeparture || !airportArrival) return true

                if (
                    flights &&
                    flights.some(
                        val =>
                            !val.airportArrival ||
                            !val.airportDeparture ||
                            !val.number ||
                            !val.timeDeparture
                    )
                ) {
                    return true
                }
            }
        }

        if (!_.isEmpty(errors)) return true

        return !!flightDocs.documents.length && flightDocs.uploading
    }

    handleFileChangeAlternative = e => {
        const {
            claimId,
            uploadDocumentAlternative,
            addUserDocuments,
            V2Claim,
            logKibana,
        } = this.props

        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                uploadErrorsAlternative: [],
            },
        }))
        const { files } = e.target
        const fileTypeReg = new RegExp(
            '(.*?).(docx|doc|pdf|xml|bmp|jpg|png|jpeg|odt)$'
        )

        fireGtagEvent({ event: 'step5_afd_choose_files' }, true)
        Array.prototype.forEach.call(files, (file, i) => {
            if (file.name && !fileTypeReg.test(file.name.toLowerCase())) {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        uploadErrorsAlternative: [
                            ...prevState.componentState.uploadErrorsAlternative,
                            {
                                name: file.name,
                                error: t`v2_claim.file.type.not.supported`,
                            },
                        ],
                        canRenderAlternative: true,
                    },
                }))
                logKibana(
                    `file_upload_failed_type: ${file.name}, claimId: ${V2Claim.id}`,
                    '/flight-documents',
                    'warning'
                )
            } else {
                const fileSize = (file.size / 1024 / 1024).toFixed(4)

                if (fileSize < 5) {
                    addUserDocuments(file)
                    uploadDocumentAlternative(file, claimId)
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            uploadErrorsAlternative: [
                                ...prevState.componentState
                                    .uploadErrorsAlternative,
                                {
                                    name: file.name,
                                    error: t`v2_claim.file.size.limit.reached`,
                                },
                            ],
                        },
                    }))
                    logKibana(
                        `file_upload_failed_size: ${fileSize}, claimId: ${V2Claim.id}`,
                        '/flight-documents',
                        'warning'
                    )
                }
            }
        })

        e.target.value = []
    }

    handleFileChange = e => {
        const {
            claimId,
            uploadDocument,
            addUserDocuments,
            V2Claim,
            logKibana,
        } = this.props

        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                uploadErrors: [],
            },
        }))
        const { files } = e.target
        const fileTypeReg = new RegExp(
            '(.*?).(docx|doc|pdf|xml|bmp|jpg|png|jpeg|odt)$'
        )

        fireGtagEvent({ event: 'step5_afd_choose_files' }, true)
        Array.prototype.forEach.call(files, (file, i) => {
            if (file.name && !fileTypeReg.test(file.name.toLowerCase())) {
                this.setState(prevState => ({
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        uploadErrors: [
                            ...prevState.componentState.uploadErrors,
                            {
                                name: file.name,
                                error: t`v2_claim.file.type.not.supported`,
                            },
                        ],
                        canRender: true,
                    },
                }))
                logKibana(
                    `file_upload_failed_type: ${file.name}, claimId: ${V2Claim.id}`,
                    '/flight-documents',
                    'warning'
                )
            } else {
                const fileSize = (file.size / 1024 / 1024).toFixed(4)

                if (fileSize < 5) {
                    addUserDocuments(file)
                    uploadDocument(file, claimId)
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        componentState: {
                            ...prevState.componentState,
                            uploadErrors: [
                                ...prevState.componentState.uploadErrors,
                                {
                                    name: file.name,
                                    error: t`v2_claim.file.size.limit.reached`,
                                },
                            ],
                        },
                    }))
                    logKibana(
                        `file_upload_failed_size: ${fileSize}, claimId: ${V2Claim.id}`,
                        '/flight-documents',
                        'warning'
                    )
                }
            }
        })

        e.target.value = []
    }

    handleReupload = file => {
        const { claimId, uploadDocument } = this.props
        uploadDocument(file, claimId)
    }

    renderDocumentsListSeparate = (documents, failedDocuments) => {
        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { file, progress } = document
                const { name } = file
                const isSuccess = progress === 100
                const isLoading = progress < 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={isLoading}
                                failed={false}
                            >
                                {isSuccess
                                    ? t`upload_document_success`
                                    : t`upload_document_uploading`}
                            </DocumentStatus>
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => {
                                this.props.removeUserDocument(document.id)
                            }}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            const failedDocs = failedDocuments.map((document, index) => {
                const { file, progress } = document
                const name = file && file.name
                const isSuccess = progress === 100

                return (
                    <DocumentWrapper>
                        <IconWrapper>
                            <Document />
                        </IconWrapper>
                        <DocumentStatusWrapper>
                            <DocumentTitle>{name}</DocumentTitle>
                            <DocumentStatus
                                isLoading={false}
                                failed={!isSuccess}
                            >
                                {isSuccess
                                    ? t`upload_document_uploading`
                                    : t`upload_document_failed`}
                            </DocumentStatus>
                            {!isSuccess && (
                                <ReuploadWrapper
                                    onClick={() => this.handleReupload(file)}
                                >
                                    <ReuploadTitle>{t`reupload_document`}</ReuploadTitle>
                                    <Reupload />
                                </ReuploadWrapper>
                            )}
                        </DocumentStatusWrapper>
                        <ClearIconWrapper
                            onClick={() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    componentState: {
                                        ...prevState.componentState,
                                        uploadErrors: [],
                                    },
                                }))
                            }}
                        >
                            <CloseCircle />
                        </ClearIconWrapper>
                    </DocumentWrapper>
                )
            })

            return (
                <div>
                    {docs}
                    {failedDocs}
                </div>
            )
        }
    }

    renderDocumentsList = (documents, failedDocuments) => {
        const { innerWidth } = this.state

        const handleName = name => {
            let availableLength = 25
            let lettersCount = 10
            let docName = ''

            if (innerWidth < 960) {
                if (innerWidth < 420) {
                    availableLength = 29
                } else if (innerWidth < 520) {
                    availableLength = 45
                } else if (innerWidth < 720) {
                    availableLength = 70
                    lettersCount = 20
                } else {
                    availableLength = 60
                    lettersCount = 20
                }
            } else {
                if (innerWidth >= 960 && innerWidth < 1145) {
                    availableLength = 15
                }
            }

            if (name) {
                if (name.length > availableLength) {
                    const firstPart = name.substring(0, lettersCount)
                    const last = name.substring(lettersCount)
                    const lastPart = last.substring(
                        last.length - (availableLength - 3 - lettersCount)
                    )
                    docName = `${firstPart}...${lastPart}`
                } else {
                    docName = name
                }
            }

            return docName ? docName : ''
        }

        if (
            (documents && !!documents.length) ||
            (failedDocuments && !!failedDocuments.length)
        ) {
            const docs = documents.map((document, index) => {
                const { file } = document
                const { name } = file

                const docName = handleName(name)

                return (
                    <DocumentOuter key={index}>
                        <DocumentIcon />
                        <DocumentInner>
                            <DocumentName>{docName}</DocumentName>
                            <DocumentProgress proc={document.progress} />
                            <DocumentSize>
                                {file.size && bytesToSize(file.size)}
                            </DocumentSize>
                        </DocumentInner>
                        <RemoveButtonContainer
                            done={!!document.id}
                            onClick={() => {
                                this.props.removeUserDocument(document.id)
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            const failedDocs = (failedDocuments || []).map((doc, i) => {
                const { name } = doc
                const docName = handleName(name)

                return (
                    <DocumentOuter key={i}>
                        <FailedDocIcon />
                        <DocumentInner>
                            <DocumentName className="document-name">
                                {docName}
                            </DocumentName>
                        </DocumentInner>
                        <RemoveButtonContainer
                            onClick={() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    componentState: {
                                        ...prevState.componentState,
                                        uploadErrors: [],
                                    },
                                }))
                            }}
                        >
                            <CloseIcon />
                        </RemoveButtonContainer>
                    </DocumentOuter>
                )
            })

            return (
                <DocumentsContainer>
                    <StyledP className="mb-16">{t`v2_common.uploaded_files`}</StyledP>
                    {docs}
                    {failedDocs}
                </DocumentsContainer>
            )
        } else {
            return null
        }
    }

    getProblemAirline = () => {
        const { probAirline } = this.props
        return _.get(probAirline, 'problem_claim_flight.airline', '')
    }

    getAirlineDocsList = () => {
        const airline = this.getProblemAirline()

        if (airline) {
            const airlineId = airline.id
            const { airlineDocuments } = getAirlineById(airlineId) || {}
            return airlineDocuments || []
        } else {
            return []
        }
    }

    hasDocList = () => {
        const airlineDocuments = this.getAirlineDocsList()
        const notRequiredDocs = airlineDocuments.filter(
            doc => doc.status === 'not_required'
        )

        if (
            !airlineDocuments ||
            airlineDocuments.length === 0 ||
            notRequiredDocs.length === airlineDocuments.length
        ) {
            return false
        } else {
            return true
        }
    }

    clearAlternativeUploadErrors = () => {
        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                uploadErrorsAlternative: [],
            },
        }))
    }

    handleHasAlternativeTransportChange = e => {
        const { name } = e.target

        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                hasAlternativeTransport: name,
            },
        }))
    }

    handleAlternativeTransportTypeChange = e => {
        const { name } = e.target

        this.setState(prevState => ({
            ...prevState,
            componentState: {
                ...prevState.componentState,
                alternativeTransportType: name,
            },
        }))
    }

    handleRadioOnChange = (stopsCount, isDirect) => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        this.setState(
            {
                componentState: {
                    ...componentState,
                    alternativeFlightComponentState: {
                        ...alternativeFlightComponentState,
                        stopsCount,
                        stopsState: [],
                        isDirect,
                    },
                },
            },
            () => {
                const { componentState } = this.state
                const { alternativeFlightComponentState } = componentState
                this.setState({
                    componentState: {
                        ...componentState,
                        alternativeFlightComponentState: {
                            ...alternativeFlightComponentState,
                            flights: this._mapFlights(),
                        },
                    },
                })
                this.handleSelectedAirports()
            }
        )
    }

    _renderDocList = () => {
        const airlineDocuments = this.getAirlineDocsList()

        const restDocs = airlineDocuments.filter(
            doc => doc.documentType !== 'ticket'
        )
        const hasRequiredRest =
            restDocs.filter(doc => doc.status !== 'not_required').length !== 0
        const resultDocs = [
            { documentType: 'ticket', status: 'optional' },
            ...restDocs.filter(doc => doc.status !== 'not_required'),
        ]

        const docTypeComponents = {
            id: 'passport',
            ticket: 'ticket',
            boarding_pass: 'email',
        }

        if (
            airlineDocuments &&
            airlineDocuments.length > 0 &&
            hasRequiredRest
        ) {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <StyledP style={{ marginBottom: 0 }}>
                                {t`flight_documents.required_documents`.replace(
                                    '%AIRLINE%',
                                    this.getProblemAirline().title || ''
                                )}
                            </StyledP>
                        </Col>
                    </Row>
                    <DocsRow>
                        {resultDocs.map((doc, i) => {
                            const Icon = docTypeComponents[doc.documentType]
                            if (Icon) {
                                return (
                                    <Col key={doc.documentType}>
                                        <img
                                            src={`/images/${
                                                isMobileDevice()
                                                    ? 'mobile-'
                                                    : ''
                                            }${
                                                docTypeComponents[
                                                    doc.documentType
                                                ]
                                            }.svg`}
                                            width="48px"
                                            alt={
                                                docTypeComponents[
                                                    doc.documentType
                                                ]
                                            }
                                        />
                                        <span className="item-title">{t`icon_label.${
                                            docTypeComponents[doc.documentType]
                                        }`}</span>
                                    </Col>
                                )
                            } else {
                                return null
                            }
                        })}
                    </DocsRow>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <StyledP style={{ marginBottom: 0 }}>
                                {t`flight_documents.required_documents`.replace(
                                    '%AIRLINE%',
                                    this.getProblemAirline().title || ''
                                )}
                            </StyledP>
                        </Col>
                    </Row>
                    <DocsRow>
                        <Col>
                            <img
                                src={`/images/${
                                    isMobileDevice() ? 'mobile-' : ''
                                }ticket.svg`}
                                style={{ minWidth: '48px' }}
                                width="48px"
                                alt="confirmation"
                            />
                            <span className="item-title">{t`doc_type_ticket`}</span>
                        </Col>
                    </DocsRow>
                </React.Fragment>
            )
        }
    }

    handleStopCountChange = (stop, i) => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState

        this.setState(
            {
                componentState: {
                    ...componentState,
                    alternativeFlightComponentState: {
                        ...alternativeFlightComponentState,
                        stopsCount: stop,
                        stopsState: alternativeFlightComponentState.stopsState.filter(
                            s => s.id <= i
                        ),
                    },
                },
            },
            () => {
                const { componentState } = this.state
                const { alternativeFlightComponentState } = componentState
                this.setState({
                    componentState: {
                        ...componentState,
                        alternativeFlightComponentState: {
                            ...alternativeFlightComponentState,
                            flights: this._mapFlights(),
                        },
                    },
                })
            }
        )
    }

    _handleStopChange = (event, callback) => {
        const { selectedAirports } = this.props
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { stopsState } = alternativeFlightComponentState
        const id = callback()

        if (
            !event ||
            !event.value ||
            !event.name ||
            selectedAirports.find(a => a.id === event.value)
        ) {
            return
        }

        const restStops = stopsState.filter(s => s.id !== id)

        const allStops = [
            {
                value: event.value,
                id,
                error: event.error || '',
            },
            ...restStops,
        ]

        this.setState(
            {
                componentState: {
                    ...componentState,
                    alternativeFlightComponentState: {
                        ...alternativeFlightComponentState,
                        stopsState: allStops,
                    },
                },
            },
            () => {
                const { componentState } = this.state
                const { alternativeFlightComponentState } = componentState
                this.setState(
                    {
                        componentState: {
                            ...componentState,
                            alternativeFlightComponentState: {
                                ...alternativeFlightComponentState,
                                flights: this._mapFlights(),
                            },
                        },
                    },
                    () => {
                        this.handleSelectedAirports()
                    }
                )
            }
        )
    }

    handleAirlineInputChange = inputValue => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState

        this.setState({
            componentState: {
                ...componentState,
                alternativeFlightComponentState: {
                    ...alternativeFlightComponentState,
                    airlinesInputValue: inputValue,
                },
            },
        })
    }

    clearAirline = index => {
        const { componentState } = this.state
        const { alternativeFlightComponentState } = componentState
        const { flights } = alternativeFlightComponentState

        const newFlights = [...flights]

        if (newFlights && newFlights[index] && newFlights[index].airline) {
            delete newFlights[index].airline
            delete newFlights[index].prefillLetters
            if (!!newFlights[index].preselectId) {
                delete newFlights[index].preselectId
                delete newFlights[index].number
            }
        }

        this.setState({
            componentState: {
                ...componentState,
                alternativeFlightComponentState: {
                    ...alternativeFlightComponentState,
                    flights: newFlights,
                },
            },
        })
    }

    render() {
        const { componentState, preselectLoading } = this.state
        const {
            flightDocs,
            nav,
            trans,
            alternativeTransEnabled,
            removeUserDocument,
        } = this.props
        const documentsExist = !!flightDocs.documents.length

        const {
            hasAlternativeTransport,
            alternativeTransportType,
            isDocumentsUploadClicked,
        } = componentState

        const isAlternativeTrans =
            alternativeTransEnabled && isDocumentsUploadClicked

        if (!trans) return null

        return (
            <StepView
                noDoLaterBtn={isAlternativeTrans}
                sideBarPaddingTop={'0px'}
                nav={{ ...nav, nextDisabled: this.checkNextStep() }}
                Sidebar={
                    <SidebarProgress
                        activeIndex={4}
                        checkedIndexes={[0, 1, 2, 3]}
                        paddingTop={0}
                    />
                }
                title={
                    isAlternativeTrans
                        ? t`alternative.trans.page.title`
                        : t`flight_documents.add_documents`
                }
                renderFPMobile={true}
                titleBottomPadding="22px"
            >
                {isAlternativeTrans ? (
                    <AlternativeTransportation
                        hasAlternativeTransport={hasAlternativeTransport}
                        alternativeTransportType={alternativeTransportType}
                        handleHasAlternativeTransportChange={
                            this.handleHasAlternativeTransportChange
                        }
                        handleAlternativeTransportTypeChange={
                            this.handleAlternativeTransportTypeChange
                        }
                        handleDrop={this.handleDropAlternative}
                        fileOnDragOver={this.fileOnDragOverAlternative}
                        fileOnDragLeave={this.fileOnDragLeaveAlternative}
                        componentState={componentState}
                        dragAlternativeContainer={this.dragAlternativeContainer}
                        handleFileChange={this.handleFileChangeAlternative}
                        removeUserDocument={removeUserDocument}
                        handleReupload={this.handleReupload}
                        clearAlternativeUploadErrors={
                            this.clearAlternativeUploadErrors
                        }
                        handleRadioOnChange={this.handleRadioOnChange}
                        setAirport={this.setAirport}
                        clearAirport={this.clearAirport}
                        handleStopCountChange={this.handleStopCountChange}
                        _handleStopChange={this._handleStopChange}
                        handleAirlineChange={this.handleAirlineChange}
                        handlePreselect={this.handlePreselect}
                        clearAirline={this.clearAirline}
                        handleAirlineInputChange={this.handleAirlineInputChange}
                        preselectLoading={preselectLoading}
                    />
                ) : (
                    <FileDragAndDrop
                        onDrop={this.handleDrop}
                        onDragOver={this.fileOnDragOver}
                        onDragLeave={this.fileOnDragLeave}
                        onDragEnd={this.fileOnDragLeave}
                    >
                        <StyledStepContent>
                            {this._renderDocList()}
                            {!!_.get(
                                componentState,
                                'uploadErrors.length',
                                0
                            ) && (
                                <Row>
                                    <Col>
                                        <StyledErrorsBox>
                                            <ul>
                                                {componentState.uploadErrors.map(
                                                    (err, i) => (
                                                        <li key={i}>
                                                            {err.name}
                                                            &nbsp;-&nbsp;
                                                            {err.error.toLowerCase()}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </StyledErrorsBox>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={12} lg={12}>
                                    <DragDropContainer
                                        innerRef={this.dragContainer}
                                    >
                                        <FileInputLabel>
                                            <FileInput
                                                type="file"
                                                id="file"
                                                name="fileselect[]"
                                                multiple="multiple"
                                                onChange={this.handleFileChange}
                                                accept="image/*,application/msword,application/vnd.oasis.opendocument.text,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            />
                                            <FileUploadText
                                                className="inputText"
                                                id="dd-box"
                                            >
                                                <TextContainer>
                                                    <IconDisplay>
                                                        <UploadIconV2 />
                                                        <DragTitle>
                                                            {isMobileDevice()
                                                                ? t`v2_claim_documents_drag_title_mobile`
                                                                : t`v2_claim_documents_drag_title_desktop`}
                                                        </DragTitle>
                                                    </IconDisplay>
                                                    <DragDescription>
                                                        {t`v2_claim_documents_drag_description`}
                                                    </DragDescription>
                                                </TextContainer>
                                            </FileUploadText>
                                        </FileInputLabel>
                                    </DragDropContainer>
                                </Col>
                            </Row>
                            {(!!_.get(
                                componentState,
                                'uploadErrors.length',
                                0
                            ) ||
                                (componentState.canRender &&
                                    documentsExist)) && (
                                <UploadedDocsWrapper>
                                    <Col
                                        className="pdt-0 pdb-0"
                                        md={12}
                                        sm={12}
                                        lg={12}
                                    >
                                        {this.renderDocumentsListSeparate(
                                            flightDocs.documents,
                                            componentState.uploadErrors
                                        )}
                                    </Col>
                                </UploadedDocsWrapper>
                            )}
                        </StyledStepContent>
                    </FileDragAndDrop>
                )}
            </StepView>
        )
    }
}

function mapStateToProps(state) {
    return {
        v2_step: state.v2_step,
        flightDocs: state.v2_user_documents,
        errors: state.v2_step_five_errors.errors,
        V2Claim: state.V2Claim,
        trans: state.translations,
        claim: state.v2_posted_claim,
        claimId: state.v2_posted_claim && state.v2_posted_claim.id,
        claimPreloaded: state.claimPreloaded,
        probAirline: state.v2_posted_claim,
        postedClaim: state.v2_posted_claim,
        alternativeTransEnabled: state.v2_claimable.isTransportAvailable,
        selectedAirports: state.v2_selectedAirports,
        usedAirports: state.v2_UsedAirports,
        usedAirlines: state.v2_UsedAirlines,
        regulation: state.regulation,
        v2_amount: state.v2_amount,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetClaimDocuments,
            uploadDocumentAlternative,
            uploadDocument,
            removeUserDocument,
            addUserDocuments,
            validateStepFive,
            setV2Claim,
            setProgress,
            checkUUID,
            getClaimDraftDocuments,
            setPreloadedClaimStep,
            logKibana,
            showModal,
            setSelectedAirports,
            setUsedAirports,
            postCodeFieldCheck,
            setSelectedAirlines,
            getSuggestedFlights,
            getAirlines,
            setUsedAirlines,
            getPayoutPlans,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
})(FlightDocuments)
