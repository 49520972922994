import React from "react";
import PropTypes from "prop-types";

const ErrorText = ({ text }) => {
    if (!text) {
        return null;
    } else {
        if (typeof text === 'string') {
            return (
                <div className="form-control-feedback has-danger h-mb5">
                    {text}
                </div>
            )
        } else {
            return (
                <div className="h-mb5">
                    {
                        text.map((item) => 
                            <div key={item} className="form-control-feedback has-danger">
                                {item}
                            </div>
                        )
                    }
                </div>
            )
        }
    }
}

ErrorText.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

ErrorText.defaultProps = {
    text: "",
};

export default ErrorText;