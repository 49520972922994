import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Datepicker from './DatePicker';

var classes;

class FlightDateField extends Component {

    constructor(props) {
        super(props);

        if (this.props.extraClasses !== "" && this.props.extraClasses) {
            classes = `col-md-5 ${this.props.extraClasses}`;
        }
        else {
            classes = "col-md-5 inline-block";
        }

        if (this.props.departureDate &&
            this.props.departureDate !== "" &&
            this.props.departureDate.toString() !== "Invalid Date") {

            this.state = {
                selectedDate: new Date(this.props.departureDate),
                isOpen: false
            };
        } else if (this.props.defaultValue) {
            this.state = {
                selectedDate: new Date(this.props.defaultValue),
                isOpen: false
            };
        }
        else {
            this.state = {
                selectedDate: "",
                isOpen: false
            };
        }
    }

    setDate = (date) => {
        const { name, value } = date;
        this.setState({selectedDate: value});
        this.props.handleChangeEvent({name, value});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props)
            if (this.props.departureDate &&
                this.props.departureDate !== "" &&
                this.props.departureDate.toString() !== "Invalid Date") {

                this.setState({
                    selectedDate: new Date(this.props.departureDate)
                });
            } else if (this.props.defaultValue) {
                this.setState({
                    selectedDate: new Date(this.props.defaultValue)
                });
            }
            else {
                this.setState({selectedDate: ""});
            }
    }


    valid = (current) => {
        const today = new Date();
        let now = new Date();
        now.setDate(today.getDate() + 14);

        let last = new Date();
        last.setDate(today.getDate() - 3660);
        return current.isBefore(now) && current.isAfter(last);
    };

    render() {
        const { selectedDate } = this.state;
        const { departureDate, selectErrors, ...props } = this.props;

        return (
            <Datepicker 
                ref={ref => {
                    this.datePickerRef = ref;
                }}
                classNames={classes}
                value={departureDate || selectedDate}
                readOnly={false}
                handleChange={this.setDate}
                errorText={selectErrors[props.name]}
                dateFormat="DD-MM-YYYY"
                viewMode="days"
                isValidDate={this.valid}
                startDate={2020}
                endDate={10}
                {...props}
            />
        );
    }
}

FlightDateField.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    viewDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    compactLabel: PropTypes.bool,
    errorText: PropTypes.string,
}


export default FlightDateField;